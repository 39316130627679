import { Button } from 'views/design-system';
import { useStyles } from './button-icon-styles';
import classNames from 'classnames';
interface ButtonIconProps {
  icon: JSX.Element;
  onClick: () => any;
  color?: 'primary' | 'secondary' | 'error' | 'success' | 'info';
  height?: number | string;
  className?: string
}

export const ButtonIcon = ({
  icon,
  onClick,
  color,
  height,
  className
}: ButtonIconProps) => {
  const classes = useStyles();

  return (
    <>
      <Button
        onClick={onClick}
        style={{
          height: height || undefined,
          background: color || 'none'
        }}
        className={classNames(classes.button, className)}
      >
        {icon}
      </Button>
    </>
  );
};
