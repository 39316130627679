import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { makeUtilClasses } from '../../../../theme';
import { picker } from '../../../../../utils/picker';
import { CircularLoading } from '../../../utils/circular-loading/circular-loading';
import { TextFieldSaurus } from '../../../controles/inputs';
import {
  DefaultFormProps,
  DefaultFormRefs,
} from '../../utils/form-default-props';
import { useFormComandaEditValidation } from './form-comanda-edit-validation';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { SelectSaurus } from 'views/components/controles/selects/select-saurus/select-saurus';
import { ComandasEditModel } from 'model/api/gestao/comanda/comanda-edit-model';
import { TpStatusComandaMock } from 'data/mocks/tp-status-comanda-mock';
import { ScannerIcon } from 'views/components/icons/scanner-icon';
import { useNFC } from 'services/app/hooks/nfc';
import { Box, Button, Grid } from 'views/design-system';


export interface FormComandaEditRefs extends DefaultFormRefs<ComandasEditModel> {
  setRfid(rfid: string): void;
}

interface FormComandaEditProps extends DefaultFormProps<ComandasEditModel> {
  setEscaneando: React.Dispatch<React.SetStateAction<boolean>>;
}

export const FormComandaEdit = forwardRef<
  FormComandaEditRefs,
  FormComandaEditProps
>(({ loading, setEscaneando, ...props }: FormComandaEditProps, ref) => {
  const utilClasses = makeUtilClasses();
  const refInputNumSalao = useRef<HTMLInputElement>(null);
  const [modelForm, setModelForm] = useState<ComandasEditModel>(
    new ComandasEditModel(),
  ); const { FormComandaEditYupValidation } = useFormComandaEditValidation();
  const { temNFC, verificarNFC } = useNFC();
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    setValue,
    getValues,
  } = useForm<ComandasEditModel>({
    defaultValues: { ...modelForm },
    resolver: yupResolver(FormComandaEditYupValidation),
    criteriaMode: 'all',
    mode: 'onChange',
  });

  const onSubmit = (values: ComandasEditModel) => {
    const salao = picker<ComandasEditModel>(values, new ComandasEditModel());
    props.onSubmit({ ...salao }, modelForm);
  };

  useImperativeHandle(ref, () => ({
    submitForm: async () => {
      await handleSubmit(onSubmit)();
    },
    resetForm: () => {
      refInputNumSalao.current?.focus();
    },
    fillForm: (model: ComandasEditModel) => {
      setModelForm(model);
      reset({ ...model });
      verificarNFC();
      setTimeout(() => {
        refInputNumSalao.current?.focus();
      }, 500);
    },
    setRfid: (rfid: string) => {
      setValue('rfId', rfid)
    }
  }));

  return (
    <>
      <Box my={2}>
        <div className={utilClasses.formContainer}>
          {loading && props.showLoading ? (
            <div className={utilClasses.controlLoading}>
              <CircularLoading tipo="NORMAL" />
            </div>
          ) : null}
          <form
            onSubmit={handleSubmit(onSubmit)}
            className={loading ? utilClasses.controlLoading : ''}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Controller
                  name="codigoComanda"
                  control={control}
                  render={({ field }) => (
                    <TextFieldSaurus
                      autoComplete='new-password'
                      tipo="TEXTO"
                      disabled={loading}
                      fullWidth
                      variant="outlined"
                      label="Código Comanda"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      placeholder={'EX: 1'}
                      error={Boolean(
                        errors.codigoComanda && errors.codigoComanda.message,
                      )}
                      helperText={
                        errors.codigoComanda
                          ? errors.codigoComanda?.message
                          : undefined
                      }
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="rfId"
                  control={control}
                  render={({ field }) => (
                    <TextFieldSaurus
                      tipo="TEXTO"
                      autoComplete='new-password'
                      readOnly={temNFC}
                      endAdornmentButton={temNFC ? <Button onClick={() => setEscaneando(true)}>
                        <ScannerIcon tipo='BUTTON' />
                      </Button> : undefined}
                      fullWidth
                      variant="outlined"
                      label="RFID"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      placeholder={'Escaneie a comanda para vincular o RFID'}
                      error={Boolean(errors.rfId && errors.rfId.message)}
                      helperText={
                        errors.rfId
                          ? errors.rfId?.message
                          : undefined
                      }
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="status"
                  control={control}
                  render={({ field }) => (
                    <SelectSaurus
                      label="Status"
                      variant="outlined"
                      fullWidth
                      disabled={loading}
                      conteudo={TpStatusComandaMock}
                      error={Boolean(errors.status && errors.status.message)}
                      helperText={
                        errors.status
                          ? errors.status?.message
                          : undefined
                      }
                      {...field}
                      onChange={(event) => {
                        const item = TpStatusComandaMock.filter(
                          (item) => item.Key === event.target.value,
                        )[0]?.Key;
                        if (item !== undefined) {
                          setValue('status', item);
                        }
                      }}
                      value={getValues('status')}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Button style={{ display: 'none' }} type="submit"></Button>
          </form>
        </div>
      </Box>
    </>
  );
});
