import { DialogSaurus } from '../dialog-saurus/dialog-saurus';
import { useStyles } from './dialog-fechamento-sessao-styles';
import { OkIcon, VoltarIcon } from 'views/components/icons';
import { SessaoValueFechamento } from 'model/api/gestao/sessao/sessao-valor-model';
import { EnumPagTpTransacao } from 'model';
import { useMovAtual } from 'services/app/hooks/mov-atual';
import { useCallback } from 'react';
import { useConfirm } from 'material-ui-confirm';
import { Box, Button, Divider, Grid, Typography } from 'views/design-system';

interface DialogFechamentoProps {
  openned: boolean;
  closeModal: () => void;
  valores: SessaoValueFechamento[];
  inserirValores: () => void;
}

export const DialogFechamentoSessao = ({
  openned,
  closeModal,
  valores,
  inserirValores
}: DialogFechamentoProps) => {
  const classes = useStyles();
  const { getMov, cancelarMov } = useMovAtual()
  const confirm = useConfirm()

  const zerados = valores.filter((item) => item.valor === '');

  const confirmar = useCallback(() => {
    const mov = getMov()

    if (mov) {
      confirm({
        title: 'Venda em Andamento',
        description:
          'Identificamos uma venda em Andamento. Caso confirme a finalização, a venda será descartada. Deseja continuar?',
        cancellationText: 'Voltar',
        cancellationButtonProps: {
          color: 'default',
        },
        confirmationButtonProps: {
          variant: 'contained',
          color: 'primary'
        },
        titleProps: {
          style: {
            fontWeight: 700
          }
        },
        confirmationText: 'Confirmar',
      }).then(async () => {
        await cancelarMov('Fechamento de Caixa')
        inserirValores()
      })
      return
    }

    inserirValores()
  }, [cancelarMov, confirm, getMov, inserirValores])

  return (
    <DialogSaurus
      aberto={openned || false}
      titulo="Finalizar Sessão"
      tamanho="xs"
    >
      <Box className={classes.cardContainer}>
        <Box className={classes.cardContent}>
          <Grid container spacing={2} style={{ marginBottom: 8 }}>
            <Grid item xs={12}>
              <Typography>
                As formas de pagamento não preechidas irão assumir o valor{' '}
                <strong>R$ 0,00</strong>
              </Typography>
            </Grid>

            <Grid item xs={12} className={classes.containerItens}>
              {zerados.map((sessaoValue, index) => (
                <>
                  <Grid container spacing={1}>
                    <Grid item xs={9}>
                      <Typography className={classes.textItem}>
                        <div className={classes.dot} />
                        {sessaoValue.pagamento.descricao}
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      {sessaoValue.pagamento.tpTransacao ===
                        EnumPagTpTransacao.S2_PAY &&
                        sessaoValue.valor === '0,00' ? (
                        <Typography style={{ color: '#808080', fontWeight: 600, fontSize: '12px' }}>À Confirmar</Typography>
                      ) : (
                        <Typography
                          style={{ color: '#808080', fontWeight: 600 }}
                        >
                          R$ 0,00
                        </Typography>
                      )}
                    </Grid>
                  </Grid>

                  {zerados.length - 1 !== index ? (
                    <Divider className={classes.divider} />
                  ) : null}
                </>
              ))}
            </Grid>

            <Grid item xs={12}>
              <Button
                disabled={zerados.length === 0 ? true : false}
                variant="contained"
                fullWidth
                color="primary"
                onClick={confirmar}
              >
                <OkIcon tipo="BUTTON_PRIMARY" />
                Confirmar
              </Button>
            </Grid>

            <Grid item xs={12}>
              {closeModal && (
                <Button
                  variant="outlined"
                  fullWidth
                  color="primary"
                  onClick={() => {
                    closeModal();
                  }}
                >
                  <VoltarIcon tipo="BUTTON" />
                  Voltar
                </Button>
              )}
            </Grid>
          </Grid>
        </Box>
      </Box>
    </DialogSaurus>
  );
};
