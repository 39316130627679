import { Grow } from '@material-ui/core';
import { DialogSaurus } from '../dialog-saurus/dialog-saurus';
import { useStyles } from './dialog-taxa-servico-styles';
import { useCallback, useState, useMemo } from 'react';
import { AlterarTaxa } from './components';
import { PedidoModel } from 'model/api/gestao/pedido/pedido-model';
import { roundTo } from 'utils/round-to';
import CardTaxaServico from 'views/components/cards/card-taxa-servico/card-taxa-servico';
import { EnumStatusProdutoPedido } from 'model/enums/enum-status-produto-pedido';
import { useCadastros } from 'services/app';
import { Box, Grid } from 'views/design-system';

interface DialogTaxaServicoProps {
  openned: boolean;
  model: PedidoModel;
  carregarInfoPedido?: () => void;
}

export interface VendedorObj {
  vendedorNome: string,
  vendedorId: string,
  taxa: number,
  valor: number
  ativo: boolean
}

export const DialogTaxaServico = ({
  openned,
  model,
  carregarInfoPedido
}: DialogTaxaServicoProps) => {
  const { fecharDialogTaxaServico } = useCadastros();
  const classes = useStyles();
  const [acao, setAcao] = useState<number>(0)
  const [pedido, setPedido] = useState<PedidoModel>(model)
  const [vendedor, setVendedor] = useState<VendedorObj>({
    taxa: 0,
    valor: 0,
    vendedorId: '',
    vendedorNome: '',
    ativo: true
  })

  const vendedoresUnicos = useCallback(() => {
    const vendedores = Array.from(new Set<string>(model.produtos.map(x => x.vendedorId)))

    return vendedores.map(x => {
      const produtos = pedido.produtos.filter(i => i.vendedorId === x)

      return {
        vendedorNome: produtos[0].vendedor,
        vendedorId: x,
        taxa: produtos[0]?.taxaServico ?? 0,
        valor: roundTo(produtos.filter(x => x.indFin).reduce((a, b) => a + (b.valorTotalServico ?? 0), 0)),
        ativo: produtos.filter(x => x.status.codigo === EnumStatusProdutoPedido.DISPONIVEL).length > 0
      } as VendedorObj
    })
  }, [model.produtos, pedido.produtos])

  const telaInicial = useMemo(() => {
    return (
      <Grow in={true}>
        <Grid
          container
          spacing={2}
          style={{ marginBottom: 8 }}
        >
          <Grid item xs={12}>
            <Grid container spacing={2}>
              {vendedoresUnicos().map(item => {
                return (
                  <Grid item xs={12}>
                    <CardTaxaServico
                      model={item}
                      onCheck={() => { }}
                      onClick={(item) => {
                        setVendedor(item)
                        setAcao(1)
                      }}
                      selected={false}
                    />
                  </Grid>
                )
              })}
            </Grid>
          </Grid>
        </Grid>
      </Grow>
    )
  }, [vendedoresUnicos])

  const acoes = useMemo(() => {
    switch (acao) {
      case 1:
        return (
          <AlterarTaxa
            pedidoModel={pedido}
            voltar={() => setAcao(0)}
            closeModal={fecharDialogTaxaServico}
            {...{
              setPedido,
              carregarInfoPedido,
              vendedor
            }}
          />
        )
      case 0:
        return telaInicial

    }
  }, [acao, carregarInfoPedido, fecharDialogTaxaServico, pedido, telaInicial, vendedor])


  const titulos = useMemo(() => {
    switch (acao) {
      case 1:
        return "Alterar Taxa"
      default:
        return "Taxa de Serviço"
    }
  }, [acao])

  return (
    <>
      <DialogSaurus
        aberto={openned || false}
        titulo={titulos}
        handleClickClose={fecharDialogTaxaServico}
        isButtonTitleClose
        tamanho="xs"
      >
        {openned && (
          <Box className={classes.cardContainer}>
            <Box className={classes.cardContent}>
              <Box className={classes.boxCustom}>
                {acoes}
              </Box>
            </Box>
          </Box>
        )}
      </DialogSaurus>
    </>
  );
};
