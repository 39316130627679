import { EnumStatusSituacaoPedido } from './../../../enums/enum-status-situacao-pedido';
import { EnumStatusPedido } from "model/enums/enum-status-pedido";
import { EnumTipoPedido } from "model/enums/enum-tipo-pedido";
import { guidEmpty } from "utils/guid-empty";
import { PedidoClienteModel } from "./pedido-cliente-model";
import { PedidoDadosIntegracaoModel } from "./pedido-integracao-model";
import { PedidoPagamentoModel } from "./pedido-pagamento-model";
import { PedidoProdutoModel } from "./pedido-produto-model";
import { ReceitaMedicaModel } from '../venda/venda-completa-model';

export class TipoPedido {
    constructor(
        public codigo: EnumTipoPedido = EnumTipoPedido.LOCAL,
        public descricao: string = 'Local'
    ) { }
}

export class StatusPedido {
    constructor(
        public codigo: EnumStatusPedido = EnumStatusPedido.CONFIRMADO,
        public descricao: string = 'Confirmado'
    ) { }
}
export class StatusAutomacao {
    constructor(
        public codigo: EnumStatusSituacaoPedido = EnumStatusSituacaoPedido.ABERTO,
        public descricao: string = 'Aberto'
    ) { }
}
export class PedidoModel {
    constructor(
        public id: string = guidEmpty(),
        public dataCriacao: string | Date = '',
        public systemUpdateDate: string | Date = '',
        public dataAgendamentoFinal: string | Date = '',
        public dataAgendamentoInicial: string | Date = '',
        public mesaId: string = guidEmpty(),
        public salaoId: string = guidEmpty(),
        public comandaId: string = guidEmpty(),
        public codigoComanda: string = '',
        public mesaCodigo: string = '',
        public tipoPedido: TipoPedido = new TipoPedido(),
        public statusPedido: StatusPedido = new StatusPedido(),
        public statusAutomacao: StatusAutomacao = new StatusAutomacao(),
        public codigoPedido: string = '',
        public codigoReferencia: string | null = null,
        public posicaoMesa: string = '',
        public documentoLoja: string = '',
        public quantidadePessoas: number = 0,
        public enderecoEntrega: PedidoEnderecoEntregaModel = new PedidoEnderecoEntregaModel(),
        public informacaoAdicional: string = '',
        public aplicarDescontoEcommerce: boolean = false,
        public valorTroco: number = 0,
        public taxaDeServico: number = 0,
        public valorTotalServico: number = 0,
        public valorTotalPedido: number = 0,
        public valorTotalAcrescimo: number = 0,
        public valorTotalAcrescimoEmbutido: number = 0,
        public valorTotalDesconto: number = 0,
        public valorTotalDescontoEmbutido: number = 0,
        public valorTotalFrete: number = 0,
        public descontoEcommerce: number = 0,
        public cliente: PedidoClienteModel = new PedidoClienteModel(),
        public produtos: PedidoProdutoModel[] = [],
        public pagamentos: PedidoPagamentoModel[] = [],
        public dadosIntegracao: PedidoDadosIntegracaoModel = new PedidoDadosIntegracaoModel(),
        public receitasMedicas: ReceitaMedicaModel[] = [],
        public identificador: string = "",

        //uso essa prop pra saber se é um pedido que pertence há uma comanda/mesa que foi reconfigurada
        public isDeprecated: boolean = false,
    ) { }
}

export class PedidoEnderecoEntregaModel {
    constructor(
        public logradouro: string = '',
        public numero: string = '',
        public bairro: string = '',
        public municipio: string = '',
        public uf: string = '',
        public cep: string = '',
        public latitude: number = 0,
        public longitude: number = 0,
        public complemento: string = '',
        public enderecoCompleto: string = '',
        public pais: string = '',
        public pontoReferencia: string = '',
        public codigoMunicipio: string = ''
    ) { }
}
