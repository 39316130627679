import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';
import { VariaveisAmbiente } from 'config';

export class KeyStoreModel {
    constructor(
        public id: string = '',
        public chave: string = '',
        public descricaoChave: string = '',
        public valor: string = '',
        public descricao: string = '',
        public referencia: string = '',
        public senha: string = ''
    ) { }
}

export function useGetKeyStore() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const getKeyStore = useCallback(
        (senha: string) => {
            return invocarApi({
                url: `/v1/KeyValueStore?senha=${senha}`,
                method: 'GET',
                enviarTokenUsuario: true,
                baseURL: `${VariaveisAmbiente.apiKeyStore}/api`
            })
        },
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        getKeyStore,
    };
}