import { Box, Button } from "views/design-system";
import { DialogSaurus } from "../dialog-saurus/dialog-saurus";
import { useStyles } from './dialog-vencimento-pagamento-styles'
import { CancelarIcon, SalvarIcon, VoltarIcon } from "views/components/icons";
import { ConfirmarIcon } from "views/components/icons/confirmar-icon";
import { useCallback, useEffect, useRef, useState } from "react";
import { MovRegistrarParcelasModel } from "model/api/gestao/mov-pag/mov-registrar";
import { MovSimplesPagamentoModel } from "model/api/gestao/movimentacao/simples/mov-simples-pagamento-model";
import { toCurrency, toDecimal } from "utils/to-decimal";
import { CardVencimentoPagamento } from "./components/card-vencimento-pagamento";
import { useConfirmSaurus } from "services/app/hooks/confirm-saurus";
import { FormVencimentoPagamento } from "views/components/form/vencimento-pagamento/form-vencimento-pagamento";
import { DefaultFormRefs } from "views/components/form/utils";
import { useCadastros } from "services/app";
import { useThemeQueries } from "views/theme";
import { useShowAviso } from "services/app/hooks/show-aviso";
import { useVencimentoParcela } from "services/app/hooks/vencimento-parcela";

interface Props {
    aberto: boolean;
    pagamento: MovSimplesPagamentoModel;
    parcelas: MovRegistrarParcelasModel[];
    callback: (parcelas: MovRegistrarParcelasModel[]) => void;
    cancelar: () => void;
}

export const DialogVencimentoPagamento = ({
    aberto,
    pagamento,
    parcelas,
    callback,
    cancelar
}: Props) => {
    const { showConfirm } = useConfirmSaurus();
    const { showAviso } = useShowAviso();
    const { getInfoFinalizadora } = useVencimentoParcela();

    const [modoLista, setModoLista] = useState<boolean>(true);
    const [step, setStep] = useState<number>(0);
    const [parcelasState, setParcelas] = useState<MovRegistrarParcelasModel[]>(parcelas)
    const vMinParcela = useRef<number>(0);

    const { fecharDialogVencimentoPagamento } = useCadastros();

    const formRef = useRef<DefaultFormRefs<MovRegistrarParcelasModel>>(null);

    const classes = useStyles();
    const { theme } = useThemeQueries();

    const confirmarVencimentos = () => {
        const vTotalParcelas = parcelasState.reduce<number>((prev, curr) => prev + curr.valor, 0);
        if (toDecimal(vTotalParcelas) !== toDecimal(pagamento.vPag)) {
            showAviso('error', `Valor total das parcelas não condiz com valor total da venda:/n/nValor da Venda: ${toCurrency(pagamento.vPag)}/nValor Total das Parcelas: ${toCurrency(vTotalParcelas)}`);
            return
        }
        callback(parcelasState);
        fecharDialogVencimentoPagamento();
    }

    const avancarStep = useCallback(() => {
        setStep(prev => prev + 1);
    }, [])

    const fecharDialog = () => {
        showConfirm({
            title: 'Confirmar Cancelamento',
            showIcon: true,
            description: 'Deseja cancelar a operação atual de pagamento?',
            primaryButtonText: 'Cancelar',
            secondaryButtonText: 'Voltar',
            secondaryButtonColor: theme.palette.primary.main,
            confirmIcon: <CancelarIcon />
        }).then(() => {
            cancelar();
            fecharDialogVencimentoPagamento();
        })
    }

    const voltarStep = useCallback(() => {
        setStep(prev => prev - 1);
    }, [])

    const abrirParcela = (index: number) => {
        setModoLista(false);
        setStep(index);
    }

    const formSubmit = (model: MovRegistrarParcelasModel) => {
        setParcelas(prev => {
            prev[step] = { ...model };

            return [...prev];
        })
        if (step >= parcelasState.length - 1) {
            setModoLista(true);
        } else {
            avancarStep();
        }
    }

    useEffect(() => {
        (async () => {
            const infoFinalizadora = await getInfoFinalizadora(pagamento);
            if (infoFinalizadora) {
                vMinParcela.current = infoFinalizadora.vMinParcela;
            }
        })()
    }, [getInfoFinalizadora, pagamento])

    useEffect(() => {
        if (!modoLista) {
            const parcela = parcelas[step];
            formRef.current?.fillForm({ ...parcela });
        }
    }, [modoLista, parcelas, step])

    return <DialogSaurus
        aberto={aberto}
        titulo={'Vencimentos - ' + pagamento.pagamentoNome + (!modoLista ? ` - Parcela ${step + 1}` : '')}
        isButtonTitleClose
        handleClickClose={fecharDialog}
        tamanho='sm'
    >
        <Box className={classes.container}>
            {modoLista ? <>
                <Box flex flexDirection="column" alignItems="stretch">
                    {parcelasState.map((parcela, index) => {
                        return <CardVencimentoPagamento
                            index={index}
                            model={parcela}
                            onClick={abrirParcela}
                            key={index}
                        />
                    })}
                </Box>
                <Box className={classes.buttonContainer}>
                    <Button onClick={confirmarVencimentos} variant="contained" color='primary'>
                        <ConfirmarIcon tipo='BUTTON_PRIMARY' />
                        Confirmar
                    </Button>
                </Box>
            </> : <>
                <FormVencimentoPagamento
                    loading={false}
                    showLoading={false}
                    onSubmit={formSubmit}
                    ref={formRef}
                    vMinParcela={vMinParcela.current}
                />
                <Box className={classes.buttonContainer}>
                    <Button fullWidth size="small" onClick={() => setModoLista(true)}>
                        Voltar à listagem
                    </Button>
                    {step > 0 && <Button onClick={voltarStep} variant="outlined" color='primary'>
                        <VoltarIcon tipo='BUTTON' />
                        Anterior
                    </Button>}
                    <Button onClick={() => formRef.current?.submitForm()} variant="contained" color='primary'>
                        <SalvarIcon tipo='BUTTON_PRIMARY' />
                        {step === parcelasState.length - 1 ? 'Salvar' : 'Salvar e Avançar'}
                    </Button>
                </Box>
            </>}
        </Box>
    </DialogSaurus>
}