import React, { useRef, useState } from 'react';
import { useGetPerfilById } from '../../../data/api/gestao/perfil/get-perfil-by-id';
import { EnumRetornoApiBase } from '../../../data/api/base/api-base-response';
import { PerfilModel } from '../../../model/api/gestao/master/perfil';
import { useCallback } from 'react';
import { TokenGestaoEmpresaModel } from '../../../model/api/gestao/master/token-gestao-empresa';
import { isEmpty } from 'lodash';
import { usePostIdentidadeRefreshToken } from '../../../data/api/gestao/identidade/post-refresh-token';
import { useGetPlanoAtual } from 'data/api/gestao/plano/get-plano-atual';
import { EmpresaModel, PerfilPermissaoModel, PoliticaModel, RetornoApiModel, TokenGestaoModel } from 'model';
import { PlanoAtualModel } from 'model/api/gestao/plano/plano-atual-model';
import { ConfigEmpresaModel } from 'model/api/gestao/config-empresa/config-empresa-model';
import { UltimoLoginModel } from 'model/app/ultimo-login/ultimo-login-model';
import { useGetConfigEmpresaId } from 'data/api/gestao/empresa/get-config-empresa-id';
import { useGetEmpresaId } from 'data/api/gestao/empresa';
import { EmpresaCompletaModel } from 'model/api/gestao/master/empresaCompleta';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { useGetUsuarioPessoa } from 'data/api/gestao/pessoa/get-usuario-pessoa';
import { PlanoCorMock } from 'data/mocks/plano-cor-mock';
import { useGestaoToken } from './gestao-token';
import { GestaoStorageKeys, useCadastros, useGestaoStorage, useToastSaurus } from '../hooks';
import { useGetConfigContrato } from 'data/api/gestao/contrato/get-contrato-configuracao';
import { MeuUsuarioModel } from 'model/api/gestao/master/meu-usuario';
import { useGetMeuUsuario } from 'data/api/gestao/meu-usuario';
import { useAuth } from '../hooks/auth';
import { useGetPoliticaExistePendente } from 'data/api/gestao/politica/get-politica-existe-pendentes';
import { PessoaModel } from 'model/api/gestao/pessoa';
import { useGetConfigEmissor } from 'data/api/gestao/empresa/get-config-emissor';
import { ContratoConfigCompletoModel } from 'model/api/gestao/master/contrato-completo-config';
import { MultiContratoModel } from 'model/api/gestao/multi-contrato/multi-contrato';
import { ApiError } from 'model/app/errors/api-erros';
import { useGetWhitelabel } from 'data/api/gestao/whitelabel/get-whitelabel';
import { WhitelabelModel } from 'model/api/gestao/whitelabel/whitelabel-model';
import { toDecimal } from 'utils/to-decimal';
import { EnumTpDescontoAcrescimo } from 'model/enums/enum-tp-desconto-acrescimo';
import { useTermosDeUsoStore } from 'views/states/termos-de-uso-store';
import { useGetPoliticaPendente, usePutPoliticaAceitar } from 'data/api/gestao/politica';
import { EnumCodigosPermissoes } from 'model/enums/enum-codigos-permissoes';

interface SessaoAtualContextValue {
  usuario?: TokenGestaoModel;
  meuUsuario?: MeuUsuarioModel;
  setUserApelido: (apelido: string) => void;
  plano?: PlanoAtualModel | undefined;
  getEmpresaSelecionada: () => TokenGestaoEmpresaModel | undefined;
  getPerfil: () => Array<PerfilModel>;
  getPessoa: () => { pessoa: PessoaModel };
  carregando: boolean;
  logar: (
    usuario: string,
    senha: string,
    contratoId: string,
    manterConectado: boolean,
    integracao?: boolean,
  ) => Promise<string>;
  deslogar: (usuarioId: string) => void;
  setarUser: (token: string, manterConectado: boolean) => Promise<void>;
  carregarDadosUsuario: (user: TokenGestaoModel) => Promise<void>;
  refreshUser: (refreshToken: string) => Promise<boolean>;
  sessaoExpirada: () => boolean;
  converterToken: (token: string) => TokenGestaoModel | undefined;
  termosDeUsoAtivo: () => boolean;
  selecionarEmpresa: (empresaId: string) => Promise<void>;
  validaUsuarioConectado: () => boolean;
  getPermissaoBoolean: (codigo: number | EnumCodigosPermissoes) => boolean;
  getPermissaoString: (codigo: number) => string;
  getPermissaoDesconto: (codigo: number, vDesc: number, tipo: EnumTpDescontoAcrescimo, vTotal: number) => boolean;
  refreshPlanoUsuario: () => Promise<void>;
  refreshEmpresa: () => Promise<void>;
  refreshContrato: () => Promise<void>;
  refreshEmpresaFiscal: () => Promise<void>;
  carregarDadosPerfisEPermissoes: (user: TokenGestaoModel) => Promise<void>;
  isPlanoAvulso: () => boolean;
}

const SessaoAtualContext = React.createContext<SessaoAtualContextValue>({
  usuario: undefined,
  meuUsuario: undefined,
  setUserApelido: (apelido: string) => { },
  carregando: false,
  getPerfil: () => new Array<PerfilModel>(),
  getPessoa: () => ({ pessoa: new PessoaModel() }),
  sessaoExpirada: () => false,
  getEmpresaSelecionada: () => undefined,

  logar: (
    usuario: string,
    senha: string,
    contratoId: string = '',
    manterConectado: boolean,
    integracao: boolean = false
  ) => {
    return new Promise<string>(() => '');
  },
  converterToken: (token: string) => {
    return undefined;
  },
  setarUser: async () => { },
  deslogar: (usuarioId: string) => { },
  carregarDadosUsuario: (user: TokenGestaoModel) => {
    return new Promise<void>(() => true);
  },
  refreshUser: (refreshToken: string) => {
    return new Promise<boolean>(() => true);
  },
  selecionarEmpresa: (empresaId: string) => new Promise<void>(() => { }),

  termosDeUsoAtivo: () => false,
  validaUsuarioConectado: () => false,
  getPermissaoBoolean: (codigo: number) => false,
  getPermissaoString: (codigo: number) => '',
  getPermissaoDesconto: (codigo: number, vDesc: number, tipo: EnumTpDescontoAcrescimo, vTotal: number) => false,
  refreshPlanoUsuario: () => {
    return new Promise<void>(() => true);
  },
  carregarDadosPerfisEPermissoes: () => {
    return new Promise<void>(() => true);
  },
  refreshEmpresa: () =>
    new Promise<void>(() => { }),
  refreshContrato: () =>
    new Promise<void>(() => { }),
  refreshEmpresaFiscal: () =>
    new Promise<void>(() => { }),
  isPlanoAvulso: () => false,

});

export interface SessaoAtualProviderProps {
  children: React.ReactNode;
}

export const useSessaoAtual = () => {
  return React.useContext(SessaoAtualContext);
};

export const SessaoAtualProvider = ({ children }: SessaoAtualProviderProps) => {
  // HOOKS
  const {
    getTokenFromStorage,
    convertToken,
    getConnectedUsers,
    persistTermosDeUso,
    getEmpresaAtual,
    getTermosDeUso,
    getPerfilPermissoes,
    persistPerfilPermissoes,
    persistPessoaUsuario,
    getPessoaUsuario,
    isTokenValid,
    persistToken,
    addConnectedUser,
    removeConnectedUser,
    getPlanoUsuario,
    persistPlanoUsuario,
    getMeuUsuarioStorage,
    persistMeuUsuarioStorage
  } = useGestaoToken();
  const { delRegistro, setRegistro, getRegistro } = useGestaoStorage();
  const { abrirAvisoCobrancaDialog } = useCadastros()
  const { callEvent } = useEventTools()
  const { showToast } = useToastSaurus();
  const { auth, carregando: carregandoAutenticacao } = useAuth();

  // STATES E REFS
  const [loadingManual, setLoadingManual] = useState(false);
  const [usuario, setarUsuario] = React.useState(getTokenFromStorage());
  const [meuUsuario, setMeuUsuario] = React.useState(getMeuUsuarioStorage());
  const [plano, setPlano] = React.useState(getPlanoUsuario());
  const logouRef = useRef<boolean>(false)
  const { setTermosAceitos } = useTermosDeUsoStore()

  // CHAMDAS API
  const { getPoliticaPendente, carregando: carregandoPoliticaPendente } = useGetPoliticaPendente()
  const { getPerfilById, carregando: carregarPerfilById } = useGetPerfilById();
  const { postIdentidadeRefreshToken, carregando: carregandoRefreshToken } =
    usePostIdentidadeRefreshToken();
  const { getPlanoAtual, carregando: carregandoPlanoAtual } =
    useGetPlanoAtual();
  const { getPoliticaExistePendente, carregando: carregandoPoliticaAutenticar } =
    useGetPoliticaExistePendente();
  const { getConfigEmissor, carregando: carregandoConfigEmissor } =
    useGetConfigEmissor();
  const { getConfigEmpresaId, carregando: carregandoConfigEmpresa } =
    useGetConfigEmpresaId();
  const { getConfigContrato, carregando: carregandoConfigContrato } =
    useGetConfigContrato();
  const { getMeuUsuario, carregando: carregandoMeuUsuario } =
    useGetMeuUsuario();
  const { getEmpresaId, carregando: carregandoEmpresaId } = useGetEmpresaId()
  const { getUsuarioPessoa, carregando: carregandoUsuarioPessoa } = useGetUsuarioPessoa()
  const { getWhitelabel, carregando: carregandoWhiteLabel } = useGetWhitelabel();
  const { putPoliticaAceitar, carregando: carregandoPutPoliticaAceitar } = usePutPoliticaAceitar()


  // AUX
  const loading =
    carregandoRefreshToken ||
    carregandoPoliticaPendente ||
    carregandoPoliticaAutenticar ||
    carregarPerfilById ||
    carregandoPlanoAtual ||
    loadingManual ||
    carregandoConfigEmissor ||
    carregandoConfigEmpresa ||
    carregandoEmpresaId ||
    carregandoUsuarioPessoa ||
    carregandoMeuUsuario ||
    carregandoAutenticacao ||
    carregandoConfigContrato ||
    carregandoWhiteLabel ||
    carregandoPutPoliticaAceitar;

  const sessaoExpirada = React.useCallback((): boolean => {
    return !isTokenValid(usuario);
  }, [isTokenValid, usuario]);

  const converterToken = React.useCallback(
    (token: string): TokenGestaoModel | undefined => {
      return convertToken(token);
    },
    [convertToken],
  );

  const termosDeUsoAtivo = React.useCallback((): boolean => {
    return getTermosDeUso();
  }, [getTermosDeUso]);

  const limparVariaveis = useCallback(() => {
    persistPlanoUsuario(undefined);
    persistTermosDeUso(undefined, undefined);
    setPlano(undefined);
    persistPerfilPermissoes(undefined);
    persistPessoaUsuario(undefined);
    persistMeuUsuarioStorage(undefined)
    delRegistro(GestaoStorageKeys.SincronizacaoGeralAtual, false);
    delRegistro(GestaoStorageKeys.Whitelabel, false);
    delRegistro(GestaoStorageKeys.Tutoriais, false);
    sessionStorage.clear();
  }, [delRegistro, persistMeuUsuarioStorage, persistPerfilPermissoes, persistPessoaUsuario, persistPlanoUsuario, persistTermosDeUso]);

  const deslogar = React.useCallback(
    (usuarioId: string) => {
      removeConnectedUser(usuarioId || '');
      persistToken(undefined);
      setarUsuario(undefined);
      persistMeuUsuarioStorage(undefined);
      setMeuUsuario(new MeuUsuarioModel());
      limparVariaveis();

      let link = document.querySelector("link[rel~='icon']") as any;
      if (!link) {
        link = document.createElement('link');
        document.getElementsByTagName('head')[0].appendChild(link);
      }
      link.href = './favicon.ico'
      document.title = 'Ponto de Venda'

      localStorage.setItem('deslogar', 'true')
    },
    [removeConnectedUser, persistToken, persistMeuUsuarioStorage, limparVariaveis],
  );

  const setUserApelido = React.useCallback((apelido: string) => {
    setarUsuario((prev) => ({ ...prev!, apelido: apelido }));
    setMeuUsuario((prev) => ({ ...prev!, apelido }))
  }, []);

  const carregarPlanoUsuario = useCallback(async (tentativa: number): Promise<RetornoApiModel> => {
    return new Promise<RetornoApiModel>(async (resolve, reject) => {
      const retPlano = await getPlanoAtual();
      if (!retPlano.resultado?.data) {
        if (tentativa < 10) {
          setTimeout(() => {
            carregarPlanoUsuario(tentativa + 1)
              .then(e => {
                resolve(e);
              })
              .catch(e => {
                reject(e);
              })
          }, tentativa * 200);
        } else {
          reject(new Error("Seu acesso não está disponível para uso. Por favor, tente realizar o login em alguns instantes. Código: GET-PlanoAtual"));
        }
        return;
      }
      resolve(retPlano);
    });
  }, [getPlanoAtual])

  const refreshPlanoUsuario = useCallback(async (): Promise<void> => {
    //PEGA O PLANO ATUAL
    try {
      const retPlano = await carregarPlanoUsuario(1);
      if (retPlano.erro) {
        throw retPlano.erro;
      }

      if (retPlano.statusCode === 204) {
        persistPlanoUsuario(new PlanoAtualModel());
        setPlano(new PlanoAtualModel());
      } else {
        persistPlanoUsuario(retPlano.resultado?.data as PlanoAtualModel);
        setPlano(retPlano.resultado?.data as PlanoAtualModel);
      }
      const res = retPlano.resultado?.data as PlanoAtualModel

      if (
        !isEmpty(res) &&
        res.contratado.avisoCobranca &&
        res.contratado.avisoCobranca.length > 0 &&
        logouRef.current
      ) {
        abrirAvisoCobrancaDialog()
      }
    } catch (e: any) {
      throw e;
    }
  }, [abrirAvisoCobrancaDialog, carregarPlanoUsuario, persistPlanoUsuario]);

  const validaUsuarioConectado = useCallback(() => {
    const users = getConnectedUsers();
    return users.filter((x) => x.usuarioId === usuario?.usuarioId).length > 0;
  }, [getConnectedUsers, usuario?.usuarioId]);

  const carregarMeuPerfil = useCallback(async (id: string, tentativa: number): Promise<RetornoApiModel> => {
    return new Promise<RetornoApiModel>(async (resolve, reject) => {
      const perfil = await getPerfilById(id);

      if (!perfil.resultado?.data) {
        if (tentativa < 10) {
          setTimeout(() => {
            carregarMeuPerfil(id, tentativa + 1)
              .then(e => {
                resolve(e);
              })
              .catch(e => {
                reject(e);
              })
          }, tentativa * 200);
        } else {
          reject(new Error("Seu acesso não está disponível para uso. Por favor, tente realizar o login em alguns instantes. Código: GET-Perfil"));
        }
        return;
      }
      resolve(perfil);
    });
  }, [getPerfilById])

  const carregarMinhaPessoa = useCallback(async (user: TokenGestaoModel, tentativa: number): Promise<RetornoApiModel> => {
    return new Promise<RetornoApiModel>(async (resolve, reject) => {
      const minhaPessoa = await getUsuarioPessoa(user.usuarioId);
      if (!minhaPessoa.resultado?.data) {
        if (tentativa < 10) {
          setTimeout(() => {
            carregarMinhaPessoa(user, tentativa + 1)
              .then(e => {
                resolve(e);
              })
              .catch(e => {
                reject(e);
              })
          }, tentativa * 200);
        } else {
          reject(new Error("Seu acesso não está disponível para uso. Por favor, tente realizar o login em alguns instantes. Código: GET-MinhaPessoa"));
        }
        return;
      }
      resolve(minhaPessoa);
    });
  }, [getUsuarioPessoa])

  const carregarMeuUsuario = useCallback(async (tentativa: number) => {
    return new Promise<RetornoApiModel>(async (resolve, reject) => {
      const usuario = await getMeuUsuario();

      if (!usuario.resultado?.data) {
        if (tentativa < 10) {
          setTimeout(() => {
            carregarMeuUsuario(tentativa + 1)
              .then(e => {
                resolve(e);
              })
              .catch(e => {
                reject(e);
              })
          }, tentativa * 200);
        } else {
          reject(new Error("Seu acesso não está disponível para uso. Por favor, tente realizar o login em alguns instantes. Código: GET-Usuario"));
        }
        return;
      }

      if (usuario.erro) {
        throw new Error('Não foi possível carregar os dados do usuario.');
      }

      setMeuUsuario(usuario.resultado?.data);
      persistMeuUsuarioStorage(usuario.resultado?.data)

      resolve(usuario);
    });


  }, [getMeuUsuario, persistMeuUsuarioStorage]);

  const carregarDadosPerfisEPermissoes = useCallback(async (user: TokenGestaoModel) => {

    //AGRUPA OS PERFIS
    const perfilIds = Array<string>();
    if (user?.empresa) {
      for (let i = 0; i < user?.empresa.length; i++) {
        if (perfilIds.indexOf(user?.empresa[i]?.Perfil?.Id) === -1) {
          perfilIds.push(user?.empresa[i]?.Perfil?.Id);
        }
      }
    }

    const perfis = new Array<PerfilModel>();
    //FACO A BUSCA NA API PELOS PERFIS DO USUARIO
    for (let i = 0; i < perfilIds.length; i++) {
      const retApiPerfil = await carregarMeuPerfil(perfilIds[i], 1);
      if (retApiPerfil.erro) {
        throw retApiPerfil.erro;
      }
      perfis.push(retApiPerfil.resultado?.data);
    }

    //SALVA OS PERFIL!
    persistPerfilPermissoes(perfis);

    const minhaPessoa = await carregarMinhaPessoa(user, 1);

    //SALVO O PESSOA USUARIO
    persistPessoaUsuario({
      pessoa: minhaPessoa.resultado?.data
    });

  }, [carregarMeuPerfil, carregarMinhaPessoa, persistPerfilPermissoes, persistPessoaUsuario])

  const RequireIp = async () => {
    try {
      const publicIp = require('public-ip');
      const ipv4 = (await publicIp.v4()) || '';
      return ipv4;
    } catch (err: any) {
      return '';
    }
  };

  const localizacaoAtual = useCallback(async () => {
    var options = {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0,
    };
    return new Promise<string>((resolve, reject) => {
      if ('geolocation' in navigator) {
        navigator.geolocation.getCurrentPosition(
          function (loc) {
            resolve(loc?.coords.latitude + ';' + loc?.coords.longitude)
          },
          function (error) {
            reject('denied');
          },
          options,
        );
      } else {
        reject('denied')
      }
    })
  }, [])




  const carregarDadosUsuario = React.useCallback(
    async (user: TokenGestaoModel) => {
      try {
        setLoadingManual(true);

        await carregarDadosPerfisEPermissoes(user);
        await carregarMeuUsuario(1);

        const minhaPessoa = getPessoaUsuario();

        let termosDeUso = false;
        let statusApiTermos = 404;
        const retPolitica = await getPoliticaExistePendente(minhaPessoa.pessoa?.contratoId ?? '');
        if (retPolitica.erro) {
          termosDeUso = false;
        } else if (retPolitica.tipoRetorno === EnumRetornoApiBase.Sucesso) {
          const termosAceitos = useTermosDeUsoStore.getState().state.termosAceitos
          if (termosAceitos) {
            const politica = await getPoliticaPendente(minhaPessoa.pessoa?.contratoId ?? '')
            const politicaId = politica.resultado?.data[0] as PoliticaModel

            const ip = await RequireIp()
            let locAtual = ''
            try {
              locAtual = await localizacaoAtual()
            } catch (err: any) {
              locAtual = ''
            }
            await putPoliticaAceitar({
              aceito: true,
              documento: minhaPessoa.pessoa.cpfcnpj,
              nome: minhaPessoa.pessoa.nome,
              referencia: minhaPessoa.pessoa.contratoId,
              ip: ip ?? '',
              localizacao: locAtual || '',
              termoId: politicaId?.id ?? ''
            })
          }
          else {
            termosDeUso = !retPolitica.resultado?.data;
            statusApiTermos = !termosDeUso ? 400 : 204;
          }
        } else {
          statusApiTermos = retPolitica.statusCode;
        }
        persistTermosDeUso(termosDeUso, statusApiTermos);
        setTermosAceitos(false)

        await refreshPlanoUsuario();
      } catch (e: any) {
        throw e;
      } finally {
        setLoadingManual(false);
      }
    },
    [carregarDadosPerfisEPermissoes, carregarMeuUsuario, getPessoaUsuario, getPoliticaExistePendente, persistTermosDeUso, setTermosAceitos, refreshPlanoUsuario, getPoliticaPendente, putPoliticaAceitar, localizacaoAtual],
  );

  const refreshWhitelabel = React.useCallback(async (user: TokenGestaoModel, token: string) => {
    const storageWhitelabel = getRegistro(GestaoStorageKeys.Whitelabel, false)
    if (isEmpty(storageWhitelabel) || storageWhitelabel.contratoId !== user.empresa[0].ContratoId) {
      const res = await getWhitelabel(token);

      if (res.erro) throw res.erro

      if (res.statusCode === 200) {
        const wl = res.resultado?.data as WhitelabelModel;

        setRegistro(GestaoStorageKeys.Whitelabel, {
          ...wl,
          contratoId: user.empresa[0].ContratoId || ''
        }, false)
      }
    }

  }, [getRegistro, getWhitelabel, setRegistro])

  const refreshUser = React.useCallback(
    async (refreshToken: string): Promise<boolean> => {
      try {
        setLoadingManual(true);

        const retRefresh = await postIdentidadeRefreshToken({ token: refreshToken });

        if (retRefresh?.resultado?.data?.accessToken) {
          let newuser = convertToken(
            retRefresh?.resultado?.data?.accessToken,
          );
          if (newuser) {
            if (validaUsuarioConectado()) {
              addConnectedUser(newuser);
            }
            persistToken(newuser);
            await carregarDadosUsuario(newuser);
            await refreshWhitelabel(newuser, retRefresh?.resultado?.data?.accessToken);
            setarUsuario(newuser);
            await carregarMeuUsuario(1);
            return true;
          }
        }
      } catch (e: any) {
        persistToken(undefined);
        persistPerfilPermissoes(undefined);
        persistPlanoUsuario(undefined);
        persistTermosDeUso(undefined, undefined);
        setPlano(undefined);
        setarUsuario(undefined);
        throw e;
      } finally {
        setLoadingManual(false);
      }
      return false;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const isEmpresaNFCe = React.useCallback(
    async (documento: string): Promise<boolean> => {
      const emissor = await getConfigEmissor(documento || '');
      if (emissor.erro) {
        new Error('Erro ao consultar a situação de NFC-e.');
        return false;
      }

      if (!!(Object.entries(emissor.resultado?.data).length ?? 0) === true) {
        return true;
      }

      return false;
    },
    [getConfigEmissor],
  );

  const getEmpresaConfig = useCallback(
    async (empresaId: string) => {
      const res = await getConfigEmpresaId(empresaId);

      if (res.erro) {
        throw res.erro;
      }

      const data = res.resultado?.data as ConfigEmpresaModel[];

      return data
    },
    [getConfigEmpresaId],
  );

  const getContratoConfig = useCallback(
    async () => {
      try {
        const res = await getConfigContrato(getPessoaUsuario().pessoa?.contratoId || '');

        if (res.erro) {
          throw res.erro;
        }

        const data = res.resultado?.data as ConfigEmpresaModel[];

        return data
      } catch (e: any) {
        showToast('error', e.message)
      }
    },
    [getConfigContrato, getPessoaUsuario, showToast],
  );

  const refreshEmpresa = useCallback(async () => {
    const empresaAtual = getEmpresaAtual()
    const empresaConfig = await getEmpresaConfig(empresaAtual?.id || '')
    const empresa = await getEmpresaId(empresaAtual?.id || '')
    const empresaDados = empresa.resultado?.data
    setRegistro(GestaoStorageKeys.EmpresaAtual, { ...empresaDados, configuracoes: [...empresaConfig] }, false)
  }, [getEmpresaAtual, getEmpresaConfig, getEmpresaId, setRegistro])

  const refreshContrato = useCallback(async () => {
    const contratoId = getPessoaUsuario().pessoa?.contratoId || '';
    const contratoConfig = await getContratoConfig();

    if (contratoConfig) {
      setRegistro(GestaoStorageKeys.ConfigContrato, { id: contratoId, configuracoes: [...contratoConfig] }, false)
    }
  }, [getContratoConfig, getPessoaUsuario, setRegistro])

  const getEmpresaSelecionada = useCallback(():
    | TokenGestaoEmpresaModel
    | undefined => {
    const emp = usuario?.empresa.filter((x) => x.Id === getEmpresaAtual()?.id);
    if (emp === null || emp?.length === 0) return undefined;
    if (emp) return emp[0];

    return undefined;
  }, [getEmpresaAtual, usuario?.empresa]);

  const getEmpresaIdWrapper = useCallback(async (empresaId: string) => {

    const res = await getEmpresaId(empresaId)

    return res.resultado?.data as EmpresaModel

  }, [getEmpresaId])

  const selecionarEmpresa = useCallback(
    async (empresaId: string): Promise<void> => {
      const empresa = await getEmpresaIdWrapper(empresaId)
      const empresaConfig = await getEmpresaConfig(empresaId);
      const nfce = await isEmpresaNFCe(empresa.cpfcnpj);
      const contratoConfig = await getContratoConfig();

      const empresaAtual: EmpresaCompletaModel = {
        ...empresa,
        configuracoes: empresaConfig,
        isFiscal: nfce || false
      }

      if (contratoConfig) {
        setRegistro(GestaoStorageKeys.ConfigContrato, new ContratoConfigCompletoModel(
          usuario?.empresa[0].ContratoId,
          contratoConfig
        ), false)
        callEvent(AppEventEnum.ContratoAtualizado, empresaId)
      }
      setRegistro(GestaoStorageKeys.EmpresaAtual, empresaAtual, false)
      callEvent(AppEventEnum.EmpresaSelecionada, empresaId)
    },
    [callEvent, getContratoConfig, getEmpresaConfig, getEmpresaIdWrapper, isEmpresaNFCe, setRegistro, usuario?.empresa],
  );

  const refreshEmpresaFiscal = useCallback(
    async (): Promise<void> => {
      const empresaAtualStorage = getEmpresaAtual();

      if (!empresaAtualStorage) return;

      const nfce = await isEmpresaNFCe(empresaAtualStorage.cpfcnpj);

      const empresaAtual: EmpresaCompletaModel = {
        ...empresaAtualStorage!,
        isFiscal: nfce || false
      }

      setRegistro(GestaoStorageKeys.EmpresaAtual, empresaAtual, false)
      callEvent(AppEventEnum.EmpresaSelecionada, empresaAtualStorage.id)
    },
    [callEvent, getEmpresaAtual, isEmpresaNFCe, setRegistro],
  );

  const setarUser = useCallback(
    async (token: string, manterConectado: boolean) => {
      let user = convertToken(token);
      const documentoContrato = getRegistro(GestaoStorageKeys.DocumentoContrato) as string | undefined
      const ultimoLogin = getRegistro(GestaoStorageKeys.UltimoLogin, false)

      if (isEmpty(user)) {
        throw new Error(
          'Não foi possível interpretar as credenciais recebidas.',
        );
      }
      if (!isTokenValid(user)) {
        throw new Error(
          'O usuário informado não é válido ou expirou seu acesso. (InvalidToken)',
        );
      }
      //GET NO WHITELABEL E SE TIVER SETO NO OBJ DO USUARIO, JÁ Q N VEM NO TOKEN
      // limpando dados do storage
      limparVariaveis();
      //SALVO NO STORAGE
      persistToken(user);

      //FAZEMOS A CONSULTA DOS DADOS NECESSÁRIOS DO USUARIO
      if (user) {
        await carregarDadosUsuario(user);
        await refreshWhitelabel(user, token);
      }

      if (manterConectado) {
        if (user !== undefined) addConnectedUser(user);
      }



      //SELECIONO A EMPRESA
      const emp = user?.empresa.filter((x) => x.Id === getEmpresaAtual()?.id);

      if (getEmpresaAtual() && (emp === null || emp?.length === 0)) {
        delRegistro(GestaoStorageKeys.UltimoLogin, false);
        delRegistro(GestaoStorageKeys.EmpresaAtual, false);
        delRegistro(GestaoStorageKeys.PDVInicializado, false)
      }

      if ((emp === null || emp?.length === 0) || user?.usuarioId !== ultimoLogin?.usuarioId) {
        //DELETO OS FAVORITOS PQ ELE TROCOU DE USUARIO E ESTÁ EM OUTRO CONTRATO
        delRegistro(GestaoStorageKeys.MovSimplesAtual, false);
        delRegistro(GestaoStorageKeys.VendedorAtual, false)
        delRegistro(GestaoStorageKeys.ConsumidorPadrao, false)
        delRegistro(GestaoStorageKeys.SalaoAtual, false)
        delRegistro(GestaoStorageKeys.PDVInicializado, false)

        //SELECIONA A PRIMEIRA EMPRESA DO CONTRATO
        if (user?.empresa) {
          const termosAceitos = useTermosDeUsoStore.getState().state.termosAceitos
          const empresaList = termosAceitos ? user.empresa : user.empresa.filter(x => x.Documento === documentoContrato)
          if (empresaList.length > 0) {
            await selecionarEmpresa(empresaList[0].Id)
            delRegistro(GestaoStorageKeys.DocumentoContrato)
          } else {
            delRegistro(GestaoStorageKeys.DocumentoContrato)
          }
          if (user.empresa.length === 1) {
            await selecionarEmpresa(user?.empresa[0].Id);
          }
        }
      }
      setRegistro(
        GestaoStorageKeys.UltimoLogin,
        new UltimoLoginModel(
          user?.email,
          user?.saudacao,
          user?.usuarioId,
          getPessoaUsuario().pessoa?.contratoId,
        ),
        false,
      );
      //SETAMOS O USUARIO 
      setarUsuario(user);
    },
    [convertToken, getRegistro, isTokenValid, limparVariaveis, persistToken, getEmpresaAtual, setRegistro, getPessoaUsuario, carregarDadosUsuario, refreshWhitelabel, addConnectedUser, delRegistro, selecionarEmpresa],
  );

  const logar = React.useCallback(
    async (
      usuario: string,
      senha: string,
      contratoId: string = '',
      manterConectado: boolean,
      integracao: boolean = false
    ): Promise<string | any> => {
      try {
        setLoadingManual(true);
        const respAuth = await auth({ usuario, senha, contratoId });

        if (integracao) {
          return respAuth.resultado?.data?.accessToken
        }

        if (respAuth.statusCode === 300) {
          return respAuth.resultado?.data as MultiContratoModel
        }

        //USUARIO NAO CONFIRMADO ESTÁ SEM AUTORIZACAO
        if (respAuth.statusCode === 401) {
          throw new ApiError(respAuth.statusCode, respAuth.erro.message);
        }

        if (respAuth.statusCode === 402) {
          return {
            status: respAuth.statusCode,
            data: respAuth.resultado?.data
          }
        }

        if (respAuth.erro) {
          throw respAuth.erro;
        }

        await setarUser(respAuth?.resultado?.data?.accessToken, manterConectado);
        logouRef.current = true

        return respAuth?.resultado?.data?.accessToken
      } catch (e) {
        throw e;
      } finally {
        setLoadingManual(false);
      }
    },
    [auth, setarUser],
  );


  const getPermissaoBoolean = useCallback(
    (codigo: number): boolean => {
      const emp = getEmpresaSelecionada();
      if (isEmpty(emp)) return false;

      const perfis = getPerfilPermissoes();
      if (isEmpty(perfis)) return false;

      const perfil = perfis.filter((x) => x.id === emp?.Perfil.Id)[0];

      let totPerm = new Array<PerfilPermissaoModel>();
      if (perfil.permissoes) {
        //CRIO UM OBJ APENAS COM OS ARRAYS
        perfil.permissoes.forEach((permissao) => {
          totPerm = totPerm.concat(permissao);
        });
      }

      const filter = totPerm.filter((x) => x.codigo === codigo);
      if (isEmpty(filter)) return false;

      return filter[0].valor === 'true';
    },
    [getEmpresaSelecionada, getPerfilPermissoes],
  );

  const getPermissaoString = useCallback(
    (codigo: number): string => {
      const emp = getEmpresaSelecionada();
      if (isEmpty(emp)) return '';

      const perfis = getPerfilPermissoes();
      if (isEmpty(perfis)) return '';

      const perfil = perfis.filter((x) => x.id === emp?.Perfil.Id)[0];
      if (!perfil.permissoes) return '';

      let totPerm = new Array<PerfilPermissaoModel>();
      //CRIO UM OBJ APENAS COM OS ARRAYS
      if (perfil.permissoes) {
        //CRIO UM OBJ APENAS COM OS ARRAYS
        perfil.permissoes.forEach((permissao) => {
          totPerm = totPerm.concat(permissao);
        });
      }

      const filter = totPerm.filter((x) => x.codigo === codigo);
      if (isEmpty(filter)) return '';

      return filter[0].valor;
    },
    [getEmpresaSelecionada, getPerfilPermissoes],
  );

  const getPermissaoDesconto = useCallback(
    (codigo: number, vDesc: number, tipo: EnumTpDescontoAcrescimo, vtotal: number): boolean => {
      const emp = getEmpresaSelecionada();
      if (isEmpty(emp)) return false;

      const perfis = getPerfilPermissoes();
      if (isEmpty(perfis)) return false;

      const perfil = perfis.filter((x) => x.id === emp?.Perfil.Id)[0];
      if (!perfil.permissoes) return false;

      let totPerm = perfil.permissoes;

      const filter = totPerm.filter((x) => x.codigo === codigo);
      if (isEmpty(filter)) return false;

      const valorMaximo = toDecimal(filter[0].valor, 2)

      if (tipo === EnumTpDescontoAcrescimo.Porcentagem) {
        if (vDesc > valorMaximo) {
          return false;
        }
      }

      if (tipo === EnumTpDescontoAcrescimo.ValorFixo) {
        const valorMaximoDescontoValorFixo = (valorMaximo / 100) * vtotal

        if (vDesc > valorMaximoDescontoValorFixo) {
          return false;
        }
      }


      return true;
    },
    [getEmpresaSelecionada, getPerfilPermissoes],
  )

  const isPlanoAvulso = useCallback(() => {
    if (PlanoCorMock.filter(x => x.id === plano?.plano.id).length > 0) {
      return false
    }
    return true
  }, [plano?.plano.id])

  return (
    <SessaoAtualContext.Provider
      value={{
        usuario,
        meuUsuario,
        setUserApelido,
        plano,
        carregando: loading,
        converterToken,
        validaUsuarioConectado,
        getPerfil: getPerfilPermissoes,
        getPessoa: getPessoaUsuario,
        deslogar,
        sessaoExpirada,
        logar,
        carregarDadosUsuario,
        termosDeUsoAtivo,
        selecionarEmpresa,
        getEmpresaSelecionada,
        refreshUser,
        getPermissaoBoolean,
        getPermissaoString,
        getPermissaoDesconto,
        setarUser,
        refreshPlanoUsuario,
        refreshEmpresa,
        refreshEmpresaFiscal,
        isPlanoAvulso,
        refreshContrato,
        carregarDadosPerfisEPermissoes,
      }}
    >
      {children}
    </SessaoAtualContext.Provider>
  );
};
