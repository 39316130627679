import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';
import { VariaveisAmbiente } from 'config';
import { useSessaoAtual } from 'services/app';

export class KeyStorePostModel {
    constructor(
        public chave: string = '',
        public senha: string = '',
        public valor: string = '',
        public referencia: string = '',
        public descricaoChave: string = '',
        public descricao: string = '',
    ) { }
}

export function usePostKeyStore() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const { getEmpresaSelecionada } = useSessaoAtual();

    const postKeyStore = useCallback(
        (model: KeyStorePostModel) => {

            model.referencia = getEmpresaSelecionada()?.Id || '';

            return invocarApi({
                url: `/v1/KeyValueStore`,
                method: 'POST',
                data: model,
                enviarTokenUsuario: true,
                baseURL: `${VariaveisAmbiente.apiKeyStore}/api`
            })
        },
        [getEmpresaSelecionada, invocarApi],
    );
    return {
        ...outrasPropriedades,
        postKeyStore,
    };
}