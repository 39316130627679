import { PesquisaHeaderProps } from './pesquisa-valor-props';
import { useStyles } from './pesquisa-valor-styles';
import { Fade, useTheme } from '@material-ui/core';
import { useCallback, useRef, useEffect, useMemo } from 'react';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { ProcurarIcon } from '../../../../../../../components/icons';
import { useThemeQueries } from 'views';
import { Controller, useForm } from 'react-hook-form';
import { useFormPesquisaValorValidation } from './pesquisa-valor-validations';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Grid } from 'views/design-system';

type PesquisaForm = {
  pesquisa: string
}

export const PesquisaValor = (props: PesquisaHeaderProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const { isMobile } = useThemeQueries();
  const refInput = useRef<HTMLDivElement | null>(null);

  const { FormPesquisaValorYupValidation } = useFormPesquisaValorValidation()

  const {
    handleSubmit,
    control,
    formState: { errors, touchedFields },
    reset,
  } = useForm<PesquisaForm>({
    resolver: yupResolver(FormPesquisaValorYupValidation),
    criteriaMode: 'all',
    mode: 'onChange',
  });

  //FOCO AO FICAR VISIVEL
  useEffect(() => {
    if (props.isOpened && !isMobile) refInput.current?.focus();
    else {
      reset();
    }
  }, [isMobile, props.isOpened, reset]);

  //CHAMADA DO PESQUISAR
  const onSubmit = useCallback(
    (value: PesquisaForm) => {
      props.onPesquisar({ termo: value.pesquisa });
    },
    [props],
  );

  const btnPesquisa = useMemo(() => (
    <Button
      color="primary"
      variant="contained"
      type="submit"
      className={classes.btnPesquisa}
      fullWidth={true}
    >
      <ProcurarIcon tipo="BUTTON_PRIMARY" />
    </Button>
  ), [classes.btnPesquisa])

  return (
    <Fade in={props.isOpened} mountOnEnter unmountOnExit>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container className={classes.root} spacing={1}>
          <Grid item xs={12}>
            <div className={classes.containerFinal}>
              <Controller
                control={control}
                name='pesquisa'
                render={({ field }) => (
                  <TextFieldSaurus
                    inputRef={refInput}
                    fullWidth
                    size="small"
                    allowSubmit={true}
                    placeholder="Digite algo..."
                    {...field}
                    adornmentColor={theme.palette.text.disabled}
                    error={Boolean(
                      errors.pesquisa && errors.pesquisa.message,
                    )}
                    helperText={
                      touchedFields.pesquisa || errors.pesquisa
                        ? errors.pesquisa?.message
                        : undefined
                    }
                  />
                )}
              />
              {btnPesquisa}
            </div>
          </Grid>
        </Grid>
      </form>
    </Fade>
  );
};
