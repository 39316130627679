import { Grow } from '@material-ui/core';
import { CardDepositoEstoque } from 'views/components/cards/card-deposito-estoque';
import { DepositoListModel } from '../produto-estoque';
import { CorrigirIcon, DepositoAddIcon } from 'views/components/icons';
import { IrAoCarrinhoIcon } from 'views/components/icons/ir-ao-carrinho-icon';
import { useStyles } from '../produto-estoque-styles';
import { Button, Grid } from 'views/design-system';

type Props = {
  depositos: Array<DepositoListModel>;
  onClick: (id: string) => void;
  mudarAcao: (acao: number) => void;
};
export const DepositosEstoque = ({ depositos, onClick, mudarAcao }: Props) => {
  const classes = useStyles();
  return (
    <Grow in={true}>
      <Grid container spacing={1} className={classes.depositoEstoque}>
        {depositos.map((item) => {
          return (
            <Grid item xs={12}>
            
              <CardDepositoEstoque model={item}>
                <>
                  <Grid item xs={4}>
                    <Button
                      variant="outlined"
                      color="primary"
                      size="medium"
                      fullWidth
                      style={{ height: '100%' }}
                      onClick={() => {
                        onClick(item.id);
                        mudarAcao(1);
                      }}
                    >
                      <CorrigirIcon tipo="BUTTON" />
                      Corrigir Saldo
                    </Button>
                  </Grid>
                  <Grid item xs={4}>
                    <Button
                      variant="outlined"
                      color="primary"
                      size="medium"
                      fullWidth
                      style={{ height: '100%' }}
                      onClick={() => {
                        onClick(item.id);
                        mudarAcao(2);
                      }}
                    >
                      <DepositoAddIcon tipo="BUTTON" />
                      Entrada de Estoque
                    </Button>
                  </Grid>
                  <Grid item xs={4}>
                    <Button
                      variant="outlined"
                      color="primary"
                      size="medium"
                      fullWidth
                      style={{ height: '100%' }}
                      onClick={() => {
                        onClick(item.id);
                        mudarAcao(3);
                      }}
                    >
                      <IrAoCarrinhoIcon tipo="BUTTON" />
                      Saída de Estoque
                    </Button>
                  </Grid>
                </>
              </CardDepositoEstoque>
            </Grid>
          );
        })}
      </Grid>
    </Grow>
  );
};
