import {
  FormControlLabel,
} from '@material-ui/core';
import { FiltroMesa } from 'model/api/gestao/mesa/mesa-filtro';
import { memo, useState } from 'react';
import { SaurusRadio } from 'views/components/controles/radio/saurus-radio';
import { FiltroIcon } from 'views/components/icons';
import { useThemeQueries } from 'views/theme';
import { useStyles } from '../footer-styles';
import classNames from 'classnames';
import { Button, Divider, Grid, Tooltip, Typography } from 'views/design-system';

interface FooterMesaListProps {
  emAtendimento: number;
  vazias: number;
  ociosas: number;
  tooltipFiltro: string;
  setFiltroMesa: (value: FiltroMesa) => void;
  filtroMesa: FiltroMesa;
}

export const FooterMesaList = ({
  emAtendimento,
  ociosas,
  vazias,
  tooltipFiltro,
  filtroMesa,
  setFiltroMesa,
}: FooterMesaListProps) => {
  const [isShowFilter, setIsShowFilter] = useState(false);
  const classes = useStyles();
  const { isMobile } = useThemeQueries();

  return (
    <Grid className={classes.footerContainer} style={{ height: '60px' }}>
      {isShowFilter && (
        <div
          className={classes.arrowDown}
          style={{ right: isMobile ? '3%' : '7%' }}
        >
          <div className={classes.containerArrow}>
            <div className={classes.filtroContainer}>
              <div className={classes.filtroItem}>
                <p className={classes.textFiltroItem}>Sem Filtro</p>
                <FormControlLabel
                  label=""
                  checked={filtroMesa.semFiltro}
                  onClick={() => {
                    setFiltroMesa({
                      semFiltro: true,
                      meusAtendimentos: false,
                      masasVazias: false,
                      mesasOciosas: false,
                    });
                    setIsShowFilter(false);
                  }}
                  control={<SaurusRadio />}
                />
              </div>
              <Divider className={classes.divider} style={{ height: '1px' }} />
              <div className={classes.filtroItem}>
                <p className={classes.textFiltroItem}>Meus Atendimentos</p>
                <FormControlLabel
                  label=""
                  checked={filtroMesa.meusAtendimentos}
                  onClick={() => {
                    setFiltroMesa({
                      semFiltro: false,
                      meusAtendimentos: true,
                      masasVazias: false,
                      mesasOciosas: false,
                    });
                    setIsShowFilter(false);
                  }}
                  control={<SaurusRadio />}
                />
              </div>
              <Divider className={classes.divider} style={{ height: '1px' }} />
              <div className={classes.filtroItem}>
                <p className={classes.textFiltroItem}>Mesas Vazias</p>
                <FormControlLabel
                  label=""
                  checked={filtroMesa.masasVazias}
                  onClick={() => {
                    setFiltroMesa({
                      semFiltro: false,
                      meusAtendimentos: false,
                      masasVazias: true,
                      mesasOciosas: false,
                    });
                    setIsShowFilter(false);
                  }}
                  control={<SaurusRadio />}
                />
              </div>
              <Divider className={classes.divider} style={{ height: '1px' }} />
              <div className={classes.filtroItem}>
                <p className={classes.textFiltroItem}>Mesas Ociosas</p>
                <FormControlLabel
                  label=""
                  checked={filtroMesa.mesasOciosas}
                  onClick={() => {
                    setFiltroMesa({
                      semFiltro: false,
                      meusAtendimentos: false,
                      masasVazias: false,
                      mesasOciosas: true,
                    });
                    setIsShowFilter(false);
                  }}
                  control={<SaurusRadio />}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      <Divider className={classes.divider} />

      <Grid className={classes.itemsFooterContainer}>
        <Grid className={classes.itemsFooter}>
          <Typography color="primary">Em Atendimento</Typography>
          <Typography className={classes.textTotalInfo} color="textPrimary">
            {emAtendimento}
          </Typography>
        </Grid>
        <Grid className={classes.itemsFooter}>
          <Typography color="primary">Vazias</Typography>
          <Typography className={classes.textTotalInfo} color="textPrimary">
            {vazias}
          </Typography>
        </Grid>
        <Grid className={classes.itemsFooter}>
          <Typography color="primary">Ociosas</Typography>
          <Typography className={classes.textTotalInfo} color="textPrimary">
            {ociosas}
          </Typography>
        </Grid>
        <Grid className={classes.itemsFooter}>
          <Tooltip arrow title={tooltipFiltro} placement="right">
            <Button
              variant="contained"
              className={classNames(classes.button, 'round')}
              onClick={() => setIsShowFilter(!isShowFilter)}
            >
              <Grid>
                <FiltroIcon tipo="BUTTON_PRIMARY" />
                <Typography
                  variant="caption"
                  className={classes.textButtonFiltro}
                  color="primary"
                >
                  Filtro
                </Typography>
              </Grid>
            </Button>
          </Tooltip>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default memo(FooterMesaList);
