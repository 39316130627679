import { CardProdutoEntradaProps } from './card-produto-entrada-props';
import { DefaultCard } from '../components/default-card';
import { useDefaultCardStyles } from '../components/default-card/default-card-styles';
import { toDecimalString } from '../../../../utils/to-decimal';
import { LixoIcon } from 'views/components/icons';
import { useStyles } from './card-produto-entrada-styles'
import { Tooltip, Typography } from 'views/design-system';

export const CardProdutoEntradaMobile = ({
  model,
  onClick,
  onRemove,
  selected,
}: CardProdutoEntradaProps) => {
  const classes = useDefaultCardStyles();
  const cardClasses = useStyles();

  const vFinal = model.vProd + model.vFrete + model.vSeg + model.vOutro - model.vDesc

  return (
    <>
      <DefaultCard
        isSelected={selected}
        onClick={async () => {
          onClick(model.id);
        }}
      >
        <div className={classes.cardContentMobile}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex' }}>
              {model.cEan.length > 0 && (
                <div className={classes.celulaGrid}>
                  <Typography color="textPrimary" variant="caption">
                    Código
                  </Typography>
                  <Typography
                    className={'celula-grid-value'}
                    color="textPrimary"
                    variant="body1"
                  >
                    <strong>{model.cEan}</strong>
                  </Typography>
                </div>
              )}
            </div>
            <div className={classes.celulaGrid} style={{ textAlign: 'center' }}>
              <Typography color="textPrimary" variant="caption">
                Qtde
              </Typography>
              <Typography
                className={'celula-grid-value'}
                color="textPrimary"
                variant="body1"
              >
                <strong>
                  {toDecimalString(model.qCom, 3)}
                </strong>
              </Typography>
            </div>
            <div className={classes.celulaGrid} style={{ textAlign: 'right' }}>
              <Typography color="textPrimary" variant="caption">
                Valor {model.uCom}
              </Typography>
              <Typography
                className={'celula-grid-value'}
                color="textPrimary"
                variant="body1"
              >
                <div>
                  R$ {toDecimalString(model.vUnCom)} {model.uCom}
                </div>
              </Typography>
            </div>
            <div className={classes.celulaGrid} style={{ textAlign: 'right' }}>
              <Typography color="textPrimary" variant="caption">
                Valor Total
              </Typography>
              <Typography
                className={'celula-grid-value'}
                color="textPrimary"
                variant="body1"
              >
                <Tooltip
                  arrow
                  title={
                    'R$ ' +
                    toDecimalString(vFinal) +
                    ' a cada ' +
                    model.uCom
                  }
                  placement="right"
                >
                  <strong>
                    R$ {toDecimalString(vFinal)}
                  </strong>
                </Tooltip>
              </Typography>
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              flexWrap: 'wrap',
            }}
          >
            <div className={classes.celulaGridFullMobile}>
              <Typography color="textPrimary" variant="caption">
                Nome
              </Typography>
              <Typography
                className={'celula-grid-value'}
                color="textPrimary"
                variant="body1"
              >
                {model.xProd}
              </Typography>
            </div>

            {model?.ncm && model.ncm.length > 0 && (
              <div
                className={classes.celulaGrid}
                style={{ textAlign: 'center' }}
              >
                <Typography color="textPrimary" variant="caption">
                  NCM
                </Typography>
                <Typography
                  className={'celula-grid-value'}
                  color="textPrimary"
                  variant="body1"
                >
                  {model.ncm}
                </Typography>
              </div>
            )}
          </div>
        </div>
        <Tooltip arrow title="Excluir Produto">
          <div className={cardClasses.removeIcon} onClick={(e) => {
            e.stopPropagation();
            onRemove(model.id);
          }}>
            <LixoIcon tipo="BUTTON" />
          </div>
        </Tooltip>
      </DefaultCard>
    </>
  );
};
