import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    card: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: theme.spacing(2),
        width: '100%',
        boxSizing: 'border-box',
        padding: theme.spacing(2),
        minHeight: 155,
        height: '100%',
        boxShadow: '0px 2.5px 4px 0px #28293D40',
        borderRadius: theme.shape.borderRadius,
        background: theme.palette.background.paper,
        cursor: 'pointer',
        '& svg': {
            fill: theme.palette.primary.main,
            height: 52,
            width: 52,
            margin: 0
        },
        '& p': {
            fontSize: '14px'
        }
    },
    iconCard: {
        '& svg': {
            fill: theme.palette.secondary.main
        }
    },
    container: {
        padding: theme.spacing(2),
        height: '100%',
        background: theme.palette.background.default,
        flex: 1
    },
    cardContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    placeholder: {
        border: 'none',
        width: '100%',
        lineHeight: '50px',

        '& .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root:hover, .MuiOutlinedInput-notchedOutline': {
            borderColor: 'transparent'
        },
        textAlign: 'center',
        textAlignLast: 'center',
        '& input': {
            justifyContent: 'center',
            lineHeight: '50px',
            backgroundColor: 'transparent',
            fontFamily: 'Montserrat',
            color: '#666'
        },
        '& :after': {
            border: 'none'
        },
        '& :focus': {
            outline: 'none'
        }

    },
    inputText: {
        height: 100,
        border: 'none',
        outline: 'none'
    },
    title: {
        fontSize: '18px',
        lineHeight: '24px',
        fontWeight: 600
    },
    inputArea: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: 260,
        maxWidth: 395,
        borderBottom: '1px solid #D0D0D0',
    },
    content: {
        marginTop: 16,
        marginBottom: 16,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row'
    },
    keyboardContainer: {
        paddingTop: 10,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        '& .teclado': {
            display: 'flex',
            flexGrow: 1
        }
    },
    buttonContainer: {
        display: 'flex',
        gridGap: theme.spacing(2),
        '& button': {
            flex: 1
        }
    },
    buttonFlexWrap: {
        [theme.breakpoints.down('sm')]: {
            flexWrap: 'wrap'
        }
    },
    formContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        height: '100%',
        flexDirection: 'column',
    },
    hasValue: {
        fontSize: '26px',
        lineHeight: '40px',
        color: theme.palette.primary.main,
        fontWeight: 600,
    },
    noValue: {
        fontSize: '16px',
        lineHeight: '40px',
    },
    cardTitle: {
        margin: 0,
        fontWeight: 500
    },
    hasValueInput: {
        fontSize: '32px'
    },
    hidden: {
        display: 'none'
    },
    buttonsInputAlt: {
        flexGrow: 1,
        alignItems: 'flex-end'
    },
    hiddenKeyBoard: {
        display: 'none'
    },
    colarIcon: {
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1),
        width: theme.spacing(5),
        height: theme.spacing(5),
        borderRadius: '100%',
        padding: '0',
        "& svg": {
            width: theme.spacing(3),
            height: theme.spacing(3),
            fill: theme.palette.primary.main,
        }
    }
}));
