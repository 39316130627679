import * as Yup from "yup";
import { useMemo } from "react";
import { toCurrency } from "utils/to-decimal";

interface Props {
    vMinParcela: number;
}

export const useFormVencimentoPagamentoValidation = ({ vMinParcela }: Props) => {
    const FormVencimentoPagamentoYupValidation = useMemo(() => {
        return Yup.object().shape({
            valor: Yup.number().min(vMinParcela, 'Valor mínimo de ' + toCurrency(vMinParcela)).required('Valor é obrigatório.'),
            dataVencimento: Yup.string().required('Informe uma Data de Vencimento'),
        });
    }, [vMinParcela]);

    return {
        FormVencimentoPagamentoYupValidation,
    };
};
