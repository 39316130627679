import { ButtonFiltroProps } from "./button-filtro-props";
import { MaisIcon } from "views/components/icons";
import { useStyles } from "./button-filtro-styles";
import classNames from "classnames";
import { CloseIconCircle } from "views/components/icons/close-icon-circle";
import { Button, Grid, Typography } from "views/design-system";

export const ButtonFiltro = ({
    handleRemove,
    titulo,
    vFiltro,
    entrarPagina,
    obrigatorio,
    ...props
}: ButtonFiltroProps) => {
    const classes = useStyles()
    return (
        <Button fullWidth variant="text" classes={{
            label: classes.btnLabel
        }}>
            <Grid container onClick={entrarPagina} alignItems="center" justifyContent="space-between" flex>
                <Grid item flex alignItems="center">
                    <props.icon class={classes.icon} />
                    <Typography>
                        {titulo}
                    </Typography>
                </Grid>
                <Grid item flex alignItems="center">
                    {vFiltro && (
                        <Typography>
                            {vFiltro}
                        </Typography>
                    )}

                </Grid>
            </Grid>
            <Button className={classes.btnLixeira} onClick={vFiltro ? handleRemove : entrarPagina}>
                {vFiltro ? (
                    <CloseIconCircle tipo='GERAL' class={classes.icon} />
                ) : (
                    <MaisIcon tipo="BUTTON" class={classNames(classes.icon, classes.iconMais)} />
                )}
            </Button>
        </Button>
    )
}