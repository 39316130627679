import { CardLoteProps } from './card-lote-props';
import { DefaultCard } from '../components';
import { useStyles } from './card-lote-styles';
import { SelectSaurus } from 'views/components/controles/selects/select-saurus/select-saurus';
import { useCallback, useMemo } from 'react';
import { KeyValueModel } from 'model';
import { toDateString } from 'utils/to-date';
import { Grid, Typography } from 'views/design-system';

export const CardLote = ({
  model,
  onClick,
  isMobile,
  disabled,
  quantidadeSelecionada,
  ...props
}: CardLoteProps) => {
  const cardClasses = useStyles();

  const quantidadeRastro = useMemo(() => {
    const quantidadeMenosDispensacao = model.quantidade - (model?.quantidadeBloqueadaDispensacao ?? 0)

    return quantidadeMenosDispensacao <= 0 ? 0 : quantidadeMenosDispensacao
  }, [model.quantidade, model?.quantidadeBloqueadaDispensacao])

  const quantidadeTotalSelect = useCallback(() => {
    let quantidade: number[] = []
    for (let i = 0; i <= quantidadeRastro; i++) {
      quantidade.push(i)
    }

    return quantidade.map(x => new KeyValueModel(x, x.toString()))
  }, [quantidadeRastro])

  return (
    <>
      <DefaultCard
        isSelected={false}
      >
        <Grid container spacing={1} style={{ width: '100%', padding: 8, alignItems: 'center' }}>
          <Grid item xs>
            <Typography color="textPrimary" variant="caption" className={cardClasses.label}>
              Lote
            </Typography>
            <Typography
              color="textPrimary"
              className={cardClasses.textConteudo}
            >
              {model.lote}
            </Typography>
          </Grid>
          <Grid item xs>
            <Typography color="textPrimary" variant="caption" className={cardClasses.label}>
              Validade
            </Typography>
            <Typography
              color="textPrimary"
              className={cardClasses.textConteudo}
            >
              {toDateString(model.dataValidade) ?? ''}
            </Typography>
          </Grid>
          <Grid item xs>
            <Typography color="textPrimary" variant="caption" className={cardClasses.label}>
              Quantidade Total
            </Typography>
            <Typography
              color="textPrimary"
              className={cardClasses.textConteudo}
            >
              {quantidadeRastro}
            </Typography>
          </Grid>
          <Grid item xs="auto">
            <SelectSaurus
              conteudo={quantidadeTotalSelect().filter(x => {
                if (quantidadeSelecionada === 0) {
                  return x.Key <= props.quantidadeDisponivel
                } else {
                  return x.Key <= quantidadeSelecionada
                }
              })}
              value={quantidadeSelecionada ?? 0}
              onChange={event => {
                const value = quantidadeTotalSelect().filter(x => x.Key === event.target.value)
                if (value.length > 0) {
                  onClick(model, value[0].Key);
                }
              }}
              disabled={disabled && quantidadeSelecionada === 0}
            />
          </Grid>
        </Grid >
      </DefaultCard >
    </>
  );
};

