import { CardDepositoProps } from './card-deposito-estoque-props';
import { DefaultCard } from '../components/default-card';
import { useStyles } from './card-deposito-estoque-styles';
import { Box, Grid, Typography } from 'views/design-system';

export const CardDepositoEstoque = ({ model, children }: CardDepositoProps) => {
  const classes = useStyles();

  return (
    <DefaultCard className={classes.card}>
      <Box className={classes.head}>
        <Typography variant="h6" color="textPrimary">
          {model?.nome ?? 'Sem nome'}
        </Typography>
        <Typography variant="h6" color="textPrimary">
          Saldo: <strong>{model.qSaldo}</strong>
        </Typography>
      </Box>
      <Grid container spacing={1} className={classes.options}>
        {children}
      </Grid>
    </DefaultCard>
  );
};
