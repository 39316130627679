import { OkIcon, VoltarIcon } from '../../icons';
import { CircularLoading, useThemeQueries } from '../../..';
import { useCallback, useEffect, useRef } from 'react';
import { DefaultFormRefs } from 'views/components/form/utils';
import { useStyles } from './dialog-adicionar-processos-styles';
import { DialogSaurus } from '../dialog-saurus/dialog-saurus';
import { FormProcessosNovo } from 'views/components/form/processos/form-processos-novo/form-processos-novo';
import {
  ProcessosFormModel,
} from 'model/api/gestao/processos/processos-model';
import { usePostAdicionarProcesso } from 'data/api/gestao/processos/post-adicionar-processo';
import { useToastSaurus } from 'services/app';
import { useSessaoAtual } from 'services/app';
import { DialogAdicionarProcessosProps } from './dialog-adicionar-processos-props';
import { Button, Grid } from 'views/design-system';

export const DialogAdicionarProcessos = ({
  close,
  carregando,
  aberto,
  processoSetor,
  processoAdicionado,
}: DialogAdicionarProcessosProps) => {
  const refInputXProd = useRef<HTMLInputElement>(null);
  const classesComponent = useStyles();

  const { getEmpresaSelecionada } = useSessaoAtual();
  const { postAdicionarProcesso, carregando: setPostAdicionarProcesso } =
    usePostAdicionarProcesso();
  const { showToast } = useToastSaurus();
  const { isMobile } = useThemeQueries();

  const loading = setPostAdicionarProcesso || carregando;

  useEffect(() => {
    if (aberto && !isMobile) {
      setTimeout(() => {
        refInputXProd.current?.focus();
      }, 50);
    }
  }, [aberto, isMobile]);

  const cadProcessoFormRef = useRef<DefaultFormRefs<ProcessosFormModel>>(null);
  const redirect = useRef<boolean>(true);

  const saveProcesso = useCallback(
    async (model: ProcessosFormModel) => {
      try {
        const ret = await postAdicionarProcesso(
          model,
          getEmpresaSelecionada()?.Id || '',
          processoSetor.setor?.id ?? '',
          processoSetor.id,
        );

        if (ret.erro) {
          throw ret.erro;
        }

        showToast('success', 'Processo adicionado');
        processoAdicionado.current = true;
        close();
      } catch (e: any) {
        showToast('error', e.message);
        cadProcessoFormRef.current?.fillForm(model);
      }
    },
    [close, getEmpresaSelecionada, postAdicionarProcesso, processoAdicionado, processoSetor?.id, processoSetor?.setor?.id, showToast],
  );

  const handleSubmit = useCallback(
    async (model: ProcessosFormModel) => {
      try {

        await saveProcesso(model);
      } catch (e: any) {
        showToast('error', e.message);
      }
    },
    [saveProcesso, showToast],
  );

  const submitForm = useCallback((redirectToEdit: boolean) => {
    redirect.current = redirectToEdit;
    cadProcessoFormRef.current?.submitForm();
  }, []);

  useEffect(() => {
    cadProcessoFormRef.current?.resetForm();
  }, []);

  const onCloseClick = useCallback(() => {
    close();
  }, [close]);

  return (
    <DialogSaurus
      aberto={aberto || false}
      titulo="Adicionar Processo"
      tamanho="sm"
    >
      {loading && <CircularLoading tipo="FULLSIZED" />}
      <Grid className={classesComponent.cardContainer}>
        <Grid className={classesComponent.cardContent}>
          <FormProcessosNovo
            ref={cadProcessoFormRef}
            showLoading={false}
            loading={loading ?? false}
            onSubmit={handleSubmit}
          />
          <Grid container className={classesComponent.acoes} spacing={2}>
            <Grid item xs={5}>
              <Button
                disabled={loading}
                onClick={onCloseClick}
                variant="outlined"
                color="primary"
                fullWidth
              >
                <VoltarIcon tipo="BUTTON" />
                Voltar
              </Button>
            </Grid>
            <Grid item xs={7}>
              <Button
                disabled={loading}
                onClick={() => {
                  submitForm(false);
                }}
                variant="contained"
                color="primary"
                fullWidth
              >
                <OkIcon tipo="BUTTON_PRIMARY" />
                Confirmar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </DialogSaurus>
  );
};
