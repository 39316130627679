import { forwardRef, useImperativeHandle } from 'react';
import { TextFieldSaurus } from '../../../controles/inputs';
import { makeUtilClasses } from '../../../../theme';
import { CircularLoading } from '../../../utils/circular-loading/circular-loading';
import {
    DefaultFormProps,
    DefaultFormRefs,
} from '../../utils/form-default-props';
import { Controller, useForm } from 'react-hook-form';
import { ManifestoAcaoModel } from 'model/api/gestao/manifesto/manifesto-acao-model';
import { useFormSituacaoManifestoAcaoValidation } from './form-situacao-manifesto-acao-validation';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Grid } from 'views/design-system';

export const FormSituacaoManifestoAcao = forwardRef<
    DefaultFormRefs<ManifestoAcaoModel>,
    DefaultFormProps<ManifestoAcaoModel>
>((props: DefaultFormProps<ManifestoAcaoModel>, ref) => {


    const { FormSituacaoManifestoAcaoValidation } = useFormSituacaoManifestoAcaoValidation();

    const utilClasses = makeUtilClasses();
    const {
        handleSubmit,
        control,
        reset,
        formState: { errors }
    } = useForm<ManifestoAcaoModel>({
        defaultValues: { ...props.model },
        criteriaMode: 'all',
        mode: 'onChange',
        resolver: yupResolver(FormSituacaoManifestoAcaoValidation)
    });

    const onSubmit = (values: ManifestoAcaoModel) => {
        props.onSubmit(values, props.model);
    };

    useImperativeHandle(ref, () => ({
        submitForm: async () => {
            await handleSubmit(onSubmit)();
        },
        resetForm: () => {
            reset({ ...props.model });
        },
        fillForm: (model: ManifestoAcaoModel) => {
            reset({ ...model });
        },
    }));

    return (
        <>
            <div className={utilClasses.formContainer}>
                {props.loading && props.showLoading ? (
                    <div className={utilClasses.controlLoading}>
                        <CircularLoading tipo="NORMAL" />
                    </div>
                ) : null}

                <form
                    onSubmit={handleSubmit(onSubmit)}
                    className={props.loading ? utilClasses.controlLoading : ''}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Controller
                                name='justificativa'
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        label='Informe o Motivo'
                                        variant='outlined'
                                        multiline
                                        minRows={6}
                                        tipo='TEXTO'
                                        autoComplete='new-password'
                                        fullWidth
                                        {...field}
                                        error={Boolean(errors.justificativa)}
                                        helperText={Boolean(errors.justificativa) ? errors.justificativa?.message : undefined}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                    <Button style={{ display: 'none' }} type="submit"></Button>
                </form>
            </div>
        </>
    );
});