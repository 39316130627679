import { Box, Typography } from "views/design-system";
import { DialogSaurus } from "../../dialog-saurus/dialog-saurus";

interface DialogPlanoCustomizadoProps {
    openned: boolean;
    closeDialog: () => void;
}

export const DialogHelpPlano = ({ openned, closeDialog }: DialogPlanoCustomizadoProps) => {


    return (
        <DialogSaurus
            aberto={openned}
            titulo='Ajuda'
            tamanho='xs'
            isButtonTitleClose
            handleClickClose={() => closeDialog()}
        >
            <Box p={1}>
                <Typography>
                    Os módulos são calculados de acordo com um valor fixo e um percentual adicional. Para módulos
                    que que possuem quantidade variada, o primeiro item sempre terá o valor do módulo, já os próximos irão
                    seguir o cálculo do percentual adicional. Caso o percentual seja 50% por exemplo, o segundo item terá 50%
                    do valor original, e assim sucessivamente.
                </Typography>
            </Box>
        </DialogSaurus>
    )
}