import { CircularLoading } from "views/components"
import { PrivatePageHeader } from "views/components/headers"
import { useDefaultCadastroStyles } from "views/pages/private/cadastros/components/default-cadastro-styles"
import { ButtonPrivateHeader } from "views/components/controles"
import { useCallback, useEffect, useState } from "react"
import { AvancarIcon, InformacaoIcon, NovoIcon, VoltarIcon } from "views/components/icons"
import { useHistory, useLocation, useParams } from "react-router-dom"
import { VendaCompletaModel } from "model/api/gestao/venda/venda-completa-model"
import { useToastSaurus } from "services/app"
import { useStyles } from "./dados-produto-styles"
import { CardProdutoEntrada } from "views/components/cards/card-produto-entrada"
import { useThemeQueries } from "views/theme"
import { CardProdutoEntradaMobile } from "views/components/cards/card-produto-entrada/card-produto-entrada-mobile"
import classNames from "classnames"
import { CardNaoEncontrado } from "views/components/cards"
import { isEmpty } from "lodash"
import { useEntrada } from "../../hooks/entrada"
import { ManifestoNNFModel } from "model/api/gestao/manifesto/manifesto-model"
import { useConfirm } from "material-ui-confirm"
import { Box, Button, Grid } from "views/design-system"
import { Container } from "@material-ui/core"

const DadosProdutoPage = () => {

    // hooks e utils
    const cadClasses = useDefaultCadastroStyles()
    const classes = useStyles()
    const { isMobile } = useThemeQueries()
    const history = useHistory()
    const location = useLocation<{ mov?: VendaCompletaModel | null, manifesto: ManifestoNNFModel | null, dtManifesto: string | null }>()
    const { movId } = useParams<{ movId: string }>()
    const { showToast } = useToastSaurus()
    const confirm = useConfirm();

    //state
    const [mov, setMov] = useState<VendaCompletaModel>(new VendaCompletaModel())

    const { getVendaCompleta, saveChangesVendaCompleta, carregando } = useEntrada(mov)

    const getInfoVenda = useCallback(async (att: boolean = false) => {
        try {
            let movAtual = new VendaCompletaModel()
            if (location.state?.mov && !att) {
                movAtual = location.state.mov
            } else {
                movAtual = await getVendaCompleta(movId)
            }

            setMov(movAtual)
        } catch (err: any) {
            showToast('error', err.message)
        }
    }, [getVendaCompleta, location.state?.mov, movId, showToast])

    const leftArea = useCallback(
        () => (
            <ButtonPrivateHeader icon={<VoltarIcon tipo="PRIVATE_HEADER" />} tooltip="Menu" onClick={() => history.push({
                pathname: `/entrada-mercadoria/visualizar/${movId}`,
                search: location.search,
                state: {
                    mov,
                    manifesto: location.state?.manifesto,
                    dtManifesto: location.state?.dtManifesto
                }
            })}></ButtonPrivateHeader >
        ),
        [history, location.search, location.state, mov, movId]
    );

    useEffect(() => {
        getInfoVenda()
    }, [getInfoVenda])

    const onClick = (prodId: string) => {
        history.push({
            pathname: `/entrada-mercadoria/visualizar/${movId}/produtos/${prodId}`,
            search: location.search,
            state: {
                mov,
                manifesto: location.state?.manifesto,
                dtManifesto: location.state?.dtManifesto
            }
        })
    }

    const onClickAdd = () => {
        history.push({
            pathname: `/entrada-mercadoria/visualizar/${movId}/produtos/adicionar`,
            search: location.search,
            state: {
                mov,
                manifesto: location.state?.manifesto,
                dtManifesto: location.state?.dtManifesto
            }
        })
    }

    const handleRemoveProduto = async (prodId: string) => {
        try {
            const movAtual = structuredClone(mov) as VendaCompletaModel
            if (!movAtual.infMov.prod) return;

            const prods = movAtual.infMov.prod.filter(x => prodId !== x.id)
            movAtual.infMov.prod = prods

            if (mov.infMov.pag.length > 0) {
                movAtual.infMov.pag = []
                movAtual.infMov.cobr.dup = []
            }

            await saveChangesVendaCompleta(movAtual)

            showToast('info', 'Produto removido com sucesso')
            getInfoVenda(true);
        } catch (error: any) {
            showToast('error', error.message)
        }
    }

    const clickRemove = (prodId: string) =>  confirm({
        title: 'Confirmação de Remoção de Produto',
        description: `Deseja remover este produto? Esta ação não poderá ser desfeita futuramente. ${mov.infMov.pag.length > 0 ? 'Também há pagamentos já realizados, se você remover este produto, todos os pagamentos serão deletados.' : ''} Deseja confirmar?`,
        cancellationText: 'Cancelar',
        confirmationText: 'Confirmar'
    }).then(() => handleRemoveProduto(prodId))


    return (
        <Grid className={cadClasses.root}>
            {carregando && <CircularLoading tipo="FULLSIZED" />}
            <Grid className={cadClasses.header}>
                <PrivatePageHeader
                    title="Dados dos Produtoss"
                    leftArea={leftArea()}
                />
            </Grid>
            <div className={cadClasses.content}>
                <Container maxWidth="md" className={classes.container}>
                    <div
                        className={classNames(
                            cadClasses.contentForms,
                            carregando ? cadClasses.contentFormsLoading : undefined
                        )}
                    >
                        {isEmpty(mov.infMov.prod) && (
                            <Box mb={2} 
                            >
                                <CardNaoEncontrado
                                    mensagem={
                                        "Nenhum produto encontrado"
                                    }
                                    icon={<InformacaoIcon tipo="GERAL" />}
                                />
                            </Box>
                        )}
                        {!isEmpty(mov.infMov.prod) && (
                            <Box 
                            >
                                {mov.infMov.prod.map(prod => (
                                    <>
                                        {isMobile ? (
                                            <CardProdutoEntradaMobile model={prod} onClick={onClick} onRemove={clickRemove} selected={false} />
                                        ) : (
                                            <CardProdutoEntrada model={prod} onClick={onClick} onRemove={clickRemove} selected={false} />
                                        )}
                                    </>
                                ))}
                            </Box>
                        )}
                        <Box mt={2}
                         >
                            <Button fullWidth size="large" onClick={onClickAdd} variant="outlined" color="primary">
                                <NovoIcon tipo="BUTTON" />
                                Adicionar Produto
                            </Button>
                        </Box>
                    </div>
                </Container>
                <div className={cadClasses.acoes}>
                    <Container maxWidth="md" className={classes.containerAcoes}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Button
                                    disabled={carregando}
                                    onClick={() => history.push({
                                        pathname: `/entrada-mercadoria/visualizar/${movId}/emitente`,
                                        search: location.search,
                                        state: {
                                            mov,
                                            manifesto: location.state?.manifesto,
                                            dtManifesto: location.state?.dtManifesto
                                        }
                                    })}
                                    variant="outlined"
                                    color="primary"
                                    size="large"
                                    fullWidth
                                >
                                    <VoltarIcon tipo="BUTTON" />
                                    Anterior
                                </Button>
                            </Grid>
                            <Grid item xs={6}>
                                <Button
                                    disabled={carregando}
                                    onClick={() => history.push({
                                        pathname: `/entrada-mercadoria/visualizar/${movId}/transportadora`,
                                        search: location.search,
                                        state: {
                                            mov,
                                            manifesto: location.state?.manifesto,
                                            dtManifesto: location.state?.dtManifesto
                                        }
                                    })}
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    fullWidth
                                >
                                    <AvancarIcon tipo="BUTTON_PRIMARY" />
                                    Próximo
                                </Button>
                            </Grid>
                        </Grid>
                    </Container>
                </div>
            </div>
        </Grid>
    )
}

export default DadosProdutoPage