import React, { useCallback, useMemo } from 'react';
import { useStyles } from './card-finalizar-sessao-valores-styles';
import { EnumPagTpTransacao } from 'model';
import { CardFinalizarSessaoValoresProps } from './card-finalizar-sessao-valores-props';
import { useCadastros, useToastSaurus } from 'services/app';
import { isPlanoPagIntegrado } from 'utils/plano-utils';
import { CalculadoraIcon } from 'views/components/icons/calculadora-icon';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { useSessaoAtual } from 'services/app';
import { Button, Grid, Typography } from 'views/design-system';

export const CardFinalizarSessaoValores = ({
  model,
  setValue,
  index,
  value,
}: CardFinalizarSessaoValoresProps) => {
  const { abrirCalculadora } = useCadastros();
  const classes = useStyles();
  const { plano } = useSessaoAtual()
  const { showToast } = useToastSaurus()

  const isPagIntegrado = isPlanoPagIntegrado(plano?.plano)

  const noModelPagIntegrado = !isPagIntegrado && model.pagamento.tpTransacao === EnumPagTpTransacao.S2_PAY

  const handleClick = useCallback(() => {
    if (noModelPagIntegrado) {
      showToast('error', 'Seu plano não tem Pagamento Integrado.')
      return
    }
  }, [noModelPagIntegrado, showToast])

  const descricao = useMemo(() => (
    <Grid item xs={6} sm={7} className={classes.alinharItensCentro}>
      <Typography className={classes.descricao}>
        {model.pagamento.descricao}
      </Typography>
    </Grid>
  ), [classes.alinharItensCentro, classes.descricao, model.pagamento.descricao])

  return (
    <Grid onClick={handleClick} className={classes.cardContainer}>
      <div className={classes.cardContent}>
        {descricao}
        {model.pagamento.tpTransacao !== EnumPagTpTransacao.S2_PAY && (
          <Grid item xs={2} sm={2} md={2} className={classes.alinharItensCentro}>
            <Button
              className={classes.buttonCalc}
              onClick={() => {
                abrirCalculadora(String(index), '', true, value, setValue);
              }}
            >
              <CalculadoraIcon tipo="BUTTON" style={{ margin: 0 }} />
            </Button>
          </Grid>
        )}
        <Grid item xs={4} sm={3} md={3} className={classes.alinharItensCentro}>
          {model.pagamento.tpTransacao === EnumPagTpTransacao.S2_PAY && model.valor === '0,00' ? (
            <Grid style={{ display: 'flex', justifyContent: 'flex-end', flex: 1 }}>
              <Typography>À Confirmar</Typography>
            </Grid>
          ) :
            <TextFieldSaurus
              tipo="DECIMAL"
              readOnly={model.pagamento.tpTransacao === EnumPagTpTransacao.S2_PAY}
              manterMascara
              fullWidth
              variant="outlined"
              value={value}
              placeholder="R$ 0,00"
              onChange={(e) => setValue(index, e.target.value)}
              className={classes.textInput}
            />
          }

        </Grid>
      </div>
    </Grid>
  );
};

export default React.memo(CardFinalizarSessaoValores);
