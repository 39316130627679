import { forwardRef, useImperativeHandle, useState } from 'react';

import { Controller, useForm } from 'react-hook-form';
import { ConfigInicialCardapioModel } from 'model/api/gestao/config-inicial/config-inicial-cardapio-model';
import { DefaultFormProps, DefaultFormRefs } from '../utils';
import { InputColor, TextFieldSaurus } from 'views/components/controles/inputs';
import { SelectSaurus } from 'views/components/controles/selects/select-saurus/select-saurus';
import { SimNaoBooleanMock } from 'data/mocks/sim-nao-mock';
import { temasMock } from 'data/mocks/temas-cardapio';
import { useStyles } from './form-config-inicial-styles';
import { Button, Grid } from 'views/design-system';


export const FormConfigInicialCardapio = forwardRef<
    DefaultFormRefs<ConfigInicialCardapioModel>,
    DefaultFormProps<ConfigInicialCardapioModel>
>(
    (props: DefaultFormProps<ConfigInicialCardapioModel>, ref) => {
        const [modelForm, setModelForm] = useState<ConfigInicialCardapioModel>(new ConfigInicialCardapioModel())
        const {
            handleSubmit,
            control,
            formState: { errors, touchedFields },
            setValue,
            getValues,
            reset
        } = useForm<ConfigInicialCardapioModel>({
            defaultValues: { ...modelForm},
            criteriaMode: 'all',
            mode: 'onChange'
        });

        const onSubmit = (values: ConfigInicialCardapioModel) => {
            props.onSubmit(values);
        };


        useImperativeHandle(ref, () => ({
            submitForm: async () => {
                await handleSubmit(onSubmit)();
            },
            fillForm: (values) => {
                if(!values) return
                reset(values)
                setModelForm(values)
            },
            resetForm: () => {
               
            },
        }));

        const classes = useStyles()
        return (
            <>
                <div className={classes.formulario}>
                    <form 
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        <Grid container spacing={2} >
                            <Grid item xs={12}>
                                <Controller
                                    name="permiteRetirada"
                                    control={control}
                                    render={({ field }) => (
                                        <SelectSaurus
                                            conteudo={SimNaoBooleanMock}
                                            label='Permitir Pedidos pelo Cardápio Digital?'
                                            error={Boolean(errors.permiteRetirada && errors.permiteRetirada.message)}
                                            helperText={
                                                errors.permiteRetirada
                                                    ? errors.permiteRetirada?.message
                                                    : undefined
                                            }
                                            {...field}
                                            onChange={(event) => {
                                                const item = SimNaoBooleanMock.filter(
                                                    (item) => item.Key === event.target.value,
                                                )[0]?.Key;

                                                setValue('permiteRetirada', item);
                                            }}
                                            value={getValues('permiteRetirada')}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    name="informacaoAdicional"
                                    control={control}
                                    render={({ field }) => (
                                        <TextFieldSaurus
                                            tipo="TEXTO"
                                            disabled={props.loading}
                                            fullWidth
                                            placeholder='Ex: Cardápio Hamburgueria'
                                            autoComplete='off'
                                            variant="outlined"
                                            label="Título do Cardápio"
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            error={Boolean(
                                                errors.informacaoAdicional && errors.informacaoAdicional.message
                                            )}
                                            helperText={
                                                touchedFields.informacaoAdicional || errors.informacaoAdicional
                                                    ? errors.informacaoAdicional?.message
                                                    : undefined
                                            }
                                            {...field}
                                            onChange={(e: any) => {
                                                const value = e.target.value;
                                                setValue('informacaoAdicional', value);
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    name="type"
                                    control={control}
                                    render={({ field }) => (
                                        <SelectSaurus
                                            conteudo={temasMock}
                                            label='Tema'
                                            error={Boolean(errors.type && errors.type.message)}
                                            helperText={
                                                errors.type
                                                    ? errors.type?.message
                                                    : undefined
                                            }
                                            {...field}
                                            onChange={(event) => {
                                                const item = temasMock.filter(
                                                    (item) => item.Key === event.target.value,
                                                )[0]?.Key;
                                                if (item !== undefined) {
                                                    setValue('type', item);
                                                }
                                            }}
                                            value={getValues('type')}

                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    name="primaryMain"
                                    control={control}
                                    render={({ field }) => (
                                        <InputColor

                                            valor={getValues('primaryMain')}
                                            labelTxt={'Cor de Exibição'}
                                            {...field}
                                            onChange={(cor) => setValue('primaryMain', cor)}

                                        />
                                    )}
                                />
                            </Grid>
                            <Button style={{ display: 'none' }} type="submit"></Button>
                        </Grid>



                    </form>
                </div>
            </>
        );
    });
