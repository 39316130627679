import { PesquisaHeaderProps } from './pesquisa-selecionar-empresa-props';
import { useStyles } from './pesquisa-selecionar-empresa-styles';
import { useTheme } from '@material-ui/core';
import { useState, useCallback, ChangeEvent, useRef } from 'react';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { ProcurarIcon } from 'views/components/icons';
import { Button, Grid } from 'views/design-system';

export const PesquisaSelecionarEmpresa = (props: PesquisaHeaderProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const [termoPesquisar, setTermoPesquisar] = useState('');
  const refInput = useRef<HTMLDivElement | null>(null);

  //CHAMADA DO PESQUISAR
  const pesquisar = useCallback(
    (event: any) => {
      event.preventDefault();
      props.onPesquisar({ termo: termoPesquisar });
    },
    [props, termoPesquisar],
  );

  //ALTERAR A PESQUISA
  const onChangePesquisa = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setTermoPesquisar(event.target.value);
    },
    [],
  );

  return (
    <form onSubmit={pesquisar}>
      <Grid container className={classes.root} spacing={1}>
        <Grid item xs={12} md={12}>
          <div className={classes.containerFinal}>
            <TextFieldSaurus
              inputRef={refInput}
              fullWidth
              size="small"
              allowSubmit={true}
              value={termoPesquisar}
              className={classes.campo}
              placeholder="Digite o CPF/CNPJ ou o Nome Fantasia"
              onChange={onChangePesquisa}
              adornmentColor={theme.palette.text.disabled}
            />
            <Button
              color="primary"
              variant="contained"
              type="submit"
              className={classes.btnPesquisa}
              fullWidth={true}
            >
              <ProcurarIcon tipo="BUTTON_PRIMARY" />
            </Button>
          </div>
        </Grid>
      </Grid>
    </form>
  );
};
