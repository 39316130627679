import { CardSincronizacaoProps } from './card-sinc-props';
import { useStyles } from './card-sinc-styles';
import { ConfirmarIcon } from 'views/components/icons/confirmar-icon';
import { FalhaIcon } from 'views/components/icons/fallha-icon';
import { TpSincronizacaoMock } from 'data/mocks/tp-sincronizacao';
import { Typography } from 'views/design-system';

export const CardSincronizacao = (props: CardSincronizacaoProps) => {
  const classes = useStyles();

  const tpSincronizacao = TpSincronizacaoMock.filter(
    (x) => x.Key === props.model.tipo,
  );
  return (
    <>
      <div
        className={classes.cardContainer}
        onClick={async () => {
          if (props.onCheck) await props.onCheck(props.model);
        }}
      >
        <div className={`${classes.cardContent}`}>
          <div className={classes.celulaGrid}>
            <Typography
              className={'celula-grid-value'}
              color="textPrimary"
              variant="body1"
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {props.model.sucesso === true ? (
                <ConfirmarIcon tipo="BUTTON" fill={'#10C822'} />
              ) : (
                <FalhaIcon tipo="BUTTON" fill={'#F44336'} />
              )}
            </Typography>
          </div>
          <div className={classes.celulaGridFull} style={{ flex: 1 }}>
            <Typography color="textPrimary" variant="caption">
              Descrição
            </Typography>
            <Typography
              className={'celula-grid-value'}
              color="textPrimary"
              variant="body1"
              style={{
                textOverflow: 'ellipsis',
              }}
            >
              <strong>Sincronização de {tpSincronizacao[0].Value}</strong>
            </Typography>
          </div>

          <div className={classes.celulaGrid}>
            <Typography color="textPrimary" variant="caption">
              Registros
            </Typography>
            <Typography
              className={'celula-grid-value'}
              color="textPrimary"
              variant="body1"
            >
              ({props.model.qtdSincronizado}/
              <strong>{props.model.qtdASincronizar}</strong>)
            </Typography>
          </div>
        </div>
      </div>
    </>
  );
};
