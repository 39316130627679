import * as Yup from 'yup';
import { useValidationYupDocumento } from '../../../form-validations'
import { EnumPagTpMod, EnumPagTpTransacao } from 'model';
import { useCallback } from 'react';

interface FinalizadoraValidationProps {
  tpMod: EnumPagTpMod;
  tpTransacao: EnumPagTpTransacao
  tpModIntegrado: boolean;
  isDev: boolean;
  isBoletoIntegrado: boolean;
}

export const useFormFinalizadoraEditValidation = ({ tpMod, tpTransacao, isBoletoIntegrado, isDev }: FinalizadoraValidationProps) => {
  const { documentoYup } = useValidationYupDocumento(true)

  const credenciais = Yup.object().shape({
    merchantId: Yup.string().required('Merchant ID é obrigatório.').typeError('Merchant ID é obrigatório.'),
    merchantToken: Yup.string().required('Merchant Token é obrigatório.').typeError('Merchant Token é obrigatório.'),
    ambiente: isDev ? Yup.number().required('Ambiente é obrigatório').typeError('Ambiente é obrigatório').typeError('Campo Obrigatório.') : Yup.number().notRequired().nullable(),
  })

  const validarCredenciais = useCallback(() => {
    if (tpTransacao === EnumPagTpTransacao.SAFRAPAY_WALLET) {
      return credenciais
    }

    switch (tpMod) {
      case EnumPagTpMod.PAGAMENTO_INSTANTANEO:
        return credenciais
      default:
        return Yup.object().shape({}).nullable(true)
    }
  }, [credenciais, tpMod, tpTransacao])

  const FormFinalizadoraEditYupValidation = Yup.object().shape({
    descricao: Yup.string().required('O campo descrição é obrigatório'),
    tpMod: Yup.number().required('O campo Modelo de pagamento é obrigatório'),
    qMaxParc: Yup.number().required('Parcelamento Máximo é obrigatório').min(1, 'Parcelamento Máximo não inválido').max(60, 'Parcelamento Máximo inválido').typeError('Parcelamento Máximo é obrigatório.'),
    cnpjCred: documentoYup('CNPJ'),
    credenciais: validarCredenciais(),
    credenciamentoBoleto: isBoletoIntegrado ? Yup.object().shape({
      ContaBancariaId: Yup.string().required('Informe uma Conta Bancaria'),
    }) : Yup.object().shape({}).nullable(true)
  });

  return {
    FormFinalizadoraYupValidation: FormFinalizadoraEditYupValidation
  }
}

