import { DialogSaurus } from '../dialog-saurus/dialog-saurus';
import { useStyles } from './dialog-qrcode-styles';
import { useCadastros } from 'services/app';
import { QRCodeSVG } from 'qrcode.react';
import { Grid, Typography } from 'views/design-system';

interface DialogQeCodeProps {
  openned: boolean;
  text: string;
}

export const DialogQRCode = ({ openned, text }: DialogQeCodeProps) => {
  // HOOKS
  const { fecharDialogQrCode } = useCadastros();

  // AUX
  const classes = useStyles();

  return (
    <DialogSaurus
      centralizarTitulo={true}
      aberto={openned || false}
      titulo="Escaneie o QrCode"
      tamanho="xs"
      isButtonTitleClose
      handleClickClose={fecharDialogQrCode}
    >
      <Grid container spacing={2}>
        <div className={classes.qrcode}>
          <QRCodeSVG value={text} size={180} />
          <Typography variant="caption" className={classes.qrText}>
            Faça a leitura do QRCode para executar a ação em seu dispositivo
          </Typography>
        </div>
      </Grid>
    </DialogSaurus>
  );
};
