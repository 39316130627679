import { AppEventEnum } from "model/enums/enum-app-event";
import { useCallback, useEffect, useState } from "react";
import { useEventTools } from "services/app/hooks/events/event-tools";
import { DialogConfirm, DialogConfirmProps } from "views/components/dialog/dialog-confirm/dialog-confirm";


export const ConfirmDialog = () => {
    const { addHandler, removeHandler } = useEventTools();
    const [modalState, setModalState] = useState<DialogConfirmProps>(
        {
            aberto: false,
            primaryButtonText: '',
            secondaryButtonText: '',
            title: '',
            description: '',
            showIcon: false
        }
    );

    const modalAlterado = useCallback((props: DialogConfirmProps) => {
        setModalState(props);
    }, [])

    useEffect(() => {
        addHandler(AppEventEnum.DialogConfirm, modalAlterado);
        return () => {
            removeHandler(AppEventEnum.DialogConfirm, modalAlterado);
        };
    }, [addHandler, modalAlterado, removeHandler]);

    return (
        <>
            {modalState.aberto && (
                <DialogConfirm
                    {...modalState}
                />
            )}
        </>
    )
}