import { Grow } from '@material-ui/core';
import { DialogSaurus } from '../dialog-saurus/dialog-saurus';
import { useStyles } from './dialog-taxa-servico-carrinho-styles';
import { useState, useMemo, useCallback } from 'react';
import { AlterarTaxa } from './components';
import { useMovAtual } from 'services/app/hooks/mov-atual';
import { MovSimplesProdutoModel } from 'model/api/gestao/movimentacao/simples/mov-simples-produto-model';
import { CardTaxaServicoCarrinho } from 'views/components/cards/card-taxa-servico-carrinho';
import { useContratoAtual } from 'services/app/hooks/contrato-atual';
import { EnumContratoConfig } from 'model/enums/enum-contrato-config';
import { useCadastros, useSessaoAtual } from 'services/app';
import { useCarrinho } from 'services/app/hooks/carrinho';
import { EnumCodigosPermissoes } from 'model/enums/enum-codigos-permissoes';
import { Box, Grid, Tooltip } from 'views/design-system';

interface DialogTaxaServicoProps {
  openned: boolean;
}

export const DialogTaxaServicoCarrinho = ({
  openned,
}: DialogTaxaServicoProps) => {

  const classes = useStyles();
  const [acao, setAcao] = useState<number>(0)
  const [vendedor, setVendedor] = useState<MovSimplesProdutoModel>(new MovSimplesProdutoModel())
  const { inativarProduto, reativarProduto } = useCarrinho()
  const { fecharDialogTaxaServicoCarrinho, abrirSolicitarPermissao } = useCadastros()
  const { getMov } = useMovAtual()
  const { getConfigByCod } = useContratoAtual();
  const { getPermissaoBoolean } = useSessaoAtual()

  const checarPermissaoInativar = useCallback(
    async (model: MovSimplesProdutoModel, naoAlteraTaxa: boolean = false) => {
      if (!getPermissaoBoolean(EnumCodigosPermissoes.CANCELAMENTO_ITENS)) {
        abrirSolicitarPermissao(
          async () => {
            await inativarProduto(model, naoAlteraTaxa);
          },
          530105,
          'inativar o produto'
        );
        return;
      }
      //caso tenha permissão segue o fluxo normalmente
      inativarProduto(model, naoAlteraTaxa);
    },
    [abrirSolicitarPermissao, getPermissaoBoolean, inativarProduto]
  );

  const produtoTaxaId = getConfigByCod(EnumContratoConfig.ProdutoServico)

  const mov = getMov()

  const produtosTaxa = useMemo(() => mov?.produtos.filter(x => x.produtoId === produtoTaxaId) ?? [], [mov?.produtos, produtoTaxaId])

  const telaInicial = useMemo(() => {
    return (
      <Grow in={true}>
        <Grid
          container
          spacing={2}
          style={{ marginBottom: 8 }}
        >
          <Grid item xs={12}>
            <Grid container spacing={2}>
              {produtosTaxa.map(item => {
                return (
                  <Tooltip title={item.ativo ? "" : "Taxa Inativa"} placement="top" arrow >
                    <Grid item xs={12} className={!item.ativo ? classes.cardInativo : undefined}>
                      <CardTaxaServicoCarrinho
                        model={item}
                        onCheck={() => { }}
                        onClick={(item) => {
                          setVendedor(item)
                          setAcao(1)
                        }}
                        selected={false}
                      />
                    </Grid>
                  </Tooltip>
                )
              })}
            </Grid>
          </Grid>
        </Grid>
      </Grow>
    )
  }, [classes.cardInativo, produtosTaxa])

  const acoes = useMemo(() => {
    switch (acao) {
      case 1:
        return (
          <AlterarTaxa
            reativarProduto={reativarProduto}
            closeModal={fecharDialogTaxaServicoCarrinho}
            vendedor={vendedor}
            voltar={setAcao}
            inativarProduto={checarPermissaoInativar}
          />
        )
      case 0:
        return telaInicial

    }
  }, [acao, checarPermissaoInativar, fecharDialogTaxaServicoCarrinho, reativarProduto, telaInicial, vendedor])


  const titulos = useMemo(() => {
    switch (acao) {
      case 1:
        return "Alterar Taxa"
      default:
        return "Taxa de Serviço"
    }
  }, [acao])

  return (
    <>
      <DialogSaurus
        aberto={openned || false}
        titulo={titulos}
        handleClickClose={fecharDialogTaxaServicoCarrinho}
        isButtonTitleClose
        tamanho="xs"
      >
        {openned && (
          <Box className={classes.cardContainer}>
            <Box className={classes.cardContent}>
              <Box className={classes.boxCustom}>
                {acoes}
              </Box>
            </Box>
          </Box>
        )}
      </DialogSaurus>
    </>
  );
};
