import { Grid } from 'views/design-system';
import { useCallback, useEffect } from 'react';
import { useDefaultCadastroStyles } from '../../cadastros/components/default-cadastro-styles';
import { NovoPagamentoList } from './components/novo-pagamento-list/novo-pagamento-list';
import { useToastSaurus } from 'services/app';
import { CarrinhoTotais } from '../mov-carrinho/components/cart-wrapper/components/carrinho-totais/carrinho-totais';
import { useMovRota } from 'services/app/hooks/mov-rota';
import { useVendaHeaderStore } from 'views/components/headers/venda-header/stores/venda-header-store';
import { useMovAtual } from 'services/app/hooks/mov-atual';
import { PedidoModel } from 'model/api/gestao/pedido/pedido-model';
import { isEmpty } from 'lodash';

export const MovPagamentoNovoPage = () => {
  const classes = useDefaultCadastroStyles();
  const { showToast } = useToastSaurus();
  const { avancarFluxoMov, redirectCarrinho, redirectVisualizarPedido } = useMovRota();
  const { getMov, limparInformacoesPedidos } = useMovAtual()

  const setHeaderProps = useVendaHeaderStore((state) => state.setHeaderProps);

  const mov = getMov()
  const informacoesPedido = mov?.informacoesGeraisPedido
  const voltarPedido = useCallback(async () => {
    try {

      const mov = getMov()
      if (!mov) return
      const informacoesPedido = mov.informacoesGeraisPedido
      redirectVisualizarPedido({
        ...new PedidoModel(),
        mesaId: informacoesPedido.mesaId ?? '',
        comandaId: informacoesPedido.comandaId ?? '',
        id: mov.id
      })
      await limparInformacoesPedidos()
    } catch (err: any) {
      showToast('error', err.message)
    }
  }, [getMov, limparInformacoesPedidos, redirectVisualizarPedido, showToast])

  const voltarCarrinho = useCallback(async () => {
    try {
      await redirectCarrinho();
    } catch (e: any) {
      showToast('error', e.message);
    }
  }, [redirectCarrinho, showToast])

  useEffect(() => {
    const mov = getMov()
    if (!mov) return
    const informacoesPedido = mov.informacoesGeraisPedido
    setHeaderProps({
      title: 'Forma de Pagamento',
      showDefinicoes: !informacoesPedido.pagAntecipado,
      voltar: {
        onClick:
          informacoesPedido.pagAntecipado
            ? voltarPedido
            : voltarCarrinho
      }
    })
  }, [redirectCarrinho, setHeaderProps, showToast, getMov, voltarPedido, voltarCarrinho])

  const validarEntradaTela = useCallback(async () => {
    try {
      // await validarCondicaoPagamentoAvancado();
    } catch (e: any) {
      try {
        await avancarFluxoMov();
      } catch (err: any) {
      }
      showToast('error', e.message);
    }
  }, [avancarFluxoMov, showToast]);

  useEffect(() => {
    (async () => {
      await validarEntradaTela();
    })();
  }, [validarEntradaTela]);


  return (
    <Grid className={classes.root}>
      <CarrinhoTotais valorPagAntecipado={informacoesPedido?.pagAntecipado?.vTotal || undefined} telaDePagamento={true} pagAntecipado={!isEmpty(informacoesPedido?.pagAntecipado)} />
      <Grid className={classes.list} flex flexDirection='column'>
        <NovoPagamentoList />
      </Grid>
    </Grid>
  );
};
