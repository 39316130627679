import { AppEventEnum } from 'model/enums/enum-app-event';
import { useEffect } from 'react';
import Joyride from 'react-joyride';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { useTourSaurus } from 'services/app/hooks/tour-saurus';
import { consoleDev } from 'utils/console-dev';
import { Typography } from 'views/design-system';
import { useThemeQueries } from 'views/theme';

export const Tour = () => {
  consoleDev('Tour');
  const {
    tourState,
    handleCallback,
    triggerTour,
    skipTour,
    nextStep
  } = useTourSaurus();
  const { addHandler, removeHandler } = useEventTools();
  const { theme } = useThemeQueries();

  useEffect(() => {
    addHandler(AppEventEnum.TourTrigger, triggerTour);
    addHandler(AppEventEnum.TourSkip, skipTour);
    return () => {
      removeHandler(AppEventEnum.TourTrigger, triggerTour);
      removeHandler(AppEventEnum.TourSkip, skipTour);
    };
    }, [addHandler, nextStep, removeHandler, skipTour, triggerTour])

    const steps = tourState.steps.map(step => {
        const newStep = { ...step }
        newStep.content = (
            <>
                <Typography variant='body1'>
                    {step.content}
                </Typography>
            </>
        )

        return newStep
    })

    return (
        <Joyride
            steps={steps}
            callback={handleCallback}
            stepIndex={tourState.stepIndex}
            run={tourState.run}
            continuous
            disableOverlayClose
            disableScrollParentFix
            scrollOffset={200}
            
            floaterProps={{
                disableAnimation: true,
            }}
            locale={{
                back: 'Anterior',
                close: 'Fechar',
                last: 'Finalizar',
                next: 'Próximo',
                open: 'Abrir',
                skip: 'Fechar'
            }}
            styles={{
                options: {
                    primaryColor: theme.palette.primary.main,
                    zIndex: 10000, // Está com essa zIndex alto por conta do Index do arquivo Menu-principal-pdv.tsx, existe uma tela escondida e o Tour é inserido nesse local. 
                },
                tooltipTitle: {
                    display:'flex',
                    justifyContent:'flex-start',
                    fontWeight: 600,
                    fontSize: '1.2rem',
                    marginTop: theme.spacing(2),
                    padding:'0 10px'
                },
                tooltipContent: {
                    textAlign:'start',
                    marginBottom: theme.spacing(2)
                }
               
            }}
        />
    )
}