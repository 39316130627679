import { useStyles } from '../opcoes-entrada-styles';
import { LoadingFinalizacao } from 'views/components';
import { ProdsModel } from 'model/api/gestao/venda/venda-completa-model';
import { ProdsXMLModel } from 'model/api/gestao/venda/venda-completa-xml-model';
import { Box, Typography } from 'views/design-system';

export interface LoadingStepProps {
    index: number;
    tipo: 'carregando' | 'produtos' | 'pessoas' | 'finalizando' | 'integrando';
    atualizando: boolean;
}


interface Props {
    steps: LoadingStepProps;
    numProdutos: number;
    prods: ProdsModel[] | ProdsXMLModel[];
}

export const OpcoesEntradaImportacaoXMLLoading = ({
    steps,
    numProdutos,
    prods
}: Props) => {

    const classes = useStyles();

    const retTexto = () => {
        const acao = steps.atualizando ? 'Atualizando' : 'Cadastrando'
        switch (steps.tipo) {
            case'carregando':
                return 'Carregando...'
            case 'produtos':
                return `${acao} Produtos (${steps.index + 1}/${numProdutos})`
            case 'pessoas':
                let tpPessoa = '';
                switch(steps.index){
                    case 0:
                        tpPessoa = 'Emitente';
                        break
                    case 1:
                        tpPessoa = 'Transportadora';
                        break
                    case 2:
                        tpPessoa = 'Destinatário';
                        break;
                }
                return `${acao} ${tpPessoa}`;
            case 'finalizando':
                return `Criando Entrada`
            case 'integrando':
                return `Integrando Manifesto`
        }
    }

    return <Box className={classes.loading}>
        <LoadingFinalizacao />
        {steps.tipo === 'produtos' && <Typography variant='h6'>
            {prods[steps.index].xProd}
        </Typography>}
        <Typography>
            {retTexto()}
        </Typography>
    </Box>
}