import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { makeUtilClasses, useThemeQueries } from 'views/theme';
import { picker } from 'utils/picker';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import {
  DefaultFormProps,
  DefaultFormRefs,
} from 'views/components/form/utils/form-default-props';

import { PerfilUsuariosConvidarFormModel } from 'model/app/forms/perfil/perfil-usuarios-convidar-form-model';
import { TextFieldSaurus } from 'views/components/controles/inputs/text-field-saurus/text-field-saurus';
import { SelectSaurus } from 'views/components/controles/selects/select-saurus/select-saurus';
import { useGetPerfis } from 'data/api/gestao/perfil/get-perfis';
import { KeyValueModel, PerfilModel } from 'model';
import { useToastSaurus } from 'services/app';
import { useSessaoAtual } from 'services/app';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useFormConvidarValidation } from './form-usuarios-convidar-validation';
import { isPlanoComVendedor } from 'utils/plano-utils';
import { Box, Grid } from 'views/design-system';

export const FormUsuariosConvidar = forwardRef<
  DefaultFormRefs<PerfilUsuariosConvidarFormModel>,
  DefaultFormProps<PerfilUsuariosConvidarFormModel>
>(
  (
    { loading, ...props }: DefaultFormProps<PerfilUsuariosConvidarFormModel>,
    ref,
  ) => {
    const utilClasses = makeUtilClasses();
    const refInput = useRef<HTMLInputElement>(null);
    const { showToast } = useToastSaurus();
    const { isMobile } = useThemeQueries();
    const [modelForm, setModelForm] = useState<PerfilUsuariosConvidarFormModel>(
      new PerfilUsuariosConvidarFormModel(),
    );
    const { FormConvidarYupValidation } = useFormConvidarValidation();

    const {
      handleSubmit,
      control,
      formState: { errors, touchedFields },
      reset,
      setValue,
      getValues,
    } = useForm<PerfilUsuariosConvidarFormModel>({
      resolver: yupResolver(FormConvidarYupValidation),
      criteriaMode: 'all',
      mode: 'onSubmit',
    });

    const [perfis, setPerfis] = useState<PerfilModel[]>([]);
    const { getPerfisDrop } = useGetPerfis();

    const { plano } = useSessaoAtual()
    const planoComVendedor = isPlanoComVendedor(plano?.plano)

    const onSubmit = async (values: PerfilUsuariosConvidarFormModel) => {
      const model = picker<PerfilUsuariosConvidarFormModel>(
        values,
        new PerfilUsuariosConvidarFormModel(),
      );

      props.onSubmit(model, modelForm);
    };

    useImperativeHandle(ref, () => ({
      submitForm: async () => {
        await handleSubmit(onSubmit)();
      },
      resetForm: () => {
        if (!isMobile) refInput.current?.focus();
        reset();
      },
      fillForm: (model: PerfilUsuariosConvidarFormModel) => {
        reset({ ...model })
        setModelForm(model);
        setTimeout(() => {
          if (!isMobile) refInput.current?.focus();
        }, 50);
      },
    }));

    const handleGetPerfisDrop = useCallback(async () => {
      const res = await getPerfisDrop();
      if (res.erro) {
        throw res.erro;
      }

      return res.resultado?.data?.list;
    }, [getPerfisDrop]);


    useEffect(() => {
      (async () => {
        try {
          const usePerfis: PerfilModel[] = await handleGetPerfisDrop();
          setPerfis(usePerfis);
          setValue('perfilId', usePerfis.map(val => val.id)[0])
        } catch (e: any) {
          showToast('error', e.message);
        }
      })();
    }, [handleGetPerfisDrop, setValue, showToast]);

    const mock: Array<KeyValueModel> = perfis.map((valor) => {
      return new KeyValueModel(valor.id, valor.nome);
    });

    return (
      <>
        <Box my={2}>
          <div className={utilClasses.formContainer}>
            {loading && props.showLoading ? (
              <div className={utilClasses.controlLoading}>
                <CircularLoading tipo="NORMAL" />
              </div>
            ) : null}
            <form
              onSubmit={handleSubmit(onSubmit)}
              className={loading ? utilClasses.controlLoading : ''}
            >
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Controller
                    name="email"
                    control={control}
                    render={({ field }) => (
                      <TextFieldSaurus
                        tipo="EMAIL"
                        allowSubmit
                        fullWidth
                        disabled={loading}
                        readOnly={getValues('hasPropEmail')}
                        label="E-mail"
                        variant="outlined"
                        autoComplete={'off'}
                        error={Boolean(errors.email && errors.email.message)}
                        helperText={
                          touchedFields.email || errors.email
                            ? errors.email?.message
                            : undefined
                        }
                        {...field}
                      />
                    )}
                  />
                </Grid>
                {planoComVendedor && (
                  <Grid item xs={12}>
                    <Controller
                      name="perfilId"
                      control={control}
                      render={({ field }) => (
                        <SelectSaurus
                          disabled={loading}
                          conteudo={mock}
                          fullWidth
                          variant="outlined"
                          label="Selecione o perfil"
                          error={Boolean(
                            errors.perfilId && errors.perfilId.message,
                          )}
                          helperText={
                            touchedFields.perfilId || errors.perfilId
                              ? errors.perfilId?.message
                              : undefined
                          }
                          {...field}
                          onChange={(event) => {
                            if (event) {
                              setValue(
                                'perfilId',
                                perfis.filter(
                                  (item) => item.id === event.target.value,
                                )[0]?.id,
                              );
                            }
                          }}
                        />
                      )}
                    />
                  </Grid>
                )}
              </Grid>

              <button style={{ display: 'none' }} type="submit"></button>
            </form>
          </div>
        </Box>
      </>
    );
  },
);
