import { memo } from 'react';
import { CardParcelamentoProps } from './card-parcelamento-props';
import { DefaultCard } from '../components/default-card';
import { useDefaultCardStyles } from '../components/default-card/default-card-styles';
import classNames from 'classnames';
import { Typography } from 'views/design-system';

export const CardParcelamento = ({ model, selected, onClick }: CardParcelamentoProps) => {
  const classes = useDefaultCardStyles();

  return (
    <>
      <DefaultCard
        onClick={async () => {
          onClick(model);
        }}
        className={ selected ? classes.cardSelected : ''}
      >
        <div className={classNames(classes.cardContent )} >
          <div className={classes.celulaGrid}>
            <Typography
              className={'celula-grid-value'}
              color="textPrimary"
              variant="body1"
            >
              {model.Value}
            </Typography>
          </div>
          <div className={classes.celulaGridFull}></div>
        </div>
      </DefaultCard>
    </>
  );
};

export default memo(CardParcelamento);
