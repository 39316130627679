import { forwardRef, useImperativeHandle } from 'react';
import { TextFieldSaurus } from '../../../controles/inputs';
import { makeUtilClasses } from '../../../../theme';
import { CircularLoading } from '../../../utils/circular-loading/circular-loading';
import {
  DefaultFormProps,
  DefaultFormRefs,
} from '../../utils/form-default-props';
import { useFormSalaoValidation } from './form-salao-cadastro-validation';
import { SaloesCadastroFormModel } from 'model/app/forms/saloes/saloes-cadastro-form-model';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { SelectSaurus } from 'views/components/controles/selects/select-saurus/select-saurus';
import { TpBalcaoSalaoMock } from 'data/mocks/tp-balcao-salao-mock';
import { Box, Button, Grid } from 'views/design-system';

export const FormSalaoCadastro = forwardRef<
  DefaultFormRefs<SaloesCadastroFormModel>,
  DefaultFormProps<SaloesCadastroFormModel>
>((props: DefaultFormProps<SaloesCadastroFormModel>, ref) => {
  const utilClasses = makeUtilClasses();
  const { FormSalaoValidationYup } = useFormSalaoValidation();

  const {
    handleSubmit,
    control,
    formState: { errors, touchedFields },
    setValue,
    getValues,
    reset,
  } = useForm<SaloesCadastroFormModel>({
    defaultValues: new SaloesCadastroFormModel(),
    resolver: yupResolver(FormSalaoValidationYup),
    criteriaMode: 'all',
    mode: 'onChange',
  });

  const onSubmit = (values: SaloesCadastroFormModel) => {
    const modelP = new SaloesCadastroFormModel();
    modelP.descricao = values.descricao;
    modelP.balcao = values.balcao;
    props.onSubmit(modelP);
  };

  useImperativeHandle(ref, () => ({
    submitForm: async () => {
      await handleSubmit(onSubmit)();
    },
    resetForm: () => {
      setValue('balcao', 1);
      setValue('descricao', '');
      reset({
        balcao: undefined,
        descricao: '',
      });
    },
    fillForm: () => {},
  }));

  return (
    <>
      <div className={utilClasses.formContainer}>
        {props.loading && props.showLoading ? (
          <div className={utilClasses.controlLoading}>
            <CircularLoading tipo="NORMAL" />
          </div>
        ) : null}

        <form
          onSubmit={handleSubmit(onSubmit)}
          className={props.loading ? utilClasses.controlLoading : ''}
        >
          <Box my={2}>
            <Grid item xs={12}>
              <Controller
                name="descricao"
                control={control}
                render={({ field }) => (
                  <TextFieldSaurus
                    tipo={'TEXTO'}
                    disabled={props.loading}
                    fullWidth
                    variant="outlined"
                    label="Descrição do Salão (Informativo)"
                    allowSubmit
                    InputLabelProps={{
                      shrink: true,
                    }}
                    placeholder="Ex: Salão primeiro andar"
                    error={Boolean(
                      errors.descricao && errors.descricao.message,
                    )}
                    helperText={
                      touchedFields.descricao || errors.descricao
                        ? errors.descricao?.message
                        : undefined
                    }
                    {...field}
                  />
                )}
              />
            </Grid>
            <br />
            <Grid item xs={12}>
              <Controller
                name="balcao"
                control={control}
                render={({ field }) => (
                  <SelectSaurus
                    label="Balcao"
                    variant="outlined"
                    fullWidth
                    disabled={props.loading}
                    conteudo={TpBalcaoSalaoMock}
                    error={Boolean(errors.balcao && errors.balcao.message)}
                    helperText={
                      touchedFields.balcao || errors.balcao
                        ? errors.balcao?.message
                        : undefined
                    }
                    {...field}
                    onChange={(event) => {
                      const item = TpBalcaoSalaoMock.filter(
                        (item) => item.Key === event.target.value,
                      )[0]?.Key;

                      setValue('balcao', item);
                    }}
                    value={getValues('balcao')}
                  />
                )}
              />
            </Grid>
          </Box>
          <Button style={{ display: 'none' }} type="submit"></Button>
        </form>
      </div>
    </>
  );
});
