import { useStyles } from './card-mesa-selecionar-styles';
import { useThemeQueries } from 'views';
import { CardMesaSelecionarProps } from './card-mesa-selecionar-props';
import { MesaIcon } from 'views/components/icons/mesa-icon';
import { memo } from 'react';
import { Grid } from 'views/design-system';

export const CardMesaSelecionar = ({
  model,
  onClick,
  selected,
}: CardMesaSelecionarProps) => {
  const classes = useStyles();
  const { theme } = useThemeQueries();

  return (
    <Grid
      onClick={() => onClick(model)}
      className={classes.cardContainer}
      style={{
        borderStyle: 'dashed',
        borderColor: '#ECEDED',
      }}
    >
      <div
        style={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <MesaIcon
          tipo="INPUT"
          fill={theme.palette.primary.main}
          style={{ marginRight: 0 }}
        />
        <p
          className={classes.codigoMesa}
          style={{
            maxWidth: '50px',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            textAlign: 'right',
          }}
        >
          {model.codigo}
        </p>
      </div>
    </Grid>
  );
};

export default memo(CardMesaSelecionar);
