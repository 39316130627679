import { Theme, makeStyles } from "@material-ui/core";

interface StylesProps{
    primary?: string;
    secondary?: string;
}

export const useStyles = makeStyles<Theme, StylesProps>((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gridGap: theme.spacing(2),
        paddingBottom: theme.spacing(3)
    },
    icon: {
        fill: theme.palette.warning.main,
        width: 75,
        height: 75
    },
    title: {
        color: '#6B6B6B',
        fontWeight: 600,
        fontSize: '1.5rem'
    },
    text: {
        color: theme.palette.text.primary,
        fontSize: '0.9rem'
    },
    buttonContainer: {
        display: 'flex',
        gridGap: theme.spacing(2),
        alignItems: 'center'
    },
    buttonPrimary:{
        background: ({primary}) => primary ? primary : theme.palette.primary.main,
        color: theme.palette.common.white,
        '&:hover': {
            background: ({primary}) => primary ? primary : theme.palette.primary.main
        }
    },
    buttonSecondary: {
        borderColor: ({secondary}) => secondary ? secondary : theme.palette.error.main,
        color: ({secondary}) => secondary ? secondary : theme.palette.error.main,
        '& svg': {
            fill: ({secondary} : StylesProps) => secondary ? secondary : theme.palette.error.main,
        },
        '&:hover': {
            borderColor: ({secondary}) => secondary ? secondary : theme.palette.error.dark,
            '& svg': {
                fill: ({secondary} : StylesProps) => secondary ? secondary : theme.palette.error.dark,
            },
        },
    }
}));