import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    cardContainer: {
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        "& .makeStyles-formContainer-161": {
            marginTop: 10
        }
    },
    cardContent: {
        display: "flex",
        flexGrow: 1,
        flexDirection: "column",
    },
    label: {
        fontSize: '0.9rem',
        color: '#BCBCBC',
        lineHeight: '14,1px',
        marginTop: theme.spacing(1)
    },
    boxCustom:{
        display: 'flex', 
        flexDirection: 'column', 
        flex: 1 
    }
}));
