import { forwardRef, useCallback, useImperativeHandle, useRef, useState } from 'react';
import { TextFieldSaurus } from '../../../controles/inputs';
import { makeUtilClasses } from '../../../../theme';
import { CircularLoading } from '../../../utils/circular-loading/circular-loading';
import {
    DefaultFormRefs,
} from '../../utils/form-default-props';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useFormEntradaCadastroValidation } from './form-dados-transportadora-validation';
import { FormDadosTransportadoraProps } from './form-dados-transportadora-props';
import { SelectSaurus } from 'views/components/controles/selects/select-saurus/select-saurus';
import { FormTranEndereco } from './components/accordion-tran-endereco/form-tran-endereco';
import { TranspFormModel } from 'model/app/forms/entrada/transp-form-model';
import { TpModFreteMock } from 'data/mocks/tp-mod-frete-mock';
import { UFMock } from 'data/mocks';
import { picker } from 'utils/picker';
import { AutocompletePessoas, EnumNomeCnpj } from 'views/components/controles/autocompletes/autocomplete-pessoa/autocomplete-pessoa';
import { useToastSaurus } from 'services/app';
import { useGetEnderecoPessoa } from 'data/api/gestao/pessoa';
import { PessoaEnderecoModel, PessoaModel } from 'model/api/gestao/pessoa';
import { isEmpty } from 'lodash';
import { formatarCPFCNPJ } from 'utils/cpfcnpj-format';
import { Box, Button, Grid } from 'views/design-system';

export const FormDadosTransportadora = forwardRef<
    DefaultFormRefs<TranspFormModel>,
    FormDadosTransportadoraProps
>((props, ref) => {
    const utilClasses = makeUtilClasses();
    const { formEntradaCadastroValidationYup } = useFormEntradaCadastroValidation();
    const { getEnderecoPessoa, carregando: carregandoGet } = useGetEnderecoPessoa()
    const [modelForm, setModelForm] = useState<TranspFormModel>(new TranspFormModel())
    const modelFormRef = useRef<TranspFormModel>(new TranspFormModel())
    const [, setAtt] = useState<boolean>(false)
    const [openAccordion, setOpenAccordion] = useState<boolean>(false)
    const { showToast } = useToastSaurus()

    const {
        handleSubmit,
        control,
        formState,
        reset,
        setValue,
        getValues,
    } = useForm<TranspFormModel>({
        resolver: yupResolver(formEntradaCadastroValidationYup),
        criteriaMode: 'firstError',
        defaultValues: {
            ...modelForm
        },
        mode: 'onChange',
    });
    const { errors, touchedFields } = formState

    const onSubmit = (values: TranspFormModel) => {
        const transp = picker<TranspFormModel>(values, new TranspFormModel())

        props.onSubmit(transp, modelFormRef.current);
    };

    useImperativeHandle(ref, () => ({
        submitForm: async () => {
            await handleSubmit(onSubmit)();
        },
        resetForm: () => {
        },
        fillForm: (model: TranspFormModel) => {
            setModelForm({ ...model })
            modelFormRef.current = { ...model }
            reset({ ...model })
        },
    }));

    const getEnderecoPessoaWrapper = useCallback(async (pessoaId: string) => {
        const res = await getEnderecoPessoa(pessoaId)
        if (res.erro) throw res.erro

        const resultado = res.resultado?.data as PessoaEnderecoModel[]
        if (resultado.length <= 0) {
            return new PessoaEnderecoModel()
        }

        return resultado[0]
    }, [getEnderecoPessoa])

    const loading = [props.loading, carregandoGet].includes(true)

    return (
        <Box my={2}>
            <div className={utilClasses.formContainer}>
                {props.loading && props.showLoading ? (
                    <div className={utilClasses.controlLoading}>
                        <CircularLoading tipo="NORMAL" />
                    </div>
                ) : null}

                <form
                    onSubmit={handleSubmit(onSubmit)}
                    className={loading ? utilClasses.controlLoading : ''}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Controller
                                name="doc"
                                control={control}
                                render={({ field }) => (
                                    <AutocompletePessoas
                                        disabled={loading}
                                        label="CPF/CNPJ"
                                        allowSubmit
                                        mensagemNaoEncontrado='Nenhum registro encontrado'
                                        error={Boolean(errors.doc && errors.doc.message)}
                                        helperText={
                                            touchedFields.doc || errors.doc
                                                ? errors.doc?.message
                                                : undefined
                                        }
                                        {...field}
                                        nomeCnpj={EnumNomeCnpj.Cnpj}
                                        onChange={async (retorno) => {
                                            try {
                                                if (!retorno.isString) {
                                                    const pessoa = retorno.value as PessoaModel
                                                    setValue('xNome', pessoa.nome)
                                                    setValue('doc', pessoa.cpfcnpj)
                                                    const enderecoPessoa = await getEnderecoPessoaWrapper(pessoa.id)
                                                    setValue('uf', UFMock.find(x => x.Value === enderecoPessoa.uf)?.Key ?? null)
                                                    setValue('xMun', enderecoPessoa.xMun)
                                                    setValue('ie', enderecoPessoa.ierg)
                                                    setValue('xEnder', `${enderecoPessoa.logradouro}, ${enderecoPessoa.numero} - ${enderecoPessoa.bairro}, ${enderecoPessoa.cep} ${enderecoPessoa.complemento ? ', ' + enderecoPessoa.complemento : ''}`)
                                                    setOpenAccordion(true)
                                                }
                                            } catch (error: any) {
                                                showToast('error', error.message)
                                            }
                                        }}
                                        value={formatarCPFCNPJ(field.value)}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name="xNome"
                                control={control}
                                render={({ field }) => (
                                    <AutocompletePessoas
                                        disabled={loading}
                                        label="Razão Social"
                                        allowSubmit
                                        permiteNovo={!isEmpty(getValues('xNome'))}
                                        mensagemNaoEncontrado='Nenhum registro encontrado'
                                        textoNovoItem='Editar para:'
                                        error={Boolean(errors.xNome && errors.xNome.message)}
                                        helperText={
                                            touchedFields.xNome || errors.xNome
                                                ? errors.xNome?.message
                                                : undefined
                                        }
                                        {...field}
                                        nomeCnpj={EnumNomeCnpj.Nome}
                                        onChange={async (retorno) => {
                                            try {
                                                if (retorno.isString) {
                                                    setValue('xNome', retorno.value)
                                                    return
                                                }
                                                const pessoa = retorno.value as PessoaModel
                                                setValue('xNome', pessoa.nome)
                                                setValue('doc', pessoa.cpfcnpj)
                                                const enderecoPessoa = await getEnderecoPessoaWrapper(pessoa.id)
                                                setValue('uf', UFMock.find(x => x.Value === enderecoPessoa.uf)?.Key ?? null)
                                                setValue('xMun', enderecoPessoa.xMun)
                                                setValue('ie', enderecoPessoa.ierg)
                                                setValue('xEnder', `${enderecoPessoa.logradouro}, ${enderecoPessoa.numero} - ${enderecoPessoa.bairro}, ${enderecoPessoa.cep} ${enderecoPessoa.complemento ? ', ' + enderecoPessoa.complemento : ''}`)
                                                setOpenAccordion(true)
                                                setAtt(prev => !prev)
                                            } catch (error: any) {
                                                showToast('error', error.message)
                                            }
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Controller
                                name="ie"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        tipo={'TEXTO'}
                                        disabled={loading}
                                        fullWidth
                                        autoComplete='new-password'
                                        variant="outlined"
                                        label="Inscrição Estadual"
                                        allowSubmit
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        error={Boolean(errors.ie && errors.ie.message)}
                                        helperText={
                                            touchedFields.ie || errors.ie
                                                ? errors.ie?.message
                                                : undefined
                                        }
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Controller
                                name="placa"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        tipo={'TEXTO'}
                                        disabled={loading}
                                        fullWidth
                                        autoComplete='new-password'
                                        variant="outlined"
                                        label="Placa"
                                        allowSubmit
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        error={Boolean(errors.placa && errors.placa.message)}
                                        helperText={
                                            touchedFields.placa || errors.placa
                                                ? errors.placa?.message
                                                : undefined
                                        }
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name="modFrete"
                                control={control}
                                render={({ field }) => (
                                    <SelectSaurus
                                        disabled={loading}
                                        conteudo={TpModFreteMock}
                                        fullWidth
                                        autoComplete='new-password'
                                        variant="outlined"
                                        label="Modalidade de Frete"
                                        allowSubmit
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        error={Boolean(errors.modFrete && errors.modFrete.message)}
                                        helperText={
                                            touchedFields.modFrete || errors.modFrete
                                                ? errors.modFrete?.message
                                                : undefined
                                        }
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Controller
                                name="ufVeiculo"
                                control={control}
                                render={({ field }) => (
                                    <SelectSaurus
                                        conteudo={UFMock}
                                        disabled={loading}
                                        fullWidth
                                        variant="outlined"
                                        label="UF do Veículo"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        error={Boolean(errors.ufVeiculo && errors.ufVeiculo.message)}
                                        helperText={
                                            touchedFields.ufVeiculo || errors.ufVeiculo
                                                ? errors.ufVeiculo?.message
                                                : undefined
                                        }
                                        {...field}
                                        onChange={(event) => {
                                            if (event) {
                                                const newStatus = UFMock.filter(
                                                    (item) => item.Key === event.target.value,
                                                )[0]?.Key;
                                                setValue('ufVeiculo', newStatus);
                                            }
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Controller
                                name="rntc"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        tipo={'NUMERO'}
                                        disabled={loading}
                                        fullWidth
                                        autoComplete='new-password'
                                        variant="outlined"
                                        label="R.N.T.C"
                                        allowSubmit
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        error={Boolean(errors.rntc && errors.rntc.message)}
                                        helperText={
                                            touchedFields.rntc || errors.rntc
                                                ? errors.rntc?.message
                                                : undefined
                                        }
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormTranEndereco
                                {...{
                                    openAccordion,
                                    setOpenAccordion,
                                    setValue,
                                    control,
                                    formState,
                                    loading,
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Button style={{ display: 'none' }} type="submit"></Button>
                </form>
            </div>
        </Box>
    );
});
