import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    root: {
        background: theme.palette.grey[100],
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: 'center',
        justifyContent: 'center',
    },
    containerError: {
        height: 'calc(100% - 135px)',
    },
    imageError: {
        textAlign: 'center',
        marginBottom: 30,
        "& svg": {
            width: 150,
            height: 150,

        },
    },
    btnTentarNovamente: {
        marginTop: theme.spacing(3),
    },
    containerLoading: {
        height: '100%',
        flexGrow: 1,
        "& .loader-container": {
            width: 150,
            height: 150,
        }
    },
    content: {
        overflowY: 'auto',
        flex: '1 1 auto',
        height: 0,
        padding: theme.spacing(1),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    container: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    cancelarIcon: {
        fill: theme.palette.error.main,
    }
}));