import { makeUtilClasses } from 'views/theme';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { Controller, useForm } from 'react-hook-form';
import { UploadCargaProduto } from 'model/api/gestao/upload-carga/upload-carga-model';
import { Box, Grid } from 'views/design-system';

export const FormUploadCargaItemProdutoVizualizar = (props: {
  model: UploadCargaProduto;
  loading: boolean;
}) => {
  const utilClasses = makeUtilClasses();

  const { control } = useForm<UploadCargaProduto>({
    defaultValues: { ...props.model },
    criteriaMode: 'all',
  });

  return (
    <>
      <Box my={2}>
        <div className={utilClasses.formContainer}>
          {props.loading ? (
            <div className={utilClasses.controlLoading}>
              <CircularLoading tipo="NORMAL" />
            </div>
          ) : null}
          <div className={props.loading ? utilClasses.controlLoading : ''}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={8}>
                <Controller
                  name="codigo"
                  control={control}
                  render={({ field }) => (
                    <TextFieldSaurus
                      readOnly
                      fullWidth
                      disabled={props.loading}
                      label="Código"
                      variant="outlined"
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Controller
                  name="quantidade"
                  control={control}
                  render={({ field }) => (
                    <TextFieldSaurus
                      readOnly
                      fullWidth
                      disabled={props.loading}
                      label="Estoque"
                      variant="outlined"
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name="categoria"
                  control={control}
                  render={({ field }) => (
                    <TextFieldSaurus
                      readOnly
                      fullWidth
                      disabled={props.loading}
                      label="Catgeoria"
                      variant="outlined"
                      {...field}
                    />
                  )}
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <Controller
                  name="subCategoria"
                  control={control}
                  render={({ field }) => (
                    <TextFieldSaurus
                      readOnly
                      allowSubmit
                      disabled={props.loading}
                      fullWidth
                      variant="outlined"
                      label="Sub Categoria"
                      {...field}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <Controller
                  name="marca"
                  control={control}
                  render={({ field }) => (
                    <TextFieldSaurus
                      readOnly
                      fullWidth
                      disabled={props.loading}
                      label="Marca"
                      variant="outlined"
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Controller
                  name="preco"
                  control={control}
                  render={({ field }) => (
                    <TextFieldSaurus
                      readOnly
                      fullWidth
                      tipo="DECIMAL"
                      casasDecimais={2}
                      label="Preço"
                      variant="outlined"
                      {...field}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <Controller
                  name="medida"
                  control={control}
                  render={({ field }) => (
                    <TextFieldSaurus
                      disabled={props.loading}
                      label={'Medida'}
                      readOnly
                      fullWidth={true}
                      variant="outlined"
                      {...field}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <Controller
                  name="descImposto"
                  control={control}
                  render={({ field }) => (
                    <TextFieldSaurus
                      disabled={props.loading}
                      label={'Desc. Imposto'}
                      readOnly
                      fullWidth={true}
                      variant="outlined"
                      {...field}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <Controller
                  name="ncm"
                  control={control}
                  render={({ field }) => (
                    <TextFieldSaurus
                      disabled={props.loading}
                      label={'NCM'}
                      readOnly
                      fullWidth={true}
                      variant="outlined"
                      {...field}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Controller
                  name="descricao"
                  control={control}
                  render={({ field }) => (
                    <TextFieldSaurus
                      disabled={props.loading}
                      label={'Descricao'}
                      readOnly
                      fullWidth={true}
                      variant="outlined"
                      {...field}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </div>
        </div>
      </Box>
    </>
  );
};
