import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState
} from 'react';
import { SelectSaurus } from 'views/components/controles/selects/select-saurus/select-saurus';
import { TpModMock, TypeTpModTransacao } from 'data/mocks/tp-mod-mock';
import { makeUtilClasses, useThemeQueries } from 'views/theme';
import { picker } from 'utils/picker';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import {
  DefaultFormProps,
  DefaultFormRefs
} from 'views/components/form/utils/form-default-props';
import { FinalizadoraEditFormModel } from 'model/app/forms/finalizadora/finalizadora-edit-form-model';
import { useFormFinalizadoraEditValidation } from './form-finalizadora-edit-validation';
import { EnumPagTpMod, EnumPagVencimentoTipo, EnumPagTpTransacao, KeyValueModel } from 'model';
import { isPlanoPagIntegrado } from 'utils/plano-utils';
import {
  GestaoStorageKeys,
  useGestaoStorage,
  useGestaoToken,
  useSessaoAtual,
  useToastSaurus
} from 'services/app';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { consoleDev } from 'utils/console-dev';
import { AmbientePagamentoMock } from 'data/mocks/ambiente-credencial-mock';
import { EnumTipoComunicacao } from 'model/enums/enum-tipo-comunicacao';
import { EnumInstituicao } from 'model/enums/enum-instituicao-';
import { useEmpresaAtual } from 'services/app/hooks/empresa-atual';
import { isEmpty, isEqual } from 'lodash';
import { CredenciamentoSafra } from 'model/api/gestao/finalizadora/finalizadora-model';
import { EnumAmbientePagamento } from 'model/enums/enum-ambiente-pagamento';
import { VariaveisAmbiente } from 'config';
import { guidEmpty } from 'utils/guid-empty';
import { useGetContasBancarias } from 'data/api/gestao/conta-bancaria/get-contas-bancarias';
import { ContaBancariaModel } from 'model/api/gestao/conta-bancaria/conta-bancaria-model';
import { useGetContaBancariaBoletoConfig } from 'data/api/gestao/conta-bancaria/get-conta-bancaria-boleto-config';
import { BancoMock } from 'data/mocks/banco-mock';
import { SwitchSaurus } from 'views/components/controles';
import { useMenuOptionsStyles } from 'views/components/menu-options/menu-options-styles';
import { Box, Button, Divider, Grid, Typography } from 'views/design-system';
import { tpPagamentoResolver } from 'utils/tp-pagamento-resolver';
import { FinalizadoraTpVencimentoMock } from 'data/mocks/finalizadora-tp-vencimento-mock';

export const FormFinalizadoraEdit = forwardRef<
  DefaultFormRefs<FinalizadoraEditFormModel>,
  DefaultFormProps<FinalizadoraEditFormModel>
>(({ loading, ...props }: DefaultFormProps<FinalizadoraEditFormModel>, ref) => {
  const utilClasses = makeUtilClasses();
  const menuOptionsStyles = useMenuOptionsStyles({})
  const refInputDescricao = useRef<HTMLInputElement>(null);
  const [modelForm, setModelForm] = useState<FinalizadoraEditFormModel>(
    new FinalizadoraEditFormModel()
  );
  const [isBoletoIntegrado, setIsBoletoIntegrado] = useState(false)
  const [tpMod, setTpMod] = useState<EnumPagTpMod>(EnumPagTpMod.DINHEIRO);
  const [tpTransacao, setTpTransacao] = useState<EnumPagTpTransacao>(EnumPagTpTransacao.SAFRAPAY_WALLET);
  const [arrayCNPJState, setArrayCNPJ] = useState<KeyValueModel[]>([]);
  const [isTpModIntegrado, setIsTpModIntegrado] = useState<boolean>(false);
  const isDev = VariaveisAmbiente.isDev;
  const { FormFinalizadoraYupValidation } = useFormFinalizadoraEditValidation({
    tpMod: tpMod,
    tpModIntegrado: isTpModIntegrado,
    isDev,
    isBoletoIntegrado,
    tpTransacao,
  });
  const { isMobile, theme } = useThemeQueries();
  const { plano } = useSessaoAtual();
  const isPagIntegrado = isPlanoPagIntegrado(plano?.plano);
  const { getEmpresaAtual } = useEmpresaAtual();
  const { showToast } = useToastSaurus();

  // Tipo Boleto deps
  const { getContasBancarias, carregando: carregandoContasBancarias } = useGetContasBancarias()
  const [contasBancarias, setContasBancarias] = useState<ContaBancariaModel[]>([])
  const { getContaBancariaBoletoConfig, carregando: carregandoContaBancariaConfig } = useGetContaBancariaBoletoConfig()

  const carregandoGeral = carregandoContaBancariaConfig || carregandoContasBancarias || loading;

  const handleGetContaBancariaConfig = async (contaBancariaId: string) => {
    try {

      const res = await getContaBancariaBoletoConfig(0, 0, {
        contaBancariaId: contaBancariaId,
        empresaId: getEmpresaAtual()?.id ?? ''
      })
      if (res.erro) {
        throw res.erro
      }
      if (res.resultado?.data.list.length <= 0)
        throw new Error("Nenhuma configuração cadastrada")

      setValue('credenciamentoBoleto.ConfigContaId', res.resultado?.data.list[0].id)
    } catch (err: any) {
      showToast('error', err.message)
    }
  }

  const {
    handleSubmit,
    control,
    formState: { errors, touchedFields },
    getValues,
    setValue,
    reset,
    getFieldState,
    setError,
    trigger,
  } = useForm<FinalizadoraEditFormModel>({
    defaultValues: { ...modelForm },
    resolver: yupResolver(FormFinalizadoraYupValidation),
    criteriaMode: 'all',
    mode: 'onChange'
  });

  const validateCarc = useCallback((value) => {
    const descricao: string = value
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');
    const regexCaracEspeciais = /[^a-zA-Z0-9À-ÖØ-öø-ÿ\s,.\\-]/;
    if (regexCaracEspeciais.test(descricao)) {
      return true;
    }
    return false;
  }, []);

  const onSubmit = (values: FinalizadoraEditFormModel) => {
    const isValidName = validateCarc(values.descricao);
    if (isValidName) {
      setError('descricao', {
        type: 'error',
        message: 'Não pode conter caracteres especiais.'
      });
      return;
    }

    const finalizadora = picker<FinalizadoraEditFormModel>(
      values,
      new FinalizadoraEditFormModel()
    );
    if (finalizadora.empresaId === guidEmpty()) {
      finalizadora.empresaId = null;
    }
    if (
      values.integrado ||
      values.tpMod === EnumPagTpMod.PAGAMENTO_INSTANTANEO
    ) {
      finalizadora.credenciais.tipo = EnumTipoComunicacao.Pix;
      finalizadora.credenciais.instituicao = EnumInstituicao.Safra;
      if (!isDev) {
        finalizadora.credenciais.ambiente = EnumAmbientePagamento.Producao;
      } else {
        finalizadora.credenciais.ambiente = EnumAmbientePagamento.Homologacao;
      }
    } if (values.tpTransacao === EnumPagTpTransacao.SAFRAPAY_WALLET) {
      finalizadora.credenciais.instituicao = EnumInstituicao.Safra;
      if (!isDev) {
        finalizadora.credenciais.ambiente = EnumAmbientePagamento.Producao;
      } else {
        finalizadora.credenciais.ambiente = EnumAmbientePagamento.Homologacao;
      }
    }

    if (values.tpMod !== EnumPagTpMod.BOLETO_BANCARIO || !isBoletoIntegrado) {
      finalizadora.credenciamentoBoleto = undefined;
    }
    props.onSubmit(finalizadora, modelForm);
  };

  useEffect(() => {
    let arrayCNPJ: Array<KeyValueModel>;
    switch (process.env.REACT_APP_ENV) {
      case 'prodSafraWeb':
        arrayCNPJ = [new KeyValueModel('32270608000122', 'SafraPay')];
        getValues('integrado') && setValue('cnpjCred', '32270608000122');
        break;
      case 'cordovaSlow':
        arrayCNPJ = [new KeyValueModel('32270608000122', 'SafraPay')];
        getValues('integrado') && setValue('cnpjCred', '32270608000122');
        break;
      default:
        arrayCNPJ = [new KeyValueModel('32270608000122', 'SafraPay')];
        getValues('integrado') && setValue('cnpjCred', '32270608000122');
        break;
    }

    setArrayCNPJ(arrayCNPJ);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tipoPagamentoIntegrado = [
    EnumPagTpMod.CARTAO_CREDITO,
    EnumPagTpMod.CARTAO_DEBITO,
    EnumPagTpMod.PAGAMENTO_INSTANTANEO,
    EnumPagTpMod.VALE_ALIMENTACAO,
    EnumPagTpMod.VALE_COMBUSTIVEL,
    EnumPagTpMod.VALE_PRESENTE,
    EnumPagTpMod.VALE_REFEICAO
  ];

  const pgtoIntegrado = tipoPagamentoIntegrado.includes(tpMod);

  useImperativeHandle(ref, () => ({
    submitForm: async () => {
      await handleSubmit(onSubmit)();
    },
    resetForm: () => {
      if (!isMobile) {
        refInputDescricao.current?.focus();
      }
    },
    fillForm: async (model: FinalizadoraEditFormModel) => {
      const empresaId = getEmpresaAtual()?.id || guidEmpty();
      if (model.empresaId === null) {
        model.empresaId = guidEmpty();
      }

      if (model.integrado && tipoPagamentoIntegrado.includes(model.tpMod)) {
        setIsTpModIntegrado(true);
        if (isEmpty(model.credenciais)) {
          model.credenciais = new CredenciamentoSafra();
        }
        model.credenciais.cnpj = getEmpresaAtual()?.cpfcnpj || '';
        model.credenciais.razaoSocial = getEmpresaAtual()?.razaoSocial || '';
        if (!isDev) {
          model.credenciais.ambiente = EnumAmbientePagamento.Producao;
        }
      } else {
        setIsTpModIntegrado(false);
      }

      if (model.tpMod === EnumPagTpMod.BOLETO_BANCARIO) {
        try {
          const getContasBancariasResponse = await getContasBancarias(0, 0, {
            empresaId: empresaId
          })
          if (getContasBancariasResponse.erro) {
            throw getContasBancariasResponse.erro
          }
          setContasBancarias(getContasBancariasResponse.resultado?.data.list)

          model.credenciamentoBoleto = {
            ConfigContaId: model.credenciamentoBoleto?.ConfigContaId || '',
            ContaBancariaId: model.credenciamentoBoleto?.ContaBancariaId || '',
            EmpresaId: getEmpresaAtual()?.id || guidEmpty()
          };
          if (model.credenciado && model.credenciamentoBoleto) {
            setIsBoletoIntegrado(true);
          }
        } catch (err: any) {
          showToast('error', err.message)
        }
      }

      reset({
        ...model
      });

      setTpTransacao(model.tpTransacao)
      setTpMod(model.tpMod);
      setModelForm(model);
      //senão o focus n funfa
      setTimeout(() => {
        if (!isMobile) {
          refInputDescricao.current?.focus();
        }
      }, 500);
    }
  }));

  const possuiParcelamento =
    tpMod === EnumPagTpMod.CARTAO_CREDITO ||
    tpMod === EnumPagTpMod.CREDITO_LOJA ||
    tpMod === EnumPagTpMod.BOLETO_BANCARIO

  const possuiVencimento = useMemo(() =>
    tpMod === EnumPagTpMod.BOLETO_BANCARIO
    , [tpMod])

  useEffect(() => {
    const isIntegrado = pgtoIntegrado && getValues('integrado');
    if (
      isIntegrado ||
      getValues('tpMod') === EnumPagTpMod.PAGAMENTO_INSTANTANEO ||
      getValues('tpTransacao') === EnumPagTpTransacao.SAFRAPAY_WALLET
    ) {
      setValue('credenciais.razaoSocial', getEmpresaAtual()!.razaoSocial);
      setValue('credenciais.cnpj', getEmpresaAtual()!.cpfcnpj);
    }
    setIsTpModIntegrado(pgtoIntegrado && getValues('integrado'));
  }, [getEmpresaAtual, getValues, pgtoIntegrado, setValue]);

  consoleDev('formFinalizadoraEdit');

  const { convertToken } = useGestaoToken();
  const { getRegistro } = useGestaoStorage();
  const token = getRegistro(GestaoStorageKeys.Token, false);
  const tokenConvertido = convertToken(token);
  const dadosEmpresa = useMemo(
    () =>
      tokenConvertido!.empresa.map(
        (empresa) => new KeyValueModel(empresa.Id, empresa.Descricao)
      ),
    [tokenConvertido]
  );
  const empresasMock =
    tpMod === EnumPagTpMod.PAGAMENTO_INSTANTANEO
      ? dadosEmpresa
      : [new KeyValueModel(guidEmpty(), 'Todas as Empresas'), ...dadosEmpresa];



  return (
    <>
      <Box my={2}>
        <div className={utilClasses.formContainer}>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className={(carregandoGeral) && props.showLoading ? utilClasses.controlLoading : ''}
          >
            {(carregandoGeral) && props.showLoading ? (
              <CircularLoading tipo="FULLSIZED" />
            ) : null}
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Controller
                  name="descricao"
                  control={control}
                  render={({ field }) => (
                    <TextFieldSaurus
                      inputRef={refInputDescricao}
                      fullWidth
                      disabled={loading}
                      label="Descrição"
                      variant="outlined"
                      error={Boolean(
                        errors.descricao && errors.descricao.message
                      )}
                      helperText={
                        touchedFields.descricao || errors.descricao
                          ? errors.descricao?.message
                          : undefined
                      }
                      {...field}
                      onChange={(e) => {
                        e.target.value = e.target.value.trimStart();
                        field.onChange(e);
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="tpMod"
                  control={control}
                  render={({ field }) => (
                    <SelectSaurus
                      label="Modelo de Pagamento"
                      variant="outlined"
                      fullWidth
                      disabled={loading}
                      conteudo={TpModMock}
                      error={Boolean(errors.tpMod && errors.tpMod.message)}
                      helperText={
                        touchedFields.tpMod || errors.tpMod
                          ? errors.tpMod?.message
                          : undefined
                      }
                      {...field}
                      onChange={(event) => {
                        const item: TypeTpModTransacao = TpModMock.filter(
                          (item) => isEqual(item.Key, event.target.value)
                        )[0]?.Key;
                        if (item !== undefined) {
                          if (typeof item === 'object') {
                            setValue('tpMod', item.tpMod);
                            setTpMod(item.tpMod);
                            setTpTransacao(item.tpTransacao)
                            setValue('tpTransacao', item.tpTransacao);
                            trigger();
                            if (
                              item.tpTransacao === EnumPagTpTransacao.SAFRAPAY_WALLET &&
                              getValues('empresaId') === guidEmpty()
                            ) {
                              setValue('empresaId', getEmpresaAtual()?.id || '');
                            }
                            if (!isDev && item.tpTransacao === EnumPagTpTransacao.SAFRAPAY_WALLET) {
                              setValue(
                                'credenciais.ambiente',
                                EnumAmbientePagamento.Producao
                              );
                            }

                          } else {
                            setValue('tpMod', item);
                            setValue('tpTransacao', EnumPagTpTransacao.NORMAL);
                            setTpTransacao(EnumPagTpTransacao.NORMAL)
                            setTpMod(item);
                            trigger();
                            if (!isDev && tipoPagamentoIntegrado.includes(item)) {
                              setValue(
                                'credenciais.ambiente',
                                EnumAmbientePagamento.Producao
                              );
                            }
                            if (
                              item === EnumPagTpMod.PAGAMENTO_INSTANTANEO &&
                              getValues('empresaId') === guidEmpty()
                            ) {
                              setValue('empresaId', getEmpresaAtual()?.id || '');
                            }
                          }
                        }
                      }}
                      value={tpPagamentoResolver(getValues('tpMod'), getValues('tpTransacao'))?.Key}
                    />
                  )}
                />
              </Grid>
              {dadosEmpresa.length > 1 && (
                <Grid item xs={12}>
                  <Controller
                    name="empresaId"
                    control={control}
                    render={({ field }) => (
                      <SelectSaurus
                        label="Empresa"
                        conteudo={empresasMock}
                        error={Boolean(
                          errors.empresaId && errors.empresaId.message
                        )}
                        helperText={
                          touchedFields.empresaId || errors.empresaId
                            ? errors.empresaId?.message
                            : undefined
                        }
                        {...field}
                        onChange={(event) => {
                          const empresa = empresasMock.filter(
                            (empresa) => empresa.Key === event.target.value
                          )[0]?.Key;
                          setValue('empresaId', empresa);
                        }}
                        value={getValues('empresaId')}
                      />
                    )}
                  />
                </Grid>
              )}
              {possuiParcelamento ? (
                <Grid item md={6} xs={6}>
                  <Controller
                    name="qMaxParc"
                    control={control}
                    render={({ field }) => (
                      <TextFieldSaurus
                        allowSubmit
                        disabled={loading}
                        fullWidth
                        tipo="NUMERO"
                        variant="outlined"
                        manterMascara
                        label="Parcelamento Máximo"
                        error={Boolean(
                          errors.qMaxParc && errors.qMaxParc.message
                        )}
                        helperText={
                          touchedFields.qMaxParc || errors.qMaxParc
                            ? errors.qMaxParc?.message
                            : undefined
                        }
                        {...field}
                      />
                    )}
                  />
                </Grid>
              ) : (
                ''
              )}
              {possuiParcelamento ? (
                <Grid item xs={6} md={6}>
                  <Controller
                    name="vMinParc"
                    control={control}
                    render={({ field }) => (
                      <TextFieldSaurus
                        fullWidth
                        tipo="DECIMAL"
                        label="Valor Mínimo de Parcela"
                        manterMascara
                        showStartAdornment
                        variant="outlined"
                        error={Boolean(
                          errors.vMinParc && errors.vMinParc.message
                        )}
                        helperText={
                          touchedFields.vMinParc || errors.vMinParc
                            ? errors.vMinParc?.message
                            : undefined
                        }
                        {...field}
                      />
                    )}
                  />
                </Grid>
              ) : (
                ''
              )}
              {possuiVencimento && (
                <>
                  <Grid item xs={6}>
                    <Controller
                      name="vencimento"
                      control={control}
                      render={({ field }) => (
                        <SelectSaurus
                          disabled={loading}
                          allowSubmit={false}
                          conteudo={FinalizadoraTpVencimentoMock}
                          label="Tipo de Vencimento"
                          fullWidth
                          autoComplete={'off'}
                          helperText={
                            getFieldState('vencimento').error
                              ? getFieldState('vencimento').error?.message
                              : undefined
                          }
                          error={Boolean(getFieldState('vencimento').error)}
                          {...field}
                          onChange={(e) => {
                            field.onChange(e);
                            trigger('qDias');
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item md={6} xs={6}>
                    <Controller
                      name="qDias"
                      control={control}
                      render={({ field }) => (
                        <TextFieldSaurus
                          allowSubmit
                          disabled={loading}
                          fullWidth
                          tipo="NUMERO_RANGE"
                          variant="outlined"
                          max={31}
                          min={0}
                          manterMascara
                          label={getValues('vencimento') === EnumPagVencimentoTipo.FIXO ? 'Dia do Vencimento' : 'Dias p/ o Vencimento'}
                          error={Boolean(
                            errors.qDias && errors.qDias.message
                          )}
                          helperText={
                            touchedFields.qDias || errors.qDias
                              ? errors.qDias?.message
                              : undefined
                          }
                          {...field}
                        />
                      )}
                    />
                  </Grid>
                </>
              )}
              {/* <Grid item xs={12} md={6}>
               <Controller
                name="pDesc"
                control={control}
                render={({ field }) => (
                  <TextFieldSaurus
                    disabled={loading}
                    fullWidth
                    tipo="DECIMAL"
                    variant="outlined"
                    manterMascara
                    label="Percentual(%) de desconto"
                    error={Boolean(errors.pDesc && errors.pDesc.message)}
                      helperText={
                        touchedFields.pDesc || errors.pDesc
                          ? errors.pDesc?.message
                          : undefined
                      }
                      {...field}
                    />
                )}
              />
              </Grid>
              <Grid item xs={12} md={6}>
              <Controller
                name="pAcresc"
                control={control}
                render={({ field }) => (
                <TextFieldSaurus
                  allowSubmit
                  disabled={loading}
                  fullWidth
                  tipo="DECIMAL"
                  variant="outlined"
                  manterMascara
                  label="Percentual(%) de acréscimo"
                 error={Boolean(errors.pAcresc && errors.pAcresc.message)}
                      helperText={
                        touchedFields.pAcresc || errors.pAcresc
                          ? errors.pAcresc?.message
                          : undefined
                      }
                      {...field}
                    />
                )}
              />
              </Grid> */}
              {tpMod !== EnumPagTpMod.DINHEIRO && (isPagIntegrado && getValues('integrado')) && (
                <Grid item xs={12}>
                  <Controller
                    name="cnpjCred"
                    control={control}
                    render={({ field }) => (
                      <SelectSaurus
                        label="CNPJ da Adquirente"
                        variant="outlined"
                        fullWidth
                        disabled={loading || !pgtoIntegrado}
                        conteudo={arrayCNPJState}
                        error={Boolean(
                          errors.cnpjCred && errors.cnpjCred.message
                        )}
                        helperText={
                          touchedFields.cnpjCred || errors.cnpjCred
                            ? errors.cnpjCred?.message
                            : undefined
                        }
                        {...field}
                        value={getValues('cnpjCred')}
                        onChange={(event) => {
                          const item = arrayCNPJState.filter(
                            (item) => item.Key === event.target.value
                          )[0]?.Key;
                          if (item !== undefined) {
                            setValue('cnpjCred', item);
                          }
                        }}
                      />
                    )}
                  />
                </Grid>
              )}
              {(
                isTpModIntegrado ||
                getValues('tpMod') === EnumPagTpMod.PAGAMENTO_INSTANTANEO ||
                getValues('tpTransacao') === EnumPagTpTransacao.SAFRAPAY_WALLET
              ) && (
                  <>
                    <Grid item xs={12}>
                      <Typography variant="h6" color="textPrimary">
                        Credenciais
                      </Typography>
                      <Divider
                        style={{ background: theme.palette.secondary.main }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Controller
                        name="credenciais.razaoSocial"
                        control={control}
                        render={({ field }) => (
                          <TextFieldSaurus
                            disabled={loading}
                            allowSubmit={false}
                            readOnly
                            id="credenciais.razaoSocial"
                            label="Razão Social"
                            fullWidth
                            autoComplete={'off'}
                            helperText={
                              getFieldState('credenciais.razaoSocial').error
                                ? getFieldState('credenciais.razaoSocial').error
                                  ?.message
                                : undefined
                            }
                            error={Boolean(
                              getFieldState('credenciais.razaoSocial').error
                            )}
                            {...field}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Controller
                        name="credenciais.cnpj"
                        control={control}
                        render={({ field }) => (
                          <TextFieldSaurus
                            disabled={loading}
                            allowSubmit={false}
                            readOnly
                            id="credenciais.cnpj"
                            tipo="CNPJ"
                            label="CNPJ"
                            fullWidth
                            autoComplete={'off'}
                            helperText={
                              getFieldState('credenciais.cnpj').error
                                ? getFieldState('credenciais.cnpj').error?.message
                                : undefined
                            }
                            error={Boolean(
                              getFieldState('credenciais.cnpj').error
                            )}
                            {...field}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Controller
                        name="credenciais.merchantId"
                        control={control}
                        render={({ field }) => (
                          <TextFieldSaurus
                            disabled={loading}
                            allowSubmit={false}
                            id="credenciais.merchantId"
                            label="ID Merchant"
                            fullWidth
                            autoComplete={'off'}
                            helperText={
                              getFieldState('credenciais.merchantId').error
                                ? getFieldState('credenciais.merchantId').error
                                  ?.message
                                : undefined
                            }
                            error={Boolean(
                              getFieldState('credenciais.merchantId').error
                            )}
                            {...field}
                            onChange={(e) => {
                              e.target.value = e.target.value.trimStart();
                              field.onChange(e);
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Controller
                        name="credenciais.merchantToken"
                        control={control}
                        render={({ field }) => (
                          <TextFieldSaurus
                            disabled={loading}
                            allowSubmit={false}
                            id="credenciais.merchantToken"
                            label="Token Merchant"
                            fullWidth
                            autoComplete={'off'}
                            helperText={
                              getFieldState('credenciais.merchantToken').error
                                ? getFieldState('credenciais.merchantToken').error
                                  ?.message
                                : undefined
                            }
                            error={Boolean(
                              getFieldState('credenciais.merchantToken').error
                            )}
                            {...field}
                            onChange={(e) => {
                              e.target.value = e.target.value.trimStart();
                              field.onChange(e);
                            }}
                          />
                        )}
                      />
                    </Grid>
                    {(getValues('tpTransacao') !== EnumPagTpTransacao.SAFRAPAY_WALLET) && (getValues('tpMod') !==
                      EnumPagTpMod.PAGAMENTO_INSTANTANEO) && (
                        <Grid item xs={12}>
                          <Controller
                            name="credenciais.codigoAtivacao"
                            control={control}
                            render={({ field }) => (
                              <TextFieldSaurus
                                disabled={loading}
                                allowSubmit={false}
                                id="credenciais.codigoAtivacao"
                                label="Código de Ativação"
                                fullWidth
                                autoComplete={'off'}
                                helperText={
                                  getFieldState('credenciais.codigoAtivacao')
                                    .isTouched &&
                                    getFieldState('credenciais.codigoAtivacao').error
                                    ? getFieldState('credenciais.codigoAtivacao')
                                      .error?.message
                                    : undefined
                                }
                                error={Boolean(
                                  getFieldState('credenciais.codigoAtivacao').error
                                )}
                                {...field}
                              />
                            )}
                          />
                        </Grid>
                      )}
                    {isDev && (
                      <Grid item xs={12}>
                        <Controller
                          name="credenciais.ambiente"
                          control={control}
                          render={({ field }) => (
                            <SelectSaurus
                              disabled={loading || !isDev}
                              allowSubmit={false}
                              conteudo={AmbientePagamentoMock}
                              id="credenciais.ambiente"
                              label="Ambiente"
                              fullWidth
                              autoComplete={'off'}
                              helperText={
                                getFieldState('credenciais.ambiente').error
                                  ? getFieldState('credenciais.ambiente').error
                                    ?.message
                                  : undefined
                              }
                              error={Boolean(
                                getFieldState('credenciais.ambiente').error
                              )}
                              {...field}
                              onChange={(ev) => {
                                const item = AmbientePagamentoMock.filter(
                                  (item) => item.Key === ev.target.value
                                );

                                setValue('credenciais.ambiente', item[0].Key);
                              }}
                            />
                          )}
                        />
                      </Grid>
                    )}
                  </>
                )}
              {(tpMod === EnumPagTpMod.BOLETO_BANCARIO && contasBancarias.length > 0) &&
                <>
                  <Grid item xs={12}>
                    <Box className={menuOptionsStyles.menuItem}>
                      <Typography>
                        {isBoletoIntegrado ? 'Desabilitar' : 'Habilitar'} Integração
                        de Boleto
                      </Typography>
                      <SwitchSaurus
                        onChange={(e) => {
                          setIsBoletoIntegrado(e.target.checked)

                        }}
                        size="small"
                        value={isBoletoIntegrado}
                        variant="DEFAULT"
                        checked={isBoletoIntegrado}
                      />
                    </Box>
                  </Grid>
                  {
                    isBoletoIntegrado && (
                      <>
                        <Grid item xs={12}>
                          <Typography variant='h6' color='textPrimary'>Credenciais</Typography>
                          <Divider style={{ background: theme.palette.secondary.main }} />
                        </Grid>
                        <Grid item xs={12}>
                          <Controller
                            name="credenciamentoBoleto.ContaBancariaId"
                            control={control}
                            render={({ field }) => {
                              const contasMock = contasBancarias.map((item) => {
                                return {
                                  Key: item.id,
                                  Value: `Nº Conta: ${item.conta} - ${BancoMock.find((itemBancoMock) => {
                                    return itemBancoMock.Key === item.banco
                                  })?.Value ?? ''}`,
                                }
                              })

                              return (
                                <SelectSaurus
                                  disabled={loading}
                                  allowSubmit={false}
                                  conteudo={contasMock}
                                  id="credenciamentoBoleto.id"
                                  label="Conta bancária"
                                  fullWidth
                                  autoComplete={'off'}
                                  helperText={
                                    getFieldState('credenciamentoBoleto.ContaBancariaId').error
                                      ? getFieldState('credenciamentoBoleto.ContaBancariaId').error?.message
                                      : undefined
                                  }
                                  error={Boolean(getFieldState('credenciamentoBoleto.ContaBancariaId').error)}
                                  {...field}
                                  onChange={ev => {
                                    const item =
                                      contasBancarias
                                        .filter(item => item.id === ev.target.value)
                                    const contaId = item[0].id
                                    setValue('credenciamentoBoleto.ContaBancariaId', contaId)
                                    handleGetContaBancariaConfig(contaId)
                                  }}
                                  value={getValues('credenciamentoBoleto.ContaBancariaId')}
                                />
                              )
                            }}
                          />
                        </Grid>
                      </>
                    )
                  }

                </>}
            </Grid>
            <Button style={{ display: 'none' }} type="submit"></Button>
          </form>
        </div>
      </Box>
    </>
  );
});
