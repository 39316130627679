import { useEffect, useRef, useState, useCallback } from 'react';
import { useHistory } from 'react-router';

import {
  Box,
  Button,
  Divider,
  Grid,
  Typography
} from 'views/design-system';

import {
  FormControlLabel
} from '@material-ui/core';
import { PublicPageHeader } from 'views/components/headers';
import { FormLogin } from 'views/components/form/master/login/form-login';
import { LoginFormModel } from 'model/app';
import { CircularLoading } from 'views';
import { CheckboxDefault } from 'views/components/controles/checkboxes';
import { DefaultFormRefs } from 'views/components/form/utils/form-default-props';
import { UserIcon, LoginIcon, NovoUsuarioIcon } from 'views/components/icons';
import { useStyles } from './login-page-styles';
import { GestaoStorageKeys, useGestaoStorage, useGestaoToken, useToastSaurus } from 'services/app';
import { useThemeQueries } from '../../../theme/util-styles';
import { makeUtilClasses } from '../../../theme/util-make-styles';
import { useConfirm } from 'material-ui-confirm';
import { ApiError } from '../../../../model/app/errors/api-erros';
import { VariaveisAmbiente } from 'config';
import { isEmpty } from 'lodash';
import { AutenticacaoPendenciaContratoModel } from 'model/api/gestao/autenticacao-pendencia/autenticacao-pendencia';
import { EnumDeviceType } from 'model/enums/enum-device-type';
import { useSessaoAtual } from 'services/app';
import { SplashScreen } from 'views/components/splash-screen/splash-screen';
import { WhitelabelModel } from 'model/api/gestao/whitelabel/whitelabel-model';
import { useShowAviso } from 'services/app/hooks/show-aviso';
import { useMovRota } from 'services/app/hooks/mov-rota';
import { RetaguardaRotasMock } from 'data/mocks/retaguarda-rotas-mock';

const LoginPage = () => {
  const { isMobile, theme } = useThemeQueries();
  const utilClasses = makeUtilClasses();
  const classes = useStyles();
  const { showToast } = useToastSaurus();
  const history = useHistory();
  const { logar, usuario, carregando, refreshUser } = useSessaoAtual();
  const [bloqueado, setBloqueado] = useState(false);
  const [splash, setSplash] = useState<string>('')
  const chkManterConectado = useRef<HTMLInputElement>();
  const confirm = useConfirm();
  const loginRefs = useRef<DefaultFormRefs<LoginFormModel>>(null);
  const logou = useRef<boolean>(false);
  const { convertToken } = useGestaoToken()
  const { setRegistro, getRegistro } = useGestaoStorage();
  const { showAviso } = useShowAviso();
  const { redirectLanding } = useMovRota();
  useEffect(() => {
    if (loginRefs) loginRefs.current?.fillForm(new LoginFormModel('', ''));
  }, []);

  const queryString = window.location.search

  const urlParams = new URLSearchParams(queryString)

  const callbackUrl = urlParams.get('callbackUrl')
  const empresaId = urlParams.get('empresaId')

  const testeUtf8 = (valor: string) => {
    const utf8 = require('utf8');

    (window as any).utf8 = utf8;
  }

  const efetuarLogin = async (model: LoginFormModel) => {
    try {
      logou.current = true;
      const manterConectado =
        chkManterConectado.current?.querySelector('input')?.checked === true;

      if (callbackUrl) {
        let urlDescript = atob(callbackUrl)

        let res: any = ''

        if (urlDescript.includes('{accessToken}')) {
          if (urlDescript.includes('{empresaId}')) {
            if (empresaId && !isEmpty(empresaId)) {
              res = await logar(model.usuario, model.senha, '', false, true)

              const token = convertToken(res || '')

              const temMesmaEmpresa = token?.empresa.filter(item => item.Id === empresaId)

              if ((temMesmaEmpresa?.length || 0) < 1) {
                throw new Error('Esta conta não tem a empresa requisitada.')
              }

              urlDescript = urlDescript.replace('{accessToken}', res)
              urlDescript = urlDescript.replace("{empresaId}", empresaId)

              return window.location.href = urlDescript
            } else {
              res = await logar(model.usuario, model.senha, '', manterConectado)
              const token = convertToken(res || '')

              const umaEmpresa = token?.empresa.length === 1

              if (umaEmpresa) {
                urlDescript = urlDescript.replace('{accessToken}', res)
                urlDescript = urlDescript.replace("{empresaId}", token?.empresa[0].Id)

                return window.location.href = urlDescript
              }

              return window.location.href = `${window.location.origin}/selecionar-empresa?callbackUrl=${callbackUrl}`
            }
          }
          res = await logar(model.usuario, model.senha, '', false, true)

          urlDescript = urlDescript.replace('{accessToken}', res)

          return window.location.href = urlDescript
        }

        res = await logar(model.usuario, model.senha, '', manterConectado)

        if (res.status) {
          if (res.status === 402) {
            setRegistro(GestaoStorageKeys.PendenciaContrato, res?.data as AutenticacaoPendenciaContratoModel)
            return history.push('/pendencias')
          }
        }

        if (typeof res !== 'string') {
          return history.push({
            pathname: '/selecionar-contrato',
            state: {
              email: model.usuario,
              senha: btoa(model.senha),
              contratos: res
            }
          })
        }

        return window.location.href = atob(callbackUrl)
      }

      const res: any = await logar(model.usuario, model.senha, '', manterConectado);

      if (res.status) {
        if (res.status === 402) {
          setRegistro(GestaoStorageKeys.PendenciaContrato, res?.data as AutenticacaoPendenciaContratoModel)
          return history.push('/pendencias')
        }
      }

      if (typeof res !== 'string') {

        //FAÇO ISSO APENAS PARA DAR TRIGGER EM UM EVENTO EM OUTRA ABA
        localStorage.setItem('warningLogin', 'warning')
        localStorage.removeItem('warningLogin')

        return history.push({
          pathname: '/selecionar-contrato',
          state: {
            email: model.usuario,
            senha: btoa(model.senha),
            contratos: res
          }
        })
      }

      setBloqueado(true);
      if (
        isMobile
      ) {
        const user = convertToken(res)
        const wl = getRegistro(GestaoStorageKeys.Whitelabel, false) as { contratoId: string } & WhitelabelModel;
        const timeoutTime = isEmpty(wl.urlIcone) || user?.empresa[0].ContratoId !== wl.contratoId ? 0 : 2500
        if (!isEmpty(wl.urlIcone) && user?.empresa[0].ContratoId === wl.contratoId) {
          setSplash(wl.urlIcone)
        }
        setTimeout(async () => {
          try {
            if (VariaveisAmbiente.paymentDevice === EnumDeviceType.NAVIGATOR) {
              history.push('/');
              return
            }
            await redirectLanding(true);
          } catch (e: any) {
            showToast('error', e.message)
          }
        }, timeoutTime)
        return
      }
      return history.push('/');
    } catch (e: any) {
      if (e.constructor.name === ApiError.name) {
        history.push(`/ativar-email?email=${model.usuario}`);
        return;
      } else {
        showToast('error', e.message);
        model.senha = '';
        loginRefs.current?.fillForm(model);
      }
    }
  };

  const redirectToLanding = useCallback(() => {
    history.push('/');
  }, [history]);

  useEffect(() => {
    if (usuario) {
      if (usuario.confirmado !== 'True') {
        confirm({
          title: 'Confirmação de Dados',
          description: `Identificamos que você ainda não confirmou seu E-mail ou Telefone (via SMS). Para sua segurança, ative sua conta o quanto antes para manter os acessos disponíveis.`,
          confirmationButtonProps: { variant: 'contained' },
          confirmationText: 'Confirmarei em Breve',
          cancellationText: 'Alterar meus Dados',
        }).then(() => {
          redirectToLanding();
        }).catch(() => {
          history.push(RetaguardaRotasMock.configuracoesRota.path);
        });
        return;
      }
    }
  }, [usuario, confirm, redirectToLanding, history]);

  useEffect(() => {
    const logarOutraAba = async (ev: StorageEvent) => {
      if (ev.key === 'Token' && !logou.current) {
        const tkn = getRegistro(GestaoStorageKeys.Token, false);

        if (convertToken(tkn)!.empresa.length > 1) {
          showAviso('info', 'Você já acessou em outra aba do seu navegador. Feche esta aba e continue usando em sua outra aba.', undefined, true, false, false)
          return
        }

        if (!isEmpty(tkn)) {
          await refreshUser(tkn)
          history.push('/')
        }
      } else if (ev.key === 'warningLogin' && !logou.current) {
        showAviso('info', 'Você já acessou em outra aba do seu navegador. Feche esta aba e continue usando em sua outra aba.', undefined, true, false, false)
      }
    }
    window.addEventListener('storage', logarOutraAba, { once: true })

    return () => {
      window.removeEventListener('storage', logarOutraAba)
    }
  }, [convertToken, getRegistro, history, refreshUser, showAviso])


  return (
    <>
      <PublicPageHeader topOpacity="0.8" />
      <div className={utilClasses.contentCenter}>
        {(!isEmpty(splash) && isMobile) && <SplashScreen alt={usuario!.licenca.RepresentanteNome} imagem={splash} />}
        <Grid container className={utilClasses.rootContainer}>
          {carregando || bloqueado ? (
            <CircularLoading tipo="FULLSIZED" />
          ) : null}
          <Grid item xs={12}>
            <Grid container alignItems="center" flexDirection="column">
              <Grid item xs={10} md={6}>
                <Typography align="center" variant="h3" color="primary">
                  <UserIcon tipo="GERAL" class={classes.imgTitulo} />
                  Login
                </Typography>
                <Box mt={3}>
                  <Typography align="center" variant="body1">
                    Olá bem vindo de volta! Informe seu e-mail para ingressar.
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={8} lg={8} style={{ width: '100%' }}>
                <FormLogin
                  showLoading={false}
                  ref={loginRefs}
                  loading={carregando}
                  onSubmit={(model) => {
                    testeUtf8(model.usuario)
                    efetuarLogin(model);
                  }}
                />
                <Grid item xs={12}>
                  <Grid
                    container
                    flexDirection={isMobile ? 'column' : 'row-reverse'}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Button
                      tabIndex={-1}
                      disabled={carregando || bloqueado}
                      onClick={() => {
                        history.push('/recuperar-senha');
                      }}
                    >
                      <Typography variant="body1" color="primary">
                        Esqueceu sua senha?
                      </Typography>
                    </Button>
                    <Box>
                      <FormControlLabel
                        disabled={carregando || bloqueado}
                        ref={chkManterConectado}
                        control={<CheckboxDefault defaultChecked={true} />}
                        label="Mantenha-me conectado"
                      />
                    </Box>
                  </Grid>
                </Grid>
                <Box mt={5}>
                  <Grid item xs={12}>
                    <Button
                      type="submit"
                      color="primary"
                      variant="contained"
                      fullWidth={true}
                      disabled={carregando || bloqueado}
                      onClick={() => {
                        loginRefs.current?.submitForm();
                      }}
                    >
                      <LoginIcon tipo="BUTTON_PRIMARY" />
                      Acessar
                    </Button>
                  </Grid>
                </Box>
                <Grid item xs={12}>
                  <Box my={1}>
                    <Grid
                      container
                      justifyContent="center"
                      alignItems="center"
                      style={{ color: theme.palette.divider }}
                    >
                      <Grid item style={{ flexGrow: 1 }}>
                        <Divider />
                      </Grid>
                      <Grid item>
                        <Box mx={2}>
                          <Typography
                            variant="h6"
                            color="inherit"
                            style={{ fontWeight: 600 }}
                          >
                            OU
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item style={{ flexGrow: 1 }}>
                        <Divider />
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
                <Grid item xs={12} style={{ color: theme.palette.divider }}>
                  <Button
                    variant="outlined"
                    color="default"
                    fullWidth={true}
                    disabled={carregando || bloqueado}
                    onClick={() => {
                      if (VariaveisAmbiente.paymentDevice === EnumDeviceType.CORDOVA_POS) {
                        history.push('/novo-contrato-redirecionar');
                        return
                      }
                      history.push('/novo-contrato');
                    }}
                  >
                    <NovoUsuarioIcon
                      fill={theme.palette.info.contrastText}
                      tipo="BUTTON"
                    />
                    Cadastre-se
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default LoginPage;
