import { VariaveisAmbiente } from "config";
import { useApiBase } from "data/api/base";
import { ChargeAuthorizationModel } from "model/api/safrapay/checkout/charge-authorization-model";
import { useCallback } from "react";
const bcrypt = require('bcryptjs')

export function usePostChargeAuthorization() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const postChargeAuthorization = useCallback(
        (charge: ChargeAuthorizationModel, merchantCredential: string, merchantToken: string) => {
            const token = `${bcrypt.hashSync("".concat(merchantCredential ?? '').concat(merchantToken ?? ''), 12)}`
            return invocarApi({
                url: `v2/charge/authorization`,
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": token,
                    "merchantCredential": merchantCredential
                },
                data: JSON.stringify(charge),
                enviarTokenUsuario: false,
                baseURL: `https://payment${VariaveisAmbiente.isDev ? "-hml" : ''}.safrapay.com.br/`,
            });
        },
        [invocarApi]
    );

    return {
        ...outrasPropriedades,
        postChargeAuthorization,
    };
}