import { CardNaoEncontrado } from '../../../../../components/cards/card-naoencontrado/card-naoencontrado';
import { InformacaoIcon } from '../../../../../components/icons/informacao-icon';
import { SessaoValorModel } from 'model/api/gestao/sessao/sessao-valor-model';
import { CardSessaoValor } from 'views/components/cards/card-sessao-valor';
import { toDateString } from 'utils/to-date';
import { Grid, Typography } from 'views/design-system';

export interface SessaoValorListDataProps {
  list: Array<SessaoValorModel>;
  carregando: boolean;
}

export const SessaoValorListData = (props: SessaoValorListDataProps) => {
  return (
    <>
      {props.list.length === 0 && !props.carregando && (
        <CardNaoEncontrado
          mensagem="Nenhum registro encontrado."
          icon={<InformacaoIcon tipo="GERAL" />}
        />
      )}

      {props.list.length > 0 &&
        props.list.map((valor, index) => {
          return (
            <>
              {(index === 0 ||
                new Date(props.list[index - 1].dataInsercao).toDateString() !==
                  new Date(valor.dataInsercao).toDateString()) && (
                <Grid xs={12} mt={1} >
                  <Typography align='center'>
                    <b>
                      {' '}
                      {toDateString(
                        new Date(valor.dataInsercao),
                        'DD/MM/yyyy',
                      )}{' '}
                    </b>
                  </Typography>
                </Grid>
              )}
              <CardSessaoValor model={valor} key={index} />
            </>
          );
        })}
    </>
  );
};
