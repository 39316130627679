import { useCallback } from 'react';
import { DefaultCard } from '../components/default-card';
import { useDefaultCardStyles } from '../components/default-card/default-card-styles';
import { useThemeQueries } from '../../..';
import { CardDocumentoFiscalProps } from './card-documento-fiscal-props';
import { bytesToSize } from 'utils/bytes-to-size';
import { toDateString } from 'utils/to-date';
import { EnumRetiradaStatus } from 'model';
import { RetiradaStatusMock } from 'data/mocks/retirada-status-mock';
import { BaixarIcon } from 'views/components/icons';
import { Divider, Tooltip, Typography } from 'views/design-system';
import { Card } from '@material-ui/core';

export const CardDocumentoFiscal = ({
  model,
  onClick,
  onCheck,
  selected,
}: CardDocumentoFiscalProps) => {
  const classes = useDefaultCardStyles();
  const { theme } = useThemeQueries();

  const retornarCorStatus = useCallback((): string => {
    return model.status === EnumRetiradaStatus.CONCLUIDO
      ? theme.palette.success.main :
      model.status === EnumRetiradaStatus.CONCLUIDO ? theme.palette.error.main
      : theme.palette.warning.main;
  }, [model.status, theme.palette.error.main, theme.palette.success.main, theme.palette.warning.main]);

  return (
    <>
      <DefaultCard
        isSelected={selected}
        onClick={async () => {
          onClick(model.id, model.url);
        }}
      >
        <div style={{
          flex: "0 1 100%",
          minWidth: 0,
          maxWidth: '100%'
        }}>
          <div className={classes.cardContent} style={{ marginBottom: 4 }}>
            {model.tamanho > 0 && <div className={classes.celulaGrid}>
              <Typography
                className={'celula-grid-value'}
                color="primary"
                variant="body1"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: '4px 0',
                  width: 100
                }}
              >
                <BaixarIcon tipo='BUTTON' />
                <strong>{bytesToSize(model.tamanho)}</strong>
              </Typography>
            </div>}
            <div className={classes.celulaGridFull}>
              <div style={{ display: 'block' }}>
                <div
                  style={{
                    width: 80,
                    borderRadius: 5,
                    textAlign: 'center',
                    alignItems: 'center',
                    background: retornarCorStatus(),
                  }}
                >
                  <Typography
                    className={'celula-grid-value'}
                    style={{ color: '#FFF' }}
                    variant="caption"
                  >
                    <strong>{RetiradaStatusMock.find(item => item.Key === model.status)?.Value}</strong>
                  </Typography>
                </div>
              </div>
            </div>
            <div className={classes.celulaGrid}>
              <Tooltip
                arrow
                title={
                  model?.url?.length <= 0 ?
                    "Não tem link para download" :
                    "Você pode baixar"}
                placement="left"
              >
                <Card style={{
                  margin: '4px'
                }}>
                  <BaixarIcon
                    tipo='BUTTON'
                    style={{ width: 22 }}
                    fill={
                      model?.url?.length <= 0 ?
                        theme.palette.error.main :
                        theme.palette.primary.main
                    }
                  />
                </Card>
              </Tooltip>
            </div>
          </div>
          <div className={classes.cardContent}>
            <div className={classes.celulaGridFull}>
              <Divider style={{ backgroundColor: "#ECEDED" }} />
            </div>
          </div>

          <div className={classes.cardContent}>
            <div className={classes.celulaGrid}>
              <Typography color="textPrimary" variant="caption">
                Data Inicial
              </Typography>
              <Typography
                className={'celula-grid-value'}
                color="textPrimary"
                variant="body1"
              >
                {toDateString(new Date(model.dInicial))}
              </Typography>
            </div>
            <div className={classes.celulaGridFull}>
              <Typography color="textPrimary" variant="caption">
                Data Final
              </Typography>
              <Typography
                className={'celula-grid-value'}
                color="textPrimary"
                variant="body1"
              >
                {toDateString(new Date(model.dFinal))}
              </Typography>
            </div>
            <div className={classes.celulaGrid}>
              <Typography color="textPrimary" variant="caption">
                Data Criação
              </Typography>
              <Typography
                className={'celula-grid-value'}
                color="textPrimary"
                variant="body1"
              >
                {toDateString(new Date(model.dCriacao), 'DD/MM/yyyy HH:mm')}
              </Typography>
            </div>
          </div>
          {model?.url?.length > 0 && (
            <div className={classes.cardContent}>
              <div className={classes.celulaGrid}>
                <Typography color="textPrimary" variant="caption">
                  URL do Arquivo
                </Typography>
                <Typography
                  className={'celula-grid-value'}
                  color="textPrimary"
                  variant="body1"
                  style={{whiteSpace: 'break-spaces', wordBreak: 'break-all'}}
                >
                  {model.url}
                </Typography>
              </div>
            </div>
          )}
        </div>
      </DefaultCard>
    </>
  );
};
