import { ComandasModel } from "model/api/gestao/comanda/comanda-model"
import { DefaultModal } from "../components"
import { ModalProps } from "../utils"
import { IdentificarComandaConteudo } from "./components/identificar-comanda-conteudo/identificar-comanda-conteudo"

export interface IdentificarComandaModalProps extends ModalProps {
    callback: (comanda: ComandasModel) => Promise<boolean> | boolean
}

export const IdentificarComandaModal = (props: IdentificarComandaModalProps) => {
    return (
        <DefaultModal
            minWidth="400px"
            open={props.openned || false}
            variant={"temporary"}
            anchor="right"
        >
            {props.openned && <IdentificarComandaConteudo {...props} />}
        </DefaultModal>
    )
}