import { TouchoneDBPrimary } from "database/touchone-database"
import { PedidoModel } from "model/api/gestao/pedido/pedido-model"
import { useCadastros } from "services/app"
import { useMovAtual } from "services/app/hooks/mov-atual"
import { useMovRota } from "services/app/hooks/mov-rota"
import { EnumTpTecladoFuncoes, RetornoTecladoProps } from "views/components/modals/teclado-funcoes/components/teclado-funcoes/teclado-funcoes-props"

export const usePedidoPagAntecipado = (pedido: PedidoModel, onCloseClick: () => void) => {

    const { abrirTecladoFuncoes } = useCadastros()
    const { redirectPagamentoAvancado } = useMovRota()
    const { setPedidoExistente } = useMovAtual()

    const novoPagamento = async (model: RetornoTecladoProps) => {
        const { valor } = model
        let codigoMesa: string = '';
        if (pedido.mesaId) {
            const mesa = await TouchoneDBPrimary.mesas.get({
                id: pedido.mesaId
            });
            if (mesa) {
                codigoMesa = mesa.codigo ?? '';
            }
        }

        let codigoComanda: string = '';
        if (pedido.comandaId) {
            const comanda = await TouchoneDBPrimary.comandas.get({
                id: pedido.comandaId
            });
            if (comanda) {
                codigoComanda = comanda.codigoComanda ?? '';
            }
        }
        await setPedidoExistente({
            pedidoId: pedido.id,
            codigoComanda,
            codigoMesa,
            codigoPedido: pedido.codigoPedido,
            comandaId: pedido.comandaId,
            identificador: pedido.identificador,
            mesaId: pedido.mesaId,
            salaoId: pedido.salaoId,
            pagAntecipado: {
                vTotal: valor || 0,
                vPago: 0,
            },
        })
        redirectPagamentoAvancado()
        onCloseClick()
        return true
    }

    const handleValorPagamento = () => {

        const vPago = pedido.pagamentos.reduce((prev, curr) => prev + curr.valorPago, 0)
        abrirTecladoFuncoes({
            id: '',
            isPagFatura: false,
            callback: novoPagamento,
            valor: pedido.valorTotalPedido + (pedido.valorTotalServico || 0) - vPago,
            tipo: EnumTpTecladoFuncoes.Decimal
        })
    }

    return {
        handleValorPagamento,
    }
}