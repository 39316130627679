import { usePostGerarMesa } from "data/api/gestao/mesas/post-gerar-mesa";
import { useConfirm } from "material-ui-confirm";
import { MesasGerarModel } from "model/api/gestao/mesa/mesa-gerar-model";
import { useCallback, useRef } from "react";
import { useCadastros, useSessaoAtual, useToastSaurus } from "services/app";
import { ButtonModalHeader } from "views/components/controles/buttons/button-modal-header";
import { FormMesaGerar } from "views/components/form/mesa/form-mesa-gerar/form-mesa-gerar";
import { DefaultFormRefs } from "views/components/form/utils";
import { SalvarNovoIcon, VoltarIcon } from "views/components/icons";
import { ModalHeader } from "views/components/modals/components";
import { useModalStyles } from "views/components/modals/utils/modal-styles";
import { Button, Grid } from "views/design-system";

interface MesasReconfigurarProps {
    temMesa: boolean;
    salaoId: string;
}

export const MesasReconfigurar = (props: MesasReconfigurarProps) => {
    //AUX
    const confirm = useConfirm();
    const classes = useModalStyles();
    const { showToast } = useToastSaurus();

    //CHAMADAS API
    const { postGerarMesa, carregando: carregandoPost } = usePostGerarMesa();
    const { getEmpresaSelecionada, carregando: carregandoSessaoAtual } = useSessaoAtual();

    const carregando = carregandoPost || carregandoSessaoAtual;

    //PROVIDERS
    const {
        fecharCadastroMesas,
    } = useCadastros();

    //REFS
    const cadMesasFormRef = useRef<DefaultFormRefs<MesasGerarModel>>(null);
    const redirect = useRef<boolean>(true);

    const onCloseClick = useCallback(() => {
        fecharCadastroMesas();
    }, [fecharCadastroMesas]);

    const saveGerarMesa = useCallback(
        async (model: MesasGerarModel) => {
            try {
                const ret = await postGerarMesa(
                    model,
                    getEmpresaSelecionada()?.Id || '',
                    props.salaoId,
                );
                if (ret.erro) {
                    throw ret.erro;
                }

                showToast('success', 'Mesas geradas com sucesso!');

                if (!redirect.current) {
                    onCloseClick();
                }
            } catch (e: any) {
                showToast('error', e.message);
                cadMesasFormRef.current?.fillForm(model);
            }
        },
        [postGerarMesa, getEmpresaSelecionada, props.salaoId, showToast, onCloseClick],
    );

    const handleSubmit = useCallback(
        async (model: MesasGerarModel) => {
            if (props.temMesa) {
                confirm({
                    title: 'Atenção',
                    description:
                        'Ao configurar ou refazer as mesas com a opção de subistituir, você perderá informações de pedidos atrelados à mesa caso tenha, você deseja continuar com o processo?',
                    cancellationText: 'Cancelar',
                    confirmationText: 'Confirmar',
                    confirmationButtonProps: {
                        variant: 'contained',
                        color: 'primary',
                    },
                }).then(() => saveGerarMesa(model));
            } else {
                saveGerarMesa(model);
            }
        },
        [confirm, props.temMesa, saveGerarMesa],
    );

    const submitForm = useCallback((redirectToEdit: boolean) => {
        redirect.current = redirectToEdit;
        cadMesasFormRef.current?.submitForm();
    }, []);

    return (
        <div className={classes.root}>
            <ModalHeader
                title={'Gerar Mesas'}
                leftArea={
                    <ButtonModalHeader
                        tooltip="Voltar"
                        icon={<VoltarIcon tipo="MODAL_HEADER" />}
                        onClick={onCloseClick}
                    />
                }
            />
            <div className={classes.contentForms}>
                <FormMesaGerar
                    ref={cadMesasFormRef}
                    showLoading={false}
                    loading={carregando ?? false}
                    onSubmit={handleSubmit}
                />
            </div>
            <div className={classes.acoes}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                        <Button
                            disabled={carregando}
                            onClick={() => submitForm(false)}
                            variant="contained"
                            color="primary"
                            fullWidth
                        >
                            <SalvarNovoIcon tipo="BUTTON_PRIMARY" />
                            Gerar Mesas
                        </Button>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}