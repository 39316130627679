import { EnumPagTpMod, EnumPagTpTransacao } from "model"
import { useStyles } from "./finalizadora-cadastro-tipo-styles";
import { IModTransacao, TpModMock, TypeTpModTransacao } from "data/mocks";
import { IconCard } from "views/components/cards/components";
import { BoletoIcon, CartaoFidelidadeIcon, CartaoIcon, ChequeIcon, CreditoLojaIcon, DepositoBancarioIcon, DinheiroIcon, DuplicataMercantilIcon, SemDinheiroIcon, TransferenciaBancariaIcon, ValeAlimentacaoIcon, ValeCombustivelIcon, ValePresenteIcon } from "views/components/icons";
import { PixIcon } from "views/components/icons/pix-icon";
import { POSIcon } from "views/components/icons/pos-icon";
import { Grid, Typography } from "views/design-system";
import { isObject } from "lodash";

const compareTpModTransacao = (tipo: IModTransacao, tpModParam: EnumPagTpMod, tpTransacaoParam: EnumPagTpTransacao) => {
    const { tpMod, tpTransacao } = tipo
    return tpMod === tpModParam && tpTransacao === tpTransacaoParam
}

export const retornarIconeFinalizadora = (tipo: TypeTpModTransacao) => {
    if (isObject(tipo)) {
        if (compareTpModTransacao(tipo, EnumPagTpMod.CARTAO_CREDITO, EnumPagTpTransacao.SAFRAPAY_WALLET)) {
            return <CartaoIcon tipo="BUTTON" />;
        }
    }
    switch (tipo) {
        case EnumPagTpMod.BOLETO_BANCARIO:
            return <BoletoIcon tipo="BUTTON" />;
        case EnumPagTpMod.CARTAO_CREDITO:
        case EnumPagTpMod.CARTAO_DEBITO:
            return <CartaoIcon tipo="BUTTON" />;
        case EnumPagTpMod.CHEQUE:
            return <ChequeIcon tipo="BUTTON" />;
        case EnumPagTpMod.CREDITO_LOJA:
            return <CreditoLojaIcon tipo="BUTTON" />;
        case EnumPagTpMod.DEPOSITO_BANCARIO:
            return <DepositoBancarioIcon tipo="BUTTON" />;
        case EnumPagTpMod.DINHEIRO:
            return <DinheiroIcon tipo="BUTTON" />;
        case EnumPagTpMod.DUPLICATA_MERCANTIL:
            return <DuplicataMercantilIcon tipo="BUTTON" />;
        case EnumPagTpMod.PAGAMENTO_INSTANTANEO:
            return <PixIcon tipo="BUTTON" />;
        case EnumPagTpMod.PROGRAMA_FIDELIDADE:
            return <CartaoFidelidadeIcon tipo="BUTTON" />;
        case EnumPagTpMod.SEM_PAGAMENTO:
            return <SemDinheiroIcon tipo="BUTTON" />;
        case EnumPagTpMod.TRANSFERENCIA_BANCARIA:
            return <TransferenciaBancariaIcon tipo="BUTTON" />;
        case EnumPagTpMod.VALE_ALIMENTACAO:
        case EnumPagTpMod.VALE_REFEICAO:
            return <ValeAlimentacaoIcon tipo="BUTTON" />;
        case EnumPagTpMod.VALE_COMBUSTIVEL:
            return <ValeCombustivelIcon tipo="BUTTON" />;
        case EnumPagTpMod.VALE_PRESENTE:
            return <ValePresenteIcon tipo="BUTTON" />;

        default:
            return <POSIcon tipo="BUTTON" />;
    }
}

interface FinalizadoraCadastroTipoProps {
    onConfirm: (tipo: TypeTpModTransacao) => void;
}

export const FinalizadoraCadastroTipo = ({
    onConfirm
}: FinalizadoraCadastroTipoProps) => {
    const classes = useStyles();
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography color='textSecondary' align='center'>Selecione uma Forma de Pagamento para Cadastrar:</Typography>
            </Grid>
            {TpModMock.map(item => (
                <Grid item xs={4} className={classes.cardContainer}>
                    <IconCard<TypeTpModTransacao>
                        icon={retornarIconeFinalizadora(item.Key)}
                        title={item.Value}
                        model={item.Key}
                        onClick={onConfirm}
                        wrapText
                        isOpacity={true}
                    />
                </Grid>
            ))}
        </Grid>
    )
}