import { DialogSaurus } from "../dialog-saurus/dialog-saurus";
import { isEmpty } from "lodash";
import { DialogUsuariosConvidar } from "./usuarios-convidar/dialog-usuarios-convidar";
import { DialogUsuarioEdit } from "./usuario-edit/dialog-usuario-edit";
import { EmpresaUsuarioModel } from "model";
import { useThemeQueries } from "views/theme";

export interface DialogUsuarioProps {
    openned: boolean;
    model?: EmpresaUsuarioModel;
    email?: string
    pessoaId?: string
}

export const DialogUsuario = ({ model, openned, email, pessoaId }: DialogUsuarioProps) => {
    const { theme } = useThemeQueries()

    return (
        <>
            {openned &&
                <DialogSaurus
                    aberto={openned || false}
                    carregando={false}
                    titulo={openned && isEmpty(model) ? "Convidar Novo Usuário" : "Editar Usuário"}
                    colorTitulo={theme.palette.primary.main}
                    centralizarTitulo
                    tamanho="sm"
                >
                    {openned && isEmpty(model) && (<DialogUsuariosConvidar email={email} aberto={openned} pessoaId={pessoaId} />)}
                    {openned && model && (<DialogUsuarioEdit model={model} aberto={openned} />)}
                </DialogSaurus>
            }
        </>
    );
};
