import { Dialog } from '@material-ui/core';
import { DialogProps } from '@material-ui/core/Dialog';
import React, { useCallback } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { useStyles } from './dialog-scanner-gerar-comanda-styles';
import Scanner from 'views/pages/private/movimentacao/mov-prod-leitor/components/scanner/scanner';
import { useToastSaurus } from 'services/app';
import { isEmpty } from 'lodash';
import { isOnlyHasNumber } from 'utils/to-decimal';
import { EnumEmpresaConfig } from 'model/enums/enum-empresa-config';
import { useEmpresaAtual } from 'services/app/hooks/empresa-atual';
import { Typography } from 'views/design-system';


interface DialogScannerGerarComandaProps {
    openned?: boolean;
    loading?: boolean;
    closeModal: () => void;
    zIndex?: string;
    tamanho?: DialogProps['maxWidth'];
    handleScan: (value: string) => any;
}

export const DialogScannerGerarComanda = ({
    closeModal,
    ...props
}: DialogScannerGerarComandaProps) => {
    const classes = useStyles();
    const { getConfigByCod } = useEmpresaAtual();
    const { showToast } = useToastSaurus();

    const confirmarComanda = useCallback(
        async (codComanda: string) => {
            try {
                let comanda: any = codComanda;
                if (codComanda.includes('https') || codComanda.includes('http') || codComanda.includes('localhost')) {
                    const url = new URL(codComanda)
                    codComanda = url.pathname.split('/')[2] ? url.pathname.split('/')[2] : ''
                } else {
                    const buscaPrefixo = getConfigByCod(EnumEmpresaConfig.PrefixoDeComanda);
                    const prefixo = buscaPrefixo?.toUpperCase();

                    if(comanda?.startsWith(prefixo)) {
                      comanda = comanda.slice(prefixo?.length);
                    }
                  
                    if (!isEmpty(prefixo) && codComanda?.startsWith(prefixo!)) {
                        if (isOnlyHasNumber(comanda)) {
                            comanda = Number(comanda);
                        }
                    }
            
                }
                props.handleScan(comanda)

            } catch (err: any) {
                showToast('error', err.message);
            }
        },
        [getConfigByCod, props, showToast],
    );

    const leituraRealizada = useCallback(
        (novoCod: string) => {
            confirmarComanda(novoCod);
        },
        [confirmarComanda],
    );

    return (
        <Dialog
            maxWidth={props.tamanho || 'md'}
            open={props.openned || false}
            fullWidth
            keepMounted
            className={classes.root}
            disableEscapeKeyDown
            ref={(el: any) => {
                if (el && (props.zIndex || '').length > 0) {
                    el.style.setProperty('z-index', props.zIndex, 'important');
                }
            }}
        >
            <div style={{ padding: 8 }}>
                <Typography
                    style={{
                        textAlign: 'center',
                        margin: '0 0 24px 0',
                        fontWeight: '600',
                        background: 'rgb(0 0 0 / 50%)',
                        padding: 12,
                        color: '#FFF',
                        borderRadius: 8,
                    }}
                >
                    Posicione o Código de barras na marcação
                </Typography>
                <div style={{ borderRadius: 8, overflowY: 'hidden' }}>
                    {props.openned && (
                        <Scanner onScan={leituraRealizada} inicialOrSecundario={false} openned={props.openned} />
                    )}
                </div>
                <div style={{ justifyContent: 'center', display: 'flex' }}>
                    <CloseIcon
                        onClick={() => {
                            closeModal();
                        }}
                        style={{ fontSize: 50, marginTop: 16, cursor: 'pointer' }}
                    />
                </div>
            </div>
        </Dialog>
    );
};