import { isEmpty } from "lodash";
import { MovSimplesModel } from "model/api/gestao/movimentacao/simples/mov-simples-model";
import { useCallback } from "react";
import { useCadastroPadrao } from "./cadastro-padrao";
import { usePedidoLocal } from "./pedido-local";
import { useEmpresaAtual } from "./empresa-atual";
import { TouchoneDBPrimary } from "database/touchone-database";
import { EnumBalcaoSalao } from "model/enums/enum-balcao-salao";
import { EnumStatusSalao } from "model/enums/enum-status-salao";
import { EnumEmpresaConfig } from "model/enums/enum-empresa-config";
import { EnumMovModelo } from "model";
import { guidEmpty } from "utils/guid-empty";
import { useContratoAtual } from "./contrato-atual";
import { EnumContratoConfig } from "model/enums/enum-contrato-config";
import { getModoPedidoIdentificador } from "config/environment-variables";
import { EnumConfigIdentificadorMesa } from "model/enums/enum-config-identificador-mesa";

export function useMovValidators() {
    const { getConfigByCod: getConfigEmp } = useEmpresaAtual();
    const { getConfiguracoesMesaEComanda } = usePedidoLocal();
    const { getConsumidor } = useCadastroPadrao();
    const { getConfigByCod: getConfigContrato } = useContratoAtual();

    const permiteEditarPedidoMesa = useCallback(async () => {
        const configMesaEComanda = getConfiguracoesMesaEComanda();
        const mesas = configMesaEComanda?.qtdeMesa || 0
        const saloes = await TouchoneDBPrimary.saloes.toArray()
        const balcoes = saloes.filter(salao => salao.balcao?.codigo === EnumBalcaoSalao.UTILIZA &&
            salao.status?.codigo === EnumStatusSalao.ATIVO)
        const temBalcaoOuMesa = mesas > 0 || balcoes.length > 0;
        return temBalcaoOuMesa;
    }, [getConfiguracoesMesaEComanda]);

    const permiteEditarPedidoComanda = useCallback(async () => {
        const configMesaEComanda = getConfiguracoesMesaEComanda();
        const comandas = configMesaEComanda?.qtdeComandas || 0
        return comandas > 0;
    }, [getConfiguracoesMesaEComanda]);

    const permiteEditarPedidoIdentificador = useCallback(async () => {
        const configMesaEComanda = getConfiguracoesMesaEComanda();
        const pedirIdentificadorComMesa = getModoPedidoIdentificador();
        const mesas = configMesaEComanda?.qtdeMesa || 0
        const comandas = configMesaEComanda?.qtdeComandas || 0
        const saloes = await TouchoneDBPrimary.saloes.toArray()
        const balcoes = saloes.filter(salao => salao.balcao?.codigo === EnumBalcaoSalao.UTILIZA &&
            salao.status?.codigo === EnumStatusSalao.ATIVO)
        const temBalcaoOuMesa = mesas > 0 || balcoes.length > 0;
        return comandas === 0 && (pedirIdentificadorComMesa === EnumConfigIdentificadorMesa.PEDIR_IDENTIFICADOR ? true : !temBalcaoOuMesa);
    }, [getConfiguracoesMesaEComanda]);

    const dadosClientePendente = useCallback(async (movAtual: MovSimplesModel | undefined) => {
        if (movAtual === undefined)
            return false;

        const consumidorPadrao = await getConsumidor();
        const identificarClienteObrigatorio = getConfigEmp(EnumEmpresaConfig.AceitarSomenteClienteCadastrado) !== "0";

        if (movAtual.mod === EnumMovModelo.DELIVERY) {
            return (movAtual.mod === EnumMovModelo.DELIVERY && (!(movAtual.clienteIdentificado) || isEmpty(movAtual.cliente?.endereco)))
        }
        return !((movAtual?.clienteIdentificado && movAtual.mod !== EnumMovModelo.NFE) ||
            ((movAtual.mod === EnumMovModelo.NFE || identificarClienteObrigatorio) && movAtual.clienteIdentificado &&
                ![consumidorPadrao?.id, guidEmpty()].includes(movAtual.cliente?.id)));

    }, [getConfigEmp, getConsumidor]);

    const dadosPedidoMesaPendente = useCallback(async (movAtual: MovSimplesModel) => {
        const mesaVazia = isEmpty(movAtual.informacoesGeraisPedido?.codigoMesa);
        const balcaoVazio = !movAtual.informacoesGeraisPedido.balcaoIdentificado || isEmpty(movAtual.informacoesGeraisPedido.salaoId);
        const permitePedidoMesa = await permiteEditarPedidoMesa();
        return (balcaoVazio && mesaVazia && permitePedidoMesa)

    }, [permiteEditarPedidoMesa]);

    const dadosProdutosPendente = useCallback(async (movAtual: MovSimplesModel) => {
        return movAtual.vNF === 0;
    }, [])

    const dadosFaturamentoPendente = useCallback(async (movAtual: MovSimplesModel) => {
        return !movAtual.isFaturada
    }, []);

    const dadosPagamentoPendente = useCallback(async (movAtual: MovSimplesModel) => {
        return movAtual.vPago < movAtual.vNF && movAtual.vNF > 0 && movAtual?.mod !== EnumMovModelo.PEDIDO
    }, []);

    const dadosPedidoComandaPendente = useCallback(async (movAtual: MovSimplesModel) => {
        return (isEmpty(movAtual.informacoesGeraisPedido?.comandaId) && await permiteEditarPedidoComanda())
    }, [permiteEditarPedidoComanda]);

    const dadosPedidoIdentificadorPendente = useCallback(async (movAtual: MovSimplesModel) => {
        return (movAtual.informacoesGeraisPedido?.identificador === undefined && await permiteEditarPedidoIdentificador());
    }, [permiteEditarPedidoIdentificador]);

    const dadosIniciaisPendente = useCallback(async (movAtual: MovSimplesModel | undefined) => {
        return movAtual === undefined;
    }, []);

    const dadosProdutoEntregaPendente = useCallback((movAtual: MovSimplesModel) => {
        const produtoEntrega = getConfigContrato(EnumContratoConfig.ProdutoEntrega);
        const hasProdEntrega = movAtual.produtos.filter(x => x.produtoId === produtoEntrega).length > 0;

        return !(hasProdEntrega);
    }, [getConfigContrato]);

    return {
        dadosClientePendente,
        dadosFaturamentoPendente,
        dadosIniciaisPendente,
        dadosPedidoComandaPendente,
        dadosPedidoIdentificadorPendente,
        dadosPedidoMesaPendente,
        dadosProdutosPendente,
        dadosPagamentoPendente,
        dadosProdutoEntregaPendente,

        permiteEditarPedidoMesa,
        permiteEditarPedidoComanda,
        permiteEditarPedidoIdentificador,
    }

}
