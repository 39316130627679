import React, { useRef, useState } from 'react';
import { PrivatePageHeader } from 'views/components/headers';
import { ButtonPrivateHeader } from 'views/components/controles';
import { AvancarIcon, VoltarIcon } from 'views/components/icons';
import { useDefaultCadastroStyles } from 'views/pages/private/cadastros/components/default-cadastro-styles';
import { useStyles } from '../opcoes-entrada-styles';
import { useFieldArray, useFormContext } from 'react-hook-form';
import {
  OpcoesEntradaImportacaoXMLPagamentoTemplateProps,
  tipoEdit
} from './opcoes-entrada-importacao-xml-template-interfaces';
import FormEntradaCadastroNovoPagamento from 'views/components/form/entrada/entrada-cadastro-xml/form-entrada-cadastro-xml-novo-pagamento';
import { DupFormModel, FormEntradaCadastroNovoPagamentoResumo } from 'views/components/form/entrada/entrada-cadastro-xml/form-entrada-cadastro-xml-novo-pagamento-resumo';
import FormEntradaCadastroNovoParcelamento from 'views/components/form/entrada/entrada-cadastro-xml/form-entrada-cadastro-xml-novo-parcelamento';
import { DefaultFormRefs } from 'views/components/form/utils';
import { EnumPagTpMod } from 'model';
import { useSalvarXML } from '../../../hooks/salvar-xml';
import { VendaCompletaXMLModel } from 'model/api/gestao/venda/venda-completa-xml-model';
import { Box, Button, Divider, Grid, Typography } from 'views/design-system';
import { Container } from '@material-ui/core';

const NaoPermiteParcelamento = [
  EnumPagTpMod.DINHEIRO,
  EnumPagTpMod.VALE_ALIMENTACAO,
  EnumPagTpMod.VALE_REFEICAO,
  EnumPagTpMod.VALE_COMBUSTIVEL,
  EnumPagTpMod.VALE_PRESENTE,
  EnumPagTpMod.CHEQUE,
  EnumPagTpMod.DEPOSITO_BANCARIO
]

const enum PaymentModes {
  Dados = 0,
  Parcelamento = 1,
  Resumo = 2
}

export default function OpcoesEntradaImportacaoXMLIPagamentosTemplate({
  carregando: carregandoExterno,
  paymentEditing,
  setEditing
}: Readonly<OpcoesEntradaImportacaoXMLPagamentoTemplateProps>): React.ReactElement {
  const cadClasses = useDefaultCadastroStyles();
  const classes = useStyles();
  const [paymentMode, setPaymentMode] = useState(0);

  const { saveChangesXML, carregando: carregandoSalvar } = useSalvarXML();

  const carregando = carregandoExterno || carregandoSalvar;

  const parcelamentoRef = useRef<DefaultFormRefs<DupFormModel>>(null);

  const { control, getValues, setValue } = useFormContext<VendaCompletaXMLModel>();

  const { fields: pagamentos, remove: removePagamento } = useFieldArray({
    control,
    name: 'infMov.pag'
  });


  const [parcelaSelected, setParcelaSelected] = useState<number | null>(
    getValues(`infMov.pag.${paymentEditing.index}.qtdeParcela`)
  );

  const nextPayment = () => {
    setPaymentMode((prevActiveStep) => prevActiveStep + 1);
  };

  const backPayment = () => {
    setPaymentMode((prevActiveStep) => prevActiveStep - 1);
  };

  const handleShowStepper = () => {
    setEditing({
      tela: tipoEdit.Stepper
    });
  };

  const handleBack = () => {
    if (paymentMode === 0) {
      if (paymentEditing.isCreating) {
        removePagamento(paymentEditing.index);
      }
      handleShowStepper();
    } else {
      const tpMod = getValues(`infMov.pag.${paymentEditing.index}.tPag`);
      if (paymentMode === 2 && NaoPermiteParcelamento.includes(tpMod)) {
        setPaymentMode(0);
        return
      }
      backPayment();
    }
  };

  const handleNext = () => {
    if (paymentMode === PaymentModes.Resumo) {
      parcelamentoRef.current?.submitForm();
    } else {
      const tpMod = getValues(`infMov.pag.${paymentEditing.index}.tPag`);
      if (NaoPermiteParcelamento.includes(tpMod) && paymentMode === 0) {
        setParcelaSelected(1)
        setPaymentMode(prev => prev + 2);
        return
      }
      nextPayment();
    }
  };

  const handleSubmit = async (model: DupFormModel) => {
    setValue(`infMov.cobr.dup`, model.dups);
    setValue(`infMov.pag.${paymentEditing.index}.qtdeParcela`, parcelaSelected || 1)

    await saveChangesXML(getValues())

    handleShowStepper()
  }

  const leftArea = () => (
    <ButtonPrivateHeader
      icon={<VoltarIcon tipo="PRIVATE_HEADER" />}
      tooltip="Menu"
      onClick={handleBack}
    ></ButtonPrivateHeader>
  );

  const handleTitle = (): string => {
    if (paymentMode === 1) {
      return 'Parcelamento';
    }

    if (paymentMode === 2) {
      const tpMod = getValues(`infMov.pag.${paymentEditing.index}.tPag`);
      if (NaoPermiteParcelamento.includes(tpMod)) {
        return 'Visualização do Pagamento';
      }
      return 'Visualização do Parcelamento';
    }

    return 'Dados de Pagamento';
  };

  return (
    <>
      {pagamentos?.map((pagamento, index) => {
        const key = `pagamento-${pagamento.id}__${index}`;

        if (paymentEditing.index === index) {
          return (
            <React.Fragment key={key}>
              <Grid className={cadClasses.header}>
                <PrivatePageHeader
                  title={handleTitle()}
                  leftArea={leftArea()}
                />
              </Grid>

              <Container maxWidth="md" className={classes.container}>
                {paymentMode === PaymentModes.Dados ? (
                  <FormEntradaCadastroNovoPagamento
                    index={paymentEditing.index}
                    handleNext={handleNext}
                    remainingValue={paymentEditing.remainingValue}
                    isCreating={paymentEditing.isCreating}
                  />
                ) : null}

                {paymentMode === PaymentModes.Resumo ? (
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Box pb={1}>
                        <Typography variant="h6">Dados</Typography>
                        <Divider />
                      </Box>
                    </Grid>
                    <FormEntradaCadastroNovoPagamentoResumo
                      index={paymentEditing.index}
                      parcelaSelected={parcelaSelected as number}
                      loading={false}
                      showLoading={false}
                      onSubmit={handleSubmit}
                      ref={parcelamentoRef}
                    />
                  </Grid>
                ) : null}

                {paymentMode === PaymentModes.Parcelamento ? (
                  <FormEntradaCadastroNovoParcelamento
                    index={paymentEditing.index}
                    setParcelaSelected={setParcelaSelected}
                    parcelaSelected={parcelaSelected}
                  />
                ) : null}

                {paymentMode !== PaymentModes.Dados ? (
                  <Box pt={4}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Button
                          disabled={carregando}
                          onClick={handleBack}
                          variant="outlined"
                          color="primary"
                          size="large"
                          fullWidth
                        >
                          <VoltarIcon tipo="BUTTON" />
                          {paymentMode === PaymentModes.Dados
                            ? 'Voltar'
                            : 'Anterior'}
                        </Button>
                      </Grid>

                      <Grid item xs={6}>
                        <Button
                          disabled={carregando}
                          onClick={handleNext}
                          variant="contained"
                          color="primary"
                          size="large"
                          fullWidth
                        >
                          <AvancarIcon tipo="BUTTON_PRIMARY" />
                          {paymentMode === PaymentModes.Resumo
                            ? 'Concluir'
                            : 'Avançar'}
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                ) : null}
              </Container>
            </React.Fragment>
          );
        }

        return <></>;
      })}
    </>
  );
}
