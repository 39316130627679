import { MovRegistrarResponseModel } from 'model/api/gestao/mov-pag/mov-registrar-response';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { useCallback, useEffect, useState } from 'react';
import { useToastSaurus } from 'services/app';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { DialogDownloadBoletos } from 'views/components/dialog/dialog-download-boletos/dialog-download-boletos';

class DownloadBoletoDialogProps {
    constructor(
        public aberto: boolean = false,
        public boletos: MovRegistrarResponseModel = new MovRegistrarResponseModel(),
        public callback: () => void = () => { },
    ) { }
}

export const DownloadBoletoDialog = () => {

    const { addHandler, removeHandler } = useEventTools();
    const { showToast } = useToastSaurus();

    const [modalState, setModalState] = useState<DownloadBoletoDialogProps>(new DownloadBoletoDialogProps());

    const modalAlterado = useCallback((props: DownloadBoletoDialogProps) => {
        setModalState({ ...props });
    }, []);

    useEffect(() => {
        addHandler(AppEventEnum.DownloadBoletoDialog, modalAlterado);

        return () => {
            removeHandler(AppEventEnum.DownloadBoletoDialog, modalAlterado);
        };
    }, [addHandler, modalAlterado, removeHandler, showToast]);

    return (
        <>
            {modalState.aberto && (
                <DialogDownloadBoletos
                    aberto={modalState.aberto}
                    boletos={modalState.boletos}
                    callback={modalState.callback}
                />
            )}
        </>
    );
};