import { CredenciamentoBoleto } from "model/api/gestao/finalizadora/finalizadora-model";
import { EnumPagTpMod, EnumPagVencimentoTipo, EnumPagTpTransacao } from "model/enums";
import { CredenciamentoPagamento } from "services/app/hooks/mov-atual";
import { guidEmpty } from "utils/guid-empty";

export class FinalizadoraCadastroFormModel {
  constructor(
    public descricao: string = "",
    public tpMod: EnumPagTpMod = EnumPagTpMod.DINHEIRO,
    public empresaId: string | null = guidEmpty(),
    public qMaxParc: number = 1,
    public vMinParc: number = 0,
    public ordem: number = 0,
    public vencimento: EnumPagVencimentoTipo | null = null,
    public credenciais?: CredenciamentoPagamento,
    public credenciamentoBoleto?: CredenciamentoBoleto,
    public tpTransacao?: EnumPagTpTransacao,
  ) { }
}
