import { useThemeQueries } from "views"
import { GestaoStorageKeys, useGestaoStorage } from "./gestao-storage";
import { useCallback } from "react";

export enum EnumDesktopMode {
    DESATIVADO = 'false',
    ATIVADO = 'true',
    DINAMICO = 'dinamico',
}
/*
 criei esse hook pra ter um controle a mais do modo desktop e quem sabe
 se um dia calhar tornar ele uma configuração ou algo personalizável, em vez de
 usar somente o isMobile
 */
export const useDesktopMode = () => {
    const { isMobile } = useThemeQueries();
    const { getRegistro, setRegistro, delRegistro } = useGestaoStorage();

    const setDesktopMode = useCallback((modo: EnumDesktopMode) => {
        if (modo === EnumDesktopMode.DINAMICO) {
            delRegistro(GestaoStorageKeys.DesktopMode, false);
            return
        }
        setRegistro(GestaoStorageKeys.DesktopMode, modo === EnumDesktopMode.ATIVADO ? true : false, false);
    }, [delRegistro, setRegistro])

    const getDesktopMode = useCallback((considerarOrientacaoTela: boolean = true): boolean => {
        const mode = getRegistro(GestaoStorageKeys.DesktopMode, false);
        if (!considerarOrientacaoTela) {
            return mode;
        }
        if (mode === undefined) {
            return !isMobile;
        }
        return mode && !isMobile;
    }, [getRegistro, isMobile])

    return {
        getDesktopMode,
        setDesktopMode,
    }
}