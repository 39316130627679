import { CardNaoEncontrado } from 'views/components/cards';
import { InformacaoIcon } from 'views/components/icons';
import CardMesaAtendimento from 'views/components/cards/card-mesa-atendimento/card-mesa-atendimento';
import { PedidoMesaProcessadaModel } from 'model/api/gestao/pedido/pedido-mesa-processada';
import { guidEmpty } from 'utils/guid-empty';
import { SaloesModel } from 'model/api/gestao/saloes/saloes-model';
import { useStyles } from '../list-styles';
import { PedidoModel } from 'model/api/gestao/pedido/pedido-model';
import { Grid, Typography } from 'views/design-system';
import { Tooltip } from '@material-ui/core';

export interface MesasListDataProps {
  list: Array<PedidoMesaProcessadaModel>;
  carregando: boolean;
  onCardSelected: (mesa: PedidoMesaProcessadaModel) => any;
  onCardHolded: (pedido: PedidoModel) => void;
  salaoAtualId: string;
  saloes: SaloesModel[];
}

export const MesasListData = (props: MesasListDataProps) => {
  const classes = useStyles();

  const onCardSelected = (mesa: PedidoMesaProcessadaModel) => {
    props.onCardSelected(mesa);
  };

  const onCardHolded = async (pedido: PedidoModel) => {
    props.onCardHolded(pedido);
  };

  const mesas = props.list.filter(mesa => !mesa.isDeprecated);
  const mesasObsoletas = props.list.filter(mesa => mesa.isDeprecated);

  return (
    <>
      {props.list.length === 0 && !props.carregando && (
        <Grid item xs={12}>
          <CardNaoEncontrado
            mensagem="Nenhuma Mesa encontrada."
            icon={<InformacaoIcon tipo="GERAL" />}
          />
        </Grid>
      )}
      <Grid item spacing={2} className={classes.containerListCard}>
        {props.list.length > 0 &&
          <>
            {mesas.map((mesa, index) => {
              return (
                <>
                  {props.salaoAtualId === guidEmpty() &&
                    (index === 0 ||
                      props.list[index - 1].salaoId !== mesa.salaoId) && (
                      <>
                        <Grid xs={12} style={{ margin: '8px 8px' }}>
                          <Typography>
                            Salão - <b>{mesa.nomeSalao}</b>
                          </Typography>
                        </Grid>
                        <br />
                      </>
                    )}
                  <CardMesaAtendimento
                    model={mesa}
                    key={mesa.idMesa}
                    onClick={onCardSelected}
                    selected={false}
                    onHold={onCardHolded}
                  />
                </>
              );
            })}
            {mesasObsoletas.length > 0 && (
              <>
                <Grid xs={12} style={{ margin: '8px 8px', display: 'flex', gap: '8px', alignItems: 'center' }}>
                  <Typography>
                    Pedidos com mesas obsoletas.
                  </Typography>
                  <Tooltip title='Esses são pedidos de mesas que foram excluídas da base de dados e os pedidos não foram finalizados.'>
                    <div>
                      <InformacaoIcon tipo='INPUT' />
                    </div>
                  </Tooltip>
                </Grid>
                <br />
                {mesasObsoletas.map((mesa) => {
                  return (
                    <>
                      <CardMesaAtendimento
                        model={mesa}
                        key={mesa.idMesa}
                        onClick={onCardSelected}
                        selected={false}
                        onHold={onCardHolded}
                      />
                    </>
                  );
                })}
              </>
            )}
          </>
        }
      </Grid>
    </>
  );
};
