import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    tipo: {
        marginBottom: theme.spacing(2)
    },
    botao: {
        marginLeft:'.5rem',
        width:'10rem',
        [theme.breakpoints.down("sm")]: {
            width:'9rem'
        }
    },
    botoes: {
        width:'100%',
        textAlign:'right',
        marginBottom: theme.spacing(2),
        [theme.breakpoints.down("sm")]: {
           textAlign:'center',
           display: 'flex',
           justifyContent: 'space-between'
        }
    }
}));
