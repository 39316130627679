import { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';

import { useDefaultCadastroStyles } from 'views/pages/private/cadastros/components/default-cadastro-styles';
import { useSessaoAtual, useToastSaurus } from 'services/app';
import { DupCobrModel, PagsModel, ProdsModel, TotalModel, VendaCompletaModel } from 'model/api/gestao/venda/venda-completa-model';
import { CircularLoading } from 'views/components';
import OpcoesEntradaImportacaoXMLResumeTemplate from './template/opcoes-entrada-importacao-xml-resume-template';
import OpcoesEntradaImportacaoXMLStepperTemplate from './template/opcoes-entrada-importacao-xml-stepper-template';
import OpcoesEntradaImportacaoXMLItensTemplate from './template/opcoes-entrada-importacao-xml-itens-template';
import { useGetNovaEntradaXML } from 'data/api/gestao/venda/get-nova-entrada-xml';
import {
  IEditing,
  Steppers,
  tipoEdit
} from './template/opcoes-entrada-importacao-xml-template-interfaces';
import OpcoesEntradaImportacaoXMLIPagamentosTemplate from './template/opcoes-entrada-importacao-xml-pagamento-template';
import { usePutConfirmarEntradaXML } from 'data/api/gestao/venda/put-confirmar-entrada-xml';
import { EnumCadastroTipo, EnumIndEscala, RetornoApiModel } from 'model';
import { guidEmpty } from 'utils/guid-empty';
import { StatusSituacaoMock } from 'data/mocks/status-situacao-mock';
import { EnumTpStatusMov } from 'model/enums/enum-tp-status-mov';
import { isEmpty, isEqual } from 'lodash';
import { useStyles } from './opcoes-entrada-styles'
import { ProdsXMLModel, ProdsXMLPutModel, VendaCompletaXMLModel } from 'model/api/gestao/venda/venda-completa-xml-model';
import { usePostProduto } from 'data/api/gestao/produto/produto/post-produto';
import { usePostProdutoCodigo } from 'data/api/gestao/produto/produto-codigo/post-produto-codigo';
import { ProdutoNovoModel } from 'model/api/gestao/produto/produto/produto-novo-model';
import { ProdutoCodigoModel } from 'model/api/gestao/produto/produto-codigo/produto-codigo-model';
import { useGetProdutoById } from 'data/api/gestao/produto/produto/get-produto-by-id';
import { ProdutoCompletoModel } from 'model/api/gestao/produto/produto/produto-completo-model';
import { picker } from 'utils/picker';
import { toDecimal } from 'utils/to-decimal';
import { newGuid } from 'utils/new-guid';
import { usePostEnderecoPessoa, usePostPessoa } from 'data/api/gestao/pessoa';
import { PessoaEnderecoModel, PessoaModel } from 'model/api/gestao/pessoa';
import { useGetPessoaByDoc } from 'data/api/gestao/pessoa/get-pessoa-by-doc';
import { usePutProdutoVariacao } from 'data/api/gestao/produto/produto-variacao/put-produto-variacao';
import { ProdutoAtualizarModel } from 'model/api/gestao/produto/produto/produto-atualizar-model';
import { useGetNcms } from 'data/api/gestao/ncm';
import { NcmModel } from 'model/api/gestao/ncm/ncm-model';
import { useGetProdutoMedidas } from 'data/api/gestao/produto/produto-medida/get-produto-medidas';
import { ProdutoMedidaModel } from 'model/api/gestao/produto/produto-medida/produto-medida-model';
import { usePostProdutoMedida } from 'data/api/gestao/produto/produto-medida/post-produto-medida';
import { ProdutoAtualizarVariacaoModel } from 'model/api/gestao/produto/produto-variacao/produto-atualizar-variacao-model';
import { usePutProdutoCodigo } from 'data/api/gestao/produto/produto-codigo/put-produto-codigo';
import { useGetProdutoCodigo } from 'data/api/gestao/produto/produto-codigo/get-produto-codigo';
import { useGetProdutoVariacao } from 'data/api/gestao/produto/produto-variacao/get-produto-variacao';
import { ProdutoVariacaoModel } from 'model/api/gestao/produto/produto-variacao/produto-variacao';
import { usePutProdutoPreco } from 'data/api/gestao/produto/produto-preco/put-produto-preco';
import { usePutDocumentoIntegrar } from 'data/api/gestao/empresa-documento/put-documento-integrar';
import { EnumRetornoApiBase } from 'data/api/base/api-base-response';
import { LoadingStepProps, OpcoesEntradaImportacaoXMLLoading } from './template/opcoes-entrada-importacao-xml-loading';
import { useConfirmSaurus } from 'services/app/hooks/confirm-saurus';
import { UFMock } from 'data/mocks';
import { useShowAviso } from 'services/app/hooks/show-aviso';
import { ValidacaoRetornoModel, usePostValidarEntradaXML } from 'data/api/gestao/venda/post-validar-entrada-xml';
import { ValidacaoXMLMock } from 'data/mocks/validacao-xml-mock';
import { useSalvarXML } from '../../hooks/salvar-xml';
import { EnumOrigemEmissao } from 'model/enums/enum-origem-emissao';
import { EnumTpNf } from 'model/enums/enum-tp-nf';
import { usePutProduto } from 'data/api/gestao/produto/produto/put-produto';
import { EnumTipoProduto } from 'model/enums/enum-tipo-produto';
import { useThemeQueries } from 'views/theme';
import { useGetDepositoEmpresa } from 'data/api/gestao/deposito';
import { DepositoEmpresaModel } from 'model/api/gestao/deposito/deposito-empresa-model';
import { Grid } from 'views/design-system';
import { tpPagamentoResolver } from 'utils/tp-pagamento-resolver';

interface ItensCadastradosModel {
  produtos: {
    principal?: boolean;
    codigoInterno?: boolean;
    codigo?: boolean;
    codigoTrib?: boolean;
    medida?: boolean;
    preco?: boolean;
    variacao?: boolean;
  }[];
  pessoas: {
    emit?: boolean;
    trans?: boolean;
  },
  finalizacao: boolean;
  integracao: boolean;
}

function formatDateToDateTimeInput(value: string) {
  const date = new Date(value);
  const year = date.getFullYear();
  const month = `${date.getMonth() + 1}`.padStart(2, '0');
  const day = `${date.getDate()}`.padStart(2, '0');
  const hours = `${date.getHours()}`.padStart(2, '0');
  const minutes = `${date.getMinutes()}`.padStart(2, '0');

  return `${year}-${month}-${day}T${hours}:${minutes}`;
}

const ImportacaoEntradaXMLPage = () => {
  const cadClasses = useDefaultCadastroStyles();
  const classes = useStyles();
  const { theme } = useThemeQueries();
  const { showAviso } = useShowAviso();
  const { showToast } = useToastSaurus();
  const { showConfirm } = useConfirmSaurus();
  const { saveChangesXML } = useSalvarXML();

  const { getEmpresaSelecionada } = useSessaoAtual();
  const history = useHistory();

  const location = history.location.state as { dtManifesto?: string };

  const urlParams = new URLSearchParams(history.location.search);
  const integrar = Boolean(urlParams.get('integrar'));

  const { getNovaEntradaXML, carregando: carregandoGet } = useGetNovaEntradaXML();
  const { postProduto } = usePostProduto();
  const { postProdutoCodigo } = usePostProdutoCodigo();
  const { putProdutoCodigo } = usePutProdutoCodigo();
  const { getProdutoById } = useGetProdutoById();
  const { getProdutoCodigo } = useGetProdutoCodigo();
  const { getPessoaByDoc, carregando: carregandoPessoa } = useGetPessoaByDoc();
  const { postPessoa } = usePostPessoa();
  const { postEnderecoPessoa } = usePostEnderecoPessoa();
  const { getNcms } = useGetNcms();
  const { getDepositoEmpresa } = useGetDepositoEmpresa();
  const { putProduto } = usePutProduto();
  const { putProdutoVariacao } = usePutProdutoVariacao();
  const { putProdutoPreco } = usePutProdutoPreco();
  const { postProdutoMedida } = usePostProdutoMedida();
  const { getProdutoMedidas } = useGetProdutoMedidas();
  const { getProdutoVariacao } = useGetProdutoVariacao();
  const { putManifestoDocumentoIntegrar } = usePutDocumentoIntegrar();
  const { putConfirmarEntradaXML } = usePutConfirmarEntradaXML();
  const { postValidarEntradaXML } = usePostValidarEntradaXML();

  const { id } = useParams<{ id: string }>();
  const [editing, setEditing] = useState<IEditing>({
    tela: tipoEdit.Resume,
  });

  const [loadingCadastros, setLoadingCadastros] = useState<boolean>(false)
  const [loadingSteps, setLoadingSteps] = useState<LoadingStepProps>({
    index: 0,
    tipo: 'carregando',
    atualizando: false,
  })

  const [activeStep, setActiveStep] = useState(Steppers.nfe);
  const [paymentEditing, setPaymentEditing] = useState({
    index: 0,
    isCreating: true,
    remainingValue: 0,
  });

  const produtosOriginaisRef = useRef<ProdsModel[]>([])
  const infoProdsIdentificados = useRef<ProdsModel[]>([]);
  const confirmouCriacao = useRef<boolean>(false);

  const qtds = useRef<{
    vCompra: number;
    vProd: number;
    vQtd: number;
  }[]>([])

  const carregando = carregandoGet ||
    carregandoPessoa;

  const itensCadastrados = useRef<ItensCadastradosModel>({
    produtos: [],
    pessoas: {},
    finalizacao: false,
    integracao: false,
  })

  const prodsId = useRef<string[]>([])

  const methods = useForm<VendaCompletaXMLModel>({
    defaultValues: new VendaCompletaXMLModel(),
    criteriaMode: 'all',
    mode: 'onChange'
  });

  const { handleSubmit: handleSubmitHookForm, reset } = methods;

  const submitPessoa = useCallback(async (model: PessoaModel, endereco?: PessoaEnderecoModel) => {
    try {
      const res = await postPessoa(model)

      if (res.erro) throw res.erro

      if (endereco) {
        const retEndereco = await postEnderecoPessoa({
          ...endereco,
          pessoaId: res.resultado?.data.id || ''
        })

        if (retEndereco.erro) {
          throw retEndereco.erro
        }
      }
    } catch (e: any) {

    }
  }, [postEnderecoPessoa, postPessoa])

  const alterarRefValidacao = (value: boolean, index: number, field: any) => {
    let produtos = itensCadastrados.current.produtos;
    produtos[index] = {
      ...produtos[index],
      [field]: value,
    }
    itensCadastrados.current.produtos = produtos;
  }

  // MARK: confirmarEntradaXML
  const confirmarEntradaXML = async (model: VendaCompletaXMLModel) => {
    try {
      let ncms: NcmModel[] = [];
      try {
        const retNcms = await getNcms('pageSize=100')
        if (retNcms.erro || !retNcms.resultado) throw retNcms.erro;

        ncms = retNcms.resultado.data.list as NcmModel[];
      } catch { }
      let medidas: ProdutoMedidaModel[] = [];
      try {
        const retMedidas = await getProdutoMedidas('', getEmpresaSelecionada()?.Id || '', 1, 100);
        if (retMedidas.erro) throw retMedidas.erro;

        medidas = retMedidas.resultado?.data.list as ProdutoMedidaModel[];
      } catch { }

      let errors: {
        nome: string;
        erros: string[]
      }[] = [];
      for (let i = 0; i < model.infMov.prod.length; i++) {
        let prod = model.infMov.prod[i] as ProdsXMLModel;

        errors.push({
          nome: prod.xProd,
          erros: []
        })

        //igualando lote com Qcom;
        prod.rastro = prod.rastro.map(rastro => {
          rastro.qLote = prod.qCom;
          return rastro;
        });

        if (prod.produtoId && prod.produtoId === guidEmpty()) {
          setLoadingSteps({
            atualizando: false,
            index: i,
            tipo: 'produtos'
          })

          try {
            const prodModel = new ProdutoNovoModel();

            prodModel.nome = prod.xProd;
            prodModel.vCompra = prod.vUnCom + prod.vCusto;
            prodModel.categoriaId = prod.categoriaId === guidEmpty() ? '' : prod.categoriaId;
            prodModel.marcaId = prod.marca;
            prodModel.indAdic = prod.infAdProd;
            prodModel.vPreco = prod.valorVenda || 0;
            prodModel.empresaId = getEmpresaSelecionada()?.Id || '';
            prodModel.codigoAnvisa = prod.cProdANVISA ? prod.cProdANVISA : ''
            prodModel.tipo = prod.cProdANVISA ? EnumTipoProduto.Medicamento : EnumTipoProduto.Produto;
            prodModel.balanca = prod.balanca || 0;
            if (ncms.length > 0) {
              const ncm = ncms.find(item => item.codigo === prod.ncm);
              if (ncm) {
                prodModel.ncmId = ncm.id;
              }
            }

            const medida = medidas.find(item => item.sigla === prod.uCom);
            if (medida) {
              prodModel.medidaSaidaId = medida.id;
            } else if (!itensCadastrados.current.produtos[i]?.medida) {
              try {
                const postMedida = new ProdutoMedidaModel(
                  guidEmpty(), getEmpresaSelecionada()?.ContratoId || '', getEmpresaSelecionada()?.Id || '',
                  prod.uCom, prod.uCom
                )
                const retPostMedida = await postProdutoMedida(postMedida)
                if (retPostMedida.erro || !retPostMedida.resultado?.data) throw retPostMedida.erro
                const newMedida = retPostMedida.resultado!.data;
                prodModel.medidaSaidaId = newMedida.id;

                //validando cadastrado de medida
                alterarRefValidacao(true, i, 'medida')

                medidas.push(
                  new ProdutoMedidaModel(newMedida.id, '', '', newMedida.descricao || '',
                    newMedida.sigla || ''
                  )
                )

              } catch (e: any) {
                errors[i].erros.push(e.message)
                alterarRefValidacao(false, i, 'medida')
              }
            }

            let res: RetornoApiModel<any> = {
              erro: undefined,
              isTimeout: false,
              resultado: undefined,
              statusCode: 0,
              tipoRetorno: EnumRetornoApiBase.Api
            };

            if (!itensCadastrados.current.produtos[i]?.principal) {
              res = await postProduto(prodModel, getEmpresaSelecionada()?.Id || '');

              let ids = prodsId.current;
              ids[i] = res.resultado?.data.id || '';
              prodsId.current = ids;

              model.infMov.prod[i].produtoId = res.resultado?.data.id || guidEmpty();


              if (res.erro) {
                throw res.erro
              }

              alterarRefValidacao(true, i, 'principal');
            }


            const resGet = await getProdutoById(prodModel.empresaId, res.resultado?.data.id || prodsId.current[i] || '');
            model.infMov.prod[i].produtoGradeId = res.resultado?.data.id || guidEmpty();
            if (resGet.erro) {
              throw resGet.erro;
            }

            const product = resGet.resultado?.data as ProdutoCompletoModel;
            model.infMov.prod[i].produtoGradeId = product.grades[0].id;
            if (prod.cProd &&
              !itensCadastrados.current.produtos[i]?.codigoInterno &&
              (
                (prod.cadastrarCodigo ? prod.cProd !== prod.cEan : true) &&
                (prod.cadastrarCodigoTrib ? prod.cProd !== prod.cEanTrib : true)
              )
            ) {
              try {

                const codigoModel = new ProdutoCodigoModel()
                codigoModel.isEanTrib = false;
                codigoModel.produtoId = product.id || '';
                codigoModel.produtoGradeId = product.grades[0].id;
                codigoModel.codigo = prod.cProd;
                codigoModel.precoFixo = null;
                codigoModel.percTabela = null;

                const res = await postProdutoCodigo(product.id, product.grades[0].id, codigoModel)

                if (res.erro) throw res.erro;

                alterarRefValidacao(true, i, 'codigoInterno');
              } catch {
                alterarRefValidacao(false, i, 'codigoInterno');
              }
            } else {
              alterarRefValidacao(true, i, 'codigoInterno');
            }
            if (
              prod.cadastrarCodigo &&
              prod.cEan && prod.cEan !== 'SEM GTIN' &&
              !itensCadastrados.current.produtos[i]?.codigo &&
              //se for cadastrar o código trib, tem q ser diferente do cod ean normal, se não só cadastra 1 como trib
              (prod.cadastrarCodigoTrib ? prod.cEan !== prod.cEanTrib : true)
            ) {
              try {
                const codigoModel = new ProdutoCodigoModel()
                codigoModel.isEanTrib = false;
                codigoModel.produtoId = product.id || '';
                codigoModel.produtoGradeId = product.grades[0].id;
                codigoModel.codigo = prod.cEan;
                codigoModel.precoFixo = null;
                codigoModel.percTabela = null;

                const res = await postProdutoCodigo(product.id, product.grades[0].id, codigoModel)

                if (res.erro) {
                  throw res.erro;
                }

                //validando cadastrado de código
                alterarRefValidacao(true, i, 'codigo');
              } catch (e: any) {
                errors[i].erros.push(e.message)
                alterarRefValidacao(false, i, 'codigo');
              }
            } else {
              alterarRefValidacao(true, i, 'codigo');
            }

            if (prod.cadastrarCodigoTrib && prod.cEanTrib && !itensCadastrados.current.produtos[i]?.codigoTrib && prod.cEanTrib !== 'SEM GTIN') {
              const codigoModel = new ProdutoCodigoModel()
              codigoModel.isEanTrib = true;
              codigoModel.produtoId = product.id || '';
              codigoModel.produtoGradeId = product.grades[0].id;
              codigoModel.codigo = prod.cEanTrib;
              codigoModel.precoFixo = null;
              codigoModel.percTabela = null;

              try {
                const res = await postProdutoCodigo(product.id, product.grades[0].id, codigoModel)

                if (res.erro) {
                  throw res.erro;
                }
                //validando cadastrado de código Trib
                alterarRefValidacao(true, i, 'codigoTrib');
              } catch (e: any) {
                errors[i].erros.push(e.message)
                alterarRefValidacao(false, i, 'codigoTrib');
              }
            } else {
              alterarRefValidacao(true, i, 'codigoTrib');
            }

          } catch (e: any) {
            errors[i].erros.push(e.message)
            alterarRefValidacao(false, i, 'principal')
          }
        } else if (!isEqual(prod, produtosOriginaisRef.current[i])) {

          setLoadingSteps({
            atualizando: true,
            index: i,
            tipo: 'produtos'
          })

          try {
            const empresaId = getEmpresaSelecionada()?.Id || ''
            const resGet = await getProdutoById(empresaId, prod.produtoId);
            if (resGet.erro) throw resGet.erro;

            const product = { ...resGet.resultado?.data } as ProdutoCompletoModel;
            const variacaoId = product.grades[0].id;

            const retVar = await getProdutoVariacao(product.id, variacaoId)
            if (retVar.erro) throw retVar.erro

            const variacao = retVar.resultado?.data as ProdutoVariacaoModel;

            const putModel = picker<ProdutoAtualizarModel>(product, new ProdutoAtualizarModel());
            putModel.categoriaId =
              (!prod.categoriaId || prod.categoriaId === guidEmpty())
                ? !product.categoriaId || product.categoriaId === guidEmpty()
                  ? null
                  : product.categoriaId
                : prod.categoriaId
            putModel.marcaId =
              (!prod.marca || prod.marca === guidEmpty())
                ? (!product.marcaId || product.marcaId === guidEmpty())
                  ? null
                  : product.marcaId
                : prod.marca

            if (ncms.length > 0) {
              const ncm = ncms.find(item => item.codigo === prod.ncm);
              if (ncm) {
                product.codigoNcm = ncm.codigo;
                product.ncmId = ncm.id;
              }
            }
            if (!itensCadastrados.current.produtos[i]?.principal) {
              try {
                const resPut = await putProduto(putModel, empresaId);

                if (resPut.erro) throw resPut.erro;

                alterarRefValidacao(true, i, 'principal');
              } catch {
                alterarRefValidacao(false, i, 'principal');
              }
            }

            variacao.vCompra = prod.vUnCom / toDecimal(prod.fator || 0);
            if (prod.valorVenda && prod.valorVenda !== variacao.precos[0].vPreco && !itensCadastrados.current.produtos[i]?.preco) {
              try {
                const preco = variacao.precos[0];
                preco.vPreco = prod.valorVenda;

                const precoRet = await putProdutoPreco(product.id, variacao.id, preco)
                if (precoRet.erro) throw precoRet.erro;

                //validando cadastrado de preço
                alterarRefValidacao(true, i, 'preco')

              } catch (e: any) {
                errors[i].erros.push(e.message)
                alterarRefValidacao(false, i, 'preco')
              }

            }
            const medida = medidas.find(item => item.sigla === prod.uCom);
            if (medida) {
              variacao.medidaSaidaId = medida.id;
            } else if (!itensCadastrados.current.produtos[i]?.medida) {
              try {
                const postMedida = new ProdutoMedidaModel(
                  guidEmpty(), getEmpresaSelecionada()?.ContratoId || '', empresaId,
                  prod.uCom, prod.uCom
                )
                const retPostMedida = await postProdutoMedida(postMedida)
                if (retPostMedida.erro || !retPostMedida.resultado?.data) throw retPostMedida.erro
                const newMedida = retPostMedida.resultado.data;
                variacao.medidaSaidaId = newMedida.id;

                //validando cadastrado de medida
                alterarRefValidacao(true, i, 'medida')

                medidas.push(
                  new ProdutoMedidaModel(newMedida.id, '', '', newMedida.descricao || '',
                    newMedida.sigla || ''
                  )
                )

              } catch (e: any) {
                errors[i].erros.push(e.message)
                alterarRefValidacao(false, i, 'medida')
              }
            }


            let codigos: ProdutoCodigoModel[] = []

            try {
              const retCodigos = await getProdutoCodigo(product.id, variacao.id);
              if (retCodigos.erro || !retCodigos.resultado?.data) throw retCodigos.erro

              codigos = retCodigos.resultado.data as ProdutoCodigoModel[];
            } catch (e: any) {
              errors[i].erros.push(e.message)
            }

            if (codigos && codigos.some(item => item.codigo === prod.cEan) && !itensCadastrados.current.produtos[i]?.codigo) {
              try {
                const codigo = { ...codigos.find(item => item.codigo === prod.cEan)! };
                if (codigo.fator !== toDecimal(prod.fator || 0)) {
                  codigo.fator = toDecimal(prod.fator || 0);
                  const retPutCodigo = await putProdutoCodigo(product.id, variacao.id, codigo);

                  if (retPutCodigo.erro) throw retPutCodigo.erro;

                  //validando atualização de código
                  alterarRefValidacao(true, i, 'codigo')

                }
              } catch (e: any) {
                errors[i].erros.push(e.message)
                alterarRefValidacao(false, i, 'codigo')
              }
            } else if (prod.cEan && !itensCadastrados.current.produtos[i]?.codigo && prod.cEan !== 'SEM GTIN') {
              try {
                const codPost = new ProdutoCodigoModel(
                  guidEmpty(),
                  product.id,
                  variacao.id,
                  prod.cEan,
                  false,
                  toDecimal(prod.fator),
                  null,
                  null
                )

                const retPostCodigo = await postProdutoCodigo(product.id, variacao.id, codPost);
                if (retPostCodigo.erro) throw retPostCodigo.erro;

                //validando cadastro de código
                alterarRefValidacao(true, i, 'codigo')
              } catch (e: any) {
                errors[i].erros.push(e.message)
                alterarRefValidacao(false, i, 'codigo')
              }
            } else {
              alterarRefValidacao(true, i, 'codigo');
            }

            if (!itensCadastrados.current.produtos[i]?.variacao) {
              try {
                const putVariacao = picker<ProdutoAtualizarVariacaoModel>(variacao, new ProdutoAtualizarVariacaoModel());
                putVariacao.tabelaPrecoId = variacao.precos[0].tabelaPrecoId

                const retVariacao = await putProdutoVariacao(product.id, putVariacao);
                if (retVariacao.erro) throw retVariacao.erro;

                //validando att de variação
                alterarRefValidacao(true, i, 'variacao')
              } catch (e: any) {
                errors[i].erros.push(e.message)
                alterarRefValidacao(false, i, 'variacao')
              }
            }
          } catch (e: any) {
            errors[i].erros.push(e.message)
            alterarRefValidacao(false, i, 'principal')
          }
        }
      }
      model.infMov.UFSaidaPais =
        model.infMov.UFSaidaPais &&
          model.infMov.UFSaidaPais > 0
          ? model.infMov.UFSaidaPais
          : null
      model.terminalId = model.terminalId === guidEmpty() ? null : model.terminalId

      //adaptando objeto de produtos de acordo com o que a API espera
      model.infMov.prod = model.infMov.prod.map(produto => ({ ...picker<ProdsXMLModel>(produto, new ProdsXMLPutModel()), rastro: isEmpty(produto.rastro) ? [] : produto.rastro, indFin: true, indEstoque: true })) as ProdsXMLModel[];

      //adaptando objeto de pagamento
      model.infMov.pag = model.infMov.pag.map(pag => {
        if (!pag.descricao) {
          pag.descricao = tpPagamentoResolver(pag.tPag, pag.tpTransacao)?.Value || '';
        }
        return { ...pag }
      })


      model.infMov.tpNF = EnumTpNf.ENTRADA;
      model.origemEmissao = EnumOrigemEmissao.TERCEIROS;

      if (model.autorizacao)
        model.autorizacao.origemEmissao = EnumOrigemEmissao.TERCEIROS;

      const empresaId = getEmpresaSelecionada()?.Id ?? '';

      model.infMov.total = { ...model.infMov.total, qCom: toDecimal(model.infMov.total.qCom) } as TotalModel;

      // if(model.infMov.cobr && model.infMov.cobr.dup){
      //   const restante = model.infMov.total.vnf - model.infMov.cobr.dup.reduce<number>((prev, curr) => prev + curr.vDup,0)
      //   if(restante > 0){
      //     model.infMov.cobr.dup[model.infMov.cobr.dup.length - 1].vDup = 
      //       model.infMov.cobr.dup[model.infMov.cobr.dup.length - 1].vDup + restante;
      //   }
      // }

      //Cadastrando pessoas automaticamente (caso necessário)
      if (
        model.infMov.emit &&
        !isEmpty(model.infMov.emit.doc) && !isEmpty(model.infMov.emit.xNome) &&
        (isEmpty(model.infMov.emit.pessoaId) || model.infMov.emit.pessoaId === guidEmpty()) &&
        !itensCadastrados.current.pessoas?.emit
      ) {

        try {
          setLoadingSteps({
            atualizando: false,
            index: 0,
            tipo: 'pessoas'
          })

          const emitModel = {
            ...new PessoaModel(),
            nome: model.infMov.emit.xNome,
            fantasia: model.infMov.emit.xNome,
            cpfcnpj: model.infMov.emit.doc,
            tpCadastro: EnumCadastroTipo.FORNECEDOR
          } as PessoaModel

          let endereco: PessoaEnderecoModel | undefined = undefined;

          if (model.infMov.emit.uf || model.infMov.emit.xMun || model.infMov.emit.cep || model.infMov.emit.xLgr) {
            endereco = {
              ...new PessoaEnderecoModel(),
              bairro: model.infMov.emit.xBairro,
              cep: model.infMov.emit.cep,
              cMun: model.infMov.emit.cMun.toString(),
              xMun: model.infMov.emit.xMun,
              logradouro: model.infMov.emit.xLgr,
              uf: model.infMov.emit.uf || '',
              cuf: model.infMov.emit.uf ? UFMock.find(x => x.Value === model.infMov.emit.uf)?.Key : 0,
              numero: model.infMov.emit.nro
            } as PessoaEnderecoModel;
          }

          await submitPessoa(emitModel, endereco)

          itensCadastrados.current = {
            ...itensCadastrados.current,
            pessoas: {
              ...itensCadastrados.current.pessoas,
              emit: true
            }
          }
        } catch (e: any) {
          errors.push({
            nome: model.infMov.emit.xNome,
            erros: [e.message]
          })
          itensCadastrados.current = {
            ...itensCadastrados.current,
            pessoas: {
              ...itensCadastrados.current.pessoas,
              emit: false
            }
          }
        }
      }

      if (
        model.infMov.transp &&
        !isEmpty(model.infMov.transp.doc) && !isEmpty(model.infMov.transp.xNome) &&
        (isEmpty(model.infMov.transp.pessoaId) || model.infMov.transp.pessoaId === guidEmpty()) &&
        !itensCadastrados.current.pessoas?.trans
      ) {

        try {
          setLoadingSteps({
            atualizando: false,
            index: 1,
            tipo: 'pessoas'
          })

          const emitModel = {
            ...new PessoaModel(),
            nome: model.infMov.transp.xNome,
            fantasia: model.infMov.transp.xNome,
            cpfcnpj: model.infMov.transp.doc,
            tpCadastro: EnumCadastroTipo.TRANSPORTADORA
          } as PessoaModel

          let endereco: PessoaEnderecoModel | undefined = undefined;

          if (model.infMov.transp.uf || model.infMov.transp.xMun || model.infMov.transp.xEnder) {
            endereco = {
              ...new PessoaEnderecoModel(),
              xMun: model.infMov.transp.xMun,
              logradouro: model.infMov.transp.xEnder,
              uf: model.infMov.transp.uf ? UFMock.find(x => x.Key === model.infMov.transp.uf)?.Value : '',
              cuf: model.infMov.transp.uf || 0,
            } as PessoaEnderecoModel;
          }

          await submitPessoa(emitModel, endereco)

          itensCadastrados.current = {
            ...itensCadastrados.current,
            pessoas: {
              ...itensCadastrados.current.pessoas,
              trans: true
            }
          }
        } catch (e: any) {
          errors.push({
            nome: model.infMov.transp.xNome,
            erros: [e.message]
          })
          itensCadastrados.current = {
            ...itensCadastrados.current,
            pessoas: {
              ...itensCadastrados.current.pessoas,
              trans: false
            }
          }
        }
      }

      let houveProblemas = itensCadastrados.current.produtos.some(prod => {
        return (
          (typeof prod.codigo === 'boolean' && prod.codigo === false) ||
          (typeof prod.codigoTrib === 'boolean' && prod.codigoTrib === false) ||
          (typeof prod.medida === 'boolean' && prod.medida === false) ||
          (typeof prod.preco === 'boolean' && prod.preco === false) ||
          (typeof prod.principal === 'boolean' && prod.principal === false) ||
          (typeof prod.variacao === 'boolean' && prod.variacao === false)
        )
      })

      houveProblemas = houveProblemas || (
        (typeof itensCadastrados.current.pessoas.emit === 'boolean' && itensCadastrados.current.pessoas.emit === false) ||
        (typeof itensCadastrados.current.pessoas.trans === 'boolean' && itensCadastrados.current.pessoas.trans === false)
      )

      if (houveProblemas) {

        setLoadingCadastros(false);
        const erros = errors.filter(erro => erro.erros.length > 0);
        const stringErro = erros.map(err => {
          let errs = [err.nome + ':/n', err.erros.map(msg => `${msg}/n`)]
          return errs.join()
        })
        showConfirm({
          title: 'Algo deu errado.',
          description: `Ocorreu o erro em algum dos seus cadastros. Tente novamente. Detalhes:/n
          ${stringErro.reduce<string>((prev, curr) => prev + curr + '/n', '')}
          `,
          primaryButtonText: 'Tentar Novamente',
          secondaryButtonText: 'Fechar',
          showIcon: true
        }).then(() => handleSubmitHookForm(onSubmit)())
        return
      }

      setLoadingSteps({
        atualizando: false,
        index: 0,
        tipo: 'finalizando'
      })

      if (!model.idNFe && model.infMov.chave) {
        if (model.infMov.chave.includes('NFe')) {
          model.idNFe = model.infMov.chave;
        }
      }

      //validações que o gui sugeriu pra não dar pau na API
      if (model.infMov.xPed === null) {
        model.infMov.xPed = '';
      }
      if (model.infMov.xCont === null) {
        model.infMov.xCont = '';
      }
      if (model.infMov.xNEmp === null) {
        model.infMov.xNEmp = '';
      }

      if (!itensCadastrados.current.finalizacao) {

        const resValid = await postValidarEntradaXML(id, empresaId);

        const validacoes = resValid.resultado?.data as ValidacaoRetornoModel[];

        if (validacoes.length > 0) {
          let mensagem = 'Detectamos alguns problemas com sua nota. Detalhes:/n';
          validacoes.forEach(validacao => {
            mensagem += ValidacaoXMLMock.find(x => x.Key === validacao.local)?.Value + ':/n';
            mensagem += validacao.mensagem + '/n';
          })
          throw new Error(mensagem);
        }

        const res = await putConfirmarEntradaXML(model as VendaCompletaModel, id, empresaId);

        if (res.erro) throw res.erro

        itensCadastrados.current = {
          ...itensCadastrados.current,
          finalizacao: true
        }
      }

      if (model.idNFe && !itensCadastrados.current.integracao && integrar) {

        setLoadingSteps({
          atualizando: false,
          index: 0,
          tipo: 'integrando'
        })

        let chave = model.idNFe;
        if (chave.includes('NFe')) {
          chave = chave.split('NFe')[1];
        }
        try {
          const res = await putManifestoDocumentoIntegrar(getEmpresaSelecionada()?.Documento || model.infMov.emit.doc || '', chave, true);
          if (res.erro) throw res.erro
        } catch (e: any) {

          itensCadastrados.current = {
            ...itensCadastrados.current,
            integracao: false,
          }

          showConfirm(
            {
              showIcon: true,
              description: 'Seu XML foi processado e sua nova entrada criada, porém houve um erro ao integrar seu manifesto. Detalhes: ' + e.message,
              title: 'Aviso',
              primaryButtonText: 'Tentar Novamente',
              secondaryButtonText: 'Fechar',
            }
          ).then(() => {
            handleSubmitHookForm(onSubmit)();
          })
          return
        }
      }
      history.push({
        pathname: '/entrada-mercadoria/visualizar/' + model.id,
        search: location?.dtManifesto ? '?dInicial=' + location.dtManifesto : ''
      })
      setLoadingCadastros(false);
    } catch (e: any) {
      showAviso('error', e.message)
    } finally {
      setLoadingCadastros(false)
    }
  }

  //MARK: getDepositosWrapper
  const getDepositoWrapper = useCallback(async () => {
    try {
      const res = await getDepositoEmpresa('')
      if (res.erro) throw res.erro

      if (res.resultado && res.resultado?.data.list.length > 0) {
        return res.resultado.data.list[0]
      }
      return null
    } catch (e: any) {
      return null
    }
  }, [getDepositoEmpresa])

  // MARK: getPessoaWrapper
  const getPessoaWrapper = useCallback(async (doc: string) => {
    try {
      const res = await getPessoaByDoc(doc);
      if (res.erro) throw res.erro

      if (res.resultado && res.resultado?.data.list.length > 0) {
        return res.resultado.data.list[0];
      }
      return null
    } catch {
      return null
    }
  }, [getPessoaByDoc])

  // MARK: getXMLCompleto
  const getXMLCompleto = useCallback(async () => {
    try {
      const empresaId = getEmpresaSelecionada()?.Id ?? '';
      const res = await getNovaEntradaXML({ empresaId, id });
      if (res.erro) throw res.erro;

      if (res.resultado?.data) {
        const resultado = { ...res.resultado.data }

        if (resultado.infMov?.dhEmi) {
          resultado.infMov.dhEmi = formatDateToDateTimeInput(
            resultado.infMov.dhEmi as string
          );
        }

        if (resultado.infMov?.dhSaiEnt) {
          resultado.infMov.dhSaiEnt = formatDateToDateTimeInput(
            resultado.infMov.dhSaiEnt as string
          );
        }

        if (resultado.infMov?.serie) {
          resultado.infMov.serie = resultado.infMov.serie
            .toString()
            .padStart(3, '0');
        }

        let depositos = [] as DepositoEmpresaModel[];

        try {
          const resDepositos = await getDepositoEmpresa();

          if (resDepositos.erro) throw resDepositos.erro;
          if (!resDepositos.resultado) throw new Error();

          depositos = resDepositos.resultado.data.list;
        } catch {

        }


        if (resultado.infMov?.prod && resultado.infMov.prod.length > 0) {
          resultado.infMov.prod = resultado.infMov.prod.map((produto: ProdsModel) => {
            if (!produto.indEscala) {
              produto.indEscala = EnumIndEscala.N
            } else if (produto.indEscala !== EnumIndEscala.S && produto.indEscala !== EnumIndEscala.N) {
              produto.indEscala = EnumIndEscala.N
            }
            produto.vUnCom = toDecimal(produto.vUnCom);
            produto.vUnTrib = toDecimal(produto.vUnTrib);
            const prodXml = { ...new ProdsXMLModel(), ...produto }

            if (
              depositos.length === 1 &&
              (!prodXml.depositoId || prodXml.depositoId === guidEmpty())
            ) {
              prodXml.depositoId = depositos[0].depositoId;
              prodXml.depositoNome = depositos[0].depositoNome;
            }
            return prodXml
          })
        }
        resultado.infMov.total.qItens = resultado.infMov.prod.length;
        resultado.status = StatusSituacaoMock.reduce<boolean>((prev, curr) => {
          return prev || curr.Key === resultado.status
        }, false) ? resultado.status : EnumTpStatusMov.Pendente;

        qtds.current = resultado.infMov.prod.map((prod: ProdsXMLModel) => {
          return {
            vProd: prod.vProd,
            vCompra: prod.vUnCom,
            vQtd: prod.qCom
          }
        })

        resultado.infMov.pag = resultado.infMov.pag.map((pag: PagsModel) => {
          if (!pag.id || pag.id === guidEmpty()) {
            return { ...pag, id: newGuid() }
          }
          return pag
        })

        if (resultado.infMov.cobr && (
          resultado.infMov.cobr.dup.length === resultado.infMov.pag.length
        )) {
          resultado.infMov.cobr.dup = resultado.infMov.cobr.dup.map((dup: DupCobrModel) => {
            const pag = resultado.infMov.pag.find((x: PagsModel) => x.vPag === dup.vDup);
            if (!pag) return dup;
            let id = pag.id;
            return {
              ...dup,
              pagId: id
            }
          })
        }

        if (resultado.infMov.emit && !isEmpty(resultado.infMov.emit.doc)) {
          const retEmit = await getPessoaWrapper(resultado.infMov.emit.doc) as PessoaModel | null;
          if (retEmit) {
            resultado.infMov.emit.pessoaId = retEmit.id;
            resultado.infMov.emit.xNome = retEmit.nome;
          }
        }

        if (resultado.infMov.transp && !isEmpty(resultado.infMov.transp.doc)) {
          const retTransp = await getPessoaWrapper(resultado.infMov.transp.doc) as PessoaModel | null;
          if (retTransp) {
            resultado.infMov.transp.pessoaId = retTransp.id;
            resultado.infMov.transp.xNome = retTransp.nome;
          }
        }
        if (resultado.infMov.transp && !resultado.infMov.transp.ufVeiculo && resultado.infMov.transp.uf) {
          resultado.infMov.transp.ufVeiculo = resultado.infMov.transp.uf;
        }

        if (resultado.infMov.transp.ufVeiculo === 0 && !resultado.infMov.transp.doc) {
          delete resultado.infMov.transp.ufVeiculo;
        }

        produtosOriginaisRef.current = resultado.infMov.prod;
        infoProdsIdentificados.current = resultado.infMov.prod;

        reset(resultado);
      }
    } catch (err: any) {
      showToast('error', err.message);
      history.push('/entrada-mercadoria')
    }
  }, [getEmpresaSelecionada, getNovaEntradaXML, id, reset, getDepositoEmpresa, getPessoaWrapper, showToast, history]);

  useEffect(() => {
    getXMLCompleto();
  }, [getXMLCompleto]);

  // MARK: onSubmit
  const onSubmit = async (values: VendaCompletaXMLModel) => {
    setLoadingCadastros(true)

    const depPadrao = await getDepositoWrapper()
    let vendaCompletaXml = values

    vendaCompletaXml.infMov.prod.forEach(produto => {
      if (isEmpty(produto.depositoId) && !isEmpty(depPadrao)) {
        produto.depositoId = depPadrao.depositoId
      }
    })
    await saveChangesXML(vendaCompletaXml, false);
    let novosProdutos = vendaCompletaXml.infMov.prod as ProdsXMLModel[];
    novosProdutos = novosProdutos.filter(x => x.produtoId === guidEmpty() || !x.produtoId) || [];
    if (novosProdutos.length > 0 && !confirmouCriacao.current) {
      showConfirm({
        title: 'Confirmação',
        description: `Tem certeza que deseja concluir? Ao concluir a importação deste XML você irá criar ${novosProdutos.length} novos produtos. Desejar prosseguir ou revisar os produtos?`,
        primaryButtonText: 'Concluir Importação',
        secondaryButtonText: 'Revisar Produtos',
        secondaryButtonColor: theme.palette.primary.main,
        showIcon: false
      }).then(() => {
        confirmouCriacao.current = true;
        confirmarEntradaXML(vendaCompletaXml);
      }).catch(() => {
        setEditing({
          tela: tipoEdit.Itens,
          step: 0
        });
        setLoadingCadastros(false);
      })
      return;
    }
    confirmarEntradaXML(vendaCompletaXml);
  };

  useEffect(() => {
    //resetando steps
    if (!loadingCadastros) {
      setLoadingSteps({
        atualizando: false,
        index: 0,
        tipo: 'carregando'
      })
    }
  }, [loadingCadastros])

  // MARK: return
  return (
    <FormProvider {...methods}>
      <Grid className={cadClasses.root}>
        {loadingCadastros && (
          <OpcoesEntradaImportacaoXMLLoading
            numProdutos={qtds.current.length}
            prods={methods.getValues(`infMov.prod`)}
            steps={loadingSteps}
          />
        )}
        {carregando && <CircularLoading tipo="FULLSIZED" />}
        <form onSubmit={handleSubmitHookForm(onSubmit)} className={classes.form}>
          {editing.tela === tipoEdit.Resume ? (
            <OpcoesEntradaImportacaoXMLResumeTemplate
              setEditing={setEditing}
              carregando={carregando}
            />
          ) : null}

          {editing.tela === tipoEdit.Stepper ? (
            <OpcoesEntradaImportacaoXMLStepperTemplate
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              setEditing={setEditing}
              setPaymentEditing={setPaymentEditing}
              carregando={carregando}
            />
          ) : null}

          {editing.tela === tipoEdit.Itens ? (
            <OpcoesEntradaImportacaoXMLItensTemplate
              setEditing={setEditing}
              carregando={carregando}
              originalQtds={qtds.current}
              produtosOriginais={produtosOriginaisRef.current}
              infoProdutosRef={infoProdsIdentificados}
              stepInicial={editing.step}
            />
          ) : null}

          {editing.tela === tipoEdit.Pagamento ? (
            <OpcoesEntradaImportacaoXMLIPagamentosTemplate
              setEditing={setEditing}
              carregando={carregando}
              paymentEditing={paymentEditing}
            />
          ) : null}
        </form>
      </Grid>
    </FormProvider>
  );
};

export default ImportacaoEntradaXMLPage;
