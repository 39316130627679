import { useTheme } from '@material-ui/core';
import { FinalizadoraModel } from 'model/api/gestao/finalizadora/finalizadora-model';
import { PessoaModel } from 'model/api/gestao/pessoa';
import {
  PagamentoSessaoModel,
  PortadorSessaoModel,
  SessaoValorFormModel,
} from 'model/api/gestao/sessao/sessao-valor-model';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { EnumTpLancamentoSessaoValor } from 'model/enums/enum-tipo-lancamento-sessao-valor';
import { useCallback, useState } from 'react';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { useSessaoPDV } from 'services/app/hooks/sessao-pdv';
import { picker } from 'utils/picker';
import { AutocompleteFinalizadora } from 'views/components/controles/autocompletes/autocomplete-finalizadoras/autocomplete-finalizadoras';
import { AutocompleteResponsaveis } from 'views/components/controles/autocompletes/autocomplete-responsaveis/autocomplete-responsaveis';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { AddIcon } from 'views/components/icons/add-icon';
import { ArrowDownIcon } from 'views/components/icons/arrow-down-icon';
import { ArrowTopIcon } from 'views/components/icons/arrow-top-icon';
import { useStyles } from './sessao-novo-valor-styles';
import { Controller, useForm } from 'react-hook-form';
import { useFormSessaoNovoValorValidation } from './sessao-novo-valor-validations';
import { yupResolver } from '@hookform/resolvers/yup';
import { TouchoneDBPrimary } from 'database/touchone-database';
import { guidEmpty } from 'utils/guid-empty';
import { EnumPagTpMod } from 'model';
import { Button, Divider, Grid, Typography } from 'views/design-system';

interface NovoValorSessao {
  tpLancamento: EnumTpLancamentoSessaoValor;
  tpEvent: AppEventEnum;
  title: string;
}

const SessaNovoValor = ({ tpLancamento, tpEvent, title }: NovoValorSessao) => {

  // STATES E REFS
  const [isMaisDetalhes, setIsMaisDetalhes] = useState<boolean>(false);

  // HOOKS
  const { setValor } = useSessaoPDV();
  const { callEvent } = useEventTools();

  const { FormSessaoNovoValorYupValidation } = useFormSessaoNovoValorValidation()

  const {
    handleSubmit,
    control,
    formState: { errors, touchedFields },
    setValue,
    getValues,
    reset
  } = useForm<SessaoValorFormModel>({
    criteriaMode: 'all',
    resolver: yupResolver(FormSessaoNovoValorYupValidation),
    mode: 'onChange',
  });

  // AUX
  const classes = useStyles();
  const theme = useTheme();

  const adicionarValorSessao = useCallback(async (values: SessaoValorFormModel) => {
    const { descricao, documento, nome, observacao, pagamentoId, portadorId, tpMod, tpTransacao, valorMovimentacao } = values

    const portador: PortadorSessaoModel = {
      documento: documento,
      nome: nome,
      portadorId: portadorId
    }

    const pagamento: PagamentoSessaoModel = {
      descricao: descricao,
      pagamentoId: pagamentoId,
      tpMod: tpMod,
      tpTransacao: tpTransacao
    }

    if (!pagamento.pagamentoId || (pagamento.pagamentoId === guidEmpty())) {
      const finalizadora = await TouchoneDBPrimary.finalizadoras.get({
        tpMod: EnumPagTpMod.DINHEIRO
      })
      if (!finalizadora) return
      pagamento.pagamentoId = finalizadora.id ?? ''
      pagamento.descricao = finalizadora.descricao ?? ''
      pagamento.tpMod = finalizadora.tpMod
      pagamento.tpTransacao = finalizadora.tpTransacao
    }

    const res = await setValor(
      Number(valorMovimentacao.replace(',', '.')),
      tpLancamento,
      pagamento,
      observacao,
      portador,
    );

    if (!res) {
      return;
    }

    callEvent(tpEvent, 1);

    reset(new SessaoValorFormModel())
  }, [
    callEvent,
    reset,
    setValor,
    tpEvent,
    tpLancamento
  ]);


  return (
    <Grid className={classes.container}>
      <Typography className={classes.title}>{title}</Typography>
      <form onSubmit={handleSubmit(adicionarValorSessao)}>
        <Grid
          container
          spacing={1}
          xs={12}
          style={{ marginBottom: '8px' }}
        >
          <Grid item xs={10} sm={10} md={10}>
            <Controller
              name="valorMovimentacao"
              control={control}
              render={({ field }) => (
                <TextFieldSaurus
                  tipo="DECIMAL"
                  fullWidth
                  manterMascara
                  inputProps={{ maxLength: 8, min: 0 }}
                  allowSubmit
                  size="medium"
                  variant="outlined"
                  label="Valor"
                  {...field}
                  placeholder={'R$ 0,00'}
                  onChange={(e: any) => {
                    if (e?.nativeEvent?.data === '-') return;
                    setValue('valorMovimentacao', e.target.value);
                  }}
                  error={Boolean(
                    errors.valorMovimentacao && errors.valorMovimentacao.message,
                  )}
                  helperText={
                    touchedFields.valorMovimentacao || errors.valorMovimentacao
                      ? errors.valorMovimentacao?.message
                      : undefined
                  }
                />
              )}
            />
          </Grid>
          <Grid item xs={2} sm={2} md={2}>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              type="submit"
            >
              <AddIcon tipo="BUTTON_PRIMARY" style={{ margin: 0 }} />
            </Button>
          </Grid>
        </Grid>

        {isMaisDetalhes && (
          <Grid container spacing={1} xs={12} sm={12} md={12} lg={12}>
            <Grid item xs={12} sm={12} md={12}>
              <Controller
                name='descricao'
                control={control}
                render={({ field }) => (
                  <AutocompleteFinalizadora
                    loadingExterno={false}
                    modo="descricao"
                    allowSubmit
                    label="Pagamento"
                    placeholder="Pagamento"
                    finalizadoraId={getValues('pagamentoId')}
                    {...field}
                    onChange={(retorno) => {
                      if (!retorno.isString) {
                        const pag = picker<FinalizadoraModel>(
                          retorno.value,
                          new FinalizadoraModel(),
                        );
                        setValue('descricao', pag.descricao)
                        setValue('pagamentoId', pag.id)
                        setValue('tpMod', pag.tpMod)
                        setValue('tpTransacao', pag.tpTransacao)
                      }
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
              <Controller
                control={control}
                name="nome"
                render={({ field }) => (
                  <AutocompleteResponsaveis
                    loadingExterno={false}
                    modo="nome"
                    label="Portador"
                    placeholder="Portador"
                    allowSubmit
                    pessoaId={getValues('portadorId')}
                    {...field}
                    onChange={(retorno) => {
                      if (!retorno.isString) {
                        const pessoa = picker<PessoaModel>(
                          retorno.value,
                          new PessoaModel(),
                        );
                        setValue('portadorId', pessoa.id)
                        setValue('nome', pessoa.nome)
                        setValue('documento', pessoa.cpfcnpj)
                      }
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
              <Controller
                name="observacao"
                control={control}
                render={({ field }) => (
                  <TextFieldSaurus
                    tipo="TEXTO"
                    fullWidth
                    allowSubmit
                    variant="outlined"
                    label="Observação"
                    inputProps={{ maxLength: 30 }}
                    {...field}
                    placeholder={'Descreva a observação'}
                  />
                )}
              />
            </Grid>
          </Grid>
        )}

        <Grid>
          <Divider className={classes.divider} />
          <Typography
            className={classes.more}
            onClick={() => {
              setIsMaisDetalhes(prev => !prev);
            }}
          >
            {isMaisDetalhes ? (
              <ArrowDownIcon
                tipo="BUTTON_PRIMARY"
                fill={theme.palette.primary.main}
                style={{ margin: 0, width: 30, height: 30 }}
              />
            ) : (
              <ArrowTopIcon
                tipo="BUTTON_PRIMARY"
                fill={theme.palette.primary.main}
                style={{ margin: 0, width: 30, height: 30 }}
              />
            )}
            {isMaisDetalhes ? 'Menos Detalhes' : 'Mais Detalhes'}
          </Typography>
        </Grid>
      </form>
    </Grid>
  );
};

export { SessaNovoValor };
