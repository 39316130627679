import { DialogSaurus } from '../dialog-saurus/dialog-saurus';
import { useStyles } from './dialog-opcoes-retirada-styles';
import { BaixarIcon, LixoIcon, VoltarIcon } from 'views/components/icons';
import { useState } from 'react';
import { CircularLoading } from 'views';
import { VariaveisAmbiente } from 'config';
import { EnumDeviceType } from 'model/enums/enum-device-type';
import { EnviarEmailIcon } from 'views/components/icons/enviar-email';
import { DialogEnviarPorEmail } from '../dialog-enviar-por-email/dialog-enviar-por-email';
import { usePostRetiradaCompartilhar } from 'data/api/gestao/relatorios/retirada-documento/post-retirada-documento-compartilhar';
import { useToastSaurus } from 'services/app';
import { useSessaoAtual } from 'services/app';
import { useDeleteRetirada } from 'data/api/gestao/relatorios/retirada-documento/delete-retirada-documento';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { useTheme } from '@material-ui/core';
import { Box, Button, Grid } from 'views/design-system';

interface DialogOpcoesRetiradaProps {
    openned: boolean;
    closeModal: () => void;
    link: string;
    id: string;
}

export const DialogOpcoesRetirada = ({
    openned,
    closeModal,
    link,
    id
}: DialogOpcoesRetiradaProps) => {
    const [modal, setModal] = useState<boolean>(false)
    const [email, setEmail] = useState<string>('')
    const { getEmpresaSelecionada } = useSessaoAtual()
    const { showToast } = useToastSaurus()
    const classes = useStyles();
    const theme = useTheme()
    const { callEvent } = useEventTools();

    const { postRetiradaCompartilhar, carregando } = usePostRetiradaCompartilhar()
    const { deleteRetirada, carregando: carregandoRetidada } = useDeleteRetirada()

    const download = () => {
        if (link !== '') {
            (window as any).open(link, "_blank").focus()
        }
    }

    const enviarEmail = async () => {
        try {

            const res = await postRetiradaCompartilhar(id, email, getEmpresaSelecionada()!.Documento)

            if (res.erro) throw res.erro

            showToast('success', 'E-mail enviado com sucesso!')

            setModal(false)

        } catch (e: any) {
            showToast('error', e.message)
            setModal(false)
        }
    }

    const excluirRetirada = async () => {
        try {
            const res = await deleteRetirada(id, getEmpresaSelecionada()!.Documento)
            if (res.erro) throw res.erro
            showToast('success', 'Retirada de arquivo removida');
            callEvent(AppEventEnum.RecarregarpaginaRetiradaDocumentos, 1);
            closeModal()
        } catch (e: any) {
            showToast('error', e.message)
            closeModal()
        }
    }

    return (
        <>
            <DialogSaurus
                aberto={openned || false}
                centralizarTitulo
                colorTitulo={theme.palette.primary.main}
                titulo="Arquivo XML"
                tamanho="xs"
            >
                {(carregando || carregandoRetidada) && <CircularLoading tipo="FULLSIZED" />}
                <Box className={classes.cardContainer}>
                    <Box className={classes.cardContent}>
                        <Box className={classes.boxCustom} >
                            <Grid
                                container
                                style={{ marginBottom: 8 }}
                                spacing={1}
                            >
                                {
                                    VariaveisAmbiente.paymentDevice !== EnumDeviceType.CORDOVA_POS &&
                                    link?.length !== 0 && (
                                        <Grid item xs={12}>
                                            <Button
                                                variant="contained"
                                                fullWidth
                                                color="primary"
                                                onClick={download}
                                            >
                                                <BaixarIcon tipo='BUTTON_PRIMARY' />
                                                Baixar Arquivo
                                            </Button>
                                        </Grid>
                                    )}
                                <Grid item xs={12}>
                                    <Button
                                        variant="contained"
                                        fullWidth
                                        color="primary"
                                        onClick={() => setModal(true)}
                                    >
                                        <EnviarEmailIcon tipo="BUTTON_PRIMARY" />
                                        Compartilhar
                                    </Button>
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        variant="contained"
                                        fullWidth
                                        color="primary"
                                        onClick={excluirRetirada}
                                    >
                                        <LixoIcon tipo="BUTTON_PRIMARY" />
                                        Deletar arquivo
                                    </Button>
                                </Grid>
                                <Grid item xs={12}>
                                    {closeModal && (
                                        <Button
                                            variant="outlined"
                                            fullWidth
                                            color="primary"
                                            onClick={() => {
                                                closeModal();
                                            }}
                                        >
                                            <VoltarIcon tipo="BUTTON" />
                                            Voltar
                                        </Button>
                                    )}
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Box>
            </DialogSaurus>
            <DialogEnviarPorEmail
                setEmail={setEmail}
                email={email}
                closeModal={() => setModal(!modal)}
                openned={modal}
                enviarEmail={enviarEmail}
                loading={carregando}
            />
        </>
    );
};
