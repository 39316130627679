import { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import { GestaoStorageKeys, useGestaoStorage, useToastSaurus } from "services/app";
import { SelecionarEmpresaListData } from "./selecionar-empresa-list-data";
import { PesquisaSelecionarEmpresa } from "../pesquisa-selecionar-empresa/pesquisa-selecionar-empresa";
import { ListaEmpresasListSearchProps } from "../../../lista-empresas/components/lista-empresas-list/lista-empresas-list-search-props";
import { CircularLoading } from "views/components";
import { useStyles } from './selecionar-empresa-styles'
import { isEmpty } from "lodash";
import { useSessaoAtual } from 'services/app';
import { Grid } from "views/design-system";

type Props = {
    callbackUrl: string | null
}

export const SelecionarEmpresaList = ({ callbackUrl = null }: Props) => {

    const { selecionarEmpresa, usuario, carregando } = useSessaoAtual()
    const [selectedList, setSelectedList] = useState<Array<string>>([])
    const { showToast } = useToastSaurus()
    const history = useHistory()
    const [searchProps, setSearchProps] = useState<ListaEmpresasListSearchProps>({
        termo: '',
    });
    const { getRegistro } = useGestaoStorage()
    const token = getRegistro(GestaoStorageKeys.Token, false)
    const classes = useStyles()

    const onCardSelected = async (id: string) => {
        try {
            await selecionarEmpresa(id)
            if (callbackUrl || !isEmpty(callbackUrl)) {

                let urlDescript = atob(callbackUrl || '')

                urlDescript = urlDescript.replace('{accessToken}', token)
                urlDescript = urlDescript.replace("{empresaId}", id)

                return window.location.href = urlDescript
            }
            history.push('/')
        } catch (e: any) {
            showToast('error', e.message)
        }
    };

    const onCardChecked = (id: string) => {
        const aux = [...selectedList];
        aux.push(id);
        setSelectedList(aux);
    };

    const onPesquisar = useCallback(
        (props: ListaEmpresasListSearchProps) => {
            setSearchProps(props);
        },
        [setSearchProps]
    );

    // eslint-disable-next-line array-callback-return
    const pesquisa = usuario!.empresa.filter(item => {
        if (searchProps.termo === '') return item
        if (
            item.Descricao.toLowerCase().includes(searchProps.termo.toLowerCase()) ||
            item.Documento.toLowerCase().includes(searchProps.termo.toLowerCase())
        ) {
            return item
        }
    })

    return (
        <>
            {carregando && (
                <CircularLoading tipo="FULLSIZED" />

            )}
            <PesquisaSelecionarEmpresa onPesquisar={onPesquisar} />
            <Grid className={classes.listContainer}>
                <SelecionarEmpresaListData
                    list={pesquisa}
                    carregando={carregando}
                    onCardChecked={onCardChecked}
                    onCardSelected={onCardSelected}
                    selectedList={selectedList}
                />
            </Grid>
        </>
    )
}