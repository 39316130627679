import { PedidoModel } from "model/api/gestao/pedido/pedido-model";
import { AppEventEnum } from "model/enums/enum-app-event";
import { useCallback, useEffect, useState } from "react";
import { useEventTools } from "services/app/hooks/events/event-tools";
import { consoleDev } from "utils/console-dev";
import { PedidoPagAntecipadoModal, PedidoPagAntecipadoModalProps } from "views/components/modals/pedido-pagamento-antecipado/pedido-pag-antecipado-modal";

export const PedidoPagAntecipadoModalRender = () => {
    const { addHandler, removeHandler } = useEventTools();
    const [modalState, setModalState] = useState<PedidoPagAntecipadoModalProps>({
        openned: false,
        pedido: new PedidoModel(),
        id: ''
    });

    consoleDev('DialogPedidoPagamentoAntecipadoRender')

    const modalAlterado = useCallback(({ pedido, openned }: PedidoPagAntecipadoModalProps) => {
        setModalState({
            pedido,
            openned,
            id: ''
        });
    }, [])

    useEffect(() => {
        addHandler(AppEventEnum.PedidoPagamentoAntecipado, modalAlterado);
        return () => {
            removeHandler(AppEventEnum.PedidoPagamentoAntecipado, modalAlterado);
        };
    }, [addHandler, modalAlterado, removeHandler]);

    return (
        <>
            {modalState.openned && (
                <PedidoPagAntecipadoModal {...modalState} />
            )}
        </>
    )
}