import { ButtonModalHeader } from "views/components/controles/buttons/button-modal-header"
import { PedidoPagAntecipadoProps } from "../../pedido-pag-antecipado-modal"
import { ModalHeader } from "views/components/modals/components"
import { useModalStyles } from "views/components/modals/utils/modal-styles"
import { InformacaoIcon, NovoIcon, VoltarIcon } from "views/components/icons"
import { useCadastros } from "services/app"
import classNames from "classnames"
import { Button, Divider, Grid, Typography } from "views/design-system"
import { CardPagAntecipado } from "views/components/cards/card-pag-antecipado"
import { CardNaoEncontrado } from "views/components/cards"
import { usePedidoPagAntecipado } from "./use-pedido-pag-antecipado"

export const PedidoPagAntecipadoConteudo = ({ pedido }: PedidoPagAntecipadoProps) => {
    const modalClasses = useModalStyles()
    const { fecharPedidoPagamentoAntecipado } = useCadastros()

    const onCloseClick = () => {
        fecharPedidoPagamentoAntecipado()
    }

    const { handleValorPagamento } = usePedidoPagAntecipado(pedido, onCloseClick)

    const pagamentos = pedido.pagamentos

    const handleClickPag = (id: string) => {

    }

    return (

        <div className={modalClasses.root}>
            <ModalHeader
                title="Pagamento Antecipado"
                leftArea={
                    <ButtonModalHeader
                        tooltip="Voltar"
                        icon={<VoltarIcon tipo="MODAL_HEADER" />}
                        onClick={onCloseClick}
                    />
                }
            />
            <div className={modalClasses.content}>
                <div
                    className={classNames(
                        modalClasses.contentForms,
                    )}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12} mb={2}>
                            <Typography color="primary" weight={500} variant="h6">
                                Pagamentos já realizados
                            </Typography>
                            <Divider />
                        </Grid>
                        {pagamentos.length <= 0 && (
                            <Grid item xs={12}>
                                <CardNaoEncontrado
                                    icon={<InformacaoIcon tipo="GERAL" />}
                                    mensagem="Nenhum pagamento encontrado"
                                />
                            </Grid>
                        )}
                        {pagamentos.map(pag => {
                            return (
                                <Grid item key={pag.id} xs={12}>
                                    <CardPagAntecipado
                                        model={pag}
                                        onClick={handleClickPag}
                                    />
                                </Grid>
                            )
                        })}
                    </Grid>
                </div>
            </div>
            <div className={modalClasses.acoes}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Button
                            onClick={handleValorPagamento}
                            variant="contained"
                            color="primary"
                            size="large"
                            fullWidth
                        >
                            <NovoIcon tipo="BUTTON_PRIMARY" />
                            Novo Pagamento
                        </Button>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}