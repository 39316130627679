import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(2),
        width: '100%',
    },
    buttonContainer: {
        display: 'flex',
        paddingTop: theme.spacing(1),
        gap: theme.spacing(1),
        flexWrap: 'wrap',
        '& button': {
            flexGrow: 1
        }
    }
}));
