import { FormPlano } from "views/components/form/plano/form-plano";
import { DialogSaurus } from "../dialog-saurus/dialog-saurus"
import { useCallback, useEffect, useRef, useState } from "react";
import { DefaultFormRefs } from "views/components/form/utils";
import { PlanoFormModel, PlanoGetModel, PlanoPutModel } from "model/api/gestao/plano/plano-form-model";
import { VoltarIcon } from "views/components/icons";
import { useCadastros, useSessaoAtual, useToastSaurus } from "services/app";
import { UpgradeIcon } from "views/components/icons/upgrade-icon";
import { CircularLoading } from "views/components/utils";
import { usePutPlanoLicenca } from "data/api/gestao/plano/put-plano-licenca";
import { DialogHelpPlano } from "./components/dialog-help-plano";
import { InterrogacaoIcon } from "views/components/icons/interrogacao-icon";
import { useGetPlanoLicenca } from "data/api/gestao/plano/get-plano-licenca";
import { ModuloPlanoModel, ModuloPlanoPutModel } from "model/api/gestao/plano/plano-modulo-model";
import { useHistory } from "react-router-dom";
import { useStyles } from './dialog-plano-customizado-styles'
import { useConfirm } from "material-ui-confirm";
import { Box, Button, Tooltip } from "views/design-system";
interface DialogPlanoCustomizadoProps {
    openned: boolean;
    pathToRedirect: string;
}

export const DialogPlanoCustomizado = ({ openned, pathToRedirect }: DialogPlanoCustomizadoProps) => {

    const { fecharDialogPlanoCustomizado } = useCadastros()
    const { showToast } = useToastSaurus()
    const history = useHistory()
    const confirm = useConfirm();

    const classes = useStyles();

    const { getPlanoLicenca, carregando: carregandoGet } = useGetPlanoLicenca()
    const { putPlanoLicenca, carregando: carregandoPut } = usePutPlanoLicenca();
    const { refreshPlanoUsuario, carregando: carregandoRefresh } = useSessaoAtual();


    const carregando = carregandoGet || carregandoPut || carregandoRefresh

    const formRef = useRef<DefaultFormRefs<PlanoFormModel>>(null)
    const planoRef = useRef<PlanoGetModel>(new PlanoGetModel())

    const fillForm = useCallback((model: PlanoFormModel) => {
        formRef.current?.fillForm(model)
    }, [])

    const [plano, setPlano] = useState<PlanoFormModel>(new PlanoFormModel())
    const [help, setHelp] = useState<boolean>(false)

    useEffect(() => {
        fillForm(plano)
    }, [fillForm, plano])

    const getPlanoWrapper = useCallback(async () => {
        try {
            const res = await getPlanoLicenca()
            if (res.erro) throw res.erro

            const data = res.resultado?.data as PlanoGetModel

            planoRef.current = data;

            const planoModel: PlanoFormModel = {
                id: data.id,
                modulos: data.modulos.map((mod: ModuloPlanoModel) => {
                    const tabela = data.dadosTela.precos.tabelas.find((x: any) => x.tabelaPrecoId === data.tabelaPrecoId);
                    if (tabela) {
                        const tabPreco = tabela.precoModulos.find((prec: any) => prec.modulo.id === mod.moduloId);

                        if (tabPreco) {
                            return {
                                ...mod,
                                valorOriginal: tabPreco.valor as number,
                                percAdicional: tabPreco.percAdicional as number,
                            }
                        }
                    }
                    return mod;
                }),
                valor: data.valorVigente,
                valorCalculado: data.valorCalculado,
                tabelaPrecoId: data.tabelaPrecoId,
                sistemaId: data.sistemaId
            }
            setPlano(planoModel)
        } catch (e: any) {
            showToast('error', e.message)
        }
    }, [getPlanoLicenca, showToast])


    useEffect(() => {
        getPlanoWrapper()
    }, [getPlanoWrapper])

    const handleSubmit = useCallback(async (model: PlanoFormModel) => {
        try {
            const putModel = new PlanoPutModel(
                model.id,
                planoRef.current.clienteId,
                model.sistemaId,
                planoRef.current.ambienteId,
                '',
                model.tabelaPrecoId,
                planoRef.current.formaPagamentoId,
                planoRef.current.melhorDia,
                planoRef.current.dataExpiracao,
                model.valorCalculado,
                model.valor,
                model.modulos.map(modulo => {
                    return new ModuloPlanoPutModel(modulo.id, modulo.moduloId, modulo.codigo, modulo.quantidadeContratada)
                }),

            )
            const res = await putPlanoLicenca(putModel)
            if (res.erro) throw res.erro
            await refreshPlanoUsuario()
            showToast('success', 'Plano atualizado com sucesso!')
            history.push(`${pathToRedirect}`);
            fecharDialogPlanoCustomizado()
            return
        } catch (e: any) {
            showToast('error', e.message)
        }
    }, [fecharDialogPlanoCustomizado, history, pathToRedirect, putPlanoLicenca, refreshPlanoUsuario, showToast])

    const confirmSubmit = useCallback(() => {
        confirm({
            title: 'Alterar Plano',
            description: 'Tem certeza que deseja alterar seu plano?',
            cancellationText: 'Cancelar',
            confirmationText: 'Confirmar'
        }).then(() => formRef.current?.submitForm());
    }, [confirm])

    return (
        <DialogSaurus
            aberto={openned}
            titulo={<Box flex alignItems='center'>
                Personalizar Plano
                <Tooltip title='Ajuda'>
                    <div onClick={() => setHelp(true)} className={classes.headerContainer}>
                        <InterrogacaoIcon
                            tipo="BUTTON"
                            style={{
                                width: 20,
                            }}
                        />
                    </div>
                </Tooltip>
            </Box>}
            tamanho='md'

            bottomArea={<Box flex justifyContent='flex-end' gap={8} p={1}>
                <Box>
                    <Button variant='outlined' color='primary' onClick={fecharDialogPlanoCustomizado}>
                        <VoltarIcon tipo='BUTTON' />
                        Fechar
                    </Button>
                </Box>
                <Box>
                    <Button variant='contained' color='primary' onClick={confirmSubmit}>
                        <UpgradeIcon tipo='BUTTON_PRIMARY' />
                        Contratar Plano
                    </Button>
                </Box>
            </Box>}
        >
            {carregando && <CircularLoading tipo='FULLSIZED' />}
            {!carregandoGet && <FormPlano
                loading={carregando}
                onSubmit={handleSubmit}
                showLoading={carregando}
                ref={formRef}
            />}
            {help && <DialogHelpPlano openned={help} closeDialog={() => setHelp(false)} />}
        </DialogSaurus>
    )
}