import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";
import { VariaveisAmbiente } from "config";

export function useGetEmpresasUsuario() {
  const { invocarApi, ...outrasPropriedades } = useApiBase();

  const getEmpresasUsuario = useCallback(
    (nome: string, usuarioId?: string, page?: number) => {
      return invocarApi({
        url: `/${VariaveisAmbiente.gatewayVersion}/UsuarioEmpresaPerfil?Query=${nome}${usuarioId ? `&usuarioId=${usuarioId}` : ''}&page=${page ? page : 1}`,
        method: "GET",
        enviarTokenUsuario: true,
      });
    },
    [invocarApi]
  );

  const getEmpresasUsuarioDrop = useCallback(
    () => {
      return invocarApi({
        url: `/${VariaveisAmbiente.gatewayVersion}/UsuarioEmpresaPerfil?page=1&pagesize=0`,
        method: "GET",
        enviarTokenUsuario: true,
      });
    },
    [invocarApi]
  );
  return {
    ...outrasPropriedades,
    getEmpresasUsuario,
    getEmpresasUsuarioDrop
  };
}
