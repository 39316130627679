import { Box, Tooltip } from 'views/design-system';
import { useStyles } from './menu-favoritos-styles';
import { useMenuFavoritos } from 'services/app/hooks/menu-favoritos';
import { CardItemMenu } from './components/card-item-menu/card-item-menu';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { useCallback, useEffect, useState } from 'react';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { MenuModel } from 'model/app';
import { MenuFavoritosProps } from './menu-favoritos-props';
import classNames from 'classnames';
import { useEmpresaAtual } from 'services/app/hooks/empresa-atual';
import { EnumEmpresaConfig } from 'model/enums/enum-empresa-config';
import { EnumMenu, EnumMovModelo } from 'model';
import { DeliveryIcon } from "views/components/icons/delivery-icon"
import { useMovAtual } from 'services/app/hooks/mov-atual';

export const MenuFavoritos = ({ renderTour = false }: MenuFavoritosProps) => {
  const classes = useStyles();
  const { getFavoritos } = useMenuFavoritos();
  const { addHandler, removeHandler } = useEventTools();
  const [menus, setMenus] = useState<MenuModel[]>(getFavoritos() ?? []);
  const [active, setActive] = useState<boolean>(true);
  const { getConfigByCod } = useEmpresaAtual()
  const solicitarClienteVenda = getConfigByCod(EnumEmpresaConfig.CamposSolicitarCliente)
  const { getMov } = useMovAtual();
  const soliClientesPorModeloTrabalho = useCallback(() => {
    return Number(solicitarClienteVenda)
  }, [solicitarClienteVenda])
  const [activeDelivery, setActiveDelivery] = useState<boolean>(getMov()?.mod === EnumMovModelo.DELIVERY);

  const checkMenuPDV = useCallback(() => {
    setMenus(getFavoritos());
  }, [getFavoritos]);

  const checkDelivery = useCallback(() => {
    setActiveDelivery(getMov()?.mod === EnumMovModelo.DELIVERY);
  }, [getMov]);

  useEffect(() => {
    addHandler(AppEventEnum.MenuPDVFavorito, checkMenuPDV);
    addHandler(AppEventEnum.AlterarMenuPDV, setActive);
    addHandler(AppEventEnum.MovAtualAlterada, checkDelivery);
    return () => {
      removeHandler(AppEventEnum.MenuPDVFavorito, checkMenuPDV);
      removeHandler(AppEventEnum.AlterarMenuPDV, setActive);
      removeHandler(AppEventEnum.MovAtualAlterada, checkDelivery);
    };
  }, [addHandler, checkDelivery, checkMenuPDV, getFavoritos, removeHandler]);

  const hasOptionsMenu = menus.length > 0

  return active && hasOptionsMenu ? (
    <Box
      className={classNames(
        classes.drawer,
      )}
    >
      {activeDelivery && (
        <Box className={classes.contentDelivery}>
          <Tooltip title="A venda está no modo delivery">
            <div>
              <DeliveryIcon tipo="GERAL" class={classes.iconDelivery} />
            </div>
          </Tooltip>
        </Box>
      )}
      {menus.filter(m => {
        if (soliClientesPorModeloTrabalho() === 0) {
          return m.codigo !== EnumMenu.OPERACIONAL_DENTROVENDA_IDENTIFICAR_CLIENTE
        }

        return m
      }).map((menu) => {
        return <CardItemMenu key={menu.id} model={menu} renderTour={renderTour} />;
      })}
    </Box>
  ) : (
    <></>
  );
};
