import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { useStyles } from './components/list-styles';
import {
  TabSaurusContent,
  TabSaurusLabel,
  TabsSaurus,
} from 'views/components/tabs/tabs-saurus';
import { UploadCargaDetalhadoModel, UploadCargaPessoa, UploadCargaProduto } from 'model/api/gestao/upload-carga/upload-carga-model';
import { EnumUploadCargaTipo } from 'model/enums/enum-upload-carga-tipo';
import { ProdutosListData } from './components/produtos-list-data';
import { ParceirosListData } from './components/parceiros-list-data';
import { useCadastros } from 'services/app';
import { EnumUploadCargaStatus } from 'model/enums/enum-upload-carga-status';
import { PaginacaoLocal } from 'views/components/paginacao/paginacao-local';
import { Grid } from 'views/design-system';

interface CargasTabsProps {
  carga: UploadCargaDetalhadoModel;
}

export const CargasTabs = ({ carga }: CargasTabsProps) => {
  // rotas
  const rotas = useMemo(
    () => [
      `/upload-carga/detalhes/${carga.id}/todas`,
      `/upload-carga/detalhes/${carga.id}/rejeitados`,
    ],
    [carga.id],
  );

  // PROVIDERS
  const { abrirCadastroUploadCarga } = useCadastros();

  // AUX
  const { replace, location } = useHistory();
  const classes = useStyles();

  const retornaAba = useCallback(
    (path: string) => {
      const rotaAtual = rotas.findIndex((rota) => rota === path);
      if (rotaAtual > -1) {
        return rotaAtual;
      }
      return 0;
    },
    [rotas],
  );

  const tabChange = (index: number) => {
    replace(rotas[index]);
  };

  const onCardChecked = (model: UploadCargaPessoa | UploadCargaProduto) => {
    const urlCallback = `/upload-carga/detalhes/${carga.id}`;
    abrirCadastroUploadCarga(carga.id, urlCallback, false, carga.tipo, model);
  };

  // STATES
  const [abaSelecionada, setAbaSelecionada] = useState(
    retornaAba(location.pathname),
  );

  useEffect(() => {
    const aba = retornaAba(location.pathname);
    setAbaSelecionada(aba);
  }, [location.pathname, retornaAba]);

  const produtosAtivos = carga.produtos.filter(prod => prod.status.codigo !== EnumUploadCargaStatus.Erro && prod.status.codigo !== EnumUploadCargaStatus.Cancelado)
  const produtosCancelados = carga.produtos.filter(prod => prod.status.codigo === EnumUploadCargaStatus.Erro || prod.status.codigo === EnumUploadCargaStatus.Cancelado)

  const pessoasAtivos = carga.pessoas.filter(prod => prod.status.codigo !== EnumUploadCargaStatus.Erro && prod.status.codigo !== EnumUploadCargaStatus.Cancelado)
  const pessoasCancelados = carga.pessoas.filter(prod => prod.status.codigo === EnumUploadCargaStatus.Erro || prod.status.codigo === EnumUploadCargaStatus.Cancelado)

  return (
    <>
      <TabsSaurus
        selectedTabIndex={abaSelecionada}
        onChange={tabChange}
        tabsLabel={[
          new TabSaurusLabel('Ativas', 0),
          new TabSaurusLabel('Rejeitadas', 1),
        ]}
        tabsContent={[
          new TabSaurusContent(
            0,
            (
              <div className={classes.containerFull}>
                <div className={classes.defaultContainer}>
                  <Grid item xs={12} className={classes.listContainer}>
                    {carga.tipo === EnumUploadCargaTipo.PRODUTO ? (
                      <PaginacaoLocal<UploadCargaProduto>
                        list={produtosAtivos}
                        listPropName='list'
                      >
                        <ProdutosListData
                          carregando={false}
                          list={produtosAtivos}
                          onCardChecked={onCardChecked}
                        />
                      </PaginacaoLocal>
                    ) : (
                      <PaginacaoLocal<UploadCargaPessoa>
                        list={pessoasAtivos}
                        listPropName='list'
                      >
                        <ParceirosListData
                          carregando={false}
                          list={pessoasAtivos}
                          onCardChecked={onCardChecked}
                        />
                      </PaginacaoLocal>
                    )}
                  </Grid>
                </div>
              </div >
            ),
          ),
          new TabSaurusContent(
            1,
            (
              <div className={classes.containerFull}>
                <div className={classes.defaultContainer}>
                  <Grid item xs={12} className={classes.listContainer}>
                    {carga.tipo === EnumUploadCargaTipo.PRODUTO ? (
                      <PaginacaoLocal<UploadCargaProduto>
                        list={produtosCancelados}
                        listPropName='list'
                      >
                        <ProdutosListData
                          carregando={false}
                          list={produtosCancelados}
                          onCardChecked={onCardChecked}
                        />
                      </PaginacaoLocal>
                    ) : (
                      <PaginacaoLocal<UploadCargaPessoa>
                        list={pessoasCancelados}
                        listPropName='list'
                      >
                        <ParceirosListData
                          carregando={false}
                          list={pessoasCancelados}
                          onCardChecked={onCardChecked}
                        />
                      </PaginacaoLocal>
                    )}
                  </Grid>
                </div>
              </div>
            ),
          ),
        ]}
      />
    </>
  );
};