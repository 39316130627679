import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import { EnumPlanoOpcoes } from 'model/enums/enum-plano-opcoes';
import { useMemo } from 'react';
import { EditarIcon, OlhoAbertoIcon } from 'views/components/icons';
import { UpgradeIcon } from 'views/components/icons/upgrade-icon';
import { CardPlanoProps } from './card-plano-props';
import { useStyles } from './card-plano-styles';
import { isEmpty } from 'lodash';
import { useDefaultCardStyles } from '../components';
import classNames from 'classnames';
import { Box, Button, Divider, Grid, Tooltip, Typography } from 'views/design-system';

const CardPlano = ({
  model,
  showDetailButton,
  isMostPopular,
  isSelected,
  onDetail,
  isAvulso
}: CardPlanoProps) => {
  const classes = useStyles();
  const classesCard = useDefaultCardStyles();

  // const limiteVendas = getPlanoLimite(model, EnumPlanoOpcoes.Vendas);

  const diasExpiracao = model.opcoes.find(
    (opcoes) => opcoes.codigo === EnumPlanoOpcoes.DiasExpiracao
  );

  const planoFragment = useMemo(() => {
    return (
      <>
        <div className={classes.cardContent}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box className={classes.cardContainerDescricao}>
                <Typography variant="h5" className={classes.cardTitle}>
                  {model?.nome}
                </Typography>
                <Tooltip title={model!.descricao} placement="top">
                  <Typography
                    variant="caption"
                    color="secondary"
                    className={classNames(
                      classes.cardSubTitle,
                      classes.cardDescricao
                    )}
                  >
                    {model?.descricao}
                  </Typography>
                </Tooltip>
              </Box>
            </Grid>

            <Grid item xs={12} className={classes.cardDescricaoValor}>
              {model?.valor === 0 && model.valorPromo === 0 && (
                <Typography
                  variant="h4"
                  color="primary"
                  className={classes.cardPricingFree}
                >
                  {model.id === 'custom' ? '' : 'Grátis'}
                </Typography>
              )}

              {model?.valor !== 0 && model.valorPromo === model?.valor && (
                <Box className={classes.boxCustom}
                >
                  <Typography
                    variant="h5"
                    color="primary"
                    className={classes.cardPricingR$}
                  >
                    R$
                  </Typography>
                  <Typography
                    variant="h4"
                    color="primary"
                    className={classes.cardPricing}
                  >
                    {model?.valor.toFixed(2).replace('.', ',')}
                  </Typography>
                  <Box className={classes.cardTextPerMonth}>
                    <Typography
                      variant="body1"
                      color="primary"
                      className={classes.textPerMonth}
                    >
                      /mês
                    </Typography>
                  </Box>
                </Box>
              )}

              {model?.valorPromo > 0 && model?.valorPromo !== model?.valor && (
                <>
                  <Grid
                    container
                    spacing={2}
                    className={classes.cardContainerDesconto}
                  >
                    <Grid item xs={12} className={classes.cardItemDesconto}>
                      {model.valorPromo < model.valor && (
                        <div
                          className={classNames(
                            classesCard.celulaGrid,
                            classes.textRiscado
                          )}
                        >
                          <Typography
                            variant="body1"
                            className={classes.cardPricingR$}
                          >
                            R$
                          </Typography>
                          <Typography
                            variant="body1"
                            className={classes.cardPricing}
                          >
                            {model?.valor.toFixed(2).replace('.', ',')}
                          </Typography>
                        </div>
                      )}
                      <div
                        className={classNames(
                          classesCard.celulaGrid,
                          classes.cardPrincingDesconto
                        )}
                      >
                        <Typography
                          variant="h5"
                          color="primary"
                          className={classes.cardPricingR$}
                        >
                          R$
                        </Typography>
                        <Typography
                          variant="h4"
                          color="primary"
                          className={classes.cardPricing}
                        >
                          {model?.valorPromo.toFixed(2).replace('.', ',')}
                        </Typography>
                        <Box className={classes.cardTextPerMonth}>
                          <Typography
                            variant="body1"
                            color="primary"
                            className={classes.textPerMonth}
                          >
                            /mês
                          </Typography>
                        </Box>
                      </div>
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>

            <Grid item xs={12}>
              <Divider className={classes.divider} />
            </Grid>

            <Grid item xs={12}>
              <Box
                className={classNames(
                  classes.containerItem,
                  classes.cardOpcoes
                )}
              >
                {!!model.opcoes &&
                  model.opcoes.length > 0 &&
                  model?.opcoes
                    .sort(function (a, b) {
                      if (a.nome < b.nome) {
                        return -1;
                      }
                      if (a.nome > b.nome) {
                        return 1;
                      }
                      return 0;
                    })
                    .map((itemOpcoes, index) => {
                      return (
                        <Grid className={classes.list} key={index}>
                          {model.id !== 'custom' && (
                            <>
                              {parseInt(itemOpcoes.valor) > 0 ? (
                                <CheckIcon color="primary" fontSize="small" />
                              ) : (
                                <CloseIcon color="error" fontSize="small" />
                              )}
                            </>
                          )}
                          <Tooltip title={itemOpcoes.descricao} placement="top">
                            <Typography
                              variant="body2"
                              className={classes.cardOptionItem}
                              noWrap
                            >
                              {itemOpcoes.nome}
                            </Typography>
                          </Tooltip>
                        </Grid>
                      );
                    })}
                {isMostPopular && !isEmpty(diasExpiracao) && (
                  <Grid
                    className={classes.list}
                    key={EnumPlanoOpcoes.DiasExpiracao}
                  >
                    <CheckIcon fontSize="small" />
                    <Tooltip
                      title={diasExpiracao?.descricao ?? ''}
                      placement="top"
                    >
                      <Typography
                        variant="body2"
                        className={classes.cardOptionItem}
                        noWrap
                      >
                        {`${diasExpiracao?.valor} ${diasExpiracao?.nome}`}
                      </Typography>
                    </Tooltip>
                  </Grid>
                )}
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={1}>
                {/* <Grid item xs={12} className={classes.cardLimiteVendas}>
                  <Typography variant="body2">
                    <Box fontWeight={600} height={20}>
                      {model.id !== 'custom' &&
                        (limiteVendas === -1
                          ? 'Vendas Ilimitadas'
                          : 'Limite de ' + limiteVendas + ' vendas por mês')}
                    </Box>
                  </Typography>
                </Grid> */}
                {showDetailButton && model?.id && (
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                      className={classes.buttonViewMore}
                      size="small"
                      onClick={() => onDetail(model)}
                    >
                      {isAvulso ? (
                        <EditarIcon tipo="BUTTON_PRIMARY" />
                      ) : isSelected ? (
                        <OlhoAbertoIcon tipo="BUTTON_PRIMARY" />
                      ) : (
                        <UpgradeIcon tipo="BUTTON_PRIMARY" />
                      )}
                      {isAvulso
                        ? 'Personalizar'
                        : isSelected
                          ? 'Visualizar'
                          : 'Contratar'}
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </div>
      </>
    );
  }, [classes.boxCustom, classes.buttonViewMore, classes.cardContainerDesconto, classes.cardContainerDescricao, classes.cardContent, classes.cardDescricao, classes.cardDescricaoValor, classes.cardItemDesconto, classes.cardOpcoes, classes.cardOptionItem, classes.cardPricing, classes.cardPricingFree, classes.cardPricingR$, classes.cardPrincingDesconto, classes.cardSubTitle, classes.cardTextPerMonth, classes.cardTitle, classes.containerItem, classes.divider, classes.list, classes.textPerMonth, classes.textRiscado, classesCard.celulaGrid, diasExpiracao, isAvulso, isMostPopular, isSelected, model, onDetail, showDetailButton]);

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Box
            className={
              isSelected
                ? classes.backGroundTitlePlanSelect
                : isMostPopular && isSelected
                  ? classes.backGroundTitleMostPopularPlan
                  : classes.backGroundTitle
            }
          >
            <Typography variant="body1" className={classes.textTopTitle}>
              {isSelected
                ? '> Plano Atual <'
                : isMostPopular && isSelected
                  ? 'Mais Popular!'
                  : ''}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box
            key={model.id}
            className={
              isSelected
                ? classes.borderPlanSelect
                : isMostPopular && isSelected
                  ? classes.borderMostPopularPlan
                  : classes.cardContainer
            }
          >
            {planoFragment}
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export { CardPlano };
