import { makeStyles } from "@material-ui/core";


export const useStyles = makeStyles((theme) => ({
    "@keyframes fadein": {
        "0%": {
            opacity: 0
        },
        "100%": {
            opacity: 1
        }
    },
    publicPageHeaderContainer: {
        position: 'fixed',
        top: 0,
        right: 0,
        width: '100vw',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        '& >div': {
            zIndex: -1,
        },
        '& :last-child': {
            zIndex: 1,
        }
    },
    publicPageHeaderContainedPage: {
        position: 'absolute',
        top: 0,
        right: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        '& >div': {
            zIndex: 1,
        },
        '& :last-child': {
            zIndex: 1,
        }
    },
    publicPageHeaderContainerWhite: {
        "& svg": {
            width: '22vw',
            [theme.breakpoints.up('sm')]: {
                width: '17vw',
            },
            [theme.breakpoints.up('md')]: {
                width: '12vw',
            },
            [theme.breakpoints.up('lg')]: {
                width: '9vw',
            }
        }
    },
    publicPageHeaderContainedPageWhite: {
        "& svg": {
            width: '22%' ,
            [theme.breakpoints.up('sm')]: {
                width: '17%' ,
            },
            [theme.breakpoints.up('md')]: {
                width: '12%' ,
            },
            [theme.breakpoints.up('lg')]: {
                width: '9%' ,
            }
        }
    },
    publicPageHeaderContainerBlue: {
        "& svg": {
            width: '27vw',
            [theme.breakpoints.up('sm')]: {
                width: '20vw',
            },
            [theme.breakpoints.up('md')]: {
                width: '15vw',
            },
            [theme.breakpoints.up('lg')]: {
                width: '12vw',
            }
        }
    },
    publicPageHeaderContainedPageBlue: {
        "& svg": {
            width: '27%',
            [theme.breakpoints.up('sm')]: {
                width: '20%',
            },
            [theme.breakpoints.up('md')]: {
                width: '15%',
            },
            [theme.breakpoints.up('lg')]: {
                width: '12%',
            }
        }
    },
    publicPageHeaderTopContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        "& svg:nth-child(2)": {
            marginTop: '20px',
        },
    },
    publicPageHeaderRightContainer: {
        position: 'fixed',
        right: 0,
        top: '50%',
        transform: `translateY(-50%)`,

    },
    publicPageHeaderBottomContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end',

    },
    publicPageHeaderVersionControl: {
        position: 'fixed',
        bottom: 0,
        right: 0,
        opacity: 0.6,
    }
}));

