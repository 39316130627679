import { PagsModel } from "model/api/gestao/venda/venda-completa-model"

export interface RetornoTecladoProps {
    pag?: PagsModel,
    credenciais?: string,
    credenciado?: boolean,
    valor?: number
}

export enum EnumTpTecladoFuncoes {
    Inteiro,
    Decimal,
    Pagamento
}


export interface TecladoFuncoesProps {
    isPagFatura?: boolean
    tipo: EnumTpTecladoFuncoes
    valor?: number,
    callback: (model: RetornoTecladoProps) => boolean | Promise<boolean>
    label?: string
}