import { useState, useCallback, useEffect } from 'react';
import { useStyles } from './produto-list-styles';
import { ProdutosMedListData } from './produto-list-data';
import { useToastSaurus } from 'services/app';
import { MovSimplesProdutoModel } from 'model/api/gestao/movimentacao/simples/mov-simples-produto-model';
import { useMovAtual } from 'services/app/hooks/mov-atual';
import { ProdutoListProps } from './produto-list-props';
import { Grid } from 'views/design-system';

export const ProdutoList = ({ refMedicamentos, ...props }: ProdutoListProps) => {
  const classes = useStyles();

  const { showToast } = useToastSaurus();
  const [queryStatus, setQueryStatus] = useState({
    list: Array<MovSimplesProdutoModel>()
  });

  const [selectedList, setSelectedList] = useState<Array<MovSimplesProdutoModel>>([]);

  const { retornaProdutosMedicamentos } = useMovAtual();

  const fillResult = useCallback(
    async (list: Array<MovSimplesProdutoModel>) => {
      setQueryStatus({ list });
    }, []);

  const search = useCallback(async () => {
    try {
      const res = retornaProdutosMedicamentos();
      const medicamentos = res ?? [];
      fillResult(medicamentos);
      refMedicamentos.current = medicamentos
      setSelectedList(medicamentos);
    } catch (e: any) {
      showToast('error', e.message);
    }
  }, [fillResult, refMedicamentos, retornaProdutosMedicamentos, showToast]);

  useEffect(() => {
    search();
  }, [search]);

  const onCardSelected = (prod: MovSimplesProdutoModel) => {
    try {
      setSelectedList(prev => {
        if (prev.find(medicamento => medicamento.id === prod.id)) {
          const produtosFilter = prev.filter(medicamento => medicamento.id !== prod.id)
          refMedicamentos.current = produtosFilter
          return produtosFilter
        }

        const newProd = [...prev, prod]
        refMedicamentos.current = newProd
        return newProd
      });
    } catch (e: any) {
      showToast('error', e.message);
    }
  };

  const onCardClick = (prod: MovSimplesProdutoModel) => {
    try {

    } catch (e: any) {

    }
  };

  return (
    <>
      <div className={classes.defaultContainer}>
        <Grid container>
          <Grid item xs={12} className={classes.listContainer}>
            <ProdutosMedListData
              list={queryStatus.list}
              selectedList={selectedList}
              onCardClick={onCardClick}
              onCardSelected={onCardSelected}
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
};

