import { DialogSaurus } from '../dialog-saurus/dialog-saurus';
import { useStyles } from './dialog-opcoes-impressao-styles';
import { TicketIcon, VoltarIcon } from 'views/components/icons';
import { LoadingButton } from 'views/components/utils';
import { ImpressoraIcon } from 'views/components/icons/impressora-icon';
import { Box, Button, Grid, Typography } from 'views/design-system';

interface DialogOpcoesImpressaoProps {
  openned: boolean;
  closeModal: () => void;
  onClickCupom: () => void;
  onClickTicket: () => void;
  carregandoCupom: boolean;
  carregandoTicket: boolean;
}

export const DialogOpcoesImpressao = ({
  openned,
  closeModal,
  carregandoCupom,
  carregandoTicket,
  onClickCupom,
  onClickTicket
}: DialogOpcoesImpressaoProps) => {


  const classes = useStyles();

  return (
    <>
      <DialogSaurus
        aberto={openned || false}
        titulo="Impressões"
        tamanho="xs"
      >
        <Box className={classes.cardContainer}>
          <Box className={classes.cardContent}>
            <Box className={classes.boxCustom}>
              <Grid
                container
                spacing={2}
                style={{ marginBottom: 8 }}
              >
                <Grid item style={{ margin: '0 8px', padding: '4px 0' }} xs={12}>
                  <Typography variant='subtitle2' className={classes.subtitle}>
                    Selecione abaixo para imprimir:
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    fullWidth
                    color="primary"
                    onClick={onClickCupom}
                    disabled={carregandoCupom}
                  >
                    {carregandoCupom && <LoadingButton tipo='AZUL' />} <ImpressoraIcon tipo="BUTTON_PRIMARY" /> Imprimir
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    fullWidth
                    color="primary"
                    disabled={carregandoTicket}
                    onClick={onClickTicket}
                  >
                    {carregandoTicket && <LoadingButton tipo='AZUL' />} <TicketIcon tipo="BUTTON_PRIMARY" />
                    Reimprimir Ticket
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  {closeModal && (
                    <Button
                      variant="outlined"
                      fullWidth
                      color="primary"
                      onClick={() => {
                        closeModal();
                      }}
                    >
                      <VoltarIcon tipo="BUTTON" />
                      Voltar
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </DialogSaurus>
    </>
  );
};
