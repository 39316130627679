import { useApiBase } from "data/api/base";
import { MovRegistrarModel } from "model/api/gestao/mov-pag/mov-registrar";
import { useCallback } from "react";

export function usePostMovRegistrar() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const postMovRegistrar = useCallback(
        (empresaId: string, data: MovRegistrarModel) => {
            return invocarApi({
                // TODO: utilizar versão da VariaveisAmbiente.gatewayVersion
                url: `/v3/empresa/${empresaId}/pagamento/registrar`,
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                data: data,
                enviarTokenUsuario: true,
                baseURL: 'https://api-movs-staging.touchone.com.br/api',
                timeout: 30000,
            });
        },
        [invocarApi]
    );

    return {
        ...outrasPropriedades,
        postMovRegistrar,
    };
}