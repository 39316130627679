import { FormControlLabel } from '@material-ui/core';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { VoltarIcon } from '../../../../icons/voltar-icon';
import { GestaoStorageKeys, useCadastros, useGestaoStorage, useSessaoAtual, useToastSaurus } from 'services/app';
import { useStyles } from './dialog-definicoes-styles';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { useThemeQueries } from 'views/theme';
import { useMovAtual } from 'services/app/hooks/mov-atual';
import { useContratoAtual } from 'services/app/hooks/contrato-atual';
import { useHistory } from 'react-router-dom';
import { useDefaultCadastroStyles } from 'views/pages/private/cadastros/components/default-cadastro-styles';
import { EnumContratoConfig } from 'model/enums/enum-contrato-config';
import { toDecimalString } from 'utils/to-decimal';
import { roundTo } from 'utils/round-to';
import { qualPercent } from 'utils/qual-percent';
import { PessoaIcon } from 'views/components/icons/pessoa-icon';
import { formatarCPFCNPJ } from 'utils/cpfcnpj-format';
import { EditarIcon, GarcomIcon, HistoricoIcon, LixoIcon } from 'views/components/icons';
import { ImpressoraIcon } from 'views/components/icons/impressora-icon';
import { SwitchSaurus } from 'views/components/controles';
import { DescontoIcon } from 'views/components/icons/desconto-icon';
import { AcrescimoIcon } from 'views/components/icons/acrescimo-icon';
import { DividirIcon } from 'views/components/icons/dividir-icon';
import { isPlanoControleMesasComandas, isPlanoFarmaceutico } from 'utils/plano-utils';
import { ModalHeader } from 'views/components/modals/components';
import { ButtonModalHeader } from 'views/components/controles/buttons/button-modal-header';
import { EnumMovModelo } from 'model';
import { CarrinhoIcon } from 'views/components/icons/carrinho-icon';
import { SaurusRadio } from 'views/components/controles/radio/saurus-radio';
import { NfceIcon } from 'views/components/icons/nfce-icon';
import { NfeIcon } from 'views/components/icons/nfe-icon';
import { Button, Divider, Grid, Typography } from 'views/design-system';
import { useMovRota } from 'services/app/hooks/mov-rota';
import { useDesktopMode } from 'services/app/hooks/desktop-mode';
import { MovRotasComCarrinho } from 'data/mocks/mov-rotas-mock';

export const DialogDefinicoes = () => {
  const refTaxa = useRef<HTMLParagraphElement>(null);
  const { getRegistro, setRegistro, } = useGestaoStorage();
  const { abrirVendaAcrescDesc, abrirDialogAlterarQuantidadeClientesVenda, abrirDialogTaxaServicoCarrinho } = useCadastros();
  // HOOKS
  const { plano } = useSessaoAtual();
  const { getConfigByCod } = useContratoAtual()
  const { getMov, alterarTpMod, hasTaxaServicoProduto } = useMovAtual();
  const { location } = useHistory();
  const { callEvent } = useEventTools();
  const { redirectCancelamento, redirectSelecionarCliente } = useMovRota();
  const { redirectPagamentoHistorico } = useMovRota();
  const getCancelados = useCallback(() => {
    const cancelados = getRegistro(
      GestaoStorageKeys.MostrarItensCancelados,
      false
    ) as boolean;

    if (cancelados === true) {
      return true;
    }

    return false;
  }, [getRegistro]);

  // STATES E REFS
  const [tipoVenda, setTipoVEnda] = useState<
    undefined | EnumMovModelo
  >(undefined);
  const [tipoDeVendaText, setTipoDeVendaText] = useState<string>('')
  const [mostrarItensCancelados, setMostrarItensCancelados] = useState(getCancelados());
  const [formTipoDeVenda, setFormTipoDeVenda] = useState<boolean>(false)

  // AUX
  const { showToast } = useToastSaurus();
  const { addHandler, removeHandler } = useEventTools();
  const { fecharDialogDefinicoes } = useCadastros();
  const { getDesktopMode } = useDesktopMode();
  const isDesktopMode = getDesktopMode();
  const cadastroClasses = useDefaultCadastroStyles();
  const classes = useStyles();
  const { theme: { palette } } = useThemeQueries();

  const mov = getMov();
  const retTxtTaxa = useCallback(() => {
    const mov = getMov();

    let valorTaxa = 0;
    let valorProdutos = 0;

    mov?.produtos
      .filter((x) => x.ativo && x.indFin)
      .forEach((x) => {
        if (x.produtoId === getConfigByCod(EnumContratoConfig.ProdutoServico))
          valorTaxa += x.vFinal;

        if (x.cobraTaxaServico)
          valorProdutos += x.vFinal;
      });

    const valorTotal = valorProdutos ?? 0;
    return `R$ ${toDecimalString(roundTo(valorTaxa))} (${roundTo(qualPercent(valorTaxa, valorTotal), 0) ?? 0
      }%)`;
  }, [getConfigByCod, getMov]);

  const movModeloString: any = useMemo(() => ({
    [EnumMovModelo.ORCAMENTO]: 'Venda Simples',
    [EnumMovModelo.NFCE]: 'NFC-e',
    [EnumMovModelo.NFE]: 'NF-e',
    [EnumMovModelo.PEDIDO]: 'Pedido',
    [EnumMovModelo.DELIVERY]: 'Delivery',
  }), [])

  const handleClickTypeOrder = useCallback(
    async (type: EnumMovModelo) => {
      //DEFINIMOS QUE A TROCA DO TIPO SERÀ APENAS NA VENDA ATUAL, QNDO FINALIZAR VOLTARA PARA O TIPO PADRAO CONFIGURADO
      setTipoVEnda(type);
      alterarTpMod(type);
      setTipoDeVendaText(movModeloString?.[type])
      setFormTipoDeVenda(false);
      fecharDialogDefinicoes();
    }, [alterarTpMod, movModeloString, fecharDialogDefinicoes]);

  const setCancelados = useCallback(
    (cancelados: boolean) => {
      setRegistro(GestaoStorageKeys.MostrarItensCancelados, cancelados, false);
      setMostrarItensCancelados(cancelados);
    },
    [setRegistro]
  );

  useEffect(() => {
    const mov = getMov()

    if (!mov) return

    setTipoVEnda(mov.mod);
    setTipoDeVendaText(movModeloString?.[mov.mod])
  }, [getMov, movModeloString]);

  const handleCancel = useCallback(async () => {
    try {
      await redirectCancelamento();
      fecharDialogDefinicoes();
    } catch (e: any) {
      showToast('error', e.message);
    }
  }, [fecharDialogDefinicoes, redirectCancelamento, showToast]);

  const validarRota = (termos: string[]) => {
    return termos.some(termo => location.pathname.toLowerCase().includes(termo.toLowerCase()));
  };

  const validarRotaCarrinho = (termos: string[]) => {
    if (isDesktopMode) {
      return [...MovRotasComCarrinho, ...termos].some(rota => location.pathname.toLowerCase().includes(rota.toLowerCase()));
    }
    return validarRota(termos);
  }

  const handleClickEdit = useCallback(async () => {
    try {
      fecharDialogDefinicoes()
      await redirectSelecionarCliente();
    } catch (e: any) {
      showToast('error', e.message);
    }
  }, [fecharDialogDefinicoes, redirectSelecionarCliente, showToast]);

  const movProdAlterado = useCallback(
    (any: any) => {
      if (refTaxa.current)
        refTaxa.current.textContent = retTxtTaxa();
    },
    [retTxtTaxa]
  );

  useEffect(() => {
    addHandler(AppEventEnum.AtualizarTaxa, movProdAlterado)
    return () => {
      removeHandler(AppEventEnum.AtualizarTaxa, movProdAlterado)
    }
  }, [addHandler, removeHandler, movProdAlterado])

  const isFarmaceutico = isPlanoFarmaceutico(plano?.plano)

  const isPedido = tipoVenda === EnumMovModelo.PEDIDO || tipoVenda === EnumMovModelo.DELIVERY;

  return (
    <Grid className={cadastroClasses.root} style={{ background: '#fff' }}>
      <ModalHeader
        title={!formTipoDeVenda ? 'Definições da Venda' : 'Tipo de Venda'}
        leftArea={
          <ButtonModalHeader
            tooltip="Voltar"
            icon={<VoltarIcon tipo="MODAL_HEADER" />}
            onClick={!formTipoDeVenda ? fecharDialogDefinicoes : () => { setFormTipoDeVenda(false) }}
          />
        }
      />
      {!formTipoDeVenda ? (
        <>
          <Grid className={cadastroClasses.list}>
            <Button fullWidth className={classes.contentOpcao} onClick={handleClickEdit}>
              <Grid xs={12} alignItems='center' flex >
                <PessoaIcon tipo="GERAL" fill={palette.primary.main} style={{ width: 26, height: 26, marginRight: 8 }} />
                <Grid flex flexDirection='column' alignItems='flex-start' justifyContent='flex-start'>
                  <Typography className={classes.sessaoTextItem} variant='caption' style={{ marginBottom: -4 }}>
                    Cliente
                  </Typography>
                  <Typography className={classes.sessaoText}>
                    {mov?.documento ? formatarCPFCNPJ((mov?.documento) || '') + ' - ' : ''}{mov?.cliente?.nome}
                  </Typography>
                </Grid>
              </Grid>
              <Grid justifyContent='flex-end' flex>
                <EditarIcon
                  tipo="GERAL"
                  fill={palette.primary.main}
                  style={{ width: 26, height: 26, marginRight: 8 }}
                />
              </Grid>
            </Button>
            <Divider className={classes.divider} />
            <Button
              fullWidth
              className={classes.contentOpcao}
              onClick={() => {
                if (isPedido) {
                  return
                }
                setFormTipoDeVenda(true)
              }}>
              <Grid container alignItems='center' justifyContent='space-between'>
                <Grid flex alignItems='center'>
                  <ImpressoraIcon
                    tipo="GERAL"
                    style={{ width: 26, height: 26, marginRight: 8 }}
                    fill={palette.primary.main}
                  />
                  <Typography className={classes.sessaoTextItem}>
                    Tipo de Venda
                  </Typography>
                </Grid>
                <Grid flex>
                  <Grid className={classes.tipoDeVenda}>{tipoDeVendaText}
                  </Grid>
                  {!isPedido && <Grid>
                    <EditarIcon
                      tipo="GERAL"
                      fill={palette.primary.main}
                      style={{ width: 26, height: 26, marginRight: 8 }}
                    />
                  </Grid>}
                </Grid>
              </Grid>
            </Button>
            <Divider className={classes.divider} />
            {validarRotaCarrinho(['carrinho']) && (
              <>
                <Button
                  fullWidth
                  className={classes.contentOpcao}
                >
                  <Grid container alignItems="center">
                    <CarrinhoIcon
                      tipo="GERAL"
                      style={{ width: 24, height: 24, marginRight: 8 }}
                      fill={palette.primary.main}
                    />
                    <Typography className={classes.sessaoTextItem}>
                      Exibir produtos cancelados no carrinho
                    </Typography>
                  </Grid>
                  <Grid className={classes.gridCheck} justifyContent='flex-end' style={{ marginRight: 4 }}>
                    <SwitchSaurus
                      variant={'DEFAULT'}
                      checked={mostrarItensCancelados}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setCancelados(event.target.checked);
                        callEvent(AppEventEnum.AtualizarMostrarItensCarrinho, event.target.checked);
                        fecharDialogDefinicoes();
                      }}
                      className={classes.switchSelect}
                    />
                  </Grid>
                </Button>
                <Divider className={classes.divider} />
              </>
            )}

            {validarRotaCarrinho(['carrinho', 'pagamento']) && (
              <>
                <Button
                  fullWidth
                  className={classes.contentOpcao}
                  onClick={() => {
                    abrirVendaAcrescDesc('desc')
                    fecharDialogDefinicoes();
                  }}
                >
                  <Grid container alignItems="center">
                    <DescontoIcon
                      tipo="GERAL"
                      style={{ width: 24, height: 24, marginRight: 8 }}
                      fill={palette.primary.main}
                    />
                    <Typography className={classes.sessaoTextItem}>
                      Desconto
                    </Typography>
                  </Grid>
                </Button>
                <Divider className={classes.divider} />
                <Button
                  fullWidth
                  className={classes.contentOpcao}
                  onClick={() => {
                    abrirVendaAcrescDesc('acresc')
                    fecharDialogDefinicoes();
                  }}
                >
                  <Grid container alignItems="center">
                    <AcrescimoIcon
                      tipo="GERAL"
                      style={{ width: 24, height: 24, marginRight: 8 }}
                      fill={palette.primary.main}
                    />
                    <Typography className={classes.sessaoTextItem}>
                      Acréscimo
                    </Typography>
                  </Grid>
                </Button>
                <Divider className={classes.divider} />
                {validarRota(['pagamento']) && (<>
                  <Button
                    fullWidth
                    className={classes.contentOpcao}
                    onClick={() => {
                      abrirDialogAlterarQuantidadeClientesVenda()
                      fecharDialogDefinicoes();
                    }}
                  >
                    <Grid container alignItems="center">
                      <DividirIcon
                        tipo="GERAL"
                        style={{ width: 24, height: 24, marginRight: 8 }}
                        fill={palette.primary.main}
                      />
                      <Typography className={classes.sessaoTextItem}>
                        Dividir Conta
                      </Typography>
                    </Grid>
                  </Button>
                  <Divider className={classes.divider} />
                </>)}
                <Button
                  fullWidth
                  className={classes.contentOpcao}
                  onClick={async () => {
                    try {
                      await redirectPagamentoHistorico();
                      fecharDialogDefinicoes();
                    } catch (e: any) {
                      showToast('error', e.message);
                    }
                  }}
                >
                  <Grid container alignItems="center">
                    <HistoricoIcon
                      tipo="GERAL"
                      style={{ width: 24, height: 24, marginRight: 8 }}
                      fill={palette.primary.main}
                    />
                    <Typography className={classes.sessaoTextItem}>
                      Pagamentos Realizados
                    </Typography>
                  </Grid>
                </Button>
                <Divider className={classes.divider} />
              </>
            )}
            {isPlanoControleMesasComandas(plano?.plano) &&
              isFarmaceutico && hasTaxaServicoProduto() && validarRotaCarrinho(['carrinho']) && (
                <>
                  <Button
                    fullWidth
                    className={classes.contentOpcao}
                    onClick={() => {
                      if (
                        (
                          getMov()?.produtos.filter(
                            (x) =>
                              x.produtoId ===
                              getConfigByCod(EnumContratoConfig.ProdutoServico)
                          ) ?? []
                        ).length <= 0
                      ) {
                        showToast('info', 'Não há taxa.');
                        return;
                      }
                      abrirDialogTaxaServicoCarrinho()
                      fecharDialogDefinicoes();
                    }}
                  >
                    <Grid
                      container
                      alignItems="center"
                    >
                      <GarcomIcon
                        tipo="GERAL"
                        style={{ width: 24, height: 24, marginRight: 8 }}
                        fill={palette.primary.main}
                      />
                      <Typography
                        className={classes.sessaoTextItem}
                      >
                        Taxa de Serviço
                      </Typography>
                    </Grid>
                    <Grid
                      style={{ width: 100, display: 'flex', justifyContent: 'flex-end' }}
                    >
                      <div>
                        <Typography
                          className={classes.sessaoTextItem}
                          align="right"
                          style={{ fontWeight: 600, fontSize: 12 }}
                        >
                          Valor
                        </Typography>
                        <Typography
                          ref={refTaxa}
                          className={classes.sessaoTextItem}
                          style={{ fontWeight: 600, fontSize: 12, textAlign: 'right' }}
                          color="primary"
                        >
                          {retTxtTaxa()}
                        </Typography>
                      </div>
                    </Grid>
                  </Button>
                  <Divider className={classes.divider} />
                </>
              )}
            <Button
              fullWidth
              className={classes.contentOpcao}
              onClick={handleCancel}
            >
              <Grid container alignItems="center">
                <LixoIcon
                  tipo="GERAL"
                  style={{ width: 24, height: 24, marginRight: 8 }}
                  fill={'red'}
                />
                <Typography
                  className={classes.sessaoTextItem}
                  color="error"
                >
                  Cancelar venda
                </Typography>
              </Grid>
            </Button>
            <Divider className={classes.divider} />
          </Grid>
        </>
      ) : (
        <>
          <Button
            fullWidth
            className={classes.contentOpcao}
            onClick={() => handleClickTypeOrder(EnumMovModelo.ORCAMENTO)}
          >
            <Grid container alignItems="center">
              <CarrinhoIcon
                tipo="GERAL"
                style={{ width: 32, height: 32, marginRight: 8 }}
                fill={palette.text.primary}
              />
              <Typography className={classes.sessaoTextItem}>
                Venda simples
              </Typography>
            </Grid>
            <FormControlLabel
              className={classes.labelRadio}
              label=""
              checked={tipoVenda === EnumMovModelo.ORCAMENTO}

              control={<SaurusRadio />}
            />
          </Button>
          <Divider className={classes.divider} />
          <Button
            fullWidth
            className={classes.contentOpcao}
            onClick={() => handleClickTypeOrder(EnumMovModelo.NFCE)}
          >
            <Grid container alignItems="center">
              <NfceIcon
                tipo="GERAL"
                style={{ width: 32, height: 32, marginRight: 8 }}
                fill={palette.text.primary}
              />
              <Typography className={classes.sessaoTextItem}>
                NFC-e (Nota Fiscal Consumidor)
              </Typography>
            </Grid>
            <FormControlLabel
              className={classes.labelRadio}
              label=""
              checked={tipoVenda === EnumMovModelo.NFCE}

              control={<SaurusRadio />}
            />
          </Button>
          <Divider className={classes.divider} />
          <Button
            fullWidth
            className={classes.contentOpcao}
            onClick={() => handleClickTypeOrder(EnumMovModelo.NFE)}
          >
            <Grid container alignItems="center">
              <NfeIcon
                tipo="GERAL"
                style={{ width: 32, height: 32, marginRight: 8 }}
                fill={palette.text.primary}
              />
              <Typography className={classes.sessaoTextItem}>
                NF-e (Nota Fiscal A4)
              </Typography>
            </Grid>
            <FormControlLabel
              className={classes.labelRadio}
              label=""
              checked={tipoVenda === EnumMovModelo.NFE}

              control={<SaurusRadio />}
            />
          </Button>
        </>
      )
      }
    </Grid >
  );
};
