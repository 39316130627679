import { Card } from "@material-ui/core";
import { CardPagamentosDetalheVendaProps } from "./card-pagamentos-detalhe-venda-props"
import { toDecimalString } from "utils/to-decimal";
import { useThemeQueries } from "views/theme";
import { Grid, Typography } from "views/design-system";
import { tpPagamentoResolver } from "utils/tp-pagamento-resolver";

export const CardPagamentosDetalheVenda = (props: CardPagamentosDetalheVendaProps) => {

    const { theme } = useThemeQueries();

    const model = props.model;
    const order = props.index + 1;

    return (
        <>
            <Card style={{ boxShadow: theme.shadows[1] }}>
                <Grid container spacing={1} p={1}>
                    <Grid item xs={12} style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: '0px 8px'
                    }}>
                        <div>
                            <Typography
                                variant="caption"
                                color="textPrimary"
                            >
                                Ordem
                            </Typography>
                            <Typography
                                variant="body2"
                                color="textPrimary"
                                weight={500}
                            >
                                {`N° ${order}`}
                            </Typography>
                        </div>
                        <div>
                            <Typography
                                variant="caption"
                                color="textPrimary"
                            >
                                Descrição
                            </Typography>
                            <Typography
                                variant="body2"
                                color='textPrimary'
                                weight={500}
                            >
                                {tpPagamentoResolver(props.model.tPag, props.model.tpTransacao)?.Value} {model.qtdeParcela > 1 && `(${model.qtdeParcela}x)`}
                            </Typography>
                        </div>
                        <div>
                            <Typography
                                variant="caption"
                                color="textPrimary"
                            >
                                Troco
                            </Typography>
                            <Typography
                                variant="body2"
                                color="textPrimary"
                                weight={500}
                            >
                                {`R$ ${toDecimalString(model.vTroco)}`}
                            </Typography>
                        </div>
                        <div>
                            <Typography
                                variant="caption"
                                color="textPrimary"
                            >
                                Total
                            </Typography>
                            <Typography
                                variant="body2"
                                color="textPrimary"
                                weight={500}
                            >
                                {`R$ ${toDecimalString(model.vPag)}`}
                            </Typography>
                        </div>
                    </Grid>
                </Grid>
            </Card>
        </>
    )
}