import { FormRefCTeProps } from "./form-ref-cte-props";
import { Controller } from "react-hook-form";
import { TextFieldSaurus } from "views/components/controles/inputs";
import { Grid } from "views/design-system";


export const FormRefCTe = ({ control, formState, setValue, ...props }: FormRefCTeProps) => {

    const { errors, touchedFields } = formState

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Controller
                    name="refCTe"
                    control={control}
                    render={({ field }) => (
                        <TextFieldSaurus
                            tipo={'NUMERO'}
                            disabled={props.loading}
                            fullWidth
                            autoComplete='new-password'
                            variant="outlined"
                            label="Chave de Acesso de Transporte Eletrônico"
                            allowSubmit
                            placeholder="Ex: 12345678912345678912345678912345"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            error={Boolean(errors.refCTe && errors.refCTe.message)}
                            helperText={
                                touchedFields.refCTe || errors.refCTe
                                    ? errors.refCTe?.message
                                    : undefined
                            }
                            {...field}
                        />
                    )}
                />
            </Grid>
        </Grid>
    );
}
