import { EditarIcon } from "views/components/icons";
import { useDefaultCardStyles } from "../components";
import { CardPerfilUsuariosProps } from "./card-usuario-perfil-props";
import { DefaultCard } from "../components/default-card"
import { useThemeQueries } from "views/theme";
import { isPlanoComVendedor } from "utils/plano-utils";
import { useSessaoAtual } from 'services/app';
import { StatusPerfilMock } from "data/mocks/status-perfil-mock";
import { Tooltip, Typography } from "views/design-system";

export const CardUsuarioPerfil = ({ model, onClick, onCheck, selected }: CardPerfilUsuariosProps) => {

    const defaultCardClasses = useDefaultCardStyles();
    const { plano } = useSessaoAtual()

    const retornarPerfis = () => {
        const varios = model.empresaPerfis.reduce<boolean>((prev, curr, _, arr) => {
            if (prev) return prev;
            return arr.some(x => x.perfil.id !== curr.perfil.id)
        }, false)

        if (varios) {
            return 'Várias'
        }

        return model.empresaPerfis?.[0]?.perfil?.nome || 'Sem Perfil';
    }

    const retornarStatus = () => {
        const varios = model.empresaPerfis.reduce<boolean>((prev, curr, _, arr) => {
            if (prev) return prev;
            return arr.some(x => x.status !== curr.status)
        }, false)

        if (varios) {
            return 'Vários'
        }

        return StatusPerfilMock.find(x => x.Key === model.empresaPerfis?.[0]?.status)?.Value || 'Sem status'
    }

    const planoComVendedor = isPlanoComVendedor(plano?.plano)

    const { xs } = useThemeQueries();

    return (
        <>
            <DefaultCard isSelected={selected}
                onClick={async () => {
                   await onClick(model)
                }}>
                {!xs ? (
                    <div className={defaultCardClasses.cardContent}>
                        <div className={defaultCardClasses.celulaGridFull}>
                            <Typography color="textPrimary" variant="caption">
                                E-mail
                            </Typography>
                            <Typography
                                className={"celula-grid-value"}
                                color="textPrimary"
                                variant="body1">
                                <strong>{model.usuario.email}</strong>
                            </Typography>
                        </div>
                        <div className={defaultCardClasses.celulaGrid}>
                            <Typography color="textPrimary" variant="caption">
                                Status
                            </Typography>
                            <Typography
                                className={"celula-grid-value"}
                                color="textPrimary"
                                variant="body1">
                                <strong>{retornarStatus()}</strong>
                            </Typography>
                        </div>
                        {planoComVendedor && (
                            <div className={defaultCardClasses.celulaGrid}>
                                <Typography color="textPrimary" variant="caption">
                                    Permissão
                                </Typography>
                                <Typography
                                    className={"celula-grid-value"}
                                    color="textPrimary"
                                    variant="body1">
                                    <strong>{retornarPerfis()}</strong>
                                </Typography>
                            </div>
                        )}
                    </div>
                ) : (
                    <div className={defaultCardClasses.cardContent}>
                        <div className={defaultCardClasses.celulaGrid}>
                            <Typography color="textPrimary" variant="caption">
                                Status
                            </Typography>
                            <Typography
                                className={"celula-grid-value"}
                                color="textPrimary"
                                variant="body1">
                                <strong>{retornarStatus()}</strong>
                            </Typography>
                        </div>
                        <div className={defaultCardClasses.celulaGrid} style={{ minWidth: 200 }}>
                            <Typography color="textPrimary" variant="caption">
                                E-mail
                            </Typography>
                            <Typography
                                className={"celula-grid-value"}
                                color="textPrimary"
                                variant="body1">
                                <strong>{model.usuario.email}</strong>
                            </Typography>
                        </div>
                        {planoComVendedor && (
                            <div className={defaultCardClasses.celulaGrid}>
                                <Typography color="textPrimary" variant="caption">
                                    Permissão
                                </Typography>
                                <Typography
                                    className={"celula-grid-value"}
                                    color="textPrimary"
                                    variant="body1">
                                    <strong>{retornarPerfis()}</strong>
                                </Typography>
                            </div>
                        )}
                    </div>
                )}
                <Tooltip arrow title="Editar perfil">
                    <div className={defaultCardClasses.cardRightButton}>
                        <EditarIcon tipo="BUTTON" />
                    </div>
                </Tooltip>
            </DefaultCard>
        </>
    );
};
