import { useStyles } from './generic-form-styles'
import React, { useCallback, useEffect, useRef } from "react";
import { DefaultFormRefs } from "views/components/form/utils";
import { CancelarIcon, LixoIcon, OkIcon } from "views/components/icons";
import { useConfirm } from "material-ui-confirm";
import { Box, Button } from 'views/design-system';

export interface HandleDeleteGenericModel {
    handleDelete: (id: string) => Promise<void>,
    id: string;
    buttonLabel: string;
    secondaryColor?: boolean;
}
interface GenericFormProps<T> {
    model: T;
    buttonLabel: string;
    children: any;
    deleteItem?: HandleDeleteGenericModel;
}

export function GenericForm<T>({
    buttonLabel,
    model,
    children,
    deleteItem,
}: GenericFormProps<T>) {
    const classes = useStyles();
    const confirm = useConfirm();

    const formRef = useRef<DefaultFormRefs<T>>(null)

    useEffect(() => {
        formRef.current?.fillForm(model)
    }, [model])

    const confirmarExclusao = useCallback(() => {
        confirm({
            title: 'Confirmar Ação',
            description: 'Deseja prosseguir com a exclusão? Esta ação não poderá ser desfeita.',
            confirmationText: deleteItem?.buttonLabel,
            cancellationText: 'Cancelar'
        }).then(() => deleteItem?.handleDelete(deleteItem.id))
    }, [confirm, deleteItem])

    return (
        <Box className={classes.formContainer}>
            {React.cloneElement(children, {
                ref: formRef,
            })}
            <Box flex gap={8}>
                {deleteItem && <Button fullWidth variant='outlined' color='primary' className={deleteItem.secondaryColor ? undefined : classes.btnDelete} onClick={() => {
                    //DPS PRETENDO AJUSTAR ISSO TA FEIO POR HORA, SECONDARY COLRO É SÓ UM INDICADOR PRA CASO SEJA UM BOTÃO DE VOLTAR E NÃO UM EXCLUIR XD
                    if (deleteItem.secondaryColor) {
                        deleteItem.handleDelete(deleteItem.id);
                        return
                    }
                    confirmarExclusao();
                }}>
                    {deleteItem.secondaryColor ? <CancelarIcon tipo='BUTTON' /> : <LixoIcon tipo='BUTTON' />}
                    {deleteItem.buttonLabel}
                </Button>}
                <Button fullWidth variant='contained' color='primary' onClick={() => formRef.current?.submitForm()}>
                    <OkIcon tipo='BUTTON_PRIMARY' />
                    {buttonLabel}
                </Button>
            </Box>
        </Box>
    )
}