import { Box, Typography } from "@material-ui/core";
import { MovRegistrarParcelasModel } from "model/api/gestao/mov-pag/mov-registrar"
import { EnumCodigosPermissoes } from "model/enums/enum-codigos-permissoes";
import { useSessaoAtual } from "services/app";
import { toDateString } from "utils/to-date";
import { toCurrency } from "utils/to-decimal";
import { DefaultCard, useDefaultCardStyles } from "views/components/cards/components";
import { EditarIcon } from "views/components/icons";
import { Grid } from "views/design-system";

interface Props {
    model: MovRegistrarParcelasModel;
    index: number;
    onClick: (index: number) => void;
}

export const CardVencimentoPagamento = ({
    model,
    index,
    onClick,
}: Props) => {

    const classes = useDefaultCardStyles();

    const { getPermissaoBoolean } = useSessaoAtual();
    const podeEditar = getPermissaoBoolean(EnumCodigosPermissoes.ALTERAR_VENCIMENTO);

    return (
        <DefaultCard
            onClick={async () => {
                if(podeEditar)
                    onClick(index);
            }}
        >
            <Box flex flexDirection='center' alignItems='center' mr={4} pl={1}>
                <Typography color="primary" variant="caption">
                    Parcela
                </Typography>
                <Typography
                    className={'celula-grid-value'}
                    color="textPrimary"
                    variant="body1"
                >
                    {index + 1}
                </Typography>
            </Box>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Typography color="primary" variant="caption">
                        Valor
                    </Typography>
                    <Typography
                        className={'celula-grid-value'}
                        color="textPrimary"
                        variant="body1"
                    >
                        {toCurrency(model.valor)}
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography color="primary" variant="caption">
                        Data de Vencimento
                    </Typography>
                    <Typography
                        className={'celula-grid-value'}
                        color="textPrimary"
                        variant="body1"
                    >
                        {toDateString(model.dataVencimento)}
                    </Typography>
                </Grid>
            </Grid>
            {podeEditar && <Box flex flexDirection='center' alignItems='center' ml={2} pr={1}>
                <div className={classes.cardRightButton}>
                    <EditarIcon tipo='BUTTON' />
                </div>
            </Box>}
        </DefaultCard>
    )
}