import { MovRegistrarModel, MovRegistrarParcelasModel } from "model/api/gestao/mov-pag/mov-registrar";
import { MovSimplesModel } from "model/api/gestao/movimentacao/simples/mov-simples-model";
import { MovSimplesPagamentoModel } from "model/api/gestao/movimentacao/simples/mov-simples-pagamento-model";
import { useCallback } from "react";
import { useToastSaurus } from "./toast-saurus";
import { useCadastros } from "./cadastros";
import { useCadastroPadrao } from "./cadastro-padrao";
import { guidEmpty } from "utils/guid-empty";
import { newGuid } from "utils/new-guid";
import { toDecimal } from "utils/to-decimal";
import { EnumPagVencimentoTipo } from "model";
import { toDateString } from "utils/to-date";
import { TouchoneDBPrimary } from "database/touchone-database";

export const useVencimentoParcela = () => {

    const { showToast } = useToastSaurus();

    const { abrirDialogVencimentoPagamento } = useCadastros();
    const { getConsumidor } = useCadastroPadrao();

    const calcularDataVencimentoDiaFixo = useCallback((
        diaVencimento: number,
        dataEmissao: Date,
        index: number,
    ): Date => {
        let datasVencimento: Date = new Date();

        // Verifica se a data de emissão está a 10 dias ou menos do vencimento
        const proximoVencimento = new Date(dataEmissao.getFullYear(), dataEmissao.getMonth(), diaVencimento);
        if (dataEmissao > proximoVencimento || (proximoVencimento.getTime() - dataEmissao.getTime()) / (1000 * 60 * 60 * 24) <= 10) {
            // Adiciona um mês se estiver a 10 dias ou menos
            proximoVencimento.setMonth(proximoVencimento.getMonth() + 1);
        }

        // Adiciona as datas de vencimento
        const vencimentoParcela = new Date(proximoVencimento);
        vencimentoParcela.setMonth(vencimentoParcela.getMonth() + index);
        datasVencimento = new Date(vencimentoParcela);

        return datasVencimento;
    }, [])

    const calcularDataVencimentoEParcelas = useCallback((
        index: number,
        parcs: MovRegistrarParcelasModel[],
        pagamento: MovSimplesPagamentoModel,
        tpVencimento: EnumPagVencimentoTipo,
        qDias: number,

    ) => {
        const nParcelas = pagamento.nParcelas || 1;
        let valor = toDecimal(pagamento.vPag / nParcelas);

        //tratando caso dê diferença na última parcela
        if (index === nParcelas - 1) {
            const valorParcelas = valor + parcs.reduce<number>((prev, curr) => prev + curr.valor, 0);
            if (toDecimal(valorParcelas) !== toDecimal(pagamento.vPag)) {
                const diferenca = pagamento.vPag - valorParcelas;
                valor = valor + diferenca;
            }
        }

        if (tpVencimento === EnumPagVencimentoTipo.FIXO) {
            const dataAtual = new Date();
            let data: string | Date = calcularDataVencimentoDiaFixo(qDias, dataAtual, index);
            data = toDateString(data, 'yyyy-MM-DD') || ''
            return new MovRegistrarParcelasModel(index + 1, valor, data, newGuid());
        } else {
            let dateObj: Date = index === 0 ? new Date() : new Date(parcs[index - 1].dataVencimento);
            dateObj.setDate(dateObj.getDate() + qDias);

            let data = toDateString(dateObj, 'yyyy-MM-DD') || '';

            return new MovRegistrarParcelasModel(index + 1, valor, data, newGuid());
        }
    }, [calcularDataVencimentoDiaFixo])

    const getInfoFinalizadora = useCallback(async (pagamento: MovSimplesPagamentoModel) => {
        const finalizadoras = await TouchoneDBPrimary.finalizadoras.toArray();
        const finalizadora = finalizadoras.find(finalizadora => finalizadora.id === pagamento.pagamentoId);
        if (finalizadora) {
            let tpVencimento = finalizadora.vencimento;
            let qDias = finalizadora.qDias ? finalizadora.qDias : new Date().getDate();
            let vMinParcela = finalizadora.vMinParc;
            return {
                tpVencimento,
                qDias,
                vMinParcela,
            }
        }
    }, [])

    const gerarVencimentoEParcelas = useCallback(async (pagamento: MovSimplesPagamentoModel) => {
        let parcs: MovRegistrarParcelasModel[] = [];
        const infoFinalizadora = await getInfoFinalizadora(pagamento);
        if (infoFinalizadora) {
            const { qDias, tpVencimento } = infoFinalizadora;
            for (let i = 0; i < (pagamento.nParcelas || 1); i++) {
                const parc = calcularDataVencimentoEParcelas(i, parcs, pagamento, tpVencimento, qDias);
                parcs.push(parc);
            }

            return parcs;
        }
    }, [calcularDataVencimentoEParcelas, getInfoFinalizadora])

    const gerenciarVencimentos = useCallback((
        pagamento: MovSimplesPagamentoModel,
        parcelas: MovRegistrarParcelasModel[],
    ) => new Promise<MovRegistrarParcelasModel[]>((resolve, reject) => {
        const confirmar = (parcelas: MovRegistrarParcelasModel[]) => {
            resolve(parcelas);
        }
        const cancelar = () => {
            reject(new Error('Operação Cancelada'));
        }

        abrirDialogVencimentoPagamento(
            pagamento,
            parcelas,
            confirmar,
            cancelar
        )
    }), [abrirDialogVencimentoPagamento])

    const handleRegistroPagamento = useCallback(async (
        pagamento: MovSimplesPagamentoModel,
        mov: MovSimplesModel,
    ): Promise<MovRegistrarModel> => new Promise(async (resolve, reject) => {
        try {
            const registrar: MovRegistrarModel = new MovRegistrarModel()

            registrar.finalizadoraId = pagamento.pagamentoId ?? guidEmpty()
            registrar.valor = pagamento.vPag

            const cliente = mov.cliente;
            const clientePadrao = await getConsumidor();

            if (!mov.clienteIdentificado || (cliente && cliente.id === clientePadrao?.id)) {
                throw new Error('Necessário identificar o cliente para prosseguir com esta forma de pagamento!');
            }

            registrar.pagador = {
                pessoaId: cliente?.id || '',
            }

            registrar.numeroTransacao = newGuid();


            let parcelas = await gerarVencimentoEParcelas(pagamento);

            if (!parcelas) throw new Error('Erro ao gerar parcelas. Tente Novamente.')

            parcelas = await gerenciarVencimentos(pagamento, parcelas);

            //TRATAR PARCELAMENTO
            registrar.parcelas = parcelas;

            resolve(registrar)

        } catch (e: any) {
            showToast('error', e.message)
            reject(e.message)
        }
    }), [gerarVencimentoEParcelas, gerenciarVencimentos, getConsumidor, showToast])

    return {
        handleRegistroPagamento,
        getInfoFinalizadora,
    }
}