import { Fade } from "@material-ui/core";
import { VariaveisAmbiente } from "config";
import { EnumDeviceType } from "model/enums/enum-device-type";
import { useState } from "react";
import { CloseIcon } from "views/components/icons/close-icon";
import { FacilIcon } from "views/components/icons/facil-icon";
import { useStyles } from "./card-propaganda-url-nfce-styles";
import { Button, Grid, Typography } from "views/design-system";

export const CardPropagandaUrlNfce = () => {

    const [showCard, setShowCard] = useState<boolean>(true);
    const classes = useStyles();
    return (
        <>
            {VariaveisAmbiente.paymentDevice !== EnumDeviceType.NAVIGATOR && (
                <Fade
                    in={showCard}
                    mountOnEnter
                    unmountOnExit>
                    <Grid className={classes.container}>
                        <Grid className={classes.content}>

                            <Grid className={classes.containerInterno}>
                                <Grid className={classes.header}>
                                    <Grid className={classes.containerTitulo}>
                                        <Grid className={classes.img}>
                                            <FacilIcon tipo="BUTTON_PRIMARY" />
                                        </Grid>
                                        <Typography variant="h6" className={classes.textTitle}>
                                            Desativado nessa versão
                                        </Typography>
                                    </Grid>
                                    <Button
                                        color="secondary"
                                        className={classes.buttonClose}
                                        onClick={() => {
                                            setShowCard(!showCard)
                                        }}
                                    >
                                        <CloseIcon tipo="BUTTON_PRIMARY"></CloseIcon>
                                    </Button>
                                </Grid>
                                <Grid className={classes.contentSubtitle}>
                                    <Grid className={classes.textInfoAndButton}>
                                        <Grid >
                                            <Typography variant="body2" className={classes.text}>
                                                Para editar as informações de NFC-e você precisa acessar nossa versão online:
                                            </Typography>
                                            <Typography variant="body1" className={classes.text}>
                                                <b>
                                                    {VariaveisAmbiente.frontUrl.replaceAll("https://", "").replaceAll("/", "")}
                                                </b>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Fade>
            )
            }
        </>
    );
};
