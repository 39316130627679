import { guidEmpty } from "utils/guid-empty";

export class MovRegistrarErroModel {
    constructor(
        public codigo: string = '',
        public mensagem: string = '',
    ) { }
}

export class MovRegistrarPixModel {
    constructor(
        public pixCopiaCola: string = '',
        public pixChave: string = '',
    ) { }
}

export class MovRegistrarBoletoModel {
    constructor(
        public urlImpressao: string = '',
        public linhaDigitavel: string = '',
        public codigoBarras: string = '',
    ) { }
}

export class MovRegistrarBodyModel {
    constructor(
        public id: string = guidEmpty(),
        public dataRegistro: string = '',
        public dataExpiracao: string = '',
        public nroParcelas: number =  0,
        public pix: MovRegistrarPixModel = new MovRegistrarPixModel(),
        public boleto: MovRegistrarBoletoModel = new MovRegistrarBoletoModel(),
        public erro: MovRegistrarErroModel = new MovRegistrarErroModel(),
    ) { }
}

export class MovRegistrarResponseModel{
    constructor(
        public parcelas: MovRegistrarBodyModel[] = [],
    ){}
}