import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
    inputDate: {
        flex: 1,
        "-webkit-appearance": "none",
        "-moz-appearance": "none",
        "appearance": "none",
        width: '100%'
    },
    formulario:{
        height:"100%", 
        display:'flex'
    },
    dataInput:{
        flex:'1', 
        width:"100%", 
        height:"0"
    }
}))