import { forwardRef, useImperativeHandle, useRef } from 'react';
import { makeUtilClasses, useThemeQueries } from 'views/theme';
import { picker } from 'utils/picker';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import {
    DefaultFormProps,
    DefaultFormRefs,
} from 'views/components/form/utils/form-default-props';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { stringNumeros } from 'utils/string-numeros';
import { useFormMarcaValidation } from './form-marca-validation';
import { MarcaModel } from 'model/api/gestao/marca/marca-model';
import { Box, Button, Grid } from 'views/design-system';

interface FormMarcaFacilitadoProps extends DefaultFormProps<MarcaModel>{
    isFarma: boolean;
}

export const FormMarcaFacilitado = forwardRef<
    DefaultFormRefs<MarcaModel>,
    FormMarcaFacilitadoProps
>(({ loading, isFarma, ...props }: FormMarcaFacilitadoProps, ref) => {
    const utilClasses = makeUtilClasses();
    const refInputNome = useRef<HTMLInputElement>(null);
    const { isMobile } = useThemeQueries();
    const { FormMarcaValidationYup } = useFormMarcaValidation();

    const {
        handleSubmit,
        control,
        formState: { errors },
        reset,
    } = useForm<MarcaModel>({
        defaultValues: { ...new MarcaModel() },
        criteriaMode: 'all',
        mode: 'onChange',
        resolver: yupResolver(FormMarcaValidationYup)
    });

    const onSubmit = (values: MarcaModel) => {
        const lab = picker<MarcaModel>(
            values,
            new MarcaModel(),
        );

        lab.cnpjFab = stringNumeros(lab.cnpjFab)

        props.onSubmit(lab);
    };

    useImperativeHandle(ref, () => ({
        submitForm: async () => {
            await handleSubmit(onSubmit)();
        },
        resetForm: () => {
            if (!isMobile) {
                refInputNome.current?.focus();
            }
        },
        fillForm: (model: MarcaModel) => {
            reset({ ...model });
            setTimeout(() => {
                if (!isMobile) {
                    refInputNome.current?.focus();
                }
            }, 500);
        },
    }));

    return (
        <>
            <Box my={2}>
                <div className={utilClasses.formContainer}>
                    {loading && props.showLoading ? (
                        <div className={utilClasses.controlLoading}>
                            <CircularLoading tipo="NORMAL" />
                        </div>
                    ) : null}
                    <form
                        onSubmit={handleSubmit(onSubmit)}
                        className={loading ? utilClasses.controlLoading : ''}
                    >
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Controller
                                    name="nome"
                                    control={control}
                                    render={({ field }) => (
                                        <TextFieldSaurus
                                            autoComplete='new-password'
                                            inputRef={refInputNome}
                                            fullWidth
                                            disabled={loading}
                                            label={`Nome d${isFarma ? 'o Laboratório' : 'a Marca'}`}
                                            variant="outlined"
                                            error={Boolean(errors.nome && errors.nome.message)}
                                            helperText={
                                                errors.nome
                                                    ? errors.nome?.message
                                                    : undefined
                                            }
                                            {...field}
                                        />
                                    )}
                                />
                            </Grid>
                            {/* <Grid item xs={12}>
                                <Controller
                                    name="cnpjFab"
                                    control={control}
                                    render={({ field }) => (
                                        <TextFieldSaurus
                                            autoComplete='new-password'
                                            fullWidth
                                            disabled={loading}
                                            tipo='CNPJ'
                                            label="CNPJ do Laboratório"
                                            variant="outlined"
                                            error={Boolean(
                                                errors.cnpjFab && errors.cnpjFab.message,
                                            )}
                                            helperText={
                                                errors.cnpjFab
                                                    ? errors.cnpjFab?.message
                                                    : undefined
                                            }
                                            {...field}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    name="indEscala"
                                    control={control}
                                    render={({ field }) => (
                                        <SelectSaurus
                                            variant="outlined"
                                            fullWidth
                                            disabled={loading}
                                            conteudo={IndEscalaMock}
                                            label={'Indicador de Escala Relevante'}
                                            {...field}
                                            onChange={(event) => {
                                                if (event) {
                                                    const valor = IndEscalaMock.filter(
                                                        (item) => item.Key === event.target.value
                                                    )[0]?.Key;
                                                    setValue('indEscala', valor);
                                                }
                                            }}
                                        />
                                    )}
                                />
                            </Grid> */}
                        </Grid>
                        <Button style={{ display: 'none' }} type="submit"></Button>
                    </form>
                </div>
            </Box>
        </>
    );
});