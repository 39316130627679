import React, { FC, useEffect } from 'react';
import ReactDOM from 'react-dom';
import dotenv from 'dotenv';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import { SaurusTheme } from './views/theme/saurus-theme';
import { ConfirmProvider } from 'material-ui-confirm';
import { RouterApp } from 'routers';
import toast, { ToastBar, Toaster, useToasterStore } from 'react-hot-toast';
// import replaceAllInserter from 'string.prototype.replaceall'
import 'react-toastify/dist/ReactToastify.css';
import './assets/css/join.css';
import { VariaveisAmbiente } from './config';
import { ResizeObserver as PolyfillResizeObserver } from '@juggle/resize-observer';
import { useThemeQueries } from 'views';
import 'core-js/features/global-this';
import { RelatorioPerformanceWrapper } from 'services/app/providers/relatorio-performance-provider/relatorio-performance-wrapper';
import { EnumDeviceType } from 'model/enums/enum-device-type';
import { EnvironmentConfig } from 'config/components/environment-config';
import { BarcodeDetector as BarcodeDetectorPolyfill } from "barcode-detector";

try {
  window['BarcodeDetector'].getSupportedFormats()
} catch {
  window['BarcodeDetector'] = BarcodeDetectorPolyfill
}

require('md-gum-polyfill');
require('element-polyfill');

dotenv.config();

const {
  AbortController,
} = require('abortcontroller-polyfill/dist/cjs-ponyfill');
const replaceAllInserter = require('string.prototype.replaceall');
const trimEndInserter = require('string.prototype.trimend');
const trimStartInserter = require('string.prototype.trimstart');
const trimInserter = require('string.prototype.trim');
const padStartInserter = require('string.prototype.padstart');

//strictMode para DEV APENAS
export const StrictMode: FC = ({ children }) => {
  return (
    <>
      {VariaveisAmbiente.isDev && (
        <React.StrictMode>{children}</React.StrictMode>
      )}
      {!VariaveisAmbiente.isDev && <>{children}</>}
    </>
  );
};


//POLYFILL DO ResizeObserver (CONTEXTO PARA NAVEGADOR ANTIGO)
if (!(window as any).ResizeObserver) {
  (window as any).ResizeObserver = PolyfillResizeObserver;
}

const HotToast = () => {
  const { toasts } = useToasterStore();
  const { isMobile } = useThemeQueries();

  const TOAST_LIMIT = 1;

  const insertScriptCheckout = () => {
    const script = document.createElement('script');
    script.src = VariaveisAmbiente.isDev ?
      "https://safrastatic-a.akamaihd.net/safrapay/checkout/dev/safrapay-transparent-dev-v1.0.0.js" :
      "https://safrastatic-a.akamaihd.net/safrapay/checkout/prod/safrapay-transparent-v1.0.0.js";
    document.head.appendChild(script);
  };

  useEffect(() => {
    insertScriptCheckout();
  }, [])

  useEffect(() => {
    toasts
      .filter((t) => t.visible) // Only consider visible toasts
      .filter((_, i) => i >= TOAST_LIMIT) // Is toast index over limit?
      .forEach((t) => toast.remove(t.id)); // Dismiss – Use toast.remove(t.id) for no exit animation
  }, [toasts]);

  return (
    <Toaster
      toastOptions={{
        style: {
          width: '100%',
          zIndex: 3000,
          wordBreak: 'break-word'
        },
        duration: 3000,
      }}
      containerStyle={{
        top: 60,
        left: 16,
        bottom: isMobile ? 65 : 60,
        right: 16,
        zIndex: 3000
      }}
    >
      {(t) => (
        <ToastBar
          toast={t}
          style={VariaveisAmbiente.paymentDevice === EnumDeviceType.CORDOVA_POS ?
            {
              ...t.style,
              animation: 'none',
              zIndex: 3000
            } :
            {
              ...t.style,
            }
          }
        >
          {({ icon, message }) => {
            return (
              <div
                onClick={() => {
                  toast.remove(t.id)
                }}
                style={{ display: 'flex', height: '100%', width: '100%' }}
              >
                {icon}
                {message}
              </div>
            );
          }}
        </ToastBar>
      )}
    </Toaster>
  );
};

if (!Object.entries) {
  Object.entries = function (obj: any) {
    var ownProps = Object.keys(obj),
      i = ownProps.length,
      resArray = new Array(i); // preallocate the Array
    while (i--) resArray[i] = [ownProps[i], obj[ownProps[i]]];

    return resArray;
  };
}

if (!(window as any).AbortController) {
  (window as any).AbortController = AbortController;
}

replaceAllInserter.shim();
trimEndInserter.shim();
trimStartInserter.shim();
trimInserter.shim();
padStartInserter.shim();

// const FeedBackTatil = ({ children }: { children: ReactNode }) => {
//   return (
//     <div
//       id="feedbackTatil"
//       style={{
//         height: '100%',
//         width: '100%',
//         display: 'flex'
//       }}
//       onClick={handleClickFeedbackTatil}
//     >
//       {children}
//     </div>
//   );
// };

const renderReactDom = () => {
  ReactDOM.render(
    <>
      <HotToast />
      <StrictMode>
        <EnvironmentConfig>
          <RelatorioPerformanceWrapper>
            <ThemeProvider theme={SaurusTheme}>
              <CssBaseline>
                <ConfirmProvider
                  defaultOptions={{
                    title: 'Confirma a Operação?',
                    cancellationButtonProps: {
                      variant: 'text',
                      color: 'secondary',
                      size: 'large'
                    },
                    confirmationButtonProps: {
                      variant: 'contained',
                      color: 'primary',
                      size: 'large'
                    },
                    confirmationText: 'Sim',
                    cancellationText: 'Não',
                  }}
                >
                  <RouterApp />
                </ConfirmProvider>
              </CssBaseline>
            </ThemeProvider>
          </RelatorioPerformanceWrapper>
        </EnvironmentConfig>
      </StrictMode>
    </>,
    document.getElementById('react-root'),
  );
};

if ((window as any).cordova) {
  document.addEventListener('deviceready', () => {
    renderReactDom();
  }, false);
} else {
  renderReactDom();
}
