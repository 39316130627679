import { DialogSaurus } from "../dialog-saurus/dialog-saurus"
import { useStyles } from './dialog-aviso-styles'
import { useCallback } from "react";
import { CancelarIcon, InformacaoIcon, OkIcon } from "views/components/icons";
import { useCadastros } from "services/app/hooks/cadastros";
import classNames from "classnames";
import { Box, Button, Typography } from "views/design-system";

interface DialogAvisoProps {
    aberto: boolean;
    text: string;
    mode: 'success' | 'error' | 'warning' | 'info',
    showCloseButton?: boolean;
    permitirFechar?: boolean;
}

export const DialogAviso = ({
    aberto,
    mode,
    text,
    showCloseButton,
    permitirFechar = true,
}: DialogAvisoProps) => {

    const classes = useStyles();

    const returnFill = useCallback((mode: 'success' | 'error' | 'warning' | 'info') => {
        switch (mode) {
            case 'error':
                return classes.fillError
            case 'success':
                return classes.fillSuccess;
            case 'warning':
                return classes.fillWarning;
            case 'info':
                return classes.fillInfo;
        }
    }, [classes.fillError, classes.fillInfo, classes.fillSuccess, classes.fillWarning])

    const returnBtn = useCallback((mode: 'success' | 'error' | 'warning' | 'info') => {
        switch (mode) {
            case 'error':
                return classes.btnError
            case 'success':
                return classes.btnSuccess;
            case 'warning':
                return classes.btnWarning;
            case 'info':
                return classes.btnInfo;
        }
    }, [classes.btnError, classes.btnInfo, classes.btnSuccess, classes.btnWarning])

    const { fecharDialogAviso } = useCadastros()

    const Icon = useCallback(() => {
        const fillClass = returnFill(mode);
        switch (mode) {
            case 'error':
                return <CancelarIcon tipo='GERAL' class={classNames(classes.icon, fillClass)} />;
            case 'success':
                return <OkIcon tipo='GERAL' class={classNames(classes.icon, fillClass)} />;
            case 'info':
                return <InformacaoIcon tipo='GERAL' class={classNames(classes.icon, fillClass)} />;
            case 'warning':
                return <InformacaoIcon tipo='GERAL' class={classNames(classes.icon, fillClass)} />;
        }
    }, [classes.icon, mode, returnFill])

    return (
        <>
            <DialogSaurus
                tamanho='xs'
                aberto={aberto}
                zIndex="1303"
                propagateClick
            >
                <Box className={classes.container} onClick={(e) => e.stopPropagation()}>
                    {Icon()}
                    <Typography className={classNames(classes.text)}>
                        {text.includes('/n') ? text.split('/n').map(value => {
                            return <span>{value}<br /></span>
                        }) : text}
                    </Typography>
                    {showCloseButton && <Button
                        className={returnBtn(mode)}
                        variant='outlined'
                        color='primary'
                        size="small"
                        onClick={() => fecharDialogAviso()}>Fechar</Button>}
                </Box>
            </DialogSaurus>
            <Box className={classes.fecharBox} onClick={() => {
                if (permitirFechar) {
                    fecharDialogAviso()
                }
            }} />
        </>
    )
}