import { EmpresaUsuarioModel } from "model";
import { AppEventEnum } from "model/enums/enum-app-event";
import { useCallback, useEffect, useState } from "react";
import { useEventTools } from "services/app/hooks/events/event-tools";
import { DialogUsuario, DialogUsuarioProps } from "views/components/dialog/dialog-usuario/dialog-usuario";

export const CadastroUsuarioDialog = () => {
    const { addHandler, removeHandler } = useEventTools();
    const [modalState, setModalState] = useState<DialogUsuarioProps>({
        model: new EmpresaUsuarioModel(),
        email: undefined,
        openned: false,
        pessoaId: undefined
    });

    const modalAlterado = useCallback(({ ...props }: any) => {
        setModalState({
            ...props
        });
    }, [])

    useEffect(() => {
        addHandler(AppEventEnum.DialogUsuario, modalAlterado);
        return () => {
            removeHandler(AppEventEnum.DialogUsuario, modalAlterado);
        };
    }, [addHandler, modalAlterado, removeHandler]);


    return (
        <>
            {modalState.openned && <DialogUsuario {...modalState} key="dialogUsuario" />}
        </>
    )
}