import { CardSessaoValorProps } from './card-sessao-valor-props';
import { useCallback } from 'react';
import { DefaultCard } from '../components/default-card';
import { toDecimalString } from '../../../../utils/to-decimal';
import { useThemeQueries } from '../../..';
import { useStyles } from './card-sessao-valor-styles';
import { Tooltip, Typography } from 'views/design-system';

export const CardSessaoValor = ({ model }: CardSessaoValorProps) => {
  const classes = useStyles();
  const { theme, isMobile } = useThemeQueries();

  const retornarCorResponsavel = useCallback((): string => {
    return model.responsavel.responsavelId
      ? theme.palette.success.main
      : theme.palette.error.main;
  }, [
    model.responsavel.responsavelId,
    theme.palette.error.main,
    theme.palette.success.main,
  ]);

  const retornaHoraMovimentacao = useCallback((data: string) => {
    const date = new Date(data);
    return `${date.getHours() < 10 ? '0' + date.getHours() : date.getHours()}:${
      date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
    }`;
  }, []);

  return (
    <>
      <DefaultCard>
        <Tooltip arrow title={'Responsável'} placement="right">
          <div
            className={classes.tagStatus}
            style={{ background: retornarCorResponsavel() }}
          ></div>
        </Tooltip>
        <div className={classes.cardContent}>
          <div
            className={classes.celulaGrid}
            style={{
              flexWrap: 'nowrap',
              overflow: 'hidden',
              minWidth: isMobile ? '215px' : '100%',
              marginTop: '8px',
              maxWidth: isMobile ? '200px' : '100%',
              textOverflow: 'ellipsis'
            }}
          >
            <Typography
              className={'celula-grid-value'}
              color="textPrimary"
              variant="body1"
            >
              <strong>
                {retornaHoraMovimentacao(model.dataInsercao)} -{' '}
                {model.pagamento.descricao}
              </strong>
            </Typography>
            <Typography color="textPrimary" variant="caption" >
              {model.portador.nome} -{' '}
              {model.observacao ? model.observacao : 'Observação não informada'}
            </Typography>
          </div>

          <div
            className={classes.celulaGridValue}
            style={{
              textAlign: 'right',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Typography
              className={'celula-grid-value'}
              color="textPrimary"
              variant="body1"
            >
              <strong>R$ {toDecimalString(model.valor)}</strong>
            </Typography>
          </div>
        </div>
      </DefaultCard>
    </>
  );
};
