import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container: {
    padding: 5,
    width: '100%',
    borderBottom: '1px solid rgba(230, 230, 230, 1)',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0.5),
      marginBottom: 8,
      borderBottom: 'none',
      height: 60,
    }
  },
  btnContent: {
    width: '100%',
    minWidth: 70,
    backgroundColor: undefined,
    '&:hover': {
      backgroundColor: undefined
    },
    [theme.breakpoints.down('sm')]: {
      minWidth: 80
    }
  },
  btnContentWithLocation: {
    width: '100%',
    minWidth: 70,
    backgroundColor: `${theme.palette.primary.main}30`,
    '&:hover': {
      backgroundColor: `${theme.palette.primary.main}30`
    },
    [theme.breakpoints.down('sm')]: {
      minWidth: 80
    }
  },
  btnLabel: {
    display: 'flex',
    flexDirection: 'column'
  },
  icon: {
    fill: theme.palette.primary.main,
    width: 25,
    height: 25,
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      width: 22,
      height: 22,
      marginBottom: 2
    }
  },
  textPrimary: {
    fontSize: 12,
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: 10
    }
  },
  btnText: {
    padding: theme.spacing(1)
  },
  btnRoot: {
    height: 'inherit'
  }

}))


