import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { makeUtilClasses, useThemeQueries } from 'views';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import {
    DefaultFormProps,
    DefaultFormRefs,
} from 'views/components/form/utils/form-default-props';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { CredenciamentoSafra } from 'model/api/gestao/finalizadora/finalizadora-model';
import { useEmpresaAtual } from 'services/app/hooks/empresa-atual';
import { useFormCredenciarPixValidation } from './form-credenciar-pix-validation';
import { SelectSaurus } from 'views/components/controles/selects/select-saurus/select-saurus';
import { AmbientePagamentoMock } from 'data/mocks/ambiente-credencial-mock';
import { EnumAmbientePagamento } from 'model/enums/enum-ambiente-pagamento';
import { Box, Button, Grid } from 'views/design-system';

export const FormCredenciarPix = forwardRef<
    DefaultFormRefs<CredenciamentoSafra>,
    DefaultFormProps<CredenciamentoSafra>
>(
    (
        { loading, ...props }: DefaultFormProps<CredenciamentoSafra>,
        ref,
    ) => {
        const utilClasses = makeUtilClasses();
        const refInputDescricao = useRef<HTMLInputElement>(null);
        const { getEmpresaAtual } = useEmpresaAtual();
        const { FormCredenciarPixvalidation } =
            useFormCredenciarPixValidation();
        const { isMobile } = useThemeQueries();

        const [ambiente, setAmbiente] = useState<EnumAmbientePagamento>(EnumAmbientePagamento.Producao)

        const {
            handleSubmit,
            control,
            reset,
            setValue,
            formState: { errors }
        } = useForm<CredenciamentoSafra>({
            resolver: yupResolver(FormCredenciarPixvalidation),
            criteriaMode: 'all',
            mode: 'onChange'
        });

        const onSubmit = (values: CredenciamentoSafra) => {
            props.onSubmit(values);
        };

        useImperativeHandle(ref, () => ({
            submitForm: async () => {
                await handleSubmit(onSubmit)();
            },
            resetForm: () => {
                reset(new CredenciamentoSafra())
                if (!isMobile) {
                    refInputDescricao.current?.focus();
                }
            },
            fillForm: (model: CredenciamentoSafra) => {
                model.cnpj = getEmpresaAtual()?.cpfcnpj || ''
                model.razaoSocial = getEmpresaAtual()?.razaoSocial || ''
                setAmbiente(model.ambiente)
                reset({ ...model })
                refInputDescricao.current?.focus();
            },
        }));
        return (
            <>
                <Box my={2}>
                    <div className={utilClasses.formContainer}>
                        {loading && props.showLoading ? (
                            <div className={utilClasses.controlLoading}>
                                <CircularLoading tipo="NORMAL" />
                            </div>
                        ) : null}
                        <form
                            onSubmit={handleSubmit(onSubmit)}
                            className={loading ? utilClasses.controlLoading : ''}
                        >
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Controller
                                        name="razaoSocial"
                                        control={control}
                                        render={({ field }) => (
                                            <TextFieldSaurus
                                                disabled={loading}
                                                allowSubmit={false}
                                                id="razaoSocial"
                                                readOnly
                                                label="Razão Social"
                                                fullWidth
                                                autoComplete={'off'}
                                                helperText={
                                                    errors.razaoSocial
                                                        ? errors.razaoSocial?.message
                                                        : undefined
                                                }
                                                error={Boolean(errors.razaoSocial)}
                                                {...field}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Controller
                                        name="cnpj"
                                        control={control}
                                        render={({ field }) => (
                                            <TextFieldSaurus
                                                disabled={loading}
                                                allowSubmit={false}
                                                id="cnpj"
                                                tipo='CNPJ'
                                                label="CNPJ"
                                                readOnly
                                                fullWidth
                                                autoComplete={'off'}
                                                helperText={
                                                    errors.cnpj
                                                        ? errors.cnpj?.message
                                                        : undefined
                                                }
                                                error={Boolean(errors.cnpj)}
                                                {...field}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Controller
                                        name="merchantId"
                                        control={control}
                                        render={({ field }) => (
                                            <TextFieldSaurus
                                                disabled={loading}
                                                allowSubmit={false}
                                                id="merchantId"
                                                label="ID Merchant"
                                                fullWidth
                                                autoComplete={'off'}
                                                helperText={
                                                    errors.merchantId
                                                        ? errors.merchantId?.message
                                                        : undefined
                                                }
                                                error={Boolean(errors.merchantId)}
                                                {...field}
                                                onChange={(e) => {
                                                    e.target.value = e.target.value.trimStart();
                                                    field.onChange(e);
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Controller
                                        name="merchantToken"
                                        control={control}
                                        render={({ field }) => (
                                            <TextFieldSaurus
                                                disabled={loading}
                                                allowSubmit={false}
                                                id="merchantToken"
                                                label="Token Merchant"
                                                fullWidth
                                                autoComplete={'off'}
                                                helperText={
                                                    errors.merchantToken
                                                        ? errors.merchantToken?.message
                                                        : undefined
                                                }
                                                error={Boolean(errors.merchantToken)}
                                                {...field}
                                                onChange={(e) => {
                                                    e.target.value = e.target.value.trimStart();
                                                    field.onChange(e);
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Controller
                                        name="ambiente"
                                        control={control}
                                        render={({ field }) => (
                                            <SelectSaurus
                                                disabled={loading}
                                                allowSubmit={false}
                                                conteudo={AmbientePagamentoMock}
                                                id="ambiente"
                                                label="Ambiente"
                                                fullWidth
                                                autoComplete={'off'}
                                                helperText={
                                                    errors.ambiente
                                                        ? errors.ambiente.message
                                                        : undefined
                                                }
                                                error={Boolean(errors.ambiente)}
                                                {...field}
                                                onChange={ev => {
                                                    const item =
                                                        AmbientePagamentoMock
                                                            .filter(item => item.Key === ev.target.value)

                                                    setValue('ambiente', item[0].Key)
                                                    setAmbiente(item[0].Key)
                                                }}
                                                value={ambiente}
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                            <Button style={{ display: 'none' }} type="submit"></Button>
                        </form >
                    </div >
                </Box >
            </>
        );
    },
);