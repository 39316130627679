import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
    divider: {
        backgroundColor: 'rgb(189, 189, 189)',
        height: .5
    },
    filtros: {
        width: "100%",
        height: "0",
        flex: "1"
    }
}))