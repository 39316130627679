export function getCardBrand(cardNumber: string): string | null {
    // Remove todos os espaços do número do cartão para a validação
    const sanitizedNumber = cardNumber.replace(/\s+/g, '');

    // Regexes para identificar cada bandeira
    const cardPatterns: { [key: string]: RegExp } = {
        visa: /^4[0-9]{12}(?:[0-9]{3})?$/,
        mastercard: /^5[1-5][0-9]{14}$/,
        amex: /^3[47][0-9]{13}$/,
        elo: /^(4011(78|79)|4312(74|75)|4389(35|36)|4514(16|17)|4576(31|32)|5066(99|70|74)|5090(20|21|22|23|24|25)|6277(80|90)|6363(68|69)|6362(97|98))[0-9]{10,12}$/,
        hipercard: /^(38[0-9]{17}|60[0-9]{14})$/
    };

    // Verifica o padrão de cada bandeira
    for (const [brand, pattern] of Object.entries(cardPatterns)) {
        if (pattern.test(sanitizedNumber)) {
            return brand; // Retorna a bandeira identificada
        }
    }

    return null; // Retorna null se nenhuma bandeira for identificada
}