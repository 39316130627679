import { useCallback, useMemo } from "react";
import { useEmpresaAtual } from "./empresa-atual";
import { KeyStoreModel, useGetKeyStore } from "data/api/gestao/key-store/get-key-store";
import { KeyStorePostModel, usePostKeyStore } from "data/api/gestao/key-store/post-key-store";
import { retryReq } from "utils/retry-req";
import { useToastSaurus } from "./toast-saurus";
import { FilaSenhaModel } from "model/api/gestao/fila-senha/fila-senha-model";
import { EnumEmpresaConfig } from "model/enums/enum-empresa-config";
import { EnumConfigResetarFila, EnumTipoIncrementoFila } from "model/enums/enum-fila";
import { toDate, toDateString } from "utils/to-date";
import { SenhaGenerator } from "model/api/gestao/fila-senha/senha-generator";
import { create } from "zustand";
import { persist } from "zustand/middleware";
import { useGetKeyStoreById } from "data/api/gestao/key-store/get-key-store-by-id";
import { GestaoStorageKeys, useGestaoStorage } from "./gestao-storage";
import { validaGuid } from "utils/valida-guid";

interface FilaSenhaStoreState {
    senhaAtual: number;
}

interface IFilaSenhaStore {
    state: FilaSenhaStoreState;
    atualizarSenha: (senhaNova: number) => void;
}

export const useFilaSenhaStore = create<IFilaSenhaStore>()(
    persist(
        (set) => ({
            state: {
                senhaAtual: 0,
            },
            atualizarSenha(senhaNova) {
                set((state) => ({
                    state: {
                        ...state.state,
                        senhaAtual: senhaNova,
                    }
                }))
            },
        }), {
        name: 'fila-senha'
    }
    )
);


export const useFilaSenha = () => {

    const { getKeyStore, carregando: carregandoGet } = useGetKeyStore();
    const { postKeyStore, carregando: carregandoPost } = usePostKeyStore();
    const { getKeyStoreById, carregando: carregandoGetById } = useGetKeyStoreById();
    const { getRegistro, setRegistro } = useGestaoStorage();
    const carregando = carregandoGet || carregandoPost || carregandoGetById;

    const { getEmpresaAtual, getConfigByCod } = useEmpresaAtual();
    const { showToast } = useToastSaurus();
    const saveSenha = useFilaSenhaStore(state => state.atualizarSenha);
    const senhaAtual = useFilaSenhaStore(state => state.state.senhaAtual);

    const senhaApiFila = useMemo(() => `empresa-fila-${getEmpresaAtual()?.id || ''}`, [getEmpresaAtual])
    const gerarSenhaHabilitado = useMemo(() => getConfigByCod(EnumEmpresaConfig.GerarSenha) !== EnumTipoIncrementoFila.NãoGerar, [getConfigByCod])

    const getSenhaString = (senha: number) => {
        const maxDigitos = Number(getConfigByCod(EnumEmpresaConfig.QuantidadeDigitosSenha));
        let senhaStr = senha.toString();
        if (senhaStr.length < maxDigitos) {
            const diff = maxDigitos - senhaStr.length;
            for (let i = 0; i < diff; i++) {
                senhaStr = `0` + senhaStr;
            }
        }
        return senhaStr;
    }

    const getIdFila = useCallback(() => {
        const ret = getRegistro(GestaoStorageKeys.IdFila, false);
        if (typeof ret === 'object') {
            return null;
        }
        if (validaGuid(ret.toString())) {
            return ret.toString()
        };
        return null;
    }, [getRegistro])

    const salvarNovaFila = useCallback(async (
        novaSenha: number,
        historico?: number[],
    ) => {
        const novaFilaModel = new FilaSenhaModel(
            novaSenha,
            toDateString(new Date(), 'MM-DD-yyyy'),
        );

        if (getConfigByCod(EnumEmpresaConfig.GerarSenha) === EnumTipoIncrementoFila.Aleatorio && historico) {
            novaFilaModel.historico = [...historico, novaSenha]
        }

        const valor = JSON.stringify(novaFilaModel);

        const model = new KeyStorePostModel(
            senhaApiFila,
            senhaApiFila,
            valor,
            getEmpresaAtual()?.id
        );

        const res = await retryReq({
            funcao: postKeyStore,
            tentativaAtual: 1,
            args: [model],
        })

        if (res.erro) throw res.erro;

        const idFila = getIdFila();
        if (!idFila) {
            setRegistro(GestaoStorageKeys.IdFila, res.resultado?.data.id || '', false);
        }

        return novaFilaModel;

    }, [getConfigByCod, getEmpresaAtual, getIdFila, postKeyStore, senhaApiFila, setRegistro])

    const retornarFilaAtual = useCallback(async () => {
        let res;
        let data: KeyStoreModel = new KeyStoreModel();
        const idFila = getIdFila();
        if (idFila) {
            res = await retryReq(
                {
                    funcao: getKeyStoreById,
                    args: [idFila],
                    tentativaAtual: 1,
                }
            )

            if (res.erro) throw res.erro

            data = res.resultado?.data as KeyStoreModel;
        } else {
            res = await retryReq({
                funcao: getKeyStore,
                args: [senhaApiFila],
                tentativaAtual: 1,
            });

            if (res.erro) throw res.erro

            data = res.resultado?.data[0] as KeyStoreModel;
        }
        if (data) {

            setRegistro(GestaoStorageKeys.IdFila, data.id, false);
            const filaAtual = JSON.parse(data.valor) as FilaSenhaModel;

            if (!filaAtual) {
                return new FilaSenhaModel(0, '');
            }

            return filaAtual;
        }

        return new FilaSenhaModel(0, '');

    }, [getIdFila, getKeyStore, getKeyStoreById, senhaApiFila, setRegistro])

    const cancelarSenhaAtual = useCallback(async () => {
        if (gerarSenhaHabilitado && senhaAtual) {
            let ultimaSenha;
            switch (getConfigByCod(EnumEmpresaConfig.GerarSenha)) {
                case EnumTipoIncrementoFila.Aleatorio:
                    const filaAtual = await retornarFilaAtual();
                    if (filaAtual.historico) {
                        ultimaSenha = filaAtual.historico[filaAtual.historico.length - 2];
                        filaAtual.historico.pop();

                        await salvarNovaFila(ultimaSenha, [...filaAtual.historico]);
                        break;
                    }
                    return;
                case EnumTipoIncrementoFila.Sequencial:
                    ultimaSenha = senhaAtual - 1;
                    await salvarNovaFila(ultimaSenha);
                    break;
                default:
                    return;
            }

            saveSenha(ultimaSenha);
            return;
        }
    }, [gerarSenhaHabilitado, getConfigByCod, retornarFilaAtual, salvarNovaFila, saveSenha, senhaAtual])

    const gerarSenha = useCallback(async () => {
        try {
            const filaAtual = await retornarFilaAtual();
            const tipoIncremento = getConfigByCod(EnumEmpresaConfig.GerarSenha);
            const maxDigitos = Number(getConfigByCod(EnumEmpresaConfig.QuantidadeDigitosSenha));
            const quandoResetarSenha = getConfigByCod(EnumEmpresaConfig.QuandoResetarSenha);

            let novaSenha = filaAtual.senhaAtual || 0;

            const resetarSenha = () => {
                novaSenha = 0;
                if (filaAtual.historico)
                    filaAtual.historico = [];
            }

            const dataUltimaSenha = toDate(filaAtual.dataUltimaSenha) || new Date();
            if (quandoResetarSenha === EnumConfigResetarFila.Diariamente &&
                (dataUltimaSenha?.getDate() !== new Date().getDate() ||
                    dataUltimaSenha.getMonth() !== new Date().getMonth() ||
                    dataUltimaSenha.getFullYear() !== new Date().getFullYear())
            ) {
                resetarSenha();
            }

            switch (tipoIncremento) {
                case EnumTipoIncrementoFila.Aleatorio:
                    const maxCombinações = Math.pow(10, maxDigitos) - 1
                    if (filaAtual.historico && filaAtual.historico.length >= maxCombinações) {
                        resetarSenha();
                    }
                    const gerador = new SenhaGenerator();
                    novaSenha = gerador.gerarSenhaAleatoria(filaAtual.historico || [], maxDigitos);
                    if(!filaAtual.historico){
                        filaAtual.historico = [];
                    }
                    break;
                case EnumTipoIncrementoFila.Sequencial:
                    if ((novaSenha + 1).toString().length > maxDigitos) {
                        resetarSenha();
                    }
                    novaSenha += 1;
                    break;
                default:
                    throw new Error('Configurado para não gerar senha.');
            }

            await salvarNovaFila(novaSenha, filaAtual.historico)
            saveSenha(novaSenha);

            return novaSenha;
        } catch (e: any) {
            showToast('error', e.message)
        }
    }, [getConfigByCod, retornarFilaAtual, salvarNovaFila, saveSenha, showToast])

    return {
        gerarSenha,
        getSenhaString,
        cancelarSenhaAtual,
        carregando,
        gerarSenhaHabilitado,
    }

}