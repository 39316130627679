import { CardCategoriaProps } from './card-categoria-props';
import { useCallback } from 'react';
import { EditarIcon } from '../../icons/editar-icon';

import { DefaultCard } from '../components/default-card';
import { useDefaultCardStyles } from '../components/default-card/default-card-styles';
import { useThemeQueries } from '../../../theme/util-styles';
import SemImagem from 'assets/img/sem-imagem.jpg';
import { Box, Grid, Tooltip, Typography } from 'views/design-system';

export const CardCategoria = ({
  model,
  onClick,
  onCheck,
  selected,
}: CardCategoriaProps) => {
  const defaultCardClasses = useDefaultCardStyles();
  const { theme, isMobile } = useThemeQueries();

  const retornarCorStatus = useCallback((): string => {
    return model.ativo ? theme.palette.success.main : theme.palette.error.main;
  }, [theme.palette.success.main, theme.palette.error.main, model.ativo]);

  const descricao = model.ativo
    ? 'Categoria Habilitada'
    : 'Categoria Desabilitada';

  const img = (imagemUrl: string) => {
    try {
      if (imagemUrl.length > 0) {
        const url = atob(imagemUrl);
        return <img src={url} className={defaultCardClasses.cardImg} alt={model.nome} />;
      }

      return (
        <img src={SemImagem} className={defaultCardClasses.cardImg} alt={model.nome} />
      );
    } catch (err) {
      return (
        <img src={SemImagem} className={defaultCardClasses.cardImg} alt={model.nome} />
      );
    }
  };

  return (
    <>
      <DefaultCard
        isSelected={selected}
        onClick={async () => {
          onClick(model.id);
        }}
      >
        <Tooltip arrow title={descricao} placement="right">
          <Box
            className={defaultCardClasses.tagStatus}
            style={{ background: retornarCorStatus() }}
          ></Box>
        </Tooltip>
        <Grid container alignItems='center' spacing={1} px={1}>
          <Grid item xs={'auto'}>
            {img(model.foto)}
          </Grid>
          <Grid item xs flex flexDirection={isMobile ? 'column' : 'row'}>
            <Grid item md={6} xs={12}
              className={defaultCardClasses.celulaGrid}
            >
              <Typography color="textPrimary" variant="caption">
                Nome da Categoria
              </Typography>
              <Typography
                className={'celula-grid-value'}
                color="textPrimary"
                variant="body1"
              >
                <Typography weight={700}>
                  {model.nome !== null ? model.nome : 'Sem nome'}
                </Typography>
              </Typography>
            </Grid>
            <Grid item md={6} xs={12} className={defaultCardClasses.celulaGrid}
            >
              <Typography color="textPrimary" variant="caption">
                Descrição
              </Typography>
              <Typography
                className={'celula-grid-value'}
                color="textPrimary"
                variant="body1"
              >
                {model.descricao !== null
                  ? model.descricao.length === 0 ? model.nome
                    : model.descricao : 'Sem nome'}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={'auto'} flex flexDirection='column' justifyContent='center' alignItems='center'>
            <Tooltip arrow title={model.cor ? model.cor : 'Não Definida'}>
              <Box mr={2} flex flexDirection='column'

              >
                <Typography color="textPrimary" variant="caption" align='center'>
                  Cor
                </Typography>
                <p
                  style={{
                    background: model.cor ? model.cor : '#FFF',
                  }}
                  className={defaultCardClasses.cardColor}
                ></p>
              </Box>
            </Tooltip>
          </Grid>
        </Grid>
        <Tooltip arrow title="Editar Categoria">
          <div className={defaultCardClasses.cardRightButton}>
            <EditarIcon tipo="BUTTON" />
          </div>
        </Tooltip>
      </DefaultCard>
    </>
  );
};
