import React, { useCallback, useRef } from 'react';
import { Container } from '@material-ui/core';
import FormEntradaCadastroImportacaoXML from 'views/components/form/entrada/entrada-cadastro-xml/form-entrada-cadastro-xml';
import { InformacaoDetalhadaIcon } from 'views/components/icons/informacao-detalhada-icon';
import { ConfirmarIcon } from 'views/components/icons/confirmar-icon';
import { PrivatePageHeader } from 'views/components/headers';
import { ButtonPrivateHeader } from 'views/components/controles';
import { VoltarIcon } from 'views/components/icons';
import { useHistory } from 'react-router-dom';
import { useDefaultCadastroStyles } from 'views/pages/private/cadastros/components/default-cadastro-styles';
import { useStyles } from '../opcoes-entrada-styles';
import {
  OpcoesEntradaImportacaoXMLTemplateProps,
  tipoEdit
} from './opcoes-entrada-importacao-xml-template-interfaces';
import { useSalvarXML } from '../../../hooks/salvar-xml';
import { useFormContext } from 'react-hook-form';
import { VendaCompletaXMLModel } from 'model/api/gestao/venda/venda-completa-xml-model';
import { isEqual } from 'lodash';
import { Box, Button, Grid } from 'views/design-system';

export default function OpcoesEntradaImportacaoXMLResumeTemplate({
  carregando: carregandoExterno,
  setEditing
}: Readonly<OpcoesEntradaImportacaoXMLTemplateProps>): React.ReactElement {
  const cadClasses = useDefaultCadastroStyles();
  const classes = useStyles();
  const { goBack } = useHistory();
  const { saveChangesXML, carregando: carregandoSalvar } = useSalvarXML();

  const {
    getValues,
  } = useFormContext<VendaCompletaXMLModel>();

  const prevMov = useRef<VendaCompletaXMLModel>({...getValues()});

  const carregando = carregandoExterno || carregandoSalvar;

  const handleShowStepper = async () => {
    if(!isEqual(prevMov.current, getValues())){
      await saveChangesXML(getValues())
    }
    setEditing({
      tela: tipoEdit.Stepper,
    });
  };

  const leftArea = useCallback(
    () => (
      <ButtonPrivateHeader
        icon={<VoltarIcon tipo="PRIVATE_HEADER" />}
        tooltip="Menu"
        onClick={() => goBack()}
      ></ButtonPrivateHeader>
    ),
    [goBack]
  );

  return (
    <>
      <Grid className={cadClasses.header}>
        <PrivatePageHeader
          title="Confirmar Informações"
          leftArea={leftArea()}
        />
      </Grid>
      <Container maxWidth="md" className={classes.container}>
        <FormEntradaCadastroImportacaoXML />

        <Box pt={4} pb={2}>
          <Grid container spacing={2} className={classes.acoes}>
            <Grid item xs={12} md={6}>
              <Button
                disabled={carregando}
                onClick={handleShowStepper}
                variant="outlined"
                color="primary"
                size="large"
                fullWidth
              >
                <InformacaoDetalhadaIcon tipo="BUTTON" viewBox="0 0 25 25" />
                Inf. Detalhadas
              </Button>
            </Grid>

            <Grid item xs={12} md={6}>
              <Button
                disabled={carregando}
                type="submit"
                variant="contained"
                color="primary"
                size="large"
                fullWidth
              >
                <ConfirmarIcon tipo="BUTTON_PRIMARY" />
                Concluir
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
}
