import { useState } from "react";
import { TooltipProps } from "./tooltip-props"
import { useStyles } from "./tooltip-styles";
import ReactDOM from "react-dom";
import React from "react";

export const Tooltip = (props: TooltipProps) => {
    const styles = useStyles();
    const [isVisible, setIsVisible] = useState(false);
    const [position, setPosition] = useState({ top: 0, left: 0 });

    const showTooltip = (e: any) => {
        const { top, left } = e.target.getBoundingClientRect();
        const tooltipHeight = 30;
        setPosition({ top: top - tooltipHeight, left: left - 80});
        setIsVisible(true);
    };

    const hideTooltip = () => {
        setIsVisible(false);
    };

    const modifiedChildren = React.cloneElement(props.children, { onMouseEnter: showTooltip, onMouseLeave: hideTooltip });

    return (
        <>
            {modifiedChildren}
            {isVisible &&
                ReactDOM.createPortal(
                    <div className={styles.tooltip} style={{ top: position.top, left: position.left }}>
                        {props.title}
                    </div>,
                    (window as any).document.getElementById('react-root')
                )}
        </>
    );
}