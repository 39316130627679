import { CardNaoEncontrado } from 'views/components/cards';
import { InformacaoIcon } from 'views/components/icons';
import { PedidoProdutoModel } from 'model/api/gestao/pedido/pedido-produto-model';
import { CardFinalizacaoParcialComanda } from 'views/components/cards/card-finalizacao-parcial-comanda/card-finalizacao-parcial-comanda';
import { EnumFinalizacaoParcial } from 'model/enums/enum-finalizacao-parcial';
import { Grid } from 'views/design-system';

export interface FinalizacaoParcialComandaListDataProps {
  itens: Array<PedidoProdutoModel>;
  carregando: boolean;
  alterarQtd: (produto: PedidoProdutoModel, qtde: number, tipo: EnumFinalizacaoParcial) => void;
}

export const FinalizacaoParcialComandaListData = (
  props: FinalizacaoParcialComandaListDataProps
) => {
  return (
    <>
      {props.itens.length === 0 && !props.carregando && (
        <Grid item xs={12}>
          <CardNaoEncontrado
            mensagem="Nenhum item encontrado."
            icon={<InformacaoIcon tipo="GERAL" />}
          />
        </Grid>
      )}

      {props.itens.length > 0 &&
        props.itens.map((produto) => {
          return (
            <CardFinalizacaoParcialComanda
              model={produto}
              key={produto.id}
              alterarQtd={props.alterarQtd}
            />
          );
        })}
    </>
  );
};
