import { ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { DialogSaurus } from "../dialog-saurus/dialog-saurus"
import { OkIcon } from "views/components/icons";
import { useStyles } from "./dialog-confirmar-conferencia-sessao-styles";
import { useThemeQueries } from "views/theme";
import { useCadastros, useSessaoAtual, useToastSaurus } from "services/app";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { usePostSessaoConferencia } from "data/api/gestao/sessao/post-sessao-conferencia";
import { useCallback } from "react";
import { SessaoGerenciamentoModel } from "model/api/gestao/sessao/sessao-gerenciamento-model";
import { SessaoPostConferenciaModel } from "model/api/gestao/sessao/sessao-post-conferencia-model";
import { toDateStringApi } from "utils/to-date";
import { CircularLoading } from "views/components/utils";
import { Button, Grid, Typography } from "views/design-system";

export interface DialogConfirmarConferenciaSessaoProps {
    aberto: boolean;
    sessao: SessaoGerenciamentoModel;
}

export const DialogConfirmarConferenciaSessao = ({
    aberto,
    sessao,
}: DialogConfirmarConferenciaSessaoProps) => {

    //AUX
    const classes = useStyles();
    const { theme } = useThemeQueries();
    const { showToast } = useToastSaurus();

    //HOOKS
    const { fecharDialogConfirmarConferenciaSessao } = useCadastros();
    const { getEmpresaSelecionada } = useSessaoAtual();

    //CHAMADAS DA API
    const { postSessaoConferencia, carregando } = usePostSessaoConferencia();

    const enviarConferencia = useCallback(async () => {
        try {

            const payload = {
                dataAcao: toDateStringApi(new Date()),
                responsavelId: sessao?.operadorId,
                responsavelNome: sessao?.operadorNome,
            } as SessaoPostConferenciaModel

            const res = await postSessaoConferencia(
                getEmpresaSelecionada()!.Id,
                sessao?.caixaId,
                sessao?.id,
                payload,
            );

            if (res.erro) {
                throw res.erro
            }

            showToast('success', 'Conferência realizada com sucesso.');
            fecharDialogConfirmarConferenciaSessao();
            return
        } catch (err: any) {
            showToast('error', err.message);
        }
    },
        [
            fecharDialogConfirmarConferenciaSessao,
            getEmpresaSelecionada,
            postSessaoConferencia,
            sessao?.caixaId,
            sessao?.id,
            sessao?.operadorId,
            sessao?.operadorNome,
            showToast,
        ],
    );

    return (
        <>
            <DialogSaurus
                aberto={aberto}
                colorTitulo={theme.palette.primary.main}
                tamanho='sm'
                titulo="Conferência de Sessão (Perdas e Sobras)"
                isButtonTitleClose
                handleClickClose={fecharDialogConfirmarConferenciaSessao}
                bottomArea={
                    <>
                        <Grid container className={classes.containerButtonBottom}>
                            <Grid item xs={12} className={classes.itemButtonBottom}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={enviarConferencia}
                                >
                                    <OkIcon tipo="BUTTON_PRIMARY" />
                                    Finalizar
                                </Button>
                            </Grid>
                        </Grid>
                    </>
                }
            >
                {carregando && <CircularLoading tipo="FULLSIZED" />}
                <Grid container spacing={1}>
                    <Grid item xs={12}>

                    </Grid>
                    <Grid item xs={12}>
                        <Typography
                            variant="h6"
                            color="textPrimary"
                            weight={700}
                        >
                            Confirmar Conferência
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography
                            variant="body1"
                            color="textPrimary"
                        >
                            A conferência da sessão realiza a baixa automática dos pagamentos realizados em Dinheiro e também faz o lançamento da quebra de caixa no Financeiro.
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <ListItem>
                            <ListItemIcon>
                                <FiberManualRecordIcon />
                            </ListItemIcon>
                            <ListItemText
                                disableTypography
                                primary={
                                    <Typography
                                    >
                                        Para sobras no caixa, será realizado o lançamento de uma Receita em nome da Empresa.
                                    </Typography>
                                }
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <FiberManualRecordIcon />
                            </ListItemIcon>
                            <ListItemText
                                disableTypography
                                primary={
                                    <Typography
                                    >
                                        Para as sessões com falta, uma receita será lançada para o usuário selecionado acima.
                                    </Typography>
                                }
                            />
                        </ListItem>
                    </Grid>
                </Grid>
            </DialogSaurus>
        </>
    )
}