import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    divider: {
        backgroundColor: 'rgb(189, 189, 189)',
        height: .5
    },
    pGrid: {
        padding: theme.spacing(0, 2)
    },
    opcao: {
        width: "100%",
        height: "0",
        flex: "1"
    }
}))