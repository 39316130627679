import { CardNaoEncontrado } from 'views/components/cards';
import { InformacaoIcon } from 'views/components/icons';
import { useThemeQueries } from 'views';
import { UploadCargaPessoa, UploadCargaProduto } from 'model/api/gestao/upload-carga/upload-carga-model';
import { CardCargaProduto } from 'views/components/cards/card-carga-produto/card-carga-produto';
import { CardCargaProdutoMobile } from 'views/components/cards/card-carga-produto/card-carga-produto-mobile';
import { useEffect, useRef, useState } from 'react';
import { useStyles } from './list-styles'
import { Grid } from 'views/design-system';

export interface UploadCargasProdutosListDataProps {
  list: Array<UploadCargaProduto>;
  carregando: boolean;
  onCardChecked: (model: UploadCargaPessoa | UploadCargaProduto) => any;
}

export const ProdutosListData = (props: UploadCargasProdutosListDataProps) => {
  const { isMobile } = useThemeQueries();
  const onCardChecked = (model: UploadCargaProduto) => {
    props.onCardChecked(model);
  };

  const classes = useStyles();

  const [,setVisibleItems] = useState(15);
  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const div = divRef.current;

    const handleScroll = () => {
      if (div && div.scrollTop + div.clientHeight + 10 >= div.scrollHeight) {
        setVisibleItems((prevVisibleItems) => prevVisibleItems + 10);
      }
    };

    div?.addEventListener('scroll', handleScroll);

    return () => {
      if (div) {
        div.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  return (
    <>
      {props.list.length === 0 && !props.carregando && (
        <CardNaoEncontrado
          mensagem="Nenhuma Carga encontrada."
          icon={<InformacaoIcon tipo="GERAL" />}
        />
      )}
      <Grid item xs={12} spacing={2} ref={divRef} className={classes.definirTamanhoVazio}>
        {props.list.length > 0 &&
          props.list
            // .slice(0, visibleItems)
            .map((produto) => {
              return (
                <>
                  {isMobile ? (
                    <CardCargaProdutoMobile
                      key={produto.id}
                      model={produto}
                      onClick={onCardChecked}
                    />
                  ) : (
                    <CardCargaProduto
                      key={produto.id}
                      model={produto}
                      onClick={onCardChecked}
                    />
                  )}
                </>
              );
            })}
      </Grid>
    </>
  );
};
