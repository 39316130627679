import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({

    tooltip: {
        position: 'fixed',
        // top: '-30px', // Ajuste a posição vertical conforme necessário
        // left: '50%', // Ajuste a posição horizontal conforme necessário
        transform: 'translateX(-50%)',
        opacity: 0.9,
        pointerEvents: 'none',
        transition: 'opacity 0.3s',
        color: '#FEFEFE',
        padding: '4px 8px',
        fontSize: '0.625rem',
        maxWidth: '300px',
        minWidth: '150px',
        overflowWrap: 'break-word', // 'word-wrap' é substituído por 'overflowWrap' no Material-UI
        fontFamily: "'Montserrat', sans-serif",
        fontWeight: 500,
        lineHeight: '1.4em',
        borderRadius: '8px',
        backgroundColor: 'rgba(97, 97, 97, 0.9)',

        
        whiteSpace: 'normal',
        zIndex: 9999, // ajuste conforme necessário para garantir que o tooltip esteja acima de outros elementos
    }
}));
