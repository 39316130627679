import { useThemeQueries } from 'views';
import { DefaultCard, useDefaultCardStyles } from '../components';
import { CardPontosVendaSimplesProps } from './card-pontos-venda-simples-props';
import { memo } from 'react';
import { Typography } from 'views/design-system';

const CardPontosVendaSimples = ({ model, onClick }: CardPontosVendaSimplesProps) => {

  const classes = useDefaultCardStyles();
  const { isMobile } = useThemeQueries();

  return (
    <>
      <DefaultCard
        onClick={async () => {
          onClick(model);
        }}
      >
        <div className={classes.cardContent}>
          <div className={classes.celulaGrid}>
            <Typography color="primary" variant="caption">
              PDV
            </Typography>
            <Typography
              className={'celula-grid-value'}
              color="textPrimary"
              variant="body1"
            >
              {model.numCaixa}
            </Typography>
          </div>
          <div className={classes.celulaGridFull}>
            <Typography color="primary" variant="caption">
              Descrição
            </Typography>
            <Typography
              className={'celula-grid-value'}
              color="textPrimary"
              variant="body1"
            >
              {model.descricao}
            </Typography>
          </div>

          <div
            className={classes.celulaGrid}
            style={{ alignItems: 'flex-end' }}
          >
            <Typography color="primary" variant="caption">
              Serial
            </Typography>
            <Typography
              className={'celula-grid-value'}
              color="textPrimary"
              variant="body1"
              style={{ maxWidth: isMobile ? '200px' : '100%' }}
            >
              {model.chave}
            </Typography>
          </div>
        </div>
      </DefaultCard>

    </>
  );
};

export default memo(CardPontosVendaSimples, ((prevProps, nextProps) => true))

