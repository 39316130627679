import React, { useCallback, useRef, useState } from "react";
import { useStyles } from './relatorio-performance-styles'
import { toDateString } from "utils/to-date";
import { consoleDev } from "utils/console-dev";
import { Tooltip } from "views/design-system";

export interface RelatorioComponents {
    id: string;
    phase: string;
    actualDuration: number;
    baseDuration: number;
    startTime: string;
    commitTime: string;
}

export class RelatorioRender {
    constructor(
        public nome: string = '',
        public mediaTempo: number = 0,
        public tempoMinimo: number = 0,
        public tempoMaximo: number = 0,
        public vezesRender: number = 0,
    ) { }
}

interface RelatorioPerformanceContextValue {
    onRender: (id: string, phase: string, actualDuration: number, baseDuration: number, startTime: number, commitTime: number) => void;
    gerarRelatorio: (filtro?: 'tempo' | 'vezes' | string) => RelatorioRender[];
    rendersPorComponente: (name: string) => RelatorioComponents[];
    limparRenderizacoes: () => void;
    carregando: boolean;
    recording: boolean;
}

const RelatorioPerformanceContext = React.createContext<RelatorioPerformanceContextValue>({
    carregando: false,
    recording: false,
    onRender: (id: string, phase: string, actualDuration: number, baseDuration: number, startTime: number, commitTime: number) => { },
    gerarRelatorio: (filtro?: 'tempo' | 'vezes' | string) => [],
    rendersPorComponente: (name: string) => [],
    limparRenderizacoes: () => { }
})

export interface RelatorioPerformanceProviderProps {
    children: React.ReactNode;
}

export const useRelatorioPerformance = () => {
    return React.useContext(RelatorioPerformanceContext);
};


export const RelatorioPerformanceProvider = ({ children }: RelatorioPerformanceProviderProps) => {

    const classes = useStyles()

    const renderList = useRef<RelatorioComponents[]>([])

    const [carregando, setCarregando] = useState<boolean>(false)

    const [recording, setRecording] = useState<boolean>(false)
    const [startRecordingDate, setStartRecordingDate] = useState<Date | undefined>(undefined);


    const onRender = useCallback((id: string, phase: string, actualDuration: number, baseDuration: number, startTime: number, commitTime: number) => {
        if (startRecordingDate == null) return;
        if (startRecordingDate.getTime() + 10000 > new Date().getTime())
            return;

        consoleDev(`---------------------------------------------
        Componente: ${id};
        Fase: ${(() => {
                switch (phase) {
                    case 'mount':
                        return 'Criação'
                    case 'update':
                        return 'Atualizando'
                    case 'nested-update':
                        return 'Atualização Encadeada'
                }
            })()}
        Duração de Renderização: ${actualDuration} milisegundos
        Duração de Renderização sem Optimização: ${baseDuration} milisegundos
        Início da Renderização: ${startTime}
        Fim da Renderização: ${commitTime}
        ---------------------------------------------
        `)

        renderList.current = [...renderList.current, {
            id,
            phase,
            actualDuration,
            baseDuration,
            startTime: toDateString(new Date(), 'HH:mm:ss:SSSS') || '',
            commitTime: toDateString(new Date(new Date().getTime() + actualDuration), 'HH:mm:ss:SSSS') || ''
        }]

    }, [startRecordingDate])

    const gerarRelatorio = useCallback((filtro?: 'tempo' | 'vezes' | 'log' | string) => {
        setCarregando(true);
        let renders: RelatorioRender[] = []

        renderList.current.forEach(item => {
            if (filtro !== 'log') {
                if (!renders.find(x => x.nome === item.id)) {
                    const thisComponentList = renderList.current.filter(x => x.id === item.id)

                    const qtdRender = thisComponentList.length;
                    const tempoMaximo = thisComponentList.reduce((prev, curr) => prev > curr.actualDuration ? prev : curr.actualDuration, 0)
                    const tempoMinimo = thisComponentList.reduce((prev, curr) => prev < curr.actualDuration ? prev : curr.actualDuration, thisComponentList[0].actualDuration)
                    const tempoMedio = (thisComponentList.reduce((prev, curr) => prev + curr.actualDuration, 0)) / qtdRender

                    renders.push(new RelatorioRender(item.id, tempoMedio, tempoMinimo, tempoMaximo, qtdRender))
                }
            } else {
                renders.push(new RelatorioRender(item.id, item.actualDuration, item.actualDuration, item.actualDuration, 1));
            }
        })
        if (filtro === 'tempo') {
            renders = renders.sort((a, b) => b.mediaTempo - a.mediaTempo)
        } else if (filtro === 'vezes') {
            renders = renders.sort((a, b) => b.vezesRender - a.vezesRender)
        } else if (filtro !== 'log' && filtro !== undefined) {
            renders = renders.filter(x => x.nome.toLowerCase().includes(filtro.toLowerCase()))
        }

        setCarregando(false)
        return renders
    }, [])

    const rendersPorComponente = useCallback((name: string) => {
        return renderList.current.filter(item => item.id === name)
    }, [])

    const limparRenderizacoes = useCallback(() => {
        renderList.current = []
    }, [])


    const playStopRecording = useCallback(() => {
        if (!recording) {
            limparRenderizacoes();
            setStartRecordingDate(new Date())
        } else {
            setStartRecordingDate(undefined)
        }

        setRecording(prev => !prev)
    }, [limparRenderizacoes, recording])

    return (
        <RelatorioPerformanceContext.Provider value={{
            carregando,
            recording,
            gerarRelatorio,
            onRender,
            rendersPorComponente,
            limparRenderizacoes
        }}>
            {children}
            <Tooltip title={recording ? 'Pausar' : 'Iniciar'}>
                <div className={classes.button} onClick={playStopRecording}>
                    {recording ? 'Parar' : 'Iniciar'}
                </div>
            </Tooltip>
        </RelatorioPerformanceContext.Provider>
    )
}