import { PropTypes } from '@material-ui/core';
import Fab from '@material-ui/core/Fab';
import { useButtonFabStyles } from './button-fab-styles';
import { useThemeQueries } from 'views/theme';
import { Tooltip } from 'views/design-system';
interface ButtonFabProps {
  id?: string;
  tooltip: string;
  icon: JSX.Element;
  size?: 'small' | 'medium' | 'large';
  color?: PropTypes.Color;
  removePadding?: boolean;
  onClick: () => any;
  spacingBottom?: number
}

export const ButtonFab = ({
  color = 'primary',
  removePadding,
  ...props
}: ButtonFabProps) => {
  const classes = useButtonFabStyles();
  const { theme } = useThemeQueries()

  return (
    <>
      <Tooltip arrow title={props.tooltip} placement="right">
        <Fab
          id={props.id}
          size={props.size}
          color={color === undefined ? 'primary' : color}
          style={{
            padding: removePadding ? '0' : undefined,
            bottom: props.spacingBottom ? theme.spacing(props.spacingBottom) : undefined
          }}
          className={classes.root}
          onClick={props.onClick}
        >
          {props.icon}
        </Fab>
      </Tooltip>
    </>
  );
};
