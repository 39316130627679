import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
    versionControl: {
        flex: '3',
        paddingTop: 5,
        paddingBottom: 5,
        opacity: 0.6,
        display: 'flex',
        alignItems: 'center',
        cursor: 'default',
        justifyContent: 'center',
        width: '100%',
        "& *": {
            marginLeft: 3,
            color: '#000',
            display: 'flex',
        },
        "& b": {
            fontSize: 10,
            fontWeight: "normal",
        },
        "& i": {
            fontSize: 10,
            fontWeight: "bold",
        }
    },
}));

