import { DefaultCard, useDefaultCardStyles } from "../components"
import { CardGerenciarMesaProps } from "./card-gerenciar-mesa-props"
import { useCallback } from "react";
import { EnumMesas } from "model/enums/enum-mesas";
import { EditarIcon } from "views/components/icons";
import { MesaIcon } from "views/components/icons/mesa-icon";
import { Box, Grid, Tooltip, Typography } from "views/design-system";
import { useTheme } from "@material-ui/core";
import React from "react";
import { SimNaoBooleanMock } from "data/mocks/sim-nao-mock";

const CardGerenciarMesa = ({
    model,
    onCheck,
    onClick,
    selected,
    salao,
}: CardGerenciarMesaProps) => {

    const classes = useDefaultCardStyles();
    const theme = useTheme();

    const retornarCorStatus = useCallback((): string => {
        switch (model.status.codigo) {
            case EnumMesas.ATIVO:
                return theme.palette.success.main;
            case EnumMesas.INATIVO:
                return theme.palette.error.main;
            default:
                return theme.palette.primary.main;
        }
    }, [
        theme.palette.success.main,
        theme.palette.error.main,
        theme.palette.primary.main,
        model.status,
    ]);
    return (
        <>
            <DefaultCard
                isSelected={selected}
                onClick={async () => {
                    onClick(model.id ?? '');
                }}
            >
                <Tooltip arrow title="" placement="right">
                    <div
                        className={classes.tagStatus}
                        style={{ background: retornarCorStatus() }}
                    ></div>
                </Tooltip>
                <Grid container className={classes.cardContent}>
                    <Grid item flex alignItems="center">
                        <Box className={classes.celulaGrid}>
                            <MesaIcon tipo="PRIVATE_HEADER" fill={theme.palette.primary.main} />
                        </Box>
                        <Box className={classes.celulaGridFullDescriptionFull}>
                            <Typography color="textPrimary" variant="caption">
                                Código
                            </Typography>
                            <Typography
                                className={'celula-grid-value'}
                                color="textPrimary"
                                variant="body1"
                            >
                                <strong>{model.codigo}</strong>
                            </Typography>
                        </Box>
                    </Grid>


                    <Grid item className={classes.celulaGrid} >
                        <Typography color="textPrimary" variant="caption">
                            QrCode na mesa
                        </Typography>
                        <Typography
                            className={'celula-grid-value'}
                            color="textPrimary"
                            variant="body1"
                        >
                            {SimNaoBooleanMock.find(
                                (item) => item.Key === model.qrCodeNaMesa
                            )?.Value || ''}
                        </Typography>
                    </Grid>
                    <Grid className={classes.celulaGrid}>
                        <Typography color="textPrimary" variant="caption">
                            Salão
                        </Typography>
                        <Typography
                            className={'celula-grid-value'}
                            color="textPrimary"
                            variant="body1"
                        >
                            {salao?.Value}
                        </Typography>
                    </Grid>
                </Grid>
                <Tooltip arrow title="Editar Mesa">
                    <div className={classes.cardRightButton}>
                        <EditarIcon tipo="BUTTON" />
                    </div>
                </Tooltip>
            </DefaultCard>
        </>
    )
}
export default React.memo(CardGerenciarMesa);