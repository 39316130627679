import classNames from 'classnames';
import { useModalStyles } from 'views/components/modals/utils/modal-styles';

import { DialogSaurus } from 'views/components/dialog/dialog-saurus/dialog-saurus';
import { useHistory } from 'react-router';
import { useCallback, useEffect } from 'react';
import { LimiteAtingidoVendaIcon } from 'views/components/icons/LimiteAtingidoVenda';
import { useCadastros } from 'services/app';
import { useMovProd } from 'services/app/hooks/mov-prod';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { useTheme } from '@material-ui/core';
import { Button, Grid, Typography } from 'views/design-system';

export interface modalPropagandaPlanoProps {
  aberto: boolean;
}

export const ModalPropagandaPlano = ({
  ...props
}: modalPropagandaPlanoProps) => {
  const classes = useModalStyles();
  const theme = useTheme()
  const history = useHistory();
  const { fecharModalPropagandaPlano } = useCadastros()
  const { carregando } = useMovProd()

  const { callEvent } = useEventTools()

  useEffect(() => {
    if (props.aberto) {
      callEvent(AppEventEnum.PermiteEntradaDigitada, false)
    }

    return () => callEvent(AppEventEnum.PermiteEntradaDigitada, true)
  }, [callEvent, props.aberto])

  const onCloseClick = useCallback(() => {
    fecharModalPropagandaPlano();
  }, [fecharModalPropagandaPlano]);

  const handleClick = () => {
    fecharModalPropagandaPlano();
    history.push('/alterar-plano');
  };

  return (
    <>
      {props.aberto && (
        <DialogSaurus
          aberto={props.aberto}
          carregando={carregando}
          tamanho="xs"
          isButtonTitleClose
          handleClickClose={onCloseClick}
        >
          <div className={classes.root}>
            <div className={classes.content}>
              <div
                className={classNames(
                  classes.contentForms,
                  carregando ? classes.contentFormsLoading : undefined,
                )}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '100%',
                }}
              >
                <Typography
                  style={{
                    fontWeight: 700,
                    fontSize: 32,
                    textAlign: 'center',
                    color: theme.palette.primary.main,
                  }}
                >
                  Aumente seu limite de plano
                </Typography>
                <div
                  style={{
                    height: '70%',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    margin: '16px 0'
                  }}
                >
                  <LimiteAtingidoVendaIcon style={{ maxWidth: '224px' }} />
                </div>
                <Typography
                  style={{
                    fontWeight: 600,
                    textAlign: 'center',
                    color: '#8D8D8D',
                    marginTop: '24px',
                  }}
                >
                  Parece que está quase acabando o seu limite de vendas, selecione um novo plano!
                </Typography>
              </div>
            </div>
            <div className={classes.acoes}>
              <Grid container spacing={2}>
                <Grid item xs={8} sm={8}>
                  <Button
                    disabled={carregando}
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={handleClick}
                  >
                    Ver planos
                  </Button>
                </Grid>
                <Grid item xs={4} sm={4}>
                  <Button
                    disabled={carregando}
                    variant="text"
                    color="primary"
                    fullWidth
                    onClick={onCloseClick}
                  >
                    Continuar
                  </Button>
                </Grid>
              </Grid>
            </div>
          </div>
        </DialogSaurus>
      )}
    </>
  );
};
