import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { useStyles } from './dialog-scanner-permissao-styles';
import Scanner from 'views/pages/private/movimentacao/mov-prod-leitor/components/scanner/scanner';
import { Typography } from 'views/design-system';


interface DialogScannerGerarComandaProps {
    openned?: boolean;
    loading?: boolean;
    closeModal: () => void;
    zIndex?: string;
    tamanho?: DialogProps['maxWidth'];
    handleScan: (value: string) => any;
}

export const DialogScannerPermissao = ({
    closeModal,
    ...props
}: DialogScannerGerarComandaProps) => {
    const classes = useStyles();

    return (
        <Dialog
            maxWidth={props.tamanho || 'md'}
            open={props.openned || false}
            fullWidth
            keepMounted
            className={classes.root}
            disableEscapeKeyDown
            ref={(el: any) => {
                if (el && (props.zIndex || '').length > 0) {
                    el.style.setProperty('z-index', props.zIndex, 'important');
                }
            }}
        >
            <div style={{ padding: 8 }}>
                <Typography className={classes.textLabel}>
                    Posicione o QRCode na marcação
                </Typography>
                <div style={{ borderRadius: 8, overflowY: 'hidden' }}>
                    {props.openned && (
                        <Scanner onScan={props.handleScan} inicialOrSecundario={false} openned={props.openned} />
                    )}
                </div>
                <div style={{ justifyContent: 'center', display: 'flex' }}>
                    <CloseIcon
                        onClick={() => {
                            closeModal();
                        }}
                        style={{ fontSize: 50, marginTop: 16, cursor: 'pointer' }}
                    />
                </div>
            </div>
        </Dialog>
    );
};