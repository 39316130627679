import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    btnIcon: {
        '& svg': {
            margin: 0
        }
    },
    erroIcon: {
        height: 80,
        width: 80,
        fill: theme.palette.primary.main
    },
    boxQRCode: {
        width:"100%",
        '& > svg': {
            padding: theme.spacing(2),
            borderRadius: theme.shape.borderRadius,
            borderStyle: 'solid',
            borderSize: 2,
            backgroundColor: '#FFF'
        }
    },
    inputPix: {
        background: theme.palette.type === "dark" ? "#545454" : "#FFFFFF"
    },
    progressBar: {
        '& .MuiLinearProgress-barColorPrimary': {
            backgroundColor:
                theme.palette.type === 'dark'
                    ? theme.palette.text.primary
                    : null
        },
        '&.MuiLinearProgress-colorPrimary': {
            backgroundColor:
                theme.palette.type === 'dark'
                    ? theme.palette.grey['700']
                    : null
        }
    },
    pgtoDetalhes:{
        minHeight:200,
        height:'100%'
    },
    boxTotalaPagar:{
        minWidth:300
    }
}));