import { AppEventEnum } from "model/enums/enum-app-event";
import { useCallback, useRef } from "react";
import { useCadastros } from "services/app";
import { useEventTools } from "services/app/hooks/events/event-tools";
import { useMenuPrincipal } from "services/app/hooks/menu-principal";
import { ButtonPrivateHeader } from "views/components/controles";
import { HeaderProp, IConvertedStateProps, useVendaHeaderStore } from "../stores/venda-header-store";
import { DesfazerIcon, FiltroIcon, VoltarIcon } from "views/components/icons";
import { MenuCirculadoIcon } from "views/components/icons/menu-circulado";
import { MenuVerticalIcon } from "views/components/icons/menu-vertical-icon";
import { PrivatePageHeader } from "../../header-private-page";
import { HeaderTextField } from "views/components/form/header-text-field/header-text-field";
import classNames from "classnames";
import { useStyles } from './venda-header-desktop-styles'
import { PesquisaValor } from "views/pages/private/gerenciar-sessao/components/sessao-valor-header/components/pesquisa-valor/pesquisa-valor";
import { useAutoInput } from "services/app/hooks/auto-input";
import { IdentidadeNovoContratoProps } from "data/api/gestao/identidade";
import { Grid } from "views/design-system";

const retProp = (prop: HeaderProp<any>) => {
    if (typeof prop === 'object' && Object.keys(prop).includes('mobile') && Object.keys(prop).includes('desktop')) {
        return prop.mobile;
    }
    return prop;
}

export const VendaHeaderMobile = () => {
    const props = useVendaHeaderStore((state) => state.props);
    const switchFiltro = useVendaHeaderStore((state) => state.switchFiltro);
    const setFiltroSearch = useVendaHeaderStore((state) => state.setSearch);

    const {
        id,
        title,
        showDefinicoes,
        showMenuPdv,
        pesquisa,
        showFiltro,
        openFiltro,
        voltar,
        customButton,
        hide,
        search
    } = Object.entries(props).reduce<IConvertedStateProps>((prev, curr) => ({ ...prev, [curr[0]]: retProp(curr[1]) }), {});

    const textRef = useRef<HTMLInputElement>(null);
    const { digitando } = useAutoInput({
        value: search || '',
        setValue(val) {
            setFiltroSearch(val)
        },
        ativo: Boolean(pesquisa),
        capturarDigitacao: props.pesquisa?.capturarDigitacao,
        aceitarLetras: Boolean(pesquisa?.allowLetras),
        aceitarNumeros: Boolean(pesquisa?.allowNumbers)
    });
    const { callEvent } = useEventTools();
    const { abrirDialogDefinicoes } = useCadastros();
    const classes = useStyles();
    const { abrirMenu } = useMenuPrincipal();


    const handleBack = async () => {
        setFiltroSearch('');
        if (textRef.current) {
            textRef.current.blur();
        }
        callEvent(AppEventEnum.TelaPesquisa, false);
    };

    const leftArea = () => {
        if (showMenuPdv && !voltar && !digitando) {
            return <ButtonPrivateHeader
                id={retProp(id)}
                icon={<MenuCirculadoIcon tipo="PRIVATE_HEADER" />}
                tooltip="Ir para Menu Administrativo"
                onClick={() => abrirMenu()}
            />
        }
        if (digitando) {
            return <ButtonPrivateHeader
                id={retProp(id)}
                icon={<VoltarIcon tipo="PRIVATE_HEADER" />}
                tooltip="Voltar"
                onClick={() => handleBack()}
            />
        }
        if (voltar) {
            return <ButtonPrivateHeader
                id={retProp(IdentidadeNovoContratoProps)}
                icon={<VoltarIcon tipo="PRIVATE_HEADER" />}
                tooltip="Voltar"
                onClick={async () => {
                    if (voltar.onClick) {
                        voltar.onClick();
                    }
                }}
            />
        }
    };

    const rightArea = useCallback(
        () => {
            const elements: JSX.Element[] = []
            if (customButton) {
                customButton.forEach(btn => {
                    elements.push(
                        <ButtonPrivateHeader
                            icon={btn.icon}
                            tooltip={btn.tooltip || ''}
                            onClick={() => {
                                btn.onClick()
                            }
                            }
                        />
                    )
                })
            }
            if (showFiltro) {
                elements.push(
                    <ButtonPrivateHeader
                        icon={openFiltro ? <DesfazerIcon tipo='PRIVATE_HEADER' /> : <FiltroIcon tipo="PRIVATE_HEADER" />}
                        tooltip={'Abrir Filtros'}
                        onClick={() =>
                            switchFiltro()
                        }
                    />
                )
            }
            if (showDefinicoes) {
                elements.push(
                    <ButtonPrivateHeader
                        icon={<MenuVerticalIcon tipo="PRIVATE_HEADER" />}
                        tooltip={'Opções de pesquisa'}
                        onClick={() =>
                            abrirDialogDefinicoes()
                        }
                    />
                )
            }
            return <Grid flex gap={16}>
                {elements}
            </Grid>
        },
        [abrirDialogDefinicoes, customButton, openFiltro, showDefinicoes, showFiltro, switchFiltro]
    );

    const pesquisaValor = (
        <PesquisaValor
            isOpened={Boolean(openFiltro)}
            onCloseSearch={() => switchFiltro()}
            onPesquisar={(props) => {
                setFiltroSearch(props.termo);
            }}
        />
    );

    const bottomArea = () => {
        if (showFiltro && openFiltro) {
            return pesquisaValor;
        }
        return null
    }

    return (
        <>
            <PrivatePageHeader
                title={title || 'Venda'}
                leftArea={leftArea()}
                rightArea={rightArea()}
                id='vendaHeader'
                middleArea={
                    pesquisa &&
                    <HeaderTextField
                        inputRef={textRef}
                        placeholder="Digite para buscar..."
                        onFocus={() => {
                            callEvent(AppEventEnum.TelaPesquisa, true);
                        }}
                        onChange={(e: any) => {
                            setFiltroSearch(e.target.value)
                        }}
                        value={search}
                    />
                }
                bottomArea={bottomArea()}
                className={classNames(
                    classes.unsetHeight,
                    hide ? classes.displayNone : ''
                )}
            />
        </>
    );
}