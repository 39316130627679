import { CredenciamentoSafra } from "model/api/gestao/finalizadora/finalizadora-model";
import { MovSimplesPagamentoModel } from "model/api/gestao/movimentacao/simples/mov-simples-pagamento-model";
import { AppEventEnum } from "model/enums/enum-app-event";
import { useCallback, useEffect, useState } from "react";
import { useEventTools } from "services/app/hooks/events/event-tools";
import { consoleDev } from "utils/console-dev";
import { DialogCheckoutCartao } from "views/components/dialog/dialog-checkout-cartao/dialog-checkout-cartao";
import { DialogCheckoutCartaoProps } from "views/components/dialog/dialog-checkout-cartao/dialog-checkout-cartao-props";


export const DialogCheckoutCartaoRender = () => {
    const { addHandler, removeHandler } = useEventTools();
    const [modalState, setModalState] = useState<DialogCheckoutCartaoProps>({
        resolve: () => { },
        reject: () => { },
        credenciamento: new CredenciamentoSafra(),
        openned: false,
        pagamento: new MovSimplesPagamentoModel()
    });

    consoleDev('DialogCheckoutCartaoRender')

    const modalAlterado = useCallback(({ ...props }: DialogCheckoutCartaoProps) => {
        setModalState({
            ...props,
        });
    }, [])

    useEffect(() => {
        addHandler(AppEventEnum.DialogCheckoutCartao, modalAlterado);
        return () => {
            removeHandler(AppEventEnum.DialogCheckoutCartao, modalAlterado);
        };
    }, [addHandler, modalAlterado, removeHandler]);

    return (
        <>
            {modalState.openned && (
                <DialogCheckoutCartao {...modalState} />
            )}
        </>
    )
}