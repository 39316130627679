import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useKeyboard } from "services/app/hooks/keyboard";
import { TelefoneTecladoProps } from "./telefone-teclado-props";
import { Button, Grid, Tooltip, Typography } from "views/design-system";
import { useStyles } from "./telefone-teclado-styles";
import { Keyboard } from "views/components/keyboard/keyboard";
import { useEventTools } from "services/app/hooks/events/event-tools";
import { AppEventEnum } from "model/enums/enum-app-event";
import { maskPhone } from "views/components/controles/inputs/text-field-saurus/text-field-saurus-masks";
import { stringNumeros } from 'utils/string-numeros';
import classNames from 'classnames';
import { validarNumeroTelefone } from 'utils/telefone-validate';
import { useMovAtual } from "services/app/hooks/mov-atual";
import { EnumTipoPessoaContato } from "model/enums/enum-tipo-pessoa-contato";
import { getClipboardValue } from "utils/getClipboardValue";
import { isEmpty } from "lodash";
import { SugestaoSources, useClienteIdentificacaoUtils } from "../use-cliente-identificacao-utils";
import { useCadastroPadrao } from "services/app/hooks/cadastro-padrao";
import { ColarIcon } from "views/components/icons";

export const TelefoneTeclado = ({
    textChanged,
    ...props
}: TelefoneTecladoProps) => {
    const classes = useStyles()
    const { addHandler, removeHandler } = useEventTools()
    const { showSuggestionToast } = useClienteIdentificacaoUtils()

    const [valueClipboard, setValueClipboard] = useState<string | undefined>()
    const valueRef = useRef<HTMLParagraphElement | null>(null);
    const valueInfo = useRef<string>('');
    const digitado = useRef<boolean>(false)
    const textoAtual = useRef<string>('')

    const {
        getFormattedText,
        addValue,
        sendText,
        addKey,
        submitKey,
        backSpaceKey,
        resetText
    } = useKeyboard({
        maxLength: 11,
        floatCases: 0,
        isNumeric: false,
        handleTextChanged: textChanged,
        digitado: digitado,
        textoAtual: textoAtual
    });

    const attValueDisplay = useCallback((any: any) => {
        const v = getFormattedText();
        if (valueRef.current) {
            if (v.length === 0) {
                valueRef.current.className = classes.placeholder
                valueRef.current.textContent = "Aguardando Digitação"
                valueInfo.current = ''
                return
            }
            valueRef.current.className = classes.inputText
            valueRef.current.textContent = maskPhone(v)
            valueInfo.current = v;
        };
    }, [classes.inputText, classes.placeholder, getFormattedText])

    useEffect(() => {
        addHandler(AppEventEnum.AlterarVersao, attValueDisplay)

        return () => removeHandler(AppEventEnum.AlterarVersao, attValueDisplay)
    }, [addHandler, attValueDisplay, removeHandler])

    const handleAddValue = useCallback(
        (value: number) => {
            addValue(value);
        },
        [addValue],
    );

    const handleResetValue = useCallback(async (value: string) => {
        await resetText(value)
    }, [resetText])

    const handleBackSpace = useCallback(() => {
        backSpaceKey();
    }, [backSpaceKey]);

    const handleText = useCallback(
        (value: string) => {
            sendText(value);
        },
        [sendText],
    );

    const handleAdd = useCallback(() => {
        addKey();
    }, [addKey]);

    const handleSubmit = useCallback(() => {
        submitKey();
    }, [submitKey]);


    const { getMov, currentClienteIsPadrao } = useMovAtual()
    const { getConsumidor } = useCadastroPadrao();

    const fillTelefone = useCallback(async () => {
        let phoneToSet = ''
        let fonteSugestao: SugestaoSources = 'Vazio'
        const mov = getMov()
        if (mov) {
            const cliente = mov.cliente
            const clientePadrao = await getConsumidor();
            if (cliente) {
                const phone = cliente.contatos.find((item) => {
                    return item.tipo === EnumTipoPessoaContato.TELEFONE
                })?.valor ?? ''
                const phonePadrao = clientePadrao?.contatos.find((item) => {
                    return item.tipo === EnumTipoPessoaContato.TELEFONE
                })?.valor ?? ''
                if (!(await currentClienteIsPadrao() && phonePadrao === phone)) {
                    phoneToSet = phone;
                }
            } else {
                const receitas = mov.receitasMedicas
                if (receitas.length > 0) {
                    const receita = receitas[0]
                    phoneToSet = receita.comprador.telefone
                    fonteSugestao = 'Receita'
                }
            }

            const pasteValue = await getClipboardValue() ?? ''
            if (validarNumeroTelefone(pasteValue)) {
                setValueClipboard(pasteValue)
            }

            if (!isEmpty(phoneToSet)) {
                if (valueRef.current) {
                    if (validarNumeroTelefone(phoneToSet)) {
                        valueRef.current!.className = classNames(classes.inputText);
                        valueRef.current.textContent = maskPhone(phoneToSet)
                        handleText(stringNumeros(phoneToSet))
                        showSuggestionToast({
                            key: 'Telefone',
                            source: fonteSugestao
                        })

                    }
                }
            }
        }

    }, [classes.inputText, currentClienteIsPadrao, getConsumidor, getMov, handleText, showSuggestionToast])

    const preencherComClipboard = useCallback(async () => {
        if (valueRef.current && valueClipboard) {
            valueRef.current!.className = classNames(classes.inputText);
            valueRef.current.textContent = maskPhone(valueClipboard);
            handleResetValue(stringNumeros(valueClipboard))
        }
    }, [classes.inputText, handleResetValue, valueClipboard])

    useEffect(() => {
        fillTelefone();
    }, [fillTelefone]);

    const teclado = useMemo(() => {
        return (
            <>
                <Grid container style={{ flex: 1 }}>
                    <Keyboard
                        handleAdd={handleAdd}
                        handleBackSpace={handleBackSpace}
                        handleAddValue={handleAddValue}
                        handleSubmit={handleSubmit}
                        handleText={handleText}
                        isNumeric
                        isButtonKeyboard00
                        isButtonBackspace
                    />
                </Grid>
            </>
        );
    }, [handleAdd, handleAddValue, handleBackSpace, handleSubmit, handleText]);

    return (
        <Grid className={classes.container}>
            <Grid className={classNames(classes.content, props.pequeno ? classes.contentPequeno : undefined)}>
                {valueClipboard && (
                    <Grid className={classes.colarIcon} />
                )}
                <Grid>
                    <Typography className={classes.title}>{'Informe o Telefone'}</Typography>
                    <Grid className={classes.inputArea}>
                        <Typography className={classes.placeholder} ref={valueRef}>
                            Aguardando Digitação
                        </Typography>
                    </Grid>
                </Grid>
                {valueClipboard && (
                    <Tooltip title="Clique para colar">
                        <div>
                            <Button
                                onClick={preencherComClipboard}
                                variant="outlined"
                                color="primary"
                                className={classes.colarIcon}
                            >
                                <ColarIcon tipo="GERAL" />
                            </Button>
                        </div>
                    </Tooltip>
                )}
            </Grid>
            {teclado}
        </Grid>
    )
}