import { Box, Button, Grid, Typography } from "views/design-system"
import { DialogSaurus } from "../dialog-saurus/dialog-saurus"
import { OkIcon } from "views/components/icons"
import { useMovRota } from "services/app/hooks/mov-rota"
import { useCadastros, useToastSaurus } from "services/app"
import { useMovAtual } from "services/app/hooks/mov-atual"
import { useMemo } from "react"
import { useStyles } from "./dialog-pagamento-realizado-styles"
import { toDecimalString } from "utils/to-decimal"

export interface DialogPagamentoRealizadoProps {
    aberto: boolean
}

export const DialogPagamentoRealizado = ({ aberto }: DialogPagamentoRealizadoProps) => {

    const { avancarFluxoMov } = useMovRota()
    const { showToast } = useToastSaurus()
    const { getMov } = useMovAtual()
    const { fecharPagamentoRealizado } = useCadastros()

    const classes = useStyles()

    const handleContinue = async () => {
        try {
            await avancarFluxoMov()
            fecharPagamentoRealizado()
        } catch (err: any) {
            showToast('error', err.message)
        }
    }

    const pagamentos = useMemo(() => getMov()?.pags ?? [], [getMov])
    const primeiroPag = pagamentos[0]

    return (
        <DialogSaurus
            aberto={aberto}
            titulo="Pagamento Realizado"
            tamanho="xs"
            bottomArea={
                <Box className={classes.container} p={2}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Button variant="contained" fullWidth color="primary" onClick={handleContinue}>
                                <OkIcon tipo="BUTTON_PRIMARY" />
                                Continuar
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            }
        >
            <Grid px={1}>
                <Grid spacing={2} container>
                    <Grid item xs={12}>
                        <Grid>
                            <Typography variant="caption" color="primary">
                                Descrição
                            </Typography>
                            <Typography>
                                {primeiroPag.pagamentoNome} {primeiroPag.nParcelas > 1 && ` - ${primeiroPag.nParcelas}x`}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className={classes.valueContent} container>
                        <Grid item xs={12} justifyContent="space-between" flex alignItems="center">
                            <Typography>
                                Valor Total
                            </Typography>
                            <Typography variant="h5" weight={700} color="primary">
                                R$ {toDecimalString(primeiroPag.vPag)}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} style={{ display: primeiroPag.vTroco > 0 ? 'flex' : 'none' }} alignItems="center" justifyContent="space-between">
                            <Typography>
                                Troco
                            </Typography>
                            <Typography variant="body1" weight={700}>
                                R$ {toDecimalString(primeiroPag.vTroco)}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </DialogSaurus>
    )
}