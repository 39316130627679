import { KeyValueModel } from "model";
import { EnumBanco } from "model/api/gestao/conta-bancaria/enum-banco";


export const BancoMock: KeyValueModel[] = [
    new KeyValueModel(EnumBanco.Safra, 'Safra'),
    new KeyValueModel(EnumBanco.Itau, 'Itaú'),
    new KeyValueModel(EnumBanco.BancoBrasil, 'Banco do Brasil'),
    new KeyValueModel(EnumBanco.Santander, 'Santander'),
    new KeyValueModel(EnumBanco.Caixa, 'Caixa Econômica'),
    new KeyValueModel(EnumBanco.Bradesco, 'Bradesco')
];