import { EnumTpEmis } from 'model/enums/enum-tp-emis';
import { guidEmpty } from 'utils/guid-empty';
import { PessoaModel } from '../pessoa';
import { VendaPagsModel } from './venda-pags-model';
import { VendaProdutoModel } from './venda-produto-model';
import { CobrModel, ReceitaMedicaModel } from './venda-completa-model';

export class VendaModel {
    constructor(
        public id: string = guidEmpty(),
        public empresaId: string = guidEmpty(),
        public operadorId: string = guidEmpty(),
        public sessaoId: any = null,
        public status: number = 10,
        public tpAmb: number = 1,
        public dEmi: string | Date = '',
        public serie: number = 0,
        public nnf: number = 0,
        public mod: number = 10,
        public pessoaId: string = guidEmpty(),
        public documento: string = '',
        public regimeTributarioId: string = guidEmpty(),
        public produtos: Array<VendaProdutoModel> = [],
        public pags: VendaPagsModel[] = [],
        public cliente: PessoaModel | null = null,
        public vNF: number = 0,
        public tpEmis: EnumTpEmis = EnumTpEmis.OFFLINE,
        public mesaCodigo: string | null = null,
        public codigoComanda: string | null = null,
        public vDesc: number = 0,
        public vAcresc: number = 0,
        public telefone: string = '',
        public receitasMedicas: ReceitaMedicaModel[] = [],
        public documentoNaNota: boolean = false,
        public cobr: CobrModel | null = null,
        public infCpl: string = '',
    ) { }
}