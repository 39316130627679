import { DialogSaurus } from '../dialog-saurus/dialog-saurus';
import { useStyles } from './dialog-opcoes-empresa-styles';
import { VoltarIcon } from 'views/components/icons';
import { useToastSaurus } from 'services/app';
import { useSessaoAtual } from 'services/app';
import { useConfirm } from 'material-ui-confirm';
import { useState } from 'react';
import { CircularLoading } from 'views';
import { useHistory, useLocation } from 'react-router-dom';
import { useMovAtual } from 'services/app/hooks/mov-atual';
import { Box, Button, Grid } from 'views/design-system';

interface DialogOpcoesEmpresaProps {
  openned: boolean;
  closeModal: () => void;
  id: string;
}

export const DialogOpcoesEmpresa = ({
  openned,
  closeModal,
  id,
}: DialogOpcoesEmpresaProps) => {
  const { showToast } = useToastSaurus();
  const [carregando, setCarregando] = useState<boolean>(false);
  const { selecionarEmpresa } = useSessaoAtual();
  const { getMov, cancelarMov } = useMovAtual();
  const confirm = useConfirm();
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  const handleConfiguracoes = () => {
    history.push('/configuracoes-empresa/' + id);
  };

  const handleEmpresa = async () => {
    setCarregando(true);
    try {
      if (getMov()) await cancelarMov('');
      await selecionarEmpresa(id);
      showToast('success', 'Empresa Selecionada');
      setCarregando(false);
      if (location.pathname === '/lista-empresas')
        history.push('/configuracoes');
      closeModal();
    } catch {
      showToast('error', 'Erro ao selecionar a empresa');
      setCarregando(false);
    }
  };

  return (
    <>
      <DialogSaurus aberto={openned || false} titulo="Opções" tamanho="xs">
        {carregando && <CircularLoading tipo="FULLSIZED" />}
        <Box className={classes.cardContainer}>
          <Box className={classes.cardContent}>
            <Box className={classes.boxCustom} >
              <Grid container spacing={2} style={{ marginBottom: 8 }}>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    fullWidth
                    color="primary"
                    onClick={() =>
                      confirm({
                        title: 'Selecionar Empresa',
                        description:
                          'Se tiver uma venda em andamento, ela irá ser cancelada na hora.',
                        cancellationText: 'Voltar',
                        cancellationButtonProps: {
                          color: 'default',
                        },
                        confirmationText: 'Selecionar',
                      }).then(() => {
                        return handleEmpresa();
                      })
                    }
                  >
                    Usar esta Empresa
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    fullWidth
                    color="primary"
                    onClick={handleConfiguracoes}
                  >
                    Abrir Configurações
                  </Button>
                </Grid>
                <Grid item xs={4}>
                  {closeModal && (
                    <Button
                      variant="outlined"
                      fullWidth
                      color="primary"
                      onClick={() => {
                        closeModal();
                      }}
                    >
                      <VoltarIcon tipo="BUTTON" />
                      Voltar
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </DialogSaurus>
    </>
  );
};
