import { ModalHeader } from "views/components/modals/components";
import { PromocaoRegraEdicaoItemProps } from "./promocao-regra-edicao-item-props";
import { ButtonModalHeader } from "views/components/controles/buttons/button-modal-header";
import { AvancarIcon, EditarIcon, OkIcon, VoltarIcon } from "views/components/icons";
import { Box, Button, Grid, Typography } from "views/design-system";
import classNames from "classnames";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useModalStyles } from "views/components/modals/utils/modal-styles";
import { MobileStepper } from "@material-ui/core";
import { useStyles } from "./promocao-regra-edicao-item-styles";
import { useKeyboard } from "services/app/hooks/keyboard";
import { Keyboard } from "views/components/keyboard/keyboard";
import { useEventTools } from "services/app/hooks/events/event-tools";
import { AppEventEnum } from "model/enums/enum-app-event";
import { EnumPromocaoTipoRegra } from "model/enums/enum-promocao-tipo-regra";
import { toDecimalString } from "utils/to-decimal";
import useEventListener from "@use-it/event-listener";
import { useStepperStyles } from "views/components/stepper/stepper-styles";
import { useDefaultCardStyles } from "views/components/cards/components";
enum EnumCampoTelaEdicao {
    PercDesconto,
    ValorDesconto,
    Valor,
    Qtde,
}
export const PromocaoRegraEdicaoItem = (props: PromocaoRegraEdicaoItemProps) => {
    const modalClasses = useModalStyles();
    const classes = useStyles();
    const stepperClasses = useStepperStyles();
    const cardStyles = useDefaultCardStyles();

    const texto = useRef('');
    const digitado = useRef<boolean>(false);
    const [itemId, setItemId] = useState<string>('');

    const refValor = useRef<HTMLParagraphElement | null>(null);
    const refPercDesc = useRef<HTMLParagraphElement | null>(null);
    const refValorDesc = useRef<HTMLParagraphElement | null>(null);
    const refNovoLucro = useRef<HTMLParagraphElement | null>(null);
    const refQtde = useRef<HTMLParagraphElement | null>(null);

    const [formState, setFormState] = useState({ valor: true, qtde: true });

    const { addHandler, removeHandler } = useEventTools()
    const [campoSelecionado, setCampoSelecionado] = useState<EnumCampoTelaEdicao | undefined>(undefined);
    const {
        getFormattedText,
        sendText,
        resetText,
        backSpaceKey
    } = useKeyboard({
        maxLength: 7,
        floatCases: campoSelecionado === EnumCampoTelaEdicao.Qtde ? 0 : 2,
        isNumeric: true,
        digitado: digitado,
        textoAtual: texto
    });

    const retornaNovoObjeto = useCallback(() => {
        const detalhe = props.detalhe;
        const item = props.item;
        switch (props.regra) {
            case EnumPromocaoTipoRegra.AtacadoPerc:
                detalhe.valor = parseFloat(refPercDesc.current?.textContent?.replace(',', '.') || '0');
                detalhe.quantidade = parseFloat(refQtde.current?.textContent?.replace(',', '.') || '0');
                break;
            case EnumPromocaoTipoRegra.AtacadoValor:
                detalhe.valor = parseFloat(refValor.current?.textContent?.replace(',', '.') || '0');
                detalhe.quantidade = parseFloat(refQtde.current?.textContent?.replace(',', '.') || '0');
                break;
            case EnumPromocaoTipoRegra.DePor:
                detalhe.valor = parseFloat(refValor.current?.textContent?.replace(',', '.') || '0');
                detalhe.quantidade = 0;
        }
        return { detalhe, item };
    }, [props.detalhe, props.item, props.regra]);

    const voltarItem = useCallback(() => {
        const resp = retornaNovoObjeto();
        setCampoSelecionado(undefined);
        props.voltarItem(resp.detalhe, resp.item);
    }, [props, retornaNovoObjeto]);

    const proximoItem = useCallback(() => {
        let descPerc = parseFloat(refPercDesc.current?.textContent?.replace(',', '.') || '0');

        const resp = retornaNovoObjeto();
        setCampoSelecionado(undefined);
        props.proximoItem(resp.detalhe, resp.item, descPerc);
    }, [props, retornaNovoObjeto]);

    const calcularValores = useCallback((campoAtual: EnumCampoTelaEdicao) => {

        let qtd = parseFloat(refQtde.current?.textContent?.replace(',', '.') || '0');
        let valor = parseFloat(refValor.current?.textContent?.replace(',', '.') || '0');
        let descPerc = parseFloat(refPercDesc.current?.textContent?.replace(',', '.') || '0');
        let descValor = parseFloat(refValorDesc.current?.textContent?.replace(',', '.') || '0');
        let valorOriginal = props.variacaoProduto.precos.length > 0 ? props.variacaoProduto.precos[0].vPreco : 0;

        if (campoAtual === EnumCampoTelaEdicao.PercDesconto) {
            valor = (valorOriginal) * (1 - descPerc / 100);
            descValor = valorOriginal - valor;

            if (refValor.current)
                refValor.current.textContent = toDecimalString(valor, 2);
            if (refValorDesc.current)
                refValorDesc.current.textContent = toDecimalString(descValor, 2);
        } else if (campoAtual === EnumCampoTelaEdicao.ValorDesconto) {
            valor = valorOriginal - descValor;
            descPerc = (valorOriginal) === 0 ? 0 : ((valorOriginal) - valor) / (valorOriginal) * 100;

            if (refValor.current)
                refValor.current.textContent = toDecimalString(valor, 2);
            if (refPercDesc.current)
                refPercDesc.current.textContent = toDecimalString(descPerc, 2);
        }
        else if (campoAtual === EnumCampoTelaEdicao.Valor) {
            descPerc = (valorOriginal) === 0 ? 0 : ((valorOriginal) - valor) / (valorOriginal) * 100;
            descValor = valorOriginal - valor;

            if (refPercDesc.current)
                refPercDesc.current.textContent = toDecimalString(descPerc, 2);
            if (refValorDesc.current)
                refValorDesc.current.textContent = toDecimalString(descValor, 2);
        }

        if (campoAtual !== EnumCampoTelaEdicao.Qtde) {
            const novoLucro = (valor - props.variacaoProduto.vCompra) / props.variacaoProduto.vCompra * 100;
            if (refNovoLucro.current) refNovoLucro.current.textContent = Math.abs(novoLucro) === Infinity ? '∞' : toDecimalString(isNaN(novoLucro) ? 0 : novoLucro, 2) + '%';
        }

        setFormState((prev) => {
            const percInvalido = descPerc <= 0 || descPerc >= 100;
            const qtdInvalida = qtd <= 0 || qtd > 9999;
            if (!percInvalido !== prev.valor || !qtdInvalida !== prev.qtde) {
                return { valor: !percInvalido, qtde: !qtdInvalida };
            }
            return prev;
        });


    }, [props.variacaoProduto.precos, props.variacaoProduto.vCompra]);

    const attValueDisplay = useCallback(
        (any: any) => {
            const v = getFormattedText();
            switch (campoSelecionado) {
                case EnumCampoTelaEdicao.ValorDesconto:
                    if (refValorDesc.current) refValorDesc.current.textContent = v;
                    break;
                case EnumCampoTelaEdicao.PercDesconto:
                    if (refPercDesc.current) refPercDesc.current.textContent = v;
                    break;
                case EnumCampoTelaEdicao.Valor:
                    if (refValor.current) refValor.current.textContent = v;
                    break;
                case EnumCampoTelaEdicao.Qtde:
                    if (refQtde.current) refQtde.current.textContent = v;
                    break;
            }
            calcularValores(campoSelecionado ?? EnumCampoTelaEdicao.Qtde);
        },
        [calcularValores, campoSelecionado, getFormattedText]
    );

    useEffect(() => {
        //QUANDO TROCA O CAMPO ELE SETA COMO DIGITADO FALSE PARA RESETAR O TECLADO
        if (campoSelecionado !== undefined) {
            digitado.current = false;
        }
    }, [campoSelecionado]);

    //PREENCHIMENTO DA TELA QUANDO ENTRA
    useEffect(() => {
        let campoSel = campoSelecionado;

        //IDENTIFICA SE TROCOU O DETALHE/ITEM
        if (props.item.id !== itemId) {
            setItemId(props.item.id);
            campoSel = undefined;
        }

        if (campoSel === undefined) {

            campoSel = EnumCampoTelaEdicao.PercDesconto;
            switch (props.regra) {
                case EnumPromocaoTipoRegra.AtacadoPerc:
                    campoSel = EnumCampoTelaEdicao.PercDesconto;
                    break;
                case EnumPromocaoTipoRegra.DePor:
                    campoSel = EnumCampoTelaEdicao.Valor;
                    break;
                case EnumPromocaoTipoRegra.AtacadoValor:
                    campoSel = EnumCampoTelaEdicao.Valor;
                    break;
            }

            setCampoSelecionado(campoSel);

            if (refQtde.current !== null)
                refQtde.current.textContent = toDecimalString(props.detalhe.quantidade, 0);

            if (refValor.current !== null) {
                if (props.regra === EnumPromocaoTipoRegra.DePor || props.regra === EnumPromocaoTipoRegra.AtacadoValor)
                    refValor.current.textContent = toDecimalString(props.detalhe.valor, 2);
            }

            if (refPercDesc.current !== null) {
                if (props.regra === EnumPromocaoTipoRegra.AtacadoPerc)
                    refPercDesc.current.textContent = toDecimalString(props.detalhe.valor, 2);
            }

            calcularValores(campoSel);
        }

    }, [calcularValores, campoSelecionado, itemId, props.detalhe.id, props.detalhe.quantidade, props.detalhe.valor, props.item.id, props.regra]);

    //RECEBER ALTERAÇÕES DO TECLADO
    useEffect(() => {
        addHandler(AppEventEnum.AlterarVersao, attValueDisplay)

        return () => removeHandler(AppEventEnum.AlterarVersao, attValueDisplay)
    }, [addHandler, attValueDisplay, removeHandler])

    //MANIPULAR O TAB E O ENTER
    useEventListener('keydown', (event) => {
        if (!event.defaultPrevented) {
            const ev = event as KeyboardEvent;
            if (ev.key === "Tab" || ev.key === "Enter") {
                if (campoSelecionado !== undefined) {

                    let campoSel = campoSelecionado + 1;
                    if (campoSel > EnumCampoTelaEdicao.Qtde) {
                        campoSel = EnumCampoTelaEdicao.PercDesconto;
                    }
                    if (campoSel === EnumCampoTelaEdicao.Qtde &&
                        (props.regra !== EnumPromocaoTipoRegra.AtacadoPerc && props.regra !== EnumPromocaoTipoRegra.AtacadoValor)
                    ) {
                        campoSel = EnumCampoTelaEdicao.PercDesconto;
                    }

                    //SE ESTOU NO ULTIMO CAMPO, VOU PARA O PROXIMO ITEM + ENTER
                    if (campoSel === EnumCampoTelaEdicao.PercDesconto &&
                        validForm && ev.key === "Enter") {
                        proximoItem();
                        return;
                    }

                    //SENAO
                    setCampoSelecionado(campoSel);
                }
            }
        }
    });

    const validForm = useMemo(() => {
        return (formState.valor && (
            (props.regra === EnumPromocaoTipoRegra.AtacadoPerc || props.regra === EnumPromocaoTipoRegra.AtacadoValor) ? formState.qtde : true))
    }, [formState.qtde, formState.valor, props.regra]);

    //region Render
    const renderButtons = useMemo(() => {
        return <Box className={classNames(modalClasses.acoes, classes.acoes)}>
            <div className={(stepperClasses.stepper, classes.stepper)}>
                <MobileStepper
                    classes={{
                        progress: classes.mobileBarProgress,
                        root: classes.customStepContainer
                    }}
                    variant='progress'
                    steps={props.total + 2}
                    position='static'
                    activeStep={props.index + 1}
                    nextButton={() => { <></> }}
                    backButton={() => { <></> }}
                />
            </div>
            <Grid container spacing={2}>
                {props.index > 0 && (
                    <Grid item xs={6}>
                        <Button
                            disabled={Boolean(props.carregando) || !(formState.valor && (
                                (props.regra === EnumPromocaoTipoRegra.AtacadoPerc || props.regra === EnumPromocaoTipoRegra.AtacadoValor) ? formState.qtde : true
                            ))}
                            variant="outlined"
                            color="primary"
                            size="large"
                            fullWidth
                            onClick={voltarItem}
                        >
                            <VoltarIcon tipo="BUTTON" />
                            Anterior
                        </Button>
                    </Grid>
                )}
                <Grid item xs={props.index === 0 ? 12 : 6}>
                    <Button
                        disabled={Boolean(props.carregando) || !validForm}
                        variant="contained"
                        color="primary"
                        size="large"
                        fullWidth
                        onClick={proximoItem}
                    >
                        {props.index + 1 === props.total ? (
                            <>
                                <OkIcon tipo="BUTTON_PRIMARY" />
                                Finalizar
                            </>
                        ) : (
                            <>
                                <AvancarIcon tipo="BUTTON_PRIMARY" />
                                Próximo
                            </>
                        )}
                    </Button>
                </Grid>
            </Grid>
        </Box >
    }, [classes.acoes, classes.customStepContainer, classes.mobileBarProgress, classes.stepper, formState.qtde, formState.valor, modalClasses.acoes, props.carregando, props.index, props.regra, props.total, proximoItem, stepperClasses.stepper, validForm, voltarItem])

    const renderHeader = useMemo(() => {
        return <ModalHeader
            title={props.item.produto + (props.item.variacao ? ` ${props.item.variacao}` : '')}
            leftArea={
                <ButtonModalHeader
                    tooltip="Voltar"
                    icon={<VoltarIcon tipo="MODAL_HEADER" />}
                    onClick={props.voltar}
                />
            }
            rightArea={<ButtonModalHeader
                className={classes.botaoTipoRegraHeader}
                tooltip="Tipo da Regra"
                icon={<>
                    <Typography variant="caption" className={classes.tipoRegraHeader}>{props.nomeRegra}</Typography>
                    <Typography variant="caption" className={classes.dadosIndex}>{props.index + 1} de {props.total}</Typography>
                </>}
                onClick={() => { }}
            />}
        />
    }, [classes.botaoTipoRegraHeader, classes.dadosIndex, classes.tipoRegraHeader, props.index, props.item.produto, props.item.variacao, props.nomeRegra, props.total, props.voltar]);

    const renderValorProduto = useMemo(() => {

        const valorProduto = props.variacaoProduto.precos.length > 0 ? props.variacaoProduto.precos[0].vPreco : 0;
        const custoProduto = props.variacaoProduto.vCompra;
        const lucroPercentualProduto = custoProduto === 0 ? Infinity : ((valorProduto - custoProduto) / custoProduto) * 100;

        return <Grid container py={1} className={classes.contentValoresProduto}>
            <Grid item className={cardStyles.celulaGridFull} flex alignItems="center" justifyContent="center" flexDirection="column">
                <Typography color='textPrimary' variant='caption'>
                    Valor de Custo
                </Typography>
                <Typography variant="subtitle1" color='textPrimary' >
                    R$ {toDecimalString(custoProduto, 2)}
                </Typography>
            </Grid>
            <Grid item className={cardStyles.celulaGrid} flex alignItems="center" justifyContent="center" flexDirection="column">
                <Typography color='textPrimary' variant='caption'>
                    % Lucro
                </Typography>
                <Typography variant="subtitle1" color='textPrimary' >
                    {Math.abs(lucroPercentualProduto) === Infinity ? '∞' : toDecimalString(isNaN(lucroPercentualProduto) ? 0 : lucroPercentualProduto, 2) + '%'}
                </Typography>
            </Grid>
            <Grid item className={cardStyles.celulaGridFull} flex alignItems="center" justifyContent="center" flexDirection="column">
                <Typography color='primary' variant='caption'>
                    Valor Venda {props.regra === EnumPromocaoTipoRegra.DePor ? <b>(De)</b> : <></>}
                </Typography>
                <Typography variant="subtitle1" color='primary' >
                    <b>R$ {toDecimalString(valorProduto, 2)}</b>
                </Typography>
            </Grid>
        </Grid>
    }, [cardStyles.celulaGrid, cardStyles.celulaGridFull, classes.contentValoresProduto, props.regra, props.variacaoProduto.precos, props.variacaoProduto.vCompra]);

    const renderValorAtual = useMemo(() => {
        const valorProduto = props.variacaoProduto.precos.length > 0 ? props.variacaoProduto.precos[0].vPreco : 0;

        return <Grid container className={classes.contentCamposPromocao}>
            <Grid className={classNames(classes.campoContainer)} item xs={4} flex alignItems="center" justifyContent="center" flexDirection="column" onClick={() => setCampoSelecionado(EnumCampoTelaEdicao.PercDesconto)}>
                <Grid className={classNames(classes.campo, !formState.valor ? classes.campoErro : undefined, campoSelecionado === EnumCampoTelaEdicao.PercDesconto ? !formState.valor ? classes.campoSelecionadoErro : classes.campoSelecionado : undefined)}>
                    <EditarIcon tipo="GERAL" />
                    <Typography variant="caption" color='textPrimary' >
                        Desconto
                    </Typography>
                    <Grid flex flexDirection="row" justifyContent="flex-end">
                        <Grid mr={0}>
                            <Typography variant="subtitle1" style={{ display: 'block', fontWeight: 'bold' }} color='textPrimary' ref={refPercDesc} >
                            </Typography>
                        </Grid>
                        <Grid >
                            <Typography variant="subtitle1" style={{ display: 'block', fontWeight: 'bold' }} color='textPrimary'>
                                %
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid className={classNames(classes.campoContainer)} item xs={4} flex alignItems="center" justifyContent="center" flexDirection="column" onClick={() => setCampoSelecionado(EnumCampoTelaEdicao.ValorDesconto)}>
                <Grid className={classNames(classes.campo, !formState.valor ? classes.campoErro : undefined, campoSelecionado === EnumCampoTelaEdicao.ValorDesconto ? !formState.valor ? classes.campoSelecionadoErro : classes.campoSelecionado : undefined)}>
                    <EditarIcon tipo="GERAL" />
                    <Typography variant="caption" color='textPrimary' >
                        Desconto
                    </Typography>
                    <Grid flex flexDirection="row" justifyContent="flex-end">
                        <Grid mr={1}>
                            <Typography variant="subtitle1" style={{ display: 'block', fontWeight: 'bold' }} color='textPrimary'>
                                R$
                            </Typography>
                        </Grid>
                        <Grid>
                            <Typography variant="subtitle1" style={{ display: 'block', fontWeight: 'bold' }} color='textPrimary' ref={refValorDesc} >
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid className={classNames(classes.campoContainer)} item xs={4} flex alignItems="center" justifyContent="center" flexDirection="column">
                <Grid className={classNames(classes.campo, !formState.valor ? classes.campoErro : undefined)}>
                    <Typography variant="caption" color='textPrimary' >
                        Novo Lucro
                    </Typography>
                    <Typography variant="subtitle1" style={{ display: 'block', fontWeight: 'bold' }} color='textPrimary' ref={refNovoLucro} >
                    </Typography>
                </Grid>
            </Grid>
            <Grid className={classNames(classes.campoContainer)} item xs={12} flex alignItems="center" justifyContent="center" flexDirection="column" onClick={() => setCampoSelecionado(EnumCampoTelaEdicao.Valor)}>
                <Grid className={classNames(classes.campo, !formState.valor ? classes.campoErro : undefined, campoSelecionado === EnumCampoTelaEdicao.Valor ? !formState.valor ? classes.campoSelecionadoErro : classes.campoSelecionado : undefined)}>
                    <EditarIcon tipo="GERAL" />
                    <Typography variant="caption" color='textPrimary' >
                        {props.regra === EnumPromocaoTipoRegra.DePor ? 'Por' : 'Valor Promocional'}
                    </Typography>
                    <Grid flex flexDirection="row" justifyContent="flex-end">
                        <Grid mr={1}>
                            <Typography variant="subtitle1" style={{ display: 'block', fontWeight: 'bold' }} color='textPrimary'>
                                R$
                            </Typography>
                        </Grid>
                        <Grid>
                            <Typography variant="subtitle1" style={{ display: 'block', fontWeight: 'bold' }} color='textPrimary' ref={refValor} >
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {!formState.valor &&
                <>
                    <Typography variant="caption" color='error' style={{ textAlign: 'center', width: '100%' }}>
                        *Valor de Venda deve ser maior que <b>R$ 0,00</b> e menor que <b>R$ {toDecimalString(valorProduto, 2)}</b>.
                    </Typography>
                </>
            }
            {(props.regra === EnumPromocaoTipoRegra.AtacadoPerc || props.regra === EnumPromocaoTipoRegra.AtacadoValor) && (
                <>
                    <Grid className={classNames(classes.campoContainer)} item xs={12} flex alignItems="center" justifyContent="center" flexDirection="column" onClick={() => setCampoSelecionado(EnumCampoTelaEdicao.Qtde)}>
                        <Grid className={classNames(classes.campo, !formState.qtde ? classes.campoErro : undefined, campoSelecionado === EnumCampoTelaEdicao.Qtde ? !formState.qtde ? classes.campoSelecionadoErro : classes.campoSelecionado : undefined)}>
                            <EditarIcon tipo="GERAL" />
                            <Typography variant="caption" color='textPrimary' >
                                Qtd. Mínima
                            </Typography>
                            <Typography variant="subtitle1" color='textPrimary' ref={refQtde}  >
                            </Typography>
                        </Grid>
                    </Grid>
                    {!formState.qtde &&
                        <>
                            <Typography variant="caption" color='error' style={{ textAlign: 'center', width: '100%' }}>
                                *A quantidade mínima deve ser de no mínimo <b>1</b> e no máximo <b>9999</b>.
                            </Typography>
                        </>
                    }
                </>
            )}
        </Grid>
    }, [campoSelecionado, classes.campo, classes.campoContainer, classes.campoErro, classes.campoSelecionado, classes.campoSelecionadoErro, classes.contentCamposPromocao, formState.qtde, formState.valor, props.regra, props.variacaoProduto.precos]);

    const renderKeyboard = useMemo(() => {
        return <Grid container style={{ flex: 1, overflowY: 'auto' }}>
            <Keyboard
                isButtonKeyboard00
                isButtonBackspace
                handleBackSpace={backSpaceKey}
                handleText={sendText}
                handleEscape={() => resetText('0')}
                isNumeric
            />
        </Grid>
    }, [backSpaceKey, resetText, sendText]);
    //endregion

    return (
        <>
            {renderHeader}
            <Box className={modalClasses.content}>
                {!props.carregando && (
                    <>
                        <Grid className={classNames(modalClasses.contentForms,)} flex flexDirection="column" m={0} p={0}>
                            {renderValorProduto}
                            {renderValorAtual}
                            {renderKeyboard}
                        </Grid>
                        {renderButtons}
                    </>
                )}
            </Box>
        </>
    );
}
