import { PedidoModel } from "model/api/gestao/pedido/pedido-model"
import { DefaultModal } from "../components"
import { ModalProps } from "../utils"
import { PedidoPagAntecipadoConteudo } from "./components/pedido-pag-antecipado-conteudo/pedido-pag-antecipado-conteudo"

export interface PedidoPagAntecipadoProps {
    pedido: PedidoModel
}

export type PedidoPagAntecipadoModalProps = ModalProps & PedidoPagAntecipadoProps

export const PedidoPagAntecipadoModal = (props: PedidoPagAntecipadoModalProps) => {
    return (
        <DefaultModal
            minWidth="400px"
            open={props.openned || false}
            variant={'temporary'}
            anchor="right"
        >
            {props.openned && <PedidoPagAntecipadoConteudo {...props} />}
        </DefaultModal>
    )
}