import { ScannerIcon } from "views/components/icons/scanner-icon"
import { useStyles } from './selecionar-cadastro-styles'
import { CodigoBarrasIcon } from "views/components/icons/codigo-barras-icon";
import { VoltarIcon } from "views/components/icons";
import { useCadastros } from "services/app";
import { Box, Button, Typography } from "views/design-system";

interface SelecionarCadastroComandaProps {
    goBack: () => void;
    showReturn: boolean;
}

export const SelecionarCadastroComanda = ({ goBack, showReturn }: SelecionarCadastroComandaProps) => {

    const classes = useStyles();

    const { abrirCadastroComandas } = useCadastros();

    return (
        <Box className={classes.root}>
            <Box className={classes.gridItem}>
                <Typography align='center'>Selecione como deseja configurar suas comandas.</Typography>
            </Box>
            <Box className={classes.gridItem}>
                <Button size='large' variant='contained' color='primary' className={classes.button}
                    onClick={() => abrirCadastroComandas('', '', true, true, false)}>
                    <ScannerIcon tipo='BUTTON_PRIMARY' />
                    Configurar com RFID
                </Button>
            </Box>
            <Box className={classes.gridItem}>
                <Button size='large' variant='contained' color='primary' className={classes.button}
                    onClick={() => abrirCadastroComandas('', '', true, false, false)}>
                    <CodigoBarrasIcon tipo='BUTTON_PRIMARY' />
                    Configurar com Código de Barras
                </Button>
            </Box>
            <Box className={classes.gridItem}>
                <Button size='large' variant='contained' color='primary' className={classes.button}
                    onClick={() => abrirCadastroComandas('', '', true, true, true)}>
                    <Box flex gap={4} alignItems='center'>
                        <ScannerIcon tipo='BUTTON_PRIMARY' />
                        <Typography className={classes.marginIcon}>+</Typography>
                        <CodigoBarrasIcon tipo='BUTTON_PRIMARY' />
                    </Box>
                    Config. RFID + Código de Barras
                </Button>
            </Box>
            {showReturn && <Box className={classes.gridItem}>
                <Button size='large' variant='outlined' color='primary' style={{ width: 320 }} onClick={goBack}>
                    <VoltarIcon tipo='BUTTON' />
                    Voltar a Listagem
                </Button>
            </Box>}
        </Box>
    )
}