export class SenhaGenerator {
    gerarSenhaAleatoria(historico: number[], limiteDigitos: number): number {
        let senha;
        let tentativas = limiteDigitos * 10;
        let tentativa = 1;
        do {
            senha = this.gerarNumeroAleatorio(limiteDigitos);
            tentativa++;
        } while (historico.includes(senha) && tentativa < tentativas);
        return senha;
    }

    private gerarNumeroAleatorio(limiteDigitos: number): number {
        const minimoValor = 1;
        const maximoValor = 10 ** limiteDigitos - 1;

        return Math.floor(Math.random() * (maximoValor - minimoValor + 1)) + minimoValor;
    }
}