import { guidEmpty } from "utils/guid-empty";

export enum EnumPaymentType {
    Debito = 1,
    Credito = 2,
    Voucher = 3,
    Boleto = 4,

    /** Transferência Eletrônica de Fundos */
    Ted = 5,

    /** Documento de Ordem de Crédito */
    Doc = 6,
    SafetyPay = 7,
    Pix = 8,
}

export enum EnumInstallmentType {
    /** Transação a vista */
    None = 0,

    /** Transação parcelada pelo lojista, ou seja, sem juros. */
    Merchant = 1,

    /** Transação parcelada pelo emissor do cartão, ou seja, com juros. */
    Issuer = 2,
}

export enum EnumPhoneType {
    /**Telefone Residencial */
    Residential = 1,

    /** Telefone Comercial */
    Commercial = 2,

    /** Correio de Voz */
    Voicemail = 3,

    /** Telefone Temporário */
    Temporary = 4,

    /** Celular */
    Mobile = 5
}

export enum EnumEntityType {
    IndividualMerchant = 1, // Pessoa Física
    Company = 2             // Pessoa Jurídica
}

export enum EnumDocumentType {
    Cpf = 1,       // CPF
    Cnpj = 2,      // CNPJ
    Passport = 3   // Passaporte
}

export enum EnumGenderType {
    None = 0,
    Female = 1,
    Male = 2,
    Other = 3,
}

export enum EnumAcquireType {
    Cielo = 1,
    Rede = 2,
    Stone = 3,
    VBI = 4,
    Granito = 5,
    InfinitePay = 6,
    Safrapay = 7, // Por enquanto este é só usado apenas este, depois validar com o Davis
    AdiqEcommerce = 8,
    PagSeguro = 9,
    AdiqTEF = 10,
    SafrapayTef = 11,
    VrBenefits = 12,
    Simulador = 999
}

export class ChargeAuthorizationModel {
    constructor(
        public charge: ChargeObjectsModel = new ChargeObjectsModel()
    ) { }
}

export class ChargeObjectsModel {
    constructor(
        public Customer: CustomerModel = new CustomerModel(),
        public transactions: TransactionModel[] = [],
        public source: number = 8,
        public gatewayId: string = '91001',
        public capture?: boolean,
        public id: string = guidEmpty()
    ) { }
}

export class CustomerModel {
    constructor(
        public name: string = '',
        public email?: string,
        public documentType: EnumDocumentType = EnumDocumentType.Cpf,
        public entityType: EnumEntityType = EnumEntityType.IndividualMerchant,
        public document: string = '',
        public gender: EnumGenderType = EnumGenderType.None,
        public phone: PhoneModel | undefined = undefined,
        public address?: AddressModel,
    ) { }
}

export class PhoneModel {
    constructor(
        public countryCode: string = '',
        public areaCode: string = '',
        public number: string = '',
        public type: EnumPhoneType = EnumPhoneType.Mobile,
    ) { }
}

export class AddressModel {
    constructor(
        public street: string = '',
        public number: string = '',
        public neighborhood: string = '',
        public city: string = '',
        public state: string = '',
        public country: string = '',
        public zipCode: string = '',
        public complement: string = '',
    ) { }
}

export class TransactionModel {
    constructor(
        public temporaryCardToken: string = '',
        public paymentType: EnumPaymentType = EnumPaymentType.Credito,
        public amount: number = 0,
        public installmentNumber: number = 1,
        public acquirer: EnumAcquireType = EnumAcquireType.Simulador,
        public installmentType: EnumInstallmentType = EnumInstallmentType.None,
        public SoftDescriptor?: string,
    ) { }
}
