import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
    cardContainer: {
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
    },
    cardContent: {
        display: "flex",
        flexGrow: 1,
        flexDirection: "column",
    },
    label: {
        fontSize: '0.9rem',
        lineHeight: '14,1px',
        marginBottom: '16px'
    },
    boxCustom:{
        display: 'flex', 
        flexDirection: 'column', 
        flex: 1 
    },
    text:{
        flex: 1
    }
}));
