import { CardCargaProdutoProps } from './card-carga-produto-props';
import { useCallback } from 'react';
import { DefaultCard } from 'views/components/cards/components';
import { Styles } from './card-carga-produto-styles';
import { useThemeQueries } from 'views';
import { toDecimalString } from 'utils/to-decimal';
import { TpUploadCargaStatusMock } from 'data/mocks/tp-carga-tipo-mock';
import { EnumUploadCargaStatus } from 'model/enums/enum-upload-carga-status';
import { TpProdutoFarmaciaMock } from 'data/mocks/tp-produto-mock';
import { Grid, Typography } from 'views/design-system';

export const CardCargaProduto = ({ model, onClick }: CardCargaProdutoProps) => {
  const classes = Styles();
  const { theme } = useThemeQueries();

  const retornarCorStatus = useCallback((): any => {
    switch (model.status.codigo) {
      case EnumUploadCargaStatus.Processado:
        return '#10C822';
      case EnumUploadCargaStatus.Aguardando:
        return '#1F9CE4';
      case EnumUploadCargaStatus.Analisando:
        return '#4A4A4A';
      case EnumUploadCargaStatus.Erro:
        return '#F44336';
      case EnumUploadCargaStatus.Processando:
        return theme.palette.warning.dark;
      case EnumUploadCargaStatus.AguardandoProcessamento:
        return '#1F9CE4';
      case EnumUploadCargaStatus.Cancelado:
        return '#4A4A4A';
      default:
        return '#28EC87';
    }
  }, [model.status, theme.palette.warning.dark]);

  const status = TpUploadCargaStatusMock.filter((x) => x.Key === model.status.codigo)[0]?.Value;

  return (
    <>
      <DefaultCard onClick={async () => onClick(model)}>
        <Grid container spacing={2} className={classes.cardContent}>
          <Grid item xs={2}>
            <Typography color="textPrimary" variant="caption">
              Código
            </Typography>

            <Typography
              className={'celula-grid-value'}
              color="textPrimary"
              variant="body1"
              style={{ minWidth: 150, maxWidth: 150 }}
            >
              <strong>{model.codigo || 'Sem Código'}</strong>
            </Typography>
          </Grid>

          <Grid item xs={5}>
            <Typography color="textPrimary" variant="caption">
              Descrição
            </Typography>
            <Typography
              className={'celula-grid-value'}
              color="textPrimary"
              variant="body1"
              style={{ minWidth: 300, maxWidth: 300 }}
            >
              {model.descricao || 'Sem Descrição'}
            </Typography>
          </Grid>

          <Grid item xs={3}>
            <Typography color="textPrimary" variant="caption">
              Categoria
            </Typography>
            <Typography
              className={'celula-grid-value'}
              color="textPrimary"
              variant="body1"
            >
              {model.categoria || 'Sem Categoria'}
            </Typography>
          </Grid>

          <Grid item xs={1}>
            <Typography color="textPrimary" variant="caption">
              Valor
            </Typography>
            <Typography
              className={'celula-grid-value'}
              color="textPrimary"
              variant="body1"
            >
              <strong>R$ {toDecimalString(model.preco || 0, 2)}</strong>
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography color="textPrimary" variant="caption">
              Tipo
            </Typography>
            <Typography
              className={'celula-grid-value'}
              color="textPrimary"
              variant="body1"
            >
              {TpProdutoFarmaciaMock.find(x => x.Key === model.tpCadastro)?.Value || 'Produto'}
            </Typography>
          </Grid>
        </Grid>
        <div>
          <Grid style={{ display: 'flex', alignItems: 'center', height: '100%', margin: 0, marginRight: theme.spacing(1) }}>
            <div
              style={{
                display: 'flex',
                padding: theme.spacing(0.5),
                borderRadius: 4,
                justifyContent: 'center',
                background: retornarCorStatus(),
              }}
            >
              <Typography
                className={'celula-grid-value'}
                color="textPrimary"
                variant="body1"
                style={{ color: '#fff' }}
              >
                {status}
              </Typography>
            </div>
          </Grid>
        </div>
      </DefaultCard>
    </>
  );
};
