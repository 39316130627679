import { DefaultCard, useDefaultCardStyles } from '../components';
import { SacoDinheiroIcon } from 'views/components/icons/saco-dinheiro-icon';
import { useCallback, useEffect, useState } from 'react';
import { useSessaoAtual, useToastSaurus } from 'services/app';
import { CircularLoading } from 'views/components/utils';
import { CardOperadores } from './components/card-operadores/card-operadores';
import { toDecimalString } from 'utils/to-decimal';
import { useStyles } from './card-gerenciamento-sessoes-styles';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import { useGetPontosVendaById } from 'data/api/gestao/pontos-venda';
import { PontosVendaCompletoModel } from 'model/app/ponto-venda/ponto-venda-completo-model';
import { CardPagamentos } from './components/card-pagamentos/card-pagamentos';
import { useGetDashboardPagamentosSessao } from 'data/api/gestao/relatorios/dashboard/get-dashboard-pagamentos-sessao';
import { DashboardPagamentosModel } from 'model/api/gestao/dashboard/dashboard-pagamentos';
import { useThemeQueries } from 'views/theme';
import { toDateStringApi } from 'utils/to-date';
import { RelogioIcon } from 'views/components/icons';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { Button, Grid, Tooltip, Typography } from 'views/design-system';
import { CardGerenciamentoSessoesProps } from './card-gerenciamento-sessoes-props';

export const CardGerenciamentoSessoes = ({
  model,
  qtdCancelados,
  qtdItens,
  valorTotal,
  sessoes,
  searchProps,
  nomeOperador,
}: CardGerenciamentoSessoesProps) => {

  //AUX
  const classes = useDefaultCardStyles();
  const classesSessoes = useStyles();
  const { showToast } = useToastSaurus();
  const { isMobile, theme } = useThemeQueries();
  const { addHandler, removeHandler } = useEventTools()
  //PROVIDERS
  const { getEmpresaSelecionada } = useSessaoAtual();
  //CHAMADAS DA API
  const { getPontosVendaById, carregando: carregandoPontoDeVenda } = useGetPontosVendaById();
  const {
    getDashboardPagamentosSessao,
    carregando: carregandoDashboardPagamentoSessao
  } = useGetDashboardPagamentosSessao();

  const carregando =
    carregandoPontoDeVenda ||
    carregandoDashboardPagamentoSessao;

  //STATES
  const [resumoPorOperadores, setResumoPorOperadores] = useState<boolean>(false);
  const [resumoPorPagamentos, setResumoPorPagamentos] = useState<boolean>(false);
  const [sessaoPagamentos, setSessaoPagamentos] = useState<DashboardPagamentosModel[]>([]);
  const [pontoDeVenda, setPontoDeVenda] = useState<PontosVendaCompletoModel>(new PontosVendaCompletoModel());

  const getPontosVendaByIdWrapper = useCallback(async () => {
    try {
      const res = await getPontosVendaById(getEmpresaSelecionada()?.Id!, model.caixaId);

      if (res.erro)
        throw res.erro

      setPontoDeVenda(res.resultado?.data);
      return;
    } catch (err: any) {
      showToast('error', err.message);
    }
  }, [getEmpresaSelecionada, getPontosVendaById, model.caixaId, showToast])

  const getDashboardPagamentosSessaoWrapper = useCallback(async () => {
    const query =
      "" +
      (!isEmpty(searchProps?.dInicial)
        ? "dInicial=" + toDateStringApi(new Date(searchProps?.dInicial))
        : "") +
      (!isEmpty(searchProps?.dFinal)
        ? "&dFinal=" + toDateStringApi(new Date(searchProps?.dFinal))
        : "");

    try {
      const res = await getDashboardPagamentosSessao(model.id, query);

      if (res.erro)
        throw res.erro

      if (isEmpty(res?.resultado?.data)) {
        return showToast(
          'error',
          'Nenhuma forma de pagamento encontrado. Mude a data inicial e a data final para obter mais resultados.',
        );
      }

      setSessaoPagamentos(res.resultado?.data)
      return
    } catch (err: any) {
      showToast('error', err.message);
    }
  }, [getDashboardPagamentosSessao, model.id, searchProps?.dFinal, searchProps?.dInicial, showToast])

  useEffect(() => {
    const gets = async () => {
      if (resumoPorOperadores) {
        await getPontosVendaByIdWrapper();
      }

      if (resumoPorPagamentos) {
        await getDashboardPagamentosSessaoWrapper();
      }
    }

    gets();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resumoPorPagamentos, resumoPorOperadores])

  const resumoPorPagamentosClick = useCallback(() => {
    if (resumoPorOperadores) {
      setResumoPorOperadores(!resumoPorOperadores);
    }

    setResumoPorPagamentos(!resumoPorPagamentos);
  }, [resumoPorOperadores, resumoPorPagamentos])

  const resumoPorOperadoresClick = useCallback(() => {

    if (resumoPorPagamentos) {
      setResumoPorPagamentos(!resumoPorPagamentos);
    }

    setResumoPorOperadores(!resumoPorOperadores);
  }, [resumoPorOperadores, resumoPorPagamentos])


  const atualizar = useCallback(async (aberto: boolean) => {
    if (!aberto) {
      if (resumoPorOperadores) {
        await getPontosVendaByIdWrapper();
      }

      if (resumoPorPagamentos) {
        await getDashboardPagamentosSessaoWrapper();
      }

    }
  }, [getDashboardPagamentosSessaoWrapper, getPontosVendaByIdWrapper, resumoPorOperadores, resumoPorPagamentos])

  useEffect(() => {
    addHandler(AppEventEnum.DialogEditarGerenciamentoSessao, atualizar)
    return () => removeHandler(AppEventEnum.DialogEditarGerenciamentoSessao, atualizar)
  }, [addHandler, atualizar, removeHandler])

  return (
    <DefaultCard
      className={classesSessoes.root}
    >
      {carregando && <CircularLoading tipo="FULLSIZED" />}
      <Grid container className={classesSessoes.containerRoot}>
        {isMobile ? (
          <>
            <Tooltip arrow title={nomeOperador}>
              <Grid item xs={8}>
                <Typography
                  color="textPrimary"
                  variant="caption"
                >
                  Operador
                </Typography>
                <Typography
                  color="textPrimary"
                  variant="body2"
                >
                  {nomeOperador}
                </Typography>
              </Grid>
            </Tooltip>
            <Grid item xs={4} className={classesSessoes.buttonsContainer}>
              <Button
                variant={resumoPorOperadores ? 'contained' : 'outlined'}
                color='primary'
                size='small'
                onClick={resumoPorOperadoresClick}
                className={classNames(classesSessoes.buttons, classesSessoes.buttonOperadores)}
              >
                <RelogioIcon
                  tipo='BUTTON_PRIMARY'
                  fill={
                    resumoPorOperadores
                      ? theme.palette.primary.contrastText
                      : theme.palette.primary.main
                  }
                />
              </Button>
              <Button
                variant={resumoPorPagamentos ? 'contained' : 'outlined'}
                color='primary'
                size='small'
                onClick={resumoPorPagamentosClick}
                className={classNames(classesSessoes.buttons, classesSessoes.buttonFormaPagamento)}
              >
                <SacoDinheiroIcon
                  tipo='BUTTON_PRIMARY'
                  fill={
                    resumoPorPagamentos
                      ? theme.palette.primary.contrastText
                      : theme.palette.primary.main
                  }
                />
              </Button>
            </Grid>
            <Grid item xs={4}>
              <Typography
                variant='caption'
                color='textPrimary'
              >
                Cancelados
              </Typography>
              <Typography
                variant='body1'
                color='textPrimary'
              >
                {qtdCancelados}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography
                variant='caption'
                color='textPrimary'
              >
                Itens
              </Typography>
              <Typography
                variant='body1'
                color='textPrimary'
              >
                {qtdItens}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography
                variant='caption'
                color='textPrimary'
              >
                Valor Total
              </Typography>
              <Typography
                variant='body1'
                color='textPrimary'
              >
                {`R$ ${toDecimalString(valorTotal, 2)}`}
              </Typography>
            </Grid>
          </>
        ) : (
          <>
            <div className={classes.cardContent}>
              <div className={classNames(classes.celulaGrid, classesSessoes.operadorContainer)}>
                <Typography
                  color="textPrimary"
                  variant="caption"
                >
                  Operador
                </Typography>
                <Typography
                  color="textPrimary"
                  variant="body2"
                >
                  {nomeOperador}
                </Typography>
              </div>
              <div className={classes.celulaGrid}>
                <Typography
                  variant='caption'
                  color='textPrimary'
                >
                  Cancelados
                </Typography>
                <Typography
                  variant='body1'
                  color='textPrimary'
                >
                  {qtdCancelados}
                </Typography>
              </div>
              <div className={classes.celulaGrid}>
                <Typography
                  variant='caption'
                  color='textPrimary'
                >
                  Itens
                </Typography>
                <Typography
                  variant='body1'
                  color='textPrimary'
                >
                  {qtdItens}
                </Typography>
              </div>
              <div className={classNames(classes.celulaGrid, classesSessoes.valorTotalContainer)}>
                <Typography
                  variant='caption'
                  color='textPrimary'
                >
                  Valor Total
                </Typography>
                <Typography
                  variant='body1'
                  color='textPrimary'
                >
                  {`R$ ${toDecimalString(valorTotal, 2)}`}
                </Typography>
              </div>
              <div className={classNames(classes.celulaGrid, classesSessoes.buttonsContainer)}>
                <Tooltip arrow title='Resumo Por Operadores'>
                  <div>
                    <Button
                      variant={resumoPorOperadores ? 'contained' : 'outlined'}
                      color='primary'
                      size='small'
                      onClick={resumoPorOperadoresClick}
                      className={classNames(classesSessoes.buttons, classesSessoes.buttonOperadores)}
                    >
                      <RelogioIcon
                        tipo={'BUTTON_PRIMARY'}
                        fill={
                          resumoPorOperadores
                            ? theme.palette.primary.contrastText
                            : theme.palette.primary.main
                        }
                      />
                    </Button>
                  </div>
                </Tooltip>
                <Tooltip arrow title='Resumo Por Forma de Pagamento'>
                  <div>
                    <Button
                      variant={resumoPorPagamentos ? 'contained' : 'outlined'}
                      color='primary'
                      size='small'
                      onClick={resumoPorPagamentosClick}
                      className={classNames(classesSessoes.buttons, classesSessoes.buttonFormaPagamento)}
                    >
                      <SacoDinheiroIcon
                        tipo='BUTTON_PRIMARY'
                        fill={
                          resumoPorPagamentos
                            ? theme.palette.primary.contrastText
                            : theme.palette.primary.main
                        }
                      />
                    </Button>
                  </div>
                </Tooltip>
              </div>
            </div>
          </>
        )}

        {resumoPorOperadores && (
          sessoes.filter(x => x.operadorId === model.operadorId).map((item) => {
            return (
              <Grid item xs={12}>
                <CardOperadores
                  model={item}
                  pontoDeVenda={pontoDeVenda}
                />
              </Grid>
            )
          })
        )}

        {resumoPorPagamentos && (
          sessaoPagamentos.map((item) => {
            return (
              <Grid item xs={12}>
                <CardPagamentos
                  pagamento={item}
                />
              </Grid>
            )
          })
        )}

      </Grid>
    </DefaultCard>
  );
};