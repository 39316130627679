import { CardNaoEncontrado } from '../../../../../../components/cards/card-naoencontrado/card-naoencontrado';
import { InformacaoIcon } from '../../../../../../components/icons/informacao-icon';
import { FinalizadoraModel } from 'model/api/gestao/finalizadora/finalizadora-model';
import { IconCard } from 'views/components/cards/components';
import { retornarIconeFinalizadora } from 'views/components/modals/finalizadora/finalizadora-modal/components/finalizadora-cadastro-tipo/finalizadora-cadastro-tipo';
import { SwitchSaurus } from 'views/components/controles';
import { useEffect, useRef, useState } from 'react';
import { isEqual, isUndefined } from 'lodash';
import { useStyles } from './finalizadora-list-styles'
import { SalvarEditarIcon } from 'views/components/icons';
import { FinalizadoraOrdemModel } from 'model/api/gestao/finalizadora/finalizadora-ordem-model';
import { EnumPagTpTransacao } from 'model';
import { Box, Button, Grid, Typography } from 'views/design-system';
import { tpPagamentoResolver } from 'utils/tp-pagamento-resolver';

export interface FinalizadoraGridProps {
  list: Array<FinalizadoraModel>;
  carregando: boolean;
  selectedList: Array<string>;
  onChangeStatus: (model: FinalizadoraModel) => Promise<any>;
  onCardSelected: (id: string) => any;
  submitOrdenacao: (list: FinalizadoraOrdemModel[]) => Promise<void>;
}

export const FinalizadoraListData = (props: FinalizadoraGridProps) => {
  const onCardSelected = (model: FinalizadoraModel) => {
    props.onCardSelected(model.id);
  };

  const [list, setList] = useState<FinalizadoraModel[]>(props.list)

  const draggingIndex = useRef<number>(0)


  const classes = useStyles();

  const moverCard = (target: number) => {
    if (isUndefined(draggingIndex.current) || isUndefined(target)) return

    let newList = [...list];
    newList[draggingIndex.current] = { ...list[target] }
    newList[target] = { ...list[draggingIndex.current] }

    setList([...newList])
  }

  useEffect(() => setList(props.list), [props.list])

  return (
    <>
      {list.length === 0 && !props.carregando && (
        <Grid item xs={12}>
          <CardNaoEncontrado
            mensagem="Nenhuma Finalizadora encontrada."
            icon={<InformacaoIcon tipo="GERAL" />}
          />
        </Grid>
      )}
      {list.length > 0 &&
        list.map((finalizadora, index) => {
          const tipo =  tpPagamentoResolver(finalizadora.tpMod, finalizadora.tpTransacao)?.Value || 'Outros'
          return (
            <Grid item xs={4} md={3}>
              <IconCard<FinalizadoraModel>
                icon={retornarIconeFinalizadora(finalizadora.tpMod)}
                title={finalizadora.descricao}
                model={finalizadora}
                onClick={onCardSelected}
                description={tipo.toLowerCase() !== finalizadora.descricao.toLowerCase() ? `${finalizadora.tpTransacao === EnumPagTpTransacao.S2_PAY ? tipo + " (Integrado)" : tipo}` : undefined}
                index={index}
                onDrop={moverCard}
                draggable
                setDraggingIndex={(index) => {
                  draggingIndex.current = index
                }}
                isOpacity={finalizadora.ativo}
              >
                <SwitchSaurus
                  variant='DEFAULT'
                  size='small'
                  extraSmall
                  checked={finalizadora.ativo}
                  onChange={() => props.onChangeStatus(finalizadora)}
                  onClick={(e) => e.stopPropagation()}
                />
              </IconCard>
            </Grid>
          );
        })}
      {!isEqual(list, props.list) && <Box className={classes.saveOrderBtn}>
        <Button variant='contained' color='secondary' onClick={() => {
          props.submitOrdenacao(list.map((item, index) => new FinalizadoraOrdemModel(index, item.id)))
        }}>
          <SalvarEditarIcon tipo='BUTTON_PRIMARY' />
          <Typography className={classes.btnText}>Salvar Ordenação</Typography>
        </Button>
      </Box>}
    </>
  );
};
