import { useRef, useState } from 'react';
import { useStyles } from './form-empresa-styles';
import { useFormEmpresaValidation } from './form-empresa-validation';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { CircularLoading, makeUtilClasses, useThemeQueries } from 'views';
import React from 'react';
import {
  DefaultFormRefs,
  DefaultFormProps,
} from '../../utils/form-default-props';
import { EmpresaFormModel } from 'model/app';
import { UFMock } from 'data/mocks/uf-mock';
import { SelectSaurus } from 'views/components/controles/selects/select-saurus/select-saurus';
import { useToastSaurus } from '../../../../../services/app/hooks';
import { useConsultaCEP } from 'data/api/wsmaster/consultar-cep';
import { RegimeMock } from '../../../../../data/mocks/regime-mock';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useConsultaCnpj } from 'data/api/wsmaster';
import { stringNumeros } from 'utils/string-numeros';
import { AutocompleteSegmento } from 'views/components/controles/autocompletes/autocomplete-segmento/autocomplete-segmento';
import { guidEmpty } from 'utils/guid-empty';
import { SegmentoModel } from 'model/api/gestao/master/segmento';
import { picker } from 'utils/picker';
import { Button, Grid } from 'views/design-system';

export interface FormEmpresaProps extends DefaultFormProps<EmpresaFormModel> {
  setCarregandoExterno(carregando: boolean): void;
}

export const FormAdicionarEmpresa = React.forwardRef<
  DefaultFormRefs<EmpresaFormModel>,
  DefaultFormProps<EmpresaFormModel>
>((props: DefaultFormProps<EmpresaFormModel>, refs) => {

  const txtRazaoSocial = useRef<HTMLInputElement>();
  const txtNro = useRef<HTMLInputElement>();
  const txtLogradouro = useRef<HTMLInputElement>();
  const utilClasses = makeUtilClasses();
  const classes = useStyles();
  const [model, setModel] = useState({ modeloForm: new EmpresaFormModel('') });
  const { showToast } = useToastSaurus();
  const { consultaCEP, carregando: carregandoCep } = useConsultaCEP();
  const { isMobile } = useThemeQueries();
  const { consultarCNPJ, carregando: carregandoCNPJ } = useConsultaCnpj()
  const { FormEmpresaValidationYup } = useFormEmpresaValidation(model.modeloForm.cpfcnpj.length !== 11);
  const {
    handleSubmit,
    control,
    formState: { errors, touchedFields },
    reset,
    getValues,
    setValue,
  } = useForm<EmpresaFormModel>({
    defaultValues: { ...model.modeloForm },
    resolver: yupResolver(FormEmpresaValidationYup),
    criteriaMode: 'all',
    mode: 'onSubmit'
  });


  const onSubmit = (form: EmpresaFormModel) => {
    props.onSubmit(form, model.modeloForm);
  };

  React.useImperativeHandle(refs, () => ({
    submitForm: async () => {
      await handleSubmit(onSubmit)();
    },
    resetForm: () => {
      setModel({ modeloForm: new EmpresaFormModel('', '') });
      reset();
      if (!isMobile) txtRazaoSocial?.current?.focus();
    },
    fillForm: (model: EmpresaFormModel) => {
      setModel({ modeloForm: model });
      reset({ ...model });
      if (!isMobile) txtRazaoSocial?.current?.focus();
    },
  }));

  let carregando = carregandoCep || carregandoCNPJ
  return (
    <>
      <div className={utilClasses.formContainer}>
        {(props.loading && props.showLoading) || carregando ? (
          <CircularLoading tipo="FULLSIZED" />
        ) : <></>}
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={`${classes.formContainer} ${props.loading && utilClasses.controlLoading
            }`}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Controller
                name="cpfcnpj"
                control={control}
                render={({ field }) => (
                  <TextFieldSaurus
                    tipo="CNPJ_CPF"
                    fullWidth={true}
                    searchable
                    onSearch={async (value) => {
                      try {
                        let valor = stringNumeros(value)
                        if (valor.length > 11) {
                          let res = await consultarCNPJ(valor)
                          setValue('razaoSocial', res.razaoSocial)
                          setValue('nomeFantasia', res.nomeFantasia)
                          setValue('ierg', res.ie)
                          setValue('regime', res.regime)
                          setValue('cep', stringNumeros(res.cep))
                          setValue('logradouro', res.logradouro)
                          setValue('numero', res.numero)
                          setValue('complemento', res.complemento)
                          setValue('bairro', res.bairro)
                          setValue('municipio', res.municipio)
                          setValue('cMunicipio', res.cMunicipio)
                          setValue('uf', UFMock.find(uf => uf.Value === res.uf)?.Value!)
                        } else {
                          showToast('info', 'Busca disponível apenas para CNPJ');
                          return
                        }
                      }
                      catch (erro: any) {
                        return showToast('error', erro.message)
                      }
                    }}
                    showStartAdornment={true}
                    autoComplete={'off'}
                    label="CPF / CNPJ"
                    placeholder=""
                    error={Boolean(
                      errors.cpfcnpj && errors.cpfcnpj.message,
                    )}
                    helperText={
                      touchedFields.cpfcnpj || errors.cpfcnpj
                        ? errors.cpfcnpj?.message
                        : undefined
                    }
                    {...field}
                    value={getValues('cpfcnpj')}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={8}>
              <Controller
                name="razaoSocial"
                control={control}
                render={({ field }) => (
                  <TextFieldSaurus
                    tipo="TEXTO"
                    disabled={props.loading}
                    fullWidth={true}
                    showStartAdornment={true}
                    allowSubmit={false}
                    inputRef={txtRazaoSocial}
                    autoComplete={'off'}
                    label="Nome Completo / Razão Social"
                    placeholder=""
                    error={Boolean(
                      errors.razaoSocial && errors.razaoSocial.message,
                    )}
                    helperText={
                      touchedFields.razaoSocial || errors.razaoSocial
                        ? errors.razaoSocial?.message
                        : undefined
                    }
                    {...field}
                    value={getValues('razaoSocial')}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="nomeFantasia"
                control={control}
                render={({ field }) => (
                  <TextFieldSaurus
                    tipo="TEXTO"
                    disabled={props.loading}
                    fullWidth={true}
                    showStartAdornment={true}
                    allowSubmit={false}
                    autoComplete={'off'}
                    label="Nome Fantasia"
                    placeholder=""
                    error={Boolean(
                      errors.nomeFantasia && errors.nomeFantasia.message,
                    )}
                    helperText={
                      touchedFields.nomeFantasia || errors.nomeFantasia
                        ? errors.nomeFantasia?.message
                        : undefined
                    }
                    {...field}
                    value={getValues('nomeFantasia')}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <Controller
                name="ierg"
                control={control}
                render={({ field }) => (
                  <TextFieldSaurus
                    tipo="TEXTO"
                    disabled={props.loading}
                    fullWidth={true}
                    showStartAdornment={false}
                    allowSubmit={false}
                    autoComplete={'off'}
                    label="IERG"
                    placeholder=""
                    error={Boolean(errors.ierg && errors.ierg.message)}
                    helperText={
                      touchedFields.ierg || errors.ierg
                        ? errors.ierg?.message
                        : undefined
                    }
                    {...field}
                    value={getValues('ierg')}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                name="fone"
                control={control}
                render={({ field }) => (
                  <TextFieldSaurus
                    tipo={'TELEFONE'}
                    disabled={props.loading}
                    fullWidth={true}
                    showStartAdornment={true}
                    allowSubmit={false}
                    label="Telefone"
                    placeholder=""
                    error={Boolean(errors.fone && errors.fone.message)}
                    helperText={
                      touchedFields.fone || errors.fone
                        ? errors.fone?.message
                        : undefined
                    }
                    {...field}
                    value={getValues('fone')}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Controller
                name="cep"
                control={control}
                render={({ field }) => (
                  <TextFieldSaurus
                    tipo="CEP"
                    disabled={props.loading}
                    fullWidth={true}
                    allowSubmit={false}
                    searchable

                    autoComplete={'off'}
                    label="CEP"
                    placeholder=""
                    onSearch={async (value: string) => {
                      try {
                        let res = await consultaCEP(value);
                        setValue('cep', stringNumeros(res.CEP));
                        setValue('bairro', res.Bairro);
                        setValue('logradouro', res.Logradouro);
                        setValue('uf', res.UF);
                        setValue('municipio', res.Municipio);
                        setValue('cMunicipio', res.CMun?.toString());
                        if (!isMobile) txtNro.current?.focus();
                      } catch (e: any) {
                        showToast('error', e.message);
                        if (!isMobile) txtLogradouro.current?.focus();
                      }
                    }}
                    error={Boolean(errors.cep && errors.cep.message)}
                    helperText={
                      touchedFields.cep || errors.cep
                        ? errors.cep?.message
                        : undefined
                    }
                    {...field}
                    value={getValues('cep')}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={9}>
              <Controller
                name="logradouro"
                control={control}
                render={({ field }) => (
                  <TextFieldSaurus
                    tipo={'TEXTO'}
                    inputRef={txtLogradouro}
                    disabled={props.loading}
                    fullWidth
                    variant="outlined"
                    label="Endereço"
                    InputLabelProps={{ shrink: true }}
                    placeholder=""
                    error={Boolean(
                      errors.logradouro && errors.logradouro.message,
                    )}
                    helperText={
                      touchedFields.logradouro || errors.logradouro
                        ? errors.logradouro?.message
                        : undefined
                    }
                    {...field}
                    value={getValues('logradouro')}
                  />
                )}
              />
            </Grid>

            <Grid item xs={4}>
              <Controller
                name="numero"
                control={control}
                render={({ field }) => (
                  <TextFieldSaurus
                    tipo="TEXTO"
                    inputRef={txtNro}
                    disabled={props.loading}
                    fullWidth
                    variant="outlined"
                    label="Número"
                    InputLabelProps={{ shrink: true }}
                    placeholder=""
                    error={Boolean(errors.numero && errors.numero.message)}
                    helperText={
                      touchedFields.numero || errors.numero
                        ? errors.numero?.message
                        : undefined
                    }
                    {...field}
                    value={getValues('numero')}
                  />
                )}
              />
            </Grid>
            <Grid item xs={8}>
              <Controller
                name="complemento"
                control={control}
                render={({ field }) => (
                  <TextFieldSaurus
                    tipo={'TEXTO'}
                    disabled={props.loading}
                    fullWidth
                    variant="outlined"
                    label="Complemento"
                    InputLabelProps={{ shrink: true }}
                    placeholder="Ex: Apartamento 111"
                    error={Boolean(
                      errors.complemento && errors.complemento.message,
                    )}
                    helperText={
                      touchedFields.complemento || errors.complemento
                        ? errors.complemento?.message
                        : undefined
                    }
                    {...field}
                    value={getValues('complemento')}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="bairro"
                control={control}
                render={({ field }) => (
                  <TextFieldSaurus
                    tipo={'TEXTO'}
                    disabled={props.loading}
                    fullWidth
                    variant="outlined"
                    label="Bairro"
                    InputLabelProps={{ shrink: true }}
                    error={Boolean(errors.bairro && errors.bairro.message)}
                    helperText={
                      touchedFields.bairro || errors.bairro
                        ? errors.bairro?.message
                        : undefined
                    }
                    {...field}
                    value={getValues('bairro')}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={7}>
              <Controller
                name="municipio"
                control={control}
                render={({ field }) => (
                  <TextFieldSaurus
                    tipo={'TEXTO'}
                    disabled={props.loading}
                    fullWidth
                    variant="outlined"
                    label="Município"
                    InputLabelProps={{ shrink: true }}
                    error={Boolean(
                      errors.municipio && errors.municipio.message,
                    )}
                    helperText={
                      touchedFields.municipio || errors.municipio
                        ? errors.municipio?.message
                        : undefined
                    }
                    {...field}
                    value={getValues('municipio')}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <Controller
                name="cMunicipio"
                control={control}
                render={({ field }) => (
                  <TextFieldSaurus
                    tipo={'NUMERO'}
                    disabled={props.loading}
                    fullWidth
                    variant="outlined"
                    label="Cód. Município"
                    InputLabelProps={{ shrink: true }}
                    error={Boolean(
                      errors.cMunicipio && errors.cMunicipio.message,
                    )}
                    helperText={
                      touchedFields.cMunicipio || errors.cMunicipio
                        ? errors.cMunicipio?.message
                        : undefined
                    }
                    {...field}
                    value={getValues('cMunicipio')?.toString()}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6} sm={2}>
              <Controller
                name="uf"
                control={control}
                render={({ field }) => (
                  <SelectSaurus
                    disabled={props.loading}
                    conteudo={UFMock}
                    fullWidth
                    variant="outlined"
                    label={'UF'}
                    {...field}
                    value={UFMock.find(uf => uf.Value === getValues('uf'))?.Key}
                    onChange={(event) => {
                      if (event) {
                        const item = UFMock.filter(
                          (item) => item.Key === event.target.value,
                        )[0];
                        if (item) {
                          setValue('uf', item.Value);
                        }
                      }
                    }}
                  />
                )}
              />
            </Grid>
            {process.env.REACT_APP_LABEL as string !== 'FARMA' && <>
              <Grid item xs={6} md={4}>
                <Controller
                  name="cnae"
                  control={control}
                  render={({ field }) => (
                    <TextFieldSaurus
                      disabled={props.loading}
                      fullWidth
                      autoComplete='new-password'
                      variant="outlined"
                      placeholder=""
                      label="CNAE"
                      InputLabelProps={{ shrink: true }}
                      error={Boolean(errors.cnae && errors.cnae.message)}
                      helperText={
                        touchedFields.cnae || errors.cnae
                          ? errors.cnae?.message
                          : undefined
                      }
                      {...field}
                      value={getValues('cnae')}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6} md={4}>
                <Controller
                  name="segmento"
                  control={control}
                  render={({ field }) => (
                    <AutocompleteSegmento
                      disabled={props.loading}
                      loadingExterno={props.loading}
                      label="Segmento"
                      error={Boolean(errors.segmento && errors.segmento.message)}
                      helperText={
                        touchedFields.segmento || errors.segmento
                          ? errors.segmento?.message
                          : undefined
                      }
                      {...field}
                      onChange={(retorno) => {
                        if (retorno.isNewVal) {
                          setValue('segmento', retorno.value);
                          setValue('segmentoId', guidEmpty());
                        } else if (!retorno.isString) {
                          const segmento = picker<SegmentoModel>(
                            retorno.value,
                            new SegmentoModel(),
                          );
                          setValue('segmento', segmento.descricao);
                          setValue('segmentoId', segmento.id);
                        } else {
                          setValue('segmento', '');
                        }
                      }}
                      value={getValues('segmento')}
                    />
                  )}
                />
              </Grid>
            </>}
            <Grid item xs={12} md={process.env.REACT_APP_LABEL as string !== 'FARMA' ? 4 : 12}>
              <Controller
                name="regime"
                control={control}
                render={({ field }) => (
                  <SelectSaurus
                    disabled={props.loading}
                    conteudo={RegimeMock}
                    fullWidth
                    variant="outlined"
                    label={'Regime Atual'}
                    {...field}
                    onChange={(event) => {
                      if (event) {
                        setValue(
                          'regime',
                          RegimeMock.filter(
                            (item) => item.Key === event.target.value,
                          )[0]?.Key,
                        );
                      }
                    }}
                    value={getValues('regime')}
                  />
                )}
              />
            </Grid>
          </Grid>
          <input name="cnae" type="hidden" value={getValues('cnae')}></input>
          <Button style={{ display: 'none' }} type="submit"></Button>
        </form>
      </div>
    </>
  );
});
