import { useCallback, useEffect, useState, useRef } from 'react';
import { VoltarIcon } from 'views/components/icons';
import { useModalStyles } from 'views/components/modals/utils/modal-styles';
import { useToastSaurus, useCadastros } from 'services/app';
import { ModalHeader } from 'views/components/modals/components/modal-header/modal-header';
import { ButtonModalHeader } from 'views/components/controles/buttons/button-modal-header/button-modal-header';
import classNames from 'classnames';
import {
  UploadCargaPessoa,
  UploadCargaProduto,
} from 'model/api/gestao/upload-carga/upload-carga-model';
import { EnumUploadCargaTipo } from 'model/enums/enum-upload-carga-tipo';
import { FormUploadCargaItemProdutoVizualizar } from 'views/components/form/upload-carga/form-upload-carga-item-produto-vizualizar/from-upload-carga-item-produto-vizualizar';
import { FormUploadCargaItemParceiroVizualizar } from 'views/components/form/upload-carga/form-upload-carga-item-parceiro-vizualizar/from-upload-carga-item-parceiro-vizualizar';
import { CardCargaResumo } from 'views/components/cards/card-carga-resumo/card-carga-resumo';
import { Typography } from 'views/design-system';

export const UploadCargaItemVisualizacao = (props: { model: UploadCargaPessoa | UploadCargaProduto, tipo: EnumUploadCargaTipo }) => {
  const { showToast } = useToastSaurus();
  const [preenchendoTela, setPreenchendoTela] = useState(true);
  const [stateProduto, setStateProduto] = useState<UploadCargaProduto>();
  const [stateParceiro, setStateParceiro] = useState<UploadCargaPessoa>();

  const classes = useModalStyles();

  const { fecharCadastroUploadCarga } =
    useCadastros();

  const refForm = useRef<any>(undefined);


  useEffect(() => {
    (async () => {
      try {
        setPreenchendoTela(true);
        if (
          props.tipo === EnumUploadCargaTipo.PESSOA
        ) {
          setStateParceiro(props.model as UploadCargaPessoa);
          refForm.current = props.model;
        } else {
          setStateProduto(props.model as UploadCargaProduto);
          refForm.current = props.model;
        }

        setPreenchendoTela(false);
      } catch (e: any) {
        showToast('error', e.message);
        setPreenchendoTela(false);
      }
    })();
  }, [showToast, props.tipo, props.model]);

  const onCloseClick = useCallback(() => {
    fecharCadastroUploadCarga();
  }, [fecharCadastroUploadCarga]);

  return (
    <div className={classes.root}>
      <ModalHeader
        title={'Detalhes do Item'}
        leftArea={
          <ButtonModalHeader
            tooltip="Voltar"
            icon={<VoltarIcon tipo="MODAL_HEADER" />}
            onClick={onCloseClick}
          />
        }
      />
      <div className={classes.content}>
        <div
          className={classNames(
            classes.contentForms,
            preenchendoTela ? classes.contentFormsLoading : '',
          )}
        >
          {props.tipo ===
            EnumUploadCargaTipo.PRODUTO &&
            stateProduto && (
              <FormUploadCargaItemProdutoVizualizar
                model={stateProduto}
                loading={false}
              />
            )}

          {props.tipo ===
            EnumUploadCargaTipo.PESSOA &&
            stateParceiro && (
              <FormUploadCargaItemParceiroVizualizar
                model={stateParceiro}
                loading={false}
              />
            )}

          {stateProduto && stateProduto.status && (
            <Typography variant="h6">Informações Complementares</Typography>
          )}

          {stateParceiro && stateParceiro.status && (
            <Typography variant="h6">Informações Complementares</Typography>
          )}

          {props.tipo ===
            EnumUploadCargaTipo.PESSOA && stateParceiro &&
            <CardCargaResumo model={stateParceiro.status} />
          }

          {props.tipo ===
            EnumUploadCargaTipo.PRODUTO &&
            stateProduto &&
            <CardCargaResumo model={stateProduto.status} />
          }
        </div>
      </div>
    </div>
  );
};
export default UploadCargaItemVisualizacao;
