import {
  Grid,
  Typography,
  Divider,
  Button,
  Box,
  Tooltip
} from 'views/design-system';
import { useCallback, useEffect, useState, useRef, useMemo } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { toDecimalString } from 'utils/to-decimal';
import { EditarIcon, GarcomIcon, MapaIcon } from 'views/components/icons';
import { ImpressoraIcon } from 'views/components/icons/impressora-icon';
import { PessoasMesaIcon } from 'views/components/icons/pessoas-mesa-icon';
import { SacoDinheiroIcon } from 'views/components/icons/saco-dinheiro-icon';
import { ProdutosListData } from './components/produtos-list/produtos-list-data';
import { useStyles } from './mov-visualizar-pedido-styles';
import { CarrinhoIcon } from 'views/components/icons/carrinho-icon';
import {
  PedidoProdutoModel,
  PedidoProdutoModelPost
} from 'model/api/gestao/pedido/pedido-produto-model';
import { ComandasModel } from 'model/api/gestao/comanda/comanda-model';
import { MesasModel } from 'model/api/gestao/mesa/mesa-model';
import { MesaIcon } from 'views/components/icons/mesa-icon';
import { CircularLoading } from 'views/components';
import { useCadastros, useToastSaurus } from 'services/app';
import { useSessaoAtual } from 'services/app';
import { PedidoModel } from 'model/api/gestao/pedido/pedido-model';
import { usePutProdutoPedido } from 'data/api/gestao/pedido/put-produto-pedido';
import { EnumStatusProdutoPedido } from 'model/enums/enum-status-produto-pedido';
import { usePutPedidoDados } from 'data/api/gestao/pedido-dados/put-pedido-dados';
import { useConfirm } from 'material-ui-confirm';
import { usePutPedidoStatus } from 'data/api/gestao/pedido-dados/put-pedido-status';
import { EnumStatusPedido } from 'model/enums/enum-status-pedido';
import { guidEmpty } from 'utils/guid-empty';
import { usePedidoLocal } from 'services/app/hooks/pedido-local';
import { useMovAtual } from 'services/app/hooks/mov-atual';
import { TouchoneDBPrimary } from 'database/touchone-database';
import { EnumTipoTrabalho } from 'model/enums/enum-tipo-trabalho';
import { usePDV } from 'services/app/hooks/pdv';
import { EnumModeloDeTrabalho } from 'model/enums/enum-modelo-de-trabalho';
import { isPlanoControleProducao, isPlanoFarmaceutico } from 'utils/plano-utils';
import { calcPercent } from 'utils/calc-percent';
import { roundTo } from 'utils/round-to';
import { qualPercent } from 'utils/qual-percent';
import { isPlanoControleMesasComandas } from 'utils/plano-utils';
import { EnumIndDesperdicio } from 'model/enums/enum-ind-desperdicio';
import { isEmpty } from 'lodash';
import { useCadastroPadrao } from 'services/app/hooks/cadastro-padrao';
import { EnumPDVConfigCod } from 'model/enums/enum-pdv-config';
import { useThemeQueries } from 'views/theme';
import classNames from 'classnames';
import { EnumCodigosPermissoes } from 'model/enums/enum-codigos-permissoes';
import { PessoaIcon } from 'views/components/icons/pessoa-icon';
import { formatarCPFCNPJ } from 'utils/cpfcnpj-format';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { PedidoModelComanda } from 'model/api/gestao/pedido/pedido-comanda';
import { EnumTpProduto } from 'model/enums/enum-tp-produto';
import { useContratoAtual } from 'services/app/hooks/contrato-atual';
import { EnumContratoConfig } from 'model/enums/enum-contrato-config';
import { usePedidoDelivery } from 'services/app/hooks/pedido-delivery';
import { LinkExternoIcon } from 'views/components/icons/link-externo-icon';
import { completarEndereco } from 'utils/completar-endereco';
import { EnumTipoPedido } from 'model/enums/enum-tipo-pedido';
import { DeliveryIcon } from 'views/components/icons/delivery-icon';
import { CarrinhoDadosEndereco } from './components/carrinho-dados-endereco/carrinho-dados-endereco';
import { MenuVerticalIcon } from 'views/components/icons/menu-vertical-icon';
import { MovRotasMock } from 'data/mocks/mov-rotas-mock';
import { useMovRota } from 'services/app/hooks/mov-rota';
import { useVendaHeaderStore } from 'views/components/headers/venda-header/stores/venda-header-store';

interface ParamsProps {
  tipoTrabalhoId: string;
}

export type TipoTelaPedido = 'mesa' | 'comanda' | 'balcao'

export interface LocationProps {
  pedido?: PedidoModelComanda
  mesa: any
  comanda: PedidoModelComanda | null
  balcao: PedidoModelComanda | null
}

export const MovVisualizarPedidoPage = () => {
  const { getEmpresaSelecionada, plano, getPermissaoBoolean } =
    useSessaoAtual();

  // STATES E REFS
  const [pedidoAtual, setPedidoAtual] = useState<PedidoModel>(new PedidoModel());
  const [carregandoInterno, setCarregandoInterno] = useState<boolean>(false)
  const { isMobile, theme } = useThemeQueries()
  const [comanda, setComanda] = useState<ComandasModel>(new ComandasModel());
  const [mesa, setMesa] = useState<MesasModel>(new MesasModel(''));
  const refProduto = useRef<PedidoProdutoModel>(new PedidoProdutoModel());
  const refDialogIndicadorDesperdicio = useRef<boolean>(false);
  const [hasProdServico, setHasProdServico] = useState<boolean>(false);
  const [isBalcao, setIsBalcao] = useState<boolean>(false)
  const { redirectLancadorPadrao } = useMovRota();
  const controleMesasComandas = isPlanoControleMesasComandas(plano?.plano);

  // CHAMADAS API
  const { putProdutoPedido, carregando: carregandoPutproduto } =
    usePutProdutoPedido();
  const { putPedidoDados, carregando: carregandoPutPedidoDados } =
    usePutPedidoDados();
  const { putPedidoStatus, carregando: carregandoPutPedidoStatus } =
    usePutPedidoStatus();

  // HOOKS
  const {
    imprimirConsumo,
    getConfiguracoesMesaEComanda,
    carregarInfoPedido,
    carregando: carregandoLocalHook,
    formatToPedidoDadosPut
  } = usePedidoLocal();
  const {
    carregarInfoPedido: carregarInfoPedidoDelivery,
    carregando: carregandoDeliveryHook
  } = usePedidoDelivery()
  const { getProdutoServico } = useCadastroPadrao();
  const {
    abrirSolicitarPermissao,
    abrirMovFechamentoDialog,
    abrirDialogAlterarQuantidadeClientes,
    abrirDialogMovEmAndamento,
    abrirDialogIndicacaoDeDesperdicio,
    abrirDialogTaxaServico,
    abrirDialogAcoesPedidos,
    abrirProdutoMotivoCancelamento
  } = useCadastros();
  const { setPedidoExistente, getMov, transformarPedidosEmVendaSimples } =
    useMovAtual();
  const { getConfigByCod } = usePDV();
  const { addHandler, removeHandler } = useEventTools();
  const { showToast } = useToastSaurus();
  const { getConfigByCod: getConfigContrato } = useContratoAtual()

  // AUX
  const isControleProducao = isPlanoControleProducao(plano?.plano);
  const { tipoTrabalhoId } = useParams<ParamsProps>();
  const prodEntregaId = getConfigContrato(EnumContratoConfig.ProdutoEntrega)
  const classes = useStyles();
  const history = useHistory();
  const confirm = useConfirm();
  const modeloTrabalho = getConfigByCod(EnumPDVConfigCod.ModeloTrabalho);
  const location = useLocation<LocationProps>()
  const isFarmaceutico = isPlanoFarmaceutico(plano?.plano);

  const isTipoTrabalhoMesa =
  getConfiguracoesMesaEComanda()?.tipoTrabalho === EnumTipoTrabalho.MESA || location.state.mesa?.idMesa;

  const isDelivery = useMemo(() => {
    let modo = new URLSearchParams(location.search).get('modo') as TipoTelaPedido
    const locationState = location?.state
    const hasComandaId = !isEmpty(locationState?.comanda) || modo === 'comanda'
    const hasMesa = !isEmpty(locationState?.mesa) || modo === 'mesa'
    const hasBalcao = !isEmpty(locationState?.balcao) || modo === 'balcao'
    if (hasMesa || hasComandaId || hasBalcao) {
      return false
    }

    return true
  }, [location.search, location?.state])

  const isSomentePedido = [
    EnumModeloDeTrabalho.LANCADOR_SEM_FECHAMENTO_DE_VENDAS
  ].includes(modeloTrabalho as EnumModeloDeTrabalho);

  const isApenasCaixa = modeloTrabalho === EnumModeloDeTrabalho.APENAS_CAIXA;

  const carregando =
    [carregandoPutproduto,
      carregandoPutPedidoDados,
      carregandoPutPedidoStatus,
      carregandoLocalHook,
      carregandoInterno,
      carregandoDeliveryHook].includes(true)

  const getComanda = useCallback(async () => {
    const comandaEncontrada = await TouchoneDBPrimary.comandas.get({
      id: tipoTrabalhoId
    });

    if (comandaEncontrada) {
      setComanda(comandaEncontrada as ComandasModel);
    }
  }, [tipoTrabalhoId]);

  const getMesa = useCallback(async (mesaId) => {
    const mesaEncontrada = await TouchoneDBPrimary.mesas.get({
      id: mesaId
    });

    if (mesaEncontrada) {
      setMesa(mesaEncontrada as MesasModel);
    }
  }, []);

  const getPedidosInfoWrapper = useCallback(async () => {
    let modo = new URLSearchParams(location.search).get('modo') as TipoTelaPedido
    try {

      const pedidoState = location?.state?.pedido
      if (isDelivery) {
        const pedido = await carregarInfoPedidoDelivery(pedidoState?.id ?? '')
        if (pedido) {
          setPedidoAtual(pedido)
        }
        return
      }
      
      const isComanda = modo === 'comanda' || !isTipoTrabalhoMesa
      const pedido = await carregarInfoPedido(tipoTrabalhoId, isComanda, modo === 'balcao');
      setIsBalcao(modo === 'balcao')
      if (pedido) {
        setPedidoAtual(pedido);
        if (pedido.mesaId) {
          await getMesa(pedido?.mesaId ?? '');
        }
      }
      if (pedido?.comandaId) {
        await getComanda();
      }

      if (!pedido && isTipoTrabalhoMesa) {
        await getMesa(tipoTrabalhoId);
      }
    } catch (e: any) {
      showToast('error', 'Erro ao atualizar o pedido, detalhe: ' + e.message)
    }
  }, [location.search, location?.state?.pedido, isDelivery, isTipoTrabalhoMesa, carregarInfoPedido, tipoTrabalhoId, carregarInfoPedidoDelivery, getMesa, getComanda, showToast]);
  
  const isPedidoEntrega = pedidoAtual.tipoPedido.codigo === EnumTipoPedido.ENTREGA;

  const onVoltar = useCallback(async () => {
    if (isFarmaceutico) {
      history.push(MovRotasMock.pedidosRota);
      return
    }
    if (isDelivery) {
      return history.push(MovRotasMock.pedidosDeliveryRota);
    }
    if (
      isBalcao
    ) {
      return history.push(MovRotasMock.pedidosBalcao)
    }
    if (
      getConfiguracoesMesaEComanda()?.tipoTrabalho ===
      EnumTipoTrabalho.MESA
    ) {
      return history.push(MovRotasMock.pedidosMesas);
    }

    if (
      getConfiguracoesMesaEComanda()?.tipoTrabalho ===
      EnumTipoTrabalho.COMANDA
    ) {
      const mesas = getConfiguracoesMesaEComanda()?.qtdeMesa ?? 0;

      if (
        mesas > 0 &&
        pedidoAtual?.mesaId &&
        pedidoAtual?.mesaId !== guidEmpty()
      ) {
        return history.push(
          `${MovRotasMock.mesaEComandaRota}/${pedidoAtual.mesaId}`
        );
      }
      return history.push(MovRotasMock.pedidosComandas);
    }

    history.goBack();
  }, [getConfiguracoesMesaEComanda, history, isBalcao, isDelivery, isFarmaceutico, pedidoAtual.mesaId])

  const retHeaderTitle = useCallback(() => {
    const modo = new URLSearchParams(location.search).get('modo') as TipoTelaPedido;
    if (isDelivery) {
      return 'Pedido #' + pedidoAtual.codigoPedido
    }
    switch (modo) {
      case 'balcao':
        return !isEmpty(pedidoAtual.identificador) ? `Identificador: ${pedidoAtual.identificador}` : 'Balcão';
      case 'comanda':
        return `Comanda ${comanda.codigoComanda}`;
      case 'mesa':
        return `Mesa ${mesa.codigo}`
    }
  }, [comanda.codigoComanda, isDelivery, location.search, mesa.codigo, pedidoAtual.codigoPedido, pedidoAtual.identificador])

  const produtoServicoWrapper = useCallback(async () => {
    const servico = await getProdutoServico();

    if (servico && servico.ativo) {
      setHasProdServico(true);
    }
  }, [getProdutoServico]);

  const reloadRefindicacoes = useCallback(() => {
    refDialogIndicadorDesperdicio.current = false;
  }, []);

  const setHeaderProps = useVendaHeaderStore(state => state.setHeaderProps);

  useEffect(() => {
    const mostrarOpcoes = !isApenasCaixa
    setHeaderProps({
      title: retHeaderTitle(),
      voltar: {
        onClick: onVoltar,
      },
      customButton: mostrarOpcoes ? [{
        icon: <MenuVerticalIcon
          tipo="PRIVATE_HEADER"
        />,
        onClick() {
          abrirDialogAcoesPedidos(
            pedidoAtual,
            getPedidosInfoWrapper
          );
        },
      }] : undefined,
    })
  }, [abrirDialogAcoesPedidos, getPedidosInfoWrapper, isApenasCaixa, isMobile, onVoltar, pedidoAtual, retHeaderTitle, setHeaderProps, theme.palette.grey])

  useEffect(() => {
    addHandler(AppEventEnum.RefIndicacaoDeDesperdicio, reloadRefindicacoes);
    return () => {
      removeHandler(
        AppEventEnum.RefIndicacaoDeDesperdicio,
        reloadRefindicacoes
      );
    };
  }, [addHandler, reloadRefindicacoes, removeHandler, setHeaderProps]);

  useEffect(() => {
    getPedidosInfoWrapper();
  }, [getPedidosInfoWrapper]);

  useEffect(() => {
    produtoServicoWrapper();
  }, [produtoServicoWrapper]);

  const onCardInativarProduto = useCallback(
    async (
      produto: PedidoProdutoModel,
      desperdicio: EnumIndDesperdicio = EnumIndDesperdicio.NaoSeAplica
    ) => {
      try {
        if (isControleProducao && !refDialogIndicadorDesperdicio.current) {
          refProduto.current = produto;
          abrirDialogIndicacaoDeDesperdicio(onCardInativarProduto, refProduto);
          refDialogIndicadorDesperdicio.current = true;
          return;
        }

        const prod = {
          ...produto,
          status: EnumStatusProdutoPedido.DESISTENCIA,
          indDesperdicio: desperdicio,
          tpProduto: produto.tpProduto.codigo,
          subItens: []
        } as PedidoProdutoModelPost;

        const prodsGroup = pedidoAtual.produtos
          .filter((x) => x.groupId === prod.codigoReferencia)
          .map((x) => {
            const prod = {
              ...x,
              status: EnumStatusProdutoPedido.DESISTENCIA,
              indDesperdicio: desperdicio,
              tpProduto: x.tpProduto.codigo,
              subItens: []
            } as PedidoProdutoModelPost;

            return prod;
          });

        if (!isEmpty(prodsGroup)) {
          const prodsInativar = [prod, ...prodsGroup];
          let produtosAtualizados: PedidoProdutoModel[] = [];
          for await (const produto of prodsInativar) {
            const respostaPut = await putProdutoPedido(
              getEmpresaSelecionada()?.Id ?? '',
              pedidoAtual.id ?? '',
              produto
            );

            if (respostaPut.isTimeout) {
              await getPedidosInfoWrapper();
              return;
            }

            if (respostaPut.erro) {
              throw new Error(respostaPut.erro);
            }

            const produtoAtualizado = respostaPut.resultado
              ?.data as PedidoProdutoModel;

            produtosAtualizados.push(produtoAtualizado);
          }

          setPedidoAtual((prev) => {
            return {
              ...prev,
              produtos: prev.produtos.map((prod) => {
                const mesmoProduto = produtosAtualizados.find(
                  (x) => x.id === prod.id
                );

                if (mesmoProduto && !isEmpty(mesmoProduto)) {
                  return mesmoProduto;
                }

                return prod;
              })
            };
          });

          return;
        }

        const respostaPut = await putProdutoPedido(
          getEmpresaSelecionada()?.Id ?? '',
          pedidoAtual.id ?? '',
          prod
        );

        if (respostaPut.isTimeout) {
          await getPedidosInfoWrapper();
          return;
        }

        if (respostaPut.erro) {
          throw new Error(respostaPut.erro);
        }

        const produtoAtualizado = respostaPut.resultado
          ?.data as PedidoProdutoModel;

        setPedidoAtual((prev) => {
          return {
            ...prev,
            produtos: prev.produtos.map((prod) => {
              if (prod.id === produtoAtualizado.id) {
                return produtoAtualizado;
              }

              return prod;
            })
          };
        });
      } catch (err: any) {
        showToast('error', err.message);
      }
    },
    [abrirDialogIndicacaoDeDesperdicio, getEmpresaSelecionada, getPedidosInfoWrapper, isControleProducao, pedidoAtual.id, pedidoAtual.produtos, putProdutoPedido, showToast]
  );

  const handleInativarProduto = useCallback((
    produto: PedidoProdutoModel,
    desperdicio: EnumIndDesperdicio = EnumIndDesperdicio.NaoSeAplica
  ) => {
    abrirProdutoMotivoCancelamento(
      async (motivo) => await onCardInativarProduto({ ...produto, motivoCancelamento: motivo }, desperdicio)
    )
  }, [abrirProdutoMotivoCancelamento, onCardInativarProduto])

  const checarPermissaoInativar = useCallback(
    async (
      produto: PedidoProdutoModel,
      desperdicio: EnumIndDesperdicio = EnumIndDesperdicio.NaoSeAplica
    ) => {
      //checa se tem permissão para cancelar itens, se tiver envia um callback pro dialog pedir permissão e continuar o processo
      if (!getPermissaoBoolean(EnumCodigosPermissoes.CANCELAMENTO_ITENS)) {
        abrirSolicitarPermissao(
          async () => {
            handleInativarProduto(produto, desperdicio);
          },
          EnumCodigosPermissoes.CANCELAMENTO_ITENS,
          'cancelar o produto'
        );
        return;
      }
      //caso tenha permissão segue o fluxo normalmente
      handleInativarProduto(produto, desperdicio);
    },
    [abrirSolicitarPermissao, getPermissaoBoolean, handleInativarProduto]
  );

  const alterarQtdePessoas = useCallback(
    async (quantidade: number) => {
      try {
        const pedido = formatToPedidoDadosPut(pedidoAtual)
        pedido.quantidadePessoas = quantidade
        const respostaPut = await putPedidoDados(
          getEmpresaSelecionada()?.Id ?? '',
          pedido
        );

        if (respostaPut.erro) {
          throw new Error(respostaPut.erro);
        }

        const pedidoAlterado = respostaPut?.resultado?.data as PedidoModel;
        setPedidoAtual((prev) => {
          return {
            ...prev,
            quantidadePessoas: pedidoAlterado.quantidadePessoas
          };
        });
      } catch (err: any) {
        showToast('error', err.message);
      }
    },
    [formatToPedidoDadosPut, getEmpresaSelecionada, pedidoAtual, putPedidoDados, showToast]
  );

  const imprimir = useCallback(async () => {
    try {
      imprimirConsumo(pedidoAtual.id, pedidoAtual.codigoReferencia ? pedidoAtual.codigoReferencia : '');
    } catch (err: any) {
      showToast('error', err.message);
    }
  }, [imprimirConsumo, pedidoAtual.codigoReferencia, pedidoAtual.id, showToast]);

  const fecharPedido = useCallback(async () => {
    try {
      setCarregandoInterno(true)
      const respostaPutStatus = await putPedidoStatus(
        getEmpresaSelecionada()?.Id ?? '',
        pedidoAtual.id,
        EnumStatusPedido.FECHADO
      );

      if (respostaPutStatus.erro) {
        throw new Error(
          `Erro ao alterar status do pedido para Fechado. Detalhe: ${respostaPutStatus.erro}`
        );
      }

      setPedidoAtual((prev) => {
        return {
          ...prev,
          statusPedido: {
            codigo: EnumStatusPedido.FECHADO,
            descricao: 'Em Fechamento'
          }
        };
      });

      setTimeout(() => {
        imprimir();
      }, 500)
    } catch (err: any) {
      showToast('error', err.message);
    }
    finally {
      setCarregandoInterno(false)
    }
  }, [
    getEmpresaSelecionada,
    imprimir,
    pedidoAtual.id,
    putPedidoStatus,
    showToast
  ]);

  const importarPedido = useCallback(async () => {
    try {
      const respostaPutStatus = await putPedidoStatus(
        getEmpresaSelecionada()?.Id ?? '',
        pedidoAtual.id,
        EnumStatusPedido.IMPORTADO
      );

      if (respostaPutStatus.erro) {
        throw new Error(
          `Erro ao alterar status do pedido para Importado. Detalhe: ${respostaPutStatus.erro}`
        );
      }

      await transformarPedidosEmVendaSimples([pedidoAtual]);
      return history.push('/venda-simples/carrinho');
    } catch (err: any) {
      showToast('error', err.message);
    }
  }, [
    getEmpresaSelecionada,
    history,
    pedidoAtual,
    putPedidoStatus,
    showToast,
    transformarPedidosEmVendaSimples
  ]);

  const lancarItem = useCallback(async () => {
    try {
      const temProdutoNaMovAtual = getMov();

      if (!temProdutoNaMovAtual) {
        showToast('error', 'Não existe uma movimentação em andamento');
        return history.push('/venda-simples/landing');
      }

      if (temProdutoNaMovAtual.produtos.length > 0) {
        abrirDialogMovEmAndamento(getMov()?.produtos.length ?? 0);
        return;
      }

      await setPedidoExistente({
        pedidoId: pedidoAtual.id,
        comandaId: pedidoAtual.comandaId === guidEmpty() ? '' : pedidoAtual.comandaId,
        mesaId: pedidoAtual.mesaId === guidEmpty() ? mesa.id : pedidoAtual.mesaId,
        salaoId: pedidoAtual.salaoId === guidEmpty() ? mesa.salaoId : pedidoAtual.salaoId,
        codigoMesa: mesa ? mesa.codigo : '',
        codigoComanda: comanda ? comanda.codigoComanda : '',
        codigoPedido: pedidoAtual.codigoPedido,
        identificador: pedidoAtual.identificador || "",
        clienteId: pedidoAtual.cliente.referenceId
      });

      await redirectLancadorPadrao();
    } catch (err: any) {
      showToast('error', err.message);
    }
  }, [getMov, setPedidoExistente, pedidoAtual.id, pedidoAtual.comandaId, pedidoAtual.mesaId, pedidoAtual.salaoId, pedidoAtual.codigoPedido, pedidoAtual.identificador, pedidoAtual.cliente.referenceId, mesa, comanda, redirectLancadorPadrao, showToast, history, abrirDialogMovEmAndamento]);

  const valorTotal = useMemo(() => {
    return pedidoAtual.produtos
      .filter(
        (p) =>
          (p.status.codigo === EnumStatusProdutoPedido.DISPONIVEL ||
            p.status.codigo === EnumStatusProdutoPedido.TROCADO) &&
          p.indFin && p.tpProduto.codigo !== EnumTpProduto.Combo &&
          p.produtoIdReferencia !== prodEntregaId
      )
      .reduce((acc, prev) => acc + prev.valorTotal, 0);
  }, [pedidoAtual.produtos, prodEntregaId])

  const valorTotalDelivery = useMemo(() => {
    return pedidoAtual.produtos
      .filter(
        (p) =>
          (p.status.codigo === EnumStatusProdutoPedido.DISPONIVEL ||
            p.status.codigo === EnumStatusProdutoPedido.TROCADO) &&
          p.produtoIdReferencia === prodEntregaId &&
          p.indFin
      )
      .reduce((acc, prev) => acc + prev.valorTotal, 0);
  }, [pedidoAtual.produtos, prodEntregaId])

  const valoresTaxa = useCallback(
    (option: 'taxa' | 'total') => {
      let valor = 0;
      const produtos = pedidoAtual.produtos.filter(
        (p) =>
          p.status.codigo === EnumStatusProdutoPedido.DISPONIVEL && p.indFin
      );

      for (let i = 0; i < produtos.length; i++) {
        if (produtos[i].taxaServico > 0) {
          valor +=
            calcPercent(produtos[i].valorTotal, produtos[i].taxaServico)

        } else if (produtos[i].valorTotalServico > 0) {
          valor += produtos[i].valorTotalServico;
        }
      }
      if (option === 'total') {
        return roundTo(valor);
      }

      const valorTotalCobraTaxa = pedidoAtual.produtos
        .filter(
          (p) =>
            (p.status.codigo === EnumStatusProdutoPedido.DISPONIVEL ||
              p.status.codigo === EnumStatusProdutoPedido.TROCADO) &&
            p.indFin && p.taxaServico > 0
        )
        .reduce((acc, prev) => acc + prev.valorTotal, 0);

      return roundTo(qualPercent(valor, valorTotalCobraTaxa));
    },
    [pedidoAtual.produtos]
  );

  const handleFechamento = useCallback(() => {
    abrirMovFechamentoDialog(fecharPedido, importarPedido);
  }, [abrirMovFechamentoDialog, fecharPedido, importarPedido]);

  const enderecoCliente = pedidoAtual.enderecoEntrega

  const mapsGoogleUrl = `https://maps.google.com/?q=${enderecoCliente ? enderecoCliente.enderecoCompleto : ''}`;

  const handleTaxaEntrega = () => {
    history.push({
      pathname: '/venda-simples/entrega-frete',
      state: {
        pedido: pedidoAtual,
        fromPedidoPage: true,
      }
    })
  }

  return (
    <>
      {carregando && <CircularLoading tipo="FULLSIZED" />}
      <Grid className={classes.root}>
        <Grid className={classes.containerInfo}>
          {!isPedidoEntrega && (
            <Button
              style={{
                background: '#fff',
                padding: '4px',
                borderRadius: '5px',
                marginRight: '8px',
                display: 'relative'
              }}
              onClick={() => {
                if (!isApenasCaixa && pedidoAtual.id !== guidEmpty()) {
                  abrirDialogAlterarQuantidadeClientes(
                    alterarQtdePessoas,
                    pedidoAtual.quantidadePessoas,
                    true
                  );
                } else {
                  showToast(
                    'error',
                    'Não é possível alterar a quantidade de pessoas, pois não há pedido.'
                  );
                }
              }}
            >
              <Grid flex mr={1} >
                <Grid className={classes.containerIcon}>
                  <PessoasMesaIcon
                    fill={theme.palette.primary.main}
                    style={{ height: '40px' }}
                  />
                </Grid>
                <Grid flex flexDirection='column' alignItems='flex-start'>
                  <Typography className={classes.textTitle}>Pessoas</Typography>
                  <Grid flex justifyContent='space-between' alignItems='center' className={classes.fullWidth}>
                    <Typography className={classes.textInfo}>
                      <strong>{pedidoAtual.quantidadePessoas ?? 0}</strong>
                    </Typography>
                    {!isApenasCaixa && (
                      <EditarIcon
                        fill={theme.palette.primary.main}
                        style={{ height: '16px', margin: 0 }}
                      />
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Button>
          )}
          {mesa.id && !isTipoTrabalhoMesa && (
            <Grid className={classes.whiteCard}>
              <Grid className={classes.containerIcon}>
                <MesaIcon
                  fill={theme.palette.primary.main}
                  style={{ height: '40px' }}
                />
              </Grid>
              <Grid flex flexDirection='column' alignItems='flex-start'>
                <Typography className={classes.textTitle}>Mesa</Typography>
                <Typography>
                  <strong>{mesa.id ? mesa.codigo : 'Sem mesa'}</strong>
                </Typography>
              </Grid>
            </Grid>
          )}
          {pedidoAtual.cliente.cpfCnpj !== '99999999000191' && (
            <Box mr={1}>
              <Grid className={classes.whiteCard}>
                <Grid className={classes.containerIcon}>
                  <PessoaIcon
                    fill={theme.palette.primary.main}
                    style={{ height: '40px' }}
                  />
                </Grid>
                <Grid flex flexDirection='column' alignItems='flex-start'>
                  <Typography className={classes.textTitle}>
                    {!isEmpty(pedidoAtual.cliente.cpfCnpj)
                      ? formatarCPFCNPJ(pedidoAtual.cliente.cpfCnpj)
                      : 'Cliente'}
                  </Typography>
                  <Typography>
                    <strong>{pedidoAtual.cliente.nomeFantasia}</strong>
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          )}
          {(isPedidoEntrega && enderecoCliente && enderecoCliente.cep) && (
            <Box mt={isMobile ? 1 : 0}>
              <Button
                style={{
                  background: '#fff',
                  padding: '4px',
                  borderRadius: '5px',
                  marginRight: '8px',
                  display: 'relative',
                  height: '100%'
                }}
                onClick={() => window.open(mapsGoogleUrl, '_blank')}
              >
                <Grid className={classes.containerIcon}>
                  <MapaIcon
                    fill={theme.palette.primary.main}
                    style={{ height: '40px' }}
                  />
                </Grid>
                <Grid flex flexDirection='column' alignItems='flex-start'
                  style={{ textAlign: 'initial' }}
                >
                  <Typography className={classes.textTitle}>
                    Endereço
                  </Typography>
                  <Grid flex justifyContent='space-between' alignItems='center' className={classes.fullWidth}>
                    <Typography variant="body2" weight={600}>
                      {completarEndereco({
                        bairro: enderecoCliente?.bairro || '',
                        municipio: enderecoCliente?.municipio || '',
                        rua: enderecoCliente?.logradouro || '',
                        numero: enderecoCliente?.numero,
                        uf: enderecoCliente?.uf || '',
                        complemento: enderecoCliente?.complemento,
                        cep: enderecoCliente?.cep || ''
                      })}
                    </Typography>
                    <Box ml={1} style={{ minWidth: 20 }}>
                      <LinkExternoIcon
                        fill={theme.palette.primary.main}
                        style={{ height: '16px', margin: 0 }}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Button>
            </Box>
          )}
        </Grid>

        <Grid py={0} px={1} >
          <Typography variant="body1">
            <b>Produtos</b>
          </Typography>
          <Divider className={classes.divider} />
        </Grid>
        <Grid className={classes.list}>
          <div className={classes.defaultContainer}>
            <Grid className={classes.containerListCard}>
              <ProdutosListData
                produtos={pedidoAtual.produtos.filter(p => p.produtoIdReferencia !== prodEntregaId) ?? []}
                carregando={false}
                inativarProduto={checarPermissaoInativar}
                statusPedido={pedidoAtual.statusPedido.codigo}
              />
            </Grid>
          </div>
        </Grid>

        {pedidoAtual.statusPedido.codigo !== EnumStatusPedido.IMPORTADO && (
          <Grid className={classes.containerAcao}>
            <Grid container spacing={1}>
              <Grid container item xs={12} spacing={1} className={classes.containerInfo}>
                {isPedidoEntrega && (
                  <Grid item xs={12}>
                    <CarrinhoDadosEndereco pedido={pedidoAtual} />
                  </Grid>
                )}
                <Grid item xs={6} sm={6} md={'auto'}>
                  <Grid className={classes.whiteCard}>
                    <Grid className={classes.containerIcon}>
                      <SacoDinheiroIcon
                        fill={theme.palette.primary.main}
                        style={{ height: '40px' }}
                      />
                    </Grid>
                    <Grid>
                      <Typography className={classes.textTitle}>
                        Valor total
                      </Typography>
                      <Typography>
                        <strong>R$ {toDecimalString(valorTotal)}</strong>
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                {
                  controleMesasComandas
                    && hasProdServico
                    && !isPedidoEntrega
                    && !isFarmaceutico
                    ? (
                      <Grid item xs={6} sm={3} md={'auto'}>
                        <Button
                          fullWidth
                          className={classNames(classes.buttonTaxaServico)}
                          onClick={() => {
                            if (
                              pedidoAtual.produtos.filter(
                                (x) =>
                                  x.status.codigo ===
                                  EnumStatusProdutoPedido.DISPONIVEL
                              ).length <= 0
                            ) {
                              showToast(
                                'info',
                                'Adicione algum produto para alterar a taxa.'
                              );
                              return;
                            }
                            abrirDialogTaxaServico(pedidoAtual, getPedidosInfoWrapper);
                          }}
                        >
                          <Grid className={classes.containerIcon}>
                            <GarcomIcon
                              fill={theme.palette.primary.main}
                              style={{ height: '40px' }}
                            />
                          </Grid>
                          <Grid className={classes.taxaServicoContainer}>
                            <Typography className={classes.textTitle}>
                              Taxa de Serviço
                            </Typography>
                            <Grid flex justifyContent='space-between' alignItems='center' className={classes.fullWidth}>
                              <Tooltip title="Este valor é calculado pelos produtos que cobram a taxa de serviço." arrow>
                                <Box pr={1}>
                                  <Typography>
                                    <strong>
                                      R$ {toDecimalString(valoresTaxa('total'))} (
                                      {pedidoAtual.taxaDeServico}%)
                                    </strong>
                                  </Typography>
                                </Box>
                              </Tooltip>
                              <EditarIcon
                                fill={theme.palette.primary.main}
                                style={{ height: '16px', margin: 0 }}
                              />
                            </Grid>
                          </Grid>
                        </Button>
                      </Grid>
                    ) : undefined}
                {isPedidoEntrega && (
                  <Grid item xs={6} sm={6} md={'auto'}>
                    {/* TODO: Voltar com a possibilidade de edição da taxa */}
                    <Button
                      fullWidth
                      className={classNames(classes.buttonTaxaServico)}
                      onClick={handleTaxaEntrega}
                    >
                      <Grid className={classes.containerIcon}>
                        <DeliveryIcon
                          fill={theme.palette.primary.main}
                          style={{ height: '40px' }}
                        />
                      </Grid>
                      <Box pr={1}>
                        <Typography className={classes.textTitle}>
                          Taxa de Entrega
                        </Typography>
                        <Typography align='right'>
                          <strong>R$ {toDecimalString(valorTotalDelivery)}</strong>
                        </Typography>
                      </Box>
                      <EditarIcon
                        fill={theme.palette.primary.main}
                        style={{ height: '16px', margin: 0 }}
                      />
                    </Button>
                  </Grid>
                )}
              </Grid>
              <Grid item xs={6}>
                {pedidoAtual.statusPedido.codigo ===
                  EnumStatusPedido.FECHADO ? (
                  <Button
                    disabled={pedidoAtual.id === guidEmpty() ? true : false}
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={imprimir}
                    style={{ height: '56px' }}
                    className="round"
                  >
                    <ImpressoraIcon tipo="BUTTON_PRIMARY" />
                    Reimprimir
                  </Button>
                ) : (
                  <Button
                    disabled={
                      valorTotal === 0 || pedidoAtual.id === guidEmpty()
                        ? true
                        : false
                    }
                    fullWidth
                    variant="contained"
                    className={classNames(classes.buttonfechamento, 'round')}
                    onClick={() => {
                      handleFechamento();
                    }}
                    style={{ height: '56px' }}
                  >
                    <ImpressoraIcon tipo="BUTTON_PRIMARY" />
                    Fechamento / Finalização
                  </Button>
                )}
              </Grid>
              <Grid item xs={6}>
                <>
                  {pedidoAtual.statusPedido.codigo ===
                    EnumStatusPedido.FECHADO ? (
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      disabled={isSomentePedido}
                      onClick={() =>
                        confirm({
                          title: 'Deseja finalizar esse pedido?',
                          description:
                            'Após finalizar pedido, não será mais possivel lançar produtos.',
                          cancellationText: 'Voltar',
                          confirmationButtonProps: {
                            variant: 'contained',
                            color: 'primary'
                          },
                          confirmationText: 'Confirmar'
                        }).then(importarPedido)
                      }
                      style={{ height: '56px' }}
                      className="round"
                    >
                      <CarrinhoIcon tipo="BUTTON_PRIMARY" />
                      Finalizar
                    </Button>
                  ) : (
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      onClick={lancarItem}
                      style={{ height: '56px' }}
                      className="round"
                      disabled={isApenasCaixa}
                    >
                      <CarrinhoIcon tipo="BUTTON_PRIMARY" />
                      Lançar item
                    </Button>
                  )}
                </>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
};
