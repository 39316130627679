import { CardEmpresaProps } from './card-empresa-props';
import { DefaultCard } from '../components/default-card';
import { useDefaultCardStyles } from '../components/default-card/default-card-styles';
import { formatarCPFCNPJ } from 'utils/cpfcnpj-format';
import { AvancarIcon, EmpresaIcon } from 'views/components/icons';
import { useStyles } from './card-empresa-styles'
import { useThemeQueries } from 'views/theme';
import { CaracteresString } from 'utils/caracteres-string';
import { Tooltip, Typography } from 'views/design-system';
export const CardEmpresa = ({
  model,
  onClick,
  onCheck,
  selected,
}: CardEmpresaProps) => {
  const classes = useDefaultCardStyles();
  const classesCard = useStyles()
  const { xs } = useThemeQueries()

  return (
    <>
      <DefaultCard
        isSelected={selected}
        onClick={async () => {
          onClick(model.id);
        }}
      >
        <div className={classes.cardContent}>
          <div className={classesCard.cardIcon}>
            <EmpresaIcon tipo='BUTTON' />
          </div>
          <div className={classes.celulaGridFull}>
            <Typography
              className={`celula-grid-value, ${classesCard.titleCard}`}
              color="primary"
            >
              {
                xs
                  ? CaracteresString(model.nomeFantasia, 26)
                  : model.nomeFantasia
              }
            </Typography>
            <Typography color="textPrimary" className={classesCard.subtitleCard}>
              {formatarCPFCNPJ(model.cpfcnpj)}
            </Typography>
          </div>
        </div>
        <Tooltip arrow title="Selecionar empresa">
          <div className={classesCard.cardRightButton}>
            <AvancarIcon tipo="BUTTON" />
          </div>
        </Tooltip>
      </DefaultCard>
    </>
  );
};
