import { Grid, Typography } from 'views/design-system';
import { memo, useCallback, useEffect, useMemo, useRef } from 'react';
import { useKeyboard } from 'services/app/hooks/keyboard';
import { toDecimal, toDecimalString } from 'utils/to-decimal';
import { Keyboard } from 'views/components/keyboard/keyboard';
import { TecladoFuncoesFragmentProps } from './teclado-funcoes-fragment-props';
import { useStyles } from './teclado-funcoes-fragment-styles';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { EnumTpTecladoFuncoes } from '../../teclado-funcoes-props';

const TecladoFuncoesFragment = (
    props: TecladoFuncoesFragmentProps,
) => {
    const digitado = useRef<boolean>(false)
    const textoAtual = useRef<string>('')
    const valueRef = useRef<HTMLParagraphElement | null>(null);

    const {
        getFormattedText,
        sendText,
        resetText,
        addKey,
        backSpaceKey,
    } = useKeyboard({
        maxLength: 7,
        floatCases: 2,
        handleTextChanged: props.textChanged,
        isNumeric: true,
        digitado: digitado,
        textoAtual: textoAtual
    });
    const { addHandler, removeHandler } = useEventTools()

    const attValueDisplay = useCallback(() => {
        const v = getFormattedText();
        if (valueRef.current) {
            valueRef.current.textContent = v;
        }
    }, [getFormattedText])

    const attDisplay = useCallback(({ valor }: { valor?: string }) => {
        resetText(valor || '0')
    }, [resetText])

    useEffect(() => {
        addHandler(AppEventEnum.AlterarVersao, attValueDisplay)
        addHandler(AppEventEnum.AlterarDisplayKeybordPayment, attDisplay)

        return () => {
            removeHandler(AppEventEnum.AlterarVersao, attValueDisplay)
            removeHandler(AppEventEnum.AlterarDisplayKeybordPayment, attDisplay)
        }
    }, [addHandler, attValueDisplay, removeHandler, attDisplay]);


    const classes = useStyles();

    const handleText = useCallback(
        (value: string) => {
            sendText(value);
        },
        [sendText],
    );

    const handleAdd = useCallback(() => {
        addKey();
    }, [addKey]);

    const handleBackSpace = useCallback(() => {
        backSpaceKey();
    }, [backSpaceKey]);

    const handleEscape = useCallback(() => {
        resetText('');
    }, [resetText]);

    const valorTela = useCallback(() => {
        const decimal = toDecimal(getFormattedText(), 2) === 0
            ? (props.vPessoa ?? 0)
            : toDecimal(getFormattedText(), 2);

        return props.tipo === EnumTpTecladoFuncoes.Inteiro ? getFormattedText() : toDecimalString(decimal, 2)
    }, [getFormattedText, props.tipo, props.vPessoa]);

    const teclado = useMemo(() => {
        return (
            <Grid container style={{ flex: 1, overflowY: 'auto' }}>
                <Keyboard
                    isButtonKeyboard00
                    isButtonBackspace
                    handleAdd={handleAdd}
                    handleBackSpace={handleBackSpace}
                    handleText={handleText}
                    handleEscape={handleEscape}
                    isNumeric
                />
            </Grid>
        );
    }, [
        handleAdd,
        handleBackSpace,
        handleEscape,
        handleText,
    ]);

    return (
        <>
            <Grid container flex flexDirection="column" className={classes.container}>
                <Grid className={classes.middleContainer}>
                    <Grid container className={classes.middleContent}>
                        <Grid item>
                            <div className={classes.valorContainer}>
                                <div className="valorMonetario">
                                    {props.tipo !== EnumTpTecladoFuncoes.Inteiro && (
                                        <Typography className="unidade" variant="body1">
                                            R$
                                        </Typography>
                                    )}
                                    <Typography className="valor" variant="h3" ref={valueRef}>
                                        {valorTela()}
                                    </Typography>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
                {teclado}
            </Grid>
        </>
    );
};

export default memo(TecladoFuncoesFragment);
