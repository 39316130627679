import { AppEventEnum } from "model/enums/enum-app-event";
import { useCallback, useEffect, useState } from "react";
import { useEventTools } from "services/app/hooks/events/event-tools";
import { IdentificarComandaModal, IdentificarComandaModalProps } from "views/components/modals/identificar-comanda/identificar-comanda-modal";

export const IdentificarComandaModalRender = () => {
    const { addHandler, removeHandler } = useEventTools();
    const [modalState, setModalState] = useState<IdentificarComandaModalProps>({
        callback: () => false,
        id: '',
        openned: false
    });

    const modalAlterado = useCallback(({ callback, openned }: IdentificarComandaModalProps) => {
        setModalState(prev => ({
            ...prev,
            openned,
            callback
        }));
    }, [])

    useEffect(() => {
        addHandler(AppEventEnum.IdentificarComanda, modalAlterado);
        return () => {
            removeHandler(AppEventEnum.IdentificarComanda, modalAlterado);
        };
    }, [addHandler, modalAlterado, removeHandler]);

    return (
        <>
            <IdentificarComandaModal {...modalState} />
        </>
    )
}