import { Checkbox } from "@material-ui/core";
import { useMemo } from "react";
import { toDateString } from "utils/to-date";
import { toDecimalString } from "utils/to-decimal";
import { useDefaultCardStyles, DefaultCard } from "views/components/cards/components";
import { useStyles } from "./card-styles";
import { EnumTpModeloApuracao } from "model/enums/enum-tp-modelo-apuracao";
import { useApuracaoStore } from "../../stores/apuracao-store";
import { useComissaoStore } from "views/components/modals/comissao/comissao-modal/stores/comissao-store";
import { IVendaComissao } from "views/components/modals/comissao/comissao-modal/stores/types/IVendaComissao";
import { Box, Grid, Tooltip, Typography } from "views/design-system";

export const ApuracaoComissaoVendaCard = (props: {
    venda: IVendaComissao;
    onClick?: (venda: IVendaComissao) => void;
}) => {
    const classes = useDefaultCardStyles();
    const cardClasses = useStyles();
    const blackList = useApuracaoStore(state => state.stateComissaoApuracao.blackListVendas)

    const isBlackList = useMemo(() => {
        return blackList.includes(props.venda.id)
    }, [blackList, props.venda.id])
    const comissaoApuracaoAcaoApurar = useApuracaoStore(state => state.stateComissaoApuracao.ui.acaoApurarComissionado)
    const comissao = useComissaoStore(state => state.state.comissao)
    return <>
        <DefaultCard
            disabled={isBlackList}
            onClick={async () => {
                if (props.onClick) {
                    props.onClick(props.venda)
                }
            }}
        >
            <Box p={1} style={{
                width: '100%'
            }}>
                <Grid container spacing={1} className={cardClasses.cardContent}>
                    <Grid item xs={8}>
                        <Box flex>
                            <div className={cardClasses.celulaGrid}>
                                <Typography color="textPrimary" variant="caption">
                                    Produto
                                </Typography>
                                <Typography
                                    className={'celula-grid-value'}
                                    color="textPrimary"
                                    variant="body1"
                                >
                                    {
                                        props.venda.produto
                                    }
                                </Typography>
                            </div>
                        </Box>
                    </Grid>
                    <Grid item xs={4} >
                        <div className={cardClasses.celulaGrid}>
                            <Typography color="textPrimary" variant="caption">
                                Venda
                            </Typography>
                            <Typography
                                className={'celula-grid-value'}
                                color="textPrimary"
                                variant="body1"
                            >
                                {
                                    props.venda.venda
                                }
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item xs={4}>
                        <div className={cardClasses.celulaGrid}>
                            <Typography color="textPrimary" variant="caption">
                                Data Lançamento
                            </Typography>
                            <Typography
                                className={'celula-grid-value'}
                                color="textPrimary"
                                variant="body1"
                            >
                                {
                                    toDateString(props.venda.dtLancamento)
                                }
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item xs={4} >
                        <div className={cardClasses.celulaGrid}>
                            <Typography color="textPrimary" variant="caption">
                                Comissão
                            </Typography>
                            <Typography
                                className={'celula-grid-value'}
                                color="textPrimary"
                                variant="body1"
                            >
                                R${
                                    toDecimalString(props.venda.valorComissao)
                                }
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item xs={4}>
                        <Tooltip title={
                            comissao.modeloApuracao === EnumTpModeloApuracao.Lucro ?
                                `Valor Venda: R$${toDecimalString(props.venda.valorVenda)} - 
                                Valor Custo: R$${toDecimalString(props.venda.custo)}` : ''
                        }
                        >

                            <div className={cardClasses.celulaGrid}>
                                <Typography color="textPrimary" variant="caption">
                                    {
                                        comissao.modeloApuracao === EnumTpModeloApuracao.Lucro ? 'Lucro' : 'Valor Venda'
                                    }
                                </Typography>
                                <Typography
                                    className={'celula-grid-value'}
                                    color="textPrimary"
                                    variant="body1"
                                >
                                    R${toDecimalString(
                                        comissao.modeloApuracao === EnumTpModeloApuracao.Lucro ? props.venda.valorVenda - props.venda.custo : props.venda.valorVenda
                                    )}
                                </Typography>
                            </div>
                        </Tooltip>
                    </Grid>
                </Grid>
            </Box>
            {
                comissaoApuracaoAcaoApurar === 'deletando' && (

                    <Tooltip arrow title={
                        isBlackList ? 'Retornar' : 'Desconsiderar'
                    }>
                        <div className={classes.cardRightButton} style={{
                            display: 'flex',
                        }}>
                            <Checkbox
                                color="primary"
                                checked={isBlackList}
                            />

                        </div>
                    </Tooltip>

                )
            }

        </DefaultCard>
    </>
}