import { EnumPagTpMod } from 'model';
import { toDateString } from 'utils/to-date';
import { toDecimalString } from 'utils/to-decimal';
import { CardPagamentoMovProps } from './card-pagamento-mov-props';
import { useStyles } from './card-pagamento-mov-styles';
import {
  BoletoIcon,
  CartaoIcon,
  ChequeIcon,
  CreditoLojaIcon,
  DepositoBancarioIcon,
  DinheiroIcon,
  DuplicataMercantilIcon,
  QrCodeIcon,
  CartaoFidelidadeIcon,
  SemDinheiroIcon,
  TransferenciaBancariaIcon,
  ValeAlimentacaoIcon,
  ValeCombustivelIcon,
  ValePresenteIcon,
} from 'views/components/icons';
import { useCallback } from 'react';
import { useThemeQueries } from 'views/theme';
import { POSIcon } from 'views/components/icons/pos-icon';
import { Grid, Typography } from 'views/design-system';
import { tpPagamentoResolver } from 'utils/tp-pagamento-resolver';

export const CardPagamentoMov = ({ pagamento }: CardPagamentoMovProps) => {
  const classes = useStyles();

  const paymentMod = tpPagamentoResolver(pagamento.modPagamento, pagamento.tpTransacao)?.Value || '';

  const { theme } = useThemeQueries();

  const cardIcon = useCallback(() => {
    switch (pagamento.modPagamento) {
      case EnumPagTpMod.BOLETO_BANCARIO:
        return <BoletoIcon tipo="BUTTON" fill={theme.palette.primary.main} />;
      case EnumPagTpMod.CARTAO_CREDITO:
      case EnumPagTpMod.CARTAO_DEBITO:
        return <CartaoIcon tipo="BUTTON" fill={theme.palette.primary.main} />;
      case EnumPagTpMod.CHEQUE:
        return <ChequeIcon tipo="BUTTON" fill={theme.palette.primary.main} />;
      case EnumPagTpMod.CREDITO_LOJA:
        return (
          <CreditoLojaIcon tipo="BUTTON" fill={theme.palette.primary.main} />
        );
      case EnumPagTpMod.DEPOSITO_BANCARIO:
        return (
          <DepositoBancarioIcon
            tipo="BUTTON"
            fill={theme.palette.primary.main}
          />
        );
      case EnumPagTpMod.DINHEIRO:
        return <DinheiroIcon tipo="BUTTON" fill={theme.palette.primary.main} />;
      case EnumPagTpMod.DUPLICATA_MERCANTIL:
        return (
          <DuplicataMercantilIcon
            tipo="BUTTON"
            fill={theme.palette.primary.main}
          />
        );
      case EnumPagTpMod.PAGAMENTO_INSTANTANEO:
        return <QrCodeIcon tipo="BUTTON" fill={theme.palette.primary.main} />;
      case EnumPagTpMod.PROGRAMA_FIDELIDADE:
        return (
          <CartaoFidelidadeIcon
            tipo="BUTTON"
            fill={theme.palette.primary.main}
          />
        );
      case EnumPagTpMod.SEM_PAGAMENTO:
        return (
          <SemDinheiroIcon tipo="BUTTON" fill={theme.palette.primary.main} />
        );
      case EnumPagTpMod.TRANSFERENCIA_BANCARIA:
        return (
          <TransferenciaBancariaIcon
            tipo="BUTTON"
            fill={theme.palette.primary.main}
          />
        );
      case EnumPagTpMod.VALE_ALIMENTACAO:
      case EnumPagTpMod.VALE_REFEICAO:
        return (
          <ValeAlimentacaoIcon tipo="BUTTON" fill={theme.palette.primary.main} />
        );
      case EnumPagTpMod.VALE_COMBUSTIVEL:
        return (
          <ValeCombustivelIcon tipo="BUTTON" fill={theme.palette.primary.main} />
        );
      case EnumPagTpMod.VALE_PRESENTE:
        return (
          <ValePresenteIcon tipo="BUTTON" fill={theme.palette.primary.main} />
        );

      default:
        return <POSIcon tipo="BUTTON" fill={theme.palette.primary.main} />;
    }
  }, [pagamento.modPagamento, theme.palette.primary.main]);


  return (
    <Grid className={classes.cardContainer}>
      <Grid container className={classes.cardContent}>
        <Grid item xs={1} className={classes.containerInstallments}>
          {cardIcon()}
        </Grid>
        <Grid item xs={4} className={classes.containerType}>
          <Typography
            color="textSecondary"
            variant="caption"
          >
            Modelo
          </Typography>
          <Typography
            className={classes.textCardPag}
            color="primary"
          >
            {paymentMod}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography
            color="textSecondary"
            variant="caption"
          >
            Hora
          </Typography>
          <Typography
            color="textPrimary"
            variant="body1"
          >
            {toDateString(pagamento.dhTransacao, 'HH:mm')}
          </Typography>
        </Grid>
        <Grid item xs={1} className={classes.containerInstallments}>
          {(pagamento.modPagamento === EnumPagTpMod.BOLETO_BANCARIO ||
            pagamento.modPagamento === EnumPagTpMod.CARTAO_CREDITO ||
            pagamento.modPagamento === EnumPagTpMod.DUPLICATA_MERCANTIL ||
            pagamento.modPagamento === EnumPagTpMod.CREDITO_LOJA) && (
              <Typography
                className={classes.textCard}
                color="textPrimary"
                variant="body1"
              >
                {pagamento.nParcelas + 1}x
              </Typography>
            )}
        </Grid>

        <Grid item xs={4} className={classes.containerValue}>
          <Typography
            color="textSecondary"
            variant="caption"
          >
            Valor Pago
          </Typography>
          <Typography
            className={classes.textCardPag}
            color="textPrimary"

            variant="body1"
          >
            R$ {toDecimalString(pagamento.vPag, 2)}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};
