import { Tooltip, Typography } from "views/design-system";
import { styles } from "./consumption-chart-styles";

interface PropsConsumptionChart {
  total: number;
  consumed: number;
  quantidade?: boolean
}

const ConsumptionChart = ({ total, consumed, quantidade }: PropsConsumptionChart) => {
  const classes = styles();
  const percent = (total: number, consumed: number): string => {
    return ((consumed * 100) / total).toFixed(1).replace(".", ",");
  };

  return (
    <>
      <Tooltip title={`Consumido: ${consumed} de ${total}`} arrow>
        {quantidade ?
          <>
            <Typography className={classes.textConsumption} variant="caption">
              <strong>{`Consumido ${consumed} de ${total}`}</strong>
            </Typography>
            <div className={classes.containerProgress}>
              <div className={classes.progress} style={{ width: percent(total, consumed).replace(",", ".") + '%' }}></div>
            </div>
          </>
          :
          <div className={classes.container}>
            <Typography className={classes.textConsumption} variant="h5">
              {percent(total, consumed)}%
            </Typography>
            <div className={classes.percent} style={{ width: percent(total, consumed).replace(",", ".") + '%' }}></div>
          </div>
        }
      </Tooltip>
    </>
  );
};

export { ConsumptionChart };
