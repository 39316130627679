import { guidEmpty } from "../../../../utils/guid-empty";

export class UsuariosConvidarModel {
    constructor(
        public empresaId: string = guidEmpty(),
        public perfilId: string = guidEmpty(),
        public email: string = '',
        public pessoaId?: string,
        public apelido?: string,
    ) { }
}
