import { AppEventEnum } from "model/enums/enum-app-event";
import { useCallback, useEffect, useRef, useState } from "react";
import { useEventTools } from "services/app/hooks/events/event-tools";
import { useKeyboard } from "services/app/hooks/keyboard";
import { Keyboard } from "views/components/keyboard/keyboard"
import { useStyles } from '../pessoa-cadastro-steps-styles'
import { EnumCadastroTipo } from "model";
import { AvancarIcon, CancelarIcon, VoltarIcon } from "views/components/icons";
import { isEmpty } from "lodash";
import { SelectSaurus } from "views/components/controles/selects/select-saurus/select-saurus";
import { InfoDocTeclado, tiposComDocProfissionalObrigatorio } from "../pessoa-cadastro-steps";
import { useToastSaurus } from "services/app";
import { TextFieldSaurus } from "views/components/controles/inputs";
import { TipoDocumentoOrgaoExpedidorEnum } from "model/enums/enum-orgao-expedidor";
import { TipoDocumentoOrgaoExpedidorMock } from "data/mocks/orgao-expedidor-mock";
import { UFMock } from "data/mocks";
import { Controller, useForm } from "react-hook-form";
import * as Yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";
import classNames from "classnames";
import { NumberIcon } from "views/components/icons/number-icon";
import { useTourSaurus } from "services/app/hooks/tour-saurus";
import { EnumTour } from "model/enums/enum-tour";
import { Box, Button, Grid, Typography } from "views/design-system";

const validationInfo = Yup.object().shape({
    orgaoExpedidor: Yup.number().required('Informe o Órgão Expedidor'),
    ufExpedicao: Yup.string().required('Informe a UF'),
    dataValidade: Yup.string().required('Informe a data de validade'),
    dataExpedicao: Yup.string().required('Informe a data de expedição'),
})

interface PessoaCadastroTecladoProps {
    submitDocumento: (cpfCnpj: string, isObrigatorio: boolean, info?: InfoDocTeclado) => void;
    handleAvancar: () => void;
    handleVoltar: () => void;
    tipo: EnumCadastroTipo;
    valorPadrao?: string;
    infoPadrao?: InfoDocTeclado;
    ufPadrao?: string;
}

export const PessoaCadastroTecladoDocumento = ({
    submitDocumento,
    handleAvancar,
    handleVoltar,
    tipo,
    valorPadrao,
    ufPadrao,
    infoPadrao = {
        orgaoExpedicao: tipo === EnumCadastroTipo.PRESCRITOR ? TipoDocumentoOrgaoExpedidorEnum.ConselhoRegionalMedicina : tipo === EnumCadastroTipo.RESPONSAVEL_TECNICO ? TipoDocumentoOrgaoExpedidorEnum.ConselhoRegionalFarmacia : TipoDocumentoOrgaoExpedidorEnum.ConselhoRegionalFarmacia,
        ufExpedicao: ufPadrao ?? '',
        dataValidade: '',
        dataExpedicao: '',
    }
}: PessoaCadastroTecladoProps) => {

    const { addHandler, removeHandler } = useEventTools();
    const classes = useStyles();
    const { showToast } = useToastSaurus();
    const [inputAlt, setInputAlt] = useState<boolean>(false)
    const isObrigatorio = tiposComDocProfissionalObrigatorio.includes(tipo)


    const digitado = useRef<boolean>(false);
    const textoAtual = useRef<string>('');
    const valueRef = useRef<HTMLInputElement | null>(null);
    const valueRefAlt = useRef<HTMLInputElement | null>(null);
    const documento = useRef<string>('');

    const {
        control,
        formState: { errors },
        getValues,
        setValue,
        trigger
    } = useForm<InfoDocTeclado>({
        defaultValues: infoPadrao,
        criteriaMode: 'all',
        mode: 'onChange',
        resolver: yupResolver(validationInfo)
    });

    const textChanged = useCallback(
        async (text: string, formattedText: string) => {
            documento.current = text;
            return true;
        },
        []
    );

    const submit = async () => {
        try {
            const infoDoc: InfoDocTeclado = {
                orgaoExpedicao: getValues('orgaoExpedicao'),
                ufExpedicao: getValues('ufExpedicao'),
                dataValidade: getValues('dataValidade'),
                dataExpedicao: getValues('dataExpedicao'),
            }

            submitDocumento(documento.current, isObrigatorio, infoDoc)
            return true
        } catch (e: any) {
            return false
        }
    }

    const {
        getFormattedText,
        addValue,
        sendText,
        addKey,
        submitKey,
        backSpaceKey,
        resetText
    } = useKeyboard({
        maxLength: 15,
        floatCases: 0,
        isNumeric: false,
        handleTextChanged: textChanged,
        handleSubmit: submit,
        digitado: digitado,
        textoAtual: textoAtual
    });

    const attValueDisplay = useCallback((any: any) => {
        const v = getFormattedText();
        if (valueRef.current) {
            if (v.length === 0) {
                valueRef.current.value = ''
                valueRef.current.textContent = isObrigatorio ? "Aguardando Digitação" : "Opcional"
                valueRef.current.className = valueRef.current!.className = classNames(classes.noValue, classes.placeholder);
                return
            }
            valueRef.current.value = v
            valueRef.current.textContent = v
            valueRef.current!.className = classNames(classes.hasValue, classes.placeholder)
        }
    }, [classes.hasValue, classes.noValue, classes.placeholder, getFormattedText, isObrigatorio])

    const handleAddValue = useCallback(
        (value: number) => {
            addValue(value);
        },
        [addValue],
    );

    const handleText = useCallback(
        (value: string) => {
            sendText(value);
        },
        [sendText],
    );

    const handleAdd = useCallback(() => {
        addKey();
    }, [addKey]);

    const handleFocus = useCallback(async () => {
        setInputAlt(!inputAlt)
        if (!inputAlt && valueRefAlt.current) {
            valueRefAlt.current.className = classNames(classes.noValue, classes.placeholder)
            valueRefAlt.current.value = ''
        }
        await resetText('')

    }, [classes.noValue, classes.placeholder, inputAlt, resetText])

    useEffect(() => {
        if (inputAlt && valueRefAlt.current) {
            setTimeout(() => { valueRefAlt.current?.focus() }, 500)
            if (valueRefAlt.current.setSelectionRange) {
                valueRefAlt.current.setSelectionRange(0, 0)
            }
        }
    }, [inputAlt])

    useEffect(() => {
        addHandler(AppEventEnum.AlterarVersao, attValueDisplay)
        return () => removeHandler(AppEventEnum.AlterarVersao, attValueDisplay)
    }, [addHandler, attValueDisplay, removeHandler])

    useEffect(() => {
        if (valorPadrao) {
            handleText(valorPadrao)
        }
        valueRef.current!.value = "Aguardando Digitação"
    }, [classes.noValue, classes.placeholder, handleText, tipo, valorPadrao])

    const {callTour} = useTourSaurus()
    
    useEffect(()=>{
        callTour(EnumTour.CADASTROPESSOASDOCUMENTO)
    },[callTour])

    return <Box className={classes.keyboardContainer}>
        <Grid container spacing={2} id="tour-form-pessoas-documento">
            <Grid item xs={9}>
                <Controller
                    control={control}
                    name='orgaoExpedicao'
                    render={({ field }) => (
                        <SelectSaurus
                            conteudo={TipoDocumentoOrgaoExpedidorMock.sort((a, b) => a.Value.localeCompare(b.Value))}
                            label='Órgão Expedidor'
                            {...field}
                            error={Boolean(errors.orgaoExpedicao)}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={3}>
                <Controller
                    control={control}
                    name='ufExpedicao'
                    render={({ field }) => (
                        <SelectSaurus
                            conteudo={UFMock}
                            label='UF'
                            {...field}
                            value={UFMock.find(uf => uf.Value === getValues('ufExpedicao'))?.Key}
                            onChange={(event) => {
                                if (event) {
                                    const item = UFMock.filter(
                                        (item) => item.Key === event.target.value,
                                    )[0];
                                    if (item) {
                                        setValue('ufExpedicao', item.Value);
                                    }
                                }
                            }}
                            error={Boolean(errors.ufExpedicao)}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={6}>
                <Controller
                    name="dataExpedicao"
                    control={control}
                    render={({ field }) => (
                        <TextFieldSaurus
                            tipo="DATA"
                            fullWidth
                            autoComplete={'off'}
                            label="Data de Expedição (Opcional)"
                            error={Boolean(errors.dataExpedicao && errors.dataExpedicao.message)}
                            helperText={
                                errors.dataExpedicao
                                    ? errors.dataExpedicao?.message
                                    : undefined
                            }
                            {...field}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={6}>
                <Controller
                    name="dataValidade"
                    control={control}
                    render={({ field }) => (
                        <TextFieldSaurus
                            tipo="DATA"
                            fullWidth
                            autoComplete={'off'}
                            label="Data de Validade (Opcional)"
                            error={Boolean(errors.dataValidade && errors.dataValidade.message)}
                            helperText={
                                errors.dataValidade
                                    ? errors.dataValidade?.message
                                    : undefined
                            }
                            {...field}
                        />
                    )}
                />
            </Grid>
        </Grid>

        <Box className={inputAlt ? classes.content : classes.hidden}>
            <Typography className={classes.title}>Informe a Inscrição</Typography>
            <Box className={classes.inputArea}>
                <TextFieldSaurus
                    variant="standard"
                    className={classes.placeholder}
                    inputRef={valueRefAlt}
                    tipo={'TEXTO'}
                    placeholder={isObrigatorio ? "Aguardando Digitação" : "Opcional"}
                    onChange={async (e) => {
                        if (isEmpty(e.target.value)) {
                            await resetText('')
                            await textChanged('', '')
                            valueRefAlt.current!.className = classNames(classes.noValue, classes.placeholder)
                            return
                        }
                        valueRefAlt.current!.className = classNames(classes.hasValue, classes.placeholder)
                        await resetText(e.target.value)
                        await textChanged(e.target.value, '')
                    }}
                    onFocus={(e) => {
                        if (e.target.value === 'Aguardando Digitação' || e.target.value === 'Opcional') {
                            valueRef.current!.value = ''

                        }
                    }}
                />
            </Box>
            <Box mt={2} >
                <Button color='primary' variant='outlined' fullWidth onClick={handleFocus}>
                    <NumberIcon tipo='BUTTON' />
                    Teclado Numérico
                </Button>
            </Box>
        </Box>

        <Box className={inputAlt ? classes.hidden : classes.content}>
            <Typography className={classes.title}>Informe a Inscrição</Typography>
            <Grid className={classes.inputArea}>
                <Typography className={classes.placeholder} ref={valueRef}>
                    {isObrigatorio ? "Aguardando Digitação" : "Opcional"}
                </Typography>
            </Grid>

        </Box>
        <Box className={classNames("teclado", inputAlt ? classes.hiddenKeyBoard : '')}>
            <Keyboard
                isNumeric={false}
                isButtonBackspace
                isButtonCaracters
                handleSubmit={submitKey}
                handleAddValue={handleAddValue}
                handleBackSpace={backSpaceKey}
                handleText={handleText}
                handleAdd={handleAdd}
                handleClickFocus={handleFocus}
            />
        </Box>

        {!isObrigatorio && <Box className={inputAlt ? classNames(classes.buttonContainer, classes.buttonsInputAlt) : classes.buttonContainer} style={{ marginBottom: '8px' }}>
            <Button color='primary' variant='outlined' onClick={handleVoltar}>
                <VoltarIcon tipo='BUTTON' />
                Voltar
            </Button>
        </Box>}
        <Box className={inputAlt ? classNames(classes.buttonContainer, classes.buttonsInputAlt) : classes.buttonContainer}>
            {!isObrigatorio && <Button color='primary' variant='outlined' onClick={() => {
                handleAvancar();
            }}>
                <CancelarIcon tipo='BUTTON' />
                Não Informar
            </Button>}
            {isObrigatorio && <Button color='primary' variant='outlined' onClick={handleVoltar}>
                <VoltarIcon tipo='BUTTON' />
                Voltar
            </Button>}
            <Button color='primary' variant='contained' onClick={async () => {
                if (isObrigatorio) {
                    if (isEmpty(documento.current)) {
                        showToast('error', 'Informe o Documento')
                        return
                    }
                }
                if (isEmpty(getValues('ufExpedicao'))) {
                    await trigger();
                    if (errors) {
                        return
                    }
                }
                submit()
            }}>
                <AvancarIcon tipo='BUTTON_PRIMARY' />
                Avançar
            </Button>
        </Box>
    </Box>
}