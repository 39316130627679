import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';
import { VariaveisAmbiente } from 'config';

export function useGetKeyStoreById() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const getKeyStoreById = useCallback(
        (id: string) => {
            return invocarApi({
                url: `/v1/KeyValueStore/${id}`,
                method: 'GET',
                enviarTokenUsuario: true,
                baseURL: `${VariaveisAmbiente.apiKeyStore}/api`
            })
        },
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        getKeyStoreById,
    };
}