import { useCallback, useState } from "react"
import { DialogHorarioAcesso } from "views/components/dialog/dialog-horario-acesso/dialog-horario-acesso"
import { ExpandIcon } from "views/components/icons"
import { useStyles } from './horarios-acesso-styles'
import { Button } from "views/design-system"

type Props = {
    perfilId: string
}

export const HorariosAcesso = ({ perfilId }: Props) => {
    const [dialog, setDialog] = useState<boolean>(false)

    const classes = useStyles()

    const abrirFecharDialog = useCallback(() => {
        setDialog(prev => !prev)
    }, [])

    return (
        <>
            <Button
                fullWidth
                color="secondary"
                variant="text"
                onClick={abrirFecharDialog}
                className={classes.button}>
                <div className={classes.alinharVertical}>
                    Horário de Acesso
                </div>
                <div className={classes.alinharVertical}>
                    <ExpandIcon
                        tipo="BUTTON"
                        fill="#666"
                        style={{
                            height: 30,
                            width: 30,
                            marginRight: 0,
                            transform: 'rotate(-90deg)'
                        }} />
                </div>
            </Button>
            <DialogHorarioAcesso
                perfilId={perfilId}
                openned={dialog}
                closeModal={abrirFecharDialog}
            />
        </>
    )
}