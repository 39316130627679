import { TextField } from "@material-ui/core"
import { EnumEntradaFiltroPagina } from "../../entrada-filtro-conteudo"
import { EntradaFiltroDataProps } from "./entrada-filtro-data-props"
import { useCallback } from "react"
import { useModalStyles } from "views/components/modals/utils/modal-styles"
import { OkIcon } from "views/components/icons"
import { toDate } from "utils/to-date"
import { useStyles } from "./entrada-filtro-data-styles"
import { Controller, useForm } from "react-hook-form"
import { Box, Button, Grid } from "views/design-system"

interface EntradaFiltroDataFormProps {
    dInicial: string
    dFinal: string
}
export const EntradaFiltroData = (props: EntradaFiltroDataProps) => {

    const dataFormatada = useCallback((val) => {
        const data = toDate(val) ?? new Date(val);
        const dia = data?.getDate();
        const mes = data?.getMonth() + 1;
        const ano = data?.getFullYear();

        const dataFormated = `${ano}-${mes?.toString().length === 1 ? '0' + mes : mes
            }-${dia?.toString().length === 1 ? '0' + dia : dia}`;
        return dataFormated;
    }, []);

    const {
        control,
        handleSubmit
    } = useForm<EntradaFiltroDataFormProps>({
        defaultValues: {
            dFinal: dataFormatada(props.vFiltro.dFinal),
            dInicial: dataFormatada(props.vFiltro.dInicial)
        }
    })

    const modalClasses = useModalStyles()
    const classes = useStyles()


    const submit = (values: EntradaFiltroDataFormProps) => {
        const { alterarFiltro } = props
        alterarFiltro('dInicial', values.dInicial ?? new Date())
        alterarFiltro('dFinal', values.dFinal ?? new Date())

        props.entrarPagina(EnumEntradaFiltroPagina.Inicial)
    }

    return (
        <form className={classes.formulario} onSubmit={handleSubmit(submit)}>
            <div className={modalClasses.content}>
                <Box p={2} className={classes.inputDate}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Controller
                                control={control}
                                name="dInicial"
                                render={({ field }) => (
                                    <TextField
                                        type={'date'}
                                        label="Data Inicial"
                                        className={classes.inputDate}
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Controller
                                control={control}
                                name="dFinal"
                                render={({ field }) => (
                                    <TextField
                                        type={'date'}
                                        label="Data Final"
                                        className={classes.inputDate}
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                </Box>
                <div className={modalClasses.acoes}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Button
                                variant="contained"
                                color="primary"
                                size="large"
                                type="submit"
                                fullWidth
                            >
                                <OkIcon tipo="BUTTON_PRIMARY" />
                                Confirmar
                            </Button>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </form>
    )
}