import { useTheme } from '@material-ui/core';
import { DialogSaurus } from '../dialog-saurus/dialog-saurus';
import { useStyles } from './dialog-alterar-numeracao-styles';
import { AtualizarIcon, VoltarIcon } from 'views/components/icons';
import { CircularLoading } from 'views';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { useRef } from 'react';
import { useMovAtual } from 'services/app/hooks/mov-atual';
import { EnumMovModelo } from 'model';
import { Box, Button, Grid, Typography } from 'views/design-system';

interface DialogAlterarNumeracaoProps {
  openned: boolean;
  loading: boolean;
  closeModal: () => void;
  enviarNumero: (e: any) => void;
  numero: number;
  setNumero: (numero: number) => void;
}

export const DialogAlterarNumeracao = ({
  openned,
  closeModal,
  enviarNumero,
  loading,
  numero,
  setNumero,
}: DialogAlterarNumeracaoProps) => {
  const classes = useStyles();
  const showContratar = enviarNumero;
  const refInput = useRef<HTMLDivElement | null>(null);
  const theme = useTheme();
  const { getMov } = useMovAtual();

  return (
    <DialogSaurus
      aberto={openned || false}
      titulo="Alterar Numeração"
      tamanho="xs"
    >
      {loading && <CircularLoading tipo="FULLSIZED" />}
      <Box className={classes.cardContainer}>
        <Box className={classes.cardContent}>
          <Box className={classes.mobileContent} >
            <form onSubmit={enviarNumero}>
              <Box className={classes.mobileInput}>
                <Typography className={classes.label}>
                  Digite o número da {getMov()?.mod === EnumMovModelo.NFE ? 'NF-e' : 'NFC-e'}
                </Typography>
                <TextFieldSaurus
                  inputRef={refInput}
                  tipo={'NUMERO'}
                  fullWidth
                  size="small"
                  allowSubmit={true}
                  value={numero}
                  placeholder="Exemplo: 51"
                  onChange={(e) => setNumero(Number(e.target.value))}
                  adornmentColor={theme.palette.text.disabled}
                />
              </Box>
              <Grid
                container
                spacing={2}
                style={{ marginTop: 16, marginBottom: 8 }}
              >
                <Grid item xs={4}>
                  {closeModal && (
                    <Button
                      disabled={loading}
                      variant="outlined"
                      fullWidth
                      color="primary"
                      onClick={() => {
                        closeModal();
                      }}
                    >
                      <VoltarIcon tipo="BUTTON" />
                      Voltar
                    </Button>
                  )}
                </Grid>
                <Grid item xs={8}>
                  <Button
                    disabled={!showContratar || loading}
                    variant="contained"
                    fullWidth
                    type="submit"
                    color="primary"
                  >
                    <AtualizarIcon tipo='BUTTON_PRIMARY' />
                    {'Alterar'}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Box>
      </Box>
    </DialogSaurus>
  );
};
