import { useCallback, useRef, useState } from 'react';
import { CircularLoading, makeUtilClasses, useThemeQueries } from 'views';
import React from 'react';
import {
  DefaultFormRefs,
  DefaultFormProps,
} from '../../utils/form-default-props';
import { RecuperarSenhaFormModel } from 'model/app';
import { useStyles } from './form-recuperar-senha-styles';
import { useFormRecuperarSenhaValidations } from './form-recuperar-senha-validation';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Grid } from 'views/design-system';

export const FormRecuperarSenha = React.forwardRef<
  DefaultFormRefs<RecuperarSenhaFormModel>,
  DefaultFormProps<RecuperarSenhaFormModel>
>((props: DefaultFormProps<RecuperarSenhaFormModel>, refs) => {
  const [tipo, setTipo] = useState<'EMAIL' | 'TELEFONE'>('EMAIL')
  const txtEmail = useRef<HTMLInputElement>();
  const { FormRecuperarSenhaYupValidations } =
    useFormRecuperarSenhaValidations(tipo);
  const utilClasses = makeUtilClasses();
  const classes = useStyles();
  const [model, setModel] = useState({
    modeloForm: new RecuperarSenhaFormModel(''),
  });
  const { isMobile } = useThemeQueries();

  const {
    handleSubmit,
    control,
    formState: { errors, touchedFields },
    reset,
    setValue
  } = useForm<RecuperarSenhaFormModel>({
    defaultValues: { ...model.modeloForm },
    resolver: yupResolver(FormRecuperarSenhaYupValidations),
    criteriaMode: 'all',
    mode: 'onChange',
  });

  const onSubmit = (form: RecuperarSenhaFormModel) => {
    props.onSubmit(form);
  };

  React.useImperativeHandle(refs, () => ({
    submitForm: async () => {
      await handleSubmit(onSubmit)();
    },
    resetForm: () => {
      setModel({ modeloForm: new RecuperarSenhaFormModel('') });
      reset();
      if (!isMobile) txtEmail?.current?.focus();
    },
    fillForm: (model: RecuperarSenhaFormModel) => {
      setModel({ modeloForm: model });
      reset({ ...model });
      if (!isMobile) txtEmail?.current?.focus();
    },
  }));

  const typeField = useCallback((e: any) => {
    setValue('email', e.target.value)

    if(/^[()\s\d-]+$/.test(e.target.value)){
      setTipo('TELEFONE')
    } else {
      setTipo('EMAIL')
    }
  }, [setValue])

  return (
    <>
      <div className={utilClasses.formContainer}>
        {props.loading && props.showLoading ? (
          <CircularLoading tipo="FULLSIZED" />
        ) : null}
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={`${classes.formContainer} ${
            props.loading ? utilClasses.controlLoading : ''
          }`}
        >
          <Grid container style={{ maxWidth: '100%' }}>
            <Grid item xs={12}>
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <TextFieldSaurus
                    tipo={tipo}
                    disabled={props.loading}
                    fullWidth={true}
                    showStartAdornment={true}
                    inputRef={txtEmail}
                    allowSubmit={true}
                    autoComplete={'off'}
                    label="E-mail / Telefone"
                    error={Boolean(errors.email && errors.email.message)}
                    helperText={
                      touchedFields.email || errors.email
                        ? errors.email?.message
                        : undefined
                    }
                    {...field}
                    onChange={typeField}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Button style={{ display: 'none' }} type="submit"></Button>
        </form>
      </div>
    </>
  );
});
