import { CardPagAntecipadoProps } from './card-pag-antecipado-props';
import { DefaultCard } from '../components/default-card';
import { Grid, Typography } from 'views/design-system';
import { toDecimalString } from 'utils/to-decimal';

export const CardPagAntecipado = ({
  model,
  onClick,
  selected,
}: CardPagAntecipadoProps) => {

  return (
    <>
      <DefaultCard
        isSelected={selected}
        onClick={async () => {
          onClick(model.id);
        }}
        notClickable
      >
        <Grid container spacing={2} mx={1} alignItems='center'>
          <Grid item xs>
            <Typography variant="caption" color="primary">
              Descrição
            </Typography>
            <Typography>
              {model.pagamentoDescricao}
            </Typography>
          </Grid>
          <Grid item xs={'auto'}>
            <Typography variant="caption" color="primary">
              Valor
            </Typography>
            <Typography weight={600}>
              R$ {toDecimalString(model.valorPago)}
            </Typography>
          </Grid>
        </Grid>
      </DefaultCard>
    </>
  );
};
