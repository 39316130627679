import { Fade } from '@material-ui/core';
import { useState, useCallback, useRef, useEffect } from 'react';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { SelectSaurus } from 'views/components/controles/selects/select-saurus/select-saurus';
import { ProcurarIcon } from 'views/components/icons';
import { useThemeQueries } from 'views';
import { PesquisaGerenciamentoSessoesProps } from './pesquisa-gerenciamento-sessoes-props';
import { useStyles } from './pesquisa-gerenciamento-sessoes-styles';
import { StatusSessaoGerenciamentoMock } from 'data/mocks/status-sessao-gerenciamento-mock';
import { Button, Grid } from 'views/design-system';

export const PesquisaGerenciamentoSessoes = (props: PesquisaGerenciamentoSessoesProps) => {
  const classes = useStyles();
  const { isMobile } = useThemeQueries();

  const dataAtual = new Date().toISOString().split('T')[0];

  const [selectedDateInicio, setSelectedDateInicio] = useState(dataAtual);
  const [selectedDateFim, setSelectedDateFim] = useState(dataAtual);
  const [statusPesquisar, setStatusPesquisar] = useState(-1);
  const refInput = useRef<HTMLDivElement | null>(null);

  //FOCO AO FICAR VISIVEL
  useEffect(() => {
    if (props.isOpened && !isMobile) refInput.current?.focus();
    else {
      setSelectedDateFim(dataAtual);
      setSelectedDateInicio(dataAtual);
      setStatusPesquisar(-1);
    }
  }, [dataAtual, isMobile, props.isOpened]);

  const handleDateInicioChange = (e: any) => {
    setSelectedDateInicio(e.target.value);
  };

  const handleDateFimChange = (e: any) => {
    setSelectedDateFim(e.target.value);
  };

  //CHAMADA DO PESQUISAR
  const pesquisar = useCallback(
    (event: any) => {
      event.preventDefault();
      props.onPesquisar({
        dInicial: selectedDateInicio,
        dFinal: selectedDateFim,
        status: statusPesquisar,
      });
    },
    [
      props,
      selectedDateFim,
      selectedDateInicio,
      statusPesquisar,
    ],
  );

  return (
    <Fade in={props.isOpened} mountOnEnter unmountOnExit>
      <form onSubmit={pesquisar}>
        <Grid container className={classes.root} spacing={1}>
          <Grid item xs={12} md={5}>
            <SelectSaurus
              allowSubmit
              size="small"
              conteudo={StatusSessaoGerenciamentoMock}
              variant="outlined"
              label="Situação"
              onChange={(event) => {
                if (event) {
                  const newStatus = StatusSessaoGerenciamentoMock.filter(
                    (item) => item.Key === event.target.value,
                  )[0]?.Key;
                  setStatusPesquisar(newStatus);
                }
              }}
              value={statusPesquisar}
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <TextFieldSaurus
              size="small"
              tipo="DATA"
              fullWidth
              name="dInicio"
              onChange={(e) => handleDateInicioChange(e)}
              autoComplete={'off'}
              value={selectedDateInicio}
              label="Data Inicial"
              placeholder="Data Inicial"
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <div className={classes.containerFinal}>
              <TextFieldSaurus
                size="small"
                tipo="DATA"
                fullWidth
                name="dFim"
                onChange={(e) => handleDateFimChange(e)}
                autoComplete={'off'}
                value={selectedDateFim}
                label="Data Final"
                placeholder="Data Inicial"
              />
              <Button
                color="primary"
                variant="contained"
                type="submit"
                className={classes.btnPesquisa}
                fullWidth={true}
              >
                <ProcurarIcon tipo="BUTTON_PRIMARY" />
              </Button>
            </div>
          </Grid>
        </Grid>
      </form>
    </Fade>
  );
};
