export enum EnumPagTpTransacao {
    NORMAL = 0,
    TEF = 1,
    FIDELIDADE = 2,
    MERCADO_PAGO = 3,
    S2_PAY = 4,
    VALE = 5,
    MAMBA = 6,
    PICPAY = 7,
    GRYN_CASH = 8,
    MY_CREDIT = 9,
    MERCADO_PAGO_PIX = 10,
    PAGSEGURO_PIX = 11,
    PENSEBANK_PIX = 14,
    PAYFACE = 15,
    CRMBONUS = 16,
    SAFRA_PIX = 17,
    BOLETO = 18,
    SAURUSPOS = 400,
    ITI = 800,
    SAFRAPAY_WALLET = 10000,
}