import { Grid, Typography, Button, Box } from 'views/design-system';
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useStyles } from './leitor-comanda-fragment-styles';
import Scanner from './components/scanner/scanner';
import { useToastSaurus } from 'services/app';
import { Keyboard } from 'views/components/keyboard/keyboard';
import { useKeyboard } from 'services/app/hooks/keyboard';
import { usePedidoLocal } from 'services/app/hooks/pedido-local';
import { useMovAtual } from 'services/app/hooks/mov-atual';
import { useHistory } from 'react-router-dom';
import { KeypadIcon } from 'views/components/icons/keypad-icon';
import { useThemeQueries } from 'views/theme';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import classNames from 'classnames';
import { useDevice } from 'services/app/hooks/device';
import { EnumAcaoLeituraRfid } from 'model/enums/enum-acao-leitura-rfid';
import { EnumTemNFC } from 'model/enums/enum-tem-nfc';
import { useNFC } from 'services/app/hooks/nfc';
import { EnumTipoTrabalhoComanda } from 'model/enums/enum-tipo-trabalho-comanda';
import { EscaneandoComanda } from './components/escaneando-comanda/escaneando-comanda';
import scanAnim from 'assets/img/nfc-animation.gif';
import { useEmpresaAtual } from 'services/app/hooks/empresa-atual';
import { isEmpty } from 'lodash';
import { EnumEmpresaConfig } from 'model/enums/enum-empresa-config';
import { isOnlyHasNumber } from 'utils/to-decimal';
import { useDigitarCodigo } from 'services/app/hooks/digitar-codigo';
import { OkIcon } from 'views/components/icons';
import { CameraIcon } from 'views/components/icons/camera-icon';
import { useMovRota } from 'services/app/hooks/mov-rota';
import { useMovValidators } from 'services/app/hooks/mov-validators';
import { useVendaHeaderStore } from 'views/components/headers/venda-header/stores/venda-header-store';
import { ComandasModel } from 'model/api/gestao/comanda/comanda-model';

interface LeitorComandaProps {
  handleConfime: () => Promise<void>;
  textChanged: (text: string, formattedText: string) => Promise<boolean>;
  submit: () => Promise<boolean>;
  add: () => Promise<boolean>;
  callback?: (comanda: ComandasModel) => Promise<boolean> | boolean
  fecharIdentificarComanda?: () => void
}

const LeitorComanda = ({
  handleConfime,
  textChanged,
  submit,
  add,
  callback,
  fecharIdentificarComanda
}: LeitorComandaProps) => {
  const digitado = useRef<boolean>(false);
  const textoAtual = useRef<string>('');
  const valueRef = useRef<HTMLParagraphElement | null>(null);
  const btnRef = useRef<HTMLButtonElement | null>(null);
  //PROVIDERS
  const {
    getFormattedText,
    addValue,
    sendText,
    resetText,
    addKey,
    submitKey,
    backSpaceKey
  } = useKeyboard({
    maxLength: 20,
    floatCases: 0,
    isNumeric: false,
    handleTextChanged: textChanged,
    handleAdd: add,
    handleSubmit: submit,
    digitado: digitado,
    textoAtual: textoAtual
  });
  const { get, set } = useDigitarCodigo();
  const { callEvent, addHandler, removeHandler } = useEventTools();
  const { searchComandas } = usePedidoLocal();
  const { setComandaId, setCodigoComanda } = useMovAtual();
  const { getComandaRfid, dispositivoTemNFC } = useDevice();
  const { temNFC, verificarNFC } = useNFC();
  //AUX E Outros
  const { push: historyPush, goBack } = useHistory();
  const { getConfiguracoesMesaEComanda } = usePedidoLocal();
  const { getConfigByCod } = useEmpresaAtual();
  const { avancarFluxoMov, redirectCarrinho } = useMovRota();
  const { permiteEditarPedidoComanda } = useMovValidators();


  const tipoTrabalhoComanda = useMemo(
    () => getConfiguracoesMesaEComanda()!.tipoTrabalhoComanda,
    [getConfiguracoesMesaEComanda]
  );

  const classesLeitor = useStyles();
  const { showToast } = useToastSaurus();
  const digitos = getFormattedText();
  const { isMobile, theme } = useThemeQueries();

  const attValueDisplay = useCallback(
    (any: any) => {
      const v = getFormattedText();
      if (valueRef.current) {
        if (v.length === 0) {
          valueRef.current.textContent = 'Aguardando Digitação';
          valueRef.current.className = classesLeitor.textVisorAguardando;
          if (btnRef.current) {
            btnRef.current.classList.add('Mui-disabled');
          }
          return;
        }
        valueRef.current.textContent = v.replace(/\*/g, 'x');
        valueRef.current.className = classesLeitor.textVisor;
        if (btnRef.current) {
          btnRef.current.classList.remove('Mui-disabled');
        }
      }
    },
    [
      classesLeitor.textVisor,
      classesLeitor.textVisorAguardando,
      getFormattedText
    ]
  );

  const setHeaderProps = useVendaHeaderStore(state => state.setHeaderProps);

  useEffect(() => {
    if (!callback) {
      setHeaderProps({
        title: 'Identifique a Comanda',
        showDefinicoes: false,
        showMenuPdv: false,
        voltar: {
          onClick: async () => {
            try {
              await redirectCarrinho();
            } catch (e: any) {
              showToast('error', e.message)
            }
          }
        }
      })
    }

    addHandler(AppEventEnum.AlterarVersao, attValueDisplay);
    return () => removeHandler(AppEventEnum.AlterarVersao, attValueDisplay);
  }, [addHandler, attValueDisplay, callback, redirectCarrinho, removeHandler, setHeaderProps, showToast]);

  //STATES E REFS
  const [digitarCodigo, setDigitarCodigo] = useState(get());
  const [escaneando, setEscaneando] = useState<boolean>(false);
  const [erro, setErro] = useState<string>('');
  const possuiScanner = true;
  const exibirDigitarCodigo = digitarCodigo || !possuiScanner;

  useEffect(() => {
    if (!exibirDigitarCodigo)
      callEvent(AppEventEnum.PermiteEntradaDigitada, true);

    return () => callEvent(AppEventEnum.PermiteEntradaDigitada, false);
  }, [callEvent, exibirDigitarCodigo]);

  const handleClickDigitarCodigo = () => {
    setDigitarCodigo(!digitarCodigo);
    set(!digitarCodigo);
  };

  const handleAddValue = useCallback(
    (value: number) => {
      addValue(value);
    },
    [addValue]
  );

  const handleBackSpace = useCallback(() => {
    backSpaceKey();
  }, [backSpaceKey]);

  const handleText = useCallback(
    (value: string) => {
      sendText(value);
    },
    [sendText]
  );

  const handleAdd = useCallback(() => {
    addKey();
  }, [addKey]);

  const handleSubmit = useCallback(async () => {
    await handleConfime();
    resetText('');
    submitKey();
  }, [handleConfime, resetText, submitKey]);

  const confirmarComanda = useCallback(
    async (codComanda: string) => {
      setErro('');
      try {
        const buscaPrefixo = getConfigByCod(EnumEmpresaConfig.PrefixoDeComanda);
        const prefixo = buscaPrefixo?.toUpperCase();

        let comanda: any = codComanda;

        if (comanda?.startsWith(prefixo)) {
          comanda = comanda.slice(prefixo?.length);
        }

        if (!isEmpty(prefixo) && codComanda.includes(prefixo!)) {
          if (isOnlyHasNumber(comanda)) {
            comanda = Number(comanda);
          }
        }

        const comandas = await searchComandas(comanda);

        if (comandas.length === 0) {
          throw new Error(
            `Nenhuma comanda foi identificada com o codigo ${comanda}`
          );
        }
        const comandaFirst = comandas[0]
        if (callback) {
          const res = await callback(comandaFirst)
          if (res && fecharIdentificarComanda) {
            fecharIdentificarComanda()
          }
          return
        }
        await setComandaId(comandaFirst.id);
        await setCodigoComanda(codComanda);
        await avancarFluxoMov();

      } catch (err: any) {
        setErro(err.message);
        showToast('error', err.message);
      } finally {
        resetText('');
      }
    },
    [avancarFluxoMov, callback, fecharIdentificarComanda, getConfigByCod, resetText, searchComandas, setCodigoComanda, setComandaId, showToast]
  );

  const confirmarComandaByRfId = useCallback(
    async (rfId: string) => {
      try {
        setEscaneando(false);
        const comandas = await searchComandas(rfId, 'rfId');

        if (comandas.length === 0) {
          throw new Error(
            `Nenhuma comanda foi identificada com o RFID ${rfId}`
          );
        }
        const comanda = comandas[0]
        if (callback) {
          const res = await callback(comanda)
          if (res && fecharIdentificarComanda) {
            fecharIdentificarComanda()
          }
          return
        }
        await setComandaId(comandas[0].id);
        await avancarFluxoMov();

      } catch (e: any) {
        setEscaneando(true);
        showToast('error', e.message);
      } finally {
        resetText('');
      }
    },
    [avancarFluxoMov, callback, fecharIdentificarComanda, resetText, searchComandas, setComandaId, showToast]
  );

  const escanearComanda = useCallback(async () => {
    let escaneandoComanda = true;
    while (escaneandoComanda) {
      try {
        const codigo = await getComandaRfid(EnumAcaoLeituraRfid.ATIVAR);
        showToast('success', `Leitura realizada com sucesso.`);
        setEscaneando(false);
        escaneandoComanda = false;
        await confirmarComandaByRfId(codigo);
        return;
      } catch (e: any) {
        if (e === EnumTemNFC.SEM_NFC) {
          setEscaneando(false);
          escaneandoComanda = false;
          return;
        }
        showToast('error', e);
      }
    }
  }, [confirmarComandaByRfId, getComandaRfid, showToast]);

  const teclado = useMemo(() => {
    return (
      <>
        <Grid flex flexDirection='column' className={classesLeitor.tecladoGrid}>
          <Keyboard
            isButtonBackspace
            isButtonKeyboard00
            handleAdd={handleAdd}
            handleBackSpace={handleBackSpace}
            handleAddValue={handleAddValue}
            handleSubmit={handleSubmit}
            handleText={handleText}
            isNumeric={false}
          />
        </Grid>
      </>
    );
  }, [classesLeitor.tecladoGrid, handleAdd, handleAddValue, handleBackSpace, handleSubmit, handleText]);


  const RenderFluxoInicialLeitor = memo(() => {
    return (
      <>
        <Button
          className={`${classesLeitor.button} round`}
          onClick={handleClickDigitarCodigo}
          variant="outlined"
          color="primary"
          style={{ height: isMobile ? '90px' : '60px' }}
        >
          <KeypadIcon tipo="BUTTON" fill={theme.palette.primary.main} />

          <Typography className={classesLeitor.textButton}>
            Digitar Código
          </Typography>
        </Button>
      </>
    );
  });

  useEffect(() => {
    permiteEditarPedidoComanda().then(async (res) => {
      if (!res) {
        try {
          await avancarFluxoMov();
        } catch (e: any) {
          showToast('error', e.message)
        }
      }
    }).catch((e) => showToast('error', e.message));

    if (
      temNFC &&
      !escaneando &&
      tipoTrabalhoComanda !== EnumTipoTrabalhoComanda.APENAS_RFID
    ) {
      setEscaneando(true);
      escanearComanda();
    } else if (!temNFC) {
      verificarNFC();
    }
    return () => {
      if (escaneando) {
        getComandaRfid(EnumAcaoLeituraRfid.DESATIVAR);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispositivoTemNFC,
    escanearComanda,
    getComandaRfid,
    getConfiguracoesMesaEComanda,
    historyPush,
    temNFC,
    tipoTrabalhoComanda,
    verificarNFC
  ]);

  const RenderEscaneandoComanda = memo(() => {
    return (
      <>
        <Box
          p={1}
          flex
          justifyContent="center"
          alignItems="center"
          flexWrap="wrap"
          gap={4}
        >
          <Typography align="center">
            Leitura de comanda RFID através do NFC disponível.
          </Typography>
          <img
            alt="Celular Escaneando NFC"
            src={scanAnim}
            className={classesLeitor.nfcAnim}
          />
        </Box>
      </>
    );
  });

  const renderInserirCodigoManual = () => {
    return (
      <>
        <Grid className={classesLeitor.visorCodigo}>
          <Grid
            container
            style={{ justifyContent: 'center', alignItems: 'center' }}
          >
            <Grid flex flexDirection='row' alignItems='center'>
              {possuiScanner && (
                <Grid className={classesLeitor.visorButton}></Grid>
              )}
              <Grid className={classesLeitor.contentVisor}>
                <Typography className={classesLeitor.label}>
                  Código da Comanda
                </Typography>
                <Typography className={classesLeitor.textVisorAguardando} variant="h6" color="primary" ref={valueRef}>
                  Aguardando Digitação
                </Typography>
              </Grid>
              {possuiScanner && (
                <Button
                  onClick={handleClickDigitarCodigo}
                  variant="outlined"
                  color="primary"
                  className={classNames(classesLeitor.visorButton, classesLeitor.visorLeitor)}
                >
                  {digitarCodigo && (
                    <CameraIcon tipo="GERAL"
                      fill={theme.palette.primary.main} />
                  )}
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>
        {teclado}
      </>
    );
  };

  const leituraRealizada = useCallback(
    (novoCod: string) => {
      confirmarComanda(digitos + novoCod);
    },
    [confirmarComanda, digitos]
  );

  const adicionarButton = useMemo(() => {
    return (
      <Button
        ref={btnRef}
        onClick={async () => {
          await handleConfime();
          resetText('');
        }}
        variant="contained"
        color="primary"
        fullWidth
        rounded
        className={classesLeitor.textButton}
      >
        <OkIcon tipo="BUTTON_PRIMARY" />
        Confirmar Comanda
      </Button>
    );
  }, [
    classesLeitor.textButton,
    handleConfime,
    resetText
  ]);

  return (
    <Grid
      className={classNames(
        classesLeitor.root,
        tipoTrabalhoComanda === EnumTipoTrabalhoComanda.APENAS_RFID
          ? classesLeitor.rootRfID
          : undefined
      )}
    >
      {possuiScanner &&
        (!exibirDigitarCodigo &&
          tipoTrabalhoComanda !== EnumTipoTrabalhoComanda.APENAS_RFID ? (
          <>
            {escaneando && <RenderEscaneandoComanda />}
            <Scanner onScan={leituraRealizada} inicialOrSecundario={false} />
            <RenderFluxoInicialLeitor />
          </>
        ) : tipoTrabalhoComanda === EnumTipoTrabalhoComanda.APENAS_RFID ? (
          <EscaneandoComanda
            closeEscaneando={() => {
              goBack();
            }}
            confirmarComanda={confirmarComandaByRfId}
            erroExterno={erro}
            labelVoltar="Voltar"
            round
          />
        ) : (
          <>{renderInserirCodigoManual()}</>
        ))}

      {possuiScanner && exibirDigitarCodigo && (
        <>
          <Grid className={classesLeitor.containerFooter}>
            {adicionarButton}
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default LeitorComanda;
