import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
    formulario: {
        display: "flex",
        height: "100%"
    },
    filtro: {
        width: "100%",
        height: "0",
        flex: "1"
    }
}))