import { useTheme } from '@material-ui/core';
import { DialogSaurus } from '../dialog-saurus/dialog-saurus';
import { useStyles } from './dialog-novo-pdv-styles';
import { SalvarIcon, VoltarIcon } from './../../../components/icons';
import { CircularLoading } from './../../../../views';
import { TextFieldSaurus } from './../../../components/controles/inputs';
import { useCallback, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDialogNovoPdvValidation } from './dialog-novo-pdv-validation';
import { yupResolver } from '@hookform/resolvers/yup';
import { TokenGestaoEmpresaModel } from 'model';
import { usePDV } from 'services/app/hooks/pdv';
import { useCadastros, useSessaoAtual, useToastSaurus } from 'services/app';
import { useConfirm } from 'material-ui-confirm';
import { VariaveisAmbiente } from 'config';
import { EnumDeviceType } from 'model/enums/enum-device-type';
import { useDevice } from 'services/app/hooks/device';
import { newGuid } from 'utils/new-guid';
import { useMovRota } from 'services/app/hooks/mov-rota';
import { Box, Button, Grid } from 'views/design-system';

export class DialogNovoPDVForm {
  constructor(
    public numero: string = '',
    public descricao: string = '',
  ) { }
}

interface DialogNovoPDVProps {
  openned: boolean;
  gerarNovoSerial?: boolean;
}

export const DialogNovoPDV = ({
  openned,
  gerarNovoSerial
}: DialogNovoPDVProps) => {
  const classes = useStyles();
  const refInput = useRef<HTMLDivElement | null>(null);
  const theme = useTheme();
  const { FormDialogNovoPdvYupValidation } = useDialogNovoPdvValidation()
  const { vincularNovoPDV, carregando } = usePDV()
  const { getEmpresaSelecionada, getPessoa } = useSessaoAtual()
  const { showToast } = useToastSaurus()
  const confirm = useConfirm()
  const { fecharNovoPDVDialog } = useCadastros()
  const { getSerialNumber } = useDevice();
  const { redirectLanding } = useMovRota();
  const handleClickNovoPDV = useCallback(async ({ descricao, numero }: DialogNovoPDVForm) => {
    try {
      let serial = (VariaveisAmbiente.paymentDevice === EnumDeviceType.CORDOVA ||
        VariaveisAmbiente.paymentDevice === EnumDeviceType.CORDOVA_POS) ? await getSerialNumber() : getPessoa()?.pessoa.id ?? '';

      if (gerarNovoSerial) {
        serial = newGuid();
        localStorage.setItem('deviceId', serial);
      }

      await vincularNovoPDV(
        Number(numero),
        descricao,
        getEmpresaSelecionada() || new TokenGestaoEmpresaModel(),
        serial
      );
      showToast('success', `PDV ${descricao} Selecionado`);
      fecharNovoPDVDialog()
      await redirectLanding(true);
    } catch (err: any) {
      showToast(
        'error',
        'Erro ao adicionar novo Ponto de venda, verifique os dados informados.' +
        err.message,
      );
    }
  }, [fecharNovoPDVDialog, gerarNovoSerial, getEmpresaSelecionada, getPessoa, getSerialNumber, redirectLanding, showToast, vincularNovoPDV]);

  const onSubmit = useCallback((values: DialogNovoPDVForm) => {
    confirm({
      title: `Deseja confirmar a criação do ponto de venda?`,
      description: `Nº: ${values.numero}, Descrição: ${values.descricao}`,
      cancellationText: 'Voltar',
      cancellationButtonProps: {
        color: 'default',
      },
      confirmationText: 'Confirmar Envio',
    }).then(() => {
      handleClickNovoPDV(values);
    });
  }, [confirm, handleClickNovoPDV])

  const {
    handleSubmit,
    control,
    formState: { errors, touchedFields },
  } = useForm<DialogNovoPDVForm>({
    resolver: yupResolver(FormDialogNovoPdvYupValidation),
    criteriaMode: 'all',
    mode: 'onChange',
  });

  return (
    <DialogSaurus
      aberto={openned || false}
      titulo="Novo Ponto de Venda"
      tamanho="xs"
    >
      {carregando && <CircularLoading tipo="FULLSIZED" />}
      <Box className={classes.cardContainer}>
        <Box className={classes.cardContent}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid
              container
              spacing={2}
              style={{ marginTop: 4 }}
            >
              <Grid item xs={12}>
                <Controller
                  name='numero'
                  control={control}
                  render={({ field }) => (
                    <TextFieldSaurus
                      label="PDV"
                      inputRef={refInput}
                      disabled={carregando}
                      fullWidth
                      size="small"
                      tipo='NUMERO'
                      allowSubmit={true}
                      {...field}
                      placeholder="500"
                      adornmentColor={theme.palette.text.disabled}
                      error={Boolean(
                        errors.numero && errors.numero.message,
                      )}
                      helperText={
                        touchedFields.numero || errors.numero
                          ? errors.numero?.message
                          : undefined
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name='descricao'
                  control={control}
                  render={({ field }) => (
                    <TextFieldSaurus
                      label="Descrição"
                      inputRef={refInput}
                      disabled={carregando}
                      fullWidth
                      size="small"
                      allowSubmit={true}
                      {...field}
                      placeholder="Nome do PDV..."
                      adornmentColor={theme.palette.text.disabled}
                      error={Boolean(
                        errors.descricao && errors.descricao.message,
                      )}
                      helperText={
                        touchedFields.descricao || errors.descricao
                          ? errors.descricao?.message
                          : undefined
                      }
                    />
                  )} />
              </Grid>
              <Grid item xs={4} className={classes.btnGrid}>
                <Button
                  disabled={carregando}
                  variant="outlined"
                  fullWidth
                  color="primary"
                  onClick={() => {
                    fecharNovoPDVDialog();
                  }}
                >
                  <VoltarIcon tipo="BUTTON" />
                  Voltar
                </Button>
              </Grid>
              <Grid item xs={8} className={classes.btnGrid}>
                <Button
                  disabled={carregando}
                  variant="contained"
                  fullWidth
                  color="primary"
                  type="submit"
                >
                  <SalvarIcon tipo="BUTTON_PRIMARY" />
                  {'Adicionar'}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Box >
    </DialogSaurus >
  );
};
