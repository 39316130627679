import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container: {
      minHeight: 45,
      width: '100%',
      padding: theme.spacing(0.5)
  },
  btnContent: {
      width: '100%',
      minWidth: 100,
      backgroundColor: undefined,
      '&:hover': {
        backgroundColor: undefined
      },
  },
  btnContentWithLocation: {
      width: '100%',
      minWidth: 100,
      backgroundColor: `${theme.palette.primary.main}30`,
      '&:hover': {
        backgroundColor: `${theme.palette.primary.main}30`
      },
  },
  btnLabel: {
    display: 'flex',
  },
  icon: {
    fill: theme.palette.primary.main,
    width: 20,
    height: 20,
  },
  textPrimary: {
    fontSize: 10,
    fontWeight: 600
  },
  btnText: {
    padding: theme.spacing(1)
  },
  btnRoot: {
    height: 'inherit'
  },
  badgeQtde: {
    top: 0,
    "& span": {
      fontSize: 10,
    }
  },
  fullWidth: {
    width: '100%'
  }
}));
