import { FormRefNFPProps } from "./form-ref-nfp-props";
import { Controller } from "react-hook-form";
import { TextFieldSaurus } from "views/components/controles/inputs";
import { SelectSaurus } from "views/components/controles/selects/select-saurus/select-saurus";
import { UFMock } from "data/mocks";
import { ModNFP } from "data/mocks/mod-nfp";
import { Grid } from "views/design-system";

export const FormRefNFP = ({ control, formState, setValue, ...props }: FormRefNFPProps) => {

    const { errors, touchedFields } = formState

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Controller
                    name="refNFP.cnpj"
                    control={control}
                    render={({ field }) => (
                        <TextFieldSaurus
                            tipo={'CNPJ_CPF'}
                            disabled={props.loading}
                            fullWidth
                            autoComplete='new-password'
                            variant="outlined"
                            label="CPF/CNPJ"
                            allowSubmit
                            placeholder="Ex: 31.063.584/0001-20"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            error={Boolean(errors.refNFP?.cnpj && errors.refNFP?.cnpj.message)}
                            helperText={
                                touchedFields.refNFP || errors.refNFP?.cnpj
                                    ? errors.refNFP?.cnpj?.message
                                    : undefined
                            }
                            {...field}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={6}>
                <Controller
                    name="refNFP.ie"
                    control={control}
                    render={({ field }) => (
                        <TextFieldSaurus
                            tipo={'NUMERO'}
                            disabled={props.loading}
                            fullWidth
                            autoComplete='new-password'
                            variant="outlined"
                            label="Inscrição Estadual"
                            allowSubmit
                            InputLabelProps={{
                                shrink: true,
                            }}
                            error={Boolean(errors.refNFP?.ie && errors.refNFP?.ie.message)}
                            helperText={
                                touchedFields.refNFP || errors.refNFP?.ie
                                    ? errors.refNFP?.ie?.message
                                    : undefined
                            }
                            {...field}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={6}>
                <Controller
                    name="refNFP.cUF"
                    control={control}
                    render={({ field }) => (
                        <SelectSaurus
                            disabled={props.loading}
                            conteudo={UFMock}
                            fullWidth
                            autoComplete='new-password'
                            variant="outlined"
                            label="UF"
                            allowSubmit
                            InputLabelProps={{
                                shrink: true,
                            }}
                            error={Boolean(errors.refNFP?.cUF && errors.refNFP?.cUF.message)}
                            helperText={
                                touchedFields.refNFP || errors.refNFP?.cUF
                                    ? errors.refNFP?.cUF?.message
                                    : undefined
                            }
                            {...field}
                            onChange={(event) => {
                                const value = UFMock.filter(
                                    (x) => x.Key === event.target.value,
                                )[0]?.Key;
                                setValue("refNFP.cUF", value)
                            }}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={6}>
                <Controller
                    name="refNFP.nNF"
                    control={control}
                    render={({ field }) => (
                        <TextFieldSaurus
                            tipo={'NUMERO'}
                            disabled={props.loading}
                            fullWidth
                            autoComplete='new-password'
                            variant="outlined"
                            label="Número"
                            placeholder="Ex: 12"
                            allowSubmit
                            InputLabelProps={{
                                shrink: true,
                            }}
                            error={Boolean(errors.refNFP?.nNF && errors.refNFP?.nNF.message)}
                            helperText={
                                touchedFields.refNFP || errors.refNFP?.nNF
                                    ? errors.refNFP?.nNF?.message
                                    : undefined
                            }
                            {...field}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={6}>
                <Controller
                    name="refNFP.mod"
                    control={control}
                    render={({ field }) => (
                        <SelectSaurus
                            conteudo={ModNFP}
                            disabled={props.loading}
                            fullWidth
                            autoComplete='new-password'
                            variant="outlined"
                            label="Modelo"
                            allowSubmit
                            InputLabelProps={{
                                shrink: true,
                            }}
                            error={Boolean(errors.refNFP?.mod && errors.refNFP?.mod.message)}
                            helperText={
                                touchedFields.refNFP || errors.refNFP?.mod
                                    ? errors.refNFP?.mod?.message
                                    : undefined
                            }
                            {...field}
                            onChange={(event) => {
                                const value = ModNFP.filter(
                                    (x) => x.Key === event.target.value,
                                )[0]?.Key;
                                setValue("refNFP.mod", value)
                            }}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={6}>
                <Controller
                    name="refNFP.serie"
                    control={control}
                    render={({ field }) => (
                        <TextFieldSaurus
                            tipo={'TEXTO'}
                            disabled={props.loading}
                            fullWidth
                            autoComplete='new-password'
                            variant="outlined"
                            label="Série"
                            placeholder="Ex: 001"
                            allowSubmit
                            InputLabelProps={{
                                shrink: true,
                            }}
                            error={Boolean(errors.refNFP?.serie && errors.refNFP?.serie.message)}
                            helperText={
                                touchedFields.refNFP || errors.refNFP?.serie
                                    ? errors.refNFP?.serie?.message
                                    : undefined
                            }
                            {...field}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={6}>
                <Controller
                    name="refNFP.aamm"
                    control={control}
                    render={({ field }) => (
                        <TextFieldSaurus
                            tipo={'NUMERO'}
                            disabled={props.loading}
                            fullWidth
                            autoComplete='new-password'
                            variant="outlined"
                            label="Ano/Mês"
                            allowSubmit
                            InputLabelProps={{
                                shrink: true,
                            }}
                            maxTexto={4}
                            error={Boolean(errors.refNFP?.aamm && errors.refNFP?.aamm.message)}
                            helperText={
                                touchedFields.refNFP || errors.refNFP?.aamm
                                    ? errors.refNFP?.aamm?.message
                                    : undefined
                            }
                            {...field}
                        />
                    )}
                />
            </Grid>
        </Grid>
    );
}
