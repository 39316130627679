import { DialogSaurus } from "../dialog-saurus/dialog-saurus"
import { InformacaoIcon, VoltarIcon } from "views/components/icons";
import { ShowConfirmProps } from "services/app/hooks/confirm-saurus";
import { useStyles } from "./dialog-confirm-styles";
import React, { useCallback } from "react";
import { useEventTools } from "services/app/hooks/events/event-tools";
import { AppEventEnum } from "model/enums/enum-app-event";
import { ConfirmarIcon } from "views/components/icons/confirmar-icon";
import { Box, Button, Typography } from "views/design-system";

export interface DialogConfirmProps extends ShowConfirmProps {
    aberto: boolean;
}

export const DialogConfirm = ({
    primaryButtonText,
    secondaryButtonText,
    title,
    primaryButtonColor,
    secondaryButtonColor,
    description,
    aberto,
    showIcon,
    confirmIcon,
    secondaryIcon,
}: DialogConfirmProps) => {

    const classes = useStyles({
        primary: primaryButtonColor,
        secondary: secondaryButtonColor
    });

    const { callEvent } = useEventTools();

    const callConfirm = useCallback(() => {
        callEvent(AppEventEnum.ConfirmEvent, {})
    }, [callEvent])

    const callCancel = useCallback(() => {
        callEvent(AppEventEnum.CancelEvent, {})
    }, [callEvent])

    return <DialogSaurus
        tamanho='xs'
        aberto={aberto}
    >
        <Box className={classes.container}>
            {showIcon && <InformacaoIcon tipo='GERAL' class={classes.icon} />}
            <Typography className={classes.title} align='center'>
                {title}
            </Typography>
            <Typography className={classes.text} align='center'>
                {description && description.includes('/n') ? description.split('/n').map(value => {
                    return <span>{value}<br /></span>
                }) : description}
            </Typography>
            <Box className={classes.buttonContainer}>
                <Button variant='outlined' className={classes.buttonSecondary} onClick={callCancel}>
                    {secondaryIcon ? React.cloneElement(secondaryIcon, {
                        tipo: 'BUTTON'
                    }) : <VoltarIcon tipo='BUTTON' />}
                    {secondaryButtonText}
                </Button>
                <Button className={classes.buttonPrimary} onClick={callConfirm} >
                    {confirmIcon ? React.cloneElement(confirmIcon, {
                        tipo: 'BUTTON_PRIMARY'
                    }) : <ConfirmarIcon tipo='BUTTON_PRIMARY' />}
                    {primaryButtonText}
                </Button>
            </Box>
        </Box>
    </DialogSaurus>
}