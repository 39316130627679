import { TpModMock, tpModMockComposto } from "data/mocks";
import { EnumPagTpMod, EnumPagTpTransacao } from "model";


export function tpPagamentoResolver(
    tpMod: EnumPagTpMod,
    tptransacao: EnumPagTpTransacao
) {
    let value = tpModMockComposto.find(x => {
        return (
            x.Key.tpTransacao === tptransacao &&
            x.Key.tpMod === tpMod
        )
    });

    if (value) {
        return value;
    }

    value = TpModMock.find(x => {
        return x.Key === tpMod;
    })

    return value;
}