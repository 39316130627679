
import classNames from 'classnames';
import CloseIcon from '@material-ui/icons/Close';
import { CardCarrinhoPedidoProps } from './card-carrinho-grupo-produtos-props';
import { useStyles } from './card-carrinho-grupo-produtos-styles';
import React, { useEffect, useState } from 'react';
import { ComandasIcon } from 'views/components/icons/comanda-icon';
import { MesaIcon } from 'views/components/icons/mesa-icon';
import { TouchoneDBPrimary } from 'database/touchone-database';
import CardCarrinhoPedido from '../card-carrinho-pedido/card-carrinho-pedido';
import { EnumMesas } from 'model/enums/enum-mesas';
import { EnumComandas } from 'model/enums/enum-comandas';
import { useContratoAtual } from 'services/app/hooks/contrato-atual';
import { EnumContratoConfig } from 'model/enums/enum-contrato-config';
import { InformacaoIcon } from 'views/components/icons';
import { useToastSaurus } from 'services/app';
import { CestaIcon } from 'views/components/icons/cesta-icon';
import { Button, Divider, Grid, Typography, Tooltip } from 'views/design-system';
import { Paper, useTheme } from '@material-ui/core';

const CardCarrinhoGrupoProdutos = ({
  model,
  inativarProduto,
  reativarProduto,
  mostrarItensCancelados,
  removerPedidoDoCarrinho,
}: CardCarrinhoPedidoProps) => {
  // STATES E REFS
  const [codigo, setCodigo] = useState('');
  const { getConfigByCod } = useContratoAtual();
  // AUX
  const classes = useStyles();
  const theme = useTheme();
  const { showToast } = useToastSaurus()
  useEffect(() => {
    const getCodigo = async () => {
      try {
        if (model.comandaId) {
          const comanda = await TouchoneDBPrimary.comandas.get({
            id: model.comandaId,
          });
          if (comanda && comanda.status?.codigo === EnumComandas.ATIVO) {
            setCodigo(comanda?.codigoComanda ?? '');
          }
        } else if (model.mesaId) {
          const mesa = await TouchoneDBPrimary.mesas.get({ id: model.mesaId });
          if (mesa && mesa.status?.codigo === EnumMesas.ATIVO) {
            setCodigo(mesa.codigo ?? '');
          }
        } else if (model.identificador) {
          setCodigo(model.identificador)
        }
        else {
          setCodigo(model.codigoPedido)
        }
      } catch (err: any) {
        showToast('error', 'Ocorreu um problema ao identificar a mesa/comanda. Detalhe: ' + err.message)
      }
    };

    getCodigo();
  });

  const prodsCancelados = model.produtos.filter(x => x.ativo === false)
  
  return (
    <Grid item xs={12}>
      <Paper
        className={`${classNames('card')} ${classes.containerInternoCard}`}
      >
        <Grid className={classes.topHeader}>
          <Grid className={classes.containerButtonRemover}>
            <Button
              className={classes.buttonRemoverPedido}
              onClick={async () => {
                try {
                  await removerPedidoDoCarrinho(model.pedidoId)
                } catch (err: any) {
                  showToast('error', err.message)
                }
              }
              }
            >
              {' '}
              <CloseIcon /> Remover pedido
            </Button>
          </Grid>

          {codigo && (
            <>
              <Grid className={classes.containerTitlePedido}>
                {model.comandaId ? (
                  <ComandasIcon tipo="BUTTON" fill={theme.palette.primary.main} />
                ) : model.mesaId ? (
                  <MesaIcon tipo="BUTTON" fill={theme.palette.primary.main} />
                ) : (
                  <CestaIcon tipo="BUTTON" fill={theme.palette.primary.main} />
                )}
                <Typography variant="h5" className={classes.codigo}>
                  {codigo}
                </Typography>
              </Grid>
              <Divider className={classes.divider} />
            </>
          )}
        </Grid>

        {prodsCancelados.length > 0 ? <Grid container justifyContent='center' xs={12}>
          <Typography>Existem produtos cancelados nesse pedido.</Typography>
          <Tooltip 
            title={
              'Clique no botão "Exibir Cancelados" para verificar os produtos removidos do carrinho. Para devolver um produto removido ao carrinho, clique na seta apresentada ao lado do valor.'
            }
          >
            <div>
              <InformacaoIcon tipo='GERAL' class={classes.infoIcon} />
            </div>
          </Tooltip>
        </Grid> : null}

        <Grid className={classes.containerProdutos} >
          {model.produtos.length > 0 &&
            model.produtos
              .filter(x => x.produtoId !== getConfigByCod(EnumContratoConfig.ProdutoServico))
              .map((produto, index) => {
                return (
                  <div
                    key={produto.id}
                    style={{
                      display:
                        !mostrarItensCancelados && !produto.ativo ? 'none' : 'block',
                    }}
                  >
                    {(index === 0 ||
                      model.produtos[index - 1].vendedorId !==
                      produto.vendedorId) && (
                        <div className={classes.vendedorContent}>
                          <Typography className={classes.vendedorText}>
                            Vendedor: <b>{produto.vendedorNome}</b>
                          </Typography>
                          <Divider className={classes.vendedorDivider} />
                        </div>
                      )}

                    <CardCarrinhoPedido
                      key={index}
                      model={produto}
                      inativarProduto={inativarProduto}
                      reativarProduto={reativarProduto}
                    />
                  </div>
                );
              })}
        </Grid>
      </Paper>
    </Grid>
  );
};
export default React.memo(CardCarrinhoGrupoProdutos);
