import { useMemo, useState } from 'react';
import { GerenciamentoSessoesSearchProps } from './components/gerenciamento-sessoes-list/gerenciamento-sessoes-search-props';
import { GerenciamentoSessoesList } from './components/gerenciamento-sessoes-list/gerenciamento-sessoes-list';
import { GerenciamentoSessoesHeader } from './components/gerenciamento-sessoes-header/gerencimento-sessoes-header';
import { useDefaultCadastroStyles } from '../cadastros/components/default-cadastro-styles';
import { Grid } from 'views/design-system';

const GerenciamentoSessoesPage = () => {
  const classes = useDefaultCadastroStyles();

  const dataAtual = new Date().toISOString().split('T')[0];

  const [openPesquisa, setOpenPesquisa] = useState(false);
  const [searchProps, setSearchProps] = useState<GerenciamentoSessoesSearchProps>(
    {
      dInicial: dataAtual,
      dFinal: dataAtual,
      status: -1,
    },
  );

  const component = useMemo(() => <>
    <Grid className={classes.root}>
      <Grid className={classes.header}>
        <GerenciamentoSessoesHeader
          openPesquisa={openPesquisa}
          setSearchProps={setSearchProps}
          setOpenPesquisa={setOpenPesquisa}
        />
      </Grid>
      <Grid className={classes.list}>
        <GerenciamentoSessoesList searchProps={searchProps} />
      </Grid>
    </Grid>
  </>, [classes.header, classes.list, classes.root, openPesquisa, searchProps])

  return component;
};

export default GerenciamentoSessoesPage