import Dexie, { Table } from 'dexie';
import { TabelaSessao } from './interfaces/interface-tabela-sessao';
import { TabelaSessaoValores } from './interfaces/interface-tabela-sessao-valores';
import { TabelaVenda } from './interfaces/interface-tabela-vendas';

export class TouchoneDatabaseBackup extends Dexie {
    sessao!: Table<TabelaSessao>;
    sessaoValores!: Table<TabelaSessaoValores>;
    vendas!: Table<TabelaVenda>;

    constructor(dbName: string) {
        super(dbName);
        this.version(100).stores({
            sessao: `++idIndexed, id, dataAbertura, dataFechamento, operadorId, aberta, empresaId, caixaId`,
            sessaoValores: `++idIndexed, id, sessaoId, pagamento, valor, responsavel, empresaId, tpLancamento, observacao, operador, dataInsercao, portador, dataSincronizacao, caixaId`,
            vendas: `++idIndexed, id, empresaId, operadorId, sessaoId, status, tpAmb, dEmi, serie, nnf, mod, pessoaId, documento, regimeTributarioId, produtos, pags, cliente, dataSincronizacao, vNF, tpEmis, mesaCodigo, codigoComanda, vDesc, vAcresc, telefone, infCpl`,
        })
    }

    limparDadosSessao = async () => {
        await this.sessao.clear();
        await this.sessaoValores.clear();
    }
}

export const TouchoneBacupkup = new TouchoneDatabaseBackup('touchone-backupv1.17');
