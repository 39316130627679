import { DialogSaurus } from '../dialog-saurus/dialog-saurus'
import { guidEmpty } from 'utils/guid-empty';
import { useCadastros, useSessaoAtual, useToastSaurus } from 'services/app';
import { useEffect, useRef } from 'react';
import { DefaultFormRefs } from 'views/components/form/utils';
import { CircularLoading } from 'views/components/utils';
import { SalvarIcon } from 'views/components/icons';
import { FormMarcaFacilitado } from 'views/components/form/marca-facilitado/form-marca-facilitado';
import { isPlanoFarmaceutico } from 'utils/plano-utils';
import { usePostMarca } from 'data/api/gestao/marca/post-marca';
import { usePutMarca } from 'data/api/gestao/marca/put-marca';
import { MarcaModel } from 'model/api/gestao/marca/marca-model';
import { Box, Button } from 'views/design-system';

interface DialogMarcaProps {
    openned: boolean;
    model: MarcaModel;
    isMarca?: boolean;
    finalizar: (laboratorio: MarcaModel) => Promise<void>;
}

export const DialogMarca = ({
    finalizar,
    model,
    openned,
    isMarca = false
}: DialogMarcaProps) => {

    const { fecharDialogMarca } = useCadastros();
    const { showToast } = useToastSaurus();
    const { plano, usuario } = useSessaoAtual();

    const { postMarca, carregando: carregandoPost } = usePostMarca();
    const { putMarca, carregando: carregandoPut } = usePutMarca();
    const carregando = carregandoPost || carregandoPut;

    const refForm = useRef<DefaultFormRefs<MarcaModel>>(null)

    const isFarma = !isMarca && isPlanoFarmaceutico(plano?.plano)

    const label = isFarma ? 'Laboratório' : 'Marca'

    const handleSubmit = async (model: MarcaModel) => {
        try {
            model.contratoId = model.contratoId || (usuario?.empresa[0].ContratoId || '');
            if (!model.id ||model.id === guidEmpty()) {
                const res = await postMarca(model);
                if (res.erro) throw res.erro
                showToast('success', `${label} Cadastrado com sucesso!`)
                finalizar(res.resultado?.data);
            } else {
                const res = await putMarca(model);
                if (res.erro) throw res.erro
                showToast('success', `${label} Atualizado com sucesso!`)
                finalizar(res.resultado?.data);
            }
        } catch (e: any) {
            showToast('error', e.message)
        }
    }

    const fillForm = (model: MarcaModel) => {
        setTimeout(() => {
            refForm.current?.fillForm(model)
        }, 50)
    }

    useEffect(() => {
        fillForm(model)
    }, [model, openned])


    return (
        <DialogSaurus
            aberto={openned}
            titulo={(model.id === guidEmpty() ? 'Cadastrar' : 'Editar') + ' ' + label}
            isButtonTitleClose
            tamanho='sm'
            handleClickClose={() => fecharDialogMarca()}
        >
            {carregando && <CircularLoading tipo='FULLSIZED' />}
            <FormMarcaFacilitado
                loading={carregando}
                onSubmit={handleSubmit}
                showLoading={false}
                ref={refForm}
                isFarma={isFarma}
            />
            <Box flex alignItems='center' justifyContent='flex-end'>
                <Button variant='contained' color='primary' onClick={() => refForm.current?.submitForm()}>
                    <SalvarIcon tipo='BUTTON_PRIMARY' />
                    Salvar {label}
                </Button>
            </Box>
        </DialogSaurus>
    )
}