import classNames from 'classnames';
import { useModalStyles } from 'views/components/modals/utils/modal-styles';
import parse from 'html-react-parser';
import { DialogSaurus } from 'views/components/dialog/dialog-saurus/dialog-saurus';
import { useSessaoAtual } from 'services/app';
import { useCallback } from 'react';
import { AtencaoIcon } from 'views/components/icons/atencao-icon';
import { useThemeQueries } from 'views/theme';
import { CloseIcon } from 'views/components/icons/close-icon';
import { useCadastros } from 'services/app';
import { Button, Grid, Typography } from 'views/design-system';

export interface modalAvisoCobrancaProps {
  aberto: boolean;
}

export const DialogAvisoCobranca = ({
  ...props
}: modalAvisoCobrancaProps) => {
  const classes = useModalStyles();
  const { plano } = useSessaoAtual();
  const { theme } = useThemeQueries()
  const { fecharAvisoCobrancaDialog } = useCadastros()

  const onCloseClick = useCallback(() => {
    fecharAvisoCobrancaDialog();
  }, [fecharAvisoCobrancaDialog]);

  return (
    <>
      {props.aberto && (
        <DialogSaurus
          aberto={props.aberto}
          titulo="Informação Importante"
          centralizarTitulo
          colorTitulo={theme.palette.primary.main}
          tamanho="xs"
          isButtonTitleClose
          handleClickClose={onCloseClick}
        >
          <div className={classes.root}>
            <div className={classes.content}>
              <div
                className={classNames(
                  classes.contentForms,
                )}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '100%',
                }}
              >
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <AtencaoIcon tipo="GERAL" style={{ maxWidth: 200 }} />
                </div>
                <Typography
                align='center'
                weight={600}
                color='primary'
                >
                  {parse(plano?.contratado?.avisoCobranca!)}
                </Typography>
              </div>
            </div>
            <div className={classes.acoes}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <Button
                    variant="outlined"
                    color="primary"
                    fullWidth
                    onClick={() => onCloseClick()}
                  >
                    <CloseIcon tipo="BUTTON" />
                    Fechar
                  </Button>
                </Grid>
              </Grid>
            </div>
          </div>
        </DialogSaurus>
      )}
    </>
  );
};
