import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    moduloContainer: {
        padding: '1rem',
        background: '#F8F8F8',
        overflowY: 'auto',
        paddingTop: 0,
        flexGrow: 1,
        maxHeight: 300,
        [theme.breakpoints.down('sm')]: {
            maxHeight: 'unset'
        },
        [theme.breakpoints.up('lg')]: {
            maxHeight: 400
        },
        [theme.breakpoints.up('xl')]: {
            maxHeight: 600
        }
    },
    card: {
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: theme.spacing(1),
    },
    buttonAddSubContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            justifyContent: 'flex-start'
        }
    },
    cardPricingR$: {
        fontWeight: "bold",
        marginleft: 0,
        marginbottom: 0
    },
    cardPricing: {
        fontWeight: "bold",
        marginleft: 0,
        marginbottom: 0,
        display: "flex",
        flexDirection: "row"
    },
    cardTextPerMonth: {
        display: "flex",
        alignItems: "flex-end",
        justifyContent: "flex-end",
    },
    textPerMonth: {
        fontWeight: 600
    },
    valorCard: {
        display: 'flex',
        alignItems: 'flex-end',
        gap: 4
    },
    containerFlex: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    cardError: {
        borderStyle: 'solid',
        borderWidth: '1px',
        borderColor: theme.palette.error.main,
        borderRadius: theme.shape.borderRadius
    },
    boxCustom: {
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        flexDirection: 'row',
    }
}));