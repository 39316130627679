import { AppEventEnum } from 'model/enums/enum-app-event';
import { useCallback, useEffect, useState } from 'react';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { consoleDev } from 'utils/console-dev';
import { DialogImpressaoNfe } from 'views/components/dialog/dialog-impressao-nfe/dialog-impressao-nfe';

class DialogImpressaoNfeProps {
  constructor(
    public aberto: boolean = false,
    public movId: string = '',
    public senhaChamada?: number,
    public emails: string[] = [],
  ) { }
}

export const DialogImpressaoNfeRender = () => {
  const { addHandler, removeHandler } = useEventTools();
  const [modalState, setModalState] = useState<DialogImpressaoNfeProps>(
    new DialogImpressaoNfeProps()
  );

  consoleDev('DialogImpressaoNfe');

  const modalAlterado = useCallback(
    ({ aberto, ...props }: any) => {
      setModalState({
        aberto,
        ...props
      });
    },
    []
  );

  useEffect(() => {
    addHandler(AppEventEnum.DialogImpressaoNfe, modalAlterado);
    return () => {
      removeHandler(AppEventEnum.DialogImpressaoNfe, modalAlterado);
    };
  }, [addHandler, modalAlterado, removeHandler]);

  return (
    <>
      {modalState.aberto && (
        <DialogImpressaoNfe
          openned={modalState.aberto}
          {...modalState}
        />
      )}
    </>
  );
};
