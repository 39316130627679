import { useStyles } from "./tooltip-saurus-styles"
import { TooltipImpressorasProps } from "./tooltip-impressoras-props"
import { InformacaoIcon } from "views/components/icons"
import { Tooltip } from "views/design-system"


export const ToolTipImpressoras = ({ descricao }: TooltipImpressorasProps) => {
    const classes = useStyles()
    return (
            <Tooltip
                title={descricao}
            >
                <div  className={classes.iconDiv}>
                    <InformacaoIcon tipo='GERAL' class={classes.infoIconExt} />
                </div>
            </Tooltip>
    )
}