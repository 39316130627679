import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useKeyboard } from 'services/app/hooks/keyboard';
import { Keyboard } from 'views/components/keyboard/keyboard';
import { useStyles } from 'views/pages/private/movimentacao/mov-pagamento-novo/components/pagamento-parcial/pagamento-parcial-efetuar-fragment/pagamento-efetuar-fragment-styles';
import NovoPagamentoListData from 'views/pages/private/movimentacao/mov-pagamento-novo/components/novo-pagamento-list/novo-pagamento-list-data';
import { FinalizadoraModel } from 'model/api/gestao/finalizadora/finalizadora-model';
import { useGetFinalizadoras } from 'data/api/gestao/finalizadora';
import { isEmpty } from 'lodash';
import { useSessaoAtual, useToastSaurus } from 'services/app';
import { toDecimal, toDecimalString } from 'utils/to-decimal';
import { VendaCompletaXMLModel } from 'model/api/gestao/venda/venda-completa-xml-model';
import { Grid, Typography } from 'views/design-system';

export default function FormEntradaCadastroNovoPagamento({
  index,
  handleNext,
  isCreating = false,
  remainingValue,
}: any): React.ReactElement {
  const classes = useStyles();
  const { setValue, getValues, control } = useFormContext<VendaCompletaXMLModel>();
  const textoAtual = useRef('');
  const textoParagraph = useRef<HTMLParagraphElement>(null);
  const { getEmpresaSelecionada } = useSessaoAtual();
  const { showToast } = useToastSaurus();

  const { fields } = useFieldArray({
    control,
    name: 'infMov.pag',
    keyName: '_id'
  })

  const [queryStatus, setQueryStatus] = useState({
    page: 1,
    totalPages: 0,
    totalResults: 0,
    list: Array<FinalizadoraModel>()
  });

  const fillResult = useCallback(
    (
      page: number,
      totalPages: number,
      totalResults: number,
      list: Array<FinalizadoraModel>
    ) => {
      setQueryStatus({
        page: page,
        list: list,
        totalResults: totalResults,
        totalPages: totalPages
      });
    },
    []
  );

  const { sendText, resetText, backSpaceKey } = useKeyboard({
    isNumeric: true,
    maxLength: 7,
    floatCases: 2,
    handleTextChanged: async (value, formattedValue) => {
      textoAtual.current = value;
      if (textoParagraph.current) {
        textoParagraph.current.innerText = toDecimalString(formattedValue, 2);
      }
      return true;
    },
    digitado: useRef(false),
    textoAtual: textoAtual
  });

  const { getFinalizadoras, carregando: carregandoFinalizadoras } =
    useGetFinalizadoras();

  const valorRestante = useMemo<number>(() => {
    return getValues('infMov.total.vnf') - (
      fields.filter((_, i) => i !== index).reduce<number>((prev, curr) => prev + curr.vPag, 0) + toDecimal(textoAtual.current)
    )
  }, [fields, getValues, index])

  const onCardClicked = useCallback(
    async (paymentMethod: FinalizadoraModel) => {
      const valorString = String(
        textoParagraph.current?.innerText
      ).replace(',', '.');
      const valorPagamento = parseFloat(valorString);

      if (valorPagamento > valorRestante) {
        showToast('error', 'Valor da Parcela ultrapassa valor total da NFe.');
        return
      }

      if (isNaN(valorPagamento) || valorPagamento <= 0) {
        showToast(
          'error',
          'Não é possível realizar um pagamento com valor zerado ou inválido.'
        );
      } else {
        setValue(`infMov.pag.${index}.tPag`, paymentMethod.tpMod, {
          shouldValidate: true
        });

        setValue(`infMov.pag.${index}.vPag`, valorPagamento)

        handleNext();
      }
    },
    [valorRestante, showToast, setValue, index, handleNext]
  );

  const search = useCallback(async () => {
    try {
      const query =
        (!isEmpty(getEmpresaSelecionada()?.Id)
          ? '&EmpresaId=' + getEmpresaSelecionada()?.Id
          : '') + '&pageSize=30';
      const res = await getFinalizadoras(query, 1);
      fillResult(
        1,
        1,
        res?.resultado?.data.list.length ?? 0,
        res?.resultado?.data.list ?? ([] as FinalizadoraModel[])
      );
    } catch (e: any) {
      showToast('error', e.message);
    }
  }, [getEmpresaSelecionada, getFinalizadoras, fillResult, showToast]);

  useEffect(() => {
    (async () => {
      if (isCreating) {
        setValue(`infMov.pag.${index}.vPag`, remainingValue)
      }
      await search();
      await resetText(`${getValues(`infMov.pag.${index}.vPag`)}`, false)
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  return (
    <Grid container flexDirection="column" className={classes.container}>
      <Grid className={classes.middleContainer}>
        <Grid container className={classes.middleContent}>
          <Grid item>
            <div className={classes.valorContainer}>
              <div className="valorMonetario">
                <Typography className="unidade" variant="body1">
                  R$
                </Typography>
                <Typography className="valor" variant="h3" ref={textoParagraph}>
                  {toDecimalString(getValues(`infMov.pag.${index}.vPag`), 2)}
                </Typography>
              </div>
            </div>
          </Grid>
        </Grid>
      </Grid>

      <Grid container style={{ flex: 1, overflowY: 'auto' }}>
        <Keyboard
          isButtonKeyboard00
          isButtonBackspace
          handleAdd={() => sendText('0')}
          handleBackSpace={backSpaceKey}
          handleText={sendText}
          handleEscape={() => resetText('0')}
          isNumeric
        />
      </Grid>

      <Grid
        container
        style={{
          height: 200,
          minHeight: 200,
          overflowY: 'hidden',
          overflowX: 'auto'
        }}
      >
        <NovoPagamentoListData
          carregando={carregandoFinalizadoras}
          list={queryStatus.list}
          selectedList={[getValues(`infMov.pag.${index}.tPag`).toString()]}
          onCardClicked={onCardClicked}
          onCardChecked={() => { }}
          paymentScreen
        />
      </Grid>
    </Grid>
  );
}
