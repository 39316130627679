import { useStyles } from './card-delivery-atendimento-styles';
import { CircularLoading, useThemeQueries } from 'views';
import { SacoDinheiroIcon } from 'views/components/icons/saco-dinheiro-icon';
import { toDecimalString } from 'utils/to-decimal';
import { useCallback, useMemo } from 'react';
import { EnumStatusPedido } from 'model/enums/enum-status-pedido';
import useOnPress from 'utils/useOnPress';
import { EnumStatusProdutoPedido } from 'model/enums/enum-status-produto-pedido';
import { PessoaIcon } from 'views/components/icons/pessoa-icon';
import { CardDeliveryAtendimentoProps } from './card-delivery-atendimento-props';
import { PedidoModel } from 'model/api/gestao/pedido/pedido-model';
import { DeliveryIcon } from 'views/components/icons/delivery-icon';
import { StatusOptions } from './components/menu-options/status-options';
import { usePutPedidoStatus } from 'data/api/gestao/pedido-dados/put-pedido-status';
import { useEmpresaAtual } from 'services/app/hooks/empresa-atual';
import { useToastSaurus } from 'services/app';
import { StatusPedidoMock } from 'data/mocks/status-pedido-mock';
import { ArrowRigthIcon } from 'views/components/icons/arrow-rigth-icon';
import { MenuOptionsModel } from 'views/components/menu-options/model/menu-options-model';
import { useContratoAtual } from 'services/app/hooks/contrato-atual';
import { EnumContratoConfig } from 'model/enums/enum-contrato-config';
import { FreteIcon } from 'views/components/icons';
import { Box, Grid, Typography } from 'views/design-system';

export const CardDeliveryAtendimento = ({
  model,
  onClick,
  onHold,
  atualizarLista
}: CardDeliveryAtendimentoProps) => {
  const classes = useStyles();
  const { theme, isMobile } = useThemeQueries();
  const { putPedidoStatus, carregando: carregandoPut } = usePutPedidoStatus()
  const carregando = carregandoPut
  const { getEmpresaAtual } = useEmpresaAtual()
  const { showToast } = useToastSaurus()
  const { getConfigByCod } = useContratoAtual()
  const produtoEntrega = getConfigByCod(EnumContratoConfig.ProdutoEntrega)

  const handlePress = useCallback(() => {
    onHold(model);
  }, [model, onHold]);

  const handleClick = useCallback(() => {
    onClick(model);
  }, [model, onClick]);

  const EventPressAndClick = useOnPress(handlePress, handleClick, {
    shouldPreventDefault: true,
    delay: 700
  });

  const alterarStatus = useCallback(async (status: EnumStatusPedido) => {
    try {

      const res = await putPedidoStatus(getEmpresaAtual()?.id ?? '', model.id, status)
      if (res.erro) throw res.erro
      atualizarLista()
      showToast('success', `Pedido #${model.codigoPedido} alterado para o status ${StatusPedidoMock.find(x => x.Key === status)?.Value ?? ''}`)
    } catch (err: any) {
      showToast('error', "Problema ao alterar o status.Detalhe: " + err.message)
    }
  }, [atualizarLista, getEmpresaAtual, model.codigoPedido, model.id, putPedidoStatus, showToast])

  const statusOptions = useMemo(() => {
    let options: MenuOptionsModel[] = StatusPedidoMock.filter(x => model.statusPedido.codigo !== x.Key).map((x) => {
      return new MenuOptionsModel(
        x.Value,
        <ArrowRigthIcon tipo="BUTTON" class={classes.editIcon} />,
        () => alterarStatus(x.Key),
      )
    })

    return options
  }, [alterarStatus, classes.editIcon, model.statusPedido.codigo])

  const DeliveryOcupado = useCallback(
    (pedido: PedidoModel) => {
      // DATA CRIACAO PEDIDO
      const horaCriacaoPedido = new Date(pedido?.dataCriacao).getHours();

      const minutosCriacaoPedido = new Date(
        pedido?.dataCriacao
      ).getMinutes();

      const pedidoDate = `${horaCriacaoPedido}:${minutosCriacaoPedido >= 10
        ? minutosCriacaoPedido
        : '0' + minutosCriacaoPedido
        }`;

      const fillColor = pedido.statusPedido.codigo === EnumStatusPedido.FECHADO
        ? theme.palette.warning.main
        : pedido.statusPedido.codigo ===
          EnumStatusPedido.IMPORTADO
          ? theme.palette.grey[500]
          : ''

      const valorTotal = pedido?.produtos
        .filter(
          (p) =>
            (p.status.codigo ===
              EnumStatusProdutoPedido.DISPONIVEL ||
              p.status.codigo ===
              EnumStatusProdutoPedido.TROCADO) &&
            p.indFin && p.produtoIdReferencia !== produtoEntrega
        )
        .reduce((acc, current) => acc + current.valorTotal, 0)

      const valorTotalEntrega = pedido?.produtos
        .filter(
          (p) =>
            (p.status.codigo ===
              EnumStatusProdutoPedido.DISPONIVEL ||
              p.status.codigo ===
              EnumStatusProdutoPedido.TROCADO) &&
            p.indFin && p.produtoIdReferencia === produtoEntrega
        )
        .reduce((acc, current) => acc + current.valorTotalFrete, 0)

      return (
        <>
          {carregando && <CircularLoading tipo='FULLSIZED' />}
          <Grid
            className={classes.cardContainer}
            style={{
              borderColor: fillColor,
              boxShadow: '0px 3px 4px rgba(0, 0, 0, 0.09)'
            }}
          >
            <div className={classes.btnContainer}>
              <StatusOptions options={statusOptions} />
            </div>
            <div className={classes.cardContent} {...EventPressAndClick}>
              <div className={classes.topContent}>
                <div className={classes.hourContent}>
                  <Typography variant="body1">{pedidoDate}</Typography>
                </div>
                <DeliveryIcon
                  tipo="INPUT"
                  fill={fillColor}
                  style={{ width: '35px' }}
                />
                <Typography className={classes.textInfo}>
                  #{pedido.codigoPedido}
                </Typography>
              </div>
              <div className={classes.topContent}>
                <Box flex alignItems='center'>
                  <PessoaIcon
                    tipo="INPUT"
                    fill={fillColor}
                    style={{ width: isMobile ? '20px' : undefined }}
                  />
                  <Typography className={classes.textInfo}>
                    {pedido.cliente.nomeFantasia.split(' ')[0]}
                  </Typography>
                </Box>
              </div>
              <div className={classes.totalContainer}>
                <SacoDinheiroIcon
                  tipo="INPUT"
                  fill={fillColor}
                  style={{ width: '20px' }}
                />
                <Typography className={classes.textInfo}>
                  R${' '}
                  {toDecimalString(valorTotal, 2)}
                </Typography>
              </div>
              <div className={classes.totalContainer}>
                <FreteIcon
                  tipo="INPUT"
                  fill={fillColor}
                  style={{ width: '20px' }}
                />
                <Typography className={classes.textInfo}>
                  R${' '}
                  {toDecimalString(valorTotalEntrega, 2)}
                </Typography>
              </div>

            </div>
          </Grid>
        </>
      );
    },
    [theme.palette.warning.main, theme.palette.grey, carregando, classes.cardContainer, classes.btnContainer, classes.cardContent, classes.topContent, classes.hourContent, classes.textInfo, classes.totalContainer, statusOptions, EventPressAndClick, isMobile, produtoEntrega]
  );

  return <>{DeliveryOcupado(model)}</>;
};
