import { makeStyles } from "@material-ui/core";


export const useStyles = makeStyles((theme) => ({
  rootAccordionNoPaper: {
    boxShadow: "unset",
    paddingLeft: "0px",
    paddingRight: "0px",
    background: 'transparent',
  },
  title: {
     color: theme.palette.grey[600],
    "& svg": {
      width: 24,
    }
  },
  accordionContent: {
    paddingTop:'0'
  },
  accordionContentNoPadding: {
    paddingLeft: "0px",
    paddingRight: "0px",
    textTransform: "capitalize"
  },
  accordionSumaryContent: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  accordionSumaryContentNoPadding: {},
  divider: {
    width: '100%',
    height: '1px',
    marginTop: '26px',
    marginBottom: '10px',
    backgroundColor: theme.palette.primary.main
  },

  sombreado: {
    boxShadow: ' 0px 2.5px 8px 0px #28293D40',
    border: '.5px inset #28293D40',
    borderRadius: theme.shape.borderRadius,
    '&::before': {
      position: 'relative'
    },

  },

  cardHeader: {
    display: 'flex',
    alignItems: 'center'
  },

  cardContainer: {
    display: "flex",
  },

  gridDivider: {
    padding: '4px 16px 0px 16px',
    marginTop: '-43px'
  }

}));
