import { useThemeQueries } from "views/theme"
import { useDefaultCadastroStyles } from "../../cadastros/components/default-cadastro-styles"
import { AdicionarEmpresaHeader } from "./components/adicionar-empresa-header/adicionar-empresa-header"
import { useStyles } from './adicionar-empresa-styles'
import { FormAdicionarEmpresa } from "views/components/form/master/empresa/form-adicionar-empresa"
import { useToastSaurus } from "services/app"
import { DefaultFormRefs } from "views/components/form/utils"
import { EmpresaFormModel } from "model/app"
import { useCallback, useRef } from "react"
import { usePostEmpresa } from "data/api/gestao/empresa"
import { EmpresaModel } from "model"
import { useHistory } from "react-router-dom"
import { CircularLoading } from "views/components"
import { picker } from "utils/picker"
import { stringNumeros } from "utils/string-numeros"
import { useConfirm } from 'material-ui-confirm';
import { useSessaoAtual } from 'services/app'
import { AdicionarEmpresaIcon, VoltarIcon } from "views/components/icons"
import { Button, Divider, Grid } from "views/design-system"

const AdicionarEmpresaPage = () => {

    const classes = useDefaultCadastroStyles()
    const classesAdd = useStyles()
    const { theme, isMobile } = useThemeQueries();
    const { showToast } = useToastSaurus()
    const { postEmpresa, carregando } = usePostEmpresa()
    const { refreshUser, usuario } = useSessaoAtual()
    const history = useHistory()
    const confirm = useConfirm()

    const empresaFormRefs = useRef<DefaultFormRefs<EmpresaFormModel>>(null);

    const saveNewEmpresa = useCallback(async (model: EmpresaModel) => {
        try {

            if (usuario!.confirmado.toLowerCase() === "false") {
                history.push('/configuracoes')
                throw new Error("E-mail não confirmado. Favor, verifique sua caixa de entrada e confirme.")
            }

            const ret = await postEmpresa(model)

            if (ret.erro)
                throw ret.erro

            showToast('success', 'Empresa cadastrada com sucesso!')

            history.push('/configuracoes')

            await refreshUser(usuario!.originalToken)

        } catch (e: any) {
            showToast('error', e.message)
            empresaFormRefs.current?.fillForm(model)
        }
    }, [history, postEmpresa, refreshUser, showToast, usuario])

    const handleSubmitFormEmpresa = async (modelo: EmpresaFormModel) => {

        confirm({
            title: "Tem certeza disso?",
            description: 'Após clicar em adicionar, não há como voltar atrás.',
            cancellationText: "Voltar",
            cancellationButtonProps: {
                color: 'default',
            },
            confirmationText: 'Adicionar',
        }).then(() => {
            const empresaToCreate = picker<EmpresaModel>(modelo, new EmpresaModel())
            empresaToCreate.cpfcnpj = stringNumeros(modelo.cpfcnpj)

            return saveNewEmpresa(empresaToCreate)
        })

    };

    return (
        <Grid className={classes.root}>
            {carregando ? <CircularLoading tipo="FULLSIZED" /> : null}
            <Grid className={classes.header}>
                <AdicionarEmpresaHeader title="Adicionar Empresa" />
            </Grid>
            <Grid
                className={classes.defaultContainer}
                container
                alignItems="flex-start"
                justifyContent="center"
                style={{
                    padding: theme.spacing(1),
                    backgroundColor: isMobile ? '#FFF' : ''
                }}
            >
                <Grid
                    container
                    spacing={1}
                    style={{ maxWidth: 1100, paddingTop: 16 }}
                    className={classesAdd.containerGrid}
                >
                    <Grid className={!isMobile ? classesAdd.cardForm : ""}>
                        <FormAdicionarEmpresa
                            ref={empresaFormRefs}
                            loading={false}
                            onSubmit={handleSubmitFormEmpresa}
                            showLoading={false}
                        />
                        <Grid container spacing={2} style={{ marginTop: 40 }}>
                            <Grid item xs={12}>
                                <Divider style={{ backgroundColor: '#ECEDED', height: 2 }} />
                            </Grid>
                            <Grid item xs={4}>
                                <Button
                                    variant="outlined"
                                    size="large"
                                    color="primary"
                                    onClick={() => history.goBack()}
                                    fullWidth
                                >
                                    <VoltarIcon tipo='BUTTON' />
                                    Voltar
                                </Button>
                            </Grid>
                            <Grid item xs={8}>
                                <Button
                                    variant="contained"
                                    size="large"
                                    color="primary"
                                    onClick={() => empresaFormRefs.current?.submitForm()}
                                    fullWidth
                                >
                                    <AdicionarEmpresaIcon tipo='BUTTON_PRIMARY' />
                                    Adicionar
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default AdicionarEmpresaPage