import { MovRegistrarParcelasModel } from 'model/api/gestao/mov-pag/mov-registrar';
import { MovSimplesPagamentoModel } from 'model/api/gestao/movimentacao/simples/mov-simples-pagamento-model';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { useCallback, useEffect, useState } from 'react';
import { useToastSaurus } from 'services/app';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { DialogVencimentoPagamento } from 'views/components/dialog/dialog-vencimento-pagamento/dialog-vencimento-pagamento';

class VencimentoPagamentoDialogProps {
    constructor(
        public aberto: boolean = false,
        public pagamento: MovSimplesPagamentoModel = new MovSimplesPagamentoModel(),
        public parcelas: MovRegistrarParcelasModel[] = [],
        public callback: (parcelas: MovRegistrarParcelasModel[]) => void = () => { },
        public cancelar: () => void = () => { },
    ) { }
}

export const VencimentoPagamentoDialog = () => {

    const { addHandler, removeHandler } = useEventTools();
    const { showToast } = useToastSaurus();

    const [modalState, setModalState] = useState<VencimentoPagamentoDialogProps>(new VencimentoPagamentoDialogProps());

    const modalAlterado = useCallback((props: VencimentoPagamentoDialogProps) => {
        setModalState({ ...props });
    }, []);

    useEffect(() => {
        addHandler(AppEventEnum.VencimentoPagamentoDialog, modalAlterado);

        return () => {
            removeHandler(AppEventEnum.VencimentoPagamentoDialog, modalAlterado);
        };
    }, [addHandler, modalAlterado, removeHandler, showToast]);

    return (
        <>
            {modalState.aberto && (
                <DialogVencimentoPagamento
                    {...modalState}
                />
            )}
        </>
    );
};