import { CardNaoEncontrado } from 'views/components/cards';
import { InformacaoIcon } from 'views/components/icons';
import { PedidoModel } from 'model/api/gestao/pedido/pedido-model';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { EnumStatusPedido } from 'model/enums/enum-status-pedido';
import { AccordionListStatus } from './components/accordion-list-status/accordion-list-status';
import { validaCaracter } from 'utils/valida-caracter';
import { Grid } from 'views/design-system';

export interface DeliveryListDataProps {
  pedidos: Array<PedidoModel>;
  carregando: boolean;
  onCardSelected: (pedido: PedidoModel) => any;
  onCardHolded: (pedido: PedidoModel) => void;
  atualizarLista: () => void;
  pesquisaPedido: string;
}

export const DeliveryListData = (props: DeliveryListDataProps) => {

  const onCardSelected = (pedido: PedidoModel) => {
    props.onCardSelected(pedido);
  };

  const onCardHolded = async (pedido: PedidoModel) => {
    props.onCardHolded(pedido);
  };
  const [termoPesquisado, setTermoPesquisado] = useState<string>('')


  const ajustarTermo = useCallback(() => {
    setTermoPesquisado(props.pesquisaPedido)
  }, [props.pesquisaPedido])

  const search = useCallback(() => {
    const pesquisa = validaCaracter(termoPesquisado).toLowerCase()
    const res = props.pedidos
      .filter((p) => {
        if (pesquisa.length === 0) {
          return p
        }
        if (validaCaracter(p.codigoPedido).toLowerCase().includes(pesquisa)) {
          return p
        };

        if (validaCaracter(p.cliente.nomeFantasia).toLowerCase().includes(pesquisa)) {
          return p
        };

        return false
      })
    return res
  }, [props.pedidos, termoPesquisado]);

  const timer = useRef({} as NodeJS.Timeout);
  const ultimoTermoPesquisado = useRef('');

  useEffect(() => {
    clearTimeout(timer.current);
    if (
      ultimoTermoPesquisado.current !== props.pesquisaPedido ||
      props.pesquisaPedido.length === 0
    ) {
      timer.current = setTimeout(async () => {
        ajustarTermo();
      }, 500);
    }
    return () => {
      clearTimeout(timer.current);
    };
  }, [ajustarTermo, props.pesquisaPedido, search]);

  const pedidoPorStatus = useMemo(() => {
    return Array.from(new Set<EnumStatusPedido>(search().map(p => p.statusPedido.codigo)))
  }, [search])

  return (
    <>
      {search().length === 0 && !props.carregando && (
        <Grid item xs={12}>
          <CardNaoEncontrado
            mensagem="Nenhum Pedido encontrado."
            icon={<InformacaoIcon tipo="GERAL" />}
          />
        </Grid>
      )}
      <Grid item xs={12} p={2} >
        {pedidoPorStatus.sort((a, b) => a - b).map((p, i) => {
          const key = `accordion-list-status-pedido-${i}`
          return (
            <AccordionListStatus
              atualizarLista={props.atualizarLista}
              index={i}
              onCardHolded={onCardHolded}
              onCardSelected={onCardSelected}
              pedidos={search()}
              statusPedido={p}
              key={key}
            />
          )
        })}
      </Grid>
    </>
  );
};
