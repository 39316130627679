import classNames from "classnames"
import { ButtonProps } from "./button-props"
import { useStyles } from "./button-styles";
import { forwardRef } from "react";
import { handleClickFeedbackTatil } from 'utils/feedback-tatil-fn';
import React from "react";
import { LoadingOverlay } from "../loading-overlay/loading-overlay";

export const Button = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
    const styles = useStyles();
    const [loading, setLoading] = React.useState(false);
    const variant = props.variant || 'text';
    const color = props.color || 'default';
    const rounded = props.rounded && props.size === 'small' ? styles.sizeSmallRounded :
        props.rounded && props.size === 'large' ? styles.sizeLargeRounded :
            props.rounded ? styles.sizeMediumRounded : undefined;
    const type = props.type || 'button'
    const handleMouseDown = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        // Remove o foco após o clique
        (event.target as HTMLButtonElement).blur();
    };
    const removeFocusOnTouch = (e: React.TouchEvent<HTMLButtonElement>) => {
        e.currentTarget.blur();
    };
    const disabled = props.disabled;
    return <button tabIndex={props.tabIndex} type={type} ref={ref} onMouseDown={handleMouseDown} disabled={disabled} className={classNames(
        props.classes?.root,
        props.classes?.text,
        props.fullWidth ? styles.fullWidth : undefined,

        color === 'primary' && variant === 'contained' ? styles.containedPrimary :
            color === 'secondary' && variant === 'contained' ? styles.containedSecondary :
                color === 'default' && variant === 'contained' ? styles.containedDefault :
                    color === 'inherit' && variant === 'contained' ? styles.containedInherit :
                        color === 'error' && variant === 'contained' ? styles.containedError : undefined,

        color === 'primary' && variant === 'outlined' ? styles.outlinedPrimary :
            color === 'secondary' && variant === 'outlined' ? styles.outlinedSecondary :
                color === 'default' && variant === 'outlined' ? styles.outlinedDefault :
                    color === 'inherit' && variant === 'outlined' ? styles.outlinedInherit :
                        color === "error" && variant === "outlined" ? styles.outlinedError : undefined,

        color === 'primary' && variant === 'text' ? styles.textPrimary :
            color === 'secondary' && variant === 'text' ? styles.textSecondary :
                color === 'default' && variant === 'text' ? styles.textDefault :
                    color === 'inherit' && variant === 'text' ? styles.textInherit :
                        color === 'error' && variant === 'text' ? styles.textError : undefined,

        props.size === 'large' ? styles.sizeLarge :
            props.size === 'small' ? styles.sizeSmall : styles.sizeMedium,

        variant === 'text' ? styles.text : variant === 'outlined' ? styles.outlined : variant === 'contained' ? styles.contained : undefined,

        variant === 'contained' && props.size === 'large' ? styles.containedSizeLarge :
            variant === 'contained' && props.size === 'small' ? styles.containedSizeSmall : undefined,

        variant === 'outlined' && props.size === 'large' ? styles.outlinedSizeLarge :
            variant === 'outlined' && props.size === 'small' ? styles.outlinedSizeSmall : undefined,

        variant === 'text' && props.size === 'large' ? styles.textSizeLarge :
            variant === 'text' && props.size === 'small' ? styles.textSizeSmall : undefined,

        rounded,

        styles.base,
        styles.root,

        props.className,


    )} style={props.style} id={props.id} onClick={async (e) => {
        if (props.onClick) {
            e.preventDefault();
            e.stopPropagation();
        }

        if (loading || disabled) return;
        await handleClickFeedbackTatil();
        setLoading(true);

        //OBS: setTimeout para garantir que vai dar tempo de fazer o render do botão antes de iniciar o processo do click e travar o DOM
        setTimeout(async () => {
            if (props.onClick) {
                await props.onClick(e);
            }
            setLoading(false);
        }, 20)

    }} onTouchStart={removeFocusOnTouch}>
        {loading && (
            <LoadingOverlay variant={variant === 'contained' ? 'dark' : 'light'} />
        )}
        <span className={classNames(styles.span, props.classes?.label, 'buttonSpan')}>
            {props.children}
        </span>
    </button >
});