import { SwitchSaurus } from 'views/components/controles/switches/switch-saurus';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { useCallback } from 'react';
import { useThemeQueries } from 'views/theme';
import { useStyles } from './button-add-sub-styles'
import { ModuloPlanoModel } from 'model/api/gestao/plano/plano-modulo-model';
import { Controller, Control } from 'react-hook-form'
import { PlanoFormModel } from 'model/api/gestao/plano/plano-form-model';
import { Typography } from 'views/design-system';

interface ModuloPlanoModelSerializado extends ModuloPlanoModel {
  indexOriginal: number;
}

interface ButtonAddESubProps {
  model: ModuloPlanoModelSerializado;
  alterarQtd: (id: number, qtde: number) => void;
  value: number;
  control: Control<PlanoFormModel, any>;
  error?: boolean;
}

export const BotoesAddESub = ({
  model,
  alterarQtd,
  error,
  control,
  value
}: ButtonAddESubProps) => {
  const classes = useStyles();
  const { isMobile } = useThemeQueries()

  const alterarQuantidade = useCallback((qtd: string) => {
    if (value === -1) {
      alterarQtd(model.indexOriginal, 0)
      return
    }

    if (isNaN(Number(qtd))) {
      alterarQtd(model.indexOriginal, 0)
      return
    }

    const qtdN = Number(qtd)
    alterarQtd(model.indexOriginal, qtdN)

  }, [alterarQtd, model.indexOriginal, value])

  return (
    <div className={classes.containerQtde}>
      {model.quantidadeMaxima > 1 || model.quantidadeMaxima === -1 ? (
        <div>
          <div style={{
            display: 'flex',
            justifyItems: 'flex-end',
            maxWidth: 150,
            alignItems: 'center'
          }}>

            <Controller
              render={({ field }) => (
                <TextFieldSaurus
                  style={{ maxWidth: 80 }}
                  tipo={model.quantidadeMinima === -1 ? "TEXTO" : "NUMERO"}
                  label="Quant."
                  size="small"
                  {...field}
                  onChange={ev => alterarQuantidade(ev.target.value)}
                  error={error}
                />
              )}
              name={`modulos.${model.indexOriginal}.quantidadeContratada`}
              control={control}
            />
          </div>
          <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            gap: 5
          }}>
            <div>
              <Typography variant="caption" color='primary'>
                Min:
              </Typography>
              <Typography variant="caption">
                {model.quantidadeMinima === -1 ? 0 : model.quantidadeMinima}
              </Typography>
            </div>
            <div>
              <Typography variant="caption" color='primary'>
                Max:
              </Typography>
              <Typography variant="caption">
                {model.quantidadeMaxima === -1 ? 9999999 : model.quantidadeMaxima}
              </Typography>
            </div>
          </div>
        </div>
      ) : (
        <div style={{
          display: 'flex',
          justifyContent: isMobile ? 'flex-start' : 'flex-end',
          width: '100%'
        }}>
          <Controller
            render={() => (
              <SwitchSaurus
                variant='LIGHT'
                disabled={model.quantidadeMinima === 1 && value === 1}
                onClick={() => {
                  if (value === 1) {
                    return alterarQtd(model.indexOriginal, 0)
                  }

                  alterarQtd(model.indexOriginal, 1)
                }}
                value={value}
                checked={value === 1}
              />
            )}
            name={`modulos.${model.indexOriginal}.quantidadeContratada`}
            control={control}
          />
        </div>
      )}
    </div>
  );
};