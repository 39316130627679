import { useThemeQueries } from "views/theme"
import { DialogSaurus } from "../dialog-saurus/dialog-saurus"
import { DialogCheckoutCartaoProps } from "./dialog-checkout-cartao-props"
import { useCheckout } from "services/app/hooks/checkout"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { FormCheckoutCartao } from "views/components/form/checkout-cartao/form-checkout-cartao/form-checkout-cartao"
import { CheckoutCartaoFormModel } from "model/app/forms/checkout-cartao/checkout-cartao"
import { DefaultFormRefs } from "views/components/form/utils"
import { Button, Grid, Typography } from "views/design-system"
import { useStyles } from "./dialog-checkout-cartao-styles"
import { BrandCardModel } from "model/api/safrapay/checkout/brand-card-model"
import { picker } from "utils/picker"
import { DataCardModel } from "model/api/safrapay/checkout/data-card-model"
import { useCadastros, useToastSaurus } from "services/app"
import { EnumAcquireType, EnumDocumentType, EnumEntityType, EnumGenderType, EnumInstallmentType, EnumPhoneType, PhoneModel } from "model/api/safrapay/checkout/charge-authorization-model"
import { useMovAtual } from "services/app/hooks/mov-atual"
import { stringNumeros } from "utils/string-numeros"
import { EnumTipoPessoaContato } from "model/enums/enum-tipo-pessoa-contato"
import { EnumSexo } from "model/enums/enum-sexo"
import { isEmpty } from "lodash"
import { OkIcon, VoltarIcon } from "views/components/icons"
import { CircularLoading } from "views/components/utils"
import { MovSimplesPagamentoModel } from "model/api/gestao/movimentacao/simples/mov-simples-pagamento-model"
import { EnumAmbientePagamento } from "model/enums/enum-ambiente-pagamento"
import { VariaveisAmbiente } from "config"

export const DialogCheckoutCartao = ({ openned, credenciamento, pagamento, reject, resolve }: DialogCheckoutCartaoProps) => {

    const refEditForm = useRef<DefaultFormRefs<CheckoutCartaoFormModel>>(null);
    const { getMov } = useMovAtual()
    const classes = useStyles()
    const { isMobile } = useThemeQueries()
    const { showToast } = useToastSaurus()
    const { fecharDialogCheckoutCartao } = useCadastros()
    const [loadingInterno, setLoadingInterno] = useState<boolean>(false)

    const isDev = credenciamento.ambiente === EnumAmbientePagamento.Homologacao || VariaveisAmbiente.isDev

    const credenciais = useMemo(() => ({
        merchantCredential: credenciamento.cnpj,
        merchantToken: credenciamento.merchantToken
    }), [credenciamento.cnpj, credenciamento.merchantToken])

    const {
        authorizeChargeOnCard,
        createTemporaryCard,
        getBrand,
        loading,
        setCredentials
    } = useCheckout()

    const carregando = loadingInterno || loading

    const handleCredentials = useCallback(() => {
        try {
            setCredentials(credenciais)
        } catch (err: any) {
            console.log(err.message)
        }
    }, [credenciais, setCredentials])

    useEffect(() => {
        handleCredentials()
    }, [handleCredentials])

    const generoMock = useCallback((genero: EnumSexo = EnumSexo.NaoInformado) => ({
        [EnumSexo.Feminino]: EnumGenderType.Female,
        [EnumSexo.Masculino]: EnumGenderType.Male,
        [EnumSexo.NaoInformado]: EnumGenderType.None,
    }[genero]), []);

    const handleSubmit = async (model: CheckoutCartaoFormModel) => {
        try {
            setLoadingInterno(true)
            const mov = getMov()
            if (!mov) return
            const cliente = mov.cliente
            const cpfcnpj = stringNumeros(cliente?.cpfcnpj ?? '')
            const contatos = cliente?.contatos ?? []
            const telefone = stringNumeros(contatos
                .find((contato) => contato.tipo === EnumTipoPessoaContato.TELEFONE)
                ?.valor ?? '')
            const email = contatos
                .find((contato) => contato.tipo === EnumTipoPessoaContato.EMAIL)
                ?.valor ?? ''

            const card = picker<DataCardModel>(model, new DataCardModel())

            const brandRes = await getBrand(model.number)

            const temporaryCard = await createTemporaryCard(
                new BrandCardModel(brandRes.brand),
                card
            )

            const telefoneModel: PhoneModel | undefined =
                isEmpty(telefone)
                    ? undefined
                    : {
                        number: telefone.substring(2, 11) ?? "",
                        areaCode: telefone.substring(0, 2) ?? "",
                        countryCode: "55",
                        type: EnumPhoneType.Mobile,
                    } as PhoneModel

            const document = cpfcnpj || card.document
            const response = await authorizeChargeOnCard({
                ...credenciais,
                acquirer: isDev ? EnumAcquireType.Simulador : EnumAcquireType.Safrapay,
                amount: pagamento.vPag,
                cardToken: temporaryCard,
                cliente: {
                    address: undefined, // PREENCHER CASO FOR DELIVERY OU SE O USUÁRIO TIVER ENDEREÇO???
                    document: document,
                    documentType: document.length === 14 ? EnumDocumentType.Cnpj : EnumDocumentType.Cpf,
                    email: email || undefined,
                    entityType: document.length === 14 ? EnumEntityType.Company : EnumEntityType.IndividualMerchant,
                    gender: generoMock(cliente?.sexo),
                    name: cliente?.nome || '',
                    phone: telefoneModel,
                },
                installmentNumber: pagamento.nParcelas <= 1 ? 1 : pagamento.nParcelas,
                installmentType: pagamento.nParcelas > 1 ? EnumInstallmentType.Merchant : EnumInstallmentType.None,
            })
            const transacao = response.charge.transactions[0]
            const cartao = transacao.card

            if (response.charge.chargeStatus === 'NotAuthorized') {
                throw new Error('Pagamento não autorizado. Verifique se o cartão tem limite disponível ou está desbloqueado para transações, e tente novamente para concluir seu pedido.')
            }
            const pagamentoAtual: MovSimplesPagamentoModel = {
                ...structuredClone(pagamento),
                codNsu: response.charge.nsu,
                numCartao: cartao.cardNumber,
                nomeCartao: cartao.cardholderName,
                bandeira: cartao.brandName,
                adquirente: transacao.acquirer,
                cAut: transacao.authorizationCode,
            }
            resolve(pagamentoAtual)
            fecharDialogCheckoutCartao()
        }
        catch (err: any) {
            if (isEmpty(err.message)) {
                showToast('error', 'Ocorreu um problema ao continuar o pagamento, verifique as credenciais informadas na Forma de Pagamento e tente novamente.')
            }
            showToast('error', err.message)
        }
        finally {
            setLoadingInterno(false)
        }
    }

    const handleSair = () => {
        reject()
        fecharDialogCheckoutCartao()
    }

    return (
        <DialogSaurus
            aberto={openned}
            tamanho='xs'
            fullScreen={isMobile}
            bottomArea={
                <Grid p={2} className={classes.containerFooter}>
                    <Grid container className={classes.acoes} spacing={2}>
                        <Grid item xs={6}>
                            <Button
                                disabled={carregando}
                                onClick={handleSair}
                                variant="outlined"
                                color="primary"
                                fullWidth
                            >
                                <VoltarIcon tipo="BUTTON" />
                                Voltar
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button
                                onClick={() => refEditForm.current?.submitForm()}
                                variant="contained"
                                color="primary"
                                fullWidth
                            >
                                <OkIcon tipo="BUTTON_PRIMARY" />
                                Confirmar
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            }
        >
            {carregando && <CircularLoading tipo="FULLSIZED" />}
            <Grid className={classes.cardContainer}>
                <Grid mb={2}>
                    <Typography color="primary" align="center" className={classes.title}>
                        Dados do Cartão
                    </Typography>
                </Grid>
                <Typography variant="body1" className={classes.descricao} align="center">
                    Para concluir a venda, o cliente deve inserir os dados do cartão no formulário e confirmar para processar o pagamento.
                </Typography>
                <Grid className={classes.cardContent} spacing={2}>
                    <FormCheckoutCartao
                        ref={refEditForm}
                        onSubmit={handleSubmit}
                        loading={false}
                        showLoading={false}
                    />
                </Grid>
            </Grid>
        </DialogSaurus>
    )
}