import { useTheme } from '@material-ui/core';
import { TouchoneDBPrimary } from 'database/touchone-database';
import { EnumSincronizacaoGeral } from 'model/enums/enum-sincronizacao-geral';
import { EnumSincronizacaoGeralStatus } from 'model/enums/enum-sincronizacao-geral-status';
import { EnumTpLancamentoSessaoValor } from 'model/enums/enum-tipo-lancamento-sessao-valor';
import { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useToastSaurus } from 'services/app';
import { useVenda } from 'services/app/hooks/gerenciar-venda';
import { usePDV } from 'services/app/hooks/pdv';
import { useSessaoPDV } from 'services/app/hooks/sessao-pdv';
import { useSincronizacaoGeral } from 'services/app/hooks/sincronizar-dados';
import { DespesaIcon } from 'views/components/icons/despesa-icon';
import { LeituraCaixaIcon } from 'views/components/icons/leitura-caixa-icon';
import { PdvIcon } from 'views/components/icons/pdv-icon';
import { ReceitaIcon } from 'views/components/icons/receita-icon';
import { ReforcoIcon } from 'views/components/icons/reforco-icon';
import { SangriaIcon } from 'views/components/icons/sangria-icon';
import { useThemeQueries } from 'views/theme';
import { LeituraDeCaixaBody } from '../sessao-leitura-caixa/components/leitura-de-caixa-body/leitura-de-caixa-body';
import { useStyles } from './gerenciar-sessao-page-styles';
import { FechamentoPdvIcon } from 'views/components/icons/fechamento-pdv-icon';
import classNames from 'classnames';
import { useVendaHeaderStore } from 'views/components/headers/venda-header/stores/venda-header-store';
import { Button, Grid, Typography } from 'views/design-system';

const GerenciarSessaoPage = () => {
  // HOOKS
  const {
    verificarItensParaSincronizacao,
    getSessao,
    agruparValores,
    verificarItensParaFechamento
  } = useSessaoPDV();
  const { getVendas } = useVenda();
  const { getPDV } = usePDV();
  const {
    getStatusSincronizacaoAtual,
    setStatusSincronizacaoAtual,
    iniciarSincronizacaoGeral
  } = useSincronizacaoGeral();
  const { showToast } = useToastSaurus();

  // AUX
  const classes = useStyles();
  const theme = useTheme();
  const { push: historyPush } = useHistory();
  const { isMobile } = useThemeQueries();

  const handleClickGoToSangria = useCallback(() => {
    return historyPush('/venda-simples/gerenciar-sessao-sangria');
  }, [historyPush]);

  const handleClickGoToDespesa = useCallback(() => {
    return historyPush('/venda-simples/gerenciar-sessao-despesa');
  }, [historyPush]);

  const handleClickGoToReforco = useCallback(() => {
    return historyPush('/venda-simples/gerenciar-sessao-reforco');
  }, [historyPush]);

  const handleClickGoToReceita = useCallback(() => {
    return historyPush('/venda-simples/gerenciar-sessao-receita');
  }, [historyPush]);

  const handleClickGoToLeituraCaixa = useCallback(async () => {
    const sincEmAndamento = getStatusSincronizacaoAtual();

    if (
      sincEmAndamento &&
      sincEmAndamento.status === EnumSincronizacaoGeralStatus.EmAndamento
    ) {
      showToast(
        'info',
        'Já existe uma sincronização em andamento, aguarde até a finalização da sincronização atual.'
      );
      return;
    }

    const vendas = await getVendas();

    if (vendas && vendas.length > 0) {
      if (!navigator.onLine) {
        showToast(
          'info',
          'Sem acesso a internet, não foi possível realizar a sincronização.'
        );
        return;
      }

      await iniciarSincronizacaoGeral(() =>
        historyPush(isMobile ? '/venda-simples/gerenciar-sessao-leitura-caixa' : '/venda-simples/gerenciar-sessao')
      );
      return;
    }

    const itens = await verificarItensParaSincronizacao();

    if (itens && itens.length > 0) {
      const agrupado = agruparValores(itens, 20);

      setStatusSincronizacaoAtual({
        ativo: false,
        sincronizandoAtual: 0,
        status: EnumSincronizacaoGeralStatus.EmAndamento,
        tipo: EnumSincronizacaoGeral.SessaoValores,
        totalAsincronizar: 0
      });

      return historyPush({
        pathname: '/venda-simples/gerenciar-sessao-sincronizar',
        state: { itens: agrupado }
      });
    }
    return historyPush(isMobile ? '/venda-simples/gerenciar-sessao-leitura-caixa' : '/venda-simples/gerenciar-sessao')
  }, [
    isMobile,
    agruparValores,
    getStatusSincronizacaoAtual,
    getVendas,
    historyPush,
    iniciarSincronizacaoGeral,
    setStatusSincronizacaoAtual,
    showToast,
    verificarItensParaSincronizacao
  ]);

  const handleClickGoToFechamentoCaixa = useCallback(async () => {
    const sessao = await getSessao();

    if (!sessao) {
      return historyPush('/');
    }

    const sincEmAndamento = getStatusSincronizacaoAtual();

    if (
      sincEmAndamento &&
      sincEmAndamento.status === EnumSincronizacaoGeralStatus.EmAndamento
    ) {
      showToast(
        'info',
        'Já existe uma sincronização em andamento, aguarde até a finalização da sincronização atual.'
      );
      return;
    }

    const vendas = await getVendas();

    if (vendas && vendas.length > 0) {
      if (!navigator.onLine) {
        showToast(
          'info',
          'Sem acesso a internet, não foi possível realizar a sincronização.'
        );
        return;
      }

      await iniciarSincronizacaoGeral();
      return;
    }

    const itens = await TouchoneDBPrimary.sessaoValores
      .filter(
        (valores) =>
          valores.tpLancamento === EnumTpLancamentoSessaoValor.FECHAMENTO &&
          valores.sessaoId === sessao.id &&
          valores.caixaId === getPDV()!.id &&
          !valores.dataSincronizacao
      )
      .toArray();

    if (itens && itens.length > 0) {
      const sincEmAndamento = getStatusSincronizacaoAtual();

      if (
        sincEmAndamento &&
        sincEmAndamento.status === EnumSincronizacaoGeralStatus.EmAndamento
      ) {
        showToast(
          'info',
          'Já existe uma sincronização em andamento, aguarde até a finalização da sincronização atual.'
        );
        return;
      }

      const valoresNaoSincronizados = await verificarItensParaFechamento();

      if (!valoresNaoSincronizados) {
        const itens = await verificarItensParaSincronizacao();
        if (!itens || itens.length === 0) {
          return historyPush('/venda-simples/gerenciar-sessao');
        }

        const agrupado = agruparValores(itens, 20);

        setStatusSincronizacaoAtual({
          ativo: false,
          sincronizandoAtual: 0,
          status: EnumSincronizacaoGeralStatus.EmAndamento,
          tipo: EnumSincronizacaoGeral.SessaoValores,
          totalAsincronizar: 0
        });

        historyPush({
          pathname: '/venda-simples/gerenciar-sessao-sincronizar',
          state: { itens: agrupado },
          search: '?redirect=fechamento'
        });
        return;
      }

      setStatusSincronizacaoAtual({
        ativo: false,
        sincronizandoAtual: 0,
        status: EnumSincronizacaoGeralStatus.EmAndamento,
        tipo: EnumSincronizacaoGeral.SessaoValores,
        totalAsincronizar: 0
      });

      return historyPush({
        pathname: '/venda-simples/gerenciar-sessao-sincronizar-fechamento',
        state: { itens: valoresNaoSincronizados }
      });
    }

    return historyPush('/venda-simples/gerenciar-sessao-fechamento-caixa');
  }, [
    agruparValores,
    getPDV,
    getSessao,
    getStatusSincronizacaoAtual,
    getVendas,
    historyPush,
    iniciarSincronizacaoGeral,
    setStatusSincronizacaoAtual,
    showToast,
    verificarItensParaFechamento,
    verificarItensParaSincronizacao
  ]);

  const setHeaderProps = useVendaHeaderStore(state => state.setHeaderProps);

  useEffect(() => {
    setHeaderProps({
      title: 'Resumo de Sessão',
      showMenuPdv: true,
    })
  }, [setHeaderProps])

  return (
    <>
      <Grid className={`${classes.container}`}>
        <Grid
          className={classes.content}
        >
          <Grid className={classes.contentAcoes}>
            <Grid
              container
              className={classes.contentCima}>
              <PdvIcon
                fill={theme.palette.primary.main} />
              <Typography variant='h3'>Operação de Caixa</Typography>
              <Typography variant='caption'>Nesta sessão você pode realizar movimentação de valor no caixa para <b>reforço ou retirada</b>, lançar um pagamento de <b>despesa ou uma receita</b> identificada.
                <br />
                A cada período é recomendado realizar o <b>fechamento do caixa</b> para apurar diferenças de pagamentos manuais.</Typography>
            </Grid>
            <Grid container className={classes.contentBotoes}>

              <Grid item xs={6} lg={3} className={classes.contentGridBotoes}>
                <Button
                  fullWidth
                  className={classes.button}
                  onClick={handleClickGoToSangria}
                >
                  <Grid container justifyContent='center' alignItems='center'>
                    <SangriaIcon fill={theme.palette.primary.main} />

                    <Grid className={classNames(classes.buttonGrid, classes.buttonGridPequeno)} style={{ maxWidth: isMobile ? 60 : 200 }}>
                      <Typography variant='h6'>Sangria</Typography>
                      {!isMobile && (
                        <Typography variant='caption'>Saída de valor para diminuir quantidade em caixa.</Typography>
                      )}
                    </Grid>
                  </Grid>
                </Button>
              </Grid>
              <Grid item xs={6} lg={3} className={classes.contentGridBotoes}>
                <Button
                  fullWidth
                  className={classes.button}
                  onClick={handleClickGoToReforco}
                >
                  <Grid container justifyContent='center' alignItems='center'>
                    <ReforcoIcon fill={theme.palette.primary.main} />
                    <Grid className={classNames(classes.buttonGrid, classes.buttonGridPequeno)} style={{ maxWidth: isMobile ? 60 : 200 }}>
                      <Typography variant='h6'>Reforço</Typography>
                      {!isMobile && (
                        <Typography variant='caption'>Entrada de valor para troco e outras operações.</Typography>
                      )}
                    </Grid>
                  </Grid>
                </Button>
              </Grid>
              <Grid item xs={6} lg={3} className={classes.contentGridBotoes}>
                <Button
                  fullWidth
                  className={classes.button}
                  onClick={handleClickGoToDespesa}
                >
                  <Grid container justifyContent='center' alignItems='center'>
                    <DespesaIcon
                      fill={theme.palette.primary.main}
                    />
                    <Grid className={classNames(classes.buttonGrid, classes.buttonGridPequeno)} style={{ maxWidth: isMobile ? 65 : 200 }}>
                      <Typography variant='h6'>Despesa</Typography>
                      {!isMobile && (
                        <Typography variant='caption'>Pagamento de fornecedor ou outro tipo de saída (manual).</Typography>
                      )}
                    </Grid>
                  </Grid>
                </Button>
              </Grid>
              <Grid item xs={6} lg={3} className={classes.contentGridBotoes}>
                <Button
                  fullWidth
                  className={classes.button}
                  onClick={handleClickGoToReceita}
                >
                  <Grid container justifyContent='center' alignItems='center'>
                    <ReceitaIcon
                      fill={theme.palette.primary.main}
                    />
                    <Grid className={classNames(classes.buttonGrid, classes.buttonGridPequeno)} style={{ maxWidth: isMobile ? 60 : 200 }}>
                      <Typography variant='h6'>Receita</Typography>
                      {!isMobile && (
                        <Typography variant='caption'>Recebimento de contas ou outra entrada de valor (manual).</Typography>
                      )}
                    </Grid>
                  </Grid>
                </Button>
              </Grid>
              {isMobile && (
                <Grid item xs={12} className={classes.contentGridBotoes}>
                  <Button
                    fullWidth
                    className={classes.button}
                    onClick={handleClickGoToLeituraCaixa}
                  >
                    <Grid container justifyContent='center' alignItems='center'>
                      <LeituraCaixaIcon
                        fill={theme.palette.primary.main}
                      />
                      <Grid className={classes.buttonGrid} style={{ maxWidth: 290 }}>
                        <Typography variant='h6'>Pré Conferência</Typography>
                        <Typography variant='caption'>Visualizar via de conferência de caixa para exibição de mobimentos e vendas realizadas.</Typography>
                      </Grid>
                    </Grid>
                  </Button>
                </Grid>
              )}
              <Grid item xs={12} className={classes.contentGridBotoes}>
                <Button
                  fullWidth
                  style={{
                    border: '1px solid',
                    borderColor: theme.palette.primary.main,
                  }}
                  className={classes.button}
                  onClick={handleClickGoToFechamentoCaixa}
                >
                  <Grid container flexDirection='row' justifyContent='center' alignItems='center'>
                    <FechamentoPdvIcon
                      fill={theme.palette.primary.main}
                    />
                    <Grid className={classes.buttonGrid} style={{ maxWidth: 290 }}>
                      <Typography variant='h6'>Fazer Fechamento</Typography>
                      <Typography variant='caption'>Fazer conferência e concluir a sessão deste usuário para apurar vendas realizadas e possíveis diferenças.</Typography>
                    </Grid>
                  </Grid>
                </Button>
              </Grid>
            </Grid>
          </Grid>
          {!isMobile && (
            <Grid style={{ flex: 1, minWidth: 360 }}>
              <LeituraDeCaixaBody />
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default GerenciarSessaoPage;
