import React from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { MenuOptionsProps } from "./status-options-props";
import { EditarIcon } from "views/components/icons";
import { useStyles } from "./status-options-styles";
import { useThemeQueries } from "views/theme";
import { Button } from "views/design-system";

export const StatusOptions = ({ options, headerPage = false }: MenuOptionsProps) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const classes = useStyles()
  const { isMobile } = useThemeQueries()

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const wrapperClick = (click: () => any) => {
    click();
    handleClose();
  };

  return (
    <div>
      <Button variant="contained" color="primary" size='small' aria-label="more" aria-haspopup="true" onClick={handleClick} className={classes.btnStatus}>
        <EditarIcon tipo="BUTTON_PRIMARY" />
        {isMobile ? 'Status' : "Alterar Status"}
      </Button>
      <Menu anchorEl={anchorEl} keepMounted open={open} onClose={handleClose}>
        {options.map((option, index) => option ? (
          <MenuItem key={index} onClick={() => wrapperClick(option.click!)}>
            {option.icon}
            {option.label}
          </MenuItem>
        ) : null)}
      </Menu>
    </div>
  );
};
