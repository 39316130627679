import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    card: {
        width: 52,
        height: 52,
        margin: 0,
        marginBottom: -2
    },
    cardElo: {
        width: 72,
        height: 32
    },
    loading: {
        marginRight: 0,
    }
}))