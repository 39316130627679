import { CardNaoEncontrado } from 'views/components/cards';
import { InformacaoIcon } from 'views/components/icons';
import { PedidoModelComanda } from 'model/api/gestao/pedido/pedido-comanda';
import { CardComandaAtendimento } from 'views/components/cards/card-comanda-atendimento/card-comanda-atendimento';
import { guidEmpty } from 'utils/guid-empty';
import { SaloesModel } from 'model/api/gestao/saloes/saloes-model';
import { useRef } from 'react';
import { useStyles } from '../list-styles';
import { PedidoModel } from 'model/api/gestao/pedido/pedido-model';
import { usePDV } from 'services/app/hooks/pdv';
import { EnumModeloDeTrabalho } from 'model/enums/enum-modelo-de-trabalho';
import { isPlanoFarmaceutico } from 'utils/plano-utils';
import { useSessaoAtual } from 'services/app';
import { Grid, Tooltip, Typography } from 'views/design-system';

export interface BalcaoListDataProps {
  list: Array<PedidoModelComanda>;
  carregando: boolean;
  onCardSelected: (balcao: PedidoModelComanda) => any;
  onCardHolded: (pedido: PedidoModel) => void;
  salaoAtualId: string;
  saloes: SaloesModel[];
}

export const BalcaoListData = (props: BalcaoListDataProps) => {
  const { getConfigByCod } = usePDV();
  const modeloTrabalho = getConfigByCod(101) as EnumModeloDeTrabalho;
  const agruparSalao = useRef<string>('');
  const { plano } = useSessaoAtual()
  const classes = useStyles();

  const onCardSelected = (balcao: PedidoModelComanda) => {
    props.onCardSelected(balcao);
  };

  const onCardHolded = async (pedido: PedidoModel) => {
    props.onCardHolded(pedido);
  };
  const isFarma = isPlanoFarmaceutico(plano?.plano)

  const pedidos = props.list.filter(pedido => !pedido.isDeprecated);
  const pedidosObsoletos = props.list.filter(pedido => pedido.isDeprecated);

  return (
    <>
      {props.list.length === 0 && !props.carregando && (
        <Grid item xs={12}>
          <CardNaoEncontrado
            mensagem="Nenhum pedido no balcao."
            icon={<InformacaoIcon tipo="GERAL" />}
          />
        </Grid>
      )}

      <Grid item xs={12} spacing={2} className={classes.containerListCard}>
        {pedidos.length > 0 &&
          props.salaoAtualId === guidEmpty() &&
          props.saloes
            .map((salao) =>
              pedidos.filter((pedido) => pedido.salaoId === salao.id),
            )
            .reduce((acc, val) => acc.concat(val), [])
            .map((pedidoBalcao, index) => {
              return (
                <>
                  {(index === 0 ||
                    agruparSalao.current !== pedidoBalcao.salaoId) && (
                      <>
                        <Grid xs={12} m={1}>
                          <Typography>
                            {(agruparSalao.current = pedidoBalcao.salaoId) ===
                              pedidoBalcao.salao
                              ? pedidoBalcao.salao
                              : ''}
                            {isFarma ? '' : (<span>Salão - <b>{pedidoBalcao.salao}</b></span>)}
                          </Typography>
                        </Grid>
                        <br />
                      </>
                    )}
                  <CardComandaAtendimento
                    model={pedidoBalcao}
                    key={index + pedidoBalcao.id}
                    onClick={onCardSelected}
                    onHold={onCardHolded}
                    modeloTrabalho={modeloTrabalho}
                  />
                </>
              );
            })}

        {pedidos.length > 0 &&
          props.salaoAtualId !== guidEmpty() &&
          pedidos.map((balcao, index) => {
            return (
              <>
                <CardComandaAtendimento
                  model={balcao}
                  key={index}
                  onClick={onCardSelected}
                  onHold={onCardHolded}
                  modeloTrabalho={modeloTrabalho}
                />
              </>
            );
          })}

        {pedidosObsoletos.length > 0 && (
          <>
            <Grid xs={12} className={classes.pedidosObsoletos}>
              <Typography>
                Pedidos com comandas / mesas obsoletas.
              </Typography>
              <Tooltip title='Esses são pedidos de comandas ou mesas que foram excluídas da base de dados e os pedidos não foram finalizados.'>
                  <div>
                    <InformacaoIcon tipo='INPUT' />
                  </div>
                </Tooltip>
            </Grid>
            <br />
            {pedidosObsoletos.map((pedido, index) => {
              return (
                <>
                  <CardComandaAtendimento
                    model={pedido}
                    key={index}
                    onClick={onCardSelected}
                    onHold={onCardHolded}
                    modeloTrabalho={modeloTrabalho}
                  />
                </>
              );
            })}
          </>
        )}
      </Grid>
    </>
  );
};
