import { ComandasModel } from "model/api/gestao/comanda/comanda-model"
import { useCadastros } from "services/app"
import { ButtonModalHeader } from "views/components/controles/buttons/button-modal-header"
import { InformacaoIcon, VoltarIcon } from "views/components/icons"
import { ModalHeader } from "views/components/modals/components"
import { useModalStyles } from "views/components/modals/utils/modal-styles"
import { Box, Tooltip } from "views/design-system"
import MovLeitorComandaPage from "views/pages/private/movimentacao/mov-leitor-comanda/venda-leitor-comanda-page"
import { useStyles } from "./identificar-comanda-conteudo-styles"

export interface IdentificarComandaConteudoProps {
    callback: (comanda: ComandasModel) => Promise<boolean> | boolean
}

export const IdentificarComandaConteudo = ({ callback }: IdentificarComandaConteudoProps) => {
    const classesModal = useModalStyles()
    const classes = useStyles()
    const { fecharIdentificarComanda } = useCadastros()

    return (
        <div className={classesModal.root}>
            <ModalHeader
                title={"Identifique a  Nova Comanda"}
                leftArea={
                    <ButtonModalHeader
                        tooltip="Voltar"
                        icon={<VoltarIcon tipo="MODAL_HEADER" />}
                        onClick={fecharIdentificarComanda}
                    />
                }
                rightArea={
                    <Tooltip arrow title="Registre a perda da comanda inserindo o código de uma nova. A comanda antiga será bloqueada, impedindo novos pedidos.">
                        <Box flex>
                            <InformacaoIcon class={classes.icon} tipo="MODAL_HEADER" />
                        </Box>
                    </Tooltip>
                }
            />
            <MovLeitorComandaPage callback={callback} />
        </div>
    )
}