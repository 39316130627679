import { EditarIcon } from 'views/components/icons';
import { DefaultCard, useDefaultCardStyles } from '../components';
import { CardProcessosProps } from './card-processos-props';
import { Tooltip, Typography } from 'views/design-system';

export const CardProcessos = ({
  order,
  model,
  selected,
  onClick,
}: CardProcessosProps) => {
  const classes = useDefaultCardStyles();

  return (
    <>
      <DefaultCard
        onClick={async () => {
          onClick(model);
        }}
      >
        <div className={classes.cardContent}>
          <div className={classes.celulaGrid}>
            <Typography
              className={'celula-grid-value'}
              color="textPrimary"
              variant="body1"
            >
              {`${model.processoProducao} - ${model.descricao}`}
            </Typography>
          </div>
        </div>
        <Tooltip arrow title="Editar Processo">
          <div className={classes.cardRightButtonProcesso}>
            <EditarIcon tipo="BUTTON" />
          </div>
        </Tooltip>
      </DefaultCard>
    </>
  );
};
