import {
  Button,
  Divider,
  Grid,
  Typography
} from 'views/design-system';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useCadastros, useGestaoToken, useToastSaurus } from 'services/app';
import { useMovSimples } from 'services/app/hooks/mov-simples';
import { useMovAnteriorStorage } from 'services/app/hooks/mov-anterior-storage';
import { CaracteresString } from 'utils/caracteres-string';
import { formatarCPFCNPJ } from 'utils/cpfcnpj-format';
import { formatDecimalInteger, toDecimalString } from 'utils/to-decimal';
import { useThemeQueries } from 'views';
import { LoadingButton } from 'views/components';
import { DialogOpcoesCompartilhamento } from 'views/components/dialog/dialog-opcoes-compartilhamento/dialog-opcoes-compartilhamento';
import { CancelarIcon, OkIcon } from 'views/components/icons';
import { CatalogoIcon } from 'views/components/icons/catalogo-icon';
import { ImpressoraIcon } from 'views/components/icons/impressora-icon';
import { useStyles } from './mov-comprovante-page-styles';
import { toDateString } from 'utils/to-date';
import { usePDV } from 'services/app/hooks/pdv';
import { DialogOpcoesImpressao } from 'views/components/dialog/dialog-opcoes-impressao/dialog-opcoes-impressao';
import { isPlanoConsumacao } from 'utils/plano-utils';
import { useVenda } from 'services/app/hooks/gerenciar-venda';
import { useSincronizacaoGeral } from 'services/app/hooks/sincronizar-dados';
import { EnumSincronizacaoGeralStatus } from 'model/enums/enum-sincronizacao-geral-status';
import { useSessaoAtual } from 'services/app';
import { EnumPDVTpCaixa } from 'model/enums/enum-pdv-tpcaixa';
import { EnviarEmailIcon } from 'views/components/icons/enviar-email';
import { SMSIcon } from 'views/components/icons/sms-icon';
import { EnumTpSincronizacao } from 'model/enums/enum-tp-sincronizacao';
import { useEmpresaAtual } from 'services/app/hooks/empresa-atual';
import classNames from 'classnames';
import { guidEmpty } from 'utils/guid-empty';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { useDevice } from 'services/app/hooks/device';
import { EnumMovModelo } from 'model';
import { EnumIdeStatus } from 'model/enums/enum-ide-status';
import { CarrinhoIcon } from 'views/components/icons/carrinho-icon';
import { isEmpty } from 'lodash';
import { useMovRota } from 'services/app/hooks/mov-rota';
import { useVendaHeaderStore } from 'views/components/headers/venda-header/stores/venda-header-store';
import { CompartilharIcon } from 'views/components/icons/compartilhar-icon';
import { tpPagamentoResolver } from 'utils/tp-pagamento-resolver';
import { EnumTipoPessoaContato } from 'model/enums/enum-tipo-pessoa-contato';

export const MovComprovantePage = () => {
  const { get } = useMovAnteriorStorage();
  const { abrirEnviarSmsDialog, abrirEnviarEmailDialog, abrirDialogImpressaoNfe } = useCadastros();
  const { imprimirCupom, imprimirTicket } = useMovSimples();
  const [isModal, setIsModal] = useState<boolean>(false);
  const [isModalImpressao, setIsModalImpressao] = useState<boolean>(false);
  const { showToast } = useToastSaurus();
  const { getPDV } = usePDV();
  const { getConfigByCod } = useEmpresaAtual()
  const { getEmpresaAtual } = useGestaoToken();
  const { plano } = useSessaoAtual();
  const { getVendas } = useVenda();
  const { getStatusSincronizacaoAtual, iniciarSincronizacaoGeral } =
    useSincronizacaoGeral();
  const { addHandler, removeHandler } = useEventTools();
  const { printType } = useDevice();
  const tpSincronizacao = getConfigByCod(5);

  const vConfig = Number(
    getEmpresaAtual()?.configuracoes.filter((item) => item.cod === 924)[0]
      .vConfig
  );

  const movAnterior = get();
  const history = useHistory();
  const classes = useStyles();
  const { isMobile, theme } = useThemeQueries();
  const [timer, setTimer] = useState<number | null>(
    vConfig > 0 ? vConfig : null
  );
  const [click, setClick] = useState<number | null>(null);
  const [carregandoCupom, setCarregandoCupom] = useState<boolean>(false);
  const [carregandoTicket, setCarregandoTicket] = useState<boolean>(false);
  const { redirectLanding, redirectVendaFinalizadaDetalhe } = useMovRota();
  const urlParams = new URLSearchParams(history.location.search)
  const isAnterior = urlParams.get('anterior')
  const showImpressao = movAnterior?.id !== guidEmpty();
  const sincronizar = useCallback(async () => {
    const sincEmAndamento = getStatusSincronizacaoAtual();

    if (
      (!sincEmAndamento ||
        sincEmAndamento.status !== EnumSincronizacaoGeralStatus.EmAndamento) &&
      tpSincronizacao === EnumTpSincronizacao.Automatica
    ) {
      const vendas = await getVendas();

      if (vendas && vendas.length > 0 && navigator.onLine) {
        await iniciarSincronizacaoGeral();
      }
    }
  }, [
    getStatusSincronizacaoAtual,
    getVendas,
    iniciarSincronizacaoGeral,
    tpSincronizacao
  ]);

  useEffect(() => {
    window.history.pushState(null, '', window.location.href);
    sincronizar()
  }, [sincronizar]);

  const planoConsumacao = isPlanoConsumacao(plano?.plano);

  const iniciarNovaVenda = useCallback(async () => {
    try {
      await redirectLanding();
    } catch (e: any) {
      showToast('error', e.message)
    }
  }, [redirectLanding, showToast]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (timer !== null && timer >= 0 && click !== 0) {
        setTimer((prev) => {
          if (prev === null) {
            return prev;
          }
          return prev - 1;
        });
      }
    }, 1000);

    if (timer !== null && timer < 0) iniciarNovaVenda();

    return () => clearInterval(interval);
  }, [click, history, iniciarNovaVenda, timer]);

  const detectLeftButton = useCallback((evt: any) => {
    if (evt.button === 0) setClick(0);
  }, []);

  const handleOpenOrClose = useCallback(() => {
    setIsModal((prev) => !prev);
    setClick(0);
  }, [])


  const setHeaderProps = useVendaHeaderStore(state => state.setHeaderProps);

  useEffect(() => {
    addHandler(AppEventEnum.stopTimer, setClick);
    return () => {
      removeHandler(AppEventEnum.stopTimer, setClick);
    };
  }, [addHandler, removeHandler]);

  const handleClickImprimirComprovante = useCallback(async () => {
    try {
      if (movAnterior!.mod === EnumMovModelo.NFE) {
        const clienteAnterior = movAnterior?.cliente
        const emails = (clienteAnterior?.contatos ?? []).filter(x => x.tipo === EnumTipoPessoaContato.EMAIL).map(x => x.valor)
        abrirDialogImpressaoNfe({ movId: movAnterior?.id ?? '', emails })
        if (isModalImpressao) {
          handleOpenOrCloseImpressao();
        }
        return
      }
      setCarregandoCupom(true);
      if (isModalImpressao) {
        handleOpenOrCloseImpressao();
      }
      setTimeout(async () => {
        try {
          await imprimirCupom({
            idVenda: movAnterior!.id,
            mod: movAnterior!.mod,
            tpEmis: movAnterior!.tpEmis,
            checkTipoPdv: true,
          });
        } catch (err: any) {
          showToast(
            'error',
            `Não foi possível realizar a impressão. Detalhe: ${err.message}`
          );
        }

      }, 500)

    } catch (err: any) {
      showToast(
        'error',
        `Não foi possível realizar a impressão. Detalhe: ${err.message}`
      );
    } finally {
      setCarregandoCupom(false);
    }
  }, [abrirDialogImpressaoNfe, imprimirCupom, isModalImpressao, movAnterior, showToast]);

  const handleClickImprimirTicket = useCallback(async () => {
    try {
      setCarregandoTicket(true);
      handleOpenOrCloseImpressao();
      setTimeout(async () => {
        await imprimirTicket(movAnterior!.id, movAnterior!.tpEmis, true, printType());
      }, 500)
    } catch (err: any) {
      showToast(
        'error',
        `Não foi possível realizar a impressão. Detalhe: ${err.message}`
      );
    } finally {
      setCarregandoTicket(false);
    }
  }, [imprimirTicket, movAnterior, printType, showToast]);

  const handleOpenOrCloseImpressao = () => {
    setIsModalImpressao((prev) => !prev);
  };

  const pdv = getPDV();

  useEffect(() => {
    setHeaderProps({
      title: 'Resumo da Venda',
      showMenuPdv: true,
      customButton: pdv?.tpCaixa === EnumPDVTpCaixa.WEBPDV ? undefined : [{
        icon: <CompartilharIcon tipo='PRIVATE_HEADER' />,
        onClick: () => handleOpenOrClose(),
      }]
    })
  }, [handleOpenOrClose, pdv?.tpCaixa, setHeaderProps])

  const getEmails = (movAnterior?.cliente?.contatos
    .filter(contato => !isEmpty(contato) && contato && contato.tipo === EnumTipoPessoaContato.EMAIL) ?? [])
    .map(x => x.valor)

  const renderCompartilhamento = useMemo(() => {
    if (movAnterior?.id === guidEmpty()) {
      return <></>;
    }
    return (
      <Grid container xs={12} style={{ marginTop: 8 }}>
        <Grid item xs={12}>
          <Divider className={classes.divider} />
        </Grid>
        {showImpressao && (
          <>
            <Grid item xs={6} style={{ paddingRight: 4 }}>
              <Button
                variant="text"
                fullWidth
                color="primary"
                rounded
                style={{ height: 40 }}
                onClick={() =>
                  abrirEnviarEmailDialog({
                    movId: movAnterior?.id || '',
                    mod: movAnterior?.mod || 65,
                    emails: getEmails
                  })
                }
              >
                <EnviarEmailIcon tipo="BUTTON" />
                Enviar E-mail
              </Button>
            </Grid>
            <Grid item xs={6} style={{ paddingLeft: 4 }}>
              <Button
                variant="text"
                fullWidth
                color="primary"
                rounded
                style={{ height: 40 }}
                onClick={() =>
                  abrirEnviarSmsDialog(movAnterior?.id || '', movAnterior?.mod || 65)
                }
              >
                <SMSIcon tipo="BUTTON" />
                Enviar SMS
              </Button>
            </Grid>
          </>
        )}
      </Grid >
    )
  }, [abrirEnviarEmailDialog, abrirEnviarSmsDialog, classes.divider, getEmails, movAnterior?.id, movAnterior?.mod, showImpressao]);

  const isCancelado = movAnterior?.status === EnumIdeStatus.CANCELADO;
  const pagamentos = movAnterior?.pags.map((item, index, row) => {
    return (
      <>
        {tpPagamentoResolver(item.modPagamento, item.tpTransacao)?.Value || '' + (item.nParcelas > 1 ? ` (${item.nParcelas}x)` : '') + (index + 1 === row.length ? '' : ', ')}
      </>
    );
  });
  return (
    <>
      <Grid
        className={`${classes.container} ${classes.containerBackgroundColor}`}
      >
        <Grid
          className={classes.containerInfo}
          onMouseDown={(e) => detectLeftButton(e)}
        >
          <Grid className={isMobile ? classes.content : classes.contentPC}>
            <Grid className={classes.containerImageSuccess}>
              <Grid className={classes.svg}>
                {!isCancelado ?
                  <OkIcon fill={theme.palette.success.dark} />
                  :
                  <CancelarIcon fill={theme.palette.error.dark} />
                }
              </Grid>
            </Grid>
            <Typography className={
              classNames(classes.textTitle,
                isCancelado ? classes.red : classes.green
              )
            }>
              Venda {isCancelado ? 'Cancelada' : 'Finalizada'}!
            </Typography>

            <Grid className={isMobile ? classes.containerInfoGeral : ''}>
              {movAnterior && (
                <Grid
                  className={
                    isMobile
                      ? classes.containerInfoPreview
                      : classes.containerInfoPreviewPC
                  }
                >
                  <Grid container justifyContent="space-between">
                    <Typography className={classes.label}>
                      Número e Série
                    </Typography>
                    <Typography className={classes.label}>
                      Data de Emissão
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    justifyContent="space-between"
                  >
                    <Typography className={classes.infoNomeEQuantidade}>
                      {movAnterior?.nnf === 0 ? 'Não Gerado' : (movAnterior?.nnf + '/' + movAnterior?.serie)}
                    </Typography>
                    <Typography className={classes.infoNomeEQuantidade}>
                      {toDateString(new Date(movAnterior.dEmi))}
                    </Typography>
                  </Grid>
                  <Grid container>
                    <Typography className={classes.label}>Cliente</Typography>
                  </Grid>
                  <Grid container style={{ marginBottom: 16 }}>
                    <Typography className={classes.infoNomeEQuantidade}>
                      {(movAnterior.cliente?.cpfcnpj !== ''
                        ? formatarCPFCNPJ(movAnterior?.cliente?.cpfcnpj || '') +
                        ' - '
                        : '') +
                        CaracteresString(movAnterior?.cliente?.nome || '', 25)}
                    </Typography>
                  </Grid>
                  <Grid container justifyContent="space-between">
                    <Typography className={classes.label}>Pagamento</Typography>
                    <Typography className={classes.label}>Itens</Typography>
                  </Grid>
                  <Grid
                    container
                    justifyContent="space-between"
                  >
                    <Typography className={classes.infoNomeEQuantidade}>
                      {isEmpty(pagamentos) ? 'Sem Pagamento' : pagamentos}
                    </Typography>
                    <Typography className={classes.infoItemValor}>
                      {formatDecimalInteger(movAnterior.qComItens, 3)}
                    </Typography>
                  </Grid>
                  <Divider className={classes.divider} />
                  <Grid container className={classes.valorNF} justifyContent="space-between">
                    <Typography className={classNames(classes.labelValue, classes.valorNFLabel)}>
                      Valor Total
                    </Typography>
                    <Typography className={classNames(classes.infoItemValor, classes.valorNFValor)}>
                      R$ {toDecimalString(movAnterior.vNF ?? 0, 2)}
                    </Typography>
                  </Grid>
                  {movAnterior.vTroco ? (
                    <>
                      <Grid container className={classes.valorTroco} justifyContent="space-between">
                        <Typography className={classNames(classes.labelValue, classes.valorTrocoLabel)}>
                          Troco
                        </Typography>
                        <Typography className={classNames(classes.infoItemValor, classes.valorTrocoValor)}>
                          R$ {toDecimalString(movAnterior.vTroco ?? 0, 2)}
                        </Typography>
                      </Grid>

                      {pdv?.tpCaixa === EnumPDVTpCaixa.WEBPDV && renderCompartilhamento}
                    </>
                  ) : (
                    <>
                      {pdv?.tpCaixa === EnumPDVTpCaixa.WEBPDV && renderCompartilhamento}
                    </>
                  )}

                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid className={classes.buttonContainer}>
          <Grid className={classes.buttons} style={{ width: '100%' }}>
            <Button
              fullWidth
              className={classNames(classes.novaVenda)}
              variant="contained"
              size="large"
              rounded
              color="primary"
              onClick={iniciarNovaVenda}
            >
              <CarrinhoIcon tipo="BUTTON_PRIMARY" />
              {timer !== null
                ? click !== 0
                  ? timer > -1
                    ? isAnterior ? `Iniciando venda em ${timer}` : `Iniciando venda em ${timer}`
                    : `Iniciando Venda...`
                  : `Nova Venda`
                : `Nova Venda`}
            </Button>
          </Grid>
          <Grid container className={classes.buttons}>
            {showImpressao && (<>

              {pdv?.configuracoes.filter((item) => item.cod === 18)[0]
                .vConfig === '1' && planoConsumacao && !((movAnterior?.informacoesGeraisPedido?.pedidos?.length ?? 0) > 0) ? (
                <Grid item style={{ paddingRight: 6 }} xs={6}>
                  <Button
                    fullWidth
                    variant="outlined"
                    color="primary"
                    style={{ height: 48 }}
                    onClick={handleOpenOrCloseImpressao}
                    rounded
                  >
                    <ImpressoraIcon tipo="BUTTON" />
                    Impressões
                  </Button>
                </Grid>
              ) : (
                <Grid item style={{ paddingRight: 6 }} xs={6}>
                  <Button
                    fullWidth
                    variant="outlined"
                    color="primary"
                    onClick={handleClickImprimirComprovante}
                    style={{ height: 48 }}
                    disabled={carregandoCupom}
                    rounded
                  >
                    {carregandoCupom && <LoadingButton tipo="AZUL" />}{' '}
                    <ImpressoraIcon tipo="BUTTON" /> Imprimir
                  </Button>
                </Grid>
              )}
            </>)}
            <Grid item xs={showImpressao ? 6 : 12}>
              <Button
                fullWidth
                variant="outlined"
                color="primary"
                style={{ height: 48 }}
                rounded
                onClick={async () => {
                  try {
                    await redirectVendaFinalizadaDetalhe();
                  } catch (e: any) {
                    showToast('error', e.message)
                  }
                }
                }
              >
                <CatalogoIcon tipo="BUTTON" />
                Detalhes
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid >

      <DialogOpcoesCompartilhamento
        closeModal={handleOpenOrClose}
        openned={isModal}
        id={movAnterior?.id || ''}
        mod={movAnterior?.mod || 65}
        emails={getEmails}
      />

      <DialogOpcoesImpressao
        openned={isModalImpressao}
        closeModal={handleOpenOrCloseImpressao}
        carregandoCupom={carregandoCupom}
        carregandoTicket={carregandoTicket}
        onClickCupom={handleClickImprimirComprovante}
        onClickTicket={handleClickImprimirTicket}
      />
    </>
  );
};
