import { AppEventEnum } from 'model/enums/enum-app-event';
import { useCallback, useEffect, useState } from 'react';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { TecladoFuncoesModalProps, TecladoFuncoesModal } from 'views/components/modals/teclado-funcoes/teclado-funcoes-modal';
import { EnumTpTecladoFuncoes } from 'views/components/modals/teclado-funcoes/components/teclado-funcoes/teclado-funcoes-props';

export const TecladoFuncoesModalRender = () => {
  const { addHandler, removeHandler } = useEventTools();
  const [modalState, setModalState] = useState<TecladoFuncoesModalProps>({
    callback: () => false,
    tipo: EnumTpTecladoFuncoes.Decimal,
    isPagFatura: false,
    id: '',
    openned: false,
  });
  
  const modalAlterado = useCallback(({ ...props }: any) => {
    setModalState(prev => ({
      ...prev,
      ...props
    }));
  }, []);

  useEffect(() => {
    addHandler(AppEventEnum.TecladoFuncoes, modalAlterado);
    return () => {
      removeHandler(AppEventEnum.TecladoFuncoes, modalAlterado);
    };
  }, [addHandler, modalAlterado, removeHandler]);

  return (
    <TecladoFuncoesModal
      key="TecladoFuncoes"
      {...modalState}
    />
  );
};
