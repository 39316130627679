import { TypeTpModTransacao } from 'data/mocks';
import { isObject } from 'lodash';
import { EnumPagTpMod, EnumPagTpTransacao } from 'model';
import { useCallback } from 'react';
import * as Yup from 'yup';

interface FinalizadoraValidationProps {
  tipo: TypeTpModTransacao;
  isBoletoIntegrado: boolean;
}

export const useFormFinalizadoraCadastroValidation = ({ tipo, isBoletoIntegrado }: FinalizadoraValidationProps) => {

  const credenciais = Yup.object().shape({
    merchantId: Yup.string().required('Merchant ID é obrigatório.').typeError('Merchant ID é obrigatório.'),
    merchantToken: Yup.string().required('Merchant Token é obrigatório.').typeError('Merchant Token é obrigatório.'),
    ambiente: Yup.number().required('Ambiente é obrigatório').typeError('Ambiente é obrigatório').typeError('Campo Obrigatório.'),
  })

  const validarCredenciais = useCallback(() => {
    if (isObject(tipo)) {
      if (tipo.tpMod === EnumPagTpMod.CARTAO_CREDITO && tipo.tpTransacao === EnumPagTpTransacao.SAFRAPAY_WALLET) {
        return credenciais
      } else {
        return Yup.object().shape({}).nullable(true)
      }
    }
    switch (tipo) {
      case EnumPagTpMod.PAGAMENTO_INSTANTANEO:
        return credenciais
      default:
        return Yup.object().shape({}).nullable(true)
    }
  }, [credenciais, tipo])

  const FormFinalizadoraCadastroYupValidation = Yup.object().shape({
    descricao: Yup.string().required('O campo descrição é obrigatório'),
    tpMod: Yup.number().required('O campo Modelo de pagamento é obrigatório'),
    credenciais: validarCredenciais(),
    qMaxParc: Yup.number().required('Parcelamento Máximo é obrigatório').min(1, 'Parcelamento Máximo inválido').max(60, 'Parcelamento Máximo inválido').typeError('Parcelamento Máximo é obrigatório.'),
    credenciamentoBoleto: isBoletoIntegrado ? Yup.object().shape({
      ContaBancariaId: Yup.string().required('Informe uma Conta Bancaria'),
    }) : Yup.object().shape({}).nullable(true)
  });

  return {
    FormFinalizadoraYupValidation: FormFinalizadoraCadastroYupValidation
  }
}

