import { RadioControlLabelProps } from "./radio-control-label-props"
import { SaurusRadio } from "views/components/controles/radio/saurus-radio"
import { useStyles } from "./radio-control-label-styles"
import { Box, Typography } from "views/design-system"

export const RadioControlLabel = ({ checked, onClick, label }: RadioControlLabelProps) => {

    const classes = useStyles()
    return (
        <Box className={classes.content} alignItems="center" flex justifyContent={"space-between"} onClick={onClick}>
            <Typography>
                {label}
            </Typography>
            <SaurusRadio checked={checked} />
        </Box>
    )
}