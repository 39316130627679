import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { Grid } from 'views/design-system';

export default function FormEntradaCadastroResumoItens(): React.ReactElement {
  const {
    control,
    getValues,
    formState: { errors, touchedFields }
  } = useFormContext();

  function checkImpostoExiste(text: string): number {
    return getValues(text) ?? 0;
  }

  const impostoICMS = checkImpostoExiste('infMov.total.vICMS');
  const impostoFCP = checkImpostoExiste('infMov.total.vFCP');
  const impostoST = checkImpostoExiste('infMov.total.vST');
  const impostoFCPST = checkImpostoExiste('infMov.total.vFCPST');
  const impostoFCPSTRet = checkImpostoExiste('infMov.total.vFCPSTRet');
  const impostoII = checkImpostoExiste('infMov.total.vII');
  const impostoIPI = checkImpostoExiste('infMov.total.vIPI');
  const impostoIPIDevol = checkImpostoExiste('infMov.total.vIPIDevol');
  const impostoPIS = checkImpostoExiste('infMov.total.vPIS');
  const impostoCOFINS = checkImpostoExiste('infMov.total.vCOFINS');
  const impostoICMSDeson = checkImpostoExiste('infMov.total.vICMSDeson');
  const impostoFCPUFDest = checkImpostoExiste('infMov.total.vFCPUFDest');
  const impostoICMSUFDest = checkImpostoExiste('infMov.total.vICMSUFDest');
  const impostoICMSUFRemet = checkImpostoExiste('infMov.total.vICMSUFRemet');

  const valorTotalImpostos =
    impostoICMS +
    impostoICMSDeson +
    impostoFCPUFDest +
    impostoICMSUFDest +
    impostoICMSUFRemet +
    impostoFCP +
    impostoST +
    impostoFCPST +
    impostoFCPSTRet +
    impostoII +
    impostoIPI +
    impostoIPIDevol +
    impostoPIS +
    impostoCOFINS;

  return (
    <Grid container spacing={2} p={1}>
      <Grid item xs={12}>
        <Controller
          name="infMov.total.qItens"
          control={control}
          render={({ field }) => (
            <TextFieldSaurus
              tipo="NUMERO"
              fullWidth
              variant="outlined"
              label="Qtde. Produtos"
              readOnly
              allowSubmit
              InputLabelProps={{
                shrink: true
              }}
              placeholder=""
              error={Boolean(errors?.qItens?.message)}
              helperText={
                touchedFields.qItens || errors.qItens
                  ? errors.qItens?.message
                  : undefined
              }
              {...field}
            />
          )}
        />
      </Grid>

      <Grid item xs={6}>
        <TextFieldSaurus
          tipo="DECIMAL"
          fullWidth
          variant="outlined"
          label="Total Impostos"
          showStartAdornment
          readOnly
          allowSubmit
          InputLabelProps={{
            shrink: true
          }}
          placeholder=""
          error={Boolean(errors?.vProd?.message)}
          helperText={
            touchedFields.vProd || errors.vProd
              ? errors.vProd?.message
              : undefined
          }
          value={valorTotalImpostos}
        />
      </Grid>

      <Grid item xs={6}>
        <Controller
          name="infMov.total.vProd"
          control={control}
          render={({ field }) => (
            <TextFieldSaurus
              tipo="DECIMAL"
              fullWidth
              variant="outlined"
              label="Total Produtos"
              showStartAdornment
              readOnly
              allowSubmit
              InputLabelProps={{
                shrink: true
              }}
              placeholder=""
              error={Boolean(errors?.vProd?.message)}
              helperText={
                touchedFields.vProd || errors.vProd
                  ? errors.vProd?.message
                  : undefined
              }
              {...field}
            />
          )}
        />
      </Grid>
    </Grid>
  );
}
