import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { makeStyles } from '@material-ui/core';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { InformacaoIcon, SucessoIcon } from 'views/components/icons';
import { guidEmpty } from 'utils/guid-empty';
import { formatarCPFCNPJ } from 'utils/cpfcnpj-format';
import { useEmpresaAtual } from 'services/app/hooks/empresa-atual';
import { Box, Grid, Tooltip } from 'views/design-system';

export const useStyles = makeStyles((theme) => ({
    errorIcon: {
        cursor: 'help',
        "& svg": {
            margin: '0',
            fill: theme.palette.warning.dark,
        }
    },
    successIcon: {
        "& svg": {
            margin: '0',
            fill: theme.palette.success.main,
        }
    },
    infoIcon: {
        "& svg": {
            margin: '0',
            fill: theme.palette.info.main,
        }
    },
}))

interface FormEntradaCadastroEmpresaProps {
    docField: string;
    razaoSocialField: string;
    pessoaIdField: string;
    ufField: string;
    logradouroField: string;
    municipioField: string;
    titulo: string;
    codigoMunicipioField?: string;
    numeroField?: string;
    indIEDestField?: string;
    bairroField?: string;
    isDestinatario?: boolean;
}

export default function FormEntradaCadastroEmpresa({
    docField,
    razaoSocialField,
    bairroField,
    codigoMunicipioField,
    indIEDestField,
    logradouroField,
    municipioField,
    numeroField,
    pessoaIdField,
    ufField,
    titulo,
    isDestinatario = false,
}: FormEntradaCadastroEmpresaProps): React.ReactElement {
    const {
        control,
        getValues,
        getFieldState,
    } = useFormContext();

    // const { consultarCNPJ, carregando: carregandoCNPJ } = useConsultaCnpj();
    // const { getPessoaByDoc, carregando: carregandoPessoa } = useGetPessoaByDoc();
    // const { getEnderecoPessoa, carregando: carregandoEndereco } = useGetEnderecoPessoa();
    // const { showToast } = useToastSaurus();
    const classes = useStyles();
    const { getEmpresaAtual } = useEmpresaAtual();

    // const carregando = carregandoCNPJ || carregandoPessoa || carregandoEndereco;

    // const getEnderecoWrapper = async (pessoaId: string) => {
    //     try {
    //         const res = await getEnderecoPessoa(pessoaId);

    //         if (res.erro) throw res.erro;
    //         if (!res.resultado) throw new Error('');

    //         const ret = res.resultado.data as PessoaEnderecoModel[];

    //         if (ret.length < 1) throw new Error('');
    //         setValue(municipioField, ret[0].xMun || getValues(municipioField));
    //         setValue(ufField, ret[0].uf || getValues(ufField));
    //         setValue(logradouroField, ret[0].logradouro || getValues(logradouroField));
    //         if (indIEDestField) setValue(indIEDestField, ret[0].indIEDest || getValues(indIEDestField));
    //         if (numeroField) setValue(numeroField, ret[0].numero || getValues(numeroField));
    //         if (bairroField) setValue(bairroField, ret[0].bairro || getValues(bairroField));
    //         if (codigoMunicipioField) setValue(codigoMunicipioField, ret[0].cMun || getValues(codigoMunicipioField));
    //     } catch (e: any) {

    //     }
    // }

    // const getPessoaWrapper = async (doc: string) => {
    //     const res = await getPessoaByDoc(doc);

    //     if (res.erro) throw res.erro;
    //     if (!res.resultado) throw new Error('')

    //     const ret = res.resultado.data.list as PessoaModel[];

    //     if (ret.length < 1) {
    //         throw new Error();
    //     }

    //     setValue(docField, doc);
    //     setValue(razaoSocialField, ret[0].nome)
    //     setValue(pessoaIdField, ret[0].id);

    //     await getEnderecoWrapper(ret[0].id);
    // }

    const mostrarInfo = getValues(razaoSocialField) && getValues(docField) && !isDestinatario

    return (
        <>
            <Grid item xs={12}>
                <Controller
                    name={docField}
                    control={control}
                    render={({ field }) => (
                        <TextFieldSaurus
                            tipo="CNPJ"
                            readOnly
                            fullWidth
                            autoComplete="new-password"
                            variant="outlined"
                            label="CNPJ"
                            allowSubmit
                            InputLabelProps={{
                                shrink: true
                            }}
                            endAdornmentButton={
                                mostrarInfo && (getValues(pessoaIdField) && getValues(pessoaIdField) !== guidEmpty() ?
                                    <Tooltip title={`${titulo} com documento ${formatarCPFCNPJ(getValues(docField))} encontrado(a) nos seus cadastros.`}>
                                        <Box flex className={classes.successIcon} mr={1}>
                                            <SucessoIcon tipo='BUTTON' />
                                        </Box>
                                    </Tooltip>
                                    :
                                    <Tooltip title={`${titulo} será cadastrado automaticamente.`} arrow>
                                        <Box flex className={classes.errorIcon} mr={1}>
                                            <InformacaoIcon tipo='BUTTON' />
                                        </Box>
                                    </Tooltip>)
                            }
                            placeholder=""
                            error={Boolean(getFieldState(docField).error)}
                            helperText={
                                getFieldState(docField).error
                                    ? getFieldState(docField).error?.message
                                    : undefined
                            }
                            {...field}
                        />
                    )}
                />
            </Grid>

            <Grid item xs={12}>
                <Controller
                    name={razaoSocialField}
                    control={control}
                    render={({ field }) => (
                        <TextFieldSaurus
                            tipo="TEXTO"
                            fullWidth
                            variant="outlined"
                            label="Razão Social/Nome Fantasia"
                            readOnly
                            allowSubmit
                            InputLabelProps={{
                                shrink: true
                            }}
                            endAdornmentButton={
                                <>
                                    {mostrarInfo && (getValues(pessoaIdField) && getValues(pessoaIdField) !== guidEmpty() ?
                                        <Tooltip title={`${titulo} com documento ${formatarCPFCNPJ(getValues(docField))} encontrado(a) nos seus cadastros.`}>
                                            <div>
                                                <Box flex className={classes.successIcon} mr={1}>
                                                    <SucessoIcon tipo='BUTTON' />
                                                </Box>
                                            </div>
                                        </Tooltip>
                                        :
                                        <Tooltip title={`${titulo} será cadastrado automaticamente.`} arrow>
                                            <div>
                                                <Box flex className={classes.errorIcon} mr={1}>
                                                    <InformacaoIcon tipo='BUTTON' />
                                                </Box>
                                            </div>
                                        </Tooltip>)}
                                    {(isDestinatario && (
                                        getEmpresaAtual()?.razaoSocial !== field.value
                                    )) && (
                                            <Tooltip title={`A razão social ${field.value} diverge da razão social ${getEmpresaAtual()?.razaoSocial} usada na sua empresa.`}>
                                                <Box flex className={classes.infoIcon} mr={1}>
                                                    <InformacaoIcon tipo='BUTTON' />
                                                </Box>
                                            </Tooltip>
                                        )}
                                </>
                            }
                            placeholder=""
                            error={Boolean(getFieldState(razaoSocialField).error)}
                            helperText={
                                getFieldState(razaoSocialField).error
                                    ? getFieldState(razaoSocialField).error?.message
                                    : undefined
                            }
                            {...field}
                        />
                    )}
                />
            </Grid>
        </>
    );
}