import SemImagem from 'assets/img/sem-imagem.jpg';
import { isEmpty } from 'lodash';
import { useStyles } from './card-carrinho-imagem-styles';
import { imagemBase64 } from 'utils/tratamento-de-imagem';
import { memo } from 'react';
import { Grid } from 'views/design-system';

interface CardCarrinhoImagemProps {
  imgUrl: string;
  xProd: string;
}

const ContentImage = ({ imgUrl, xProd }: CardCarrinhoImagemProps) => {
  const classes = useStyles();

  return (
    <Grid className={classes.containerAvatar}>
      {imgUrl?.length > 0 && (
        <img
          src={imagemBase64(imgUrl)}
          className={classes.cardImg}
          alt={xProd}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = SemImagem;
          }}
        />
      )}
      {isEmpty(imgUrl) && (
        <img src={SemImagem} className={classes.cardImg} alt={xProd} />
      )}
    </Grid>
  );
};

export default memo(ContentImage)