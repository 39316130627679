import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { makeUtilClasses } from 'views/theme';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import {
    DefaultFormProps,
    DefaultFormRefs,
} from 'views/components/form/utils/form-default-props';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useFormCheckoutCartaoValidation } from './form-checkout-cartao-validation';
import { stringNumeros } from 'utils/string-numeros';
import { Box, Button, Grid } from 'views/design-system';
import { CheckoutCartaoFormModel } from 'model/app/forms/checkout-cartao/checkout-cartao';
import { AmexIcon, EloIcon, HipercardIcon, MasterCardIcon, VisaIcon } from 'views/components/icons';
import { useStyles } from './form-checkout-cartao-styles';
import { getCardBrand } from 'utils/get-card-brand';

export const FormCheckoutCartao = forwardRef<
    DefaultFormRefs<CheckoutCartaoFormModel>,
    DefaultFormProps<CheckoutCartaoFormModel>
>(({ ...props }: DefaultFormProps<CheckoutCartaoFormModel>, ref) => {
    const utilClasses = makeUtilClasses();
    const inputNumeroCartao = useRef<HTMLInputElement>(null)
    const classes = useStyles()

    const { FormCheckoutCartaoValidation } = useFormCheckoutCartaoValidation();
    const [modelForm, setModelForm] = useState<CheckoutCartaoFormModel>(new CheckoutCartaoFormModel())
    const [, setAtt] = useState<boolean>(false)

    const att = useCallback(() => setAtt(prev => !prev), [])

    const {
        handleSubmit,
        control,
        formState: { errors, touchedFields },
        reset,
        getValues,
        setValue
    } = useForm<CheckoutCartaoFormModel>({
        resolver: yupResolver(FormCheckoutCartaoValidation),
        criteriaMode: 'all',
        mode: 'onChange',
        defaultValues: modelForm
    });

    const onSubmit = (values: CheckoutCartaoFormModel) => {
        values.number = stringNumeros(values?.number ?? '')
        values.document = stringNumeros(values.document)
        props.onSubmit(values, modelForm);
    };

    useImperativeHandle(ref, () => ({
        submitForm: async () => {
            await handleSubmit(onSubmit)();
        },
        resetForm: () => {
            reset();
        },
        fillForm: (model) => {
            if (model) {
                setModelForm(model)
                reset(model)
            }
        },
    }));

    useEffect(() => {
        inputNumeroCartao.current?.focus()
    }, []);

    const cardBrand = useCallback(() => {

        switch ((getValues('brand') ?? '').toLowerCase()) {
            case 'mastercard':
                return <MasterCardIcon tipo="INPUT" class={classes.card} />
            case 'elo':
                return <EloIcon tipo="INPUT" class={classes.cardElo} />
            case 'visa':
                return <VisaIcon tipo="INPUT" class={classes.card} />
            case 'amex':
                return <AmexIcon tipo="INPUT" class={classes.card} />
            case 'hipercard':
                return <HipercardIcon tipo="INPUT" class={classes.cardElo} />
            default:
                return <></>
        }
    }, [classes.card, classes.cardElo, getValues])

    const handleCardBrand = useCallback((value: string) => {
        const brand = getCardBrand(value)

        if (brand) {
            setValue('brand', brand)
        }
    }, [setValue])

    return (
        <>
            <div className={utilClasses.formContainer}>
                {props.loading && props.showLoading ? (
                    <div className={utilClasses.controlLoading}>
                        <CircularLoading tipo="NORMAL" />
                    </div>
                ) : null}

                <form
                    onSubmit={handleSubmit(onSubmit)}
                    className={props.loading ? utilClasses.controlLoading : ''}
                >
                    <Box my={2}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Controller
                                    name="number"
                                    control={control}
                                    render={({ field }) => (
                                        <TextFieldSaurus
                                            tipo={'CARTAO'}
                                            disabled={props.loading}
                                            allowSubmit
                                            endAdornmentButton={
                                                cardBrand()
                                            }
                                            fullWidth
                                            variant="outlined"
                                            label="Número do Cartão"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            error={Boolean(
                                                errors.number && errors.number.message,
                                            )}
                                            helperText={
                                                touchedFields.number || errors.number
                                                    ? errors.number?.message
                                                    : undefined
                                            }
                                            {...field}
                                            onBlur={() => {
                                                field.onBlur()
                                                handleCardBrand(field.value)
                                                att()
                                            }}
                                            inputRef={inputNumeroCartao}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Controller
                                    name="expiry"
                                    control={control}
                                    render={({ field }) => (
                                        <TextFieldSaurus
                                            tipo={'MMAA'}
                                            disabled={props.loading}
                                            fullWidth
                                            variant="outlined"
                                            label="Validade"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            error={Boolean(
                                                errors.expiry && errors.expiry.message,
                                            )}
                                            helperText={
                                                touchedFields.expiry || errors.expiry
                                                    ? errors.expiry?.message
                                                    : undefined
                                            }
                                            {...field}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Controller
                                    name="cvc"
                                    control={control}
                                    render={({ field }) => (
                                        <TextFieldSaurus
                                            tipo={'NUMERO'}
                                            disabled={props.loading}
                                            maxTexto={3}
                                            fullWidth
                                            variant="outlined"
                                            label="CVV"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            error={Boolean(
                                                errors.cvc && errors.cvc.message,
                                            )}
                                            helperText={
                                                touchedFields.cvc || errors.cvc
                                                    ? errors.cvc?.message
                                                    : undefined
                                            }
                                            {...field}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    name="name"
                                    control={control}
                                    render={({ field }) => (
                                        <TextFieldSaurus
                                            tipo={'UPPERCASE'}
                                            disabled={props.loading}
                                            fullWidth
                                            variant="outlined"
                                            label="Nome do titular"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            error={Boolean(
                                                errors.name && errors.name.message,
                                            )}
                                            helperText={
                                                touchedFields.name || errors.name
                                                    ? errors.name?.message
                                                    : undefined
                                            }
                                            {...field}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    name="document"
                                    control={control}
                                    render={({ field }) => (
                                        <TextFieldSaurus
                                            tipo={'CNPJ_CPF'}
                                            disabled={props.loading}
                                            allowSubmit
                                            fullWidth
                                            variant="outlined"
                                            label="CPF/CNPJ do titular"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            error={Boolean(
                                                errors.document && errors.document.message,
                                            )}
                                            helperText={
                                                touchedFields.document || errors.document
                                                    ? errors.document?.message
                                                    : undefined
                                            }
                                            {...field}
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                    <Button style={{ display: 'none' }} type="submit"></Button>
                </form>
            </div>
        </>
    );
});
