import { GridSize } from "@material-ui/core";
import { useStyles } from '../../controles/radio-list/radio-list-styles'
import { ConfirmarIcon } from "views/components/icons/confirmar-icon";
import { Controller, ControllerRenderProps, useForm } from "react-hook-form";
import { TextFieldSaurus } from "views/components/controles/inputs";
import { TipoField } from "views/components/controles/inputs/text-field-saurus/text-field-saurus-types";
import { KeyValueModel } from "model";
import { useCallback } from "react";
import { SelectSaurus } from "views/components/controles/selects/select-saurus/select-saurus";
import { useFormPersonalizadoValidation } from "./form-personalizado-validation";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Grid } from "views/design-system";


export interface FieldProps {
    propName: string;
    placeholder?: string
    label?: string;
    tipo?: 'SELECT' | 'TEXTFIELD',
    tipoTextfield?: TipoField;
    conteudo?: KeyValueModel[];
    showCamBarcode?: boolean;
    disabled?: boolean;
    required?: boolean;
    grid?: {
        xs: GridSize;
        md: GridSize;
    }
}


interface FormPersonalizadoProps<T> {
    onSubmit: (model: T) => void;
    model: T;
    campos: FieldProps[];
}

export const FormPersonalizado = <T,>({
    onSubmit,
    campos,
    model
}: FormPersonalizadoProps<T>) => {

    const classes = useStyles();

    const { formPersonalizadoValidation } = useFormPersonalizadoValidation({ campos });

    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm<any>({
        defaultValues: { ...model },
        criteriaMode: 'all',
        mode: 'onChange',
        resolver: yupResolver(formPersonalizadoValidation)
    });

    const renderizarCampo = useCallback((campos: FieldProps, field: ControllerRenderProps<any, string>) => {
        switch (campos.tipo) {
            case 'SELECT':
                return (
                    <SelectSaurus
                        label={campos.label || ''}
                        conteudo={campos.conteudo || []}
                        {...campos}
                        {...field}
                        error={Boolean(errors[campos.propName])}
                        helperText={Boolean(errors[campos.propName]) ? errors[campos.propName]?.message : undefined}
                    />
                )
            default:
                return (
                    <TextFieldSaurus
                        label={campos.label || ''}
                        conteudo={campos.conteudo || []}
                        variant='outlined'
                        {...campos}
                        tipo={campos.tipoTextfield || 'TEXTO'}
                        {...field}
                        error={Boolean(errors[campos.propName])}
                        helperText={Boolean(errors[campos.propName]) ? errors[campos.propName]?.message : undefined}
                    />
                )
        }
    }, [errors])

    return (
        <Box className={classes.container}>
            <form onSubmit={() => handleSubmit(onSubmit)()} className={classes.text}>
                <Grid container spacing={2}>
                    {campos.map(campo => {
                        let grids: { xs: GridSize, md: GridSize } = { xs: 12, md: 12 }
                        if (campo.grid) {
                            grids = { ...campo.grid }
                        }
                        return (
                            <Grid item {...grids}>
                                <Controller
                                    control={control}
                                    name={campo.propName}
                                    render={({ field }) => {
                                        return renderizarCampo(campo, field)
                                    }}
                                />
                            </Grid>
                        )
                    })}
                </Grid>
            </form>
            <Box className={classes.button}>
                <Button fullWidth variant='contained' color='primary' onClick={() => handleSubmit(onSubmit)()}>
                    <ConfirmarIcon tipo='BUTTON_PRIMARY' />
                    Confirmar
                </Button>
            </Box>
        </Box>
    )
}