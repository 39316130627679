import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    cardContainer: {
        display: "flex",
        flexDirection: "column",
        height: '100%',
    },
    cardContent: {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        marginBottom: theme.spacing(2),
    },
    acoes: {
        display: 'flex',
        marginTop: theme.spacing(1)
    },
    descricao: {
        textAlign: 'center',
        marginBottom: theme.spacing(2),
    },
    containerFooter: {
        width: '100%'
    },
    title: {
        fontWeight: 700,
        fontSize: '2.5rem',
    }
}))