import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import React, { useCallback } from 'react';
import Scanner from 'views/pages/private/movimentacao/mov-prod-leitor/components/scanner/scanner';
import CloseIcon from '@material-ui/icons/Close';
import { useToastSaurus } from 'services/app';
import { useSessaoAtual } from 'services/app';
import { useStyles } from './dialog-scann-atendimento-styles';
import { usePedidoLocal } from 'services/app/hooks/pedido-local';
import { useMovAtual } from 'services/app/hooks/mov-atual';
import { useHistory } from 'react-router-dom';
import { useGetPedidoSalaoProcessado } from 'data/api/gestao/pedido/get-pedido-salao-processado';
import { isEmpty } from 'lodash';
import { EnumEmpresaConfig } from 'model/enums/enum-empresa-config';
import { isOnlyHasNumber } from 'utils/to-decimal';
import { useEmpresaAtual } from 'services/app/hooks/empresa-atual';
import { Typography } from 'views/design-system';

interface DialogScannAtendimentoProps {
    openned?: boolean;
    loading?: boolean;
    closeModal: () => void;
    zIndex?: string;
    tamanho?: DialogProps['maxWidth'];
}

export const DialogScannAtendimento = ({
    closeModal,
    ...props
}: DialogScannAtendimentoProps) => {
    const classes = useStyles();
    const { showToast } = useToastSaurus();
    const { searchComandas } = usePedidoLocal();
    const { push: historyPush } = useHistory();
    const { setComandaId } = useMovAtual();
    const { getPedidoSalaoProcessado } = useGetPedidoSalaoProcessado();
    const { getEmpresaSelecionada } = useSessaoAtual();
    const { getConfigByCod } = useEmpresaAtual();

    const confirmarComanda = useCallback(
        async (codComanda: string) => {
            try {
                const buscaPrefixo = getConfigByCod(EnumEmpresaConfig.PrefixoDeComanda);
                const prefixo = buscaPrefixo?.toUpperCase();

                let comanda: any = codComanda;

                if(comanda.startsWith(prefixo)) {
                  comanda = codComanda.slice(prefixo?.length);
                }
                
                if (!isEmpty(prefixo) && codComanda.startsWith(prefixo!)) {
                    if (isOnlyHasNumber(comanda)) {
                        comanda = Number(comanda);
                    }
                }

                const comandas = await searchComandas(comanda);

                if (comandas.length === 0) {
                    throw new Error(
                        `Nenhuma comanda foi identificada com o codigo: ${comanda}`,
                    );
                }

                setComandaId(comandas[0]?.id ?? '');

                const pedidos = await getPedidoSalaoProcessado(getEmpresaSelecionada()?.Id!, `comandaId=${comandas[0]?.id ?? ''}`);

                let comandaComPedido;
                // eslint-disable-next-line array-callback-return
                await pedidos.resultado?.data?.list?.map((item: any) => {
                    // eslint-disable-next-line array-callback-return
                    item.pedidos.map((valor: any) => {
                        comandaComPedido = valor.comandaId;
                    })
                })

                if (isEmpty(comandaComPedido) || comandaComPedido !== comandas[0].id) {
                    return showToast('error', `A Comanda ${codComanda} não possui um pedido.`)
                }

                else {
                    historyPush(`/venda-simples/visualizar-pedido/${comandas[0].id}`)
                }

            } catch (err: any) {
                showToast('error', err.message);
            }
        },
        [getConfigByCod, getEmpresaSelecionada, getPedidoSalaoProcessado, historyPush, searchComandas, setComandaId, showToast],
    );

    const leituraRealizada = useCallback(
        (novoCod: string) => {
            confirmarComanda(novoCod);
        },
        [confirmarComanda],
    );

    return (
        <Dialog
            maxWidth={props.tamanho || 'md'}
            open={props.openned || false}
            fullWidth
            keepMounted
            className={classes.root}
            disableEscapeKeyDown
            ref={(el: any) => {
                if (el && (props.zIndex || '').length > 0) {
                    el.style.setProperty('z-index', props.zIndex, 'important');
                }
            }}
        >
            <div style={{ padding: 8 }}>
                <Typography
                    style={{
                        textAlign: 'center',
                        margin: '0 0 24px 0',
                        fontWeight: '600',
                        background: 'rgb(0 0 0 / 50%)',
                        padding: 12,
                        color: '#FFF',
                        borderRadius: 8,
                    }}
                >
                    Posicione o código de barras na marcação
                </Typography>
                <div style={{ borderRadius: 8, overflowY: 'hidden' }}>
                    {props.openned && (
                        <Scanner onScan={leituraRealizada} inicialOrSecundario={false} openned={props.openned} />
                    )}
                </div>
                <div style={{ justifyContent: 'center', display: 'flex' }}>
                    <CloseIcon
                        onClick={() => {
                            closeModal();
                        }}
                        style={{ fontSize: 50, marginTop: 16, cursor: 'pointer' }}
                    />
                </div>
            </div>
        </Dialog>
    );
};
