import { useRef, useCallback, useEffect, useState } from 'react';
import { useSessaoAtual, useToastSaurus } from 'services/app';
import { useCadastros } from '../../../../../../services/app/hooks/cadastros';
import { ModalHeader } from '../../../components/modal-header/modal-header';
import { useModalStyles } from '../../../utils/modal-styles';
import { VoltarIcon } from '../../../../icons/voltar-icon';
import { ButtonModalHeader } from '../../../../controles/buttons/button-modal-header/button-modal-header';
import { CircularLoading } from '../../../../utils';
import classNames from 'classnames';
import { toDecimal, toDecimalString } from 'utils/to-decimal';
import NovoPagamentoListData from 'views/pages/private/movimentacao/mov-pagamento-novo/components/novo-pagamento-list/novo-pagamento-list-data';
import { FinalizadoraModel } from 'model/api/gestao/finalizadora/finalizadora-model';
import { useGetFinalizadoras } from 'data/api/gestao/finalizadora';
import { isEmpty } from 'lodash';
import { CardModel, PagsModel } from 'model/api/gestao/venda/venda-completa-model';
import { EnumPagTpMod } from 'model';
import { EnumTpTecladoFuncoes, TecladoFuncoesProps } from './teclado-funcoes-props';
import { roundTo } from 'utils/round-to';
import { useStyles } from './teclado-funcoes-styles';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { Button, Grid } from 'views/design-system';
import { OkIcon } from 'views/components/icons';
import TecladoFuncoesFragment from './components/teclado-funcoes-fragment/teclado-funcoes-fragment';

export const TecladoFuncoes = ({ callback, tipo, isPagFatura, valor, label }: TecladoFuncoesProps) => {

  const valorDigitadoConfirmado = useRef<number>(valor || 0);
  const digitado = useRef<number>(valor || 0);
  const pagClasses = useStyles()
  const [queryStatus, setQueryStatus] = useState({
    page: 1,
    totalPages: 0,
    totalResults: 0,
    list: Array<FinalizadoraModel>()
  });

  const fillResult = useCallback(
    (
      page: number,
      totalPages: number,
      totalResults: number,
      list: Array<FinalizadoraModel>
    ) => {
      setQueryStatus({
        page: page,
        list: list,
        totalResults: totalResults,
        totalPages: totalPages
      });
    },
    []
  );

  const { getFinalizadoras, carregando: carregandoFinalizadoras } =
    useGetFinalizadoras();
  const {
    fecharTecladoFuncoes,
    abrirParcelamento
  } = useCadastros();
  const { getEmpresaSelecionada } = useSessaoAtual();
  const { callEvent } = useEventTools()

  const carregando = carregandoFinalizadoras;
  const classes = useModalStyles();
  const { showToast } = useToastSaurus();

  useEffect(() => {
    callEvent(AppEventEnum.PermiteEntradaDigitada, false)

    return () => {
      callEvent(AppEventEnum.PermiteEntradaDigitada, true)
    }
  }, [callEvent])

  const search = useCallback(async () => {
    try {
      if (tipo !== EnumTpTecladoFuncoes.Pagamento) return

      const query =
        (!isEmpty(getEmpresaSelecionada()?.Id)
          ? '&EmpresaId=' + getEmpresaSelecionada()?.Id
          : '') + '&pageSize=30';
      const res = await getFinalizadoras(query, 1);
      fillResult(
        1,
        1,
        res?.resultado?.data.list.length ?? 0,
        (res?.resultado?.data.list ?? []).filter((finalizadora: FinalizadoraModel) => {
          if (isPagFatura) {
            if (finalizadora.tpMod === EnumPagTpMod.CREDITO_LOJA) return false
          }
          return finalizadora
        })
      );
    } catch (e: any) {
      showToast('error', e.message);
    }
  }, [tipo, getEmpresaSelecionada, getFinalizadoras, fillResult, isPagFatura, showToast]);

  useEffect(() => {
    (async () => {
      await search();
    })();
  }, [search]);

  const onCloseClick = useCallback(() => {
    fecharTecladoFuncoes();
  }, [fecharTecladoFuncoes]);

  const textChanged = useCallback(
    async (text: string, formattedText: string) => {
      try {
        digitado.current = toDecimal(formattedText, 2);
        return true;
      } catch (e: any) {
        showToast(
          'error',
          'Erro ao inserir o valor avulso. Detalhe: ' + e.message
        );
        return false;
      }
    },
    [showToast]
  );

  const onCardClicked = useCallback(
    async (paymentMethod: FinalizadoraModel) => {
      try {
        // Parcelamento do pagamento
        if (digitado.current === 0) {
          throw new Error(
            'Não é possível realizar um pagamento com valor zerado.'
          );
        }
        if (valor && digitado.current > valor && ![EnumPagTpMod.DINHEIRO, EnumPagTpMod.OUTRO].includes(paymentMethod.tpMod)) {
          throw new Error('O valor não pode ser maior que o restante.')
        }

        const arrayParcelamento = () => {
          const arrayParcelamento: number[] = [];
          for (let i = 1; i <= paymentMethod.qMaxParc; i++) {
            arrayParcelamento.push(i);
          }
          return arrayParcelamento;
        };

        const arrayParc = arrayParcelamento().filter((item: number) => {
          return digitado.current / item >= paymentMethod.vMinParc;
        });

        if (
          paymentMethod.qMaxParc > 1 &&
          paymentMethod.vMinParc < digitado.current - 0 &&
          arrayParc.length !== 1 && !isPagFatura
        ) {
          const parapagar = digitado.current;

          abrirParcelamento(
            async (parcelas: number) => {
              const pagModel: PagsModel = {
                ...new PagsModel(),
                tPag: paymentMethod.tpMod,
                descricao: paymentMethod.descricao,
                tpTransacao: paymentMethod.tpTransacao,
                vPag: digitado.current,
                qtdeParcela: parcelas,
                pagamentoId: paymentMethod.id,
                card: {
                  ...new CardModel(),
                  id: paymentMethod.id,
                }
              }
              const res = await callback({
                pag: pagModel,
                credenciais: paymentMethod.credenciais
              })
              if (res) {
                fecharTecladoFuncoes();
              }
            },
            1,
            parapagar,
            paymentMethod
          );
        } else {
          const pagModel: PagsModel = {
            ...new PagsModel(),
            tPag: paymentMethod.tpMod,
            descricao: paymentMethod.descricao,
            tpTransacao: paymentMethod.tpTransacao,
            vPag: digitado.current,
            vTroco: digitado.current > (valor || 0) ? roundTo(digitado.current - (valor || 0)) : 0,
            qtdeParcela: 1,
            pagamentoId: paymentMethod.id,
            card: {
              ...new CardModel(),
              id: paymentMethod.id,
            }
          }

          const res = await callback({
            pag: pagModel,
            credenciais: paymentMethod.credenciais,
            credenciado: paymentMethod.credenciado,
          })

          if (res) {
            fecharTecladoFuncoes();
          }
        }
      } catch (e: any) {
        showToast('error', e.message)
      }
    },
    [abrirParcelamento, callback, fecharTecladoFuncoes, isPagFatura, showToast, valor]
  );

  const handleClick = async () => {
    try {
      // Parcelamento do pagamento
      if (digitado.current === 0) {
        throw new Error(
          'Não é possível realizar um pagamento com valor zerado.'
        );
      }
      if (valor && digitado.current > valor) {
        throw new Error(`O valor não pode ser maior que o restante. Valor à pagar: R$ ${toDecimalString(valor)}`)
      }

      const res = await callback({
        valor: digitado.current
      })

      if (res) {
        fecharTecladoFuncoes();
      }
    } catch (err: any) {
      showToast('error', err.message)
    }
  }

  const onCardChecked = useCallback((model: FinalizadoraModel) => { }, []);
  return (
    <div className={classes.root}>
      {carregando ? <CircularLoading tipo="FULLSIZED" /> : null}
      <ModalHeader
        title={label || 'Dados do Pagamento'}
        leftArea={
          <ButtonModalHeader
            tooltip="Voltar"
            icon={<VoltarIcon tipo="MODAL_HEADER" />}
            onClick={onCloseClick}
          />
        }
      />
      <div className={classes.content}>
        <div
          className={classNames(
            classes.contentForms,
            pagClasses.contentForms,
            carregandoFinalizadoras ? classes.contentFormsLoading : undefined
          )}
        >
          <Grid
            flexDirection="column"
            style={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'column' }}
          >
            <Grid
              style={{
                flex: '1 1 100%',
                overflowX: 'hidden',
                overflow: 'hidden',
                position: 'relative'
              }}
            >
              <TecladoFuncoesFragment
                valorDigitadoConfirmadoRef={valorDigitadoConfirmado}
                vRestante={valor ?? 0}
                vPago={0}
                vPessoa={valor ?? 0}
                textChanged={textChanged}
                tipo={tipo}
              />
            </Grid>

            <Grid>
              {tipo !== EnumTpTecladoFuncoes.Pagamento ? (
                <Grid p={2}>
                  <Button size="large" fullWidth color="primary" variant="contained" onClick={handleClick}>
                    <OkIcon tipo="BUTTON_PRIMARY" />
                    Confirmar
                  </Button>
                </Grid>
              ) : (
                <NovoPagamentoListData
                  carregando={carregandoFinalizadoras}
                  list={queryStatus.list}
                  selectedList={[]}
                  onCardClicked={onCardClicked}
                  onCardChecked={onCardChecked}
                  paymentScreen
                  entrada
                  isPagFatura={isPagFatura}
                />
              )}
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};
