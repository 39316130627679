import { DefaultIconProps } from './default-icon/default-icon-props';
import { DefaultIcon } from './default-icon/default-icon';

export const EloIcon = (props: DefaultIconProps) => {
  return (
    <>
      <DefaultIcon
        tipo={props.tipo}
        class={props.class}
        style={props.style}
        fill={props.fill}
      >
        <g clip-path="url(#clip0_1207_5410)">
          <path d="M4.24628 0H75.3716C77.7176 0 79.6178 1.90021 79.6178 4.24628V45.7537C79.6178 48.0998 77.7176 50 75.3716 50H4.24628C1.90021 50 0 48.0998 0 45.7537V4.24628C0 1.90021 1.90021 0 4.24628 0Z" fill="black" />
          <path d="M15.9979 18.1097C16.7197 17.8655 17.4947 17.7381 18.3015 17.7381C21.8259 17.7381 24.7664 20.2434 25.4352 23.5661L30.4246 22.547C29.2781 16.8995 24.2887 12.6426 18.3015 12.6426C16.932 12.6426 15.6157 12.8655 14.3843 13.2795L15.9979 18.1097Z" fill="#FFF100" />
          <path d="M10.1061 34.2889L13.4819 30.4673C11.9745 29.1297 11.0297 27.187 11.0297 25.0108C11.0297 22.8452 11.9745 20.8919 13.4819 19.5649L10.1061 15.7539C7.54776 18.0257 5.93417 21.3272 5.93417 25.0214C5.93417 28.7051 7.54776 32.0172 10.1061 34.2889Z" fill="#00A3DF" />
          <path d="M25.4352 26.4746C24.7558 29.7973 21.8259 32.292 18.3015 32.292C17.4947 32.292 16.7197 32.1646 15.9872 31.9205L14.3737 36.7506C15.6051 37.1646 16.932 37.3876 18.3015 37.3876C24.2887 37.3876 29.2781 33.1413 30.4246 27.4937L25.4352 26.4746Z" fill="#EE4023" />
          <path d="M47.0488 29.8932C46.2208 30.7 45.1061 31.1883 43.8747 31.1671C43.0255 31.1565 42.2399 30.9017 41.5817 30.477L39.9257 33.1097C41.0616 33.821 42.3885 34.2456 43.8323 34.2669C45.9236 34.2987 47.8344 33.4707 49.225 32.1225L47.0488 29.8932ZM44.0552 19.1607C39.8938 19.097 36.4543 22.4303 36.3906 26.5917C36.3694 28.1522 36.8153 29.6172 37.6115 30.8274L51.2845 24.9781C50.5202 21.6979 47.5902 19.2244 44.0552 19.1607ZM39.5223 27.1862C39.5011 27.0163 39.4904 26.8359 39.4904 26.6554C39.5329 24.2032 41.5499 22.2393 44.0021 22.2817C45.3397 22.3029 46.5287 22.908 47.3248 23.8635L39.5223 27.1862ZM55.5839 15.7637V30.339L58.1104 31.39L56.9108 34.2669L54.4055 33.2265C53.8429 32.9824 53.4607 32.6108 53.1741 32.1862C52.8981 31.7615 52.6858 31.1671 52.6858 30.3815V15.7637H55.5839Z" fill="white" />
          <path d="M64.7027 22.5052C65.1486 22.3566 65.6157 22.2823 66.1146 22.2823C68.2696 22.2823 70.0531 23.8109 70.4671 25.8385L73.5138 25.2122C72.8131 21.7621 69.7664 19.1719 66.1146 19.1719C65.276 19.1719 64.4692 19.3099 63.7261 19.554L64.7027 22.5052ZM61.104 32.3672L63.1635 30.0424C62.2399 29.225 61.6666 28.036 61.6666 26.709C61.6666 25.3821 62.2505 24.1931 63.1635 23.3863L61.104 21.0615C59.5435 22.4415 58.5562 24.4691 58.5562 26.7196C58.5562 28.9702 59.5435 30.9872 61.104 32.3672ZM70.4671 27.6114C70.0531 29.639 68.259 31.1676 66.1146 31.1676C65.6263 31.1676 65.1486 31.0827 64.7027 30.9341L63.7155 33.8853C64.4692 34.14 65.276 34.278 66.1146 34.278C69.7664 34.278 72.8131 31.6878 73.5138 28.2377L70.4671 27.6114Z" fill="white" />
        </g>
        <defs>
          <clipPath id="clip0_1207_5410">
            <rect width="79.6178" height="50" fill="white" />
          </clipPath>
        </defs>
      </DefaultIcon>
    </>
  );
};
