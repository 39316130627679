import { forwardRef, useCallback, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { useFormFinalizadoraCadastroValidation } from './form-finalizadora-cadastro-validations';
import { FinalizadoraCadastroFormModel } from 'model/app/forms/finalizadora/finalizadora-cadastro-form-model';
import { makeUtilClasses, useThemeQueries } from 'views';
import { picker } from 'utils/picker';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { SelectSaurus } from 'views/components/controles/selects/select-saurus/select-saurus';
import {
  DefaultFormProps,
  DefaultFormRefs,
} from 'views/components/form/utils/form-default-props';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// import { GestaoStorageKeys, useGestaoStorage, useGestaoToken } from 'services/app';
// import { KeyValueModel } from 'model';
import { guidEmpty } from 'utils/guid-empty';
import { EnumPagTpMod, EnumPagTpTransacao } from 'model';
import { AmbientePagamentoMock } from 'data/mocks/ambiente-credencial-mock';
import { useEmpresaAtual } from 'services/app/hooks/empresa-atual';
import { VariaveisAmbiente } from 'config';
import { CredenciamentoSafra } from 'model/api/gestao/finalizadora/finalizadora-model';
import { EnumAmbientePagamento } from 'model/enums/enum-ambiente-pagamento';
import { validarCNPJ } from 'utils/cpfcnpj-validate';
import { useGetContasBancarias } from 'data/api/gestao/conta-bancaria/get-contas-bancarias';
import { ContaBancariaModel } from 'model/api/gestao/conta-bancaria/conta-bancaria-model';
import { useMenuOptionsStyles } from 'views/components/menu-options/menu-options-styles';
import { SwitchSaurus } from 'views/components/controles';
import { useGetContaBancariaBoletoConfig } from 'data/api/gestao/conta-bancaria/get-conta-bancaria-boleto-config';
import { BancoMock } from 'data/mocks/banco-mock';
import { useToastSaurus } from 'services/app';
import { Box, Button, Divider, Grid, Typography } from 'views/design-system';
import { TypeTpModTransacao } from 'data/mocks';
import { FinalizadoraTpVencimentoMock } from 'data/mocks/finalizadora-tp-vencimento-mock';
import { isObject } from 'lodash';

interface FormFinalizadoraCadastroProps extends DefaultFormProps<FinalizadoraCadastroFormModel> {
  tpMod: TypeTpModTransacao;
}

export const FormFinalizadoraCadastro = forwardRef<
  DefaultFormRefs<FinalizadoraCadastroFormModel>,
  FormFinalizadoraCadastroProps
>(
  (
    { loading, tpMod, ...props }: FormFinalizadoraCadastroProps,
    ref,
  ) => {
    const utilClasses = makeUtilClasses();
    const refInputDescricao = useRef<HTMLInputElement>(null);
    const menuOptionsStyles = useMenuOptionsStyles({})
    const [isBoletoIntegrado, setIsBoletoIntegrado] = useState(false)
    const { FormFinalizadoraYupValidation } =
      useFormFinalizadoraCadastroValidation({ tipo: tpMod, isBoletoIntegrado });
    const { isMobile, theme } = useThemeQueries();
    const { getEmpresaAtual } = useEmpresaAtual();
    const { showToast } = useToastSaurus()

    const isDev = VariaveisAmbiente.isDev

    const {
      handleSubmit,
      control,
      formState: { errors, touchedFields },
      setValue,
      reset,
      getFieldState,
      setError,
    } = useForm<FinalizadoraCadastroFormModel>({
      resolver: yupResolver(FormFinalizadoraYupValidation),
      defaultValues: new FinalizadoraCadastroFormModel(),
      criteriaMode: 'all',
      mode: 'onChange',
    });

    const validateCarc = useCallback((value) => {
      const descricao: string = value.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
      const regexCaracEspeciais = /[^a-zA-Z0-9À-ÖØ-öø-ÿ\s,.\\-]/
      if (regexCaracEspeciais.test(descricao)) {
        return true
      }
      return false
    }, [])

    const possuiParcelamento = useMemo(() =>
      tpMod === EnumPagTpMod.CARTAO_CREDITO ||
      tpMod === EnumPagTpMod.CREDITO_LOJA ||
      tpMod === EnumPagTpMod.BOLETO_BANCARIO
      , [tpMod])

    const possuiVencimento = useMemo(() =>
      tpMod === EnumPagTpMod.BOLETO_BANCARIO
      , [tpMod])

    const onSubmit = (values: FinalizadoraCadastroFormModel) => {
      validateCarc(values.descricao)
      const isValidName = validateCarc(values.descricao);
      if (isValidName) {
        setError('descricao', { type: "error", message: 'Não pode conter caracteres especiais.' })
        return
      }

      const model = picker<FinalizadoraCadastroFormModel>(
        values,
        new FinalizadoraCadastroFormModel(),
      );


      if (!isBoletoIntegrado) model.credenciamentoBoleto = undefined;
      if (model.empresaId === guidEmpty()) { model.empresaId = null }
      if (!possuiVencimento) {
        model.vencimento = null
      }
      props.onSubmit(model);

    };

    // Tipo Boleto deps
    const { getContasBancarias, carregando: carregandoContasBancarias } = useGetContasBancarias()
    const [contasBancarias, setContasBancarias] = useState<ContaBancariaModel[]>([])
    const { getContaBancariaBoletoConfig, carregando: carregandoContaBancariaConfig } = useGetContaBancariaBoletoConfig()

    const handleGetContaBancariaConfig = async (contaBancariaId: string) => {
      try {

        const res = await getContaBancariaBoletoConfig(0, 0, {
          contaBancariaId: contaBancariaId,
          empresaId: getEmpresaAtual()?.id ?? ''
        })
        if (res.erro) {
          throw res.erro
        }
        if (res.resultado?.data.list.length <= 0)
          throw new Error("Nenhuma configuração cadastrada")

        setValue('credenciamentoBoleto.ConfigContaId', res.resultado?.data.list[0].id)
      } catch (err: any) {
        showToast('error', err.message)
      }
    }

    const isFinalizadoraCredenciada = useMemo(() => {
      if (isObject(tpMod)) {
        return tpMod.tpMod === EnumPagTpMod.CARTAO_CREDITO && tpMod.tpTransacao === EnumPagTpTransacao.SAFRAPAY_WALLET
      }

      return tpMod === EnumPagTpMod.PAGAMENTO_INSTANTANEO
    }, [tpMod])

    useImperativeHandle(ref, () => ({
      submitForm: async () => {
        await handleSubmit(onSubmit)();
      },
      resetForm: () => {
        if (!isMobile) {
          refInputDescricao.current?.focus();
        }
      },
      fillForm: async (model: FinalizadoraCadastroFormModel) => {

        const empresaId = getEmpresaAtual()?.id || guidEmpty();
        if (isFinalizadoraCredenciada) {
          model.empresaId = empresaId
          model.credenciais = {
            ...new CredenciamentoSafra(),
            ambiente: EnumAmbientePagamento.Producao,
            razaoSocial: getEmpresaAtual()?.razaoSocial || '',
            cnpj: getEmpresaAtual()?.cpfcnpj || '',
          };
        } else if (model.tpMod === EnumPagTpMod.BOLETO_BANCARIO) {
          try {
            model.empresaId = empresaId
            const getContasBancariasResponse = await getContasBancarias(0, 0, {
              empresaId: empresaId
            })
            if (getContasBancariasResponse.erro) {
              throw getContasBancariasResponse.erro
            }

            setContasBancarias(getContasBancariasResponse.resultado?.data.list)
            model.credenciamentoBoleto = {
              ConfigContaId: '',
              ContaBancariaId: '',
              EmpresaId: getEmpresaAtual()?.id || guidEmpty()
            };
          } catch (err: any) {
            showToast('error', err.message)
          }
        } else {
          model.credenciais = undefined
        }
        reset({ ...model })
        if (!isMobile) {
          refInputDescricao.current?.focus();
        }
      },
    }));

    // const { convertToken } = useGestaoToken();
    // const { getRegistro } = useGestaoStorage();
    // const token = getRegistro(GestaoStorageKeys.Token, false);
    // const tokenConvertido = convertToken(token);
    // const empresas = useMemo(() => tokenConvertido!.empresa.map(empresa => new KeyValueModel(empresa.Id, empresa.Descricao)), [tokenConvertido])
    // const empresasMock = tpMod === EnumPagTpMod.PAGAMENTO_INSTANTANEO ? empresas : [new KeyValueModel(guidEmpty(), 'Todas as Empresas'), ...empresas];
    const carregandoGeral = [carregandoContaBancariaConfig, carregandoContasBancarias, loading].includes(true)


    return (
      <>
        <Box my={2}>
          <div className={utilClasses.formContainer}>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className={carregandoGeral && props.showLoading ? utilClasses.controlLoading : ''}
            >
              {(carregandoGeral) && props.showLoading ? (
                <div>
                  <CircularLoading tipo="FULLSIZED" />
                </div>
              ) : null}
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Controller
                    name="descricao"
                    control={control}
                    render={({ field }) => (
                      <TextFieldSaurus
                        inputRef={refInputDescricao}
                        fullWidth
                        autoComplete='new-password'
                        label="Descrição"
                        variant="outlined"
                        error={Boolean(
                          errors.descricao && errors.descricao.message,
                        )}
                        helperText={
                          touchedFields.descricao || errors.descricao
                            ? errors.descricao?.message
                            : undefined
                        }
                        {...field}
                        onChange={(e) => {
                          e.target.value = e.target.value.trimStart();
                          field.onChange(e);
                        }}
                      />
                    )}
                  />
                </Grid>
                {/* {tokenConvertido && tokenConvertido.empresa.length > 1 && <Grid item xs={12}>
                  <Controller
                    name="empresaId"
                    control={control}
                    render={({ field }) => (
                      <SelectSaurus
                        label="Empresa"
                        conteudo={empresasMock}
                        error={Boolean(errors.empresaId && errors.empresaId.message)}
                        helperText={
                          touchedFields.empresaId || errors.empresaId
                            ? errors.empresaId?.message
                            : undefined
                        }
                        {...field}
                        onChange={(event) => {
                          const empresa = empresasMock.filter(
                            (empresa) => empresa.Key === event.target.value,
                          )[0]?.Key;
                          setValue("empresaId", empresa);
                        }}
                        value={getValues("empresaId")}

                      />
                    )}
                  />

                </Grid>} */}
                {possuiParcelamento && <>
                  <Grid item md={6} xs={6}>
                    <Controller
                      name="qMaxParc"
                      control={control}
                      render={({ field }) => (
                        <TextFieldSaurus
                          allowSubmit
                          disabled={loading}
                          fullWidth
                          tipo="NUMERO_RANGE"
                          variant="outlined"
                          label="Parcelamento Máximo"
                          error={Boolean(
                            errors.qMaxParc && errors.qMaxParc.message,
                          )}
                          helperText={
                            touchedFields.qMaxParc || errors.qMaxParc
                              ? errors.qMaxParc?.message
                              : undefined
                          }
                          {...field}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <Controller
                      name="vMinParc"
                      control={control}
                      render={({ field }) => (
                        <TextFieldSaurus
                          fullWidth
                          tipo="DECIMAL"
                          showStartAdornment
                          manterMascara
                          casasDecimais={2}
                          label="Valor Mínimo de Parcela"
                          variant="outlined"
                          error={Boolean(
                            errors.vMinParc && errors.vMinParc.message,
                          )}
                          helperText={
                            touchedFields.vMinParc || errors.vMinParc
                              ? errors.vMinParc?.message
                              : undefined
                          }
                          {...field}
                        />
                      )}
                    />
                  </Grid>
                </>}
                {possuiVencimento && (
                  <Grid item xs={12}>
                    <Controller
                      name="vencimento"
                      control={control}
                      render={({ field }) => (
                        <SelectSaurus
                          disabled={loading}
                          allowSubmit={false}
                          conteudo={FinalizadoraTpVencimentoMock}
                          label="Tipo de Vencimento"
                          fullWidth
                          autoComplete={'off'}
                          helperText={
                            getFieldState('vencimento').error
                              ? getFieldState('vencimento').error?.message
                              : undefined
                          }
                          error={Boolean(getFieldState('vencimento').error)}
                          {...field}
                        />
                      )}
                    />
                  </Grid>
                )}
                {isFinalizadoraCredenciada &&
                  <>
                    <Grid item xs={12}>
                      <Typography variant='h6' color='textPrimary'>Credenciais</Typography>
                      <Divider style={{ background: theme.palette.secondary.main }} />
                    </Grid>
                    <Grid item xs={12} style={{ paddingTop: 16 }}>
                      <Controller
                        name="credenciais.razaoSocial"
                        control={control}
                        render={({ field }) => (
                          <TextFieldSaurus
                            disabled={loading}
                            allowSubmit={false}
                            readOnly
                            id="credenciais.razaoSocial"
                            label="Razão Social"
                            fullWidth
                            autoComplete={'off'}
                            helperText={
                              getFieldState('credenciais.razaoSocial').error
                                ? getFieldState('credenciais.razaoSocial').error?.message
                                : undefined
                            }
                            error={Boolean(getFieldState('credenciais.razaoSocial').error)}
                            {...field}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Controller
                        name="credenciais.cnpj"
                        control={control}
                        render={({ field }) => (
                          <TextFieldSaurus
                            disabled={loading}
                            allowSubmit={false}
                            readOnly={validarCNPJ(getEmpresaAtual()?.cpfcnpj || '')}
                            id="credenciais.cnpj"
                            tipo='CNPJ'
                            label="CNPJ"
                            fullWidth
                            autoComplete={'off'}
                            helperText={
                              getFieldState('credenciais.cnpj').error
                                ? getFieldState('credenciais.cnpj').error?.message
                                : undefined
                            }
                            error={Boolean(getFieldState('credenciais.cnpj').error)}
                            {...field}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Controller
                        name="credenciais.merchantId"
                        control={control}
                        render={({ field }) => (
                          <TextFieldSaurus
                            disabled={loading}
                            allowSubmit={false}
                            id="credenciais.merchantId"
                            label="ID Merchant"
                            fullWidth
                            autoComplete={'off'}
                            helperText={
                              getFieldState('credenciais.merchantId').error
                                ? getFieldState('credenciais.merchantId').error?.message
                                : undefined
                            }
                            error={Boolean(getFieldState('credenciais.merchantId').error)}
                            {...field}
                            onChange={(e) => {
                              e.target.value = e.target.value.trimStart();
                              field.onChange(e);
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Controller
                        name="credenciais.merchantToken"
                        control={control}
                        render={({ field }) => (
                          <TextFieldSaurus
                            disabled={loading}
                            allowSubmit={false}
                            id="credenciais.merchantToken"
                            label="Token Merchant"
                            fullWidth
                            autoComplete={'off'}
                            helperText={
                              getFieldState('credenciais.merchantToken').error
                                ? getFieldState('credenciais.merchantToken').error?.message
                                : undefined
                            }
                            error={Boolean(getFieldState('credenciais.merchantToken').error)}
                            {...field}
                            onChange={(e) => {
                              e.target.value = e.target.value.trimStart();
                              field.onChange(e);
                            }}
                          />
                        )}
                      />
                    </Grid>
                    {isDev && <Grid item xs={12}>
                      <Controller
                        name="credenciais.ambiente"
                        control={control}
                        render={({ field }) => (
                          <SelectSaurus
                            disabled={loading}
                            allowSubmit={false}
                            conteudo={AmbientePagamentoMock}
                            id="credenciais.ambiente"
                            label="Ambiente"
                            fullWidth
                            autoComplete={'off'}
                            helperText={
                              getFieldState('credenciais.ambiente').error
                                ? getFieldState('credenciais.ambiente').error?.message
                                : undefined
                            }
                            error={Boolean(getFieldState('credenciais.ambiente').error)}
                            {...field}
                            onChange={ev => {
                              const item =
                                AmbientePagamentoMock
                                  .filter(item => item.Key === ev.target.value)

                              setValue('credenciais.ambiente', item[0].Key)
                            }}
                          />
                        )}
                      />
                    </Grid>}
                  </>}
                {(tpMod === EnumPagTpMod.BOLETO_BANCARIO && contasBancarias.length > 0) &&
                  <>
                    <Grid item xs={12}>
                      <Box className={menuOptionsStyles.menuItem}>
                        <Typography>
                          {isBoletoIntegrado ? 'Desabilitar' : 'Habilitar'} Integração
                          de Boleto
                        </Typography>
                        <SwitchSaurus
                          onChange={(e) => {
                            setIsBoletoIntegrado(e.target.checked)

                          }}
                          size="small"
                          value={isBoletoIntegrado}
                          variant="DEFAULT"
                          checked={isBoletoIntegrado}
                        />
                      </Box>
                    </Grid>
                    {
                      isBoletoIntegrado && (
                        <>
                          <Grid item xs={12}>
                            <Typography variant='h6' color='textPrimary'>Credenciais</Typography>
                            <Divider style={{ background: theme.palette.secondary.main }} />
                          </Grid>
                          <Grid item xs={12}>
                            <Controller
                              name="credenciamentoBoleto.ContaBancariaId"
                              control={control}
                              render={({ field }) => (
                                <SelectSaurus
                                  disabled={loading}
                                  allowSubmit={false}
                                  conteudo={contasBancarias.map((item) => {
                                    return {
                                      Key: item.id,
                                      Value: `Nº Conta: ${item.conta} - ${BancoMock.find((itemBancoMock) => {
                                        return itemBancoMock.Key === item.banco
                                      })?.Value ?? ''}`,
                                    }
                                  })}
                                  id="credenciamentoBoleto.id"
                                  label="Conta bancária"
                                  fullWidth
                                  autoComplete={'off'}
                                  helperText={
                                    getFieldState('credenciamentoBoleto.ContaBancariaId').error
                                      ? getFieldState('credenciamentoBoleto.ContaBancariaId').error?.message
                                      : undefined
                                  }
                                  error={Boolean(getFieldState('credenciamentoBoleto.ContaBancariaId').error)}
                                  {...field}
                                  onChange={ev => {

                                    const item =
                                      contasBancarias
                                        .filter(item => item.id === ev.target.value)
                                    const contaId = item[0].id
                                    setValue('credenciamentoBoleto.ContaBancariaId', contaId)
                                    handleGetContaBancariaConfig(contaId)
                                  }}
                                />
                              )}
                            />
                          </Grid>
                        </>
                      )
                    }

                  </>}
              </Grid>
              <Button style={{ display: 'none' }} type="submit"></Button>
            </form>
          </div>
        </Box>
      </>
    );
  },
);
