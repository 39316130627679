import { PesquisaHeaderProps } from './pesquisa-lista-empresas-props';
import { useStyles } from './pesquisa-lista-empresas-styles';
import { Fade, useTheme } from '@material-ui/core';
import { useState, useCallback, ChangeEvent, useRef, useEffect } from 'react';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { ProcurarIcon } from '../../../../../../../components/icons';
import { useThemeQueries } from 'views';
import { Button, Grid } from 'views/design-system';

export const PesquisaListaEmpresas = (props: PesquisaHeaderProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const { isMobile } = useThemeQueries();
  const [termoPesquisar, setTermoPesquisar] = useState('');
  const refInput = useRef<HTMLDivElement | null>(null);

  //FOCO AO FICAR VISIVEL
  useEffect(() => {
    if (props.isOpened && !isMobile) refInput.current?.focus();
    else {
      setTermoPesquisar('');
    }
  }, [isMobile, props.isOpened]);

  //CHAMADA DO PESQUISAR
  const pesquisar = useCallback(
    (event: any) => {
      event.preventDefault();
      props.onPesquisar({ termo: termoPesquisar });
    },
    [props, termoPesquisar],
  );

  //ALTERAR A PESQUISA
  const onChangePesquisa = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setTermoPesquisar(event.target.value);
    },
    [],
  );

  return (
    <Fade in={props.isOpened} mountOnEnter unmountOnExit>
      <form onSubmit={pesquisar}>
        <Grid container className={classes.root} spacing={1}>
          <Grid item xs={12} md={12}>
            <div className={classes.containerFinal}>
              <TextFieldSaurus
                inputRef={refInput}
                fullWidth
                size="small"
                allowSubmit={true}
                value={termoPesquisar}
                placeholder="Digite aqui alguma informação para procurar..."
                onChange={onChangePesquisa}
                adornmentColor={theme.palette.text.disabled}
              />
              <Button
                color="primary"
                variant="contained"
                type="submit"
                className={classes.btnPesquisa}
                fullWidth={true}
              >
                <ProcurarIcon tipo="BUTTON_PRIMARY" />
              </Button>
            </div>
          </Grid>
          {/* <Grid item xs={12} md={4}>
              <SelectSaurus
                style={{ height: '40px' }}
                fullWidth={true}
                variant="outlined"
                conteudo={StatusIndstatusMockTodos}
                allowSubmit
                onChange={(event) => {
                  if (event) {
                    const newStatus = StatusIndstatusMockTodos.filter(
                      (item) => item.Key === event.target.value,
                    )[0]?.Key;
                    setStatusPesquisar(newStatus);
                  }
                }}
                value={statusPesquisar}
              />

            </div>
          </Grid> */}
        </Grid>
      </form>
    </Fade>
  );
};
