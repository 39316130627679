import React from "react";
import { CardNaoEncontrado } from "views/components/cards";
import { InformacaoIcon } from "views/components/icons";
import { EmpresaUsuarioModel } from "model/api/gestao/perfil/perfil-empresa-usuario-model";
import { CircularLoading } from "views";
import { CardUsuarioPerfil } from "views/components/cards/card-usuarios-perfil/card-usuario-perfil";

export interface PerfilUsuarioListDataProps {
    usuario: Array<EmpresaUsuarioModel>;
    carregando: boolean;
    selectedList: Array<string>;
    onCardSelected: (model: EmpresaUsuarioModel) => any;
    onCardChecked: (id: string) => any;
}

export const PerfiUsuariolListData = ((props: PerfilUsuarioListDataProps) => {

    const onCardSelected = (model: EmpresaUsuarioModel) => {
        props.onCardSelected(model);
    };

    const onCardChecked = (id: string) => {
        props.onCardChecked(id);
    };

    return (
        <>
            {props.carregando && <CircularLoading tipo="FULLSIZED" />}
            {props.usuario.length === 0 && !props.carregando && (
                <CardNaoEncontrado mensagem="Nenhum usuário encontrado." icon={<InformacaoIcon tipo="GERAL" />} />
            )}
            {props.usuario.length > 0 && props.usuario.map((listusuario, index) => {
                return (
                    <CardUsuarioPerfil model={listusuario}
                        key={index}
                        onClick={onCardSelected}
                        onCheck={onCardChecked}
                        selected={false}
                    />
                );
            })}
        </>
    );
}
);
