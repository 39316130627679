import { Radio } from "@material-ui/core";
import { KeyValueModel } from "model";
import { useEffect, useState } from "react";
import { useStyles } from './radio-list-styles'
import { CheckboxDefault } from 'views/components/controles/checkboxes';
import { OkIcon } from "views/components/icons";
import { useToastSaurus } from "services/app";
import { RelatorioProfiler } from "views/components/debug/relatorio-profiler";
import { Box, Button, Typography } from "views/design-system";

interface RadioListProps {
  handleSubmit: (tipo: any) => Promise<void>;
  handleSubmitMultiCheck?: Function
  selecionado: any;
  mock: KeyValueModel[];
  text?: string;
  multiCheck?: boolean;
  //apenas caso seja multicheck
  minChecks?: number ;
}

export const RadioList = ({
  handleSubmit,
  selecionado,
  mock,
  text,
  multiCheck = false,
  handleSubmitMultiCheck,
  minChecks = 0,
}: RadioListProps) => {

  const [select, setSelect] = useState<any>(selecionado);
  const classes = useStyles();
  const { showToast } = useToastSaurus();

  const handleSelect = (value: any) => {
    const temOtipoSelecionado = select.find((tipo: any) => tipo === value)

    //lógica caso seja -1 (TODOS)
    if(value === -1){
      let selecionados = [...select];
      if(!temOtipoSelecionado){
        selecionados = mock.map(tp => tp.Key);
        setSelect(selecionados);
      }else{
        if(minChecks > 0){
          let mocks: any[] = [];
          for(let i=0;i<minChecks;i++){
            mocks.push(mock[i + 1].Key) 
          }
          setSelect([...mocks])
          return
        }
        setSelect([])
      }
      return
    }
    if(temOtipoSelecionado && select.length <= minChecks){
      showToast('info', `Obrigatório ao menos ${minChecks} ${minChecks > 1 ? 'opções' : 'opção'}.`, 2000)
      return
    }
    setSelect((prev: any) => {
      return temOtipoSelecionado || temOtipoSelecionado === 0 ? prev.filter((tipo: any) => tipo !== temOtipoSelecionado && tipo !== -1) : [...prev, value]
    })
  }

  useEffect(() => {
    if(multiCheck && typeof selecionado === 'object' && selecionado.includes(-1)){
      setSelect(mock.map(tp => tp.Key));
    }
  }, [multiCheck, mock, selecionado])

  return (
    <RelatorioProfiler component="radiolist">
      <Box className={classes.container}>
      {text && (
        <Box className={classes.text}>
          <Typography align="center">{text}</Typography>
        </Box>
      )}
      {!multiCheck &&
        mock.map((tipo) => {
          const isSelected = select === tipo.Key;
          return (
            <Box className={classes.item} onClick={() => setSelect(tipo.Key)}>
              <Typography>{tipo.Value}</Typography>
              <Radio checked={isSelected} />
            </Box>
          );
        })}
      {multiCheck &&
        mock.map((tipo) => {
          const isSelected = select.some((t: number) => t === tipo.Key);
          return (
            <Box className={classes.item} onClick={() => handleSelect(tipo.Key)}>
              <Typography>{tipo.Value}</Typography>
              <CheckboxDefault checked={isSelected} />
            </Box>
          );
        })}
      <Box className={classes.button}>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          onClick={() =>
            multiCheck ? handleSubmitMultiCheck?.(select) : handleSubmit(select)
          }
        >
          <OkIcon tipo="BUTTON_PRIMARY" />
          Confirmar
        </Button>
      </Box>
    </Box>
    </RelatorioProfiler>
  );
}