import classNames from "classnames"
import { ButtonModalHeader } from "views/components/controles/buttons/button-modal-header"
import { OkIcon, VoltarIcon } from "views/components/icons"
import { ModalHeader } from "views/components/modals/components"
import { useModalStyles } from "views/components/modals/utils/modal-styles"
import { CadastroEntradaManualProps } from "./cadastro-entrada-manual-props"
import { DefaultFormRefs } from "views/components/form/utils"
import { useCallback, useRef } from "react"
import { FormEntradaCadastro } from "views/components/form/entrada/entrada-cadastro/form-entrada-cadastro"
import { useCadastros, useGestaoToken, useToastSaurus } from "services/app"
import { EntradaCadastroFormModel } from "model/app/forms/entrada/entrada-cadastro-form-model"
import { useGetEnderecoPessoa } from "data/api/gestao/pessoa"
import { usePostVendaCompleta } from "data/api/gestao/venda/post-venda-completa"
import { DestModel, EmitModel, TranspModel, VendaCompletaModel } from "model/api/gestao/venda/venda-completa-model"
import { PessoaEnderecoModel, PessoaModel } from "model/api/gestao/pessoa"
import { EnumModFrete } from "model/enums/enum-mod-frete"
import { isEmpty } from "lodash"
import { EnumTpStatusMov } from "model/enums/enum-tp-status-mov"
import { useHistory } from "react-router-dom"
import { useEmpresaAtual } from "services/app/hooks/empresa-atual"
import { EmpresaCompletaModel } from "model/api/gestao/master/empresaCompleta"
import { stringNumeros } from "utils/string-numeros"
import { EnumMovModelo } from "model"
import { CircularLoading } from "views/components/utils"
import { UFMock } from "data/mocks"
import { useGetUltimaNumeracao } from "data/api/gestao/ultima-numerao/get-ultima-numeracao"
import { EnumTpNf } from "model/enums/enum-tp-nf"
import { Button, Grid } from "views/design-system"

export const CadastroEntradaManual = ({ voltarEntrada }: CadastroEntradaManualProps) => {

    const modalClasses = useModalStyles()
    const cadFormRef = useRef<DefaultFormRefs<EntradaCadastroFormModel>>(null);
    const { getEmpresaAtual } = useEmpresaAtual()
    const { getEnderecoPessoa, carregando: carregandoGetEndereco } = useGetEnderecoPessoa()
    const { postVendaCompleta, carregando: carregandoPost } = usePostVendaCompleta()
    const { getPessoaUsuario } = useGestaoToken()
    const { getUltimaNumeracao: ultimaNumeracao, carregando: carregandoNumeracao } = useGetUltimaNumeracao()
    const carregando = [carregandoGetEndereco, carregandoPost, carregandoNumeracao].includes(true)
    const { showToast } = useToastSaurus()
    const { fecharEntradaModal } = useCadastros()
    const history = useHistory()
    


    const getEnderecoPessoaWrapper = useCallback(async (pessoaId: string) => {
        const res = await getEnderecoPessoa(pessoaId)

        if (res.erro) throw res.erro

        const resultado = res.resultado?.data[0] as PessoaEnderecoModel | null
        return resultado
    }, [getEnderecoPessoa])

    const preencherDest = useCallback((dest: EmpresaCompletaModel) => {
        const emit: DestModel = {
            ...new DestModel(),
            cep: stringNumeros(dest.cep),
            cMun: Number(dest.cMunicipio || 0),
            documento: dest.cpfcnpj,
            id: dest.id,
            ie: dest.ierg,
            nro: dest.numero,
            im: dest.im,
            uf: dest.uf,
            xBairro: dest.bairro,
            xCpl: dest.complemento,
            fone: stringNumeros(dest.fone) || '0',
            xLgr: dest.logradouro,
            xMun: dest.municipio,
            xNome: dest.razaoSocial,
            email: dest.email,
            isuf: '',
        }

        return emit
    }, [])

    const preencherTransp = useCallback((pessoa: PessoaModel, endereco: PessoaEnderecoModel, modFrete: EnumModFrete) => {
        const transp: TranspModel = {
            ...new TranspModel(),
            pessoaId: pessoa.id,
            doc: pessoa.cpfcnpj,
            modFrete: modFrete,
            ie: endereco.ierg,
            xMun: endereco.xMun,
            xNome: pessoa.nome,
            xEnder: `${endereco.logradouro}, ${endereco.numero} - ${endereco.bairro}, ${endereco.cep} ${endereco.complemento ? ', ' + endereco.complemento : ''}`,
        }

        return transp
    }, [])

    const getUltimaNumeracao = async (mod: number, serie: number, tpAmb: number) => {
        const res = await ultimaNumeracao(getEmpresaAtual()?.id ?? '', `${mod}`, `${serie}`, `${tpAmb}`)

        if (res.erro) throw res.erro

        return res.resultado?.data.nnf
    }

    const handleSubmit = async (model: EntradaCadastroFormModel) => {
        try {
            const venda = new VendaCompletaModel()
            const empresa = getEmpresaAtual()

            if (isEmpty(empresa?.cep) && isEmpty(empresa?.logradouro) && isEmpty(empresa?.numero)) {
                throw new Error('Preencha o endereço da sua empresa para realizar a entrada manual')
            }

            venda.infMov.dest = preencherDest(empresa!)
            venda.infMov.finNFe = model.finNFe
            venda.infMov.mod = EnumMovModelo.NFE
            venda.infMov.tpNF = EnumTpNf.ENTRADA
            venda.infMov.natOp = model.natOp
            venda.operadorId = getPessoaUsuario().pessoa.id
            venda.infMov.serie = 0o1
            const numeracao = await getUltimaNumeracao(venda.infMov.mod, venda.infMov.serie, venda.infMov.tpAmb)
            venda.infMov.nnf = numeracao + 1
            venda.terminalNumero = 1

            if (model.transp && !isEmpty(model.transp)) {
                const enderecoPessoa = await getEnderecoPessoaWrapper(model.transp?.id || '')
                venda.infMov.transp = preencherTransp(model.transp, enderecoPessoa || new PessoaEnderecoModel(), model.modFrete)
                venda.infMov.transp.empresaId = empresa?.id || ''
            }
            const enderecoEmit = await getEnderecoPessoaWrapper(model.emit.id)
            let emit: EmitModel = {
                ...model.emit,
            }
            if (enderecoEmit) {
                emit = {
                    ...emit,
                    cep: stringNumeros(enderecoEmit.cep),
                    cMun: Number(enderecoEmit.cMun || '0'),
                    uf: enderecoEmit.uf,
                    xBairro: enderecoEmit.bairro,
                    xCpl: enderecoEmit.complemento,
                    xLgr: enderecoEmit.logradouro,
                    xMun: enderecoEmit.xMun,
                    nro: enderecoEmit.numero,
                    ie: enderecoEmit.ierg,
                    im: enderecoEmit.im
                }
                const cufEmit = UFMock.find(x => x.Value === enderecoEmit.uf)?.Key
                venda.infMov.cuf = cufEmit || 35
            } else {
                venda.infMov.cuf = 35
            }

            if (!venda.infMov.cuf) {
                const cufEmpresaAtual = UFMock.find(x => x.Value === empresa?.uf)?.Key
                venda.infMov.cuf = cufEmpresaAtual ?? 35
            }

            venda.infMov.emit = emit
            venda.infMov.dhEmi = new Date()
            venda.dhCompetencia = new Date()
            venda.empresaId = empresa?.id || ''
            venda.status = EnumTpStatusMov.EmDigitacao

            const res = await postVendaCompleta(venda)
            if (res.erro) throw res.erro

            const resultado = res.resultado?.data as VendaCompletaModel

            showToast('success', 'Entrada criada com sucesso!')
            fecharEntradaModal()
            history.push(`/entrada-mercadoria/visualizar/${resultado.id}`)
        } catch (err: any) {
            showToast('error', err.message)
        }
    }

    return (
        <div className={modalClasses.root}>
            {carregando && <CircularLoading tipo="FULLSIZED" />}
            <ModalHeader
                title={'Entrada Manual'}
                leftArea={
                    <ButtonModalHeader
                        tooltip="Voltar"
                        icon={<VoltarIcon tipo="MODAL_HEADER" />}
                        onClick={voltarEntrada}
                    />
                }
            />
            <div className={modalClasses.content}>
                <div className={classNames(modalClasses.contentForms)}>
                    <FormEntradaCadastro
                        ref={cadFormRef}
                        showLoading={false}
                        loading={carregando}
                        onSubmit={handleSubmit}
                    />
                </div>
                <div className={modalClasses.acoes}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Button
                                disabled={carregando}
                                onClick={() => {
                                    cadFormRef.current?.submitForm()
                                }}
                                variant="contained"
                                color="primary"
                                size="large"
                                fullWidth
                            >
                                <OkIcon tipo="BUTTON_PRIMARY" />
                                Confirmar
                            </Button>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </div>
    )
}