import useStyles from './card-produto-importacao-xml-styles';

import { CardProdutoEntradaImportacaoXMLProps } from './card-produto-importacao-xml-props';

import { DefaultCard } from '../components/default-card';
import { useDefaultCardStyles } from '../components/default-card/default-card-styles';
import { toDecimalString } from '../../../../utils/to-decimal';
import { useThemeQueries } from '../../..';
import { EditarIcon } from 'views/components/icons';
import { toDateString } from 'utils/to-date';
import { useFormContext } from 'react-hook-form';
import { DupCobrModel } from 'model/api/gestao/venda/venda-completa-model';
import { Grid, Tooltip, Typography } from 'views/design-system';
import { tpPagamentoResolver } from 'utils/tp-pagamento-resolver';

export const CardPagamentosImportacaoXML = ({
  model,
  onClick,
  selected,
  index
}: CardProdutoEntradaImportacaoXMLProps) => {
  const classes = useDefaultCardStyles();
  const cardProdutoImporcataoXMLClasses = useStyles();
  const { theme } = useThemeQueries();
  const { getValues } = useFormContext()

  const title = model?.cancelado ? 'Cancelado' : 'Habilitado';

  const background = model?.cancelado
    ? theme.palette.error.main
    : theme.palette.success.main;

  const handleOnClick = async () => {
    if (onClick) {
      onClick(model?.id);
    }
  };

  const retData = () => {
    const cobr = getValues(`infMov.cobr`)
    if (cobr) {
      const dup = cobr.dup.find((x: DupCobrModel) => x.pagId === model.id);
      if (dup && dup.dVenc) {
        return toDateString(dup.dVenc, 'DD/MM/YYYY')
      }
    }
    return 'Não Informado'
  }

  return (
    <DefaultCard
      className={cardProdutoImporcataoXMLClasses.content}
      isSelected={selected}
      onClick={handleOnClick}
    >
      <Tooltip arrow title={title} placement="right">
        <div
          className={classes.tagStatus}
          style={{
            background
          }}
        />
      </Tooltip>

      <Grid container spacing={2}>
        <Grid
          item
          xs={6}
          className={cardProdutoImporcataoXMLClasses.gridProdutoItem}
        >
          <Typography color="textPrimary" variant="caption">
            Forma de Pagamento
          </Typography>
          <Typography color="textPrimary" variant="body1">
            <strong>
              {model?.tPag
                ? tpPagamentoResolver(model.tPag, model.tpTransacao)?.Value
                : 'Não informado'}
            </strong>
          </Typography>
        </Grid>

        <Grid
          item
          xs={6}
          className={cardProdutoImporcataoXMLClasses.gridProdutoItem}
        >
          <Typography color="textPrimary" variant="caption">
            Data de Vencimento
          </Typography>
          <Typography color="textPrimary" variant="body1">
            <strong>
              {retData()}
            </strong>
          </Typography>
        </Grid>

        <Grid
          item
          xs={6}
          className={cardProdutoImporcataoXMLClasses.gridProdutoName}
        >
          <Typography color="textPrimary" variant="caption">
            Parcela
          </Typography>
          <Typography color="textPrimary" variant="body1">
            <strong> {model?.qtdeParcela ? model.qtdeParcela + 'x' : '1x'}</strong>
          </Typography>
        </Grid>

        <Grid
          item
          xs={6}
          className={cardProdutoImporcataoXMLClasses.gridProdutoName}
        >
          <Typography color="textPrimary" variant="caption">
            Valor Total
          </Typography>
          <Typography color="textPrimary" variant="body1">
            <strong>
              {model?.vPag
                ? `R$ ${toDecimalString(model.vPag)}`
                : 'Não informado'}
            </strong>
          </Typography>
        </Grid>
      </Grid>

      <Tooltip arrow title="Editar Produto">
        <div className={cardProdutoImporcataoXMLClasses.cardRightButton}>
          <EditarIcon tipo="BUTTON" />
        </div>
      </Tooltip>
    </DefaultCard>
  );
};
