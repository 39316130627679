import { Fade, useTheme } from '@material-ui/core';
import { CotaModel } from 'model/api/gestao/cota/cota-model';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { EnumPlanoOpcoes } from 'model/enums/enum-plano-opcoes';
import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useToastSaurus } from 'services/app';
import { useSessaoAtual } from 'services/app';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { getPlanoLimite } from 'utils/plano-utils';
import { CircularLoading, useThemeQueries } from 'views';
import { ConsumptionChart } from 'views/components/consumption-chart/consumption-chart';
import { CloseIcon } from 'views/components/icons/close-icon';
import { UpgradeIcon } from 'views/components/icons/upgrade-icon';
import { styles } from './card-controle-cota-usuarios-styles';
import { useGetCotasAnoMes } from 'data/api/gestao/cota/get-cota-ano-mes';
import { dateParaCompetencia } from 'utils/competencia';
import { toDecimal } from 'utils/to-decimal';
import { Button, Grid, Typography } from 'views/design-system';

export const CardControleCotaUsuarios= () => {
  const [showCard, setShowCard] = useState(true);
  const classes = styles();
  const history = useHistory();
  const { isMobile } = useThemeQueries();
  const theme = useTheme();
  const handleViewPlans = () => {
    history.push('/alterar-plano');
  };
  const { addHandler, removeHandler } = useEventTools()
  const [modalEditAberto, setModalEditAberto] = useState<boolean>()
  const { plano } = useSessaoAtual();
  const { getCotasAnoMes, carregando: carregandoCotas } = useGetCotasAnoMes();
  const carregando = carregandoCotas;
  const { showToast } = useToastSaurus();

  const [queryStatus, setQueryStatus] = useState({
    page: 1,
    totalPages: 0,
    totalResults: 0,
    list: Array<CotaModel>(),
  });

  const fillResult = useCallback(
    async (
      page: number,
      totalPages: number,
      totalResults: number,
      list: Array<CotaModel>,
    ) => {
      setQueryStatus({
        page: page,
        list: list,
        totalResults: totalResults,
        totalPages: totalPages,
      });
    },
    [],
  );

  const anoMes = dateParaCompetencia(new Date());
  
  const search = useCallback(
    async (newPage: number) => {
      const query = 'anoMes=' + anoMes + "&codigo=" + EnumPlanoOpcoes.Usuarios;
      try {
        const res = await getCotasAnoMes(query);
        if (res.erro) throw res.erro;

        //se o index for maior que as paginas ele busca a ultima
        if (
          res.resultado?.data?.pageIndex > res.resultado?.data?.totalPages &&
          res.resultado?.data?.totalResults > 0
        ) {
          search(res.resultado?.data?.totalPages);
          return;
        }

        fillResult(
          res.resultado?.data?.pageIndex,
          res.resultado?.data?.totalPages,
          res.resultado?.data?.totalResults,
          res.resultado?.data,
        );
      } catch (e: any) {
        showToast('error', e.message);
      }
    },
    [anoMes, getCotasAnoMes, fillResult, showToast],
  );

  const modalEdit = useCallback(({ openned }: any) => {
    setModalEditAberto(openned)
  }, [])

  useEffect(() => {
    if (!modalEditAberto) {
      search(1);
    }

    addHandler(AppEventEnum.ProdutoModal, modalEdit)

    return () => removeHandler(AppEventEnum.ProdutoModal, modalEdit)
  }, [addHandler, modalEdit, modalEditAberto, queryStatus.page, removeHandler, search]);

  const total = getPlanoLimite(plano?.plano, EnumPlanoOpcoes.Usuarios);
  const consumed = queryStatus.list.filter(
    (user) => user.codigo === EnumPlanoOpcoes.Usuarios && user.quant > 0,
  );
  const qtdConsumed = consumed.reduce((acc, cur) => {
    const res = acc+ cur.quant
    return res
  }, 0)

  const percent = () => {

    return toDecimal((((qtdConsumed || 0 ) * 100) / total))
  };

  if(percent() < 80) return null

  return (
    <>
      {total >= 0 && (
        <Fade in={showCard} mountOnEnter unmountOnExit>
          <Grid className={classes.container}>
            {carregando ? <CircularLoading tipo="FULLSIZED" /> : null}
            <Grid className={classes.content}>
              <Grid container spacing={2} className={classes.containerInterno}>
                <Grid item className={classes.header}>
                  <Typography
                    style={{ fontSize: isMobile ? '0.8rem' : '1rem' }}
                    className={classes.textTitle}
                  >
                    Olá, fique atento aos limites do plano{' '}
                    <strong>{plano?.plano?.nome}</strong>!
                  </Typography>

                  <Button
                    color="secondary"
                    className={classes.buttonClose}
                    onClick={() => setShowCard(!showCard)}
                  >
                    <CloseIcon tipo="BUTTON_PRIMARY"></CloseIcon>
                  </Button>
                </Grid>

                <Grid item className={classes.contentSubtitle}>
                  <Grid className={classes.textInfoAndButton}>
                    {isMobile ? (
                      <Grid className={classes.chart}>
                        <Typography variant="caption" className={classes.text}>
                          Seu plano possui um limite de{' '}
                          <strong>{total} Usuário(s) </strong>
                          convidado(s).
                        </Typography>
                      </Grid>
                    ) : (
                      <Grid className={classes.chart}>
                        <Typography variant="caption" className={classes.text}>
                          Seu plano possui um limite de{' '}
                          <strong>{total} Usuário(s) </strong>
                          convidado(s). Para aumentar a capacidade faça um
                          UPGRADE agora mesmo!
                        </Typography>
                      </Grid>
                    )}
                    <Grid>
                        {!carregando && (
                          <ConsumptionChart
                            quantidade
                            total={total}
                            consumed={ 
                              qtdConsumed || 0
                            }
                          />
                        )}
                      </Grid>

                    <Grid xs={12}
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginTop: theme.spacing(1),
                      }}
                    >
                      <Button
                        onClick={handleViewPlans}
                        variant="contained"
                        fullWidth
                        size="small"
                        className={classes.button}
                      >
                        <UpgradeIcon tipo="BUTTON" />
                        FAZER UPGRADE
                      </Button>
                      
                    </Grid>
                    
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Fade>
      )}
    </>
  );
};
