import { forwardRef, useImperativeHandle } from 'react';
import { makeUtilClasses } from 'views/theme';
import { picker } from 'utils/picker';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import {
    DefaultFormProps,
    DefaultFormRefs,
} from 'views/components/form/utils/form-default-props';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Grid } from 'views/design-system';
import { MovRegistrarParcelasModel } from 'model/api/gestao/mov-pag/mov-registrar';
import { useFormVencimentoPagamentoValidation } from './form-vencimento-pagamento-validation';
import { toDecimal } from 'utils/to-decimal';

export interface FormParcelaVencimentoProps
    extends DefaultFormProps<MovRegistrarParcelasModel> {
    vMinParcela: number;
}

export const FormVencimentoPagamento = forwardRef<
    DefaultFormRefs<MovRegistrarParcelasModel>,
    FormParcelaVencimentoProps
>(({ ...props }: FormParcelaVencimentoProps, ref) => {
    const utilClasses = makeUtilClasses();

    const { FormVencimentoPagamentoYupValidation } = useFormVencimentoPagamentoValidation({ vMinParcela: props.vMinParcela })

    const {
        handleSubmit,
        control,
        formState: { errors, touchedFields },
        reset,
        setValue,
        getValues,
    } = useForm<MovRegistrarParcelasModel>({
        resolver: yupResolver(FormVencimentoPagamentoYupValidation),
        criteriaMode: 'all',
        mode: 'onSubmit',
    });

    const onSubmit = (values: MovRegistrarParcelasModel) => {
        const model = picker<MovRegistrarParcelasModel>(
            values,
            new MovRegistrarParcelasModel(),
        );

        props.onSubmit(model);
    };

    useImperativeHandle(ref, () => ({
        submitForm: async () => {
            setValue('valor', toDecimal(getValues('valor')));
            handleSubmit(onSubmit)();
        },
        resetForm: () => {
        },
        fillForm: (model: MovRegistrarParcelasModel) => {
            reset({ ...model });
        },
    }));

    return (
        <>
            <Box my={2}>
                <div className={utilClasses.formContainer}>
                    <form
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Controller
                                    name="valor"
                                    control={control}
                                    render={({ field }) => (
                                        <TextFieldSaurus
                                            autoComplete='new-password'
                                            fullWidth
                                            allowSubmit
                                            tipo='DECIMAL'
                                            manterMascara
                                            showStartAdornment
                                            label="Valor"
                                            variant="outlined"
                                            error={Boolean(
                                                errors.valor && errors.valor.message,
                                            )}
                                            helperText={
                                                touchedFields.valor || errors.valor
                                                    ? errors.valor?.message
                                                    : undefined
                                            }
                                            {...field}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    name="dataVencimento"
                                    control={control}
                                    render={({ field }) => (
                                        <TextFieldSaurus
                                            autoComplete='new-password'
                                            fullWidth
                                            tipo="DATA"
                                            allowSubmit
                                            manterMascara
                                            label="Data de Vencimento"
                                            variant="outlined"
                                            error={Boolean(
                                                errors.dataVencimento && errors.dataVencimento.message,
                                            )}
                                            helperText={
                                                touchedFields.dataVencimento || errors.dataVencimento
                                                    ? errors.dataVencimento?.message
                                                    : undefined
                                            }
                                            {...field}
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                        <Button style={{ display: 'none' }} type="submit"></Button>
                    </form>
                </div>
            </Box>
        </>
    );
});