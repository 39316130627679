import {
  Comissionado,
  useComissionadosStore
} from 'views/components/modals/comissao/comissao-modal/stores/comissionados-store';
import { useStyles } from './styles';
import { ButtonModalHeader } from 'views/components/controles/buttons/button-modal-header';
import { VoltarIcon } from 'views/components/icons';
import { useCallback, useEffect, useState } from 'react';
import { ModalHeader } from 'views/components/modals/components';
import { useCadastros, useToastSaurus } from 'services/app';
import { useModalStyles } from 'views/components/modals/utils/modal-styles';
import { PreviewGerentes } from './components/preview-gerentes';
import { ComissionadoValor } from '../comissionado-valor/comissionado-valor';
import { EnumTpPessoaComissionamento } from 'model/enums/enum-tp-pessoa-comissionamento';
import { useFiltrosModais } from 'services/app/hooks/filtros-modais';
import { PessoaModel } from 'model/api/gestao/pessoa';
import { newGuid } from 'utils/new-guid';
import { guidEmpty } from 'utils/guid-empty';
import { usePostComissaoComissionados } from 'data/api/gestao/comissao/post-comissionados';
import { useEmpresaAtual } from 'services/app/hooks/empresa-atual';
import { ComissaoModel } from 'model/api/gestao/comissao/comissao-model';
import { TipoValorComissao } from '../comissionado-valor/components/keyboard/components/keyboard-fragment-props';
import { CircularLoading } from 'views/components/utils';
import { useThemeQueries } from 'views/theme';
import { Typography } from 'views/design-system';

interface ComissionadoGerenteProps {
  comissaoId: string;
  getComissionados: () => Promise<void>;
  carregando: boolean;
  handleRemoverComissionado: (comissionados: Comissionado[], reload?: boolean) => Promise<boolean>
}

enum EnumPassoComissionadoGerente {
  ListaGerente,
  Valor
}

export const ComissionadoGerente = ({
  carregando: carregandoComissionado,
  comissaoId,
  getComissionados,
  handleRemoverComissionado,
  ...props
}: ComissionadoGerenteProps) => {
  const { comissionadoGerentes, gerenteAtual } = useComissionadosStore(
    (store) => store.state
  );
  const { showToast } = useToastSaurus();
  const { setGerenteAtual } = useComissionadosStore((store) => store);
  const { postComissaoComissionados, carregando: carregandoPost } =
    usePostComissaoComissionados();
  const { getEmpresaAtual } = useEmpresaAtual();
  const { theme } = useThemeQueries();

  const carregando = [carregandoComissionado, carregandoPost].includes(true);

  const [passo, setPasso] = useState<EnumPassoComissionadoGerente>(
    EnumPassoComissionadoGerente.ListaGerente
  );
  const [gerenteSelecionado, setGerenteSelecionado] =
    useState<Comissionado | null>(null);

  const { fecharComissaoComissionados } = useCadastros();
  const { abrirPessoaSelecionarModal, fecharPessoaSelecionarModal } =
    useFiltrosModais();

  const definirPasso = (passo: EnumPassoComissionadoGerente) => {
    setPasso(passo);
  };

  // HOOKS
  const classes = useStyles();
  const modalClasses = useModalStyles();

  const handleAdicionarGerente = useCallback(
    (pessoas: PessoaModel[]) => {
      const [pessoa] = pessoas;
      setGerenteAtual({
        pessoaId: pessoa.id,
        vendedores: [],
        internalId: newGuid(),
        condicao: 0,
        id: guidEmpty(),
        documento: pessoa.cpfcnpj,
        nome: pessoa.nome,
        tipo: EnumTpPessoaComissionamento.Gerente,
        valor: 0
      });
      definirPasso(EnumPassoComissionadoGerente.Valor);
      fecharPessoaSelecionarModal();
    },
    [fecharPessoaSelecionarModal, setGerenteAtual]
  );

  const handleEditarGerente = useCallback(
    async (pessoas: PessoaModel[], oldComissionado: Comissionado) => {
      try {
        fecharPessoaSelecionarModal();
        const [pessoa] = pessoas;
        const comissionados: ComissaoModel[] = [];
        if (oldComissionado.vendedores) {
          oldComissionado.vendedores.forEach((vend) => {
            comissionados.push({
              ...new ComissaoModel(),
              ComissaoCampanhaId: comissaoId,
              comissionadoId: pessoa.id,
              condicao: vend.condicao,
              id: newGuid(),
              valor: vend.valor,
              vendedorId: vend.pessoaId
            });
          });
        }

        await handleRemoverComissionado(oldComissionado.vendedores ?? [], false)
        const res = await postComissaoComissionados(
          getEmpresaAtual()?.id ?? '',
          comissaoId,
          comissionados
        );
        if (res.erro) throw res.erro;
        await getComissionados()
        setGerenteSelecionado({
          ...oldComissionado,
          nome: pessoa.nome,
          documento: pessoa.cpfcnpj,
          pessoaId: pessoa.id,
          vendedores: [
            ...(oldComissionado.vendedores ?? []).map(x => {
              const newVend = comissionados.find(y => y.vendedorId === x.pessoaId)
              return {
                ...x,
                id: newVend?.id ?? '',
              }
            })
          ]
        })
        showToast('success', 'Gerente editado com sucesso!');
        return true;
      } catch (err: any) {
        showToast('error', err.message);
        return false;
      }
    },
    [comissaoId, fecharPessoaSelecionarModal, getComissionados, getEmpresaAtual, handleRemoverComissionado, postComissaoComissionados, showToast]
  );

  const handleNovosComissionados = useCallback(
    async (pessoas: PessoaModel[]) => {
      try {
        fecharPessoaSelecionarModal();
        const gerente = useComissionadosStore.getState().state.gerenteAtual;

        const comissionados: ComissaoModel[] = [];

        const gerenteAtual = gerente || gerenteSelecionado;

        if (gerenteAtual) {
          pessoas.forEach((vendedor) => {
            comissionados.push({
              ...new ComissaoModel(),
              ComissaoCampanhaId: comissaoId,
              comissionadoId: gerenteAtual.pessoaId,
              condicao: gerenteAtual.condicao,
              id: guidEmpty(),
              valor: gerenteAtual.valor,
              vendedorId: vendedor.id
            });
          });
        }

        const res = await postComissaoComissionados(
          getEmpresaAtual()?.id ?? '',
          comissaoId,
          comissionados
        );
        if (res.erro) throw res.erro;

        await getComissionados();

        fecharComissaoComissionados();
        showToast('success', 'Novos Comissionados adicionado!');

        setGerenteAtual(undefined);
      } catch (err: any) {
        showToast('error', err.message);
      }
    },
    [
      fecharPessoaSelecionarModal,
      gerenteSelecionado,
      postComissaoComissionados,
      getEmpresaAtual,
      comissaoId,
      getComissionados,
      fecharComissaoComissionados,
      showToast,
      setGerenteAtual
    ]
  );

  const handleVoltarSelecao = useCallback(() => {
    fecharPessoaSelecionarModal();
  }, [fecharPessoaSelecionarModal]);

  const abrirSelecionarVendedores = useCallback(() => {
    abrirPessoaSelecionarModal({
      idsBloqueados: (
        gerenteSelecionado?.vendedores ??
        gerenteAtual?.vendedores ??
        []
      ).map((x) => x.pessoaId),
      adicionar: handleNovosComissionados,
      voltar: handleVoltarSelecao,
      typeSelect: 'select',
      label: (
        <div>
          <Typography weight={600} variant="h6" color="primary">
            <span style={{ color: theme.palette.text.secondary }}>
              Gerente:
            </span>{' '}
            {gerenteSelecionado
              ? gerenteSelecionado.nome
              : gerenteAtual?.nome ?? ''}
          </Typography>
          <Typography variant="h6" weight={600}  color="primary">
            Selecione os Vendedores
          </Typography>
        </div>
      )
    });
  }, [
    abrirPessoaSelecionarModal,
    gerenteAtual?.nome,
    gerenteAtual?.vendedores,
    gerenteSelecionado,
    handleNovosComissionados,
    handleVoltarSelecao,
    theme.palette.text.secondary
  ]);

  const abrirAdicionarGerente = useCallback(
    (comissionado?: Comissionado) => {
      abrirPessoaSelecionarModal({
        idsBloqueados: comissionado
          ? [comissionado.pessoaId]
          : useComissionadosStore
            .getState()
            .state.comissionadoGerentes.map((x) => x.pessoaId),
        adicionar: comissionado
          ? async (pessoas) => {
            const res = await handleEditarGerente(pessoas, comissionado);
            if (!res) {
              abrirAdicionarGerente(comissionado);
            }
          }
          : handleAdicionarGerente,
        typeSelect: 'radio',
        voltar: handleVoltarSelecao,
        label: 'Selecione o Gerente'
      });
    },
    [
      abrirPessoaSelecionarModal,
      handleAdicionarGerente,
      handleEditarGerente,
      handleVoltarSelecao
    ]
  );

  useEffect(() => {
    if (comissionadoGerentes.length === 0) {
      abrirAdicionarGerente();
    }
  }, [abrirAdicionarGerente, comissionadoGerentes.length]);

  const handleVoltarValor = () => {
    setGerenteAtual(undefined);
    setPasso(EnumPassoComissionadoGerente.ListaGerente);
  };

  const handleConfirmarValor = useCallback(
    (valor: number, tipoValor: TipoValorComissao) => {
      if (gerenteAtual) {
        setGerenteAtual({
          ...gerenteAtual,
          valor: valor,
          condicao: tipoValor
        });
      }
      abrirSelecionarVendedores();
    },
    [abrirSelecionarVendedores, gerenteAtual, setGerenteAtual]
  );

  return (
    <div className={modalClasses.root}>
      {carregando && <CircularLoading tipo="FULLSIZED" />}
      <ModalHeader
        title={gerenteSelecionado ? 'Editar Gerente' : 'Gerentes'}
        leftArea={
          <ButtonModalHeader
            tooltip="Voltar"
            icon={<VoltarIcon tipo="MODAL_HEADER" />}
            onClick={fecharComissaoComissionados}
          />
        }
      />
      <div className={classes.content}>
        {passo === EnumPassoComissionadoGerente.ListaGerente && (
          <PreviewGerentes
            setAddVendedor={abrirSelecionarVendedores}
            setAlterarGerente={abrirAdicionarGerente}
            setAddGerente={abrirAdicionarGerente}
            handleRemove={handleRemoverComissionado}
            gerenteSelecionado={gerenteSelecionado}
            setGerenteSelecionado={setGerenteSelecionado}
          />
        )}
        {passo === EnumPassoComissionadoGerente.Valor && (
          <ComissionadoValor
            handleVoltar={handleVoltarValor}
            handleConfirmar={handleConfirmarValor}
          />
        )}
      </div>
    </div>
  );
};
