import { FormRefNFeProps } from "./form-ref-nfe-props";
import { Controller } from "react-hook-form";
import { TextFieldSaurus } from "views/components/controles/inputs";
import { Grid } from "views/design-system";

export const FormRefNFe = ({ control, formState, setValue, ...props }: FormRefNFeProps) => {

    const { errors, touchedFields } = formState

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Controller
                    name="refNFe"
                    control={control}
                    render={({ field }) => (
                        <TextFieldSaurus
                            tipo={'NUMERO'}
                            disabled={props.loading}
                            fullWidth
                            autoComplete='new-password'
                            variant="outlined"
                            label="Chave de Acesso"
                            allowSubmit
                            placeholder="Ex: 12345678912345678912345678912345"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            error={Boolean(errors.refNFe && errors.refNFe?.message)}
                            helperText={
                                touchedFields.refNFe || errors.refNFe
                                    ? errors.refNFe?.message
                                    : undefined
                            }
                            {...field}
                        />
                    )}
                />
            </Grid>
        </Grid>
    );
}
