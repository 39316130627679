import { makeStyles } from "@material-ui/core"

export const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        margin: 0,
        '& form': {
            width: '100%',
        }
    },
    containerValores: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '100%',
        marginBottom: theme.spacing(2),
    },
    text: {
        fontSize: '16px',
        lineHeight: '18px',
        textAlign: 'right',
        margin: theme.spacing(1),
    },
    title: {
        lineHeight: '18px',
        fontWeight: 600,
        textAlign: 'right',
        margin: theme.spacing(1),
    },
    containerButton: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    buttonChanged: {
        width: '55px',
        height: '55px',
        padding: '0!important',
        borderRadius: '50%',
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    prefix: {
        fontSize: '38px',
        fontWeight: 600,
    },
    textPerson: {
        fontSize: '40px',
        lineHeight: '32px',
        fontWeight: 700
    },
    textWarning: {
        color: theme.palette.warning.main
    },
    iconAviso: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        '& svg': {
            fill: theme.palette.warning.main,
            width: 100
        }
    },
    formEntrega: {
        width: "100%",
        border: `dashed 1px ${theme.palette.text.secondary}`,
        borderRadius: theme.spacing(1)
    }
}));