import { AppEventEnum } from 'model/enums/enum-app-event';
import { useCallback, useEffect, useState } from 'react';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { consoleDev } from 'utils/console-dev';
import { DialogPagamentoRealizado, DialogPagamentoRealizadoProps } from 'views/components/dialog/dialog-pagamento-realizado/dialog-pagamento-realizado';

export const DialogPagamentoRealizadoRender = () => {
  const { addHandler, removeHandler } = useEventTools();
  const [modalState, setModalState] = useState<DialogPagamentoRealizadoProps>({
    aberto: false
  }
  );

  consoleDev('DialogPagamentoRealizadoRender');

  const modalAlterado = useCallback(({ aberto }: any) => {
    setModalState({
      aberto,
    });
  }, []);

  useEffect(() => {
    addHandler(AppEventEnum.PagamentoRealizado, modalAlterado);
    return () => {
      removeHandler(AppEventEnum.PagamentoRealizado, modalAlterado);
    };
  }, [addHandler, modalAlterado, removeHandler]);

  return (
    <>
      {modalState.aberto && (
        <DialogPagamentoRealizado
          {...modalState}
        />
      )}
    </>
  );
};
