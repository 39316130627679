import { useMemo, useEffect } from 'react';
import { Grid, Typography, Divider, Box } from 'views/design-system';
import { useStyles } from './mov-comprovante-detalhe-page-styles';
import { useThemeQueries } from 'views';
import { formatarCPFCNPJ } from 'utils/cpfcnpj-format';
import { toCurrency, toDecimalString } from 'utils/to-decimal';
import { useMovAnteriorStorage } from 'services/app/hooks/mov-anterior-storage';
import { MovSimplesProdutoModel } from 'model/api/gestao/movimentacao/simples/mov-simples-produto-model';
import { useMovRota } from 'services/app/hooks/mov-rota';
import { useToastSaurus } from 'services/app';
import { useVendaHeaderStore } from 'views/components/headers/venda-header/stores/venda-header-store';
import { useDescontoAcrescimo } from 'services/app/hooks/desconto-acrescimo';
import { MovSimplesModel } from 'model/api/gestao/movimentacao/simples/mov-simples-model';
import { EnumTpProduto } from 'model/enums/enum-tp-produto';
import { CardDetalhesVendaProduto } from 'views/components/cards/card-detalhes-da-venda-produto';
import { tpPagamentoResolver } from 'utils/tp-pagamento-resolver';

export const MovComprovanteDetalhePage = () => {
  const classes = useStyles();
  const { isMobile } = useThemeQueries();
  const { get } = useMovAnteriorStorage();
  const { redirectVendaFinalizadaComprovante } = useMovRota();
  const detalhes = get();
  const { showToast } = useToastSaurus()
  const {
    retornaDescontoDosProdutos,
    retornaDescontoNaVenda,
    retornaDescontoDasPromocoes,
    retornaValorTotalDaVendaComDescontosEacrescimos
  } = useDescontoAcrescimo()
  let pagamentosUnicos = new Map(
    detalhes?.pags.map((obj) => [obj.modPagamento, obj]),
  );

  const setHeaderProps = useVendaHeaderStore(state => state.setHeaderProps);

  useEffect(() => {
    setHeaderProps({
      title: 'Detalhes da Venda',
      voltar: {
        onClick: async () => {
          try {
            await redirectVendaFinalizadaComprovante();
          } catch (e: any) {
            showToast('error', 'Erro ao redirecionar para o comprovante');
          }
        }
      }
    })
  }, [redirectVendaFinalizadaComprovante, setHeaderProps, showToast])

  const pagamentosUnicosList = Array.from(pagamentosUnicos.values());

  const pagamentosSerelizados = pagamentosUnicosList.map((pagamento) => {
    return {
      ...pagamento,
      vPag: 0,
    };
  });

  pagamentosSerelizados.map((pagamento) => {
    detalhes?.pags.map((pag) => {
      if (pag.modPagamento === pagamento.modPagamento) {
        let novaQuantidade = (pagamento.vPag += pag.vPag);
        return {
          ...pagamento,
          vPag: novaQuantidade,
        };
      }
      return pag;
    });
    return pagamento;
  });

  const produtosAtivos = detalhes?.produtos.filter(
    (prod) => prod.ativo === true,
  );
  interface InfoProdInterface {
    produto: MovSimplesProdutoModel;
    qtdPai: number
  }

  const InfoProduto = ({ produto, qtdPai }: InfoProdInterface) => {
    if (produto.infoSubItem?.qPadrao === 0 && produto.qCom < 1) {
      return null;
    }

    return (
      <>
        {produto.idAdicional && produto.indFin ?
          <>
            <div style={{ marginLeft: '10px' }}>
              <p style={{ display: 'flex', justifyContent: 'space-between' }}>
                {produto.qCom <= 1 ? produto.qCom : (produto.qCom / qtdPai)}x {produto.xProd} <strong style={{ textAlign: 'end', fontWeight: 500 }}>{toCurrency(produto.vProd)}</strong>
              </p>
              {produto.prodSubItem.length > 0 &&
                produto.prodSubItem.map((p, index) => (
                  <InfoProduto
                    key={index}
                    produto={{ ...p, qCom: produto.qCom === 0 ? 0 : p.qCom }}
                    qtdPai={qtdPai}
                  />
                ))}
            </div>
          </>
          :
          <div style={{ marginLeft: '10px', }}>
            {produto.qCom}x {produto.xProd}
            {produto.prodSubItem.length > 0 &&
              produto.prodSubItem.filter(prod => !prod.idAdicional).map((p, index) => (
                <InfoProduto
                  key={index}
                  produto={{ ...p, qCom: produto.qCom === 0 ? 0 : p.qCom }}
                  qtdPai={qtdPai}
                />
              ))}
          </div>
        }
      </>
    );
  };

  const produtosPai = produtosAtivos?.filter(item => ![EnumTpProduto.Insumo, EnumTpProduto.Opcional].includes(item.tpProduto) && item.indFin)

  const descontoNosProdutos = useMemo(() => {
    return retornaDescontoDosProdutos(produtosAtivos ?? new Array<MovSimplesProdutoModel>())
  }, [produtosAtivos, retornaDescontoDosProdutos])
  const descontodePromocoes = useMemo(() => {
    return retornaDescontoDasPromocoes(produtosAtivos ?? new Array<MovSimplesProdutoModel>())
  }, [produtosAtivos, retornaDescontoDasPromocoes])
  const descontoNaVenda = useMemo(() => {
    return retornaDescontoNaVenda(produtosAtivos ?? new Array<MovSimplesProdutoModel>(), detalhes ?? new MovSimplesModel())
  }, [detalhes, produtosAtivos, retornaDescontoNaVenda])
  const valorTotal = useMemo(() => {
    return retornaValorTotalDaVendaComDescontosEacrescimos(detalhes ?? new MovSimplesModel())
  }, [detalhes, retornaValorTotalDaVendaComDescontosEacrescimos])
  return (
    <>
      <Box
        className={`${classes.container} ${isMobile ? '' : classes.containerBackgroundColor
          }`}
      >

        <Grid container className={classes.containerInfo}>
          <Grid container className={classes.containerInfoWidth}>
            <Grid container className={isMobile ? '' : classes.containerInfoGeralPC}>
              <Typography
                variant='subtitle1'
                color='primary'
                className={classes.label}>Informações Gerais</Typography>
              <Divider
                className={classes.divider}
                style={{ marginBottom: 16 }}
              />
              <Grid container className={classes.containerInfoPreviewBlue}>

                <Grid container className={classes.gridTopicos}>
                  <Typography variant={'caption'} >
                    Dados do cliente
                  </Typography>
                  <Grid container justifyContent='space-between' xs={12} >
                    <Typography
                      variant='subtitle1'
                      color='primary'
                    >
                      CPF/CNPJ
                    </Typography>
                    <Typography
                      variant='subtitle1'
                      color='primary'
                      className={classes.label}
                    >
                      {formatarCPFCNPJ(detalhes?.documento || '') ??
                        'Não informado'}
                    </Typography>
                  </Grid>

                  <Grid container justifyContent='space-between' xs={12}>
                    <Typography
                      variant='subtitle1'
                      color='primary'
                    >
                      Telefone
                    </Typography>
                    <Typography
                      variant='subtitle1'
                      color='primary'
                      className={classes.label}>
                      {'Não informado'}
                    </Typography>
                  </Grid>

                  <Grid container justifyContent='space-between' xs={12}>
                    <Typography
                      variant='subtitle1'
                      color='primary'
                    >
                      Nome
                    </Typography>
                    <Typography

                      variant='subtitle1'
                      color='primary' className={classes.label}>
                      {detalhes?.cliente?.nome ?? 'CONSUMIDOR'}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container className={classes.gridTopicos}>
                  <Typography variant={'caption'} >
                    Pagamentos
                  </Typography>
                  {pagamentosSerelizados.map((pag) => (
                    <Grid container justifyContent='space-between' xs={12}>
                      <Typography
                        variant='subtitle1'
                        color='primary'
                      >
                        {
                          tpPagamentoResolver(pag.modPagamento, pag.tpTransacao)?.Value
                        } {pag.nParcelas > 1 && `(${pag.nParcelas}x)`}
                      </Typography>
                      <Typography
                        variant='subtitle1'
                        color='primary'
                        className={classes.label}>
                        R$ {toDecimalString(pag.vPag, 2)}
                      </Typography>
                    </Grid>
                  ))}
                </Grid>
                {((detalhes?.vDesc ?? 0) || (detalhes?.vDescEmbutido ?? 0)) > 0 &&
                  <Grid container className={classes.gridTopicos}>
                    <Typography variant={'caption'} >
                      Descontos
                    </Typography>
                    {descontoNosProdutos > 0 &&
                      <Grid container justifyContent='space-between' xs={12}>
                        <Typography
                          variant='subtitle1'
                          color='primary'
                        >
                          Descontos nos produtos
                        </Typography>
                        <Typography
                          variant='subtitle1'
                          color='primary'
                          className={classes.label}>
                          - R$ {toDecimalString(descontoNosProdutos, 2)}
                        </Typography>
                      </Grid>
                    }
                    {descontodePromocoes > 0 &&
                      <Grid container justifyContent='space-between' xs={12}>
                        <Typography
                          variant='subtitle1'
                          color='primary'
                        >
                          Descontos de promoções
                        </Typography>
                        <Typography
                          variant='subtitle1'
                          color='primary'
                          className={classes.label}>
                          - R$ {toDecimalString(descontodePromocoes, 2)}
                        </Typography>
                      </Grid>
                    }
                    {(descontoNaVenda - descontodePromocoes) > 0 &&
                      <Grid container justifyContent='space-between' xs={12}>
                        <Typography
                          variant='subtitle1'
                          color='primary'
                        >
                          Desconto na venda
                        </Typography>
                        <Typography
                          variant='subtitle1'
                          color='primary'
                          className={classes.label}>
                          - R$ {toDecimalString(descontoNaVenda, 2)}
                        </Typography>
                      </Grid>
                    }
                    <Grid container justifyContent='space-between' xs={12}>
                      <Typography
                        variant='subtitle1'
                        color='primary'
                      >
                        Totais de descontos
                      </Typography>
                      <Typography
                        variant='subtitle1'
                        color='primary'
                        className={classes.label}>
                        - R$ {toDecimalString((detalhes?.vDesc ?? 0) + (detalhes?.vDescEmbutido ?? 0), 2)}
                      </Typography>
                    </Grid>
                  </Grid>
                }
                {((detalhes?.vOutro ?? 0) || (detalhes?.vOutroEmbutido ?? 0)) > 0 &&
                  <Grid item justifyContent='space-between' xs={12}>
                    <Typography variant='caption'>
                      Acrescimos
                    </Typography>
                    {(detalhes?.vOutroEmbutido ?? 0) > 0 &&
                      <Grid container justifyContent='space-between' xs={12}>
                        <Typography
                          variant='subtitle1'
                          color='primary'
                        >
                          Acrescimos nos produtos
                        </Typography>
                        <Typography
                          variant='subtitle1'
                          color='primary'
                          className={classes.label}>
                          R$ {toDecimalString((detalhes?.vOutroEmbutido ?? 0), 2)}
                        </Typography>
                      </Grid>
                    }
                    {(detalhes?.vOutro ?? 0) > 0 &&
                      <Grid container justifyContent='space-between' xs={12}>
                        <Typography
                          variant='subtitle1'
                          color='primary'
                        >
                          Acrescimos na venda
                        </Typography>
                        <Typography
                          variant='subtitle1'
                          color='primary'
                          className={classes.label}>
                          R$ {toDecimalString((detalhes?.vOutro ?? 0), 2)}
                        </Typography>
                      </Grid>
                    }
                    <Grid container justifyContent='space-between' xs={12}>
                      <Typography
                        variant='subtitle1'
                        color='primary'
                      >
                        Total de Acrescimos
                      </Typography>
                      <Typography
                        variant='subtitle1'
                        color='primary'
                        className={classes.label}>
                        R$ {toDecimalString((detalhes?.vOutro ?? 0) + (detalhes?.vOutroEmbutido ?? 0), 2)}
                      </Typography>
                    </Grid>
                  </Grid>
                }
                <Grid xs={12} item justifyContent='space-between' className={classes.gridTopicos}>
                  <Typography variant={'caption'} >
                    Totais
                  </Typography>
                  <Grid container justifyContent='space-between' xs={12}>
                    <Typography
                      variant='subtitle1'
                      color='primary'
                    >
                      Valor Total
                    </Typography>
                    <Typography
                      variant='subtitle1'
                      color='primary'
                      className={classes.labelTotais}>
                      R$ {toDecimalString(valorTotal, 2)}
                    </Typography>
                  </Grid>
                  <Grid container justifyContent='space-between' xs={12}>
                    <Typography
                      variant='subtitle1'
                      color='primary'
                    >
                      Valor Pago
                    </Typography>
                    <Typography
                      variant='subtitle1'
                      color='primary'
                      className={classes.labelTotais}>
                      R$ {toDecimalString(detalhes?.vNF, 2)}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Typography
                variant='subtitle1'
                color='primary'
                className={classes.label}>Produtos</Typography>
              <Divider
                className={classes.divider}
                style={{ marginBottom: 16 }}
              />
              {produtosPai?.map((produto, index) => {
                return (<CardDetalhesVendaProduto produto={produto} index={index} />)
              })}
            </Grid>
          </Grid>
        </Grid>
      </Box >
    </>
  );
};
