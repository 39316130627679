import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { makeUtilClasses } from '../../../../theme';
import { picker } from '../../../../../utils/picker';
import { CircularLoading } from '../../../utils/circular-loading/circular-loading';
import { TextFieldSaurus } from '../../../controles/inputs';
import {
  DefaultFormProps,
  DefaultFormRefs,
} from '../../utils/form-default-props';
import { useFormModificadorEditValidation } from './form-modificador-edit-validation';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { SelectSaurus } from 'views/components/controles/selects/select-saurus/select-saurus';
import { ModificadorEditFormModel } from 'model/app/forms/modificador/modificador-edit-form-model';
import { TpCalculoModificadorMock } from 'data/mocks/tp-calculo-modificador-mock';
import { useStyles } from './form-modificador-edit-styles'
import { Box, Button, Grid } from 'views/design-system';

export const FormModificadorEdit = forwardRef<
  DefaultFormRefs<ModificadorEditFormModel>,
  DefaultFormProps<ModificadorEditFormModel>
>(({ ...props }: DefaultFormProps<ModificadorEditFormModel>, ref) => {
  const utilClasses = makeUtilClasses();
  const formClasses = useStyles()
  const refInputNumSalao = useRef<HTMLInputElement>(null);
  const [modelForm, setModelForm] = useState<ModificadorEditFormModel>(
    new ModificadorEditFormModel(),
  );
  const { FormModificadorEditYupValidation } = useFormModificadorEditValidation();

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    setValue,
    getValues,
  } = useForm<ModificadorEditFormModel>({
    defaultValues: { ...modelForm },
    resolver: yupResolver(FormModificadorEditYupValidation),
    criteriaMode: 'all',
    mode: 'onChange',
  });

  const onSubmit = (values: ModificadorEditFormModel) => {
    const modificador = picker<ModificadorEditFormModel>(
      values,
      new ModificadorEditFormModel(),
    );
    props.onSubmit({ ...modificador }, modelForm);
  };

  useImperativeHandle(ref, () => ({
    submitForm: async () => {
      await handleSubmit(onSubmit)();
    },
    resetForm: () => {
      reset({ ...modelForm });
      refInputNumSalao.current?.focus();
    },
    fillForm: (model: ModificadorEditFormModel) => {
      setModelForm(model);
      reset({ ...model });
      setTimeout(() => {
        refInputNumSalao.current?.focus();
      }, 500);
    },
  }));

  return (
    <>
      <Box my={2} className={formClasses.boxForm}>
        <div className={utilClasses.formContainer}>
          {props.loading && props.showLoading ? (
            <div className={utilClasses.controlLoading}>
              <CircularLoading tipo="NORMAL" />
            </div>
          ) : null}
          <form
            onSubmit={handleSubmit(onSubmit)}
            className={props.loading ? utilClasses.controlLoading : ''}
          >
            <Grid container spacing={2}>
              <Grid item xs={7}>
                <Controller
                  name="nome"
                  control={control}
                  render={({ field }) => (
                    <TextFieldSaurus
                      tipo={'TEXTO'}
                      disabled={props.loading}
                      fullWidth
                      variant="outlined"
                      label="Nome"
                      allowSubmit
                      InputLabelProps={{
                        shrink: true,
                      }}
                      placeholder="Ex: Tamanho do Açaí"
                      error={Boolean(
                        errors.nome && errors.nome.message,
                      )}
                      helperText={
                        errors.nome
                          ? errors.nome?.message
                          : undefined
                      }
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={5}>
                <Controller
                  name="tpCalculo"
                  control={control}
                  render={({ field }) => (
                    <SelectSaurus
                      label="Tipo de Cálculo"
                      variant="outlined"
                      fullWidth
                      disabled={props.loading}
                      conteudo={TpCalculoModificadorMock}
                      error={Boolean(errors.tpCalculo && errors.tpCalculo.message)}
                      helperText={
                        errors.tpCalculo
                          ? errors.tpCalculo?.message
                          : undefined
                      }
                      {...field}
                      onChange={(event) => {
                        const item = TpCalculoModificadorMock.filter(
                          (item) => item.Key === event.target.value,
                        )[0]?.Key;

                        setValue('tpCalculo', item);
                      }}
                      value={getValues('tpCalculo')}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="descricao"
                  control={control}
                  render={({ field }) => (
                    <TextFieldSaurus
                      tipo={'TEXTO'}
                      disabled={props.loading}
                      fullWidth
                      variant="outlined"
                      label="Descrição"
                      limite={150}
                      allowSubmit
                      placeholder="Digite uma instrução para facilitar o entendimento deste modificador"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      multiline
                      rows={2}
                      error={Boolean(
                        errors.descricao && errors.descricao.message,
                      )}
                      helperText={
                        errors.descricao
                          ? errors.descricao?.message
                          : undefined
                      }
                      {...field}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Button style={{ display: 'none' }} type="submit"></Button>
          </form>
        </div>
      </Box>
    </>
  );
});
