import { Button, Divider, Grid, Typography } from "views/design-system"
import { useThemeQueries } from "views/theme"

export interface IRangeHorario {
    inicio: string
    fim: string
}

export type HorarioOptionsMaskedType = string | {
    inicio?: string
    fim?: string
}

interface IProps {
    type?: 'time' | 'time-range'
    value?: IRangeHorario | string
    manterMascara?: boolean
    onChange?: (value: IRangeHorario | string, maskedValue?: HorarioOptionsMaskedType) => void
    onConfirm?: () => void
}

export const HorarioOptions = (props: IProps) => {
    const { theme } = useThemeQueries()
    return (
        <div style={{
            height: "405px",
            width: "324px",
            borderRadius: `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0px 0px`,
            border: `2px solid ${theme.palette.primary.main}`,
            background: "#ffffff",
            padding: "10px",
            userSelect: "none",
            display: 'flex',
            justifyContent: 'center',
            paddingBottom: '30px'
        }}>
            <Grid container spacing={2}>
                {/* hOUR COLUMNS */}
                {
                    props.type === 'time-range' && (
                        <>
                            <Grid item xs={6}>
                                <Grid container spacing={1} style={{
                                    height: "300px",
                                    overflowY: 'auto',
                                    position: 'relative'
                                }}>
                                    <Grid item xs={12} style={{
                                        color: theme.palette.primary.main,
                                        textAlign: 'center',
                                        background: theme.palette.background.paper,
                                        position: 'sticky',
                                        top: 0,
                                        zIndex: 1000,
                                    }}>
                                        <Typography variant="caption"  >
                                            <strong>
                                                Hora Início
                                            </strong>
                                        </Typography>
                                    </Grid>
                                    {
                                        Array.from({ length: 96 }, (_, i) => {
                                            const hour = Math.floor(i / 4);
                                            const minute = (i % 4) * 15;
                                            const formattedHour = hour < 10 ? `0${hour}` : `${hour}`;
                                            const formattedMinute = minute === 0 ? '00' : `${minute}`;
                                            return (
                                                <Grid item xs={12} key={`${formattedHour}:${formattedMinute}`}>
                                                    <Button
                                                        onClick={() => {
                                                            if (props.onChange) {
                                                                let maskedValue: HorarioOptionsMaskedType | undefined = undefined
                                                                if (props.manterMascara) {
                                                                    const fimMasked = (props.value as IRangeHorario)?.fim ? (props.value as IRangeHorario)?.fim.replace(/\D/g, '') : undefined
                                                                    const inicioMasked = `${formattedHour}${formattedMinute}`.replace(/\D/g, '')
                                                                    maskedValue = {
                                                                        inicio: inicioMasked,
                                                                        fim: fimMasked
                                                                    }
                                                                }
                                                                props.onChange({
                                                                    inicio: `${formattedHour}:${formattedMinute}`,
                                                                    fim: (props.value as IRangeHorario)?.fim
                                                                }, maskedValue)
                                                            }

                                                        }}
                                                        style={{
                                                            width: '100%',
                                                            height: '100%',
                                                            borderRadius: theme.shape.borderRadius,
                                                            background: (props.value as IRangeHorario)?.inicio === `${formattedHour}:${formattedMinute}` ? theme.palette.primary.main : (i % 2 === 0 ? theme.palette.background.paper : theme.palette.background.default),
                                                            color: (props.value as IRangeHorario)?.inicio === `${formattedHour}:${formattedMinute}` ? theme.palette.common.white : theme.palette.text.primary,
                                                        }}
                                                    >
                                                        {`${formattedHour}:${formattedMinute}`}
                                                    </Button>
                                                </Grid>
                                            )
                                        })
                                    }
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container spacing={1} style={{
                                    height: "300px",
                                    overflowY: 'auto',
                                    position: 'relative'
                                }}>
                                    <Grid item xs={12} style={{
                                        color: theme.palette.primary.main,
                                        textAlign: 'center',
                                        background: theme.palette.background.paper,
                                        position: 'sticky',
                                        top: 0,
                                        zIndex: 1000,
                                    }}>
                                        <Typography variant="caption"  >
                                            <strong>
                                                Hora Fim
                                            </strong>
                                        </Typography>

                                    </Grid>
                                    {
                                        Array.from({ length: 96 }, (_, i) => {
                                            const hour = Math.floor(i / 4);
                                            const minute = (i % 4) * 15;
                                            const formattedHour = hour < 10 ? `0${hour}` : `${hour}`;
                                            const formattedMinute = minute === 0 ? '00' : `${minute}`;
                                            return (
                                                <Grid item xs={12} key={`${formattedHour}:${formattedMinute}`}>
                                                    <Button
                                                        onClick={() => {
                                                            if (props.onChange) {
                                                                let maskedValue: HorarioOptionsMaskedType | undefined = undefined
                                                                if (props.manterMascara) {
                                                                    const inicioMasked = (props.value as IRangeHorario)?.inicio ? (props.value as IRangeHorario)?.inicio.replace(/\D/g, '') : undefined
                                                                    // Convert new value
                                                                    const fimMasked = `${formattedHour}:${formattedMinute}`.replace(/\D/g, '')
                                                                    maskedValue = {
                                                                        inicio: inicioMasked,
                                                                        fim: fimMasked
                                                                    }
                                                                }
                                                                props.onChange({
                                                                    inicio: (props.value as IRangeHorario)?.inicio,
                                                                    fim: `${formattedHour}:${formattedMinute}`
                                                                }, maskedValue)
                                                            }
                                                        }}
                                                        style={{
                                                            // Change style if selected
                                                            width: '100%',
                                                            height: '100%',
                                                            borderRadius: theme.shape.borderRadius,
                                                            background: (props.value as IRangeHorario)?.fim === `${formattedHour}:${formattedMinute}` ? theme.palette.primary.main : (i % 2 === 0 ? theme.palette.background.paper : theme.palette.background.default),
                                                            color: (props.value as IRangeHorario)?.fim === `${formattedHour}:${formattedMinute}` ? theme.palette.common.white : theme.palette.text.primary,
                                                        }}
                                                    >
                                                        {`${formattedHour}:${formattedMinute}`}
                                                    </Button>
                                                </Grid>
                                            )
                                        })
                                    }
                                </Grid>
                            </Grid>
                        </>
                    )
                }
                {
                    props.type === 'time' && (
                        <Grid item xs={12}>
                            <Grid container spacing={1} style={{
                                height: "300px",
                                overflowY: 'auto',
                                position: 'relative'
                            }}>
                                {
                                    Array.from({ length: 96 }, (_, i) => {
                                        const hour = Math.floor(i / 4);
                                        const minute = (i % 4) * 15;
                                        const formattedHour = hour < 10 ? `0${hour}` : `${hour}`;
                                        const formattedMinute = minute === 0 ? '00' : `${minute}`;
                                        return (
                                            <Grid item xs={12} key={`${formattedHour}:${formattedMinute}`}>
                                                <Button
                                                    onClick={() => {
                                                        if (props.onChange) {
                                                            let maskedValue: HorarioOptionsMaskedType | undefined = undefined
                                                            if (props.manterMascara) {
                                                                maskedValue = `${formattedHour}:${formattedMinute}`.replace(/\D/g, '')
                                                            }
                                                            props.onChange(`${formattedHour}:${formattedMinute}`, maskedValue)
                                                        }
                                                    }}
                                                    style={{
                                                        width: '100%',
                                                        height: '100%',
                                                        borderRadius: theme.shape.borderRadius,
                                                        background: (props.value as string) === `${formattedHour}:${formattedMinute}` ? theme.palette.primary.main : (i % 2 === 0 ? theme.palette.background.paper : theme.palette.background.default),
                                                        color: (props.value as string) === `${formattedHour}:${formattedMinute}` ? theme.palette.common.white : theme.palette.text.primary,
                                                    }}
                                                >
                                                    {`${formattedHour}:${formattedMinute}`}
                                                </Button>
                                            </Grid>
                                        )
                                    })
                                }
                            </Grid>
                        </Grid>
                    )
                }
                <Grid item xs={12}>
                    <Divider style={{
                        background: theme.palette.primary.main
                    }} />
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Button
                                fullWidth
                                onClick={() => {
                                    if (props.onChange) {
                                        props.onChange('')
                                    }
                                }}
                                variant="outlined"

                            >
                                Limpar
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button
                                fullWidth
                                onClick={() => {
                                    if (props.onConfirm) {
                                        props.onConfirm()
                                        if(props.onChange){
                                            props.onChange(props.value as IRangeHorario)
                                        }
                                    }
                                }}
                                variant="contained"
                                color="primary"
                            >
                                Confirmar
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

        </div>
    )
}