import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
  Fragment
} from 'react';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import { makeUtilClasses, useThemeQueries } from 'views';

import {
  DefaultFormRefs
} from 'views/components/form/utils/form-default-props';
import { Controller, useForm } from 'react-hook-form';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { SelectSaurus } from 'views/components/controles/selects/select-saurus/select-saurus';
import { UFMock } from 'data/mocks';
import { PessoaDocumentoModel } from 'model/api/gestao/pessoa/pessoa-documento-model';
import { TpDocumentoMock } from 'data/mocks/tp-documento-mock';
import { toDateString, toDateStringApi } from 'utils/to-date';
import { isEmpty } from 'lodash';
import { EnumTipoDocumento } from 'model/enums/enum-tp-documento';
import { TipoDocumentoOrgaoExpedidorMock } from 'data/mocks/orgao-expedidor-mock';
import { useFormPessoaDocumentoValidation } from './form-pessoa-documento-validation';
import { yupResolver } from '@hookform/resolvers/yup';
import { TipoDocumentoOrgaoExpedidorEnum } from 'model/enums/enum-orgao-expedidor';
import { useEmpresaAtual } from 'services/app/hooks/empresa-atual';
import { EnumCadastroTipo } from 'model';
import { Button, Grid } from 'views/design-system';

export interface PessoaDocumentoFormModelProps {
  tipoPessoa: EnumCadastroTipo | undefined;
  loading: boolean;
  showLoading: boolean;
  model?: PessoaDocumentoModel;
  onSubmit(
    model: PessoaDocumentoModel,
    beforeModel?: PessoaDocumentoModel | null,
    permissoes?: PessoaDocumentoModel | null
  ): void;
}

const documentosPessoais = [
  EnumTipoDocumento.CarteiraIdentidade,
  EnumTipoDocumento.CarteiraTrabalhoPrevidenciaSocial,
  EnumTipoDocumento.Passaporte,
  EnumTipoDocumento.CarteiraRegistroProfissional
];
export const FormPessoaDocumento = forwardRef<
  DefaultFormRefs<PessoaDocumentoModel>,
  PessoaDocumentoFormModelProps
>((props: PessoaDocumentoFormModelProps, ref) => {
  const utilClasses = makeUtilClasses();
  const [validarUf, setValidarUF] = useState<boolean>(true);

  const { FormPessoaDocumentoValidationYup } =
    useFormPessoaDocumentoValidation(validarUf);

  const { isMobile } = useThemeQueries();
  const { getEmpresaAtual } = useEmpresaAtual();

  //REFS
  const refInputCnpjCpf = useRef<HTMLInputElement>(null);

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    getValues,
    formState: { errors }
  } = useForm<PessoaDocumentoModel>({
    defaultValues: props.model || new PessoaDocumentoModel(),
    criteriaMode: 'all',
    mode: 'onChange',
    resolver: yupResolver(FormPessoaDocumentoValidationYup)
  });

  const onSubmit = (values: PessoaDocumentoModel) => {
    if (!isEmpty(values.dataExpedicao))
      values.dataExpedicao = toDateStringApi(new Date(values.dataExpedicao));
    if (!isEmpty(values.dataValidade))
      values.dataValidade = toDateStringApi(new Date(values.dataValidade));
    if (!documentosPessoais.includes(getValues('tipo'))) {
      values.dataExpedicao = '';
      values.dataValidade = '';
      values.ufExpedicao = '';
    }
    props.onSubmit(values);
  };

  useImperativeHandle(ref, () => ({
    submitForm: async () => {
      onSubmit(getValues());
    },
    resetForm: () => {
      reset();
      if (!isMobile) refInputCnpjCpf.current?.focus();
    },
    fillForm: (model: PessoaDocumentoModel) => {
      if (!documentosPessoais.includes(model.tipo)) {
        setValidarUF(false);
      }
      reset({
        ...model,
        orgaoExpedicao:
          model.orgaoExpedicao ||
          (props.tipoPessoa === EnumCadastroTipo.PRESCRITOR
            ? TipoDocumentoOrgaoExpedidorEnum.ConselhoRegionalMedicina
            : props.tipoPessoa === EnumCadastroTipo.RESPONSAVEL_TECNICO
            ? TipoDocumentoOrgaoExpedidorEnum.ConselhoRegionalFarmacia
            : TipoDocumentoOrgaoExpedidorEnum.SecretariaEstadoSeguranca),
        ufExpedicao: model.ufExpedicao || getEmpresaAtual()?.uf || '',
        dataExpedicao: !model.dataExpedicao
          ? ''
          : toDateString(new Date(model.dataExpedicao), 'yyyy-MM-DD'),
        dataValidade: !model.dataValidade
          ? ''
          : toDateString(new Date(model.dataValidade), 'yyyy-MM-DD')
      });
      setTimeout(() => {
        if (!isMobile) refInputCnpjCpf.current?.focus();
      }, 500);
    }
  }));

  useEffect(() => {
    if (props.model) {
      reset({ ...props.model });
    }
  }, [props.model, reset]);

  return (
    <>
      <div className={utilClasses.formContainer}>
        {props.loading && props.showLoading ? (
          <div className={utilClasses.controlLoading}>
            <CircularLoading tipo={'NORMAL'} />
          </div>
        ) : null}

        <form
          onSubmit={handleSubmit(onSubmit)}
          className={
            props.loading && props.showLoading ? utilClasses.controlLoading : ''
          }
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                name="tipo"
                control={control}
                render={({ field }) => (
                  <SelectSaurus
                    disabled={props.loading}
                    conteudo={TpDocumentoMock}
                    fullWidth
                    autoComplete={'off'}
                    label="Tipo de Documento"
                    placeholder=""
                    error={Boolean(errors.tipo && errors.tipo.message)}
                    helperText={errors.tipo ? errors.tipo?.message : undefined}
                    {...field}
                    onChange={(e) => {
                      setValue('tipo', Number(e.target.value));
                      setValidarUF(
                        documentosPessoais.includes(Number(e.target.value))
                      );
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={documentosPessoais.includes(getValues('tipo')) ? 5 : 12 }>
              <Controller
                name="documento"
                control={control}
                render={({ field }) => (
                  <TextFieldSaurus
                    tipo={'TEXTO'}
                    disabled={props.loading}
                    fullWidth
                    autoComplete={'off'}
                    label="Número do Documento"
                    error={Boolean(
                      errors.documento && errors.documento.message
                    )}
                    helperText={
                      errors.documento ? errors.documento?.message : undefined
                    }
                    {...field}
                  />
                )}
              />
            </Grid>
            {documentosPessoais.includes(getValues('tipo')) && (
              <Fragment>
                <Grid item sm={3} xs={4}>
                  <Controller
                    name="ufExpedicao"
                    control={control}
                    render={({ field }) => (
                      <SelectSaurus
                        disabled={props.loading}
                        conteudo={UFMock}
                        fullWidth
                        variant="outlined"
                        label={'UF'}
                        allowSubmit
                        select
                        {...field}
                        value={
                          UFMock.find(
                            (uf) => uf.Value === getValues('ufExpedicao')
                          )?.Key
                        }
                        onChange={(event) => {
                          if (event) {
                            const item = UFMock.filter(
                              (item) => item.Key === event.target.value
                            )[0];
                            if (item) {
                              setValue('ufExpedicao', item.Value);
                            }
                          }
                        }}
                        error={Boolean(
                          errors.ufExpedicao && errors.ufExpedicao.message
                        )}
                        helperText={
                          errors.ufExpedicao
                            ? errors.ufExpedicao?.message
                            : undefined
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item sm={4} xs={8}>
                  <Controller
                    name="orgaoExpedicao"
                    control={control}
                    render={({ field }) => (
                      <SelectSaurus
                        disabled={props.loading}
                        fullWidth
                        autoComplete={'off'}
                        conteudo={TipoDocumentoOrgaoExpedidorMock.sort((a, b) =>
                          a.Value.localeCompare(b.Value)
                        )}
                        label="Órgão Expedidor"
                        placeholder="Ex: SSP"
                        error={Boolean(
                          errors.orgaoExpedicao && errors.orgaoExpedicao.message
                        )}
                        helperText={
                          errors.orgaoExpedicao
                            ? errors.orgaoExpedicao?.message
                            : undefined
                        }
                        {...field}
                        onChange={(e) =>
                          setValue('orgaoExpedicao', Number(e.target.value))
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    name="dataExpedicao"
                    control={control}
                    render={({ field }) => (
                      <TextFieldSaurus
                        tipo="DATA"
                        disabled={props.loading}
                        fullWidth
                        autoComplete={'off'}
                        label="Data de Expedição"
                        error={Boolean(
                          errors.dataExpedicao && errors.dataExpedicao.message
                        )}
                        helperText={
                          errors.dataExpedicao
                            ? errors.dataExpedicao?.message
                            : undefined
                        }
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    name="dataValidade"
                    control={control}
                    render={({ field }) => (
                      <TextFieldSaurus
                        tipo="DATA"
                        disabled={props.loading}
                        fullWidth
                        autoComplete={'off'}
                        label="Data de Validade"
                        error={Boolean(
                          errors.dataValidade && errors.dataValidade.message
                        )}
                        helperText={
                          errors.dataValidade
                            ? errors.dataValidade?.message
                            : undefined
                        }
                        {...field}
                      />
                    )}
                  />
                </Grid>
                
              </Fragment>
            )}
          </Grid>
          <Button style={{ display: 'none' }} type="submit"></Button>
        </form>
      </div>
    </>
  );
});
