import { VariaveisAmbiente } from "config";
import { PedidoDadosPagamentoModel } from "model/api/gestao/pedido/pedido-dados-model";
import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";

export function usePostPedidoPagamento() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const postPedidoPagamento = useCallback(
        (pedido: PedidoDadosPagamentoModel, empresaId: string, pedidoId: string) => {
            return invocarApi({
                url: `/v2/empresa/${empresaId}/pedido-salao/${pedidoId}/pagamento`,
                method: "POST",
                enviarTokenUsuario: true,
                headers: { "Content-Type": "application/json" },
                data: JSON.stringify(pedido),
                baseURL: `${VariaveisAmbiente.apiUrlPedidos}/api`,
            });
        },
        [invocarApi]
    );
    return {
        ...outrasPropriedades,
        postPedidoPagamento,
    };
}
