import classNames from "classnames"
import { alignType, colorType, TypographyProps, weightType } from "./typography-props"
import { useStyles } from "./typography-styles";
import { forwardRef } from "react";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";

//colocando as funções fora pra evitar que elas sejam renderizadas com o componente, apenas o retorno ser re-renderizado
function returnWeight(weight: weightType | undefined, styles: ClassNameMap) {
    switch (weight) {
        case "normal":
            return styles['weightNormal'];
        case 500:
            return styles['weight500'];
        case 600:
            return styles['weight600'];
        case 700:
            return styles['weight700'];
        case 800:
            return styles['weight800'];
        case 900:
            return styles['weight900'];
        default:
            return undefined
    }
}

function returnColor(color: colorType | undefined, styles: ClassNameMap) {
    return color ? styles[color] : undefined;
}

function returnAlign(align: alignType | undefined, styles: ClassNameMap) {
    switch (align) {
        case 'center':
            return styles['alignCenter'];
        case 'justify':
            return styles['alignJustify'];
        case 'left':
            return styles['alignLeft'];
        case 'right':
            return styles['alignRight'];
        default:
            return undefined;
    }
}

export const Typography = forwardRef<HTMLHeadingElement, TypographyProps>((props, ref) => {

    const styles = useStyles();
    const color = returnColor(props.color, styles);

    const variant = props.variant || 'body1';
    const align = returnAlign(props.align, styles);

    const noWrap = props.noWrap ? styles.noWrap : undefined;

    const weight = returnWeight(props.weight, styles);

    switch (variant) {
        case 'h1':
            return <h1 ref={ref} {...props} className={classNames(styles.root, styles.h1, props.className, color, align, noWrap, weight)}>{props.children}</h1>
        case 'h2':
            return <h2 ref={ref} {...props} className={classNames(styles.root, styles.h2, props.className, color, align, noWrap, weight)}>{props.children}</h2>
        case 'h3':
            return <h3 ref={ref} {...props} className={classNames(styles.root, styles.h3, props.className, color, align, noWrap, weight)}>{props.children}</h3>
        case 'h4':
            return <h4 ref={ref} {...props} className={classNames(styles.root, styles.h4, props.className, color, align, noWrap, weight)}>{props.children}</h4>
        case 'h5':
            return <h5 ref={ref} {...props} className={classNames(styles.root, styles.h5, props.className, color, align, noWrap, weight)}>{props.children}</h5>
        case 'h6':
            return <h6 ref={ref} {...props} className={classNames(styles.root, styles.h6, props.className, color, align, noWrap, weight)}>{props.children}</h6>
        case 'subtitle1':
            return <h6 ref={ref} {...props} className={classNames(styles.root, styles.subtitle1, props.className, color, align, noWrap, weight)}>{props.children}</h6>
        case 'subtitle2':
            return <h6 ref={ref} {...props} className={classNames(styles.root, styles.subtitle2, props.className, color, align, noWrap, weight)}>{props.children}</h6>
        case 'body1':
            return <p ref={ref} {...props} className={classNames(styles.root, styles.body1, props.className, color, align, noWrap, weight)}>{props.children}</p>
        case 'body2':
            return <p ref={ref} {...props} className={classNames(styles.root, styles.body2, props.className, color, align, noWrap, weight)}>{props.children}</p>
        case 'caption':
            return <span ref={ref} {...props} className={classNames(styles.root, styles.caption, props.className, color, align, noWrap, weight)}>{props.children}</span>
        case 'button':
            return <span ref={ref} {...props} className={classNames(styles.root, styles.button, props.className, color, align, noWrap, weight)}>{props.children}</span>
        case 'overline':
            return <span ref={ref} {...props} className={classNames(styles.root, styles.overline, props.className, color, align, noWrap, weight)}>{props.children}</span>
        case 'srOnly':
            return <span ref={ref} {...props} className={classNames(styles.root, styles.srOnly, props.className, color, align, noWrap, weight)}>{props.children}</span>
        case 'inherit':
            return <span ref={ref} {...props} className={classNames(styles.root, props.className, color, align, noWrap, weight)}>{props.children}</span>
    }

    return <></>
});