import { NFrefModel } from "model/api/gestao/venda/venda-completa-model"
import { formatarCPFCNPJ } from "utils/cpfcnpj-format";
import { useStyles } from "../card-doc-referenciados-styles";
import { useThemeQueries } from "views/theme";
import { TipoDocRefMock } from "data/mocks/tipo-doc-ref-mock";
import classNames from "classnames";
import { EditarIcon } from "views/components/icons";
import { Grid, Typography } from "views/design-system";

interface Props {
    model: NFrefModel
}

export const DocReferenciadoNFP = ({ model }: Props) => {
    const nfp = model.refNFP
    const myClasses = useStyles()
    const valorReal = Object.entries(model).find(x => x[1])
    const getKey = Object.keys(model).filter(x => x === valorReal?.[0] || '')
    const { isMobile } = useThemeQueries()
    return (
        <>
            <Grid container spacing={1}>
                <Grid item xs={6}>
                    <Grid className={myClasses.content}>
                        <Typography color="primary" variant="body2">
                            Tipo de Documento
                        </Typography>
                        <Typography
                            className={classNames('celula-grid-value', myClasses.valores)}
                            color="textPrimary"
                            variant="body2"
                        >
                            {TipoDocRefMock.filter(x => x.Key === getKey[0])[0].Value}
                        </Typography>
                    </Grid>
                    <Grid>
                        <Typography color="primary" variant="body2">
                            CPF/CNPJ
                        </Typography>
                        <Typography
                             className={classNames('celula-grid-value', myClasses.valores)}
                            color="textPrimary"
                            variant="body2"
                        >
                            {nfp?.cnpj || nfp?.cpf ? formatarCPFCNPJ(nfp?.cnpj ?? (nfp?.cpf ?? '')) : ''}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item xs={3}>
                    <Grid>
                        <Typography color="primary" variant="body2">
                            Número
                        </Typography>
                        <Typography
                             className={classNames('celula-grid-value', myClasses.valores)}
                            color="textPrimary"
                            variant="body2"
                        >
                            {nfp?.nNF ?? 0}
                        </Typography>
                    </Grid>
                    <Grid>
                        <Typography color="primary" variant="body2">
                            Ano/Mês
                        </Typography>
                        <Typography
                            className={classNames('celula-grid-value', myClasses.valores)}
                            color="textPrimary"
                            variant="body2"
                        >
                            {nfp?.aamm}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item xs={2}>
                    <Grid>
                        <Typography color="primary" variant="body2">
                            Série
                        </Typography>
                        <Typography
                            className={classNames('celula-grid-value', myClasses.valores)}
                            color="textPrimary"
                            variant="body2"
                        >
                            {(`000` + nfp?.serie).substr(-3)}
                        </Typography>
                    </Grid>
                </Grid>
                {isMobile &&
                    <Grid xs={1} item style={{ display: 'flex' }} alignItems="center" justifyContent="flex-end">
                        <EditarIcon tipo='BUTTON' />
                    </Grid>
                }
            </Grid>
        </>
    )
}