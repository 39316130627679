
export class CheckoutCartaoFormModel {
    constructor(
        public brand: string = '',
        public number: string = '',
        public expiry: string = '',
        public cvc: string | number = '',
        public name: string = '',
        public document: string = '',
    ) { }
}