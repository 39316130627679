import { useCallback } from 'react';
import { useDefaultCadastroStyles } from '../cadastros/components/default-cadastro-styles';
import { EntradaHeader } from './components/entrada-header/entrada-header';
import { ButtonFab } from 'views/components/controles';
import { useCadastros } from 'services/app';
import { EntradaList } from './components/entrada-list/entrada-list';
import { NovoIcon } from 'views/components/icons';
import { Grid } from 'views/design-system';

const EntradaPage = () => {
  const classes = useDefaultCadastroStyles();

  const { abrirEntradaModal } = useCadastros();

  const onClickAdd = useCallback(() => {
    abrirEntradaModal();
  }, [abrirEntradaModal]);

  return (
    <Grid className={classes.root}>
      <Grid className={classes.header}>
        <EntradaHeader />
      </Grid>
      <Grid className={classes.list}>
        <EntradaList />
      </Grid>
      <ButtonFab
        tooltip="Nova Entrada"
        icon={<NovoIcon tipo="BUTTON_FAB" />}
        onClick={onClickAdd}
      />
    </Grid>
  );
};

export default EntradaPage;
