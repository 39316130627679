import React, { useCallback, useEffect } from "react"
import { useCadastros } from "./cadastros";
import { useEventTools } from "./events/event-tools";
import { AppEventEnum } from "model/enums/enum-app-event";

export interface ShowConfirmProps{
    title: string;
    primaryButtonText: string;
    secondaryButtonText: string;
    primaryButtonColor?: string;
    secondaryButtonColor?: string;
    confirmIcon?: React.ReactElement;
    secondaryIcon?: React.ReactElement;
    description?: string;
    showIcon?: boolean;
}

export const useConfirmSaurus = () => {

    const {abrirDialogConfirm, fecharDialogConfirm} = useCadastros();
    const { addHandler, removeHandler } = useEventTools();

    const confirmCallback = useCallback((resolve?: (value: unknown) => void) => {
        fecharDialogConfirm()
        if(resolve){
            resolve(true)
        }
    }, [fecharDialogConfirm])

    const cancelCallback = useCallback((reject?: (reason?: any) => void) => {
        fecharDialogConfirm()
        if(reject){
            reject(false)
        }
    }, [fecharDialogConfirm])

    const showConfirm = useCallback((props: ShowConfirmProps) => new Promise((resolve, reject) => {
        abrirDialogConfirm(props)

        addHandler(AppEventEnum.ConfirmEvent, () => confirmCallback(resolve))
        addHandler(AppEventEnum.CancelEvent, () => cancelCallback(reject))
    }), [abrirDialogConfirm, addHandler, cancelCallback, confirmCallback])

    useEffect(() => {
        return () => {
            removeHandler(AppEventEnum.ConfirmEvent, () => confirmCallback())
            removeHandler(AppEventEnum.CancelEvent, () => cancelCallback())
        }
    }, [cancelCallback, confirmCallback, removeHandler])

    return {
        showConfirm
    }
}