import { DialogSaurus } from '../dialog-saurus/dialog-saurus';
import { useStyles } from './dialog-mov-indicador-desperdicio-styles';
import { VoltarIcon } from 'views/components/icons';
import { ConsumidoIcon } from 'views/components/icons/consumido-icon';
import { ProduzidoIcon } from 'views/components/icons/produzido-icon';
import { NaoSeAplicaIcon } from 'views/components/icons/nao-aplica-icon';
import { LixoConsumidoIcon } from 'views/components/icons/lixo-consumido-icon';
import { DialogMovIndicadorDesperdicioProps } from './dialog-mov-indicador-desperdicio-props';
import { EnumIndDesperdicio } from 'model/enums/enum-ind-desperdicio';
import { useCadastros } from 'services/app';
import { Box, Button, Grid, Typography } from 'views/design-system';

export const DialogMovIndicadorDesperdicio = ({
  openned,
  handleConfirme,
  produto
}: DialogMovIndicadorDesperdicioProps) => {
  const { fecharDialogIndicacaoDeDesperdicio } = useCadastros();
  const classes = useStyles();

  return (
    <DialogSaurus
      aberto={openned || false}
      titulo="Indicador de desperdicio"
      tamanho="xs"
    >
      <Box className={classes.cardContainer}>
        <Typography style={{ marginBottom: '16px' }}>
          Selecione a opção que mais se adequa de acordo com o produto.
        </Typography>
        <Box className={classes.cardContent}>
          <Grid container spacing={2} style={{ marginBottom: 8 }}>
            <Grid item xs={12}>
              <Button
                variant="contained"
                fullWidth
                color="primary"
                onClick={() => {
                  handleConfirme(produto?.current, EnumIndDesperdicio.NaoSeAplica);
                  fecharDialogIndicacaoDeDesperdicio();
                }}
              >
                <NaoSeAplicaIcon tipo="BUTTON_PRIMARY" />
                Não se aplica
              </Button>
            </Grid>

            <Grid item xs={12}>
              <Button
                variant="contained"
                fullWidth
                color="primary"
                onClick={() => {
                  handleConfirme(produto?.current, EnumIndDesperdicio.NaoProduzido);
                  fecharDialogIndicacaoDeDesperdicio();
                }}
              >
                <ProduzidoIcon tipo="BUTTON_PRIMARY" />
                Não produzido
              </Button>
            </Grid>

            <Grid item xs={12}>
              <Button
                variant="contained"
                fullWidth
                color="primary"
                onClick={() => {
                  handleConfirme(produto?.current, EnumIndDesperdicio.Produzido);
                  fecharDialogIndicacaoDeDesperdicio();
                }}
              >
                <ConsumidoIcon tipo="BUTTON_PRIMARY" />
                Produzido
              </Button>
            </Grid>

            <Grid item xs={12}>
              <Button
                variant="contained"
                fullWidth
                color="primary"
                onClick={() => {
                  handleConfirme(produto?.current, EnumIndDesperdicio.Consumido);
                  fecharDialogIndicacaoDeDesperdicio();
                }}
              >
                <LixoConsumidoIcon tipo="BUTTON_PRIMARY" />
                Consumido
              </Button>
            </Grid>

            <Grid item xs={12}>
              {fecharDialogIndicacaoDeDesperdicio && (
                <Button
                  variant="outlined"
                  fullWidth
                  color="primary"
                  onClick={() => {
                    fecharDialogIndicacaoDeDesperdicio();
                  }}
                >
                  <VoltarIcon tipo="BUTTON" />
                  Voltar
                </Button>
              )}
            </Grid>
          </Grid>
        </Box>
      </Box>
    </DialogSaurus>
  );
};
