import { useCallback, useEffect, useState, useRef } from 'react';
import {
  NovoIcon,
  OkIcon,
  SalvarIcon,
  VoltarIcon,
} from 'views/components/icons';
import { isEqual } from 'utils/is-equal';
import { useModalStyles } from 'views/components/modals/utils/modal-styles';
import { DefaultFormRefs } from 'views/components/form/utils';
import { useToastSaurus, useCadastros } from 'services/app';
import { ModalHeader } from 'views/components/modals/components/modal-header/modal-header';
import { ButtonModalHeader } from 'views/components/controles/buttons/button-modal-header/button-modal-header';
import { picker } from 'utils/picker';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import { MenuOptions } from 'views/components/menu-options/menu-options';
import { MenuOptionsModel } from 'views/components/menu-options/model/menu-options-model';
import { useGetProdutoFiscalById } from '../../../../../../data/api/gestao/produto-fiscal/get-produto-fiscal-by-id';
import { usePutProdutoFiscal } from '../../../../../../data/api/gestao/produto-fiscal/put-produto-fiscal';
import { ProdutoFiscalEditFormModel } from '../../../../../../model/app/forms/produto-fiscal/produto-fiscal-edit-form-model';
import { ProdutoFiscalModel } from '../../../../../../model/api/gestao/produto/produto-fiscal/produto-fiscal-model';
import { FormProdutoFiscalEdit } from '../../../../form/produto-fiscal/produto-fiscal-edit/form-produto-fiscal-edit';
import classNames from 'classnames';
import { CancelarIcon } from '../../../../icons/cancelar-icon';
import { Button, Grid } from 'views/design-system';

export const ProdutoFiscalEdit = (props: { id: string; empresaId: string }) => {
  const { showToast } = useToastSaurus();
  const [preenchendoTela, setPreenchendoTela] = useState(true);
  const classes = useModalStyles();
  const { fecharCadastroProdutoFiscal, abrirCadastroProdutoFiscal } =
    useCadastros();
  const { getProdutoFiscalById, carregando: carregandoGet } =
    useGetProdutoFiscalById();
  const { putProdutoFiscal, carregando: carregandoPut } = usePutProdutoFiscal();
  const carregando = carregandoPut || carregandoGet || preenchendoTela;
  const [produtoFiscalFormState, setProdutoFiscalForm] =
    useState<ProdutoFiscalEditFormModel>(new ProdutoFiscalEditFormModel());

  const refEditForm = useRef<DefaultFormRefs<ProdutoFiscalEditFormModel>>(null);
  const refProdutoFiscalModel = useRef<ProdutoFiscalModel>(
    new ProdutoFiscalModel(),
  );

  const recarregarForm = useCallback((model: ProdutoFiscalEditFormModel) => {
    refEditForm.current?.fillForm({ ...model });
  }, []);

  useEffect(() => {
    recarregarForm(produtoFiscalFormState);
  }, [produtoFiscalFormState, recarregarForm]);

  const getProdutoFiscalByIdWrapper =
    useCallback(async (): Promise<ProdutoFiscalEditFormModel> => {
      const res = await getProdutoFiscalById(props.id);
      if (res.erro) {
        throw res.erro;
      }
      const ret = res.resultado?.data as ProdutoFiscalModel;
      refProdutoFiscalModel.current = ret;
      const produtofiscal = picker<ProdutoFiscalEditFormModel>(
        ret,
        new ProdutoFiscalEditFormModel(),
      );
      return produtofiscal;
    }, [props.id, getProdutoFiscalById]);

  const preencherTela = useCallback(async () => {
    try {
      setPreenchendoTela(true);
      const prodfiscal = await getProdutoFiscalByIdWrapper();
      setProdutoFiscalForm(prodfiscal);
      setPreenchendoTela(false);
    } catch (e: any) {
      showToast('error', e.message);
      setPreenchendoTela(false);
    }
  }, [getProdutoFiscalByIdWrapper, showToast]);

  useEffect(() => {
    (async () => {
      await preencherTela();
    })();
    return () => {
      setProdutoFiscalForm(new ProdutoFiscalEditFormModel());
    };
  }, [preencherTela, showToast, getProdutoFiscalByIdWrapper]);

  const saveChangesProdutoFiscal = useCallback(
    async (ProdutoFiscalModelForm: ProdutoFiscalEditFormModel) => {
      let produtofiscal = picker<ProdutoFiscalModel>(
        ProdutoFiscalModelForm,
        refProdutoFiscalModel.current,
      );

      try {
        const ret = await putProdutoFiscal(produtofiscal);
        if (ret.erro) {
          throw ret.erro;
        }

        showToast('success', 'Grupo de Impostos atualizado com sucesso!', );
        await preencherTela();
      } catch (e: any) {
        showToast('error', e.message);
        refEditForm.current?.resetForm();
      }
    },
    [putProdutoFiscal, preencherTela, showToast],
  );

  const redirectToCadastro = useCallback(() => {
    abrirCadastroProdutoFiscal('', '', true);
  }, [abrirCadastroProdutoFiscal]);

  const handleSubmit = useCallback(
    async (
      model: ProdutoFiscalEditFormModel,
      beforeModel: ProdutoFiscalEditFormModel,
    ) => {
      const formIsEqual = isEqual(model, beforeModel);
      if (formIsEqual) {
        showToast('info', 'Nenhuma informação foi alterada');
        return;
      }

      saveChangesProdutoFiscal(model);
    },
    [saveChangesProdutoFiscal, showToast],
  );
  const onCloseClick = useCallback(() => {
    fecharCadastroProdutoFiscal();
  }, [fecharCadastroProdutoFiscal]);

  const changeStatus = useCallback(async () => {
    try {
      let model = await getProdutoFiscalByIdWrapper();
      model.ativo = !model.ativo;
      saveChangesProdutoFiscal(model);
    } catch (e: any) {
      showToast('error', e.message);
    }
  }, [showToast, getProdutoFiscalByIdWrapper, saveChangesProdutoFiscal]);

  return (
    <div className={classes.root}>
      {carregando ? <CircularLoading tipo="FULLSIZED" /> : null}
      <ModalHeader
        title={'Edição de Impostos'}
        leftArea={
          <ButtonModalHeader
            tooltip="Voltar"
            icon={<VoltarIcon tipo="MODAL_HEADER" />}
            onClick={onCloseClick}
          />
        }
        rightArea={
          <MenuOptions
            options={[
              new MenuOptionsModel(
                `${produtoFiscalFormState.ativo ? 'Desabilitar' : 'Habilitar'
                } Grupo`,
                produtoFiscalFormState.ativo ? (
                  <CancelarIcon tipo="INPUT" />
                ) : (
                  <OkIcon tipo="INPUT" />
                ),
                changeStatus,
              ),
            ]}
          />
        }
      />
      <div className={classes.content}>
        <div
          className={classNames(
            classes.contentForms,
            preenchendoTela ? classes.contentFormsLoading : undefined,
          )}
        >
          <FormProdutoFiscalEdit
            ref={refEditForm}
            onSubmit={handleSubmit}
            showLoading={false}
            loading={carregando}
          />
        </div>
        <div className={classes.acoes}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Button
                disabled={carregando}
                variant="outlined"
                color="primary"
                size="large"
                fullWidth
                onClick={redirectToCadastro}
              >
                <NovoIcon tipo="BUTTON" />
                Novo
              </Button>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Button
                disabled={carregando}
                onClick={() => refEditForm.current?.submitForm()}
                variant="contained"
                color="primary"
                size="large"
                fullWidth
              >
                <SalvarIcon tipo="BUTTON_PRIMARY" />
                Salvar
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};
export default ProdutoFiscalEdit;
