import { Box, Button } from "views/design-system";
import { DialogSaurus } from "../dialog-saurus/dialog-saurus";
import { MovRegistrarResponseModel } from "model/api/gestao/mov-pag/mov-registrar-response";
import { useStyles } from '../dialog-vencimento-pagamento/dialog-vencimento-pagamento-styles'
import { CardDownloadBoleto } from "./components/card-download-boleto";
import { ConfirmarIcon } from "views/components/icons/confirmar-icon";

interface Props {
    aberto: boolean;
    boletos: MovRegistrarResponseModel;
    callback: () => void;
}

export const DialogDownloadBoletos = ({
    aberto,
    boletos,
    callback,
}: Props) => {

    const classes = useStyles();

    return <DialogSaurus
        aberto={aberto}
        titulo={'Boleto' + (boletos.parcelas.length > 1 ? 's' : '')}
        tamanho='sm'
    >
        <Box className={classes.container}>

            <Box flex flexDirection="column" alignItems="stretch">
                {boletos.parcelas.map((parcela, index) => {
                    return <CardDownloadBoleto
                        index={index}
                        model={parcela}
                        key={parcela.id}
                    />
                })}
            </Box>
            <Box className={classes.buttonContainer}>
                <Button onClick={callback} variant="contained" color='primary'>
                    <ConfirmarIcon tipo='BUTTON_PRIMARY' />
                    Finalizar Venda
                </Button>
            </Box>
        </Box>
    </DialogSaurus>
}