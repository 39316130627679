import { DefaultModal } from '../components/default-modal/default-modal';
import { ModalProps } from '../utils/modal-props';
import { TecladoFuncoes } from './components/teclado-funcoes/teclado-funcoes';
import { TecladoFuncoesProps } from './components/teclado-funcoes/teclado-funcoes-props';

export type TecladoFuncoesModalProps = ModalProps & TecladoFuncoesProps

export const TecladoFuncoesModal = (props: TecladoFuncoesModalProps) => {
  return (
    <DefaultModal
      minWidth="400px"
      open={props.openned || false}
      variant={'temporary'}
      anchor="right"
    >
      {props.openned && <TecladoFuncoes {...props} />}
    </DefaultModal>
  );
};
