import { useMemo } from 'react';
import { stringNumeros } from 'utils/string-numeros';
import * as Yup from 'yup';

const isValidCardNumber = (cardNumber: string) => {
    let sum = 0;
    let shouldDouble = false;

    for (let i = cardNumber.length - 1; i >= 0; i--) {
        let digit = parseInt(cardNumber[i], 10);

        if (shouldDouble) {
            digit *= 2;
            if (digit > 9) digit -= 9;
        }

        sum += digit;
        shouldDouble = !shouldDouble;
    }

    return sum % 10 === 0;
};

export const useFormCheckoutCartaoValidation = () => {
    const FormCheckoutCartaoValidation = useMemo(() => {
        return (
            Yup.object().shape({
                number: Yup.string().required('Número de cartão obrigatório').test({
                    message: 'Número de cartão inválido',
                    test: (value) => {
                        if (value) {
                            const numeros = stringNumeros(value)
                            return isValidCardNumber(numeros)
                        }
                        return false
                    },
                    name: 'card-number'
                }),
                cvc: Yup.string().required('CVV é obrigatório'),
                expiry: Yup.string().required('Validade é obrigatório'),
                name: Yup.string().required('Nome do titular é obrigatório'),
                document: Yup.string().required('CPF/CNPJ do titular é obrigatório'),
            })
        )
    }, [])

    return {
        FormCheckoutCartaoValidation,
    }
}

