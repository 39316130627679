import { Box, Button, Grid, Typography } from 'views/design-system';
import { DialogSaurus } from '../dialog-saurus/dialog-saurus';
import { useStyles } from './dialog-nota-fiscal-styles';
import { NotaFiscalIcon, NotaManualIcon } from 'views/components/icons';

interface DialogNotaFiscalProps {
  openned: boolean;
  onClickNF: () => void
  onClickManual: () => void
}

export const DialogNotaFiscal = ({ openned, onClickManual, onClickNF }: DialogNotaFiscalProps) => {

  const classes = useStyles();


  return (
    <>
      <DialogSaurus
        aberto={openned}
        titulo="Nota Fiscal"
        centralizarTitulo
        tamanho="xs"
      >
        {openned && (
          <Box className={classes.cardContainer}>
            <Box className={classes.cardContent}>
              <Box className={classes.boxCustom}>
                <Grid
                  container
                  spacing={2}
                  style={{ marginBottom: 4 }}
                >
                  <Grid item xs={6}>
                    <Button
                      variant="contained"
                      style={{
                        height: 120,
                        display: 'block'
                      }}
                      onClick={onClickNF}
                      fullWidth
                      size='large'
                      color="primary"
                    >
                      <div>

                        <NotaFiscalIcon tipo="MODAL_HEADER" />
                      </div>
                      <Typography variant='body1' style={{
                        fontWeight: 600
                      }}>
                        NFC-e
                      </Typography>
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      variant="contained"
                      style={{
                        height: 120,
                        display: 'block'
                      }}
                      onClick={onClickManual}
                      fullWidth
                      size='large'
                      color="primary"
                    >
                      <div>
                        <NotaManualIcon tipo="MODAL_HEADER" />
                        <Typography variant='body1' style={{
                          fontWeight: 600
                        }}>
                          Nota Manual
                        </Typography>
                      </div>

                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
        )}
      </DialogSaurus>
    </>
  );
};
