import { EnumEntradaFiltroPagina } from "../../entrada-filtro-conteudo"
import { EntradaFiltroInicialProps } from "./entrada-filtro-inicial-props"
import { CalendarioIcon, OkIcon, StatusIcon } from "views/components/icons"
import { UltimaVendaIcon } from "views/components/icons/ultima-venda-icon"
import { useStyles } from "./entrada-filtro-inicial-styles"
import { useModalStyles } from "views/components/modals/utils/modal-styles"
import { toDate, toDateString } from "utils/to-date"
import { ButtonFiltroProps } from "./button-filtro/button-filtro-props"
import { ButtonFiltro } from "./button-filtro/button-filtro"
import { StatusMovimentacaoMockTodos } from "data/mocks/status-entrada-mock"
import { BorrachaIcon } from "views/components/icons/borracha-icon"
import { isDateEmpty } from "utils/is-date-empty"
import { Box, Button, Divider, Grid } from "views/design-system"


export const EntradaFiltroInicial = (props: EntradaFiltroInicialProps) => {
    const { entrarPagina, submit, vFiltros, limparFiltros, setVFiltros } = props;

    const classes = useStyles()
    const modalClasses = useModalStyles()

    const hasDataValue = !isDateEmpty(vFiltros.dInicial) || !isDateEmpty(vFiltros.dFinal)

    const handleRemove = (label: 'dInicial' | 'dFinal' | 'status' | 'nnf') => {
        setVFiltros(prev => ({
            ...prev,
            [label]: label === 'nnf' ? 0 : label === "status" ? -1 : ''
        }))
    }

    const btnFiltrosList: ButtonFiltroProps[] = [
        {
            entrarPagina: () => entrarPagina(EnumEntradaFiltroPagina.Data),
            handleRemove: () => {
                handleRemove('dInicial')
                handleRemove('dFinal')
            },
            icon: CalendarioIcon,
            titulo: 'Data',
            vFiltro: hasDataValue ? `${!isDateEmpty(vFiltros.dInicial) ? toDateString(toDate(vFiltros.dInicial)) : ''} ${isDateEmpty(vFiltros.dInicial) || isDateEmpty(vFiltros.dFinal) ? '' : '-'} ${!isDateEmpty(vFiltros.dFinal) ? toDateString(toDate(vFiltros.dFinal)) : ''}` : '',
        },
        {
            entrarPagina: () => entrarPagina(EnumEntradaFiltroPagina.Status),
            handleRemove: () => handleRemove('status'),
            icon: StatusIcon,
            titulo: 'Status',
            vFiltro: vFiltros.status > -1 ? StatusMovimentacaoMockTodos.find(x => x.Key === vFiltros.status)?.Value : '',
        },
        {
            entrarPagina: () => entrarPagina(EnumEntradaFiltroPagina.NNF),
            handleRemove: () => handleRemove('nnf'),
            icon: UltimaVendaIcon,
            titulo: 'N° da Nota Fiscal',
            vFiltro: vFiltros.nnf > 0 ? vFiltros.nnf : '',
        }
    ]

    return (
        <div className={modalClasses.content}>
            <Box className={classes.filtros}>
                <Grid container>
                    <Grid xs={12}>
                        <Divider className={classes.divider} />
                    </Grid>
                    {btnFiltrosList.map((filtro, index) => {
                        const key = `entrada-btn-${index}`
                        return (
                            <Grid xs={12} key={key}>
                                <ButtonFiltro
                                    {...filtro}
                                />
                                <Divider className={classes.divider} />
                            </Grid>
                        )
                    })}
                </Grid>
            </Box>
            <div className={modalClasses.acoes}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Button
                            onClick={limparFiltros}
                            variant="outlined"
                            color="primary"
                            fullWidth
                        >
                            <BorrachaIcon tipo='BUTTON' />
                            Limpar Filtros
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            onClick={submit}
                            variant="contained"
                            color="primary"
                            fullWidth
                        >
                            <OkIcon tipo="BUTTON_PRIMARY" />
                            Buscar
                        </Button>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}