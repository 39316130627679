import { DialogSaurus } from '../dialog-saurus/dialog-saurus';
import { useStyles } from './dialog-mensagem-ok-styles';
import { CircularLoading } from 'views';
import { useSessaoAtual } from 'services/app';
import { Box, Button, Grid, Typography } from 'views/design-system';

interface DialogMensagemOkProps {
  openned: boolean;
  loading: boolean;
  closeModal: () => void;
}

export const DialogMensagemOk = ({
  openned,
  closeModal,
  loading,
}: DialogMensagemOkProps) => {
  const { plano } = useSessaoAtual();
  const classes = useStyles();

  return (
    <DialogSaurus
      aberto={openned || false}
      titulo="Aviso do Representante"
      tamanho="sm"
    >
      {loading && <CircularLoading tipo="FULLSIZED" />}
      <Box className={classes.cardContainer}>
        <Box className={classes.cardContent}>
          <Box className={classes.boxCustom} >
            <Box className={classes.msg}
            >
              <Typography>
                O seu representante enviou um aviso para você:{' '}
                <b>{plano?.contratado.avisoCobrancaRepresentante}</b>
              </Typography>
            </Box>
            <Grid
              container
              spacing={2}
              style={{ marginTop: 16, marginBottom: 8 }}
            >
              <Grid item xs={12}>
                {closeModal && (
                  <Button
                    disabled={loading}
                    variant="outlined"
                    fullWidth
                    color="primary"
                    onClick={() => {
                      closeModal();
                    }}
                  >
                    Fechar
                  </Button>
                )}
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </DialogSaurus>
  );
};
