import { useCallback, useEffect, useRef, useState } from "react";
import { useStyles } from "./calendario-styles"
import { AvancarIcon, VoltarIcon } from "views/components/icons";
import { IconButton } from "@material-ui/core";
import { useThemeQueries } from "views/theme";
import { toDateString } from "utils/to-date";
import { Button, Grid, Typography } from "views/design-system";

export interface IRangeData {
    inicio: Date | undefined
    fim: Date | undefined
}

export type CalendarioMaskType = string | {
    inicio: string
    fim: string
}

interface IProps {
    value?: IRangeData | Date
    manterMascara?: boolean
    type?: 'date' | 'date-range'
    onChange: (value: IRangeData | Date, maskedValue?: CalendarioMaskType) => void
}

const months = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro"
];

const weekdays = ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"];

export const Calendario = (props: IProps) => {
    const classes = useStyles()
    const { theme } = useThemeQueries()
    const [hoveredDay, setHoveredDay] = useState(-1)
    const [currentDate, setCurrentDate] = useState(new Date())
    const [showPainelAno, setShowPainelAno] = useState(false)
    const [showPainelMes, setShowPainelMes] = useState(false)

    useEffect(() => {
        if (props.value) {
            if (props.type === 'date') {
                setCurrentDate(props.value as Date)
            } else if (props.type === 'date-range') {
                const castedProps = props.value as IRangeData
                if (castedProps.inicio) {
                    setCurrentDate(castedProps.inicio)
                } else if (castedProps.fim) {
                    setCurrentDate(castedProps.fim)
                }
            }
        }
    }, [props.type, props.value])

    const handleMouseEnter = useCallback((e: any) => {
        e.target.style.background = theme.palette.primary.main
        e.target.style.color = theme.palette.primary.contrastText
    }, [theme.palette.primary.contrastText, theme.palette.primary.main])

    const handleMouseLeave = useCallback((e: any) => {
        e.target.style.background = theme.palette.background.paper
        e.target.style.color = theme.palette.text.primary
    }, [
        theme.palette.text.primary, theme.palette.background.paper])

    const tableRef = useRef<HTMLDivElement>(null);
    const generateCalendar = useCallback(() => {
        const table = document.createElement("table");
        table.id = "calendar";

        const trHeader = document.createElement('tr');
        trHeader.className = classes.weekends;
        weekdays.forEach(week => {
            const th = document.createElement('th');
            const w = document.createTextNode(week.substring(0, 3));
            th.appendChild(w);
            trHeader.appendChild(th);
        });

        table.appendChild(trHeader);

        const weekDay = new Date(
            new Date(currentDate ?? '').getFullYear(),
            new Date(currentDate ?? '').getMonth(),
            1
        ).getDay();

        const lastDay = new Date(
            new Date(currentDate ?? '').getFullYear(),
            new Date(currentDate ?? '').getMonth() + 1,
            0
        ).getDate();

        let tr = document.createElement("tr");
        let td: any = '';
        let empty: any = '';
        let btn = document.createElement('button');
        let week = 0;
        while (week < weekDay) {
            td = document.createElement("td");
            empty = document.createTextNode(' ');
            td.appendChild(empty);
            tr.appendChild(td);
            week++;
        }
        for (let i = 1; i <= lastDay;) {
            while (week < 7) {
                td = document.createElement('td');
                let text = document.createTextNode(i.toString());
                const date = new Date(
                    new Date(currentDate ?? '').getFullYear(),
                    new Date(currentDate ?? '').getMonth(),
                    i
                ).toDateString();
                btn = document.createElement('button');
                btn.className = `${classes.btnDay}`;
                if (props.type === 'date-range') {
                    const propsCasted = props.value as IRangeData

                    if ((propsCasted?.inicio && propsCasted?.inicio.toDateString() === date) || (propsCasted?.fim && propsCasted?.fim.toDateString() === date)) {
                        btn.classList.add('selected-day')
                    } else {
                        btn.addEventListener('mouseenter', (e) => {
                            setHoveredDay(parseInt(text.textContent!))
                            handleMouseEnter(e)
                        })
                        btn.addEventListener('mouseleave', (e) => {
                            setHoveredDay(-1)
                            handleMouseLeave(e)
                        })
                    }

                    if (currentDate && (hoveredDay > 0 || propsCasted?.fim)) {
                        const currentMonth = currentDate.getMonth()
                        const currentYear = currentDate.getFullYear()

                        const beginDateMonth = (propsCasted?.inicio ?? currentDate).getMonth()
                        const beginDateDay = (propsCasted?.inicio ?? currentDate).getDate()
                        const beginDateYear = (propsCasted?.inicio ?? currentDate).getFullYear()
                        let flag = false
                        if (!propsCasted?.fim) {
                            if (currentYear > beginDateYear) {
                                flag = hoveredDay >= i
                            } else if (currentYear === beginDateYear) {
                                if (currentMonth > beginDateMonth) {
                                    flag = hoveredDay >= i
                                } else if (currentMonth === beginDateMonth) {
                                    flag = beginDateDay < i && hoveredDay >= i
                                }
                            }
                        } else {
                            const endDateMonth = propsCasted?.fim.getMonth()
                            const endDateDay = propsCasted?.fim.getDate()
                            const endDateYear = propsCasted?.fim.getFullYear()
                            const date = new Date(currentYear, currentMonth, i)
                            const dateMonth = date.getMonth()
                            const dateDay = date.getDate()
                            const dateYear = date.getFullYear()
                            if (dateYear > beginDateYear && dateYear < endDateYear) {
                                flag = true
                            } else if (dateYear === beginDateYear && dateYear === endDateYear) {
                                if (dateMonth > beginDateMonth && dateMonth < endDateMonth) {
                                    flag = true
                                } else if (dateMonth === beginDateMonth && dateMonth === endDateMonth) {
                                    flag = dateDay > beginDateDay && dateDay < endDateDay
                                } else if (dateMonth === beginDateMonth) {
                                    flag = dateDay > beginDateDay
                                } else if (dateMonth === endDateMonth) {
                                    flag = dateDay < endDateDay
                                }
                            } else if (dateYear === beginDateYear) {
                                if (dateMonth > beginDateMonth) {
                                    flag = true
                                } else if (dateMonth === beginDateMonth) {
                                    flag = dateDay > beginDateDay
                                }
                            } else if (dateYear === endDateYear) {
                                if (dateMonth < endDateMonth) {
                                    flag = true
                                } else if (dateMonth === endDateMonth) {
                                    flag = dateDay < endDateDay
                                }
                            }
                        }
                        if (flag) {
                            btn.style.background = theme.palette.primary.main + '22'
                        }

                    }
                    const handleClick = (e: any) => {
                        e.stopPropagation()
                        e.preventDefault()
                        if (currentDate) {
                            let maskedValue: string | { inicio: string, fim: string } | undefined = undefined
                            const intDay = parseInt(text.textContent!)
                            const date = new Date(
                                currentDate.getFullYear(),
                                currentDate.getMonth(),
                                intDay
                            )

                            if (props.manterMascara) {
                                maskedValue = {
                                    fim: '',
                                    inicio: ''
                                }
                            }
                            if (propsCasted?.inicio && propsCasted?.fim) {
                                if (props.manterMascara) {
                                    maskedValue = {
                                        inicio: toDateString(propsCasted?.inicio) ?? '',
                                        fim: ''
                                    }
                                }
                                props.onChange({ inicio: date, fim: undefined }, maskedValue)
                                return;
                            }
                            if (propsCasted?.inicio) {
                                if (props.manterMascara) {
                                    maskedValue = {
                                        inicio: toDateString(propsCasted?.inicio) ?? '',
                                        fim: ''
                                    }
                                }
                                if (date < propsCasted?.inicio) {
                                    props.onChange({ inicio: date, fim: undefined }, maskedValue)
                                    return;
                                } else {
                                    if (props.manterMascara) {
                                        maskedValue = {
                                            inicio: toDateString(propsCasted?.inicio) ?? '',
                                            fim: toDateString(date) ?? ''
                                        }
                                    }
                                    props.onChange({ inicio: propsCasted?.inicio, fim: date }, maskedValue)
                                }
                            } else {
                                maskedValue = {
                                    inicio: toDateString(date) ?? '',
                                    fim: ''
                                }
                                props.onChange({
                                    fim: undefined,
                                    inicio: date
                                }, maskedValue)
                            }


                        }
                    }
                    btn.addEventListener('click', handleClick)
                    btn.addEventListener('touchstart', handleClick)
                } else if (props.type === 'date') {
                    if (props.value && date === (props.value as Date).toDateString()) {
                        btn.classList.add('selected-day')
                    } else {
                        btn.addEventListener('mouseenter', (e) => {
                            setHoveredDay(parseInt(text.textContent!))
                            handleMouseEnter(e)
                        })
                        btn.addEventListener('mouseleave', (e) => {
                            setHoveredDay(-1)
                            handleMouseLeave(e)
                        })
                    }
                    const handleCLick = (e: any) => {
                        e.stopPropagation()
                        e.preventDefault()
                        if (currentDate) {
                            const intDay = parseInt(text.textContent!)
                            const date = new Date(
                                currentDate.getFullYear(),
                                currentDate.getMonth(),
                                intDay
                            )
                            if (props.manterMascara) {
                                props.onChange(date, toDateString(date))
                            } else {
                                props.onChange(date)
                            }
                        }
                    }
                    btn.addEventListener('click', handleCLick)
                    btn.addEventListener('touchstart', handleCLick)

                }

                week++;
                if (i <= lastDay) {
                    i++;
                    btn.appendChild(text);
                    td.appendChild(btn)
                } else {
                    text = document.createTextNode(' ');
                    td.appendChild(text);
                }
                tr.appendChild(td);
            }
            table.appendChild(tr);

            tr = document.createElement("tr");

            week = 0;
        }
        if (tableRef.current) {
            tableRef.current.innerHTML = '';
            table.className = classes.calendarContainer
            tableRef.current.appendChild(table);
        }
    }, [classes.btnDay, classes.calendarContainer, classes.weekends, currentDate, handleMouseEnter, handleMouseLeave, hoveredDay, props, theme.palette.primary.main])

    useEffect(() => {

        if (!showPainelAno && !showPainelMes) {
            generateCalendar()
        }

    }, [generateCalendar, showPainelAno, showPainelMes])

    const { isMobile } = useThemeQueries()

    return <>
        <div className={classes.calendar} style={{
            paddingTop: isMobile ? '50px' : undefined
        }}>
            <div className={classes.header}>
                {
                    showPainelAno && (
                        <>
                            <Grid container justifyContent="center" alignItems="center" style={{ display: 'flex', position: 'relative', width: '100%' }}>
                                <div style={{
                                    position: 'absolute',
                                    left: 0
                                }}>
                                    <IconButton onClick={() => setShowPainelAno(false)}>
                                        <VoltarIcon tipo="GERAL" style={{
                                            width: '20px',
                                            height: '20px',
                                        }} />
                                    </IconButton>
                                </div>
                                <Grid item>
                                    <Typography>
                                        <strong>
                                            Selecionar Ano
                                        </strong>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </>
                    )
                }

                {
                    showPainelMes && (
                        <>
                            <Grid container justifyContent="center" alignItems="center" style={{ display: 'flex', position: 'relative', width: '100%' }}>
                                <div style={{
                                    position: 'absolute',
                                    left: 0
                                }}>
                                    <IconButton onClick={() => setShowPainelMes(false)}>
                                        <VoltarIcon tipo="GERAL" style={{
                                            width: '20px',
                                            height: '20px',
                                        }} />
                                    </IconButton>
                                </div>
                                <Grid item>
                                    <Typography>
                                        <strong>
                                            Selecionar Mês
                                        </strong>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </>
                    )
                }

                {
                    !showPainelAno && !showPainelMes && (
                        <>
                            <IconButton onClick={(e) => {
                                e.stopPropagation()
                                let newDate = new Date(currentDate ?? '')
                                newDate.setMonth(newDate.getMonth() - 1)
                                setCurrentDate(newDate)
                            }}>
                                <div style={{
                                    width: '10px',
                                    height: '10px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}>
                                    <VoltarIcon tipo="GERAL" style={{
                                        width: '10px',
                                        height: '10px'
                                    }} />
                                </div>
                            </IconButton>
                            <Typography>
                                <strong>
                                    <span onClick={() => {
                                        setShowPainelMes(true)
                                    }}>
                                        {months[new Date(currentDate ?? '').getMonth()]}
                                    </span>
                                    {' de '}
                                    <span onClick={() => {
                                        setShowPainelAno(true)
                                    }}>{new Date(currentDate ?? '').getFullYear()}</span>
                                </strong>
                            </Typography>
                            <div>
                                {/* onclick="prevMonth()" */}
                                <IconButton
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        let newDate = new Date(currentDate ?? '')
                                        newDate.setMonth(newDate.getMonth() + 1)
                                        setCurrentDate(newDate)
                                    }}
                                >
                                    <div style={{
                                        width: '10px',
                                        height: '10px',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}>
                                        <AvancarIcon tipo="GERAL" style={{
                                            width: '10px',
                                            height: '10px'
                                        }} />
                                    </div>
                                </IconButton>
                                {/* onclick="nextMonth()"  */}
                            </div>
                        </>
                    )
                }

            </div>
            {
                showPainelAno && (
                    <>
                        <Grid container alignItems="center">
                            {
                                Array.from({ length: 20 }).map((_, index) => (
                                    <Grid item xs={4} key={index}>
                                        <Button
                                            variant={new Date().getFullYear() + index - 12 === new Date(currentDate ?? '').getFullYear() ? 'contained' : 'text'}
                                            color={new Date().getFullYear() + index - 12 === new Date(currentDate ?? '').getFullYear() ? 'primary' : 'default'}
                                            fullWidth
                                            onClick={() => {
                                                // Ano Button
                                                const newDate = new Date(currentDate ?? '')
                                                newDate.setFullYear((new Date().getFullYear() + index - 12))
                                                setCurrentDate(newDate)
                                                setShowPainelAno(false)

                                            }}
                                        >
                                            {new Date().getFullYear() + index - 12}
                                        </Button>
                                    </Grid>
                                ))
                            }
                        </Grid>
                    </>
                )

            }

            {
                showPainelMes && (
                    <Grid container alignItems="center">
                        {
                            months.map((month, index) => (
                                <Grid item xs={4} key={index}>
                                    <Button
                                        variant={index === new Date(currentDate ?? '').getMonth() ? 'contained' : 'text'}
                                        color={index === new Date(currentDate ?? '').getMonth() ? 'primary' : 'default'}
                                        fullWidth
                                        onClick={() => {
                                            const newDate = new Date(currentDate ?? '')
                                            newDate.setMonth(index)
                                            setCurrentDate(newDate)
                                            setShowPainelMes(false)
                                        }}
                                    >
                                        {month}
                                    </Button>
                                </Grid>
                            ))
                        }
                    </Grid>
                )
            }
            {
                (!showPainelAno && !showPainelMes) && (
                    <div ref={tableRef}></div>
                )
            }

            {/* <div ref={tableRef}></div> */}

        </div >
        {
            props.type === 'date-range' && !showPainelAno && (
                <div className={classes.valueDIsplayContainer}>
                    <div onClick={() => {
                        if (props.type === 'date-range') {
                            const data = (props.value as IRangeData).inicio ?? new Date()
                            if (data) {
                                setCurrentDate(data)
                            }
                        }
                    }}>
                        <Typography variant="caption" >
                            <strong>
                                Início
                            </strong>
                        </Typography>
                        <Typography>
                            <strong>
                                {toDateString((props.value as IRangeData)?.inicio)}
                            </strong>
                        </Typography>
                    </div>
                    <div onClick={() => {
                        if (props.type === 'date-range') {
                            const castedProps = props.value as IRangeData
                            if (castedProps.fim) {
                                setCurrentDate(castedProps.fim ?? new Date())
                            } else {
                                if (castedProps.inicio) {
                                    setCurrentDate(castedProps.inicio ?? new Date())
                                }
                            }
                        }
                    }}>
                        <Typography variant="caption" >
                            <strong>
                                Fim
                            </strong>
                        </Typography>
                        <Typography>
                            <strong>
                                {toDateString((props.value as IRangeData)?.fim)}
                            </strong>
                        </Typography>
                    </div>
                </div>
            )
        }
    </>
}