import { useEffect } from 'react';
import { SessaoValorList } from '../components/sassao-valor-list/sessao-valor-list';
import { SessaNovoValor } from '../components/sessao-novo-valor/sessao-novo-valor';
import { useStyles } from '../components/styles/sessao-valor-page-styles';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { EnumTpLancamentoSessaoValor } from 'model/enums/enum-tipo-lancamento-sessao-valor';
import { useVendaHeaderStore } from 'views/components/headers/venda-header/stores/venda-header-store';
import { useHistory } from 'react-router-dom';
import { PdvRotasMock } from 'data/mocks/pdv-rotas-mock';
import { Grid } from 'views/design-system';

const SessaoReforcoPage = () => {
  // AUX
  const classes = useStyles();
  const{push: historyPush} = useHistory();

  const termo = useVendaHeaderStore(state => state.props.search || '');
  const setHeaderProps = useVendaHeaderStore(state => state.setHeaderProps);

  useEffect(() => {
    setHeaderProps({
      title: 'Reforço',
      showDefinicoes: false,
      showMenuPdv: false,
      showFiltro: true,
      voltar: {
        onClick: () => {
          historyPush(PdvRotasMock.gerenciarSessao);
        }
      }
    })
  }, [historyPush, setHeaderProps])

  return (
    <>
      <Grid className={classes.container}>
        <Grid className={classes.list}>
          <SessaoValorList
            searchProps={{termo}}
            tpEvent={AppEventEnum.SessaoReforco}
            tpLancamento={EnumTpLancamentoSessaoValor.SUPRIMENTO}
          />
        </Grid>

        <SessaNovoValor
          tpEvent={AppEventEnum.SessaoReforco}
          title="Novo Reforço"
          tpLancamento={EnumTpLancamentoSessaoValor.SUPRIMENTO}
        />
      </Grid>
    </>
  );
};

export default SessaoReforcoPage;
