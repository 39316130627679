import { VariaveisAmbiente } from 'config';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';


export function useGetContasBancarias() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const getContasBancarias = useCallback(
        //page size 0 é sem paginação
        (page: number = 0, pageSize: number = 0, data: {
            empresaId: string
        }) =>
            invocarApi({
                method: 'GET',
                url: `/v1/empresa/${data.empresaId}/conta-bancaria?PageSize=${pageSize}&Page=${page}`,
                headers: { 'Content-Type': 'application/json;charset=utf-8' },
                enviarTokenUsuario: true,
                baseURL: VariaveisAmbiente.apiBoletoSafra + '/api'
            }),
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        getContasBancarias,
    };
}