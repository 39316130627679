import { DialogSaurus } from '../dialog-saurus/dialog-saurus';
import { DesfazerIcon, SATIcon, SalvarIcon, SetorProcessosAddIcon } from '../../icons';
import { CircularLoading, useThemeQueries } from '../../..';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useToastSaurus } from 'services/app';
import { ProcessosModel, ProcessosSetorModel } from 'model/api/gestao/processos/processos-model';
import { useStyles } from './dialog-processos-styles';
import { useEmpresaAtual } from 'services/app/hooks/empresa-atual';
import { useGetProcessos } from 'data/api/gestao/processos/get-processos';
import { AccordionProcessos } from 'views/components/modals/setores/setores-edit/components/accordion-processos/accordion-processos';
import { DialogAdicionarProcessos } from '../dialog-adicionar-processos/dialog-adicionar-processos';
import { DialogEditarProcessos } from '../dialog-editar-processos/dialog-editar-processos';
import { SetoresModel } from 'model/api/gestao/setores/setores-model';
import { isEmpty } from 'lodash';
import { DialogProcessosProps } from './dialog-processos-props';
import { usePostProcessosIniciar } from 'data/api/gestao/processos/post-processos-iniciar';
import { Controller, useForm } from 'react-hook-form';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { usePutProcessoSetor } from 'data/api/gestao/processos/put-processo-setor';
import { Box, Button, Grid, Typography } from 'views/design-system';

export const DialogProcessos = ({
  close,
  aberto,
  setorId,
  salao
}: DialogProcessosProps) => {
  const { getEmpresaAtual } = useEmpresaAtual();
  const { showToast } = useToastSaurus();
  const { getProcessos, carregando: carregandoGetProcessos } = useGetProcessos()
  const { postProcessosIniciar, carregando: carregandoPostIniciar } = usePostProcessosIniciar()
  const { putProcessoSetor, carregando: carregandoPutProcessoSetor } = usePutProcessoSetor()

  const loading = [carregandoGetProcessos, carregandoPostIniciar, carregandoPutProcessoSetor].includes(true);
  const classes = useStyles();
  const { theme, isMobile } = useThemeQueries()

  // STATES E REFS
  const [processoSetor, setProcessoSetor] = useState<ProcessosSetorModel>(
    new ProcessosSetorModel()
  );
  const [processo, setProcesso] = useState<ProcessosModel>(
    new ProcessosModel()
  );
  const [txtErro, setTxtErro] = useState<string>('')

  const [showDialogEditarProcessos, setShowDialogEditarProcessos] =
    useState<boolean>(false);
  const [showDialogAdicionarProcessos, setShowDialogAdicionarProcessos] =
    useState<boolean>(false);

  const setorRef = useRef<SetoresModel>(new SetoresModel());
  const processoAdicionado = useRef<boolean>(false);
  const [onlyImpressor, setOnlyImpressor] = useState<boolean>(false);

  const {
    control,
    handleSubmit,
    reset
  } = useForm<{ minutosDePreparo: string }>({
    defaultValues: {
      minutosDePreparo: String(processoSetor.minutosDePreparo)
    },
  })

  const getProcessosWrapper = useCallback(async () => {
    try {
      setTxtErro('')
      const res = await getProcessos(getEmpresaAtual()?.id ?? '', setorId, salao.id ? `?salaoId=${salao.id ?? ''}` : '')

      if (res.erro) throw res.erro

      const ret = res.resultado?.data.list as ProcessosSetorModel[]

      if (isEmpty(ret)) {
        return
      }

      if (!salao.id) {
        const resultado = ret.find(x => !x.salao) ?? new ProcessosSetorModel()
        reset({ minutosDePreparo: String(resultado.minutosDePreparo) })
        setProcessoSetor(resultado)
        return
      }
      const resultado = ret[0]
      reset({ minutosDePreparo: String(resultado.minutosDePreparo) })
      setProcessoSetor(resultado)
    } catch (err: any) {
      showToast('error', err.message)
      setTxtErro(err.message)
    }
  }, [getEmpresaAtual, getProcessos, reset, salao.id, setorId, showToast])

  const postProcessosIniciarWrapper = useCallback(async () => {
    try {
      const res = await postProcessosIniciar(getEmpresaAtual()?.id ?? '', setorId, salao.id ?? '')

      if (res.erro) throw res.erro

      const ret = res.resultado?.data as ProcessosSetorModel

      if (!isEmpty(ret)) {
        setProcessoSetor(ret)
      }
      showToast('success', 'Processos criados com sucesso!')
    } catch (err: any) {
      showToast('error', err.message)
    }
  }, [getEmpresaAtual, postProcessosIniciar, salao.id, setorId, showToast])

  const handleOpenNovoProcesso = () => {
    setShowDialogAdicionarProcessos(true);
  };

  const handleClickCard = useCallback(
    (model: ProcessosModel, isOnlyImpressor = false) => {
      setProcesso(model);
      setOnlyImpressor(isOnlyImpressor);
      setShowDialogEditarProcessos(true);
    }, []);

  const onCloseClick = useCallback(() => {
    close();
  }, [close]);

  useEffect(() => {
    getProcessosWrapper()
  }, [getProcessosWrapper])

  const submit = useCallback(async (model: { minutosDePreparo: string }) => {
    try {
      if (Number(model?.minutosDePreparo ?? '0') === processoSetor.minutosDePreparo) {
        showToast('info', 'Nenhuma informação alterada')
        return
      }
      const res = await putProcessoSetor(
        {
          minutosDePreparo: !model?.minutosDePreparo ? 0 : Number(model.minutosDePreparo),
          id: processoSetor.id,
          processos: processoSetor.processos.map(x => ({
            descricao: x.descricao,
            equipamentosId: x.equipamentos.map(y => y.id),
            id: x.id,
            processoProducao: x.processoProducao,
            status: x.status.codigo ?? 0
          })),
          salaoId: processoSetor?.salao?.id ?? null,
          setorId: processoSetor.setor.id
        },
        getEmpresaAtual()?.id ?? '',
        processoSetor.setor.id,
        processoSetor.id
      )

      if (res.erro) throw res.erro

      setProcessoSetor(prev => ({
        ...prev,
        minutosDePreparo: !model?.minutosDePreparo ? 0 : Number(model.minutosDePreparo)
      }))

      showToast('success', 'Minutos de Preparo atualizado com sucesso!')
    } catch (error: any) {
      showToast('error', error.message)
    }
  }, [getEmpresaAtual, processoSetor, putProcessoSetor, showToast])

  useEffect(() => {
    if (
      !showDialogAdicionarProcessos
      && !showDialogEditarProcessos
      && processoAdicionado.current
    ) {
      getProcessosWrapper();
      processoAdicionado.current = false;
      return;
    }

  }, [getProcessosWrapper, showDialogAdicionarProcessos, showDialogEditarProcessos]);

  return (
    <>
      {showDialogAdicionarProcessos && (
        <DialogAdicionarProcessos
          aberto={showDialogAdicionarProcessos}
          close={() => {
            setShowDialogAdicionarProcessos(false);
          }}
          processoSetor={processoSetor}
          processoAdicionado={processoAdicionado}
        />
      )}

      {showDialogEditarProcessos && (
        <DialogEditarProcessos
          onlyImpressor={onlyImpressor}
          aberto={showDialogEditarProcessos}
          close={() => {
            setShowDialogEditarProcessos(false);
          }}
          processoAdicionado={processoAdicionado}
          setor={setorRef.current}
          processoSetor={processoSetor}
          processo={processo}
        />
      )}
      <DialogSaurus
        fullScreen={isMobile}
        aberto={aberto || false}
        titulo={`Lista de Processos`}
        subTitulo={salao.id ? `Salão: ${salao.descricao}` : ''}
        colorSubTitulo={theme.palette.primary.main}
        tamanho="sm"
        bottomArea={(
          <Box className={classes.boxCustom} py={1} px={3}>
            <Button
              disabled={loading}
              onClick={onCloseClick}
              variant="outlined"
              color="primary"
              fullWidth
              size="large"
            >
              <DesfazerIcon tipo="BUTTON" />
              Voltar
            </Button>
          </Box>
        )}
      >
        {loading && <CircularLoading tipo="FULLSIZED" />}
        <Grid className={classes.cardContainer}>
          <Grid className={classes.cardContent}>
            <Grid container spacing={2}>
              {!isEmpty(txtErro) && !loading && (
                <Box className={classes.boxCustom} mb={1} p={1} alignItems="center">
                  <Box mb={2}>
                    <Typography variant="body1" align='center' weight={500}>
                      Ocorreu um erro ao buscar os processos, clique no botão para tentar novamente ou tente mais tarde. Detalhe: {txtErro}
                    </Typography>
                  </Box>
                  <Button
                    size='large'
                    color='primary'
                    disabled={loading}
                    variant="contained"
                    fullWidth
                    onClick={getProcessosWrapper}
                  >
                    <SATIcon tipo="BUTTON_PRIMARY" />
                    Tentar novamente
                  </Button>
                </Box>
              )}
              {isEmpty(txtErro) && !loading && isEmpty(processoSetor.processos) && (
                <Box className={classes.boxCustom} mb={1} p={1} alignItems="center">
                  <Button
                    size='large'
                    color='primary'
                    variant="contained"
                    disabled={loading}
                    fullWidth
                    onClick={postProcessosIniciarWrapper}
                  >
                    <SetorProcessosAddIcon tipo="BUTTON_PRIMARY" />
                    Iniciar Processos
                  </Button>
                  <Box className={classes.boxCustom}>
                    <Typography variant='caption' color="textSecondary">
                      Irá criar os processos iniciais necessários
                    </Typography>
                  </Box>
                </Box>
              )}
              {isEmpty(txtErro) && !loading && !isEmpty(processoSetor.processos) && (
                <>
                  <Box p={1} className={classes.boxCustom}>
                    <form onSubmit={handleSubmit(submit)}>
                      <Grid container spacing={2}>
                        <Grid item xs={8}>
                          <Controller
                            control={control}
                            name='minutosDePreparo'
                            render={({ field }) => (
                              <TextFieldSaurus
                                label="Minutos de Preparo"
                                tipo="NUMERO"
                                maxTexto={3}
                                {...field}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <Button
                            variant='contained'
                            fullWidth
                            color="primary"
                            type="submit"
                          >
                            <SalvarIcon tipo="BUTTON_PRIMARY" />
                            Salvar
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  </Box>
                  <AccordionProcessos
                    handleClickCard={handleClickCard}
                    handleOpenNovoProcesso={handleOpenNovoProcesso}
                    models={processoSetor.processos}
                  />
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      </DialogSaurus>
    </>
  );
};
