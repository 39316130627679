import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    root: {
        maxHeight: '100%',
        overflow: 'hidden',
        background: theme.palette.background.default,
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        padding: theme.spacing(3, 2),
        gap: theme.spacing(2),
    },
    relative: {
        position: 'relative',
        height: '100%',
        overflowY: 'auto'
    },
    formContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        position: 'absolute',
        width: '100%',
        top: 0,
        bottom: 0,
        height: '100%',
        background: theme.palette.background.paper,
        padding: theme.spacing(2),
        zIndex: 99999
    },
    loadingContainer: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        zIndex: 999999
    },
    buttonContainer: {
        display: 'flex',
        alignItems: 'flex-end',
        flexGrow: 1,
        gap: theme.spacing(2),
    },
    listContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        width: '100%',
        padding: theme.spacing(2),
        gap: theme.spacing(1)
    },
    btnDelete: {
        borderColor: theme.palette.error.main,
        color: theme.palette.error.main,
        '&:hover': {
            background: theme.palette.error.light,
            borderColor: theme.palette.error.main,
        },
        '& svg': {
            fill: theme.palette.error.main
        }
    },
    dividerGrid: {
        marginBottom: theme.spacing(1),
    },
    dividerText: {
        paddingBottom: '4px',
        fontWeight: 'bolder'
    },
    divider: {
        backgroundColor: 'black'
    },
    infoIcon: {
        marginLeft: theme.spacing(1),
        width: 20,

    },
    menuOpcoesGrid: {
        display: 'flex'
    },
    tipoClienteText: {
        marginRight: theme.spacing(1),
    },
    menuItem: {
        height: 60,
        width: '100%',
        display: 'flex',
        alignItems: 'center'
    },
    boxBtn: {
        display: 'flex',
        paddingLeft: theme.spacing(1),
    }
}));