import { DialogSaurus } from '../dialog-saurus/dialog-saurus';
import { useStyles } from './dialog-pagamentos-styles';
import { FinalizadoraModel } from 'model/api/gestao/finalizadora/finalizadora-model';
import { useThemeQueries } from 'views/theme';
import { NovoPagamentoListData } from 'views/pages/private/movimentacao/mov-pagamento-novo/components/novo-pagamento-list/novo-pagamento-list-data';
import { useCallback } from 'react';
import { CircularLoading } from 'views/components/utils';
import { usePayment } from 'services/app/hooks/payment';
import { Box, Typography } from 'views/design-system';

interface DialogPaymentsProps {
  openned: boolean;
  closeModal: () => void;
  payments: FinalizadoraModel[];
}

export const DialogPagamentos = ({
  openned,
  closeModal,
  payments
}: DialogPaymentsProps) => {
  const { startPayment, loading } = usePayment();
  const classes = useStyles();
  const { isMobile } = useThemeQueries();

  const onCardClicked = useCallback(
    async (paymentMethod: FinalizadoraModel) => {
      await startPayment(paymentMethod);
      closeModal();
    },
    [startPayment, closeModal]
  );

  const onCardChecked = useCallback((model: FinalizadoraModel) => {
    return [];
  }, []);

  return (
    <DialogSaurus
      aberto={openned || false}
      titulo="Selecione a forma de pagamento"
      tamanho="md"
      isButtonTitleClose
      handleClickClose={closeModal}
      fullScreen={isMobile}
    >
      <Box className={classes.cardContainer}>
        {loading && <CircularLoading tipo="FULLSIZED" />}
        <Box className={classes.cardContent}>
          <Box className={classes.boxCustom}>
            <Box className={classes.text}>
              <Typography className={classes.label}>
                Identificamos que você possui mais de uma forma de pagamento com
                o tipo selecionado.
              </Typography>

              <NovoPagamentoListData
                carregando={false}
                list={payments}
                selectedList={[]}
                onCardClicked={onCardClicked}
                onCardChecked={onCardChecked}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </DialogSaurus>
  );
};
