import { useStyles } from './card-mesa-atendimento-mov-styles';
import { useThemeQueries } from 'views';
import { CardMesaAtendimentoProps } from './card-mesa-atendimento-props';
import { MesaIcon } from 'views/components/icons/mesa-icon';
import { PessoasMesaIcon } from 'views/components/icons/pessoas-mesa-icon';
import { SacoDinheiroIcon } from 'views/components/icons/saco-dinheiro-icon';
import { toDecimalString } from 'utils/to-decimal';
import { MesaIconNaoPreenchido } from 'views/components/icons/mesa-icon-nao-preenchido';
import { memo, useCallback, useMemo } from 'react';
import { EnumStatusPedido } from 'model/enums/enum-status-pedido';
import useOnPress from 'utils/useOnPress';
import { usePedidoLocal } from 'services/app/hooks/pedido-local';
import { EnumTipoTrabalho } from 'model/enums/enum-tipo-trabalho';
import classNames from 'classnames';
import { PessoaIcon } from 'views/components/icons/pessoa-icon';
import { MouseLeftClick } from 'views/components/icons/mouse-left-click';
import { TemTouch } from 'utils/tem-touch';
import { HoldPressIcon } from 'views/components/icons/hold-press-icon';
import { Grid, Typography } from 'views/design-system';

const CardMesaAtendimento = ({
  model,
  onClick,
  onHold
}: CardMesaAtendimentoProps) => {
  // HOOKS
  const { getConfiguracoesMesaEComanda } = usePedidoLocal();

  // AUX
  const mesaOuComanda = getConfiguracoesMesaEComanda();
  const classes = useStyles();
  const { theme } = useThemeQueries();

  const handlePress = useCallback(() => {
    if (mesaOuComanda?.tipoTrabalho === EnumTipoTrabalho.COMANDA) return;
    onHold(model.pedidos[0]);
  }, [mesaOuComanda?.tipoTrabalho, model, onHold]);

  const handleClick = useCallback(() => {
    onClick(model);
  }, [model, onClick]);

  const EventPressAndClick = useOnPress(handlePress, handleClick, {
    shouldPreventDefault: true,
    delay: 700
  });

  const mesaOcupada = useMemo(
    () => {
      // DATA CRIACAO PEDIDO
      const data = new Date(model.dataCriacao)

      const horaCriacaoPedido = data.getHours();
      const minutosCriacaoPedido = data.getMinutes();
      const pedidoDate = `${horaCriacaoPedido}:${minutosCriacaoPedido >= 10
        ? minutosCriacaoPedido
        : '0' + minutosCriacaoPedido
        }`;

      const dataUltimoPedido = new Date(model?.dataUltimoPedido)
      const hora = dataUltimoPedido.getHours();
      const minutos = dataUltimoPedido.getMinutes();
      const horaPedido = `${hora >= 10 ? hora : '0' + hora}:${minutos >= 10 ? minutos : '0' + minutos
        }`;

      const pedidosNaMesa = model.pedidos.length;

      const pedidosFechados = model.pedidos.filter(
        (pedido) => pedido.statusPedido.codigo === EnumStatusPedido.FECHADO
      ).length;

      const pedidosImportados = model.pedidos.filter(
        (pedido) => pedido.statusPedido.codigo === EnumStatusPedido.IMPORTADO
      ).length;

      const fillColor = pedidosFechados === pedidosNaMesa
        ? theme.palette.warning.main
        : pedidosImportados === pedidosNaMesa
          ? theme.palette.grey[500]
          : model.ociosa
            ? theme.palette.error.main
            : ''

      const pedidoCliente = model.pedidos.find(pedido => {
        return mesaOuComanda?.tipoTrabalho === EnumTipoTrabalho.MESA &&
          pedido.cliente.cpfCnpj !== '99999999000191'
      })

      return (
        <Grid
          className={classes.cardContainer}
          style={{
            borderColor: fillColor,
            boxShadow: model.ociosa
              ? ''
              : '0px 3px 4px rgba(0, 0, 0, 0.09)'
          }}
          {...EventPressAndClick}
        >
          <div className={classes.cardContent}>
            <Grid className={classes.longPressIcon}>
              {!TemTouch() ? (
                <MouseLeftClick
                  tipo="INPUT"
                  fill={fillColor}
                />
              ) : (
                <HoldPressIcon
                  tipo="INPUT"
                  fill={fillColor}
                />
              )}
            </Grid>
            <div className={classes.topContent}>
              <div className={classes.hourContent}>
                <Typography>{pedidoDate}</Typography>
              </div>
              <div style={{ display: 'flex' }}>
                <MesaIcon
                  tipo="INPUT"
                  fill={fillColor}
                />

                <h4
                  className={classes.codigoMesa}
                  style={{
                    maxWidth: '65px',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    textAlign: 'right'
                  }}
                >
                  {model?.codigoMesa}
                </h4>
              </div>
            </div>
            {pedidoCliente && <div className={classes.pessoasContainer}>
              <PessoaIcon
                tipo="INPUT"
                fill={fillColor}
                style={{ width: '20px' }}
              />
              <Typography className={classes.textInfo}>
                {pedidoCliente?.cliente.nomeFantasia.split(' ')[0]}
              </Typography>
            </div>}
            <div className={classes.pessoasContainer}>
              <PessoasMesaIcon
                tipo="INPUT"
                fill={fillColor}
                style={{ width: '20px' }}
              />
              <Typography className={classes.textInfo}>
                {model.qtdePessoas}{' '}
                {model.qtdePessoas !== 1 ? 'Pessoas' : 'Pessoa'}
              </Typography>
            </div>
            <div className={classes.totalContainer}>
              <SacoDinheiroIcon
                tipo="INPUT"
                fill={fillColor}
                style={{ width: '20px' }}
              />
              <Typography className={classes.textInfo}>
                R$ {toDecimalString(model?.valorTotalPedido, 2)}
              </Typography>
            </div>

            <div className={classes.footer}>
              {pedidosFechados === pedidosNaMesa ? (
                <Grid className={classes.fechamentoContainer}>
                  <Typography variant="body1">Fechada</Typography>
                </Grid>
              ) : pedidosImportados === pedidosNaMesa ? (
                <Grid className={classes.importadoContainer}>
                  <Typography variant="body1">Importado</Typography>
                </Grid>
              ) : (
                <>
                  <Typography
                    className={classes.textBottomInfo}
                    variant="body2"
                  >
                    Último produto às {horaPedido}
                  </Typography>
                </>
              )}
            </div>
          </div>
        </Grid>
      );
    },
    [EventPressAndClick, classes.cardContainer, classes.cardContent, classes.codigoMesa, classes.fechamentoContainer, classes.footer, classes.hourContent, classes.importadoContainer, classes.longPressIcon, classes.pessoasContainer, classes.textBottomInfo, classes.textInfo, classes.topContent, classes.totalContainer, mesaOuComanda?.tipoTrabalho, model?.codigoMesa, model.dataCriacao, model?.dataUltimoPedido, model.ociosa, model.pedidos, model.qtdePessoas, model?.valorTotalPedido, theme.palette.error.main, theme.palette.grey, theme.palette.warning.main]
  );

  const mesaLivre = useMemo(
    () => {
      return (
        <Grid
          onClick={() => onClick(model)}
          className={classNames(classes.cardContainer, classes.cardVazio)}
        >
          <div>
            <div className={classes.topContent}>
              <div className={classes.hourContent} />
              <MesaIconNaoPreenchido
                tipo="INPUT"
                fill={theme.palette.primary.main}
              />
              <h5 className={classes.codigoMesa}>
                {model?.codigoMesa}
              </h5>
            </div>
            <div
              style={{
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: '20px'
              }}
            >
              <Typography
                variant="h5"
                style={{ fontWeight: '600', marginBottom: '8px' }}
              >
                Vazia
              </Typography>
              <div style={{ width: '70%', borderTop: '2px solid #ECEDED' }} />
            </div>
          </div>
        </Grid>
      );
    },
    [
      classes.cardContainer,
      classes.cardVazio,
      classes.codigoMesa,
      classes.hourContent,
      classes.topContent,
      model,
      onClick,
      theme.palette.primary.main
    ],
  );

  return (
    <>{model.pedidos.length > 0 ? mesaOcupada : mesaLivre}</>
  );
};

export default memo(CardMesaAtendimento)
