import { CardSaloesProcessosProps } from './card-saloes-processos-props';
import { DefaultCard } from '../components/default-card';
import { useDefaultCardStyles } from '../components/default-card/default-card-styles';
import { EditarIcon } from 'views/components/icons';
import { Button, Tooltip, Typography } from 'views/design-system';

export const CardSaloesProcessos = ({
  model,
  onClick,
  onCheck,
  selected,
}: CardSaloesProcessosProps) => {
  const classes = useDefaultCardStyles();

  return (
    <>
      <DefaultCard
        isSelected={selected}
        onClick={async () => {
          onClick(model);
        }}
      >
        <div className={classes.cardContent}>
          <div className={classes.celulaGridFullDescriptionFull}>
            <Typography color="primary" variant="caption">
              Descrição
            </Typography>
            <Typography
              className={'celula-grid-value'}
              color="textPrimary"
              variant="body1"
            >
              <strong>{model.descricao}</strong>
            </Typography>
          </div>
        </div>
        <Tooltip arrow title="Editar Processo">
          <div style={{
            display: 'flex',
            alignItems: "center"
          }}>
            <Button
              variant='contained'
              color="primary"
              size="large"
              style={{
                width: 40,
                height: 40,
                padding: 0,
                marginRight: 5,
                marginLeft: 5
              }}
            >
              <EditarIcon tipo="BUTTON_PRIMARY" style={{ width: 25, height: 25, marginRight: 0 }} />
            </Button>
          </div>
        </Tooltip>
      </DefaultCard>
    </>
  );
};
