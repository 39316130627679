import React, { useCallback, useEffect, useRef } from "react";
import { IconCardProps } from "../icon-card";
import { useDrag, useDrop } from "react-dnd";
import { useStyles } from '../icon-card-styles'
import { useThemeQueries } from "views/theme";
import { DragIcon } from "views/components/icons/drag-icon";
import { Box, Typography } from "views/design-system";

/*SE QUISER ENTENDER COMO ISSO FUNCIONA E QUAIS PROPS PASSAR CERTINHO VE NA LISTAGEM DE FORMA DE PAGAMENTO Q 
  TEM UM EXEMPLO FUNCIONANDO LÁ!
*/

interface DragDropCardProps<T> extends IconCardProps<T> {
    card: any;
    childs: any[];
}

export function DragComponent<T>(props: DragDropCardProps<T>) {
    const ref = useRef<HTMLDivElement>(null)
    const dragRef = useRef(null)
    const dragCard = useRef<HTMLDivElement>(null)
    const refSize = useRef({
        width: 0,
        height: 0
    })

    const { isMobile } = useThemeQueries();


    const [{ handlerId, hovered }, drop] = useDrop({
        accept: 'card',
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
                hovered: monitor.isOver(),
            }
        },
        drop(item: any, monitor) {
            if (!ref.current || !props.draggable) {
                return
            }
            if (props.onDrop) {
                props.onDrop(props.index || 0)
            }
        },
    })

    const [{ isDragging }, drag] = useDrag(() => ({
        type: 'card',
        collect: (monitor) => ({
            isDragging: monitor.isDragging()
        }),

    }))

    const classes = useStyles();

    const onTouchDrag = useCallback((e: TouchEvent) => {
        if (dragCard.current) {
            const clientX = e.touches[0].clientX - (refSize.current.width / 2);
            const clientY = e.touches[0].clientY + 5
            dragCard.current.style.height = refSize.current.height + 'px'
            dragCard.current.style.width = refSize.current.width + 'px'
            dragCard.current.style.transform = `translate(${clientX}px, ${clientY}px)`
        }
    }, [])

    const onMouseDrag = useCallback((e: MouseEvent) => {
        if (dragCard.current) {
            const clientX = e.clientX - 5;
            const clientY = e.clientY + 5
            dragCard.current.style.height = refSize.current.height + 'px'
            dragCard.current.style.width = refSize.current.width + 'px'
            dragCard.current.style.transform = `translate(${clientX}px, ${clientY}px)`
        }
    }, [])

    drop(ref)
    drag(dragRef)

    useEffect(() => {
        if (isDragging) {
            if (props.setDraggingIndex) props.setDraggingIndex(props.index || 0)
            if (isMobile) {
                window.addEventListener('touchmove', onTouchDrag)
            } else {
                window.addEventListener('mousemove', onMouseDrag)
            }
        } else {
            window.removeEventListener('touchmove', onTouchDrag)
            window.removeEventListener('mousemove', onMouseDrag)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDragging])

    useEffect(() => {
        setTimeout(() => {
            if (ref.current) {
                refSize.current = {
                    height: ref.current.clientHeight,
                    width: ref.current.clientWidth
                }
            }
        }, 50)
    }, [])

    return (
        <>
            {React.cloneElement(props.card, {
                ref: ref,
                className: classes.card,
                // eslint-disable-next-line no-useless-computed-key
                ['data-handler-id']: handlerId,
            }, [
                ...props.childs,
                hovered ? <Box className={classes.dragOverlay}>
                    <Typography align='center'>Solte para Re-ordenar</Typography>
                </Box> : null,
                <div className={classes.dragInfo} ref={dragRef}>
                    <DragIcon />
                </div>
            ])}
            {isDragging && React.cloneElement(props.card, {
                className: classes.draggableCard,
                ref: dragCard
            }, [
                ...props.childs,
                <div className={classes.dragInfo}>
                    <DragIcon />
                </div>
            ])}
        </>
    )
}

export default function DragDropCard<T>(props: DragDropCardProps<T>) {

    if (!props.draggable) {
        return <>
            {props.card}
        </>
    }

    return (
        <DragComponent{...props} />
    )
}