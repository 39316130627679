import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%'
    },
    valueContent: {
        border: `1px solid ${theme.palette.primary.main}50`,
        borderRadius: theme.shape.borderRadius,
    }
}))